export type PaymentLink = {
  id: string;
  status: number;
  amount: number;
  creation_date: string;
  expiration: string;
  status_description: string;
  url: string;
};

export interface PagePagination {
  page: number;
  page_size: number;
  total: number;
  total_pages: number;
}

export interface PagePagination {
  page: number;
  page_size: number;
  total: number;
  total_pages: number;
}

export type GetPaymentLinksResponse = {
  data: PaymentLink[];
} & PagePagination;

export enum EnumStatusPaymentLink {
  CREATED = 'CREATED',
  EXPIRED = 'EXPIRED',
  CANCELED = 'CANCELED',
  PAYED = 'PAYED',
}

export type GetPaymentLinksQueryParams = {
  start_date?: string;
  end_date?: string;
  page: number;
  page_size: number;
  id_status?: EnumStatusPaymentLink;
  id_payment_link?: string;
  establishment_id?: string;
  amount?: string;
};

export type PaymentLinkInfoResponse = PaymentLinkInfoData;

export type StatusType = 'CREATED' | 'EXPIRED' | 'CANCELLED' | 'PAYED';

export type PaymentLinkInfoData = {
  id_payment_link: string;
  id_status: StatusType;
  establishment_id: string;
  user_id: string;
  client_id: string;
  checkout: string;
  clicks_counter: string;
  checkout_id: string | null;
  original_amount: number;
  fee_amount: number;
  fine_amount: number;
  discount_amount: number;
  total_amount: number;
  items: PaymentLinkInfoItem[];
  installment_number: number;
  expiration_date: string;
  notification_email: string;
  charge_methods: string[];
  url: string;
  created_by: string;
  created_at: string;
  updated_at: string;
  splits?: PaymentLinkSplit[];
  redirect: {
    success_url: string;
    expired_url: string;
  };
};

export type PaymentLinkSplit = {
  establishment_id: string;
  amount: number;
  type: 'FIXED' | 'PERCENT';
};

export interface PaymentLinkInfoItem {
  id_payment_link_item: string;
  id_payment_link: string;
  debit_id: any;
  bill_id: string;
  pix_id: string;
  title: string;
  description: string;
  amount: number;
}

export const OptionsStatusPaymentLink = [
  {
    label: 'Criado',
    value: EnumStatusPaymentLink.CREATED,
  },
  {
    label: 'Cancelado',
    value: EnumStatusPaymentLink.CANCELED,
  },
  {
    label: 'Expirado',
    value: EnumStatusPaymentLink.EXPIRED,
  },
  {
    label: 'Pago',
    value: EnumStatusPaymentLink.PAYED,
  },
];

export type TCreatePaymentLink = {
  installment_number: number;
  items?: TCreatePaymentLinkItem[];
  amount?: number;
  expiration_date?: string;
  user_id: string;
  establishment_id: string;
  description: string;
  notification: {
    email?: string;
  };
  charge_methods: ['CREDIT_CARD', 'DEBIT_CARD', 'PIX', 'BILL'];
};

export enum EnumCreatePaymentLinkRequestItemType {
  CAR_DEBIT = 'CAR_DEBIT',
  BILLET = 'BILLET',
  PIX = 'PIX',
  DETACHED = 'DETACHED',
}

export type TCreatePaymentLinkItem = {
  type: EnumCreatePaymentLinkRequestItemType;
  debit_id?: string;
  bill_id?: string;
};

export type CreatePaymentLinkResponse = CreatePaymentLinkResponseData;

export type CreatePaymentLinkResponseData = {
  id_payment_link: string;
  id_status: StatusType;
  establishment_id: string;
  user_id: string;
  client_id: string;
  checkout: string;
  origin_amount: number;
  fee_amount: number;
  total_amount: number;
  installment_number: number;
  expiration_date: string;
  charge_methods: string;
  url: string;
  created_by: string;
  created_at: string;
  updated_at: string;
};
