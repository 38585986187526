import { Reducer } from 'redux';

import { EnumUtilsActions } from "./actions";
import { Estado, Responsabilidade, StatusPermissao, StatusVinculo } from './types';

export interface IUtilsState {
  qtdePermissoesPendentes: number;
  qtdeVinculosPendentes: number;
  status_permissao: StatusPermissao[];
  status_vinculo: StatusVinculo[];
  estado: Estado[];
  responsabilidade: Responsabilidade[];
}

const initial_state: IUtilsState = {
  qtdePermissoesPendentes: 0,
  qtdeVinculosPendentes: 0,
  status_permissao: [],
  status_vinculo: [],
  estado: [],
  responsabilidade: [],
};

const utils: Reducer<IUtilsState> = (state = initial_state, action) => {
  const { payload } = action;
  switch (action.type) {
    case EnumUtilsActions.SET_PERMISSOES_PENDENTES:
      return {
        ...state,
        qtdePermissoesPendentes: payload.qtdePermissoesPendentes
      };
    case EnumUtilsActions.SET_VINCULOS_PENDENTES:
      return {
        ...state,
        qtdeVinculosPendentes: payload.qtdeVinculosPendentes
      };
    case EnumUtilsActions.SET_STATUS_PERMISSOES:
      return {
        ...state,
        status_permissao: payload.status_permissao
      };
    case EnumUtilsActions.SET_STATUS_VINCULOS:
      return {
        ...state,
        status_vinculo: payload.status_vinculo
      };
    case EnumUtilsActions.SET_ESTADOS:
      return {
        ...state,
        estado: payload.estado
      };
    case EnumUtilsActions.SET_RESPONSABILIDADE:
      return {
        ...state,
        responsabilidade: payload.responsabilidade
      };

    default:
      return state;
  }
}

export default utils;