import React, { useMemo } from 'react';
import {
  Form,
  Row,
  Col,
  Input,
  Checkbox,
  Button,
  Select,
  Divider} from 'antd';
import { useHistory, useParams } from 'react-router-dom';

import CardCollapse from '../../../components/CardCollapse';
import PageHeader from '../../../components/PageHeader';
import { EnumTypePartner, OptionsInputKeyPartner, OptionsTypePartner, Partner } from '../provider/dto/partner.dto';
import { useCreatePartner, useGetPartner, useUpdatePartner } from '../hooks/usePartner';
import { MdAdd, MdRemoveCircle } from 'react-icons/md';

const default_message = 'Esse campo é obrigatório';
const uuid_pattern = new RegExp(
  '^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$',
  'i',
);

const ParceiroCadastro: React.FC = () => {
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();
  const [form] = Form.useForm();

  const is_edit = useMemo(() => {
    return id && id !== 'new';
  }, [id]);

  const { data: partner, isLoading: isLoadingInfo } = useGetPartner({ id_partner: id || "", enabled: !!is_edit })
  const { mutate: createPartner, isLoading: isLoadingCreatingPartner } = useCreatePartner()
  const { mutate: updatePartner, isLoading: isLoadingUpdatingPartner } = useUpdatePartner()
  const isLoading = isLoadingCreatingPartner || isLoadingUpdatingPartner


  function handleCreatePartner(data: Partner) {
    createPartner(data, {
      onSuccess() {
        history.push('/parceiro')
      },
    })
  }

  function handleUpdatePartner(data: Partner) {
    updatePartner({
      data,
      id_partner: id || ""
    }, {
      onSuccess() {
        history.push('/parceiro')
      },
    })  }

  return (
    <>
      <PageHeader
        title="Parceiro"
        breadcrumb={['Parceiro', is_edit ? 'Editar' : 'Cadastro']}
      />

      <CardCollapse header={false} loading={isLoadingInfo}>
        <Form
          layout="vertical"
          onFinish={is_edit ? handleUpdatePartner : handleCreatePartner}
          form={form}
          initialValues={{
            ...partner,
            fields: partner?.partner_fields
          }}
        >
          {!is_edit ? (
            <Col md={5}>
              <Form.Item
                name="id_partner"
                label="Identificador (UUID)"
                rules={[
                  { required: true, message: default_message },
                  {
                    pattern: uuid_pattern,
                    message: 'Insira um REGEX válido',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          ) : null}
          <Row gutter={16}>
            <Col md={4}>
              <Form.Item
                name="type"
                label="Tipo"
                initialValue={EnumTypePartner.DETRAN}
              >
                <Select
                  options={OptionsTypePartner}
                />
              </Form.Item>
            </Col>
              <Col md={5}>
                <Form.Item
                  name="name"
                  label="Nome"
                  rules={[{ required: true, message: default_message }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col md={5}>
                <Form.Item
                  name="state"
                  label="Estado"
                  rules={[{ required: true, message: default_message }]}
                >
                  <Input placeholder='Ex: SP'/>
                </Form.Item>
              </Col>
            <Col md={3}>
              <Form.Item
                name="integrated"
                label="Tem Integração?"
                valuePropName="checked"
                initialValue={false}
              >
                <Checkbox checked={false}>Sim</Checkbox>
              </Form.Item>
            </Col>
            <Col md={3}>
              <Form.Item
                name="production"
                label="Ambiente"
                valuePropName="checked"
                initialValue={false}
              >
                <Checkbox checked={false}>Produção</Checkbox>
              </Form.Item>
            </Col>
            <Col md={4}>
              <Form.Item
                name="display"
                label="Display"
                valuePropName="checked"
                initialValue={false}
                extra="Deve aparecer nas listagens de parceiros?"
              >
                <Checkbox checked={false}>Sim</Checkbox>
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item
                name="establishment_id"
                label="Id do Usuário Responsável"
                rules={[
                  { required: true, message: default_message },
                  {
                    pattern: uuid_pattern,
                    message: 'Deve ser um id válido',
                  },
                ]}
                extra="As vendas serão em nome desse usuário"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item
                name="image_src"
                label="URL da Imagem"
                rules={[
                  { required: true, message: default_message },
                  { type: 'url', message: 'Deve ser uma URL válida' },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

            <br />
          <br />

          <Divider orientation="left">Campos</Divider>
          {/* <h3>Campos</h3> */}
          <Form.List name="fields">
            {(fields, { add, remove }) => (
              <>
                <Row gutter={16} align="middle">
                  {fields.map(({ key, name, ...restField }) => (
                    <>
                      <Col md={6}>
                        <Form.Item
                          {...restField}
                          label="Label"
                          name={[name, 'label']}
                          fieldKey={[name, 'label']}
                          rules={[{ required: true, message: default_message }]}
                        >
                          <Input placeholder="Ex: Documento" />
                        </Form.Item>
                      </Col>
                      <Col md={6}>
                        <Form.Item
                          {...restField}
                          label="Chave"
                          name={[name, 'input_key']}
                          fieldKey={[name, 'input_key']}
                          rules={[{ required: true, message: default_message }]}
                        >
                          <Select options={OptionsInputKeyPartner} />
                        </Form.Item>
                      </Col>
                      <Col md={7}>
                        <Form.Item
                          {...restField}
                          label="Obrigatório"
                          name={[name, 'required']}
                          valuePropName="checked"
                          fieldKey={[name, 'required']}
                          initialValue={true}
                         >
                          <Checkbox defaultChecked />
                        </Form.Item>
                      </Col>
                      <Col md={1}>
                        {fields.length !== 1 ? (
                          <MdRemoveCircle onClick={() => remove(name)} />
                        ) : null}
                      </Col>
                    </>
                  ))}
                </Row>
                <br />
                <Row justify="space-between">
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    icon={<MdAdd />}
                    disabled={isLoading}
                  >
                    Adicionar Campo
                  </Button>
                  <Button
                type="primary"
                htmlType="submit"
                loading={isLoading}
                >
                {is_edit ? 'Salvar' : 'Cadastrar'}
              </Button>
                </Row>
              </>
            )}
          </Form.List>
        </Form>
      </CardCollapse>
    </>
  );
};

export default ParceiroCadastro;
