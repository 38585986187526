import {
  Button,
  Modal,
  Form,
  Row,
  Col,
  Input,
  DatePicker,
  Empty,
  List,
  Tag,
} from 'antd';
import React, { useState } from 'react';

import CardCollapse from '../../../../components/CardCollapse';
import { usePermission } from '../../../../hooks/usePermissions';
import { fetchApi } from '../../../../services/api';
import UserName from '../../../../components/User/Name';
import { Commission as ICommission } from '../types';
import { dinheiroMask } from 'masks-br';
import { MdAttachMoney } from 'react-icons/md';

type TProps = {
  commission?: ICommission[];
  loading: boolean;
  fetchCheckout: () => Promise<any>;
};

const Commission: React.FC<TProps> = ({
  loading,
  commission,
  fetchCheckout,
}: TProps) => {
  const { userPermissions } = usePermission();
  const [formPagamentoManual] = Form.useForm();

  const [idTransacaoPagamentoManual, setIdTransacaoPagamentoManual] = useState<
    string | undefined
  >(undefined);

  function handlePagamentoManual(data: {
    token_transacao: string;
    transacionado_em: Date;
  }) {
    return fetchApi({
      url: `/comissao/${idTransacaoPagamentoManual}`,
      method: 'post',
      data,
      messages: {
        error: 'Erro ao salvar pagamanto manual, tente novamente!',
        loading: 'Salvando pagamento manual, aguarde...',
        success: 'Pagamento manual salvo com sucesso!',
      },
      onSuccess: () => {
        fetchCheckout();
        formPagamentoManual.resetFields();
        setIdTransacaoPagamentoManual(undefined);
      },
    });
  }

  function getStatus(status: ICommission['status']) {
    switch (status) {
      case 'WAITING':
        return <Tag color="blue">Aguardando Aprovação</Tag>;
      case 'DONE':
        return <Tag color="green">Transferência Realizada</Tag>;
      case 'REMOVED':
        return <Tag color="red">Transferência Removida</Tag>;

      default:
        return <Tag>Status desconhecido</Tag>;
    }
  }

  return (
    <>
      <CardCollapse title="Comissões" loading={loading}>
        {commission?.length ? (
          <List
            itemLayout="horizontal"
            dataSource={commission}
            renderItem={item => (
              <List.Item
                actions={[
                  <Button
                    onClick={() =>
                      setIdTransacaoPagamentoManual(item.id_commission)
                    }
                    disabled={
                      item.status !== 'WAITING' ||
                      !userPermissions.COMISSAO_PAGAR_COMUALMENTE_COMISSAO
                    }
                  >
                    <MdAttachMoney />
                  </Button>,
                ]}
              >
                <List.Item.Meta
                  title={<UserName id_user={item.id_user} />}
                  description={dinheiroMask(item.amount)}
                />
                <div>{getStatus(item.status)}</div>
              </List.Item>
            )}
          />
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Sem comissões"
          />
        )}
      </CardCollapse>

      <Modal
        visible={!!idTransacaoPagamentoManual}
        title="Pagamento Manual"
        footer={false}
        onCancel={() => setIdTransacaoPagamentoManual(undefined)}
        centered
      >
        <p>
          Utilize essa funcionalidade apenas quando o pagamento da comissão for
          realizado fora do sistema! Caso contrário, impedirá o pagamento da
          comissão pelo sistema
        </p>
        <Form
          form={formPagamentoManual}
          layout="vertical"
          onFinish={data => handlePagamentoManual(data)}
        >
          <Row gutter={16}>
            <Col md={12}>
              <Form.Item label="Código de Liquidação" name="token_transacao">
                <Input maxLength={45} />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item label="Data do Pagamento" name="transacionado_em">
                <DatePicker
                  showTime
                  format="DD/MM/YYYY HH:mm:ss"
                  placeholder=""
                />
              </Form.Item>
            </Col>
            <Col md={12} />
            <Col md={12}>
              <br />
              <Button htmlType="submit" type="primary" block>
                Salvar
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default Commission;
