import React, {  } from 'react';
import { useParams } from 'react-router-dom';

import { Col, Row, message } from 'antd';
import PageHeader from '../../../components/PageHeader';
import ChargeInfoGeneral from './General';
import ChargeData from './Data';
import { useQuery } from "react-query";
import { getChargeById } from "../services";

const PaymentInfoPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const { isLoading, data } = useQuery({
    queryKey: ['charge-details', id],
    queryFn: () => getChargeById(id),
    enabled: !!id,
    retry: false,
    onError(err) {
      message.error((err as any)?.response?.data?.message)
    },
  })

  if (!data) {
    return (
      <PageHeader
        title="Informações da cobrança"
        breadcrumb={['Cobrança', 'Informação']}
      />
    )
  }

  return (
    <>
      <PageHeader
        title="Informações da cobrança"
        breadcrumb={['Cobrança', 'Informação']}
      />
      <ChargeInfoGeneral data={data} loading={isLoading} />
      <Row gutter={16}>
        <Col md={12} xs={24}>
          <ChargeData loading={isLoading} data={data} />
        </Col>
      </Row>
    </>
  );
};

export default PaymentInfoPage;
