import React from 'react';

import { Wrapper, Content } from './styles';
import Logo from '../../../assets/image/logo.svg';

const AuthLayout: React.FC = ({ children }) => {
  return (
    <Wrapper>
      <img src={Logo} alt="Parcelamos Tudo Logo" width={250} />

      <Content>{children}</Content>
    </Wrapper>
  );
};

export default AuthLayout;
