import React from 'react';
import { message, Table, Tag } from 'antd';
import { format } from 'date-fns';
import { useState } from 'react';
import { useEffect } from 'react';
import Paragraph from 'antd/lib/typography/Paragraph';
import { fetchApi } from '../../../services/api';
import PageHeader from '../../../components/PageHeader';

type SMS = {
  _id: string;
  to: string;
  message: string;
  status: string;
  created_at: string;
};

type Pagination = {
  total: number;
  data: SMS[];
};
export enum EnumSmsStatus {
  SENT = 'sent',
  NOT_SENT = 'not_sent',
}

const PesquisarSMS: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [dataPaginationSMS, setDataPaginationSMS] = useState<Pagination>();

  useEffect(() => {
    fetchSMSSent();
  }, []);

  async function fetchSMSSent() {
    setLoading(true);
    try {
      fetchApi({
        method: 'GET',
        url: '/sms',
        query_params: {
          limit: 25,
          skip: 0,
        },
        onSuccess: data => {
          setDataPaginationSMS(data);
        },
        onError: () => {
          message.error('Erro ao buscar usuários do totem');
        },
      });
      setLoading(false);
    } catch (error) {
      message.error('Erro ao buscar usuários do totem');
      setLoading(false);
    }
  }

  const columns = [
    {
      title: 'Destinatário',
      dataIndex: 'to',
      key: 'to',
      render: (to: string) => <Paragraph copyable>{to}</Paragraph>,
    },
    {
      title: 'Mensagem',
      dataIndex: 'message',
      key: 'message',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => {
        if (status === EnumSmsStatus.SENT) {
          return <Tag color="success">{status.toUpperCase()}</Tag>;
        }
        return <Tag color="error">{status.toUpperCase()}</Tag>;
      },
    },
    {
      title: 'Criado em',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at: string) =>
        format(new Date(created_at), 'dd/MM/yyyy HH:mm:ss'),
    },
  ];

  return (
    <>
      <PageHeader title="SMS Enviados" />
      <Table
        dataSource={dataPaginationSMS?.data}
        columns={columns}
        loading={loading}
        pagination={{
          total: dataPaginationSMS?.total,
        }}
      />
    </>
  );
};

export default PesquisarSMS;
