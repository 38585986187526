import { EnumCheckoutStatus } from '..';
import { StatusTipo } from '../../../store/modules/utils/types';
import { EnumPaymentStatus } from '../../Boleto/types';

export type CarrinhoStatus = {
  id_carrinho: string;
  id_carrinho_status: string;
  id_status_carrinho: number;
  nome: string;
  tipo: StatusTipo;
  mensagem?: string;
  criado_em: string;
};

export type CarrinhoBoleto = {
  cod_boleto: string;
  criado_em: string;
  id_boleto: string;
  id_carrinho: string;
  liquidacao_id?: string;
  liquidado_em?: string;
  removido_em?: string;
  removido_por?: string;
  tipo_liquidacao: BoletoTipoLiquidacao[];
  taxa: number;
  valor: number;
  valor_total: number;
};

export type CarrinhoAnaliseRisco = {
  aprovado: boolean;
  cod_integrador: string;
  id_carrinho: string;
  id_carrinho_analise_risco: string;
  integrador: string;
  mensagem: string;
  score: string;
  status: string;
};

export type BoletoTipoLiquidacao = {
  atualizado_em: string;
  id_boleto: string;
  id_boleto_liquidacao: string;
  id_status_boleto: number;
  id_tipo_liquidacao: number;
  info?: string;
  liquidacao_id: string;
  status_nome: string;
  status_tipo: StatusTipo;
  tipo_liquidacao_nome: string;
  liquidado_por_nome: string;
};

export type GetBackofficeCheckoutResponse = {
  checkout: Checkout;
  client?: Client;
  payments?: Payment[];
  charges?: Charge[];
  commissions?: Commission[];
  payment_link?: {
    id_payment_link: string;
  };
  partner?: Partner;
  summary?: {
    mdr: number;
    anticipation: number;
  };
};

export interface Partner {
  id_partner: string;
  name: string;
}

export interface Client {
  id_cliente: string;
  nome: string;
  email: string;
  cpf: string;
  telefone: string;
  endereco: string;
  numero: string;
  complemento: string;
  data_nascimento: string;
  bairro: string;
  cep: string;
  cidade: string;
  estado: string;
  criado_em: string;
}
export interface Charge {
  id_charge: string;
  id_status: string;
  type: string;
  source: string;
  channel: string;
  acquirer: string;
  amount_total: number;
  card_brand?: string;
  card_last_four_digits?: string;
  nsu_code?: string;
  provider_code?: string;
  installment_number: number;
  installment_amount: number;
  created_at: string;
  updated_at: string;
  status: Status;
  card?: {
    id_card: string;
    client_id?: string;
    last_four_digits: string;
    brand: string;
    holder_name: string;
    holder_document: string;
    created_at: string;
    updated_at: string;
  };
}

export interface Commission {
  id_commission: string;
  id_user: string;
  amount: number;
  percent: number;
  approved_by?: string;
  status: 'WAITING' | 'DONE' | 'REMOVED';
}

type Status = {
  id_status: string;
  name: string;
  type: StatusTipo;
  description?: string;
};

export interface Checkout {
  id_checkout: string;
  id_status: string;
  client_id: string;
  payment_link_id?: string;
  establishment_id: string;
  terminal_id: string;
  user_id?: string;
  order_code: string;
  origin: string;
  type: string;
  amount: number;
  fee_amount: number;
  total_amount: number;
  payment_quantity: number;
  created_at: string;
  created_by: string;
  updated_at: string;
  timeline: CheckoutTimeline[];
  status: CheckoutStatus;
  risk_analysis: CheckoutRiskAnalysis[];
  establishment: CheckoutEstablishment;
}

export interface CheckoutTimeline {
  id_timeline: string;
  id_checkout: string;
  id_status: EnumCheckoutStatus;
  name: string;
  description: string;
  created_at: string;
  status: CheckoutStatus;
}
export interface CheckoutStatus {
  id_status: string;
  name: string;
  type: StatusTipo;
  description: string;
}
export interface CheckoutEstablishment {
  id_establishment: string;
  name: string;
  document: string;
  email?: string;
  phone?: string;
  created_at: string;
  updated_at: string;
}
export interface CheckoutRiskAnalysis {
  id_risk_analysis: string;

  id_checkout: string;
  provider: string;
  result: RiskAnalysisResult;
  identifier?: string;
  score?: string;

  created_at: string;
}
export enum RiskAnalysisResult {
  APPROVED = 'APPROVED',
  REPROVED = 'REPROVED',
  PENDING = 'PENDING',
}

export type GetPaymentsResponse = {};

export type ResponseApiError = {
  statusCode: number;
  message: string;
};

export type Payment = {
  id_payment: string;
  amount: number;
  fee_amount: number;
  total_amount: number;
  id_status: EnumPaymentStatus;
  status: Status;
  created_at: string;
  updated_at: string;
} & TypeBill;

type TypeBill = {
  type: 'BILL';
  bill: Bill;
};

// type TypeDebit = {
//   type: 'DEBIT'
// }

export interface Bill {
  id_bill: string;
  id_status: string;
  code: string;
  digitable: string;
  type: string;
  recipient_assignor: string;
  recipient_document: string;
  recipient_name: string;
  payer_document: string;
  payer_name: string;
  due_date: any;
  amount_original: number;
  amount_total: number;
  amount_discount: number;
  amount_additional: number;
  authentication_code: string;
  id_settlement_type: number;
  settlement_institution: string;
  settlement_transaction_id: string;
  settled_at: string;
  created_at: string;
  updated_at: string;
}
