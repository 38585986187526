export enum EnumTypePartner {
  DETRAN = "DETRAN",
  GOVERNMENT = "GOVERNMENT"
}

export const OptionsTypePartner = [
  {
    label: 'Detran',
    value: EnumTypePartner.DETRAN
  },
  {
    label: 'Governo',
    value: EnumTypePartner.GOVERNMENT
  }
]

enum FieldInputKey {
  LICENSE_PLATE = 'LICENSE_PLATE',
  RENAVAM = 'RENAVAM',
  CHASSI = 'CHASSI',
  DOCUMENT = 'DOCUMENT'
}

export const OptionsInputKeyPartner = [
  {
    value: 'LICENSE_PLATE'
  },
  {
    value: 'RENAVAM'
  },
  {
    value: 'CHASSI'
  },
  {
    value: 'DOCUMENT'
  }
]

export interface Partner {
  establishment_id: string
  type: string
  name: string
  state: string
  image_src: string
  production: boolean
  display: boolean
}

export type GetPartnerResponse = GetPartnerResponseItem

export interface GetPartnerResponseItem {
  id_partner: string
  establishment_id: string
  type: string
  name: string
  state: string
  image_src: string
  production: boolean
  display: boolean
  created_at: string
  updated_at: string
  deleted_at: string
  deleted_by: string
  partner_fields: GetPartnerResponseItemField[]
}

type GetPartnerResponseItemField = {
  id_partner_field: string
  id_partner: string
  required: boolean
  label: string
  input_key: FieldInputKey
}

export type UpdatePartnerServiceProps = {
  data: Partner
  id_partner: string;
}

export type GetPartnersServiceProps = {
  page: number
  page_size: number
}

