import React, { useMemo } from 'react';

import CardCollapse from '../../../../components/CardCollapse';
import { EnumPaymentType, PaymentDetailItem } from '../../services/types';
import PropertyList from '../../../../components/PropertyList';
import { formatDate } from '../../../../utils/date';

type IProps = {
  data: PaymentDetailItem;
  loading: boolean;
};

const Settlement: React.FC<IProps> = ({ loading, data }: IProps) => {
  const title = 'Liquidação';

  const info = useMemo(() => {
    switch (data.type) {
      case EnumPaymentType.BILL:
        return {
          settlement_institution: data.bill.settlement_institution,
          authentication_code: data.bill.authentication_code,
          settled_at: data.bill.settled_at,
        };
      case EnumPaymentType.PIX:
        return {
          settlement_institution: data?.pix?.settlement_institution,
          authentication_code: data?.pix?.authentication_code,
          settled_at: data?.pix?.settled_at,
        };
      case EnumPaymentType.TRANSFER:
        return {
          settlement_institution: data.transfer.settlement_institution,
          authentication_code: data.transfer.authentication_code,
          settled_at: data.transfer.settled_at,
        };
    }
  }, [data]);

  if (!info?.settled_at) {
    return null;
  }

  return (
    <CardCollapse title={title} loading={loading}>
      <PropertyList.Container>
        <PropertyList.Line>
          <PropertyList.Label>Instituição</PropertyList.Label>
          <PropertyList.Value>{info.settlement_institution}</PropertyList.Value>
        </PropertyList.Line>
        <PropertyList.Line>
          <PropertyList.Label>Código de Autenticação</PropertyList.Label>
          <PropertyList.Value>{info.authentication_code}</PropertyList.Value>
        </PropertyList.Line>
        <PropertyList.Line>
          <PropertyList.Label>Liquidado em</PropertyList.Label>
          <PropertyList.Value>
            {formatDate(new Date(info.settled_at), 'datetimefull')}
          </PropertyList.Value>
        </PropertyList.Line>
      </PropertyList.Container>
    </CardCollapse>
  );
};

export default Settlement;
