import {
  Button,
  Modal,
  Form,
  Input,
  Timeline,
  message,
  List,
  Divider,
  Tooltip,
  Typography,
  DatePicker,
  Select,
} from 'antd';
import { format, formatDistanceToNow } from 'date-fns';
import { dinheiroMask } from 'masks-br';
import React, { useCallback, useMemo, useState } from 'react';
import {
  MdList,
  MdAttachMoney,
  MdOpenInNew,
  MdInfo,
  MdBookmark,
} from 'react-icons/md';
import { Link } from 'react-router-dom';
import { ptBR } from 'date-fns/locale';

import CardCollapse from '../../../../components/CardCollapse';
import { useApi } from '../../../../hooks/useApi';
import { fetchApi } from '../../../../services/api';
import { findStatusColor, getColor } from '../../index';
import { EnumBillStatus, EnumPaymentStatus } from '../../../Boleto/types';
import { GetBackofficeCheckoutResponse, Payment } from '../types';
import PropertyList from '../../../../components/PropertyList';
import moment from 'moment';
import Option from '../../../../types/Option';
import { usePermission } from '../../../../hooks/usePermissions';
import TooltipConditional from '../../../../components/TooltipConditional';

const { Paragraph, Text } = Typography;

type ICarrinhoInfoBoleto = {
  loading: boolean;
  checkout?: GetBackofficeCheckoutResponse;
  tick: number;
  fetchCheckout: () => Promise<any>;
};

export type IBoletoLog = {
  id_timeline: string;
  id_payment: string;
  id_status: string;
  description: string;
  created_by: string;
  created_at: string;
  status: Status;
};

export interface Status {
  id_status: string;
  name: string;
  type: string | EnumTipoLogBoleto;
  description: string;
}

export enum EnumTipoLogBoleto {
  SUCCESS = 'success',
  WARNING = 'warning',
  INFO = 'info',
  ERROR = 'error',
  WAITING = 'waiting',
}

type ILogs = {
  id_payment: string;
  modal: boolean;
  logs: IBoletoLog[];
};

type modalPaymentManual = {
  modal_open: boolean;
  id_payment?: string;
};

export enum EnumBillSettlementInstitution {
  CELCOIN = 'CELCOIN',
  CAIXA = 'CAIXA',
  BANCO_DO_BRASIL = 'BANCO_DO_BRASIL',
  BANESE = 'BANESE',
}

export const instituicao_liquidacao: Option[] = Object.keys(
  EnumBillSettlementInstitution,
).map(key => ({
  label: key.replace(/_/g, ' '),
  value:
    EnumBillSettlementInstitution[
      key as keyof typeof EnumBillSettlementInstitution
    ],
}));

const default_message = 'Esse campo é obrigatório';

const CheckoutInfoPayments: React.FC<ICarrinhoInfoBoleto> = ({
  loading,
  fetchCheckout,
  checkout,
  tick,
}: ICarrinhoInfoBoleto) => {
  const [formUpdateCode] = Form.useForm();
  const { loading: loadingUpdate, fetchRequest } = useApi();
  const [openInfoModal, setOpenInfoModal] = useState<string>();
  const [logsBoleto, setLogsBoleto] = useState<ILogs>({} as ILogs);
  const [modalCodLiquidacao, setModalCodLiquidacao] =
    useState<modalPaymentManual>({} as modalPaymentManual);
  const [formLiquidacao] = Form.useForm();
  const [loadingModal, setLoadingModal] = useState<boolean>(false);

  const { getPermissions } = usePermission();

  const permissoes = getPermissions();

  const partner = checkout?.partner;

  const selectedPayment = useMemo(() => {
    return checkout?.payments?.find(item => item.id_payment === openInfoModal)!;
  }, [openInfoModal, checkout]);

  const partnerExist = useMemo(() => {
    return partner?.name;
  }, [partner]);

  function handleFetchBoletoLogs(id_payment: string) {
    if (logsBoleto.id_payment === id_payment) {
      setLogsBoleto({
        ...logsBoleto,
        modal: true,
      });

      return '';
    }

    return fetchApi({
      url: `/backoffice/checkout/payment/${id_payment}/timeline`,
      method: 'get',
      onSuccess: data => {
        setLogsBoleto({
          modal: true,
          id_payment,
          logs: data.timeline.map((log: IBoletoLog) => {
            return (
              <Timeline.Item
                key={log.id_timeline}
                color={getColor(log.status.type.toLowerCase())}
                position="start"
              >
                <strong>{log.status.name}</strong>
                {log.status.id_status === EnumPaymentStatus.PAID_MANUALLY && (
                  <Paragraph copyable>
                    <strong>Liquidador por:</strong> {log.created_by}
                  </Paragraph>
                )}
                <br />
                <Text type="secondary">
                  {log.created_at
                    ? format(new Date(log.created_at), 'PPPpp', {
                        locale: ptBR,
                      })
                    : null}
                </Text>
              </Timeline.Item>
            );
          }),
        });
      },
    });
  }

  function handlePagarBoletoManualmente(id_payment: string) {
    const key = 'handlePagarBoletoManualmente';

    Modal.confirm({
      title: 'Tem certeza que deseja reprocessar esse pagamento?',
      content: 'Essa ação não poderá ser desfeita!',
      centered: true,
      onOk() {
        message.loading({
          content: 'Liquidando boleto manualmente, aguarde...',
          key,
          duration: 10000,
        });

        return fetchApi({
          url: `/backoffice/payment/${id_payment}/retry`,
          method: 'post',
          onSuccess: async data => {
            if (data.success) {
              message.success({
                content: 'Boleto liquidado com sucesso!',
                key,
              });
              await fetchCheckout();
            } else {
              message.error({
                content: 'Erro ao liquidar boleto, tente novamente!',
                key,
              });
            }
          },
          onError: () => {
            message.error({
              content: 'Erro ao liquidar boleto, tente novamente!',
              key,
            });
          },
        });
      },
    });
  }

  function handleUpdateBoleto(id_bill: string, code: string) {
    Modal.confirm({
      title: 'Tem certeza que deseja alterar o código do boleto?',
      content: 'Essa ação não poderá ser desfeita!',
      centered: true,
      onOk() {
        return fetchRequest({
          method: 'patch',
          url: `/backoffice/bill/${id_bill}/code`,
          data: {
            code,
          },
          messages: {
            loading: 'Atualizando código de boleto e consultando, aguarde...',
            error: 'Erro ao atualizar boleto, tente novamente!',
            success: 'Código do boleto atualizado com sucesso!',
          },
          onSuccess: async () => {
            await fetchCheckout();
          },
        });
      },
    });
  }

  function getNameSettlement(id: number) {
    switch (id) {
      case 1:
        return 'Bancária';
      case 2:
        return 'Arquivo Retorno';
    }
  }

  function findBoletoInfoButtons(payment: Payment) {
    return [
      <Tooltip title="Abrir informações">
        <Button onClick={() => setOpenInfoModal(payment.id_payment)}>
          <MdInfo />
        </Button>
      </Tooltip>,
      <Button key="1" onClick={() => handleFetchBoletoLogs(payment.id_payment)}>
        <MdList />
      </Button>,
      <Button
        disabled={
          !(
            [
              EnumPaymentStatus.PAYMENT_ERROR,
              EnumPaymentStatus.WAITING_PAYMENT,
              EnumPaymentStatus.PAYMENT_REVERSED,
            ].includes(payment.id_status) && permissoes.CARRINHO_PAGAR_BOLETO
          )
        }
        onClick={() =>
          setModalCodLiquidacao({
            modal_open: true,
            id_payment: payment.id_payment,
          })
        }
      >
        <MdBookmark />
      </Button>,
      <TooltipConditional
        title="Pagamento possui um arquivo de retorno."
        condition={[
          EnumBillStatus.WAITING_EXPORT,
          EnumBillStatus.EXPORTED,
        ].includes(payment?.bill?.id_status as EnumBillStatus)}
      >
        <Button
          key="2"
          type="primary"
          onClick={() => handlePagarBoletoManualmente(payment.id_payment)}
          disabled={
            ![
              EnumPaymentStatus.PAYMENT_ERROR,
              EnumPaymentStatus.WAITING_PAYMENT,
            ].includes(payment.id_status) ||
            [EnumBillStatus.WAITING_EXPORT].includes(
              payment?.bill?.id_status as EnumBillStatus,
            )
          }
          danger
        >
          <MdAttachMoney />
        </Button>
      </TooltipConditional>,
      <Tooltip title="Abrir pagamento">
        <Link
          className="ant-btn"
          to={
            payment?.bill?.id_bill
              ? `/boleto/${payment?.bill?.id_bill}`
              : `/payment/${payment?.id_payment}`
          }
        >
          <MdOpenInNew />
        </Link>
      </Tooltip>,
    ];
  }

  const getDistanceTime = useCallback(
    (updated_at: string) => {
      return (
        'Atualizado ' +
        formatDistanceToNow(new Date(updated_at), {
          locale: ptBR,
          addSuffix: true,
          includeSeconds: true,
        })
      );
    },
    [tick],
  );

  const getTitle = (payment: Payment) => {
    const settlement = payment?.bill?.id_settlement_type ? (
      getNameSettlement(payment?.bill?.id_settlement_type)
    ) : (
      <Text type="warning">Boleto não liquidado.</Text>
    );

    return (
      <>
        <b style={{ fontSize: '20px', paddingRight: 10 }}>
          {dinheiroMask(payment.total_amount)}
        </b>
        {settlement}
      </>
    );
  };

  function handleLiquidacaoManual(data: any) {
    setLoadingModal(true);

    const { id_payment } = modalCodLiquidacao;
    data.settled_at = data.settled_at.toISOString();

    return fetchRequest({
      method: 'put',
      url: `/backoffice/payment/${id_payment}/manually`,
      data,
      onSuccess: async () => {
        setLoadingModal(false);
        setModalCodLiquidacao({
          modal_open: false,
        });
        message.success('Código de liquidação adicionado com sucesso!');
        formLiquidacao.resetFields();
        await fetchCheckout();
      },
      onError: () => {
        setLoadingModal(false);
        setModalCodLiquidacao({
          modal_open: false,
        });
        message.error('Erro ao adicionar código de liquidação!');
      },
    });
  }

  return (
    <>
      <Modal
        centered
        visible={modalCodLiquidacao.modal_open}
        title="Adicionar Código de Liquidação"
        onCancel={() =>
          setModalCodLiquidacao({
            modal_open: false,
          })
        }
        okText="Adicionar"
        onOk={() => formLiquidacao.submit()}
        okButtonProps={{ loading: loadingModal }}
      >
        <Form
          layout="vertical"
          form={formLiquidacao}
          onFinish={handleLiquidacaoManual}
          initialValues={{
            settled_at: moment(),
            settlement_institution: instituicao_liquidacao[0].value,
          }}
        >
          <Form.Item
            label="Código de Liquidação"
            name="authentication_code"
            rules={[
              {
                required: true,
                message: default_message,
              },
            ]}
          >
            <Input
              maxLength={45}
              placeholder="Informe o código de liquidação"
            />
          </Form.Item>
          <Form.Item label="Data da Liquidação" name="settled_at">
            <DatePicker placeholder="Selecione a data" format={'DD/MM/YYYY'} />
          </Form.Item>
          <Form.Item label="Instituição" name="settlement_institution">
            <Select options={instituicao_liquidacao} />
          </Form.Item>
        </Form>
      </Modal>

      <CardCollapse title="Débitos" loading={loading}>
        <List
          loading={loading}
          itemLayout="horizontal"
          dataSource={checkout?.payments || []}
          renderItem={item => (
            <List.Item
              key={item.id_payment}
              actions={findBoletoInfoButtons(item)}
            >
              <List.Item.Meta
                title={getTitle(item)}
                description={getDistanceTime(item.updated_at)}
              />
              <div>
                {findStatusColor(item?.status?.name, item?.status?.type)}
              </div>
            </List.Item>
          )}
        />
      </CardCollapse>

      <Modal
        visible={!!openInfoModal}
        title="Informações do Pagamento"
        footer={false}
        onCancel={() => setOpenInfoModal(undefined)}
        centered
        width={600}
      >
        <PropertyList.Container>
          <PropertyList.Line>
            <PropertyList.Label>Id</PropertyList.Label>
            <PropertyList.Value>
              <Paragraph copyable>{selectedPayment?.id_payment}</Paragraph>
            </PropertyList.Value>
          </PropertyList.Line>
          <PropertyList.Line>
            <PropertyList.Label>Valor</PropertyList.Label>
            <PropertyList.Value>
              {dinheiroMask(selectedPayment?.amount)}
            </PropertyList.Value>
          </PropertyList.Line>
          <PropertyList.Line>
            <PropertyList.Label>Taxa</PropertyList.Label>
            <PropertyList.Value>
              {dinheiroMask(selectedPayment?.fee_amount)}
            </PropertyList.Value>
          </PropertyList.Line>
          <PropertyList.Line>
            <PropertyList.Label>Valor Total</PropertyList.Label>
            <PropertyList.Value>
              {dinheiroMask(selectedPayment?.total_amount)}
            </PropertyList.Value>
          </PropertyList.Line>
          <PropertyList.Line>
            <PropertyList.Label>Tipo</PropertyList.Label>
            <PropertyList.Value>{selectedPayment?.type}</PropertyList.Value>
          </PropertyList.Line>
          {!!partnerExist &&(
          <PropertyList.Line>
            <PropertyList.Label>Parceiro</PropertyList.Label>
            <PropertyList.Value>{partnerExist}</PropertyList.Value>
          </PropertyList.Line>)}
        </PropertyList.Container>

        {selectedPayment?.bill && (
          <>
            <Divider orientation="left">Boleto</Divider>

            <PropertyList.Container>
              <PropertyList.Line>
                <PropertyList.Label>Id</PropertyList.Label>
                <PropertyList.Value>
                  <Paragraph copyable>
                    {selectedPayment?.bill?.id_bill}
                  </Paragraph>
                </PropertyList.Value>
              </PropertyList.Line>
              <PropertyList.Line>
                <PropertyList.Label>Código de Barras</PropertyList.Label>
                <PropertyList.Value>
                  <Form
                    layout="vertical"
                    initialValues={selectedPayment.bill}
                    form={formUpdateCode}
                    onFinish={data =>
                      handleUpdateBoleto(
                        selectedPayment?.bill?.id_bill,
                        data.code,
                      )
                    }
                    style={{ width: '100%' }}
                  >
                    <Form.Item
                      name="code"
                      initialValue={selectedPayment.bill.code}
                      rules={[
                        {
                          min: 12,
                          message: 'Deve ser maior ou igual 12 caracteres',
                        },
                        {
                          max: 48,
                          message: 'Deve ser menor ou igual 48 caracteres',
                        },
                      ]}
                    >
                      <Input.Search
                        enterButton="Salvar"
                        width={100}
                        disabled={
                          selectedPayment.bill.id_status !==
                            EnumBillStatus.FETCH_ERROR &&
                          selectedPayment.bill.id_status !==
                            EnumBillStatus.PAYMENT_ERROR
                        }
                        onSearch={() => formUpdateCode.submit()}
                        loading={loadingUpdate}
                      />
                    </Form.Item>
                  </Form>
                </PropertyList.Value>
              </PropertyList.Line>
              <PropertyList.Line>
                <PropertyList.Label>Tipo</PropertyList.Label>
                <PropertyList.Value>
                  {selectedPayment?.bill?.type}
                </PropertyList.Value>
              </PropertyList.Line>
              <PropertyList.Line>
                <PropertyList.Label>Emissor</PropertyList.Label>
                <PropertyList.Value>
                  {selectedPayment?.bill?.recipient_assignor}
                </PropertyList.Value>
              </PropertyList.Line>
              <PropertyList.Line>
                <PropertyList.Label>
                  Instituição de Pagamento
                </PropertyList.Label>
                <PropertyList.Value>
                  {selectedPayment?.bill?.settlement_institution}
                </PropertyList.Value>
              </PropertyList.Line>
            </PropertyList.Container>
          </>
        )}
      </Modal>

      <Modal
        visible={logsBoleto.modal}
        title="Logs do Boleto"
        footer={false}
        onCancel={() => setLogsBoleto({ ...logsBoleto, modal: false })}
        centered
      >
        <Timeline mode="left" reverse>
          {logsBoleto.logs as any}
        </Timeline>
      </Modal>
    </>
  );
};

export default CheckoutInfoPayments;
