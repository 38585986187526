import { Button, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { MdOpenInNew } from 'react-icons/md';
import { useHistory } from 'react-router-dom';

import PageHeader from '../../../components/PageHeader';
import { useUtils } from '../../../hooks/useUtils';

const ResponsabilidadePesquisar: React.FC = () => {
  const history = useHistory();
  const { getResponsabilidade } = useUtils();

  const [loading, setLoading] = useState(true);
  const [responsabilidades, setResponsabilidades] = useState<any[]>([]);

  useEffect(() => {
    fetchResponsabilidade();
  }, []);

  async function fetchResponsabilidade() {
    const responsabilidade_response = await getResponsabilidade();

    setLoading(false);
    setResponsabilidades(responsabilidade_response);
  }

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'nome',
      key: 'nome',
    },
    {
      title: 'Descrição',
      dataIndex: 'descricao',
      key: 'descricao',
    },
    {
      title: 'Editar',
      width: 100,
      render: (data: any) => (
        <Button type="primary" onClick={() => history.push(`/responsabilidade/${btoa(data.id_responsabilidade)}`)}>
          <MdOpenInNew />
        </Button>
      )
    },
  ];

  return (
    <>
      <PageHeader title="Responsabilidades" breadcrumb={['Responsabilidade']} />

      <Table
        columns={columns}
        dataSource={responsabilidades}
        loading={loading}
        pagination={false}
      />
    </>
  );
}

export default ResponsabilidadePesquisar;