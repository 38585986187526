import api from '../../../../services/api';
import { FiltersQueryRules } from "../Search";
import {
  BodyQueryRule,
  GetQueryRulesResponse,
  UpdateQueryRuleBody,
} from '../types';

export async function createGeneralQueryRule(search_rule: BodyQueryRule & { establishment_id?: string }) {
  const response = await api.post(`/backoffice/search-rule`, search_rule);

  return response.data;
}

export async function getGeneralQueryRules(params: FiltersQueryRules & { id_establishment?: string }) {
  const response = await api.get<GetQueryRulesResponse>(
    '/backoffice/search-rule',
    {
      params,
    },
  );

  return response.data;
}

export async function updateGeneralQueryRule(search_rule: UpdateQueryRuleBody) {
  const data: BodyQueryRule = {
    validation: search_rule.validation,
    action: search_rule.action,
    value: search_rule.value,
    type: search_rule.type,
    property: search_rule.property,
    description: search_rule.description,
  };

  const response = await api.put(
    `/backoffice/search-rule/${search_rule.id_search_rule}`,
    data,
  );

  return response.data;
}

export async function deleteGeneralQueryRule(id_search_rule: string) {
  await api.delete(`/backoffice/search-rule/${id_search_rule}`);
}
