import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Button, Row, Tooltip } from 'antd';

type IWhitelabelVisualizacao = {
  id_usuario: string;
};

const WhitelabelVisualizacao: React.FC<IWhitelabelVisualizacao> = ({
  id_usuario,
}: IWhitelabelVisualizacao) => {
  const srcPortal = useMemo(() => {
    return `${process.env.REACT_APP_URL_PORTAL}/payment_online/${id_usuario}`;
  }, [id_usuario]);

  return (
    <>
      <Row align="middle">
        <Button href={srcPortal} target="_blank">
          Preview
        </Button>
      </Row>

      <Tooltip placement="top" title="Clique para abrir no portal">
        <Iframe src={srcPortal} scrolling="no"></Iframe>
      </Tooltip>
    </>
  );
};

export default WhitelabelVisualizacao;

const Iframe = styled.iframe`
  margin-top: 20px;
  width: 100%;
  height: 500px;
  border: 0;
  pointer-events: none;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 0px 10px;
  border-radius: 7px;
  overflow: hidden;
`;
