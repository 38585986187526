import { Table } from 'antd';
import { dinheiroMask } from 'masks-br';
import React from 'react';

import CardCollapse from '../../../../components/CardCollapse';

type ICarrinhoInfoFormaPagamento = {
  loading: boolean;
  formas_pagamento?: any[];
};

const CarrinhoInfoFormaPagamento: React.FC<ICarrinhoInfoFormaPagamento> = ({
  loading,
  formas_pagamento,
}: ICarrinhoInfoFormaPagamento) => {
  const columns_forma_pagamento = [
    {
      title: 'Forma',
      dataIndex: 'forma_pagamento',
      key: 'forma_pagamento',
    },
    {
      title: 'Condição/Parcelas',
      dataIndex: 'condicao_pagamento',
      key: 'condicao_pagamento',
    },
    {
      title: 'Valor',
      dataIndex: 'valor_total',
      key: 'valor_total',
      render: (item: number) => dinheiroMask(item),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
  ];

  return (
    <>
      <CardCollapse title="Formas de Pagamento" loading={loading}>
        <Table
          columns={columns_forma_pagamento}
          dataSource={formas_pagamento || []}
          pagination={false}
          size="small"
        />
      </CardCollapse>
    </>
  );
};

export default CarrinhoInfoFormaPagamento;
