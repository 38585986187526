import {
  Button,
  Modal,
  Form,
  Row,
  Col,
  Input,
  Timeline,
  message,
  List,
  Divider,
  Tooltip,
  Typography,
} from 'antd';
import { format } from 'date-fns';
import { dinheiroMask } from 'masks-br';
import React, { useMemo, useState } from 'react';
import { MdList, MdAttachMoney, MdOpenInNew } from 'react-icons/md';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import CardCollapse from '../../../../components/CardCollapse';
import { useApi } from '../../../../hooks/useApi';
import { fetchApi } from '../../../../services/api';
import { getColor } from '../../index';
import { Important } from '../../../../components/Text';

import downloadFiles from '../../../../utils/downloadFiles';
import { GetPaymentsResponse, Payment, ResponseApiError } from './types';
import { EnumBillStatus, EnumPaymentStatus } from '../../../Boleto/types';

const { Paragraph, Text } = Typography;

type ICarrinhoInfoBoleto = {
  cartId: string;
  loading: boolean;
  setLoading: (val: boolean) => void;
  busca: any;
  estabelecimento: string;
};

export type IBoletoLog = {
  id_timeline: string;
  id_payment: string;
  id_status: string;
  description: string;
  created_by: string;
  created_at: string;
  status: Status;
};

export interface Status {
  id_status: string;
  name: string;
  type: string | EnumTipoLogBoleto;
  description: string;
}

type IPagadorBeneficiario = {
  nome: string;
};

type IBoletoLogForTable = {
  codigo_barras?: string;
  valor_boleto?: string;
  data_vencimento?: string;
  valor_pagamento?: string;
  data_pagamento?: string;
  pagador?: IPagadorBeneficiario;
  beneficiario?: IPagadorBeneficiario;
  valor_desconto?: string;
  valor_outros_descontos?: string;
  valor_outros_acrecimos?: string;
  valor_encargos?: string;
  linha_digitavel?: string;
  id_boleto?: string;
  valor_total?: string;
  id_usuario?: string;
  liquidacao_id?: string;
  digitable_code?: string;
  amount?: string;
  error?: string;
  message?: string;
};

export enum EnumTipoLogBoleto {
  SUCCESS = 'success',
  WARNING = 'warning',
  INFO = 'info',
  ERROR = 'error',
  WAITING = 'waiting',
}

type ILogs = {
  id_payment: string;
  modal: boolean;
  logs: IBoletoLog[];
};

type dataTableListLogBoleto = {
  title: string;
  data: string | IPagadorBeneficiario | JSX.Element | undefined;
};

type IPaymentBill = {
  id_payment: string;
  id_bill: string;
  status_payment: EnumPaymentStatus;
};

const CarrinhoInfoBoleto: React.FC<ICarrinhoInfoBoleto> = ({
  cartId,
  loading,
  setLoading,
  busca,
  estabelecimento,
}: ICarrinhoInfoBoleto) => {
  const { loading: loadingUpdate, fetchRequest } = useApi();
  const [loadingDetranSP, setLoadingDetranSP] = useState(false);
  const [idPaymentBill, setIdPaymentBill] = useState<IPaymentBill[]>(
    [] as IPaymentBill[],
  );

  const id_detran_sp = '34ca1eba-146d-4d11-8b05-ff5c24cd1120';

  async function getDebts() {
    return fetchApi<GetPaymentsResponse & ResponseApiError>({
      method: 'get',
      url: `/backoffice/checkout/${cartId}`,
    });
  }

  const { data, refetch: refetchList } = useQuery(['debits'], {
    queryFn: getDebts,
    refetchOnWindowFocus: false,
  });

  const boletos = useMemo(() => {
    return (
      data?.payments.map(boleto => {
        const criado_em = format(
          new Date(boleto.created_at),
          'dd/MM/yyyy HH:mm:ss',
        );

        return {
          ...boleto,
          criado_em,
          id_payment: boleto.id_payment,
          status_bill: boleto.bill.id_status,
          status_payment: boleto.id_status,
        };
      }) || []
    );
  }, [data?.payments]);

  const [logsBoleto, setLogsBoleto] = useState<ILogs>({} as ILogs);

  function handleFetchBoletoLogs(id_payment: string) {
    if (logsBoleto.id_payment === id_payment) {
      setLogsBoleto({
        ...logsBoleto,
        modal: true,
      });

      return '';
    }

    return fetchApi({
      url: `/backoffice/checkout/payment/${id_payment}/timeline`,
      method: 'get',
      onSuccess: data => {
        setLogsBoleto({
          modal: true,
          id_payment,
          logs: data.timeline.map((log: IBoletoLog) => {
            return (
              <Timeline.Item
                key={log.id_timeline}
                label={format(new Date(log.created_at), 'dd/MM/yyyy HH:mm:ss')}
                color={getColor(log.status.type.toLowerCase())}
                position="start"
              >
                <strong>{log.status.name}</strong>
                {log.status.id_status === EnumPaymentStatus.PAID_MANUALLY && (
                  <Paragraph copyable>
                    <strong>Liquidador por:</strong> {log.created_by}
                  </Paragraph>
                )}
              </Timeline.Item>
            );
          }),
        });
      },
    });
  }

  function handlePagarBoletoManualmente(id_payment: string) {
    const key = 'handlePagarBoletoManualmente';

    Modal.confirm({
      title: 'Tem certeza que deseja reprocessar esse pagamento?',
      content: 'Essa ação não poderá ser desfeita!',
      centered: true,
      onOk() {
        message.loading({
          content: 'Liquidando boleto manualmente, aguarde...',
          key,
          duration: 10000,
        });

        return fetchApi({
          url: `/backoffice/payment/${id_payment}/retry`,
          method: 'post',
          onSuccess: async data => {
            setLoading(true);

            if (data.success) {
              message.success({
                content: 'Boleto liquidado com sucesso!',
                key,
              });
              await refetchList();
            } else {
              message.error({
                content: 'Erro ao liquidar boleto, tente novamente!',
                key,
              });
            }

            setLoading(false);
          },
          onError: () => {
            setLoading(false);
            message.error({
              content: 'Erro ao liquidar boleto, tente novamente!',
              key,
            });
          },
        });
      },
    });
  }

  function handleUpdateBoleto(id_bill: string, code: string) {
    Modal.confirm({
      title: 'Tem certeza que deseja alterar o código do boleto?',
      content: 'Essa ação não poderá ser desfeita!',
      centered: true,
      onOk() {
        return fetchRequest({
          method: 'patch',
          url: `/backoffice/bill/${id_bill}/code`,
          data: {
            code,
          },
          messages: {
            loading: 'Atualizando código de boleto, aguarde...',
            error: 'Erro ao atualizar boleto, tente novamente!',
            success: 'Código do boleto atualizado com sucesso!',
          },
          onSuccess: async () => {
            await refetchList();
          },
        });
      },
    });
  }

  function handlePagarBoletoManualmenteDetranSP(id_boleto: string) {
    setLoadingDetranSP(true);
    return fetchApi({
      url: `/detran/salesoff/sp`,
      method: 'post',
      data: {
        id_boleto,
      },
      messages: {
        loading: 'Realizando pagamento de boleto Detran SP, aguarde..',
        error:
          'Erro ao realizar pagamento de boleto Detran SP, tente novamente!',
      },
      onSuccess: data => {
        const [boleto] = data;

        if (boleto.liquidado) {
          message.success({ content: 'Boleto liquidado com sucesso!' });

          setLoadingDetranSP(false);
        } else {
          message.error({
            content: 'Erro ao liquidar boleto, tente novamente!',
          });
          setLoadingDetranSP(false);
        }
      },
      onError: () => {
        message.error({
          content: 'Erro ao liquidar boleto, tente novamente!',
        });
        setLoadingDetranSP(false);
      },
    });
  }

  async function handleDownloadReceiptBoletoDetranSP(id_boleto: string) {
    await downloadFiles(
      `/detran/salesoff/sp/${id_boleto}`,
      `receipt-${id_boleto}.pdf`,
    );
  }

  function getNameSettlement(id: number) {
    switch (id) {
      case 1:
        return 'Bancária';
      case 2:
        return 'Arquivo Retorno';
    }
  }

  function findBoletoInfoButtons(boleto: Payment) {
    return [
      <Button key="1" onClick={() => handleFetchBoletoLogs(boleto.id_payment)}>
        <MdList />
      </Button>,
      <Button
        key="2"
        type="primary"
        onClick={() => handlePagarBoletoManualmente(boleto.id_payment)}
        disabled={
          ![
            EnumPaymentStatus.PAYMENT_ERROR,
            EnumPaymentStatus.WAITING_PAYMENT,
          ].includes(boleto.id_status as EnumPaymentStatus)
        }
        danger
      >
        <MdAttachMoney />
      </Button>,
      <Tooltip title="Abrir boleto">
        <Link className="ant-btn" to={`/boleto/${boleto.bill.id_bill}`}>
          <MdOpenInNew />
        </Link>
      </Tooltip>,
      // <Tooltip key="3" placement="topRight" title="Liquidar manualmente">
      //   <Button
      //     type="dashed"
      //     onClick={() =>
      //       handlePagarBoletoManualmenteDetranSP(boleto.bill.id_bill)
      //     }
      //     disabled={
      //       !(
      //         busca?.id_parceiro === id_detran_sp ||
      //         estabelecimento === 'DETRAN SP'
      //       )
      //     }
      //     danger
      //   >
      //     <FaCarSide />
      //   </Button>
      // </Tooltip>,
      // <Tooltip key="4" placement="topRight" title="Enviar Notificação">
      //   <Button
      //     type="primary"

      //     onClick={() =>
      //       handleEnviarNotificacaoManualmente(boleto.bill.id_bill)
      //     }
      //   >
      //     <MdNotifications />
      //   </Button>
      // </Tooltip>,
      // <Tooltip key="5" placement="topRight" title="Baixar comprovante">
      //   <Button
      //     type="dashed"
      //     loading={loadingDetranSP}
      //     onClick={() =>
      //       handleDownloadReceiptBoletoDetranSP(boleto.bill.id_bill)
      //     }
      //     disabled={
      //       !(
      //         busca?.id_parceiro === id_detran_sp ||
      //         estabelecimento === 'DETRAN SP'
      //       )
      //     }
      //   >
      //     <BsReceipt />
      //   </Button>
      // </Tooltip>,
    ];
  }

  return (
    <>
      <CardCollapse title="Débitos" loading={loading}>
        {boletos?.map(boleto => (
          <CardCollapse
            key={boleto.bill.id_bill}
            title="Informações do pagamento"
          >
            <Form layout="vertical" initialValues={boleto}>
              <Row gutter={16}>
                <Col md={24} xs={24}>
                  <Form.Item
                    label={
                      <Paragraph copyable={{ text: boleto.bill.code }}>
                        Código de Barras
                      </Paragraph>
                    }
                    name="code"
                    initialValue={boleto.bill.code}
                  >
                    <Input.Search
                      enterButton="Salvar"
                      disabled={
                        boleto.status_bill !== EnumBillStatus.FETCH_ERROR &&
                        boleto.status_bill !== EnumBillStatus.PAYMENT_ERROR
                      }
                      onSearch={cod_boleto =>
                        handleUpdateBoleto(boleto.bill.id_bill, cod_boleto)
                      }
                      loading={loadingUpdate}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                  <Form.Item label="Valor" name="valor">
                    <Important>{dinheiroMask(boleto.amount)}</Important>
                  </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                  <Form.Item label="Taxa" name="taxa">
                    <Important>{dinheiroMask(boleto.fee_amount)}</Important>
                  </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                  <Form.Item label="Total" name="valor_total">
                    <Important>{dinheiroMask(boleto.total_amount)}</Important>
                  </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                  <Form.Item label="Criado em" name="criado_em">
                    {boleto.criado_em}
                  </Form.Item>
                </Col>
              </Row>
            </Form>

            <br />

            <Divider orientation="left">Tipo de Liquidação</Divider>

            <List
              itemLayout="horizontal"
              dataSource={[boleto.bill]}
              renderItem={item => (
                <List.Item
                  key={item.id_bill}
                  actions={findBoletoInfoButtons(boleto)}
                >
                  <List.Item.Meta
                    title={
                      item.id_settlement_type ? (
                        <strong>
                          {getNameSettlement(item.id_settlement_type)}
                        </strong>
                      ) : (
                        <Text type="warning">Boleto não liquidado.</Text>
                      )
                    }
                    description={
                      <p>
                        {item.settled_at
                          ? format(
                              new Date(item.settled_at),
                              'dd/MM/yyyy HH:mm:ss',
                            )
                          : null}
                      </p>
                    }
                  />
                </List.Item>
              )}
            />
          </CardCollapse>
        ))}
      </CardCollapse>

      <Modal
        visible={logsBoleto.modal}
        title="Logs do Boleto"
        footer={false}
        onCancel={() => setLogsBoleto({ ...logsBoleto, modal: false })}
        centered
      >
        <Timeline mode="left">{logsBoleto.logs}</Timeline>
      </Modal>
    </>
  );
};

export default CarrinhoInfoBoleto;
