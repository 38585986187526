import { Select } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';

import { IState } from '../../store';
import TipoAcesso from '../../types/TipoAcesso';

import DashboardAdmin from './DashboardAdmin';
import DashboardParceiro from './DashboardParceiro';

export const time_default_admin = 'today';
export const time_default_parceiro = 'today';

export const time_options = (
  <>
    <Select.OptGroup label="Absoluto">
      <Select.Option value="today">Hoje</Select.Option>
      <Select.Option value="this_week">Essa Semana</Select.Option>
      <Select.Option value="this_month">Esse Mês</Select.Option>
      <Select.Option value="this_year">Esse Ano</Select.Option>
    </Select.OptGroup>
    <Select.OptGroup label="Outros">
      <Select.Option value="yesterday">Ontem</Select.Option>
      <Select.Option value="today_last_week">
        Hoje na semana passada
      </Select.Option>
      <Select.Option value="last_week">Semana Passada</Select.Option>
      <Select.Option value="last_month">Mês Passado</Select.Option>
      <Select.Option value="last_year">Ano Passado</Select.Option>
    </Select.OptGroup>
    <Select.OptGroup label="Relativo">
      <Select.Option value="last_7_days">Últimos 7 Dias</Select.Option>
      <Select.Option value="last_30_days">Últimos 30 Dias</Select.Option>
      <Select.Option value="last_6_months">Últimos 6 Meses</Select.Option>
    </Select.OptGroup>
  </>
);

export const time_options_partner = (
  <>
    <Select.OptGroup label="Absoluto">
      <Select.Option value="today">Hoje</Select.Option>
      <Select.Option value="this_week">Essa Semana</Select.Option>
      <Select.Option value="this_month">Esse Mês</Select.Option>
      <Select.Option value="this_year">Esse Ano</Select.Option>
    </Select.OptGroup>
    <Select.OptGroup label="Outros">
      <Select.Option value="yesterday">Ontem</Select.Option>
      <Select.Option value="last_week">Semana Passada</Select.Option>
      <Select.Option value="last_month">Mês Passado</Select.Option>
      <Select.Option value="last_year">Ano Passado</Select.Option>
    </Select.OptGroup>
  </>
);

const Dashboard: React.FC = () => {
  const tipo = useSelector<IState, TipoAcesso | undefined>(
    state => state.auth.tipo,
  );

  if (tipo === 'parceiro') {
    return <DashboardParceiro />;
  }

  return <DashboardAdmin />;
};

export default Dashboard;
