import React, { useState, useEffect, useMemo } from 'react';
import CountUp from 'react-countup';
import { Spin, Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import { dinheiroMask, numeroMask } from 'masks-br';

import { StyledCard } from './styles';

const { Text } = Typography;

export type TextType = 'success' | 'danger';
export type Status = 'success' | 'danger' | 'warning' | 'link';

export interface CardI {
  title: string | JSX.Element;
  description?: string;
  blur?: boolean;
  value?: number;
  delay?: number;
  prefix?: string;
  posfix?: string;
  money?: boolean;
  loading?: boolean;
  hover?: boolean;
  to?: string;
  toData?: any;
  children?: any;
  showValue?: boolean;
  onClick?: () => void;
  type?: TextType;
  fontSizeValue?: string;
  status?: Status;
}

type Value = {
  last: number;
  current: number;
};

export default function Card({
  title,
  value = 0,
  delay = 0.5,
  prefix = '',
  posfix = '',
  money = false,
  loading = false,
  showValue = true,
  hover = true,
  blur = false,
  fontSizeValue: fontSize,
  type,
  to,
  toData,
  children,
  onClick,
  description,
  status,
}: CardI): JSX.Element {
  const [componentValue, setComponentValue] = useState<Value>({
    current: 0,
    last: 0,
  });
  const history = useHistory();

  function onClickFn() {
    if (to) {
      history.push(to, toData);
    }

    if (onClick) {
      onClick();
    }
  }

  useEffect(() => {
    if (value !== componentValue.current) {
      setComponentValue({
        current: value,
        last: componentValue.current,
      });
    }
  }, [value]);

  const shadow_color = useMemo(() => {
    if (status === 'danger') return 'rgb(255, 77, 79)';
    if (status === 'success') return 'rgb(82, 196, 26)';
    if (status === 'warning') return 'rgb(250, 173, 20)';
    if (status === 'link') return '#5d9cec';

    return undefined;
  }, [status, to]);

  return (
    <Spin spinning={loading}>
      <StyledCard
        onClick={() => onClickFn()}
        clickable={!!to || !!onClick}
        hover={hover}
        blur={blur}
        fontSize={fontSize}
        shadow_color={shadow_color}
      >
        <div className="title">{title}</div>
        <div className="value">
          <small className="prefix">{prefix}</small>
          <Text type={type}>
            {showValue
              ? children || (
                  <CountUp
                    end={componentValue.current || 0}
                    duration={delay}
                    delay={0}
                    start={componentValue.last}
                    decimals={money ? 2 : 0}
                    formattingFn={(e: any) =>
                      money
                        ? dinheiroMask(e)
                        : numeroMask(e, { minimumFractionDigits: 0 })
                    }
                  />
                )
              : '-'}
          </Text>
          <small className="posfix">{posfix}</small>
        </div>

        {description ? <div className="description">{description}</div> : null}
      </StyledCard>
    </Spin>
  );
}
