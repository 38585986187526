import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Row,
  Select
} from 'antd';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { MdRemoveCircle } from 'react-icons/md';
import { cleanMask, cleanMoneyMask } from 'masks-br';
import { useHistory } from 'react-router-dom';
import CardCollapse from '../../../components/CardCollapse';
import PageHeader from '../../../components/PageHeader';
import { fetchApi } from '../../../services/api';
import { ItemInputCurrency } from '../../../components/Form';
import Option from '../../../types/Option';
import { default_message } from '.';

export const CarrinhoCriar: React.FC = () => {
  const [formasPgto, setFormasPgto] = useState<Option[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const history = useHistory();

  const origem: Option[] = [
    {
      label: 'POS',
      value: 'pos',
    },
    {
      label: 'ONLINE',
      value: 'online',
    },
  ];

  useEffect(() => {
    fetchCondicaoPagamento();
  }, []);

  function fetchCondicaoPagamento() {
    return fetchApi({
      url: '/condicao_pagamento',
      method: 'get',
      onSuccess: (data: any[]) => {
        setFormasPgto(
          data.map((item: any) => ({
            label: `${item.forma_pagamento} - ${item.qtde_parcelas}x`,
            value: `${item.id_condicao_pagamento}-${item.id_forma_pagamento}`,
          }))
        );
      },
    });
  }

  function handleCreateCard(data: any) {
    const boletos = data.boleto.map((boleto: any) => ({
      ...boleto,
      cod_boleto: cleanMask(boleto.cod_boleto),
    }));
    const forma_pagamento: any[] = data.forma_pagamento.map((item: any) => {
      const [id_condicao_pagamento, id_forma_pagamento] = item.id_forma_pagamento.split('-');

      const valor_total = cleanMoneyMask(item.valor);
      const taxa = cleanMoneyMask(item.taxa);

      const valor = Number((valor_total - taxa).toFixed(2));

      return {
        valor,
        valor_total,
        taxa,
        id_condicao_pagamento,
        id_forma_pagamento,
      };
    });

    const valor_total = forma_pagamento.reduce(
      (prev, item) => prev + item.valor_total,
      0
    );
    const valor = forma_pagamento.reduce((prev, item) => prev + item.valor, 0);
    const taxa = forma_pagamento.reduce((prev, item) => prev + item.taxa, 0);

    const finish = {
      carrinho: {
        criado_em: data.criado_em.toDate().toISOString(),
        origem: data.origem,
        valor,
        taxa,
        valor_total,
        id_usuario_vinculo: data.id_usuario_vinculo,
      },
      boletos,
      forma_pagamento,
    };

    console.log(finish);

    fetchCreateCard(finish);
  }

  async function fetchCreateCard(data: any) {
    setLoading(true);

    const response = await fetchApi({
      url: '/carrinho',
      method: 'post',
      data,
      messages: {
        error: 'Erro ao criar carrinho, tente novamente!',
        loading: 'Criando carrinho, aguarde...',
        success: 'Carrinho criado com sucesso!',
      },
    });

    setLoading(false);

    if (response) {
      return history.push('/carrinho');
    }

    return null;
  }

  return (
    <>
      <PageHeader
        title="Criar Carrinho Manualmente"
        breadcrumb={['Dashboard', 'Carrinho', 'Cadastrar']} />

      <CardCollapse>
        <Form
          layout="vertical"
          initialValues={{
            // calcular_comissao: true,
            calcular_comissao: false,
            criado_em: moment(),
            origem: 'pos',
            boleto: [{}],
            forma_pagamento: [{}],
          }}
          onFinish={handleCreateCard}
        >
          <Row gutter={16}>
            <Col md={8}>
              <Form.Item
                label="Data"
                name="criado_em"
                rules={[
                  {
                    required: true,
                    message: default_message,
                  },
                ]}
              >
                <DatePicker format="DD/MM/YYYY HH:mm" showTime />
              </Form.Item>
            </Col>
            {/* <Col md={8}>
              <Form.Item
                label="Taxa"
                name="taxa"
                rules={[
                  {
                    required: true,
                    message: default_message,
                  },
                ]}
              >
                <ItemInputCurrency />
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item
                label="Valor Total"
                name="valor_total"
                rules={[
                  {
                    required: true,
                    message: default_message,
                  },
                ]}
              >
                <ItemInputCurrency />
              </Form.Item>
            </Col> */}
            <Col md={8}>
              <Form.Item
                label="Origem"
                name="origem"
                rules={[
                  {
                    required: true,
                    message: default_message,
                  },
                ]}
              >
                <Select options={origem} />
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item label="Vinculo do Usuário" name="id_usuario_vinculo">
                <Input maxLength={36} />
              </Form.Item>
            </Col>
            {/* <Col md={4}>
              <Form.Item
                label=" "
                name="calcular_comissao"
                valuePropName="checked"
              >
                <Checkbox>Calcular Comissão</Checkbox>
              </Form.Item>
            </Col> */}
          </Row>

          <br />

          <Divider orientation="left">Débitos</Divider>

          <Row gutter={16}>
            <Form.List name="boleto">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }, index) => (
                    <>
                      <Col md={22}>
                        <Form.Item
                          {...restField}
                          label="Código de Barras"
                          name={[name, 'cod_boleto']}
                          fieldKey={[name, 'cod_boleto']}
                          rules={[
                            {
                              pattern: new RegExp('^[0-9]{44,48}$'),
                              required: true,
                              message: 'Código de barras inválido',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      {/* <Col md={4}>
                              <Form.Item
                                {...restField}
                                label="Valor"
                                name={[name, 'valor']}
                                fieldKey={[name, 'valor']}
                                rules={[
                                  {
                                    required: true,
                                    message: default_message,
                                  },
                                ]}
                              >
                                <ItemInputCurrency />
                              </Form.Item>
                            </Col>
                            <Col md={5}>
                              <Form.Item
                                {...restField}
                                label="Instituição de Liquidação"
                                name={[name, 'instituicao_liquidacao']}
                                fieldKey={[name, 'instituicao_liquidacao']}
                                rules={[
                                  {
                                    required: true,
                                    message: default_message,
                                  },
                                ]}
                              >
                                <Select options={instituicao_liquidacao} />
                              </Form.Item>
                            </Col>
                            <Col md={5}>
                              <Form.Item
                                {...restField}
                                label="Cod. Liquidação"
                                name={[name, 'cod_liquidacao']}
                                fieldKey={[name, 'cod_liquidacao']}
                                rules={[
                                  {
                                    required: true,
                                    message: default_message,
                                  },
                                ]}
                              >
                                <Input />
                              </Form.Item>
                            </Col> */}
                      <Col md={1}>
                        <Button
                          style={{ marginTop: 34 }}
                          onClick={() => remove(index)}
                          disabled={index === 0}
                        >
                          <MdRemoveCircle />
                        </Button>
                      </Col>
                    </>
                  ))}
                  <Col md={24}>
                    <Button
                      style={{ marginTop: 25 }}
                      block
                      onClick={() => add()}
                    >
                      Adicionar
                    </Button>
                  </Col>
                </>
              )}
            </Form.List>
          </Row>

          <br />

          <Divider orientation="left">Forma de Pagamento</Divider>

          <Row gutter={16}>
            <Form.List name="forma_pagamento">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }, index) => (
                    <>
                      <Col md={8}>
                        <Form.Item
                          label="Forma de Pagamento"
                          name={[name, 'id_forma_pagamento']}
                          fieldKey={[name, 'id_forma_pagamento']}
                          rules={[
                            {
                              required: true,
                              message: default_message,
                            },
                          ]}
                        >
                          <Select options={formasPgto} />
                        </Form.Item>
                      </Col>
                      <Col md={7}>
                        <Form.Item
                          {...restField}
                          label="Taxa"
                          name={[name, 'taxa']}
                          fieldKey={[name, 'taxa']}
                          rules={[
                            {
                              required: true,
                              message: default_message,
                            },
                          ]}
                        >
                          <ItemInputCurrency />
                        </Form.Item>
                      </Col>
                      <Col md={7}>
                        <Form.Item
                          {...restField}
                          label="Valor"
                          name={[name, 'valor']}
                          fieldKey={[name, 'valor']}
                          rules={[
                            {
                              required: true,
                              message: default_message,
                            },
                          ]}
                        >
                          <ItemInputCurrency />
                        </Form.Item>
                      </Col>
                      <Col md={1}>
                        <Button
                          style={{ marginTop: 34 }}
                          onClick={() => remove(index)}
                          disabled={index === 0}
                        >
                          <MdRemoveCircle />
                        </Button>
                      </Col>
                    </>
                  ))}
                  <Col md={24}>
                    <Button
                      style={{ marginTop: 25 }}
                      block
                      onClick={() => add()}
                    >
                      Adicionar
                    </Button>
                  </Col>
                </>
              )}
            </Form.List>
          </Row>

          <br />
          <br />

          <Row justify="end">
            <Button type="primary" htmlType="submit" loading={loading}>
              Cadastrar
            </Button>
          </Row>
        </Form>
      </CardCollapse>
    </>
  );
};
