import React from 'react';
import PageHeader from "../../../components/PageHeader";
import TerminalForm, { FormStoreSchema } from "../components/Form";
import { useMutation } from "react-query";
import { postTerminal } from "../services";
import { message } from "antd";
import { useHistory } from "react-router-dom";
import { TerminalErrorResponse, getTerminalErrorMessages } from "../services/types";
import { queryClientApp } from "../../../App";

const TerminalRegister: React.FC = () => {
  const history = useHistory()
  
  const { isLoading, mutate: registerTerminal } = useMutation({
    mutationFn: postTerminal,
    onSuccess(data) {
      queryClientApp.invalidateQueries(['terminal-search'])
      history.replace(`/terminal/${data.id_terminal}`)
    },
    onError(error) {
      const errorResponse = error as TerminalErrorResponse

      const errorText = getTerminalErrorMessages(errorResponse)

      message.error(errorText)
    },
  })

  function handleSubmit(data: FormStoreSchema) {
    registerTerminal(data)
  }

  return (
    <>
      <PageHeader 
        title="Cadastrar terminal"
        breadcrumb={['Terminais', 'Cadastrar']}
      />

      <TerminalForm loading={isLoading} onSubmit={handleSubmit} />
    </>
  )
}

export default TerminalRegister