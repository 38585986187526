import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Row,
  Space,
  Table,
} from 'antd';
import { celularMask, cleanMask, cpfMask, telefoneMask } from 'masks-br';
import React, { useEffect, useMemo, useState } from 'react';
import locale from 'antd/es/date-picker/locale/pt_BR';
import { format, parseISO } from 'date-fns';
import gerador from 'generate-password';
import Paragraph from 'antd/lib/typography/Paragraph';
import CardCollapse from '../../../../components/CardCollapse';
import PageHeader from '../../../../components/PageHeader';
import 'moment/locale/pt-br';
import { setQueryParams, useQueryParams } from '../../../../utils/UrlQuery';
import { fetchApi } from '../../../../services/api';
import EnumResponsabilidade from '../../../../types/enum/Responsabilidade';

const default_message = 'Esse campo é obrigatório';

export type IIncentivadorTJMA = {
  nome: string;
  cpf: string;
  telefone: string;
  email: string;
  validade: string;
};

interface IListIncentivadorTJMA {
  total: number;
  pagina: number;
  porPagina: number;
  data: IIncentivadorTJMA[];
}

const CadastroUsuario: React.FC = () => {
  const [formFilters] = Form.useForm();
  const [incentivadores, setIncentivadores] = useState(
    {} as IListIncentivadorTJMA,
  );
  const [filtros, setFiltros] = useState<any | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [usuarioIncentivador] = Form.useForm();
  const query = useQueryParams();

  function handleUpdateIncentivadorTjma(data: any) {
    setLoading(true);

    console.log({ data });

    data.type = 'fisica';
    data.permissions = [EnumResponsabilidade.INCENTIVADOR];

    data.user = {
      document: data.cpf ? cleanMask(data.cpf) : undefined,
      phone: data.telefone ? cleanMask(data.telefone) : undefined,
      email: data.email,
      password:
        data.senha ||
        gerador.generate({ uppercase: false, numbers: false, length: 8 }),
      name: data.nome,
      code: data.matricula,
      validity: data.validade,
    };

    return fetchApi({
      url: '/panel/users/tj',
      method: 'post',
      data,
      onSuccess: async () => {
        await fetchUsuarioIncentivadorTjma();
        message.success('Usuário cadastrado com sucesso.');
        usuarioIncentivador.resetFields();
        setLoading(false);
      },
      onError: error => {
        setLoading(false);
        message.error(error.message);
      },
    });
  }
  const senha_default = useMemo(() => {
    return gerador.generate({ uppercase: false, numbers: false, length: 8 });
  }, []);

  const handleSetFiltroValues = (data: any) => {
    data.documento = data.cpf ? cleanMask(data.cpf) : undefined;

    setFiltros({
      ...filtros,
      ...data,
    });
  };

  useEffect(() => {
    const final = {
      page: 1,
      page_size: 25,
      ...query,
    };

    setFiltros(final);
    formFilters.setFieldsValue(final);
  }, []);

  useEffect(() => {
    if (filtros) {
      fetchUsuarioIncentivadorTjma();
    }
  }, [filtros]);

  async function fetchUsuarioIncentivadorTjma() {
    setLoading(true);

    return fetchApi({
      url: 'panel/users/tj',
      method: 'get',
      query_params: {
        ...filtros,
      },
      onSuccess: (data: any) => {
        setLoading(false);
        setIncentivadores(data);
      },
      onError: () => {
        setLoading(false);
      },
    });
  }
  function handleResetForm() {
    setQueryParams({});
    formFilters.resetFields();
    setFiltros({
      page: 1,
      page_size: 25,
    });
    fetchUsuarioIncentivadorTjma();
  }

  const columns = [
    {
      title: '#',
      dataIndex: 'id_usuario',
      key: 'id_usuario',
      render: (item: string) => (
        <Paragraph copyable={{ tooltips: false }}>{item}</Paragraph>
      ),
    },
    {
      title: 'Nome',
      dataIndex: 'nome',
      key: 'nome',
    },
    {
      title: 'CPF',
      dataIndex: 'cpf',
      key: 'cpf',
      render: (item: string) => cpfMask(item),
    },
    {
      title: 'Telefone',
      dataIndex: 'telefone',
      key: 'telefone',
      render: (item: string) => telefoneMask(item),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Matricula',
      dataIndex: 'cod_usuario',
      key: 'cod_usuario',
    },
    {
      title: 'Validade',
      dataIndex: 'validade',
      key: 'validade',
      render: (item: string) =>
        item ? format(parseISO(item), 'dd/MM/yyyy HH:mm:ss') : '',
    },
    {
      title: 'Criado em',
      dataIndex: 'criado_em',
      key: 'criado_em',
      render: (item: string) =>
        item ? format(new Date(item), 'dd/MM/yyyy HH:mm:ss') : '',
    },
  ];

  return (
    <>
      <PageHeader
        title="Cadastro de Usuário"
        breadcrumb={['Cadastrar Usuario', 'Criação']}
      />

      <CardCollapse loading={loading} title="Cadastrar Usuário">
        <Form
          layout="vertical"
          form={usuarioIncentivador}
          onFinish={handleUpdateIncentivadorTjma}
          initialValues={{ senha: senha_default }}
        >
          <Row gutter={16}>
            <Col md={12} xs={24}>
              <Form.Item
                name="nome"
                label="Nome"
                rules={[{ required: true, message: default_message }]}
              >
                <Input maxLength={150} />
              </Form.Item>
            </Col>
            <Col md={6} xs={24}>
              <Form.Item
                name="cpf"
                label="CPF"
                rules={[{ required: true, message: default_message }]}
              >
                <Input
                  onChange={e =>
                    usuarioIncentivador.setFieldsValue({
                      cpf: cpfMask(e.target.value),
                    })
                  }
                />
              </Form.Item>
            </Col>
            <Col md={6} xs={24}>
              <Form.Item
                name="telefone"
                label="Telefone"
                rules={[{ required: true, message: default_message }]}
              >
                <Input
                  onChange={e =>
                    usuarioIncentivador.setFieldsValue({
                      telefone: celularMask(e.target.value),
                    })
                  }
                />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    type: 'email',
                    message: 'Insira um email válido',
                    required: true,
                  },
                ]}
              >
                <Input maxLength={150} />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item
                name="validade"
                label="Validade"
                rules={[{ required: true, message: default_message }]}
              >
                <DatePicker
                  placeholder=""
                  format="DD/MM/YYYY"
                  locale={locale}
                />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item
                name="matricula"
                label="Matrícula"
                rules={[{ required: true, message: default_message }]}
              >
                <Input maxLength={9} />
              </Form.Item>
            </Col>
          </Row>
          <br />
          <Row justify="end">
            <Button type="primary" htmlType="submit">
              Salvar
            </Button>
          </Row>
        </Form>
      </CardCollapse>
      <CardCollapse title="Filtros">
        <Form
          layout="vertical"
          onFinish={data => handleSetFiltroValues(data)}
          form={formFilters}
        >
          <Row gutter={16}>
            <Col md={6}>
              <Form.Item name="nome" label="Nome">
                <Input />
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item name="email" label="Email">
                <Input />
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item name="documento" label="CPF">
                <Input onChange={val => cpfMask(val.target.value)} />
              </Form.Item>
            </Col>
            <Col md={6} xs={24}>
              <Form.Item name="matricula" label="Matrícula">
                <Input maxLength={150} />
              </Form.Item>
            </Col>
          </Row>

          <br />

          <Row justify="end">
            <Space>
              <Button onClick={() => handleResetForm()}>Limpar</Button>
              <Button type="primary" htmlType="submit">
                Filtrar
              </Button>
            </Space>
          </Row>
        </Form>
      </CardCollapse>
      <Table
        columns={columns}
        dataSource={incentivadores.data}
        loading={loading}
        pagination={{
          total: incentivadores.total,
          current: incentivadores.pagina,
          pageSize: incentivadores.porPagina,
          onChange: (page, page_size) =>
            handleSetFiltroValues({ page, page_size }),
        }}
      />
    </>
  );
};

export default CadastroUsuario;
