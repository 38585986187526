import styled from 'styled-components';

export const Container = styled.div<any>`
  width: 100%;
`;
type ILine = {
  directions?: 'row' | 'column';
};
export const Line = styled.div<ILine>`
  display: flex;
  padding: 4px 0;
  flex-direction: ${props => props.directions || 'row'};
  word-break: break-all;
`;
export const Label = styled.div<any>`
  padding-right: 5px;
  width: 30%;
  color: rgb(104, 115, 133);
  display: flex;
  align-items: center;
`;
export const Value = styled.div<any>`
  align-items: center;
  width: 70%;
  display: flex;
  gap: 5px;

  .ant-typography {
    margin-bottom: 0;
  }
`;
