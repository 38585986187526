import React, { useState } from 'react';
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Popconfirm,
  Result,
  Row,
  Table as TableAntd,
} from 'antd';
import { Link } from 'react-router-dom';
import { MdRemoveCircle } from 'react-icons/md';

import CardCollapse from '../../../../components/CardCollapse';
import { ItemInputCurrency } from '../../../../components/Form';
import { fetchApi } from '../../../../services/api';
import { usePermission } from '../../../../hooks/usePermissions';

type IState = {
  loading: boolean;
  default_message: string;
  id_usuario: string;
  pos: any[];
  onAddPOS: () => void;
  onRemovePOS: () => void;
};

const CardPOS: React.FC<IState> = ({
  loading,
  default_message,
  pos,
  id_usuario,
  onAddPOS,
  onRemovePOS,
}: IState) => {
  const [posForm] = Form.useForm();
  const { userPermissions } = usePermission();

  const [modalPOS, setModalPOS] = useState(false);

  function handleAddPOS(data: any) {
    return fetchApi({
      url: `/usuario/${id_usuario}/ponto_venda`,
      method: 'post',
      data,
      onSuccess: () => {
        setModalPOS(false);
        onAddPOS();
      },
    });
  }

  function handleRemovePOS(id_estabelecimento_ponto_venda: string) {
    return fetchApi({
      url: `/usuario/ponto_venda/${id_estabelecimento_ponto_venda}`,
      method: 'delete',
      onSuccess: () => onRemovePOS(),
    });
  }

  const columns_pos = [
    {
      title: 'Modelo',
      dataIndex: 'modelo',
      key: 'modelo',
    },
    {
      title: 'Logic Number',
      dataIndex: 'logic_number',
      key: 'logic_number',
      render: (item: string, data: any) => (
        <Link to={`/pos/${data.id_ponto_venda}/editar`}>{item}</Link>
      ),
    },
    {
      title: 'Serial Number',
      dataIndex: 'serial_number',
      key: 'serial_number',
      render: (item: string, data: any) => (
        <Link to={`/pos/${data.id_ponto_venda}/editar`}>{item}</Link>
      ),
    },
    {
      title: 'Criado Em',
      dataIndex: 'criado_em',
      key: 'criado_em',
    },
    {
      title: 'Remover',
      width: 100,
      render: (data: any) => (
        <Popconfirm
          placement="topRight"
          title="Tem certeza que deseja remover essa associação?"
          onConfirm={() => handleRemovePOS(data.id_estabelecimento_ponto_venda)}
          disabled={!userPermissions.POS_DESVINCULAR_POS}
        >
          <Button
            type="primary"
            danger
            disabled={!userPermissions.POS_DESVINCULAR_POS}
          >
            <MdRemoveCircle />
          </Button>
        </Popconfirm>
      ),
    },
  ];

  return (
    <>
      <CardCollapse loading={loading} title="Máquinas Associadas">
        {userPermissions.POS_VINCULAR_POS ? (
          <>
            <Row justify="end">
              <Button
                type="primary"
                htmlType="submit"
                onClick={() => setModalPOS(true)}
              >
                Adicionar POS
              </Button>
            </Row>

            <br />
          </>
        ) : null}

        {pos?.length ? (
          <TableAntd
            columns={columns_pos}
            dataSource={pos}
            pagination={false}
          />
        ) : (
          <Result extra="Usuário não possui nenhuma maquininha vinculada" />
        )}
      </CardCollapse>

      <Modal
        title="Associar POS"
        centered
        footer={false}
        visible={modalPOS}
        onCancel={() => setModalPOS(false)}
      >
        <Form
          layout="vertical"
          form={posForm}
          initialValues={{
            valor_seguro: 0,
            valor_perda: 0,
            valor_aluguel: 0,
            valor_aluguel_promocao: 0,
          }}
          onFinish={handleAddPOS}
        >
          <Row gutter={16}>
            <Col md={24}>
              <Form.Item
                name="ponto_venda"
                label="Id ou Logic Number do POS"
                rules={[{ required: true, message: default_message }]}
              >
                <Input maxLength={36} />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                name="valor_seguro"
                label="Valor Seguro"
                rules={[{ required: true, message: default_message }]}
              >
                <ItemInputCurrency
                  onChange={(_, val) =>
                    posForm.setFieldsValue({ valor_seguro: val })
                  }
                />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                name="valor_perda"
                label="Valor Perda"
                rules={[{ required: true, message: default_message }]}
              >
                <ItemInputCurrency
                  onChange={(_, val) =>
                    posForm.setFieldsValue({ valor_perda: val })
                  }
                />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                name="valor_aluguel"
                label="Valor Aluguel"
                rules={[{ required: true, message: default_message }]}
              >
                <ItemInputCurrency
                  onChange={(_, val) =>
                    posForm.setFieldsValue({ valor_aluguel: val })
                  }
                />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                name="valor_aluguel_promocao"
                label="Valor Aluguel Promoção"
              >
                <ItemInputCurrency
                  onChange={(_, val) =>
                    posForm.setFieldsValue({ valor_aluguel_promocao: val })
                  }
                />
              </Form.Item>
            </Col>
            <Col md={24}>
              <Form.Item
                label="Código de Rastreio do Correio"
                name="cod_rastreio"
                extra="Aqui é o único lugar para colocar o Código de Rastreio do objeto!"
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <br />

          <Row justify="end">
            <Button
              type="primary"
              htmlType="submit"
              className="buttonMarginTop"
            >
              Adicionar POS
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default CardPOS;
