import { Col, Row } from 'antd';
import React from 'react';

import Card from '../../../../components/Card';

type IInfoDashboard = {
  billingRevenue: number;
  commission: number;
  loading: boolean;
};

const InfoDashboard: React.FC<IInfoDashboard> = ({
  commission,
  billingRevenue,
  loading,
}: IInfoDashboard) => {
  return (
    <>
      <Row gutter={16} style={{ marginTop: 20 }}>
        <Col md={12}>
          <Card
            title="Receita"
            description="Últimos 30 dias"
            money
            value={billingRevenue}
            fontSizeValue="24px"
            loading={loading}
          />
        </Col>
        <Col md={12}>
          <Card
            title="Comissão"
            description="Últimos 30 dias"
            money
            value={commission}
            fontSizeValue="24px"
            loading={loading}
          />
        </Col>
      </Row>
    </>
  );
};

export default InfoDashboard;
