import React, { useMemo } from 'react';
import { Table as AntdTable, Empty, Row } from 'antd';
import { TableProps, TablePaginationConfig } from 'antd/lib/table';
import { numeroMask } from 'masks-br';

interface TableRequest<RecordType extends object = any>
  extends TableProps<RecordType> {
  total?: number;
  emptyText?: string;
  children?: any;
}

function Table({
  pagination,
  total,
  children,
  emptyText = 'Nenhum valor encontrado',
  ...rest
}: TableRequest): JSX.Element {
  const pagination_data = useMemo<TablePaginationConfig>(() => {
    return pagination
      ? {
          ...pagination,
          pageSizeOptions: pagination.pageSizeOptions || [25, 35, 50],
          showSizeChanger: pagination.showSizeChanger || true,
          defaultPageSize: pagination.defaultPageSize || 25,
        }
      : {};
  }, [pagination]);

  const result_text = useMemo(() => {
    if ((pagination && String(pagination.total)) || typeof total === 'number') {
      const number_result = numeroMask(
        Number((pagination && pagination?.total) || total),
        {
          minimumFractionDigits: 0,
        },
      );

      return <b>Número de resultados: {number_result}</b>;
    }

    return null;
  }, [pagination]);

  return (
    <>
      <Row justify="space-between" align="middle">
        {result_text}
        {children}
      </Row>

      <br />

      <AntdTable
        {...rest}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={emptyText}
            />
          ),
          // emptyText: <Result title={emptyText} />,
        }}
        pagination={pagination_data}
      />
    </>
  );
}

export default Table;
