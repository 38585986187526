import React, { useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import { Col, Row, DatePicker } from 'antd';
import ApexChart from 'react-apexcharts';
import { dinheiroMask } from 'masks-br';
import { ApexOptions } from 'apexcharts';

import PageHeader from '../../../components/PageHeader';

import CardCollapse from '../../../components/CardCollapse';
import cores from '../../../styles/colors';
import { useApi } from '../../../hooks/useApi';

type Filtro = {
  data: string;
  data_ref: Moment;
};
type DataInfo = {
  mes: any;
  condicao_pagamento: any;
  condicao_forma_pagamento: any;
  forma_pagamento_pct: any;
};

const RelatorioFaturamento: React.FC = () => {
  const [filtros, setFiltros] = useState<Filtro>(() => {
    const data = moment();

    return {
      data_ref: data,
      data: data.format('YYYY'),
    };
  });
  const [dataInfo, setDataInfo] = useState<DataInfo>({
    condicao_forma_pagamento: {},
  } as DataInfo);

  const { fetchRequest, loading } = useApi();

  useEffect(() => {
    fetchData();
  }, [filtros]);

  function fetchData() {
    return fetchRequest({
      method: 'get',
      url: '/relatorio/faturamento',
      query_params: {
        ano: filtros.data,
      },
      onSuccess: data => {
        setDataInfo(data);
      },
    });
  }

  function handleUpdate(data: any) {
    const date = data.format('YYYY');

    setFiltros({
      data_ref: data,
      data: date,
    });
  }

  const chart_option_area: ApexOptions = {
    chart: {
      height: 250,
      width: '100%',
      type: 'area',
      group: 'dashboard',
      zoom: {
        enabled: true,
      },
      toolbar: {
        show: true,
        tools: {
          download: false,
          selection: false,
          zoom: true,
          zoomin: false,
          zoomout: false,
          pan: false,
        },
      },
      animations: {
        enabled: true,
        easing: 'linear',
      },
    },
    dataLabels: {
      formatter: (val: any) => {
        return dinheiroMask(val);
      },
    },
    colors: [cores.primary],
    stroke: {
      curve: 'smooth',
    },
    tooltip: {
      x: {
        format: 'yyyy/MM',
      },
    },
    xaxis: {
      // show: false,
      tooltip: {
        enabled: false,
      },
    },
    legend: {
      show: false,
    },
    yaxis: {
      show: false,
      labels: {
        show: false,
        formatter: (val: number) => dinheiroMask(val),
      },
    },
  };

  const chart_option_bar: ApexOptions = {
    chart: {
      type: 'bar',
      height: 500,
      stacked: true,
      toolbar: {
        show: true,
        tools: {
          download: false,
          selection: false,
          zoom: true,
          zoomin: false,
          zoomout: false,
          pan: false,
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    stroke: {
      width: 0,
      colors: ['#fff'],
    },
    dataLabels: {
      formatter: (val: any) => {
        return dinheiroMask(val);
      },
    },
    xaxis: {
      categories: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
      ],
      labels: {
        formatter: (val: any) => {
          return dinheiroMask(val);
        },
      },
    },
    yaxis: {
      title: {
        text: undefined,
      },
    },
    tooltip: {
      y: {
        formatter: (val: any) => {
          return dinheiroMask(val);
        },
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      offsetX: 40,
    },
  };

  return (
    <>
      <PageHeader
        title="Relatório de Faturamento"
        breadcrumb={['Relatório', 'Faturamento']}
      >
        <DatePicker
          onChange={handleUpdate}
          picker="year"
          placeholder="Selecione o Ano"
          value={filtros.data_ref}
        />
      </PageHeader>

      <Row gutter={16}>
        <Col md={24}>
          <CardCollapse title="Faturamento por Mes" loading={loading}>
            <ApexChart
              options={{
                ...chart_option_area,
                xaxis: {
                  type: 'datetime',
                  categories: dataInfo.mes?.categories || [],
                },
              }}
              series={dataInfo.mes?.values || []}
              type="area"
              height={250}
            />
          </CardCollapse>
        </Col>
        <Col md={24}>
          <CardCollapse
            title="Faturamento por Condição Pagamento"
            loading={loading}
          >
            <ApexChart
              options={{
                ...chart_option_bar,
                xaxis: {
                  categories: dataInfo.condicao_pagamento?.categories || [],
                  labels: {
                    formatter: (val: any) => {
                      return dinheiroMask(val);
                    },
                  },
                },
              }}
              series={dataInfo.condicao_pagamento?.values || []}
              type="bar"
              height={250}
            />
          </CardCollapse>
        </Col>
        <Col md={24}>
          <CardCollapse
            title="Faturamento por Condição e Forma de Pagamento"
            loading={loading}
          >
            <ApexChart
              options={{
                ...chart_option_bar,
                xaxis: {
                  categories:
                    dataInfo.condicao_forma_pagamento?.categories || [],
                  labels: {
                    formatter: (val: any) => {
                      return dinheiroMask(val);
                    },
                  },
                },
              }}
              series={dataInfo.condicao_forma_pagamento?.values || []}
              type="bar"
              height={250}
            />
          </CardCollapse>
        </Col>
        <Col md={24}>
          <CardCollapse title="Forma de Pagamento %" loading={loading}>
            <ApexChart
              options={{
                chart: {
                  type: 'pie',
                },
                labels: dataInfo.forma_pagamento_pct?.categories || [],
                tooltip: {
                  y: {
                    formatter: (val: any) => {
                      return dinheiroMask(val);
                    },
                  },
                },
                responsive: [
                  {
                    breakpoint: 480,
                    options: {
                      chart: {
                        width: 200,
                      },
                      legend: {
                        position: 'bottom',
                      },
                    },
                  },
                ],
              }}
              series={dataInfo.forma_pagamento_pct?.values || []}
              type="pie"
              height={250}
            />
          </CardCollapse>
        </Col>
      </Row>
    </>
  );
};

export default RelatorioFaturamento;
