import { cnpjMask, cpfMask } from 'masks-br';
import React, { useMemo } from 'react';
import { Divider, Empty } from 'antd';

import CardCollapse from '../../../../components/CardCollapse';
import PropertyList from '../../../../components/PropertyList';
import { DataBoletoRaw } from '../../types';

type IProps = {
  data: DataBoletoRaw;
  loading: boolean;
};

const BoletoInfoData: React.FC<IProps> = ({ data, loading }: IProps) => {
  function formatDocument(doc?: string) {
    if (doc?.length === 11) return cpfMask(doc);
    return cnpjMask(doc || '');
  }

  const has_data: boolean = useMemo(() => {
    return (
      !!data?.recipient_assignor ||
      !!data?.recipient_name ||
      !!data?.recipient_document ||
      !!data?.payer_name ||
      !!data?.payer_document
    );
  }, [data]);

  return (
    <CardCollapse title="Dados" loading={loading}>
      {!has_data && (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="Boleto sem informações"
        />
      )}

      {data?.code && (
        <PropertyList.Container>
          <PropertyList.Line>
            <PropertyList.Label>Código de barras</PropertyList.Label>
            <PropertyList.Value>{data?.code}</PropertyList.Value>
          </PropertyList.Line>
        </PropertyList.Container>
      )}
      {data?.recipient_assignor && (
        <PropertyList.Container>
          <PropertyList.Line>
            <PropertyList.Label>Emissor</PropertyList.Label>
            <PropertyList.Value>{data?.recipient_assignor}</PropertyList.Value>
          </PropertyList.Line>
        </PropertyList.Container>
      )}
      {data?.recipient_name && data?.recipient_document && (
        <>
          <Divider orientation="left">Recebedor</Divider>

          <PropertyList.Container>
            <PropertyList.Line>
              <PropertyList.Label>Nome</PropertyList.Label>
              <PropertyList.Value>{data?.recipient_name}</PropertyList.Value>
            </PropertyList.Line>
            <PropertyList.Line>
              <PropertyList.Label>Documento</PropertyList.Label>
              <PropertyList.Value>
                {formatDocument(data?.recipient_document)}
              </PropertyList.Value>
            </PropertyList.Line>
          </PropertyList.Container>
        </>
      )}
      {data?.recipient_name && data?.recipient_document && (
        <>
          <Divider orientation="left">Pagador</Divider>

          <PropertyList.Container>
            <PropertyList.Line>
              <PropertyList.Label>Nome</PropertyList.Label>
              <PropertyList.Value>{data?.payer_name}</PropertyList.Value>
            </PropertyList.Line>
            <PropertyList.Line>
              <PropertyList.Label>Documento</PropertyList.Label>
              <PropertyList.Value>
                {formatDocument(data?.payer_document)}
              </PropertyList.Value>
            </PropertyList.Line>
          </PropertyList.Container>
        </>
      )}
    </CardCollapse>
  );
};

export default BoletoInfoData;
