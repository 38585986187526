import React, { useState } from 'react';
import PageHeader from '../../../components/PageHeader';
import CardCollapse from '../../../components/CardCollapse';
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Typography,
  message,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import {
  Terminal,
  terminalStatus,
  EnumTerminalStatus,
  EnumTerminalType,
} from '../services/types';
import { useQuery } from 'react-query';
import { getTerminals } from '../services';
import { Link } from 'react-router-dom';
import { MdOpenInNew } from 'react-icons/md';
import { setQueryParams, useQueryParams } from '../../../utils/UrlQuery';
import Table from '../../../components/Table';
import UserName from '../../../components/User/Name';
import { format } from 'date-fns';

const { Paragraph } = Typography;
const defaultPaginationFilter = { page: 1, page_size: 25 };

type Filters = {
  serial_number?: string;
  model?: string;
  type?: EnumTerminalType;
  status?: EnumTerminalStatus;
};

const TerminalSearch: React.FC = () => {
  const [form] = Form.useForm();
  const query_values = useQueryParams();
  const [filters, setFilters] = useState<any>({
    ...defaultPaginationFilter,
    ...query_values,
  });

  const statusOptions = Object.entries(terminalStatus).map(option => ({
    value: option[0],
    label: option[1],
  }));
  const typeOptions = Object.values(EnumTerminalType).map(value => ({ value }));

  const { isLoading, data: terminals } = useQuery({
    queryKey: ['terminal-search', filters],
    queryFn: () => getTerminals(filters),
    staleTime: 2 * 60 * 1000,
    retry: false,
    enabled: !!filters,
    onError(err: any) {
      message.error(err?.message);
    },
  });

  function handleResetForm() {
    setQueryParams(defaultPaginationFilter);
    form.resetFields();
    setFilters({
      page: 1,
      page_size: 10,
    });
  }

  function handleSetFiltersValues(nextFilters: Filters) {
    let final: any = {
      ...filters,
      ...nextFilters,
    };

    setQueryParams(final);
    setFilters(final);
  }

  function handleSetFiltersValuesPagination(page: number, page_size: number) {
    let final: any = {
      ...filters,
      page,
      page_size,
    };

    setQueryParams(final);
    setFilters(final);
  }

  const columns: ColumnsType<Terminal> = [
    {
      title: 'ID',
      dataIndex: 'id_terminal',
      render: value => (
        <Paragraph copyable={{ text: value }} ellipsis>
          {value}
        </Paragraph>
      ),
      align: 'center',
      width: 120,
    },
    {
      title: 'Tipo',
      dataIndex: 'type',
      align: 'center',
      width: 120,
    },
    {
      title: 'Modelo',
      dataIndex: 'model',
      render: value => (
        <Paragraph copyable ellipsis>
          {value}
        </Paragraph>
      ),
      align: 'center',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render(value: EnumTerminalStatus) {
        return terminalStatus[value];
      },
      align: 'center',
      width: 100,
    },
    {
      title: 'Serial Number',
      dataIndex: 'serial_number',
      render: value => (
        <Paragraph copyable ellipsis>
          {value}
        </Paragraph>
      ),
      align: 'center',
    },
    {
      title: 'Logic Number',
      dataIndex: 'logic_number',
      render: value => (
        <Paragraph copyable ellipsis>
          {value}
        </Paragraph>
      ),
      align: 'center',
    },
    {
      title: 'Responsável',
      dataIndex: 'user_id',
      render: value => {
        if (value) return <UserName id_user={value} />;

        return <UserName name="Sem responsável" />;
      },
      align: 'center',
    },
    {
      title: 'Criado por',
      dataIndex: 'created_by',
      render: value => <UserName id_user={value} />,
      align: 'center',
    },
    {
      title: 'Criado em',
      align: 'center',
      key: 'created_at',
      dataIndex: 'created_at',
      render: value => {
        const createdAt = format(new Date(value), 'dd/MM/yyyy HH:mm:ss');

        return createdAt;
      },
      width: 180,
    },
    {
      title: 'Ver',
      render: value => (
        <Link
          to={`/terminal/${value.id_terminal}`}
          className="ant-btn ant-btn-primary"
        >
          <MdOpenInNew />
        </Link>
      ),
      width: 70,
      fixed: 'right',
    },
    {
      title: 'Editar',
      render: value => (
        <Link
          to={`/terminal/${value.id_terminal}/edit`}
          className="ant-btn ant-btn-primary"
        >
          <MdOpenInNew />
        </Link>
      ),
      width: 70,
      fixed: 'right',
    },
  ];

  return (
    <>
      <PageHeader title="Terminais" breadcrumb={['Terminais']}>
        <Link className="ant-btn ant-btn-primary" to="/terminal/new">
          Cadastrar Terminal
        </Link>
      </PageHeader>

      <CardCollapse title="Filtros">
        <Form
          layout="vertical"
          onFinish={data => handleSetFiltersValues(data)}
          form={form}
        >
          <Row gutter={16}>
            <Col md={5} xs={12}>
              <Form.Item name="serial_number" label="Serial Number">
                <Input placeholder="Ex: 1170758865" />
              </Form.Item>
            </Col>
            <Col md={5} xs={12}>
              <Form.Item name="model" label="Modelo">
                <Input placeholder="Ex: Moderninha Smart" />
              </Form.Item>
            </Col>
            <Col md={6} xs={8}>
              <Form.Item name="type" label="Tipo">
                <Select options={typeOptions} placeholder="Tipo de terminal" />
              </Form.Item>
            </Col>
            <Col md={6} xs={8}>
              <Form.Item name="status" label="Status">
                <Select
                  options={statusOptions}
                  placeholder="Status do terminal"
                />
              </Form.Item>
            </Col>
          </Row>

          <br />

          <Row justify="end">
            <Button onClick={handleResetForm} className="buttonMarginRight">
              Limpar
            </Button>
            <Button type="primary" htmlType="submit">
              Filtrar
            </Button>
          </Row>
        </Form>
      </CardCollapse>

      <Table
        loading={isLoading}
        columns={columns}
        dataSource={terminals?.data}
        scroll={{ x: 1500 }}
        pagination={{
          current: terminals?.page,
          pageSize: terminals?.page_size,
          total: terminals?.total,
          onChange: handleSetFiltersValuesPagination,
        }}
        emptyText="Não há dados para exibir!"
      />
    </>
  );
};

export default TerminalSearch;
