import React from 'react';
import { Typography } from 'antd';
import { Link } from "react-router-dom";

const { Paragraph } = Typography

type LinkCopyableProps = {
  copyableText: string;
  to: string;
  children: string;
}

export default function LinkCopyable({ copyableText, to, children }: LinkCopyableProps) {
  return (
    <Paragraph
      copyable={{ tooltips: false, text: copyableText }}
    >
      <Link
        to={to}
        className="ant-link"
      >
        {children}
      </Link>
    </Paragraph>
  )
}
