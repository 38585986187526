import React, { useEffect, useState } from 'react';
import { Button, Table, Tag } from 'antd';
import { MdOpenInNew } from 'react-icons/md';
import { Link } from 'react-router-dom';

import PageHeader from '../../../components/PageHeader';
import { useUtils } from '../../../hooks/useUtils';
import { Perfil } from '../../../store/modules/utils/types';
import { usePermission } from '../../../hooks/usePermissions';

const PerfilPesquisar: React.FC = () => {
  const { getPerfil } = useUtils();
  const { userPermissions } = usePermission();

  const [perfis, setPerfis] = useState<Perfil[]>([]);

  useEffect(() => {
    getPerfil().then(perfis_list => {
      setPerfis(perfis_list);
    });
  }, []);

  const columns = [
    {
      title: 'Perfil',
      dataIndex: 'nome',
      key: 'nome',
    },
    {
      title: 'Tipo',
      dataIndex: 'tipo',
      key: 'tipo',
      render: (tipo: string) => <Tag>{tipo?.toUpperCase()}</Tag>,
    },
    {
      title: 'Descrição',
      dataIndex: 'descricao',
      key: 'descricao',
    },
    {
      title: 'Abrir',
      dataIndex: 'id_perfil',
      key: 'id_perfil',
      width: 100,
      render: (id_perfil: string) =>
        userPermissions.PERFIL_EDITAR ? (
          <Link className="ant-btn ant-btn-primary" to={`/perfil/${id_perfil}`}>
            <MdOpenInNew />
          </Link>
        ) : (
          <Button type="primary" disabled>
            <MdOpenInNew />
          </Button>
        ),
    },
  ];

  return (
    <>
      <PageHeader title="Perfis" breadcrumb={['Perfil']}>
        {userPermissions.PERFIL_CADASTRAR ? (
          <Link className="ant-btn ant-btn-primary" to="/perfil/new">
            Cadastrar novo perfil
          </Link>
        ) : null}
      </PageHeader>

      <Table columns={columns} dataSource={perfis} />
    </>
  );
};

export default PerfilPesquisar;
