import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Badge, Dropdown, Menu } from 'antd';
import { useSelector } from 'react-redux';

import { MenuItem } from '../styles';
import { IState } from '../../../store';
import { usePermission } from '../../../hooks/usePermissions';

export default function MenuAdmin(): JSX.Element {
  const qtdePermissoesPendentes = useSelector<IState, number>(
    state => state.utils.qtdePermissoesPendentes,
  );
  const qtdeVinculosPendentes = useSelector<IState, number>(
    state => state.utils.qtdeVinculosPendentes,
  );

  const { userPermissions } = usePermission();

  const PERMISSION_ADMINISTRACAO_GERAL =
    userPermissions.ADMINISTRACAO_GERAL_FORMAS_CONDICAO_PAGAMENTO ||
    userPermissions.ADMINISTRACAO_GERAL_RESPONSABILIDADES ||
    userPermissions.ADMINISTRACAO_GERAL_PERFIS ||
    userPermissions.ADMINISTRACAO_GERAL_SIMULADOR;

  const PERMISSION_ADMINISTRACAO_LOGISTICA =
    userPermissions.ADMINISTRACAO_LOGISTICA_POS;

  const PERMISSION_ADMINISTRACAO_TI =
    userPermissions.ADMINISTRACAO_TI_CLIENTE_API ||
    userPermissions.ADMINISTRACAO_TI_CLIENTE_API ||
    userPermissions.ADMINISTRACAO_TI_PARCEIROS;

  const PERMISSION_ADMINISTRACAO_RELATORIOS =
    userPermissions.ADMINISTRACAO_RELATORIOS_GERAL ||
    userPermissions.ADMINISTRACAO_RELATORIOS_FATURAMENTO ||
    userPermissions.ADMINISTRACAO_RELATORIOS_ARQUIVO_RETORNO ||
    userPermissions.ADMINISTRACAO_RELATORIOS_DIVERGENCIA_ADQUIRENCIA;

  const PERMISSION_ADMINISTRACAO_TOTEM =
    userPermissions.ADMINISTRACAO_TOTEM_USUARIOS;

  const menu_administracao = useMemo(() => {
    return () => (
      <Menu>
        {PERMISSION_ADMINISTRACAO_GERAL ? (
          <Menu.ItemGroup key="g1" title="Geral">
            {userPermissions.FORMA_CONDICAO_LISTAGEM ||
            userPermissions.ADMINISTRACAO_GERAL_FORMAS_CONDICAO_PAGAMENTO ? (
              <Menu.Item key="2">
                <Link to="/forma-pagamento">Formas/Condições de Pagamento</Link>
              </Menu.Item>
            ) : null}
            {userPermissions.ADMINISTRACAO_GERAL_RESPONSABILIDADES ? (
              <Menu.Item key="3">
                <Link to="/responsabilidade">Responsabilidades</Link>
              </Menu.Item>
            ) : null}
            {userPermissions.PERFIL_LISTAGEM ||
            userPermissions.ADMINISTRACAO_GERAL_PERFIS ? (
              <Menu.Item key="4">
                <Link to="/perfil">Perfis</Link>
              </Menu.Item>
            ) : null}
            {userPermissions.CONTRATO_SIGNATARIO_PADRAO_LISTAGEM ? (
              <Menu.Item key="5">
                <Link to="/contrato/signatarios">Signatários Padrões</Link>
              </Menu.Item>
            ) : null}
            {userPermissions.ADMINISTRACAO_GERAL_SIMULADOR ? (
              <Menu.Item key="a6">
                <Link to="/simulador">Simulador</Link>
              </Menu.Item>
            ) : null}
            {userPermissions.ADMINISTRACAO_GERAL_SIMULADOR ? (
              <Menu.Item key="a7">
                <Link to="/detran">Detran</Link>
              </Menu.Item>
            ) : null}
            {userPermissions.USUARIO_LISTAGEM ? (
              <Menu.Item key="a8">
                <Link to="/regras/consultas">
                  Regras Gerais de Consulta 
                </Link>
              </Menu.Item>
            ) : null}
            <Menu.Item key="a9">
              <Link to="/link">Links de Pagamento</Link>
            </Menu.Item>
          </Menu.ItemGroup>
        ) : null}
        {PERMISSION_ADMINISTRACAO_LOGISTICA ? (
          <Menu.ItemGroup key="g2" title="Logistica">
            {userPermissions.POS_LISTAGEM ||
            userPermissions.ADMINISTRACAO_LOGISTICA_POS ? (
              <>
                <Menu.Item key="1">
                  <Link to="/pos">POS (Maquininhas)</Link>
                </Menu.Item>
                <Menu.Item key="2">
                  <Link to="/terminal">Terminais</Link>
                </Menu.Item>
              </>
            ) : null}
          </Menu.ItemGroup>
        ) : null}
        {PERMISSION_ADMINISTRACAO_TI ? (
          <Menu.ItemGroup key="g3" title="TI">
            {userPermissions.CLIENTE_API_BUSCAR ||
            userPermissions.ADMINISTRACAO_TI_CLIENTE_API ? (
              <Menu.Item key="6">
                <Link to="/cliente-api">Clientes da API</Link>
              </Menu.Item>
            ) : null}
            {userPermissions.ADMINISTRACAO_TI_PARCEIROS ||
            userPermissions.ADMINISTRACAO_TI_PARCEIROS ? (
              <Menu.Item key="7">
                <Link to="/parceiro">Parceiros</Link>
                {/* <Link to="/detran">Detran</Link> */}
              </Menu.Item>
            ) : null}
            {userPermissions.ADMINISTRACAO_TI_UPLOAD_IMAGENS ? (
              <Menu.Item key="12">
                <Link to="/bucket/images">Upload de Images</Link>
              </Menu.Item>
            ) : null}
            {userPermissions.ADMINISTRACAO_TI_UPLOAD_IMAGENS ? (
              <Menu.Item key="14">
                <Link to="/sms">SMS Enviados</Link>
              </Menu.Item>
            ) : null}
          </Menu.ItemGroup>
        ) : null}
        {PERMISSION_ADMINISTRACAO_RELATORIOS ? (
          <Menu.ItemGroup key="g4" title="Relatórios">
            {userPermissions.ADMINISTRACAO_RELATORIOS_GERAL ? (
              <Menu.Item key="8">
                <Link to="/relatorio/geral">Geral</Link>
              </Menu.Item>
            ) : null}
            {userPermissions.ADMINISTRACAO_RELATORIOS_FATURAMENTO ? (
              <Menu.Item key="9">
                <Link to="/relatorio/faturamento">Faturamento</Link>
              </Menu.Item>
            ) : null}
            {userPermissions.ADMINISTRACAO_RELATORIOS_ARQUIVO_RETORNO ? (
              <Menu.Item key="10">
                <Link to="/relatorio/arquivo_retorno">Arquivo Retorno</Link>
              </Menu.Item>
            ) : null}
            {userPermissions.ADMINISTRACAO_RELATORIOS_DIVERGENCIA_ADQUIRENCIA ? (
              <Menu.Item key="11">
                <Link to="/relatorio/divergencia_adquirencia">
                  Divergência de Adquirência
                </Link>
              </Menu.Item>
            ) : null}
            {userPermissions.ADMINISTRACAO_RELATORIOS_ARQUIVO_RETORNO ? (
              <Menu.Item key="96">
                <Link to="/transferencias">Transferencias</Link>
              </Menu.Item>
            ) : null}
          </Menu.ItemGroup>
        ) : null}
        {PERMISSION_ADMINISTRACAO_TOTEM ? (
          <Menu.ItemGroup key="g4" title="Clientes">
            {userPermissions.ADMINISTRACAO_TOTEM_USUARIOS ? (
              <Menu.Item key="13">
                <Link to="/clientes">Pesquisar</Link>
              </Menu.Item>
            ) : null}
          </Menu.ItemGroup>
        ) : null}
      </Menu>
    );
  }, [userPermissions]);

  const menu_carrinho = useMemo(() => {
    return () => (
      <Menu>
        {userPermissions.CARRINHO_CADASTRAR_MANUALMENTE ? (
          <Menu.Item key="2">
            <Link to="/carrinho-novo">Cadastrar</Link>
          </Menu.Item>
        ) : null}
        {userPermissions.COMISSAO_LISTAGEM ? (
          <Menu.ItemGroup title="Comisões">
            <Menu.Item key="3">
              <Link to="/comissao/pendente">Pagar</Link>
            </Menu.Item>
          </Menu.ItemGroup>
        ) : null}
        <Menu.ItemGroup title="Pagamentos">
          <Menu.Item key="payment">
            <Link to="/payment">Buscar</Link>
          </Menu.Item>
        </Menu.ItemGroup>
        <Menu.ItemGroup title="Cobranças">
          <Menu.Item key="charge">
            <Link to="/charge">Buscar</Link>
          </Menu.Item>
        </Menu.ItemGroup>
        <Menu.ItemGroup title="Boletos">
          <Menu.Item key="4">
            <Link to="/boleto">Buscar</Link>
          </Menu.Item>
        </Menu.ItemGroup>
        <Menu.ItemGroup title="Pix">
          <Menu.Item key="4">
            <Link to="/pix">Buscar</Link>
          </Menu.Item>
        </Menu.ItemGroup>
        <Menu.ItemGroup title="Clientes">
          <Menu.Item key="5">
            <Link to="/cliente">Buscar</Link>
          </Menu.Item>
        </Menu.ItemGroup>
      </Menu>
    );
  }, [userPermissions]);

  return (
    <>
      <MenuItem>
        <Dropdown overlay={menu_administracao}>
          <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
            Administração
          </a>
        </Dropdown>
      </MenuItem>

      {userPermissions.USUARIO_LISTAGEM ? (
        <MenuItem>
          <Link to="/usuario">Usuários</Link>
        </MenuItem>
      ) : null}

      {userPermissions.PERMISSAO_LISTAGEM ? (
        <MenuItem>
          <Badge
            offset={[0, -4]}
            count={qtdePermissoesPendentes}
            overflowCount={99}
          >
            <Link to="/permissoes">Permissões</Link>
          </Badge>
        </MenuItem>
      ) : null}

      {userPermissions.VINCULO_LISTAGEM ? (
        <MenuItem>
          <Badge
            offset={[0, -4]}
            count={qtdeVinculosPendentes}
            overflowCount={99}
          >
            <Link to="/vinculos">Vínculos</Link>
          </Badge>
        </MenuItem>
      ) : null}

      {userPermissions.CARRINHO_LISTAGEM ? (
        <MenuItem>
          <Link to="/carrinho">Carrinhos</Link>
        </MenuItem>
      ) : null}

      {userPermissions.CARRINHO_LISTAGEM ? (
        <MenuItem>
          <Dropdown overlay={menu_carrinho}>
            <Link to="/checkout">Checkout</Link>
          </Dropdown>
        </MenuItem>
      ) : null}
    </>
  );
}
