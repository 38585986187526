export interface GetQueryRulesResponse {
  data: QueryRule[];
  total: number;
  page: number;
  page_size: number;
}

export interface QueryRule {
  id_search_rule: string;
  establishment_id: string;
  description: string;
  property: EnumRuleProperty;
  validation: EnumRuleValidation;
  value: string;
  action: EnumAction;
  type: EnumRuleType;
  created_by: string;
  created_at: Date;
  updated_by?: string;
  updated_at?: Date;
  deleted_by?: string;
  deleted_at?: Date;
}

export enum EnumRuleType {
  BILL = 'BILL',
  PIX = 'PIX',
}

export const ruleTypeLabel = {
  [EnumRuleType.BILL]: 'BOLETO',
  [EnumRuleType.PIX]: 'PIX'
}

export type BodyQueryRule = Pick<
  QueryRule,
  'property' | 'validation' | 'value' | 'action' | 'description' | 'type'
>;

export type UpdateQueryRuleBody = Pick<QueryRule, 'id_search_rule'> & BodyQueryRule;

export enum EnumRuleProperty {
  KEY = 'KEY',
  TYPE = 'TYPE',
  RECIPIENT_ASSIGNOR = 'RECIPIENT_ASSIGNOR',
  RECIPIENT_DOCUMENT = 'RECIPIENT_DOCUMENT',
  RECIPIENT_NAME = 'RECIPIENT_NAME',
  PAYER_DOCUMENT = 'PAYER_DOCUMENT',
  PAYER_NAME = 'PAYER_NAME',
}
export enum EnumRuleValidation {
  EQUAL = 'EQUAL',
  DIFFERENT = 'DIFFERENT',
}
export enum EnumAction {
  ALLOW = 'ALLOW',
  BLOCK = 'BLOCK',
}

export function parseRulePropertyToPortuguese(property: string) {
  switch (property) {
    case EnumRuleProperty.KEY:
      return 'Chave Pix';
    case EnumRuleProperty.TYPE:
      return 'Tipo';
    case EnumRuleProperty.RECIPIENT_ASSIGNOR:
      return 'Emissor';
    case EnumRuleProperty.RECIPIENT_DOCUMENT:
      return 'Documento do cedente';
    case EnumRuleProperty.RECIPIENT_NAME:
      return 'Nome do cedente';
    case EnumRuleProperty.PAYER_DOCUMENT:
      return 'Documento do pagador';
    case EnumRuleProperty.PAYER_NAME:
      return 'Nome do pagador';
    default:
      return '';
  }
}

export function parseRuleValidationToPortuguese(validation: string) {
  switch (validation) {
    case EnumRuleValidation.EQUAL:
      return 'Igual';
    case EnumRuleValidation.DIFFERENT:
      return 'Diferente';
    default:
      return '';
  }
}

export function parseRuleActionToPortuguese(action: string) {
  switch (action) {
    case EnumAction.ALLOW:
      return 'Permitir';
    case EnumAction.BLOCK:
      return 'Bloquear';
    default:
      return '';
  }
}

export const query_rules_properties = Object.values(EnumRuleProperty).map(
  value => ({
    label: parseRulePropertyToPortuguese(value).replace(/_/g, ' '),
    value,
  }),
);

export const query_rules_validations = Object.values(EnumRuleValidation).map(
  value => ({
    label: parseRuleValidationToPortuguese(value),
    value,
  }),
);

export const query_rules_actions = Object.values(EnumAction).map(value => ({
  label: parseRuleActionToPortuguese(value),
  value,
}));
export const query_rules_types = Object.values(EnumRuleType).map(value => ({
  label: ruleTypeLabel[value],
  value,
}));
