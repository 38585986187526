import styled, { css } from 'styled-components';

import cores from '../../styles/colors';
import { header_size, menu_size } from '../../styles/global';
import devices from '../../styles/devices';

interface IMenuStyle {
  opened: boolean;
}

export const MenuStyle = styled.nav<IMenuStyle>`
  display: flex;
  align-items: center;

  @media only screen and (max-width: ${devices.tablet}) {
    display: block;
    background: #232323;
    position: fixed;
    top: 0;
    /* left: 0; */
    width: 80%;
    z-index: 3;
    left: -100%;
    transition: 0.5s;
    height: 100vh;
    padding-top: 50px;

    ${props => props.opened && css`
      left: 0;
    `}

  }
`;

export const MenuStyle2 = styled.nav`
  min-height: calc(100vh - ${header_size});
  width: ${menu_size};
  background-color: ${cores.background_dark};
  padding-top: 50px;
  display: flex;
  float: left;
  flex-direction: column;
  flex: 1;

  position: fixed;
  margin-top: ${header_size};
  overflow: auto;
  height: calc(100vh - ${header_size});

  ::-webkit-scrollbar-track {
    background-color: ${cores.background_dark};
    padding-right: 1px;
  }
  ::-webkit-scrollbar {
    width: 6px;
    background: ${cores.background_dark};
  }
  ::-webkit-scrollbar-thumb {
    background: ${cores.font_menu};
    border-radius: 3px;
  }

  @media only screen and (max-width: ${devices.tablet}) {
    z-index: 3;
    left: -100%;
    transition: 0.5s;

    &.opened {
      left: 0;
    }
  }
`;

export const MenuItem = styled.div`
  margin-right: 30px;

  a {
    color: #505050;
  }

  @media only screen and (max-width: ${devices.tablet}) {
    margin: 15px 25px;

    a {
      color: #afafaf;
    }
  }
`;

export const MenuItem2 = styled.div`
  color: ${cores.font_menu};

  h3 {
    padding-left: 30px;
    font-weight: 400;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 10px;
    color: #fff;
  }

  ul {
    margin-bottom: 40px;

    li {
      /* padding-left: 30px; */
      /* height: 50px;
      display: flex;
      align-items: center;
      font-size: 16px;
      cursor: pointer;
      transition: 0.2s; */
      /* text-transform: uppercase; */

      a {
        padding-left: 30px;
        height: 45px;
        display: flex;
        align-items: center;
        font-size: 16px;
        cursor: pointer;
        transition: 0.2s;
        color: #dcdcdc;
        padding-right: 10px;

        &:hover {
          background: #232323;
        }

        svg {
          font-size: 25px;
          margin-right: 20px;
          color: #656363;
        }

        &.active {
          padding-left: 26px;
          border-left: 4px solid ${cores.primary};
        }

        &.disabled,
        &:disabled,
        &[disabled] {
          color: #656363;
        }
      }
    }
  }
`;
