import { Timeline, Typography } from 'antd';
import { format } from 'date-fns';
import React, { useMemo } from 'react';

import CardCollapse from '../../../../components/CardCollapse';
import { getColor, findStatusCarrinhoIcon } from '../../index';

type ICarrinhoInfoStatus = {
  status: any[];
  loading: boolean;
};

const { Text } = Typography;

const CarrinhoInfoStatus: React.FC<ICarrinhoInfoStatus> = ({
  status,
  loading,
}: ICarrinhoInfoStatus) => {
  const status_list = useMemo(() => {
    return (
      <Timeline>
        {status?.map((item: any) => (
          <Timeline.Item
            dot={findStatusCarrinhoIcon(item.tipo, item.id_status_carrinho)}
            color={getColor(item.tipo)}
          >
            <Text type={item.tipo}>{item.nome}</Text>
            <br />
            <Text type="secondary">
              {item.criado_em
                ? format(new Date(item.criado_em), 'dd/MM/yyyy HH:mm:ss')
                : null}
            </Text>
            {item.mensagem ? (
              <p style={{ marginTop: 15, marginBottom: 0 }}>{item.mensagem}</p>
            ) : null}
          </Timeline.Item>
        ))}
      </Timeline>
    );
  }, [status]);

  return (
    <>
      <CardCollapse title="Timeline" loading={loading}>
        {status_list}
      </CardCollapse>
    </>
  );
};

export default CarrinhoInfoStatus;
