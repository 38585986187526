import { Button, message, Table, Tooltip } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import { format } from 'date-fns';
import { MdOpenInNew, MdOutlineAddToHomeScreen, MdSmartphone } from 'react-icons/md';
import { RiComputerLine } from 'react-icons/ri';

import { cpfMask, telefoneMask } from 'masks-br';
import React, { useEffect, useState } from 'react';
import PageHeader from '../../../components/PageHeader';
import { fetchApi } from '../../../services/api';

import { Link } from 'react-router-dom';
import { ColumnsType } from 'antd/lib/table';
interface IUserClient {
  cpf: string;
  full_name: string;
  phone: string;
  created_at: string;
  origin: string;
  id_establishment: string;
}

interface IPaginationMongo {
  limit: number | undefined;
  skip: number;
}

interface IListUserClient {
  total: number;
  data: IUserClient[];
}

const TotemUsuarios: React.FC = () => {
  const [clients, setClients] = useState<IListUserClient>();
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState<IPaginationMongo>({
    limit: 25,
    skip: 0,
  });

  const columns: ColumnsType<IUserClient> = [
    {
      // title: 'Origem',
      key: 'origin',
      dataIndex: 'origin',
      render: (item: string) => {
        const color = '#7d7d7d';
        const size = 25;

        if (item === 'pos') {
          return (
            <Tooltip placement="right" title={item.toLocaleUpperCase()}>
              <MdSmartphone size={size} color={color} />
            </Tooltip>
          );
        }

        if (item === 'online') {
          return (
            <Tooltip placement="right" title={item.toLocaleUpperCase()}>
              <RiComputerLine size={size} color={color} />
            </Tooltip>
          );
        }

        if (item === 'toten') {
          return (
            <Tooltip placement="right" title={item.toLocaleUpperCase()}>
              <MdOutlineAddToHomeScreen size={size} color={color} />
            </Tooltip>
          );
        }

        return item.toLocaleUpperCase();
      },
    },
    {
      title: 'Name',
      dataIndex: 'full_name',
      key: 'full_name',
    },
    {
      title: 'Documento',
      dataIndex: 'document',
      key: 'document',
      render: (document: string) => <Paragraph copyable>{cpfMask(document)}</Paragraph>,
    },
    {
      title: 'Telefone',
      dataIndex: 'phone',
      key: 'phone',
      render: (phone: string) => telefoneMask(phone),
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Data de Nascimento',
      dataIndex: 'birth_date',
      key: 'birth_date',
      render: (birth_date: string) =>
        format(new Date(birth_date), 'dd/MM/yyyy'),
    },
    {
      title: 'Criado em',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at: string) =>
        format(new Date(created_at), 'dd/MM/yyyy HH:mm'),
    },
    {
      title: 'Estabelecimento',
      dataIndex: 'id_establishment',
      key: 'id_establishment',
      render: (id_establishment: string) => <Paragraph copyable>{id_establishment ? id_establishment : 'Parcelamos Tudo'}</Paragraph>,
    },
    {
      title: 'Carrinho',
      align: 'center',
      render: (data: any) =>
        <Link
          to={`/carrinho?pagina=1&porPagina=25&document_client=${data?.document}${data?.id_establishment ? `&id_estabelecimento=${data.id_establishment}` : ''}`}
          className="ant-btn ant-btn-primary"
        >
          <MdOpenInNew />
        </Link>
    },
    {
      title: 'Remover',
      dataIndex: 'document',
      key: 'document',
      render: (document: string) => (
        <Button danger type="primary" onClick={() => deleteClient(document)}>
          Remover
        </Button>
      ),
    },
  ];

  useEffect(() => {
    fetchClients();
  }, [pagination]);

  async function deleteClient(cpf: string) {
    setLoading(true);
    try {
      await fetchApi({
        url: `/customer/${cpf}`,
        method: 'delete'
      })

      message.success('Cliente removido com sucesso');
      await fetchClients();
      setLoading(false);
    } catch (error) {
      message.error('Erro ao remover cliente');
      setLoading(false);
    }
  }

  async function fetchClients() {
    setLoading(true);
    try {
      const data = await fetchApi<IListUserClient>({
        url: '/customer',
        method: 'get',
        query_params: pagination,
      })

      setClients({ data: data?.data ?? [], total: data?.total ?? 0 });
    } catch (error) {
      message.error('Erro ao buscar clientes');
    } finally {
      setLoading(false);
    }
  }

  function handleCurrentPage(skip: number) {
    if (skip <= 0) {
      return 1;
    }

    return Math.ceil(skip / 25) + 1;
  }

  function handleSkipPage(page: number) {
    if (page === 1) {
      return 0;
    }

    return (page - 1) * 25;
  }

  return (
    <>
      <PageHeader title="Clientes" />
      <Table
        dataSource={clients?.data}
        columns={columns}
        loading={loading}
        pagination={{
          total: clients?.total,
          current: handleCurrentPage(pagination.skip),
          pageSize: pagination.limit,
          onChange: (page, pageSize) =>
            setPagination({ limit: pageSize, skip: handleSkipPage(page) }),
        }}
      />
    </>
  );
};

export default TotemUsuarios;
