import api from "../../../services/api";
import { FormStoreSchema } from "../components/Form";
import { GetTerminalInfoResponse, GetTerminalModelsResponse, GetTerminalsResponse } from "./types";

export async function getTerminals(params: any) {
  const response = await api.get<GetTerminalsResponse>('/backoffice/terminal', { params })

  return response.data
}
export async function getTerminalModels(params: any) {
  const response = await api.get<GetTerminalModelsResponse>('/backoffice/terminal/models/list', { params })

  return response.data
}

export async function getTerminalInfo(id_terminal: string) {
  const response = await api.get<GetTerminalInfoResponse>(`/backoffice/terminal/${id_terminal}`)

  return response.data
}

export async function postTerminal(data: FormStoreSchema) {
  const response = await api.post('/backoffice/terminal', data)

  return response.data
}

type UpdateTerminalData = {
  id_terminal: string;
  payload: FormStoreSchema
}

export async function putTerminal(data: UpdateTerminalData) {
  const response = await api.put(`/backoffice/terminal/${data.id_terminal}`, data.payload)

  return response.data
}