import { findStatusColor } from ".."
import Option from "../../../types/Option"

export interface GetAllPixResponse {
  data: GetAllPixResponseItem[]
  page: number
  page_size: number
  total: number
  total_pages: number
}

export interface GetAllPixResponseItem {
  id_pix: string
  id_status: string
  correlation_id: string
  source: string
  type: string
  emv: string
  url: string
  gui: string
  key: string
  key_type: string
  aditional_information: string
  end_to_end_id: string
  merchant_name: string
  merchant_city: string
  merchant_country: string
  merchant_zipcode: string
  merchant_category_code: string
  amount_original: number
  amount_total: number
  amount_discount: number
  amount_additional: number
  authentication_code: string
  settlement_institution: string
  settled_at: string
  created_by: string
  due_date: string
  created_at: string
  updated_at: string
}

export enum EnumStatusPix {
  FETCH_ERROR = "FETCH_ERROR",
  FETCHED = "FETCHED",
  WAITING_PAYMENT = "WAITING_PAYMENT",
  WAITING_CONFIRMATION = "WAITING_CONFIRMATION",
  PAYMENT_ERROR = "PAYMENT_ERROR",
  PAYED = "PAYED",
  CANCELED = "CANCELED",
  REVERSED = "REVERSED",
}

export const pixStatusOptions: Option[] = [
  {
    label: findStatusColor('Buscado', 'info'),
    value: EnumStatusPix.FETCHED
  },
  {
    label: findStatusColor('Erro na busca', 'error'),
    value: EnumStatusPix.FETCH_ERROR
  },
  {
    label: findStatusColor('Aguardando pagamento', 'info'),
    value: EnumStatusPix.WAITING_PAYMENT
  },
  {
    label: findStatusColor('Aguardando confirmação', 'info'),
    value: EnumStatusPix.WAITING_CONFIRMATION
  },
  {
    label: findStatusColor('Pago', 'success'),
    value: EnumStatusPix.PAYED
  },
  {
    label: findStatusColor('Erro no pagamento', 'danger'),
    value: EnumStatusPix.PAYMENT_ERROR
  },
  {
    label: findStatusColor('Estornado', 'warning'),
    value: EnumStatusPix.REVERSED
  },
  {
    label: findStatusColor('Cancelado', 'danger'),
    value: EnumStatusPix.CANCELED
  },
]