import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { format, parseISO } from 'date-fns';

import PageHeader from '../../../components/PageHeader';
import Table from '../../../components/Table';
import { useApi } from '../../../hooks/useApi';
import Pagina from '../../../types/Pagina';

const ClienteApiChaveLog: React.FC = () => {
  const { loading, fetchRequest } = useApi();
  const { id } = useParams<{ id: string }>();

  const [logs, setLogs] = useState<Pagina>({} as Pagina);

  useEffect(() => {
    fetchLogs();
  }, []);

  function fetchLogs(pagina = 1, porPagina = 25) {
    return fetchRequest({
      method: 'get',
      url: `/cliente-api-chave/${id}/log`,
      query_params: { pagina, porPagina },
      onSuccess: response => {
        const data = response.data.map((item: any) => ({
          ...item,
          criado_em: format(parseISO(item.criado_em), 'dd/MM/yyyy HH:mm:ss'),
          body: <pre>{JSON.stringify(item.body, null, 2)}</pre>,
        }));

        setLogs({
          ...response,
          data,
        });
      },
    });
  }

  const columns = [
    {
      title: 'URL Original',
      key: 'url_original',
      dataIndex: 'url_original',
    },
    {
      title: 'URL Final',
      key: 'url',
      dataIndex: 'url',
    },
    {
      title: 'IP',
      key: 'ip',
      dataIndex: 'ip',
    },
    {
      title: 'Body Request',
      key: 'body',
      dataIndex: 'body',
    },
    {
      title: 'Criado Em',
      key: 'criado_em',
      dataIndex: 'criado_em',
    },
  ];

  return (
    <>
      <PageHeader
        title="Logs da Chave"
        breadcrumb={['Clientes API', 'Informações', 'Chave', 'Logs']}
      />

      <Table
        columns={columns}
        pagination={{
          current: logs.pagina,
          pageSize: logs.porPagina,
          total: logs.total,
          onChange: fetchLogs,
        }}
        dataSource={logs.data}
        loading={loading}
        bordered
      />
    </>
  );
};

export default ClienteApiChaveLog;
