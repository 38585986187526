import React, { createContext, useContext, useState } from 'react';
import { fetchApi, FetchApiI } from '../services/api';

interface ApiContext {
  fetchRequest<T = any>(data: FetchApiI<T>): Promise<T | undefined>;
  loading: boolean;
}

export function useApi(): ApiContext {
  const [loading, setLoading] = useState<boolean>(false);

  async function fetchRequest<T = any>(data: FetchApiI<T>) {
    setLoading(true);

    const response = await fetchApi(data);

    setLoading(false);

    return response;
  }

  return { loading, fetchRequest };
}

const ApiContext = createContext<ApiContext>({} as ApiContext);

const ApiProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false);

  async function fetchRequest<T = any>(data: FetchApiI<T>) {
    setLoading(true);

    const response = await fetchApi(data);

    setLoading(false);

    return response;
  }

  return (
    <ApiContext.Provider value={{ loading, fetchRequest }}>
      {children}
    </ApiContext.Provider>
  );
};

// function useApi(): ApiContext {
//   const context = useContext(ApiContext);

//   if (!context) {
//     throw new Error('useApi deve ser utilizado dentro do ApiProvider');
//   }

//   return context;
// }

export { ApiContext, ApiProvider };
