import React, { useState } from 'react';
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Popconfirm,
  Result,
  Row,
  Select,
  Table as TableAntd,
  message,
} from 'antd';
import { MdRemoveCircle } from 'react-icons/md';
import UserName from '../../../../components/User/Name';

import CardCollapse from '../../../../components/CardCollapse';
import { EditFilled } from '@ant-design/icons';
import { format } from 'date-fns';
import { EnumRuleType, QueryRule, parseRuleActionToPortuguese, parseRulePropertyToPortuguese, parseRuleValidationToPortuguese, query_rules_actions, query_rules_properties, query_rules_types, query_rules_validations, ruleTypeLabel } from "../../../General/QueryRules/types";
import { getGeneralQueryRules } from "../../../General/QueryRules/services";
import { useQuery } from "react-query";
import { useCreateQueryRule, useDeleteQueryRule, useUpdateQueryRule } from "../../../General/QueryRules/hooks";
import { fieldErrorMessages, fieldRegex } from "../../../../utils/fields";

type IState = {
  default_message: string;
  id_usuario: string;
};

type IPagination = {
  page: number;
  page_size: number;
};

const CardQueryRule: React.FC<IState> = ({
  id_usuario,
  default_message,
}: IState) => {
  const [formCreate] = Form.useForm();
  const [formUpdate] = Form.useForm();

  const [pagination, setPagination] = useState<IPagination>({
    page: 1,
    page_size: 10,
  });
  const [modalUpdateQueryRule, setModalUpdateQueryRule] = useState({
    open: false,
    query_rule: {} as QueryRule,
  });

  const [modalCreateQueryRule, setModalCreateQueryRule] = useState({
    open: false,
    query_rule: {} as QueryRule,
  });

  const { data: queryRules, isLoading: isLoadingQueryRules } = useQuery(
    ['get-general-query-rules', pagination, id_usuario],
    () => getGeneralQueryRules({...pagination,  id_establishment: id_usuario}),
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: false,
      onError(err) {
        message.error('Erro ao buscar regras de consulta');
      },
    },
  );

  const { mutate: createQueryRule } = useCreateQueryRule();
  const { mutate: updateQueryRule } = useUpdateQueryRule();
  const { mutate: deleteQueryRule } = useDeleteQueryRule();

  function handlerAddQueryRule(data: any) {
    createQueryRule({...data, establishment_id: id_usuario}, {
      onSuccess: () => {
        message.success('Regra de consulta adicionada com sucesso!');
        setModalCreateQueryRule({ open: false, query_rule: {} as QueryRule });
        formCreate.resetFields();
      },
      onError: () => {
        setModalCreateQueryRule({ open: false, query_rule: {} as QueryRule });
        message.error('Erro ao adicionar regra de consulta!');
      },
    });
  }

  function handleRemoveQueryRule(id_search_rule: string) {
    deleteQueryRule(id_search_rule, {
      onSuccess: () => {
        message.success('Regra removida com sucesso!');
      },
      onError: () => {
        message.error('Erro ao remover regra!');
      },
    });
  }

  function handlerUpdateQueryRule(data: any) {
    updateQueryRule(data, {
      onSuccess: () => {
        message.success('Regra atualizada com sucesso!');
        setModalUpdateQueryRule({ open: false, query_rule: {} as QueryRule });
      },
      onError: () => {
        setModalUpdateQueryRule({ open: false, query_rule: {} as QueryRule });
        message.error('Erro ao atualizar regra!');
      },
    });
  }


  const columns_perfil = [
    {
      title: 'Tipo',
      dataIndex: 'type',
      key: 'type',
      render: (type: EnumRuleType) => {
        if (!type) return ruleTypeLabel[EnumRuleType.BILL];

        return ruleTypeLabel[type];
      },
    },
    {
      title: 'Regra',
      dataIndex: 'property',
      key: 'property',
      render: (item: string) => parseRulePropertyToPortuguese(item),
    },
    {
      title: 'Validação',
      dataIndex: 'validation',
      key: 'validation',
      render: (item: string) => parseRuleValidationToPortuguese(item),
    },
    {
      title: 'Valor',
      dataIndex: 'value',
      key: 'value',
      width: 300,
    },
    {
      title: 'Ação',
      dataIndex: 'action',
      key: 'action',
      render: (item: string) => parseRuleActionToPortuguese(item),
    },
    {
      title: 'Criado Por',
      dataIndex: 'created_by',
      key: 'created_by',
      render: (item: string) => <UserName id_user={item} />,
    },
    {
      title: 'Criado Em',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (item: string) => format(new Date(item), 'dd/MM/yyyy HH:mm:ss'),
    },
    {
      title: 'Editar',
      width: 100,
      render: (data: any) => (
        <Button
          type="primary"
          onClick={() =>
            setModalUpdateQueryRule({
              open: true,
              query_rule: data,
            })
          }
        >
          <EditFilled />
        </Button>
      ),
    },
    {
      title: 'Remover',
      width: 100,
      render: (data: any) => (
        <Popconfirm
          placement="topRight"
          title="Tem certeza que deseja remover essa regra?"
          onConfirm={() => handleRemoveQueryRule(data.id_search_rule)}
          okText="Sim"
          cancelText="Não"
        >
          <Button type="primary" danger>
            <MdRemoveCircle />
          </Button>
        </Popconfirm>
      ),
    },
  ];

  return (
    <>
      <CardCollapse loading={isLoadingQueryRules} title="Regras de Consulta">
        <>
          <Row gutter={16}>
            <Col md={18} />
            <Col md={6}>
              <Button
                type="primary"
                htmlType="submit"
                block
                onClick={() =>
                  setModalCreateQueryRule({
                    open: true,
                    query_rule: {} as QueryRule,
                  })
                }
              >
                Adicionar
              </Button>
            </Col>
          </Row>

          <br />
        </>

        {queryRules?.data?.length ? (
          <TableAntd
            columns={columns_perfil}
            dataSource={queryRules.data}
            scroll={{ x: 1100 }}
            pagination={{
              pageSize: queryRules.page_size,
              current: queryRules.page,
              total: queryRules.total,
              hideOnSinglePage: true,
              onChange(page, page_size) {
                setPagination({ page, page_size });
              },
            }}
          />
        ) : (
          <Result extra="Usuário com nenhum regra" />
        )}
      </CardCollapse>
      <Modal
        title="Criar Regra"
        visible={modalCreateQueryRule.open}
        onCancel={() =>
          setModalCreateQueryRule({ open: false, query_rule: {} as QueryRule })
        }
        footer={false}
      >
        <Form
          layout="vertical"
          onFinish={data => handlerAddQueryRule(data)}
          form={formCreate}
        >
          <Row gutter={2}>
            <Col md={24} xs={24}>
              <Form.Item
                label="Tipo"
                name="type"
                rules={[{ required: true, message: default_message }]}
              >
                <Select
                  placeholder="Para que se aplica?"
                  options={query_rules_types}
                />
              </Form.Item>

              <Form.Item
                label="Propriedade"
                name="property"
                rules={[{ required: true, message: default_message }]}
              >
                <Select
                  placeholder="Selecione uma regra"
                  options={query_rules_properties}
                />
              </Form.Item>
            </Col>
            <Col md={24} xs={24}>
              <Form.Item
                label="Validação"
                name="validation"
                rules={[{ required: true, message: default_message }]}
              >
                <Select
                  placeholder="Selecione um validação"
                  options={query_rules_validations}
                />
              </Form.Item>
              <Form.Item
                label="Valor"
                name="value"
                rules={[{ required: true, message: default_message }, { message: fieldErrorMessages.clearSpaces, pattern: fieldRegex.clearSpacesBegnningAndEnd }]}
              >
                <Input placeholder="Digite um valor" />
              </Form.Item>
            </Col>
            <Col md={24} xs={24}>
              <Form.Item
                label="Ação"
                name="action"
                rules={[{ required: true, message: default_message }]}
              >
                <Select
                  placeholder="Selecione uma regra"
                  options={query_rules_actions}
                />
              </Form.Item>
            </Col>
          </Row>
          <br />
          <Row justify="end">
            <Button type="primary" htmlType="submit">
              Criar
            </Button>
          </Row>
        </Form>
      </Modal>
      <Modal
        title="Editar Regra"
        visible={modalUpdateQueryRule.open}
        onCancel={() =>
          setModalUpdateQueryRule({ open: false, query_rule: {} as QueryRule })
        }
        footer={false}
      >
        <Form
          layout="vertical"
          onFinish={data => handlerUpdateQueryRule(data)}
          form={formUpdate}
          initialValues={{
            id_search_rule: modalUpdateQueryRule.query_rule.id_search_rule,
            property: modalUpdateQueryRule.query_rule.property,
            validation: modalUpdateQueryRule.query_rule.validation,
            value: modalUpdateQueryRule.query_rule.value,
            type: modalUpdateQueryRule.query_rule.type,
            action: modalUpdateQueryRule.query_rule.action,
          }}
        >
          <Row gutter={4}>
            <Col md={24} xs={24}>
              <Form.Item
                label="ID"
                name="id_search_rule"
                rules={[{ required: true, message: default_message }]}
              >
                <Input placeholder="Digite um valor" disabled />
              </Form.Item>
              <Form.Item
                label="Tipo"
                name="type"
                rules={[{ required: true, message: default_message }]}
              >
                <Select
                  placeholder="Para que se aplica?"
                  options={query_rules_types}
                />
              </Form.Item>
              <Form.Item
                name="property"
                label="Regra"
                rules={[{ required: true, message: default_message }]}
              >
                <Select
                  placeholder="Selecione uma regra"
                  options={query_rules_properties}
                />
              </Form.Item>
            </Col>
            <Col md={24} xs={24}>
              <Form.Item
                label="Validação"
                name="validation"
                rules={[{ required: true, message: default_message }]}
              >
                <Select
                  placeholder="Selecione um validação"
                  options={query_rules_validations}
                />
              </Form.Item>
              <Form.Item
                label="Valor"
                name="value"
                rules={[{ required: true, message: default_message }, { message: fieldErrorMessages.clearSpaces, pattern: fieldRegex.clearSpacesBegnningAndEnd }]}
              >
                <Input placeholder="Digite um valor" />
              </Form.Item>
            </Col>
            <Col md={24} xs={24}>
              <Form.Item
                label="Ação"
                name="action"
                rules={[{ required: true, message: default_message }]}
              >
                <Select
                  placeholder="Selecione uma regra"
                  options={query_rules_actions}
                />
              </Form.Item>
            </Col>
          </Row>
          <br />
          <Row justify="end">
            <Button type="primary" htmlType="submit">
              Atualizar
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default CardQueryRule;
