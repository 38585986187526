import React, { useMemo, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from 'styled-components';
import { QueryClientProvider, QueryClient } from 'react-query';
import { Alert, ConfigProvider } from 'antd';

import Routes from './routes';
import GlobalStyle from './styles/global';
import { store, persistor } from './store';
import theme from './styles/theme';

import { UtilsProvider } from './hooks/useUtils';
import { PermissionProvider } from './hooks/usePermissions';
import { ApiProvider } from './hooks/useApi';
import ErrorPage from './pages/Error';

import 'antd/dist/antd.css';
import { ErrorBoundary } from 'react-error-boundary';

import { ptBR } from 'date-fns/locale';
import { setDefaultOptions } from 'date-fns';

setDefaultOptions({ locale: ptBR });

export const queryClientApp = new QueryClient();

function App() {
  const [offline, setOffline] = useState<boolean>(false);

  window.addEventListener('online', () => setOffline(false));
  window.addEventListener('offline', () => setOffline(true));

  const banner = useMemo(() => {
    return offline ? (
      <Alert
        message="Ops! Parece que você não está conectado com a internet."
        type="warning"
        showIcon
        closable
        banner
      />
    ) : null;
  }, [offline]);

  function getPopupContainer(node: HTMLElement | undefined): HTMLElement {
    if (node && node?.parentNode) {
      return node.parentNode as HTMLElement;
    }
    return document.body;
  }

  return (
    <ErrorBoundary fallbackRender={ErrorPage}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <QueryClientProvider client={queryClientApp}>
            <ConfigProvider getPopupContainer={getPopupContainer}>
              <UtilsProvider>
                <PermissionProvider>
                  <ApiProvider>
                    <ThemeProvider theme={theme}>
                      <Router>
                        {banner}
                        <Routes />
                        <GlobalStyle />
                      </Router>
                    </ThemeProvider>
                  </ApiProvider>
                </PermissionProvider>
              </UtilsProvider>
            </ConfigProvider>
          </QueryClientProvider>
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  );
}

export default App;
