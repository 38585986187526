import { StatusTipo } from "../../../store/modules/utils/types";
import Option from "../../../types/Option";
import { PagePagination } from "../../../types/Pagina";
import { EnumSettlementType } from "../../Boleto/types";
import { findStatusColor } from "../../Checkout";
import { EnumStatusPix } from "../../Pix/services/types";

export type GetPaymentParams = {
  page: number;
  page_size: number;
  id_payment?: string;
  status?: EnumPaymentStatus;
  amount_total?: number
  type?: EnumPaymentType
}

export type GetPaymentsResponse = PagePagination<Payment>

export type Payment = {
  id_payment: string;
  id_status: EnumPaymentStatus;
  checkout_id: string;
  type: EnumPaymentType;
  total_amount: number;
  created_at: string;
}

export enum EnumPaymentType {
  BILL = 'BILL',
  PIX = 'PIX',
  PARTNER = 'PARTNER',
  TRANSFER = 'TRANSFER',
}


export const paymentTypeLabel = {
  BILL: 'Boleto',
  PIX: 'PIX',
  PARTNER: 'Débito Veicular',
  TRANSFER: 'Transferência',
}

export enum EnumPaymentStatus {
  RECEIVED = "RECEIVED",
  STARTED = "STARTED",
  PENDING = "PENDING",
  WAITING_PAYMENT = "WAITING_PAYMENT",
  PAYMENT_DONE = "PAYMENT_DONE",
  PAYMENT_ERROR = "PAYMENT_ERROR",
  PAYMENT_REVERSED = "PAYMENT_REVERSED",
  PAYMENT_CANCELED = "PAYMENT_CANCELED",
  PAYMENT_CONFIRMED = "PAYMENT_CONFIRMED",
  PAID_MANUALLY = "PAID_MANUALLY",
  WAITING_APPROVAL = "WAITING_APPROVAL",
  APPROVED = "APPROVED",
  REPROVED = "REPROVED"
}

export const paymentStatusOptions: Option[] = [
  {
    label: findStatusColor('Recebido', 'success'),
    value: EnumPaymentStatus.RECEIVED
  },
  {
    label: findStatusColor('Iniciado', 'info'),
    value: EnumPaymentStatus.STARTED
  },
  {
    label: findStatusColor('Pendente', 'info'),
    value: EnumPaymentStatus.PENDING
  },
  {
    label: findStatusColor('Aguardando pagamento', 'info'),
    value: EnumPaymentStatus.WAITING_PAYMENT
  },
  {
    label: findStatusColor('Pagamento finalizado', 'success'),
    value: EnumPaymentStatus.PAYMENT_DONE
  },
  {
    label: findStatusColor('Erro no pagamento', 'danger'),
    value: EnumPaymentStatus.PAYMENT_ERROR
  },
  {
    label: findStatusColor('Pagamento estornado', 'warning'),
    value: EnumPaymentStatus.PAYMENT_REVERSED
  },
  {
    label: findStatusColor('Pagamento cancelado', 'danger'),
    value: EnumPaymentStatus.PAYMENT_CANCELED
  },
  {
    label: findStatusColor('Pagamento confirmado', 'info'),
    value: EnumPaymentStatus.PAYMENT_CONFIRMED
  },
  {
    label: findStatusColor('Pagamento manual', 'info'),
    value: EnumPaymentStatus.PAID_MANUALLY
  },
  {
    label: findStatusColor('Aguardando aprovação', 'info'),
    value: EnumPaymentStatus.WAITING_APPROVAL
  },
  {
    label: findStatusColor('Aprovado', 'success'),
    value: EnumPaymentStatus.APPROVED
  },
  {
    label: findStatusColor('Reprovado', 'error'),
    value: EnumPaymentStatus.REPROVED
  },
]

export type GetPaymentByIdResponse = {
  data: PaymentDetailItem
  success: boolean
}


export type PaymentDetailItem = PaymentTypeBill | PaymentTypeTransfer | PaymentTypePix | PaymentTypeDebitPartner

interface PaymentDetailType<T extends EnumPaymentType> {
  type: T;
  id_payment: string;
  checkout_id: string;
  establishment_id: string;
  id_status: EnumPaymentStatus;
  amount: number;
  fee_amount: number;
  total_amount: number;
  created_at: Date;
  updated_at: Date;
  status: PaymentStatusDetail;
}

type PaymentStatusDetail =  {
  id_status: EnumPaymentStatus;
  name: string;
  type: StatusTipo;
  description: string;
}

interface PaymentTypeDebitPartner extends PaymentDetailType<EnumPaymentType.PARTNER> {
  debit_id: string;
  debit: Debit
}

type Debit = {
  id_debit: string
  id_partner: string
  id_search: string
  establishment_id: string
  payment_id: any
  type: string
  name: string
  description: string
  identifier: string
  digitable: any
  year: number
  amount: number
  amount_fee: number
  amount_fine: number
  amount_discount: number
  amount_original: number
  license_plate: string
  renavam: any
  due_date: string
  infraction_date: string
  payed_at: any
  expired_at: any
  created_at: string
  updated_at: string
}


interface PaymentTypeBill extends PaymentDetailType<EnumPaymentType.BILL> {
  bill: Bill
  id_bill: string;
}

type Bill = {
  id_bill: string;
  id_status: string;
  code: string;
  digitable: string;
  type: string;
  source: string;
  correlation_id?: string;
  recipient_assignor?: string;
  recipient_document?: string;
  recipient_name?: string;
  payer_document?: string;
  payer_name?: string;
  due_date?: string;
  start_payment_hour?: number;
  end_payment_hour?: number;
  amount_original: number;
  amount_total: number;
  amount_discount: number;
  amount_additional: number;
  authentication_code?: string;
  id_settlement_type?: EnumSettlementType;
  settlement_institution?: string;
  settlement_transaction_id?: string;
  settled_at?: string;
  created_by?: string;
  created_at: Date;
  updated_at: Date;
  status?: string;
}

interface PaymentTypeTransfer extends PaymentDetailType<EnumPaymentType.TRANSFER> {
  id_transfer: string;
  transfer: Transfer
}

type Transfer = {
  id_transfer: string;
  id_status: string;
  bank_name: string;
  bank_code: string;
  agency_number: string;
  agency_number_digit: string;
  account_number: string;
  account_number_digit: string;
  account_type: string;
  amount_total: number;
  description?: string;
  recipient_name: string;
  recipient_document: string;
  recipient_email: string;
  recipient_phone: string;
  payer_name?: string;
  payer_document?: string;
  payer_email?: string;
  payer_phone?: string;
  reference_id: string;
  authentication_code?: string;
  settlement_institution?: string;
  settled_at?: string;
  updated_by?: string;
  created_at: Date;
  updated_at: Date;
  payments?: any[];
  status?: string;
}

interface PaymentTypePix extends PaymentDetailType<EnumPaymentType.PIX> {
  id_pix: string;
  pix: Pix
}

enum EnumPixType {
  STATIC = 'STATIC',
  DYNAMIC_IMMEDIATE = 'DYNAMIC_IMMEDIATE',
  DYNAMIC_DUEDATE = 'DYNAMIC_DUEDATE',
}

type Pix = {
  id_pix: string;
  id_status: EnumStatusPix;
  correlation_id: string;
  source: string;
  type: EnumPixType;
  emv: string;
  url?: string;
  gui?: string;
  key?: string;
  key_type?: string;
  aditional_information?: string;
  end_to_end_id?: string;
  merchant_name?: string;
  merchant_city?: string;
  merchant_country?: string;
  merchant_zipcode?: string;
  merchant_category_code: string;
  amount_original: number;
  amount_total: number;
  amount_discount: number;
  amount_additional: number;
  authentication_code?: string;
  settlement_institution: string;
  settled_at?: Date;
  created_by?: string;
  due_date?: string;
  created_at: Date;
  updated_at: Date;
  payments?: any[];
}

