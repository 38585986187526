import { Typography, Tooltip } from 'antd';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import { format } from 'date-fns';
import { dinheiroMask } from 'masks-br';
import CardCollapse from '../../../../components/CardCollapse';
import {
  Charge,
  chargeStatusOptions,
  chargeTypeLabels,
} from '../../services/types';
import { Link } from 'react-router-dom';
import { MdOpenInNew } from 'react-icons/md';
import Option from '../../../../types/Option';
import { getOriginIcon } from '../..';

const { Paragraph } = Typography;

interface PropsChargeInfoGeneral {
  data: Charge;
  loading: boolean;
}

const ChargeInfoGeneral: React.FC<PropsChargeInfoGeneral> = ({
  data,
  loading,
}: PropsChargeInfoGeneral) => {
  const created_at = useMemo(() => {
    if (!data?.created_at) return '';
    return format(new Date(data.created_at), 'dd/MM/yyyy HH:mm:ss');
  }, [data]);
  const due_date = useMemo(() => {
    if (!data?.due_date) return '';
    return format(new Date(data.due_date), 'dd/MM/yyyy');
  }, [data]);

  const status = useMemo(() => {
    const statusOption = chargeStatusOptions.find(
      item => item.value === data.id_status,
    ) as Option;

    return statusOption?.label as any;
  }, [data.id_status]);

  return (
    <>
      <CardCollapse header={false} loading={loading}>
        <Header>
          <div className="row row-header">
            <div>{created_at}</div>
            <div>
              {data?.id_charge && (
                <Paragraph copyable>{data?.id_charge}</Paragraph>
              )}
            </div>
          </div>
          <div className="row row-body">
            <div className="status">
              <span className="value">{dinheiroMask(data.amount_total)}</span>
              {status}
            </div>
            <div>
              <Tooltip title="">
                <Link
                  to={`/checkout/${data.checkout_id}`}
                  className="ant-btn ant-btn-circle"
                >
                  <MdOpenInNew />
                </Link>
              </Tooltip>
            </div>
          </div>
        </Header>
        <Body>
          <span className="body-item">
            <h3>Origem</h3>
            <p>{getOriginIcon(data.source)}</p>
          </span>
          <span className="body-item">
            <h3>Parcelas</h3>
            <p>{data.installment_number}</p>
          </span>
          <span className="body-item">
            <h3>Valor da parcela</h3>
            <p>{dinheiroMask(data.installment_amount)}</p>
          </span>
          <span className="body-item">
            <h3>Tipo</h3>
            <p>{chargeTypeLabels[data.type]}</p>
          </span>
          {due_date && (
            <span className="body-item">
              <h3>Vencimento</h3>
              <p>{due_date}</p>
            </span>
          )}
        </Body>
      </CardCollapse>
    </>
  );
};

export default ChargeInfoGeneral;

export const Header = styled.div`
  border-bottom: 1px solid #f1f1f1;

  .row {
    display: flex;
    justify-content: space-between;

    span.value {
      font-size: 40px;
      margin-right: 25px;
      font-weight: lighter;
    }

    div {
      .ant-typography {
        margin: 0;
      }
    }
  }

  .row-header {
    font-size: 13px;
    color: #717171;

    .ant-typography {
      color: #717171;
    }
  }

  .row-body {
    margin-top: 10px;
    display: flex;
    align-items: center;

    .status {
      display: flex;
      align-items: center;
    }
  }
`;

export const Body = styled.div`
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;

  #body-item-cart {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2px;
  }

  span.body-item {
    /* min-width: 200px; */
    max-width: 300px;
    padding: 0 25px;
    height: 50px;
    border-right: 1px solid #f1f1f1;

    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
      border-right: none;
    }

    h3 {
      margin-bottom: 0;
      font-size: 13px;
      color: #a7a4a4;
      text-transform: uppercase;
    }

    p {
      font-size: 15px;
      margin: 0;
    }
  }
`;
