import React from 'react';
import { Result } from 'antd';
import { Link } from 'react-router-dom';

const DefaultNotFound: React.FC = () => {
  return (
    <Result
      status="404"
      title="Opssss, essa página não existe"
      extra={<Link to="/dashboard">Voltar para o Dashboard</Link>}
    />
  );
}

export default DefaultNotFound;