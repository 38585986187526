import React, { useMemo } from 'react';
import { format, parseISO } from 'date-fns';
import { dinheiroMask, porcentagemMask } from 'masks-br';
import { Col, Divider, List, Row, Tag, Tooltip, Typography } from 'antd';

import UserName from '../User/Name';
import CardCollapse from '../CardCollapse';
import {
  PaymentLinkInfoData,
  PaymentLinkInfoItem,
  StatusType,
} from '../../pages/LinkDePagamento/services/types/links';

import * as S from './styles';
import { Link } from 'react-router-dom';
import { AiOutlineShoppingCart } from 'react-icons/ai';
import { MdInfo } from 'react-icons/md';
import { getChargeTypeName } from '../../utils/checkout';
import PaymentLinkButton from '../PaymentLinkButton';
import PropertyList from '../PropertyList';
import { formatDate } from '../../utils/date';

const { Paragraph } = Typography;

type Props = {
  data: PaymentLinkInfoData;
  isAdmin: boolean;
};

export default function PaymentLinkInfoCard({
  data,
  isAdmin,
}: Props): JSX.Element {
  function findStatusColor(status: StatusType) {
    switch (status.toLowerCase()) {
      case 'created':
        return <Tag color="blue">Criado</Tag>;
      case 'payed':
        return <Tag color="green">Pago</Tag>;
      case 'expired':
        return <Tag color="yellow">Expirado</Tag>;
      case 'canceled':
        return <Tag color="red">Cancelado</Tag>;
      default:
        return;
    }
  }

  const charge_methods = useMemo(() => {
    return data.charge_methods.map(method => (
      <Tag>{getChargeTypeName(method)}</Tag>
    ));
  }, [data]);
  const clicks_counter = useMemo(() => {
    if (Number(data.clicks_counter) === 0) return 'Nenhuma';
    return `${data.clicks_counter} vezes`;
  }, [data]);

  return (
    <>
      <CardCollapse>
        <S.HeaderRow>
          <p>
            {data?.created_at
              ? format(new Date(data.created_at), 'dd/MM/yyyy HH:mm:ss')
              : '-'}
          </p>
          <Paragraph copyable style={{ color: '#717171' }}>
            {data.id_payment_link}
          </Paragraph>
        </S.HeaderRow>

        <S.BodyContainer>
          <S.BodyAmountRow>
            <S.BodyAmountText>
              {dinheiroMask(data.total_amount)}
            </S.BodyAmountText>
            {findStatusColor(data.id_status)}
            <S.LinkRow>
              <PaymentLinkButton url={data.url} target="_blank" />
            </S.LinkRow>
          </S.BodyAmountRow>

          <Divider style={{ margin: '0px 0px 10px 0px' }} />

          <S.BodyInfoRow>
            <S.BodyInfoCol>
              <S.InfoLabel>Estabelecimento</S.InfoLabel>
              <UserName id_user={data.establishment_id} />
            </S.BodyInfoCol>

            <S.InfoDivider type="vertical" />

            <S.BodyInfoCol>
              <S.InfoLabel>Usuário</S.InfoLabel>
              <UserName id_user={data.user_id} />
            </S.BodyInfoCol>

            <S.InfoDivider type="vertical" />

            <S.BodyInfoCol>
              <S.InfoLabel>Criado por</S.InfoLabel>
              <UserName id_user={data.created_by} />
            </S.BodyInfoCol>

            {data.checkout_id && isAdmin && (
              <>
                <S.InfoDivider type="vertical" />
                <S.BodyInfoCol>
                  <S.InfoLabel>Checkout</S.InfoLabel>
                  <Tooltip title="Abrir carrinho">
                    <Link
                      className="ant-btn ant-btn-round ant-btn-sm"
                      to={`/checkout/${data.checkout_id}`}
                    >
                      <AiOutlineShoppingCart />
                    </Link>
                  </Tooltip>
                </S.BodyInfoCol>
              </>
            )}
          </S.BodyInfoRow>
        </S.BodyContainer>
      </CardCollapse>
      <Row style={{ marginTop: '32px' }} gutter={16}>
        <Col md={12}>
          <CardCollapse title="Informações">
            <PropertyList.Container>
              <PropertyList.Line>
                <PropertyList.Label>Parcelas</PropertyList.Label>
                <PropertyList.Value>
                  {data?.installment_number || '-'}
                </PropertyList.Value>
              </PropertyList.Line>
              <PropertyList.Line>
                <PropertyList.Label>Expiração</PropertyList.Label>
                <PropertyList.Value>
                  {data?.expiration_date
                    ? formatDate(parseISO(data?.expiration_date), 'date')
                    : '-'}
                </PropertyList.Value>
              </PropertyList.Line>
              <PropertyList.Line>
                <PropertyList.Label>Formas de pagamento</PropertyList.Label>
                <PropertyList.Value>{charge_methods}</PropertyList.Value>
              </PropertyList.Line>
              <PropertyList.Line>
                <PropertyList.Label>Visualizado</PropertyList.Label>
                <PropertyList.Value>{clicks_counter}</PropertyList.Value>
              </PropertyList.Line>
              <PropertyList.Line>
                <PropertyList.Label>Email notificado</PropertyList.Label>
                <PropertyList.Value>
                  {data.notification_email ?? '-'}
                </PropertyList.Value>
              </PropertyList.Line>
            </PropertyList.Container>

            <Divider orientation="left">Valores</Divider>

            <PropertyList.Container>
              <PropertyList.Line>
                <PropertyList.Label>Original</PropertyList.Label>
                <PropertyList.Value>
                  {dinheiroMask(data?.original_amount)}
                </PropertyList.Value>
              </PropertyList.Line>
              <PropertyList.Line>
                <PropertyList.Label>Multa</PropertyList.Label>
                <PropertyList.Value>
                  + {dinheiroMask(data?.fine_amount)}
                </PropertyList.Value>
              </PropertyList.Line>
              <PropertyList.Line>
                <PropertyList.Label>Taxa</PropertyList.Label>
                <PropertyList.Value>
                  + {dinheiroMask(data?.fee_amount)}
                </PropertyList.Value>
              </PropertyList.Line>
              <PropertyList.Line>
                <PropertyList.Label>Desconto</PropertyList.Label>
                <PropertyList.Value>
                  - {dinheiroMask(data?.discount_amount)}
                </PropertyList.Value>
              </PropertyList.Line>
              <PropertyList.Line>
                <PropertyList.Label>Final</PropertyList.Label>
                <PropertyList.Value>
                  {dinheiroMask(data?.total_amount)}
                </PropertyList.Value>
              </PropertyList.Line>
            </PropertyList.Container>

            <Divider orientation="left">Redirecionamento</Divider>

            <PropertyList.Container>
              <PropertyList.Line>
                <PropertyList.Label>Sucesso</PropertyList.Label>
                <PropertyList.Value>
                  {data?.redirect?.success_url ? (
                    <Paragraph copyable>
                      {data?.redirect?.success_url}
                    </Paragraph>
                  ) : (
                    '-'
                  )}
                </PropertyList.Value>
              </PropertyList.Line>
              <PropertyList.Line>
                <PropertyList.Label>Expirado</PropertyList.Label>
                <PropertyList.Value>
                  {data?.redirect?.expired_url ? (
                    <Paragraph copyable>
                      {data?.redirect?.expired_url}
                    </Paragraph>
                  ) : (
                    '-'
                  )}
                </PropertyList.Value>
              </PropertyList.Line>
            </PropertyList.Container>
          </CardCollapse>
        </Col>

        <Col md={12}>
          <CardCollapse title="Itens">
            <List
              itemLayout="horizontal"
              dataSource={data?.items}
              renderItem={item => (
                <List.Item actions={getLink(isAdmin, item)}>
                  <List.Item.Meta
                    title={<b>{item.title}</b>}
                    description={item.description}
                  />
                  <b>{dinheiroMask(item.amount)}</b>
                </List.Item>
              )}
            />
          </CardCollapse>
          {!!data.splits?.length && (
            <CardCollapse title="Split">
              <List
                itemLayout="horizontal"
                dataSource={data.splits}
                renderItem={({ amount, establishment_id, type }) => (
                  <List.Item>
                    <List.Item.Meta
                      title={<UserName id_user={establishment_id} />}
                    />
                    <b>
                      {type === 'FIXED'
                        ? dinheiroMask(amount)
                        : porcentagemMask(amount)}
                    </b>
                  </List.Item>
                )}
              />
            </CardCollapse>
          )}
        </Col>
      </Row>
    </>
  );
}

function getLink(isAdmin: boolean, item: PaymentLinkInfoItem) {
  if (!isAdmin) {
    return [];
  }

  let link: string | null = null;

  if (item.bill_id) link = `/boleto/${item.bill_id}`;

  if (!link) {
    return [];
  }

  return [
    <Tooltip title="Abrir informações">
      <Link className="ant-btn" to={link}>
        <MdInfo />
      </Link>
    </Tooltip>,
  ];
}
