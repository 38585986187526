import React, { useState, useEffect } from 'react';
import {
  Button,
  Form,
  Row,
  Col,
  Input,
  Select,
  Tooltip,
  Modal,
  Spin,
  DatePicker,
  message,
  Space,
  Popover,
} from 'antd';
import { format } from 'date-fns';
import { dinheiroMask } from 'masks-br';
import {
  MdFileDownload,
  MdOpenInNew,
  MdPhonelink,
  MdPointOfSale,
  MdOutlineSmartphone,
  MdFiberManualRecord,
} from 'react-icons/md';
import { Link } from 'react-router-dom';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import 'moment/locale/pt-br';
import { useQuery } from 'react-query';
import { LoadingOutlined } from '@ant-design/icons';

import PageHeader from '../../../components/PageHeader';
import Table from '../../../components/Table';
import { fetchApi } from '../../../services/api';
import Pagina from '../../../types/Pagina';
import CardCollapse from '../../../components/CardCollapse';
import { ItemInputCurrency } from '../../../components/Form';
import { setQueryParams, useQueryParams } from '../../../utils/UrlQuery';
import { usePermission } from '../../../hooks/usePermissions';
import Option from '../../../types/Option';
import downloadFiles from '../../../utils/downloadFiles';

import { findStatusColor } from '..';
import UserName from '../../../components/User/Name';
import { uuid_pattern } from '../../../utils/pattern';

const { RangePicker } = DatePicker;

type DataFiltersParams = {
  id_checkout?: string;
  origin?: string;
  establishment_name?: string;
  id_status?: string;
  total_amount?: number;
  data?: [moment.Moment | null, moment.Moment | null];
  date_start?: moment.Moment | null;
  date_end?: moment.Moment | null;
};

const CheckoutSearch: React.FC = () => {
  const default_filters = { page: 1, page_size: 25 };

  const [form] = Form.useForm();
  const query_values = useQueryParams();
  const { getPermissions } = usePermission();

  const permissoes = getPermissions();

  const [loading, setLoading] = useState(true);
  const [carrinho, setCarrinho] = useState<Pagina<any[]>>({} as Pagina);
  const [filtros, setFiltros] = useState<any | undefined>(undefined);

  useEffect(() => {
    let data: [moment.Moment, moment.Moment] | undefined;

    if (query_values.date_start && query_values.date_end) {
      data = [moment(query_values.date_start), moment(query_values.date_end)];
    }

    setFiltros({
      ...default_filters,
      ...query_values,
      data,
    });
    form.setFieldsValue({ ...query_values, data });
  }, []);

  useEffect(() => {
    if (filtros) {
      fetchCarrinhos();
    }
  }, [filtros]);

  const { isFetching: isStatusLoading, data: statusList } = useQuery<Option[]>({
    queryKey: ['checkout-status'],
    queryFn: fetchCheckoutStatus,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
    refetchOnReconnect: false,
  });

  async function fetchCarrinhos() {
    return fetchApi({
      url: '/backoffice/checkout',
      method: 'get',
      query_params: filtros,
      onSuccess: data => {
        const list = data.data.map((item: any) => {
          return {
            ...item,
            created_at: format(
              new Date(item.created_at),
              'dd/MM/yyyy HH:mm:ss',
            ),
          };
        });
        setCarrinho({
          ...data,
          data: list,
        });
        setLoading(false);
      },
      onError(error) {
        message.error(error.message);
        setLoading(false);
      },
    });
  }
  async function fetchCheckoutStatus() {
    return fetchApi({
      url: '/backoffice/checkout/status',
      method: 'get',
      onSuccess: data => {
        const opts: Option[] = data.data.map((item: any) => ({
          label: findStatusColor(item.name, item.type, item.description),
          value: item.id_status,
        }));

        return opts;
      },
    });
  }

  function handleResetForm() {
    setQueryParams(default_filters);
    form.resetFields();
    setFiltros(default_filters);
  }

  function handleSetFiltroValues(data: DataFiltersParams) {
    let [date_start, date_end] = [...(data?.data || [])];
    if (!date_start && !date_end) {
      const dataField = form.getFieldValue('data') as any;
      [date_start, date_end] = dataField || [];
    }

    data.date_start = date_start;
    data.date_end = date_end;

    delete data.data;

    let finalQueryParams = {
      ...default_filters,
      ...data,
      date_start: date_start?.format('YYYY-MM-DD') || date_start,
      date_end: date_end?.format('YYYY-MM-DD') || date_end,
    };

    if (data.total_amount !== undefined && data.total_amount === 0)
      delete finalQueryParams.total_amount;

    if (data.establishment_name)
      finalQueryParams.establishment_name = data.establishment_name.trim();

    setQueryParams(finalQueryParams);
    setFiltros(finalQueryParams);
  }

  function handleUpdatePagination(page: number, page_size?: number) {
    const queryParams = { ...filtros, page, page_size };
    setQueryParams(queryParams);
    setFiltros(queryParams);
  }

  async function downloadFile(type = 'csv') {
    Modal.info({
      title: 'A exportação utilizará os filtros da pesquisa!',
      centered: true,
      onOk() {
        return downloadFiles(
          `/backoffice/checkout/export`,
          `exportacao_carrinho.${type}`,
          undefined,
          { ...filtros, file_type: type },
          { 'api-version': '1' },
        );
      },
    });
  }

  const getOriginIcon = (origin: string) => {
    const color = '#443d3e';
    const size = 25;

    if (origin.toLocaleLowerCase() === 'pos') {
      return (
        <Tooltip placement="right" title="POS">
          <MdOutlineSmartphone size={size} color={color} />
        </Tooltip>
      );
    }

    if (origin.toLocaleLowerCase() === 'online') {
      return (
        <Tooltip placement="right" title="Online">
          <MdPhonelink size={size} color={color} />
        </Tooltip>
      );
    }

    if (origin.toLocaleLowerCase() === 'totem') {
      return (
        <Tooltip placement="right" title="Totem">
          <MdPointOfSale size={size} color={color} />
        </Tooltip>
      );
    }

    return origin.toLocaleUpperCase();
  };

  const columns: ColumnsType<any> = [
    {
      key: 'origin',
      dataIndex: 'origin',
      width: 50,
      render: origin => <div test-id={origin}>{getOriginIcon(origin)}</div>,
    },
    {
      title: 'Estabelecimento',
      align: 'center',
      render: (item: any) => {
        let data = null;

        if (item.establishment_id !== item.created_by) {
          data = data = (
            <Popover
              content={<UserName id_user={item.created_by} />}
              trigger="hover"
            >
              <MdFiberManualRecord />
            </Popover>
          );
        }

        return (
          <Space>
            <UserName
              id_user={item.establishment_id}
              name={item.establishment}
            />
            {data}
          </Space>
        );
      },
    },
    {
      title: 'Valor',
      key: 'total_amount',
      dataIndex: 'total_amount',
      align: 'center',
      render: (item: number) => dinheiroMask(item),
    },
    {
      title: 'Status',
      key: 'id_status',
      dataIndex: 'id_status',
      align: 'center',
      render: (item: string) => {
        if (isStatusLoading)
          return (
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 18 }} spin />}
            />
          );

        const status = statusList?.find(i => i.value === item);

        return status?.label;
      },
    },
    {
      title: 'Criado em',
      align: 'center',
      key: 'created_at',
      dataIndex: 'created_at',
      width: 200,
    },
    {
      title: 'Abrir',
      width: 80,
      align: 'center',
      render: (data: any) =>
        permissoes.CARRINHO_INFORMACAO ? (
          <Link
            to={`/checkout/${data.id_checkout}`}
            className="ant-btn ant-btn-primary"
          >
            <MdOpenInNew />
          </Link>
        ) : (
          <Button type="primary" disabled>
            <MdOpenInNew />
          </Button>
        ),
    },
  ];

  return (
    <>
      <PageHeader title="Checkout" breadcrumb={['Checkout']}>
        {permissoes.COMISSAO_LISTAGEM ? (
          <Link className="ant-btn ant-btn-primary" to="/comissao/pendente">
            Abrir comissões a serem pagas
          </Link>
        ) : null}
      </PageHeader>
      <CardCollapse title="Filtros">
        <Form
          layout="vertical"
          onFinish={data => handleSetFiltroValues(data)}
          form={form}
        >
          <Row gutter={16}>
            <Col md={5} xs={12}>
              <Form.Item
                name="id_checkout"
                label="Id"
                rules={[
                  { pattern: uuid_pattern, message: 'Deve ser um ID  válido!' },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={4} xs={12}>
              <Form.Item name="origin" label="Origem">
                <Select allowClear>
                  <Select.Option value="ONLINE">Online</Select.Option>
                  <Select.Option value="POS">POS</Select.Option>
                  <Select.Option value="TOTEM">Totem</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col md={8} xs={12}>
              <Form.Item name="establishment_name" label="Estabelecimento">
                <Input />
              </Form.Item>
            </Col>
            <Col md={6} xs={12}>
              <Form.Item name="id_status" label="Status">
                <Select
                  options={statusList}
                  loading={isStatusLoading}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col md={6} xs={12}>
              <Form.Item name="total_amount" label="Valor">
                <ItemInputCurrency
                  onChange={(_, val) =>
                    form.setFieldsValue({ total_amount: val })
                  }
                />
              </Form.Item>
            </Col>
            <Col md={6} xs={12}>
              <Form.Item name="data" label="Data de criação">
                <RangePicker
                  format="DD/MM/YYYY"
                  placeholder={['Data Inicio', 'Data Fim']}
                  allowClear
                />
              </Form.Item>
            </Col>
          </Row>

          <br />

          <Row justify="end">
            <Button onClick={handleResetForm} className="buttonMarginRight">
              Limpar
            </Button>
            <Button type="primary" htmlType="submit">
              Filtrar
            </Button>
          </Row>
        </Form>
      </CardCollapse>

      <Table
        columns={columns}
        dataSource={carrinho.data}
        loading={loading}
        size="small"
        pagination={{
          total: carrinho.total,
          current: carrinho.page,
          pageSize: carrinho.page_size,
          onChange: handleUpdatePagination,
        }}
      >
        <Tooltip placement="topRight" title="Exportar para CSV">
          <Button
            onClick={() => downloadFile()}
            type="primary"
            shape="round"
            icon={<MdFileDownload />}
          />
        </Tooltip>
      </Table>
    </>
  );
};

export default CheckoutSearch;
