import styled from 'styled-components';

export const Container = styled.div`
  border-bottom: 1px solid white;
  padding-bottom: 10px;
  margin-top: 25px;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LeftContainer = styled.div``;

export const RightContainer = styled.div`
  a {
    margin-left: 10px;
  }
`;

export const Breadcrumb = styled.div`
  background: white;
  padding: 5px 8px 2px;
  border-radius: 8px;
  font-size: 15px;

  ul {
    margin-bottom: 0;
    color: #a8a8a8;
    margin-top: -4px;
    display: flex;
    align-items: center;

    svg {
      padding-top: 4px;
      margin: 0 6px;
    }
  }
`;
