import {
  Button,
  Col,
  Form,
  List,
  Modal,
  Popconfirm,
  Row,
  Select,
  Table as TableAntd,
} from 'antd';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { MdList, MdRemoveCircle } from 'react-icons/md';
import { Link } from 'react-router-dom';

import CardCollapse from '../../../../components/CardCollapse';
import { useApi } from '../../../../hooks/useApi';
import { usePermission } from '../../../../hooks/usePermissions';
import { useUtils } from '../../../../hooks/useUtils';
import Option from '../../../../types/Option';
import { findTagByTipo } from '../../../../utils/findTagColorByTipo';

type IState = {
  id_usuario: string;
  loading: boolean;
  default_message: string;
  permissoes: any[];
  onAddPermissao: () => void;
  onRemovePermissao: () => void;
};

const CardPermissoes: React.FC<IState> = ({
  loading,
  default_message,
  permissoes,
  id_usuario,
  onAddPermissao,
  onRemovePermissao,
}: IState) => {
  const [form] = Form.useForm();
  const [form_update] = Form.useForm();
  const { getResponsabilidade, getStatusPermissao } = useUtils();
  const { userPermissions } = usePermission();

  const { loading: loadingAdd, fetchRequest: fetchAdd } = useApi();
  const { loading: loadingRemove, fetchRequest: fetchRemove } = useApi();
  const { loading: loadingHistorico, fetchRequest: fetchHistorico } = useApi();
  const { loading: loadingUpdateStatus, fetchRequest: fetchUpdateStatus } =
    useApi();

  const [responsabilidade, setResponsabilidade] = useState<Option[]>([]);
  const [modalHistoricoStatus, setModalHistoricoStatus] =
    useState<boolean>(false);
  const [historicoStatus, setHistoricoStatus] = useState<any[]>([]);
  const [permissaoStatus, setPermissaoStatus] = useState<Option[]>([]);
  const [usuarioPermissaoSelected, setUsuarioPermissaoSelected] =
    useState<string>();

  useEffect(() => {
    getResponsabilidade(true).then(responsabilidade_response => {
      // Get permission already added
      const permissionAlreadyAdded: string[] = permissoes
        .filter(permissao => permissao.status_tipo !== 'danger')
        .map(permissao => permissao.responsabilidade);

      // Remove permission already added in list for add
      const responsabilidade_response_filtered =
        responsabilidade_response.filter(
          responsabilidade_res =>
            !permissionAlreadyAdded.includes(responsabilidade_res.nome),
        );

      setResponsabilidade(
        responsabilidade_response_filtered?.map((item: any) => ({
          value: item.id_responsabilidade,
          label: item.nome,
        })),
      );
    });
    getStatusPermissao().then(status => {
      setPermissaoStatus(
        status.map(item => ({
          label: findTagByTipo(item.nome, item.tipo),
          value: item.id_status_permissao,
        })),
      );
    });
  }, [permissoes]);

  function handleAddPermissao(data: { id_responsabilidade: number }) {
    return fetchAdd({
      url: `/usuario/${id_usuario}/permissao`,
      method: 'post',
      data: {
        id_responsabilidade: data.id_responsabilidade,
      },
      onSuccess: () => {
        form.resetFields();
        onAddPermissao();
      },
    });
  }

  function handleRemovePermissao(id_usuario_permissao: string) {
    return fetchRemove({
      url: `/permissao/${id_usuario_permissao}`,
      method: 'delete',
      onSuccess: () => onRemovePermissao(),
    });
  }

  function handleFetchHistoricoPermissao(id_usuario_permissao: string) {
    return fetchHistorico({
      url: `/permissao/${id_usuario_permissao}/status`,
      method: 'get',
      onSuccess: (data: any) => {
        setUsuarioPermissaoSelected(id_usuario_permissao);
        setModalHistoricoStatus(true);
        setHistoricoStatus(data);
      },
    });
  }

  function closeModal() {
    setUsuarioPermissaoSelected(undefined);
    setModalHistoricoStatus(false);
  }

  function handleUpdateStatus(data: any) {
    fetchUpdateStatus({
      url: `/permissao/status`,
      method: 'put',
      data: {
        id_usuario_permissao: [usuarioPermissaoSelected],
        id_status_permissao: data.id_status_permissao,
      },
      onSuccess: async () => {
        form_update.resetFields();
        await handleFetchHistoricoPermissao(usuarioPermissaoSelected!);
        onAddPermissao();
      },
    });
  }

  const columns_permissoes = [
    {
      title: 'Permissão',
      dataIndex: 'responsabilidade',
      key: 'responsabilidade',
      render: (item: string, data: any) => (
        <Link to={`/responsabilidade/${btoa(data.id_responsabilidade)}`}>
          {item}
        </Link>
      ),
    },
    // {
    //   title: 'Criado Por',
    //   dataIndex: 'criado_por_nome',
    //   key: 'criado_por_nome',
    //   render: (item: string, data: any) => (
    //     <Link to={`/usuario/${data.id_usuario}`}>{item}</Link>
    //   ),
    // },
    {
      title: 'Status',
      dataIndex: 'status_nome',
      key: 'status_nome',
      render: (item: string, data: any) =>
        findTagByTipo(item, data.status_tipo),
    },
    {
      title: 'Histórico',
      width: 100,
      render: (data: any) => (
        <Button
          type="primary"
          loading={loadingHistorico}
          onClick={() =>
            handleFetchHistoricoPermissao(data.id_usuario_permissao)
          }
        >
          <MdList />
        </Button>
      ),
    },
    // {
    //   title: 'Criado Em',
    //   dataIndex: 'criado_em',
    //   key: 'criado_em',
    // },
    {
      title: 'Remover',
      width: 100,
      render: (data: any) => (
        <Popconfirm
          placement="topRight"
          title="Tem certeza que deseja remover essa permissão?"
          onConfirm={() => handleRemovePermissao(data.id_usuario_permissao)}
          disabled={!userPermissions.PERMISSAO_DESVINCULAR_USUARIO}
        >
          <Button
            type="primary"
            disabled={
              data.removido_em || !userPermissions.PERMISSAO_DESVINCULAR_USUARIO
            }
            danger
            loading={loadingRemove}
          >
            <MdRemoveCircle />
          </Button>
        </Popconfirm>
      ),
    },
  ];

  return (
    <CardCollapse loading={loading} title="Permissões">
      {userPermissions.PERMISSAO_VINCULAR_USUARIO ? (
        <>
          <Form form={form} layout="vertical" onFinish={handleAddPermissao}>
            <Row justify="end" gutter={16}>
              <Col md={18} xs={24}>
                <Form.Item
                  name="id_responsabilidade"
                  rules={[{ required: true, message: default_message }]}
                >
                  <Select
                    placeholder="Selecione uma Responsabilidade"
                    options={responsabilidade}
                  />
                </Form.Item>
              </Col>
              <Col md={6} xs={24}>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="buttonMarginTopLow"
                  block
                  loading={loadingAdd}
                >
                  Adicionar
                </Button>
              </Col>
            </Row>
          </Form>

          <br />
        </>
      ) : null}

      <TableAntd
        columns={columns_permissoes}
        dataSource={permissoes}
        pagination={false}
      />

      <Modal
        visible={modalHistoricoStatus}
        onCancel={() => closeModal()}
        centered
        title="Histórico de Status"
        footer={false}
      >
        {userPermissions.PERMISSAO_ALTERAR_STATUS ? (
          <>
            <Form
              form={form_update}
              layout="vertical"
              onFinish={handleUpdateStatus}
            >
              <Row justify="end" gutter={16}>
                <Col md={18} xs={24}>
                  <Form.Item
                    name="id_status_permissao"
                    rules={[{ required: true, message: default_message }]}
                  >
                    <Select placeholder="" options={permissaoStatus} />
                  </Form.Item>
                </Col>
                <Col md={6} xs={24}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="buttonMarginTopLow"
                    block
                    loading={loadingUpdateStatus}
                  >
                    Alterar
                  </Button>
                </Col>
              </Row>
            </Form>

            <br />
          </>
        ) : null}

        <List
          itemLayout="horizontal"
          dataSource={historicoStatus}
          loading={loadingHistorico}
          renderItem={(item: any) => (
            <List.Item>
              <List.Item.Meta
                title={findTagByTipo(item.status, item.status_tipo)}
                description={format(
                  new Date(item.criado_em),
                  'dd/MM/yyyy HH:mm',
                )}
              />
              <Link to={`/usuario/${item.criado_por}`}>
                {item.criado_por_nome}
              </Link>
            </List.Item>
          )}
        />
      </Modal>
    </CardCollapse>
  );
};

export default CardPermissoes;
