import { Button, Form, Input, Modal, Result, Timeline, Typography } from 'antd';
import { format } from 'date-fns';
import React, { useMemo, useState } from 'react';

import CardCollapse from '../../../../components/CardCollapse';
import { useApi } from '../../../../hooks/useApi';
import { Container } from './styles';

const { Text, Paragraph } = Typography;

const default_message = 'Esse campo é obrigatório';

type ICardAnotacao = {
  id_usuario: string;
  anotacoes: any[];
  onAddAnotacao: () => void;
  loading: boolean;
};

const CardAnotacao: React.FC<ICardAnotacao> = ({
  id_usuario: id,
  anotacoes,
  onAddAnotacao,
  loading,
}: ICardAnotacao) => {
  const [modalAnotacao, setModalAnotacao] = useState(false);
  const [modalForm] = Form.useForm();

  const { fetchRequest, loading: loadingCreate } = useApi();

  const timelime = useMemo(() => {
    if (!anotacoes.length) {
      return <Result subTitle="Nenhuma anotação feita" />;
    }

    return (
      <Container>
        <Timeline>
          {anotacoes.map(anotacao => {
            const data = format(
              new Date(anotacao.criado_em),
              'dd/MM/yyyy HH:mm:ss',
            );

            return (
              <Timeline.Item key={anotacao}>
                <strong>{anotacao.usuario_nome}</strong>
                <br />
                <Text type="secondary">{data}</Text>
                <br />
                <br />
                <strong>{anotacao.titulo}</strong>
                <br />
                <br />
                <Paragraph
                  ellipsis={{
                    rows: 5,
                    expandable: true,
                  }}
                >
                  <span>{anotacao.descricao}</span>
                </Paragraph>
              </Timeline.Item>
            );
          })}
        </Timeline>
      </Container>
    );
  }, [anotacoes]);

  function handleCreate(data: any) {
    return fetchRequest({
      method: 'post',
      url: `/usuario/${id}/anotacao`,
      data,
      messages: {
        error: 'Erro ao criar anotação, tente novamente!',
      },
      onSuccess: () => {
        setModalAnotacao(false);
        modalForm.resetFields();
        onAddAnotacao();
      },
    });
  }

  return (
    <>
      <CardCollapse title="Anotações" loading={loading}>
        <Button block onClick={() => setModalAnotacao(true)}>
          Criar anotação
        </Button>

        <br />
        <br />

        {timelime}
      </CardCollapse>

      <Modal
        title="Criar Anotação"
        visible={modalAnotacao}
        centered
        onCancel={() => setModalAnotacao(false)}
        footer={false}
      >
        <Form
          layout="vertical"
          form={modalForm}
          onFinish={data => handleCreate(data)}
        >
          <Form.Item
            label="Título"
            name="titulo"
            rules={[{ required: true, message: default_message }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Descrição"
            name="descricao"
            rules={[{ required: true, message: default_message }]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>

          <br />
          <Button type="primary" htmlType="submit" loading={loadingCreate}>
            Criar
          </Button>
        </Form>
      </Modal>
    </>
  );
};

export default CardAnotacao;
