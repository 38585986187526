import React from 'react';
import { Tag } from 'antd';
import {
  AiFillCheckCircle,
  AiFillInfoCircle,
  AiFillCloseCircle,
  AiFillCreditCard,
  AiOutlineCreditCard,
} from 'react-icons/ai';
import { FaShieldAlt } from 'react-icons/fa';
import { RiFilePaperLine } from 'react-icons/ri';
import { MdFileUpload } from 'react-icons/md';
import { GiPunch } from 'react-icons/gi';

import { StatusTipo } from '../../store/modules/utils/types';

export function findStatusCarrinho(carrinho: any) {
  if (carrinho?.removido_em) {
    return <Tag color="red">Removido</Tag>;
  }

  if (carrinho?.concluido_em) {
    return <Tag color="green">Concluido</Tag>;
  }

  return <Tag>Aguardando confirmação</Tag>;
}

export function findStatusColor(name: string, tipo: StatusTipo) {
  let color = '';

  switch (tipo?.toLowerCase()) {
    case 'danger':
    case 'error':
      color = 'red';
      break;
    case 'info':
      color = 'blue';
      break;
    case 'success':
      color = 'green';
      break;

    case 'warning':
      color = 'gold';
      break;

    default:
      color = 'default';
      break;
  }

  return <Tag color={color}>{name}</Tag>;
}

export function findStatusCarrinhoIcon(
  tipo: string,
  id_status_carrinho: number,
) {
  if (id_status_carrinho === 3) {
    return <FaShieldAlt />;
  }
  if (id_status_carrinho === 7) {
    return <AiFillCreditCard />;
  }
  if (id_status_carrinho === 11) {
    return <AiOutlineCreditCard />;
  }
  if (id_status_carrinho === 19) {
    return <RiFilePaperLine />;
  }
  if (id_status_carrinho === 23) {
    return <MdFileUpload />;
  }
  if (id_status_carrinho === 29) {
    return <GiPunch />;
  }

  switch (tipo) {
    case 'danger':
    case 'error':
      return <AiFillCloseCircle />;
    case 'info':
      return <AiFillInfoCircle />;
    case 'success':
      return <AiFillCheckCircle />;

    default:
      return null;
  }
}

export function getColor(info: string) {
  switch (info) {
    case 'danger':
    case 'error':
      return 'red';
    case 'info':
      return 'blue';
    case 'success':
      return 'green';

    default:
      return 'gray';
  }
}

export function findTransacaoStatus(transacao: any) {
  const retorno = {
    status: <Tag color="blue">Aguardando Aprovação</Tag>,
    id_usuario_status: undefined,
    nome_usuario_status: undefined,
  };

  if (transacao.removido_por) {
    retorno.status = <Tag color="red">Transferência Removida</Tag>;
    retorno.id_usuario_status = transacao.removido_por;
    retorno.nome_usuario_status = transacao.nome_usuario_removido;
  }

  if (transacao.token_transacao) {
    retorno.status = <Tag color="green">Transferência Realizada</Tag>;
    retorno.id_usuario_status = transacao.id_usuario_aprovacao;
    retorno.nome_usuario_status = transacao.nome_usuario_aprovacao;
  }

  return retorno;
}

export function findFormaPagamento(
  forma_pagamento: any,
  id_status_carrinho: number,
) {
  if (id_status_carrinho !== 2) {
    return <Tag color="red">Pagamento não concluido</Tag>;
  }

  if (forma_pagamento.pagamento_confirmado_em) {
    return <Tag color="green">Pagamento Confirmado</Tag>;
  }

  if (forma_pagamento.pagamento_estornado_em) {
    return <Tag color="gold">Pagamento Cancelado/Estornado</Tag>;
  }

  return <Tag color="blue">Pagamento Realizado</Tag>;
}
