import {
  Avatar,
  Modal,
  Tag,
  Divider,
  Typography,
  Tooltip,
  Row,
  Col,
  Form,
  Space,
} from 'antd';
import { celularMask, cepMask, cnpjMask, cpfMask } from 'masks-br';
import React, { useMemo, useState } from 'react';
import { ImWhatsapp } from 'react-icons/im';
import { MdEmail, MdInfo, MdPhone } from 'react-icons/md';
import styled from 'styled-components';

import CardCollapse from '../../../../components/CardCollapse';
import EnumResponsabilidadeString from '../../../../types/enum/ResponsabilidadeString';
import findTagByDocumentAccount from '../../../../utils/findTagByDocumentAccount';
import { findTagByTipo } from '../../../../utils/findTagColorByTipo';

const { Paragraph } = Typography;

type ICardGeral = {
  loading: boolean;

  info: any;

  perfis: any[];
  permissoes: any[];
};

const CardGeral: React.FC<ICardGeral> = ({
  info,
  loading,
  permissoes,
  perfis,
}: ICardGeral) => {
  const [modal, setModal] = useState(false);

  const documento = useMemo(() => {
    if (info?.cpf) {
      return cpfMask(info.cpf);
    }

    if (info?.cnpj) {
      return cnpjMask(info.cnpj);
    }

    return '';
  }, [info]);

  const checkSuccessInfo = (permissao_responsabilidade: PermissoesFiltered) =>
    permissao_responsabilidade.status_tipo === 'success' ||
    permissao_responsabilidade.status_tipo === 'info';

  const permissoes_data = useMemo(() => {
    // Remove equals
    const permission = Array.from(
      new Set(permissoes.map(permissao => JSON.stringify(permissao))),
    ).map(permissao => JSON.parse(permissao));

    // Filtro para indicador
    let permissoes_indicador = permission.filter(
      permissao =>
        permissao.responsabilidade === EnumResponsabilidadeString.INDICADOR,
    );

    if (permissoes_indicador.some(checkSuccessInfo)) {
      permissoes_indicador = permissoes_indicador.filter(checkSuccessInfo);
    }

    // Filtro para representante
    let permissoes_representante = permission.filter(
      permissao =>
        permissao.responsabilidade === EnumResponsabilidadeString.REPRESENTANTE,
    );

    if (permissoes_representante.some(checkSuccessInfo)) {
      permissoes_representante = permissoes_representante.filter(
        checkSuccessInfo,
      );
    }

    // Filtro para estabelecimento
    let permissoes_estabelecimento = permission.filter(
      permissao =>
        permissao.responsabilidade ===
        EnumResponsabilidadeString.ESTABELECIMENTO,
    );

    if (permissoes_estabelecimento.some(checkSuccessInfo)) {
      permissoes_estabelecimento = permissoes_estabelecimento.filter(
        checkSuccessInfo,
      );
    }

    // Filtro para responsavel
    let permissoes_responsavel = permission.filter(
      permissao =>
        permissao.responsabilidade === EnumResponsabilidadeString.RESPONSAVEL,
    );

    if (permissoes_responsavel.some(checkSuccessInfo)) {
      permissoes_responsavel = permissoes_responsavel.filter(checkSuccessInfo);
    }

    // Filtro para incentivador
    let permissoes_incentivador = permission.filter(
      permissao =>
        permissao.responsabilidade === EnumResponsabilidadeString.INCENTIVADOR,
    );

    if (permissoes_incentivador.some(checkSuccessInfo)) {
      permissoes_incentivador = permissoes_incentivador.filter(
        checkSuccessInfo,
      );
    }

    const permissoes_filtered: PermissoesFiltered[] = [
      ...permissoes_indicador,
      ...permissoes_representante,
      ...permissoes_estabelecimento,
      ...permissoes_responsavel,
      ...permissoes_incentivador,
    ];

    return permissoes_filtered.map(permission_filtered =>
      findTagByTipo(
        permission_filtered.responsabilidade,
        permission_filtered.status_tipo,
      ),
    );
  }, [permissoes]);

  const perfis_data = useMemo(() => {
    if (!perfis || !perfis.length) {
      return null;
    }

    return perfis?.map(item => <Tag>{item.perfil}</Tag>);
  }, [perfis]);

  const contatos = useMemo(() => {
    if (!info?.email && !info?.telefone) {
      return null;
    }

    return (
      <Space>
        {info.email ? (
          <a
            className="ant-btn ant-btn-circle ant-btn-icon-only"
            href={`mailto:${info.email}`}
          >
            <MdEmail />
          </a>
        ) : null}
        {info.telefone ? (
          <>
            <a
              className="ant-btn ant-btn-circle ant-btn-icon-only"
              href={`tel:${info.telefone}`}
            >
              <MdPhone />
            </a>
            <a
              className="ant-btn ant-btn-circle ant-btn-icon-only"
              href={`https://api.whatsapp.com/send?phone=${info.telefone}`}
              target="blank"
            >
              <ImWhatsapp />
            </a>
          </>
        ) : null}
      </Space>
    );
  }, [perfis]);

  const documento_conta_pt_verificado = useMemo(() => {
    return findTagByDocumentAccount({
      documento_conta_pt: info?.documento_conta_pt || '',
      documento_conta_pt_verificado: info?.documento_conta_pt_verificado,
    });
  }, [info?.documento_conta_pt_verificado, info?.documento_conta_pt]);

  return (
    <>
      <CardCollapse header={false} loading={loading}>
        <Header>
          <Row justify="space-between" className="top-information">
            <div>
              <Paragraph copyable>{info?.id_usuario}</Paragraph>
            </div>
            <div>
              <Tooltip
                placement="left"
                title="Click para abrir mais informações do usuário"
                mouseEnterDelay={0.5}
                mouseLeaveDelay={0}
              >
                <button type="button" onClick={() => setModal(true)}>
                  <MdInfo />
                </button>
              </Tooltip>
            </div>
          </Row>

          <div className="avatar">
            <Avatar size={64} />
          </div>

          <h3>{info?.nome}</h3>
          <p>{info?.cod_usuario}</p>
          <p>
            <Paragraph copyable={{ tooltips: false }}>{documento}</Paragraph>
          </p>
        </Header>

        {permissoes_data ? (
          <BodyItem>
            <h4>Permissões</h4>

            {permissoes_data}
          </BodyItem>
        ) : null}

        {perfis_data ? (
          <BodyItem>
            <h4>Perfis</h4>

            {perfis_data}
          </BodyItem>
        ) : null}

        {documento_conta_pt_verificado ? (
          <BodyItem>
            <h4>Conta Bancária</h4>

            {documento_conta_pt_verificado}
          </BodyItem>
        ) : null}

        {contatos ? (
          <BodyItem>
            <h4>Contatos</h4>

            {contatos}
          </BodyItem>
        ) : null}
      </CardCollapse>

      <Modal
        title="Informações do Usuário"
        visible={modal}
        centered
        onCancel={() => setModal(false)}
        footer={false}
      >
        <ModalInfo>
          <Form layout="vertical">
            <Row gutter={16}>
              <Col md={12} xs={24}>
                <Form.Item label="Nome">{info?.nome}</Form.Item>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item label="Razão Social">{info?.razao_social}</Form.Item>
              </Col>
              <Col md={8} xs={24}>
                <Form.Item label="CPF">{cpfMask(info?.cpf)}</Form.Item>
              </Col>
              <Col md={8} xs={24}>
                <Form.Item label="CNPJ">{cnpjMask(info?.cnpj)}</Form.Item>
              </Col>
              <Col md={8} xs={24}>
                <Form.Item label="RG">{info?.rg}</Form.Item>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item label="Orgão Emissor">
                  {info?.orgao_emissor}
                </Form.Item>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item label="Estado Emissor">
                  {info?.estado_emissor}
                </Form.Item>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item label="Data Nascimento">
                  {info?.data_nascimento}
                </Form.Item>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item label="Código do Usuário">
                  {info?.cod_usuario}
                </Form.Item>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item label="Telefone">
                  {celularMask(info?.telefone)}
                </Form.Item>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item label="Estado Civil">{info?.estado_civil}</Form.Item>
              </Col>
              <Col md={24} xs={24}>
                <Form.Item label="Email">{info?.email}</Form.Item>
              </Col>
              <Col md={24} xs={24}>
                <Form.Item label="Ramo de Atividade">
                  {info?.ramo_atividade}
                </Form.Item>
              </Col>
            </Row>
          </Form>

          <Divider>Endereço</Divider>

          <Form layout="vertical">
            <Row gutter={16}>
              <Col md={12} xs={24}>
                <Form.Item label="CEP">{cepMask(info?.cep)}</Form.Item>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item label="Número">{info?.numero}</Form.Item>
              </Col>
              <Col md={24} xs={24}>
                <Form.Item label="Endereço">{info?.endereco}</Form.Item>
              </Col>
              <Col md={24} xs={24}>
                <Form.Item label="Complemento">{info?.complemento}</Form.Item>
              </Col>
              <Col md={24} xs={24}>
                <Form.Item label="Bairro">{info?.bairro}</Form.Item>
              </Col>
            </Row>
          </Form>
        </ModalInfo>
      </Modal>
    </>
  );
};

export default CardGeral;

type PermissoesFiltered = {
  status_tipo: string;
  responsabilidade: string;
};

export const Header = styled.div`
  margin-bottom: 40px;

  div.avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
  }

  h3 {
    text-align: center;
    margin-top: 28px;
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 0;
  }

  p,
  .ant-typography {
    text-align: center;
    font-size: 14px;
    color: #9c9c9c;
    margin-bottom: 0;
  }

  .top-information {
    div.ant-typography {
      font-size: 12px;
    }

    button {
      border: 0;
      background: none;
      color: #9c9c9c;
      transition: 0.3s;

      &:hover {
        color: var(--font_dark);
      }
    }
  }
`;

export const BodyItem = styled.div`
  margin: 25px 0;

  h4 {
    font-size: 14px;
    color: #9c9c9c;
    text-transform: uppercase;
    margin-bottom: 16px;
  }
`;

export const ModalInfo = styled.div`
  .ant-form-item-label {
    padding-bottom: 0;
    font-weight: bold;
  }
`;
