import React, { useState, useEffect } from 'react';
import {
  Button,
  Form,
  Row,
  Col,
  Input,
  Select,
  Tooltip,
  InputNumber,
  DatePicker,
  Modal,
} from 'antd';
import { format, parseISO } from 'date-fns';
import { dinheiroMask } from 'masks-br';
import {
  MdFileDownload,
  MdOpenInNew,
  MdSmartphone,
  MdOutlineAddToHomeScreen,
} from 'react-icons/md';
import { RiComputerLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import 'moment/locale/pt-br';

import PageHeader from '../../../components/PageHeader';
import Table from '../../../components/Table';
import { fetchApi } from '../../../services/api';
import Pagina from '../../../types/Pagina';
import CardCollapse from '../../../components/CardCollapse';
import { ItemInputCurrency } from '../../../components/Form';
import UrlQuery, {
  setQueryParams,
  useQueryParams,
} from '../../../utils/UrlQuery';
import { usePermission } from '../../../hooks/usePermissions';
import Option from '../../../types/Option';
import { useUtils } from '../../../hooks/useUtils';
import downloadFiles from '../../../utils/downloadFiles';

import { findStatusColor } from '..';

const { RangePicker } = DatePicker;

const CarrinhoPesquisar: React.FC = () => {
  const [form] = Form.useForm();
  const query_values = useQueryParams();
  const { getPermissions } = usePermission();
  const { getStatusCarrinho } = useUtils();

  const permissoes = getPermissions();

  const [loading, setLoading] = useState(true);
  const [carrinho, setCarrinho] = useState<Pagina>({} as Pagina);
  const [filtros, setFiltros] = useState<any | undefined>(undefined);
  const [statusCarrinho, setStatusCarrinho] = useState<Option[]>([]);
  const [setedFilterData, setSetedFilterData] = useState(false);

  useEffect(() => {
    let data;

    if (query_values.data_inicio && query_values.data_fim) {
      data = [moment(query_values.data_inicio), moment(query_values.data_fim)];
    }

    setFiltros({
      pagina: 1,
      porPagina: 25,
      ...query_values,
      data,
    });
    form.setFieldsValue({ ...query_values, data });
    fetchStatus();
  }, []);

  useEffect(() => {
    if (filtros) {
      fetchCarrinhos();
    }
  }, [filtros]);

  async function fetchCarrinhos() {
    return fetchApi({
      url: '/carrinho',
      method: 'get',
      query_params: filtros,
      onSuccess: data => {
        const list = data.data.map((item: any) => {
          return {
            ...item,
            criado_em: format(parseISO(item.criado_em), 'dd/MM/yyyy HH:mm:ss'),
          };
        });
        setCarrinho({
          ...data,
          data: list,
        });
        setLoading(false);
      },
    });
  }

  function handleResetForm() {
    setQueryParams({});
    form.resetFields();
    setFiltros({});
  }

  function handleSetFiltroValues(data: any) {
    let [data_inicio, data_fim] = [...(data?.data || [])];

    if (!data_inicio && !data_fim && setedFilterData) {
      [data_inicio, data_fim] = form.getFieldsValue().data;
    }

    delete data.data;

    const final = {
      ...filtros,
      ...data,
      data_inicio: data_inicio ? data_inicio.format('YYYY-MM-DD') : undefined,
      data_fim: data_inicio ? data_fim.format('YYYY-MM-DD') : undefined,
    };

    setQueryParams(final);
    setFiltros(final);
    setSetedFilterData(false);
  }

  function handleUpdatePagination(pagina: number, porPagina?: number) {
    setSetedFilterData(true);
    handleSetFiltroValues({ pagina, porPagina });
  }

  async function fetchStatus() {
    getStatusCarrinho().then(data => {
      setStatusCarrinho(
        data.map(item => ({
          label: findStatusColor(item.nome, item.tipo),
          value: item.id_status_carrinho,
        })),
      );
    });
  }

  async function downloadFile(type = 'csv') {
    Modal.info({
      title: 'A exportação utilizará os filtros da pesquisa!',
      centered: true,
      onOk() {
        return downloadFiles(
          `/carrinho/export/${type}?${UrlQuery(filtros)}`,
          `exportacao_carrinho.${type}`,
        );
      },
    });
  }

  const columns: ColumnsType<any> = [
    {
      // title: 'Origem',
      key: 'origem',
      dataIndex: 'origem',
      render: (item: string) => {
        const color = '#7d7d7d';
        const size = 25;

        if (item === 'pos') {
          return (
            <Tooltip placement="right" title={item.toLocaleUpperCase()}>
              <MdSmartphone size={size} color={color} />
            </Tooltip>
          );
        }

        if (item === 'online') {
          return (
            <Tooltip placement="right" title={item.toLocaleUpperCase()}>
              <RiComputerLine size={size} color={color} />
            </Tooltip>
          );
        }

        if (item === 'toten') {
          return (
            <Tooltip placement="right" title={item.toLocaleUpperCase()}>
              <MdOutlineAddToHomeScreen size={size} color={color} />
            </Tooltip>
          );
        }

        return item.toLocaleUpperCase();
      },
    },
    {
      title: 'Pedido',
      key: 'num_pedido',
      dataIndex: 'num_pedido',
      align: 'center',
    },
    {
      title: 'Estabelecimento',
      key: 'estabelecimento',
      dataIndex: 'estabelecimento',
      align: 'center',
      render: (item: string, data: any) =>
        item ? (
          <Link to={`/usuario/${data.id_estabelecimento}`}>{item}</Link>
        ) : (
          '-'
        ),
    },
    // {
    //   title: 'Boletos',
    //   key: 'boletos',
    //   dataIndex: 'boletos',
    //   align: 'center',
    // },
    {
      title: 'Valor Boletos',
      key: 'valor',
      dataIndex: 'valor',
      align: 'center',
      render: (item: number) => dinheiroMask(item),
    },
    {
      title: 'Valor',
      key: 'valor_total',
      dataIndex: 'valor_total',
      align: 'center',
      render: (item: number) => dinheiroMask(item),
    },
    {
      title: 'Status',
      align: 'center',
      render: (data: any) =>
        findStatusColor(data.status || 'Sem status', data.status_tipo),
    },
    {
      title: 'Criado em',
      align: 'center',
      key: 'criado_em',
      dataIndex: 'criado_em',
      width: 200,
    },
    {
      title: 'Abrir',
      width: 80,
      align: 'center',
      render: (data: any) =>
        permissoes.CARRINHO_INFORMACAO ? (
          <Link
            to={`/carrinho/${data.id_carrinho}`}
            className="ant-btn ant-btn-primary"
          >
            <MdOpenInNew />
          </Link>
        ) : (
          <Button type="primary" disabled>
            <MdOpenInNew />
          </Button>
        ),
    },
  ];

  return (
    <>
      <PageHeader title="Carrinhos" breadcrumb={['Carrinho', 'Pesquisar']}>
        {permissoes.COMISSAO_LISTAGEM ? (
          <Link className="ant-btn ant-btn-primary" to="/comissao/pendente">
            Abrir comissões a serem pagas
          </Link>
        ) : null}
      </PageHeader>

      <CardCollapse title="Filtros">
        <Form
          layout="vertical"
          onFinish={data => handleSetFiltroValues(data)}
          form={form}
        >
          <Row gutter={16}>
            <Col md={4} xs={12}>
              <Form.Item name="origem" label="Origem">
                <Select>
                  <Select.Option value="online">ONLINE</Select.Option>
                  <Select.Option value="pos">POS</Select.Option>
                  <Select.Option value="toten">TOTEN</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col md={10} xs={12}>
              <Form.Item name="estabelecimento" label="Estabelecimento">
                <Input />
              </Form.Item>
            </Col>
            <Col md={10} xs={12}>
              <Form.Item name="usuario" label="Usuário">
                <Input />
              </Form.Item>
            </Col>
            <Col md={5} xs={12}>
              <Form.Item name="valor" label="Valor Total Boletos">
                <ItemInputCurrency
                  onChange={(_, val) => form.setFieldsValue({ valor: val })}
                />
              </Form.Item>
            </Col>
            <Col md={5} xs={12}>
              <Form.Item name="valor_total" label="Valor Total">
                <ItemInputCurrency
                  onChange={(_, val) =>
                    form.setFieldsValue({ valor_total: val })
                  }
                />
              </Form.Item>
            </Col>
            <Col md={6} xs={12}>
              <Form.Item name="num_pedido" label="Pedido">
                <InputNumber />
              </Form.Item>
            </Col>
            <Col md={8} xs={12}>
              <Form.Item name="status" label="Status">
                <Select options={statusCarrinho} />
              </Form.Item>
            </Col>
            <Col md={8} xs={12}>
              <Form.Item name="data" label="Data">
                <RangePicker
                  format="DD/MM/YYYY"
                  placeholder={['Data Inicio', 'Data Fim']}
                  allowClear
                  onChange={() => setSetedFilterData(true)}
                />
              </Form.Item>
            </Col>
          </Row>

          <br />

          <Row justify="end">
            <Button onClick={handleResetForm} className="buttonMarginRight">
              Limpar
            </Button>
            <Button type="primary" htmlType="submit">
              Filtrar
            </Button>
          </Row>
        </Form>
      </CardCollapse>

      <Table
        columns={columns}
        dataSource={carrinho.data}
        loading={loading}
        size="small"
        pagination={{
          total: carrinho.total,
          current: carrinho.pagina,
          pageSize: carrinho.porPagina,
          onChange: handleUpdatePagination,
        }}
      >
        <Tooltip placement="topRight" title="Exportar para CSV">
          <Button
            onClick={() => downloadFile()}
            type="primary"
            shape="round"
            icon={<MdFileDownload />}
          />
        </Tooltip>
      </Table>
    </>
  );
};

export default CarrinhoPesquisar;
