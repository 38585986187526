enum EnumStatusPermissao {
  SOLICITACAO_PERMISSAO_PENDENTE = 1,
  CRIACAO_CONTA_BANCARIA_PENDENTE = 2,
  APROVACAO_CONTA_BANCARIA_PENDENTE = 3,
  CONTA_BANCARIA_REPROVADA = 4,
  CONTA_BANCARIA_APROVADA = 5,
  ENVIO_CONTRATO_PENDENTE = 6,
  ASSINATURA_CONTRATO_PENDENTE = 7,
  CONTRATO_ASSINADO = 8,
  CONTRATO_EXPIRADO = 9,
  PERMISSAO_APROVADA = 10,
  PERMISSAO_REPROVADA = 11,
  PERMISSAO_REMOVIDA = 12,
  CONTRATO_CANCELADO = 13,
}

export default EnumStatusPermissao;
