import React, { useMemo, useState } from 'react';
import { DataBoletoRaw } from '../../types';
import CardCollapse from '../../../../components/CardCollapse';
import {
  Button,
  Empty,
  List,
  Modal,
  Timeline,
  Tooltip,
  Typography,
} from 'antd';
import { dinheiroMask } from 'masks-br';
import { Link } from 'react-router-dom';
import { AiOutlineShoppingCart } from 'react-icons/ai';
import { format } from 'date-fns';
import { MdList } from 'react-icons/md';
import { fetchApi } from '../../../../services/api';
import { IBoletoLog } from '../../../Carrinho/Info/Boleto';
import { getColor } from '../Status';
import UserName from '../../../../components/User/Name';
import { findStatusColor } from '../Geral';

type IProps = {
  data: DataBoletoRaw;
  loading: boolean;
};

type ILogs = {
  id_payment?: string;
  id_boleto?: string;
  modal: boolean;
  logs: any;
};

const { Paragraph } = Typography;

const BoletoInfoPagamentos: React.FC<IProps> = ({ data, loading }: IProps) => {
  const [timeline, setTimeline] = useState<ILogs>({} as ILogs);

  function handleFetchBoletoLogs(id_payment: string) {
    if (timeline.id_payment === id_payment) {
      setTimeline({
        ...timeline,
        modal: true,
      });

      return '';
    }

    return fetchApi({
      url: `/backoffice/checkout/payment/${id_payment}/timeline`,
      method: 'get',
      onSuccess: data => {
        setTimeline({
          modal: true,
          id_payment,
          logs: data.timeline.map((log: IBoletoLog) => {
            return (
              <Timeline.Item
                key={log.id_timeline}
                label={format(new Date(log.created_at), 'dd/MM/yyyy HH:mm:ss')}
                color={getColor(log.status.type.toLowerCase())}
                position="start"
              >
                <strong>{log.status.name}</strong>
                {log.created_by && (
                  // <Paragraph copyable>
                  <UserName id_user={log.created_by} />
                  // </Paragraph>
                  // <Paragraph copyable>{log.created_by}</Paragraph>
                )}
              </Timeline.Item>
            );
          }),
        });
      },
    });
  }

  function getType(type: string): string | null {
    switch (type) {
      case 'BILL':
        return 'Boleto';
      case 'PIX':
        return 'Pix';
      case 'PARTNER':
        return 'Parceiro';
      default:
        return null;
    }
  }

  const payments = useMemo(() => {
    if (!data?.payments?.length) {
      return (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={<span>Nenhum pagamento associado</span>}
        />
      );
    }

    return (
      <List
        itemLayout="horizontal"
        dataSource={data?.payments}
        renderItem={item => (
          <List.Item
            actions={[
              <Tooltip title="Timeline do pagamento">
                <Button onClick={() => handleFetchBoletoLogs(item.id_payment)}>
                  <MdList />
                </Button>
              </Tooltip>,
              <Tooltip title="Abrir carrinho">
                <Link className="ant-btn" to={`/checkout/${item.checkout_id}`}>
                  <AiOutlineShoppingCart />
                </Link>
              </Tooltip>,
            ]}
          >
            <List.Item.Meta
              title={<b>{dinheiroMask(item.amount)}</b>}
              description={`${format(
                new Date(item.created_at),
                'dd/MM/yyyy HH:mm:ss',
              )} - ${getType(item.type)}`}
            />
            {findStatusColor(item?.status?.name, item?.status?.type)}
          </List.Item>
        )}
      />
    );
  }, [data]);

  return (
    <CardCollapse title="Pagamentos" loading={loading}>
      {payments}

      <Modal
        visible={timeline.modal}
        title="Timeline do pagamento"
        footer={false}
        onCancel={() => setTimeline({ ...timeline, modal: false })}
        centered
      >
        <Timeline mode="left">{timeline.logs}</Timeline>
      </Modal>
    </CardCollapse>
  );
};

export default BoletoInfoPagamentos;
