import {
  Button,
  Col,
  Empty,
  Form,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Table,
} from 'antd';
import { porcentagemMask } from 'masks-br';
import React, { useEffect, useState } from 'react';
import { MdRemoveCircle } from 'react-icons/md';

import CardCollapse from '../../../../components/CardCollapse';
import { useApi } from '../../../../hooks/useApi';
import { usePermission } from '../../../../hooks/usePermissions';
import { fetchApi } from '../../../../services/api';

type ICardTaxa = {
  id_usuario: string;
};

type IUsuarioCondicaoPagamento = {
  id_usuario_condicao_pagamento: string;
  id_condicao_pagamento: number;
  id_forma_pagamento: number;
  antecipacao: number;
  coeficiente_final: number;
  forma_pagamento: string;
  from_usuario: boolean;
  mdr: number;
  prazo_padrao: number;
  qtde_parcelas: number;
  taxa_conveniencia: number;
  id_adquirencia: number;
  nome_adquirencia: string;
};

interface IAdquirencia {
  data: {
    id_adquirencia: string;
    nome: string;
    descricao: string;
  }[];
  total: number;
  pagina: number;
  porPagina: number;
}

const CardTaxa: React.FC<ICardTaxa> = ({ id_usuario }: ICardTaxa) => {
  const [newUserFeeForm] = Form.useForm();

  const [modalTaxa, setModalTaxa] = useState(false);

  const [condicao, setCondicao] = useState<any[]>([]);
  const [taxasUsuario, setTaxasUsuario] = useState<IUsuarioCondicaoPagamento[]>(
    [],
  );
  const [taxasUsuarioEditadas, setTaxasUsuarioEditadas] = useState<any[]>([]);

  const [adquirencia, setAdquirencia] = useState({} as IAdquirencia);
  const [filterAdquirencia, setFilterAdquirencia] = useState(
    {} as { id_adquirencia: number; nome: string },
  );

  const { fetchRequest, loading } = useApi();
  const { userPermissions } = usePermission();

  useEffect(() => {
    fetchAdquirencia();
    handleAdquirenciaSeleted();
  }, []);

  useEffect(() => {
    if (filterAdquirencia.nome) {
      handleCondicao();
      handleFetchTaxasUsuario();
    }
  }, [filterAdquirencia]);

  function handleCondicao() {
    return fetchRequest({
      method: 'GET',
      url: `/condicao_pagamento`,
      query_params: filterAdquirencia,
      onSuccess: data => {
        const list = [
          {
            label: `Todos`,
            value: 'todos',
          },
          ...data.map((item: any) => ({
            label: `${item.forma_pagamento} - ${item.qtde_parcelas}x`,
            value: item.id_condicao_pagamento,
            ...item,
          })),
        ];

        setCondicao(list);
      },
    });
  }

  function handleFetchTaxasUsuario() {
    return fetchRequest({
      method: 'GET',
      url: `/condicao_pagamento/usuario/${id_usuario}`,
      query_params: {
        id_adquirencia: filterAdquirencia?.id_adquirencia,
      },
      onSuccess: data => {
        setTaxasUsuario(data);
      },
    });
  }

  async function handleAdquirenciaSeleted() {
    const fetch_adquirencia_selected = fetchRequest({
      method: 'GET',
      url: `/condicao_pagamento/usuario/adquirencia/${id_usuario}`,
      onSuccess: data => {
        setFilterAdquirencia({
          id_adquirencia: data.id_adquirencia,
          nome: data.nome,
        });
      },
    });

    return fetch_adquirencia_selected;
  }

  async function fetchAdquirencia() {
    const fetch_adquirencia = await fetchApi({
      url: '/adquirencia',
      method: 'get',
      onSuccess: data => {
        setAdquirencia(data);
      },
    });

    return fetch_adquirencia;
  }

  async function handleFilterCondicaoPagamento(data: any) {
    setFilterAdquirencia({
      id_adquirencia: data.id_adquirencia,
      nome:
        adquirencia.data?.find(
          item => item.id_adquirencia === data.id_adquirencia,
        )?.nome || '',
    });
  }

  async function handleApplyBankCharges() {
    return fetchApi({
      url: `/condicao_pagamento/usuario/adquirencia/${id_usuario}`,
      method: 'put',
      data: {
        id_adquirencia: filterAdquirencia?.id_adquirencia,
      },
      onSuccess: async () => {
        await handleFetchTaxasUsuario();
      },
    });
  }

  function handleDeleteTaxaUsuario(id: string) {
    Modal.confirm({
      title: 'Deseja remover essa taxa?',
      // content: 'Some descriptions',
      okText: 'Sim',
      okType: 'danger',
      cancelText: 'Não',
      centered: true,
      onOk() {
        return fetchRequest({
          method: 'delete',
          url: `/condicao_pagamento/usuario/${id}`,
          messages: {
            error: 'Erro ao remover taxa',
          },
          onSuccess: () => {
            resetFields();
          },
        });
      },
    });
  }

  function setTaxas(data: any[]) {
    data.forEach((item, index) => {
      const a = {
        [`${index}-id_condicao_pagamento`]: item.id_condicao_pagamento,
        [`${index}-id_forma_pagamento`]: item.id_forma_pagamento,
      };
      newUserFeeForm.setFieldsValue(a);
    });
    setTaxasUsuarioEditadas(data);
  }

  function handleAddFee(data: any): boolean {
    const { condicao_pagamento } = data;

    const condicoes = [...condicao];

    condicoes.shift();

    if (condicao_pagamento === 'todos') {
      setTaxas(condicoes);

      return true;
    }

    if (
      taxasUsuarioEditadas.some(
        item => item.id_condicao_pagamento === condicao_pagamento,
      )
    ) {
      return false;
    }

    const cond = condicoes.find(
      item => item.id_condicao_pagamento === condicao_pagamento,
    );

    setTaxas([...taxasUsuarioEditadas, cond]);

    return true;
  }

  function handleRemoveFeeFromList(condicao_pagamento: number) {
    const condicoes = taxasUsuarioEditadas.filter(
      item => item.id_condicao_pagamento !== condicao_pagamento,
    );

    setTaxasUsuarioEditadas(condicoes);
  }

  function handleCreateUserFee(data: { [key: string]: number }) {
    const info: any[] = [];

    Object.entries(data).forEach(([fullkey, val]) => {
      const [str_index, key] = fullkey.split('-');
      const index = Number(str_index);

      if (info[index]) {
        info[index][key] = val;
      } else {
        info[index] = { [key]: val };
      }
    });

    return fetchRequest({
      method: 'post',
      url: `/condicao_pagamento/usuario/${id_usuario}`,
      data: info,
      messages: {
        error: 'Erro ao atualizar as taxas do usuário',
      },
      onSuccess: () => {
        resetFields();
      },
    });
  }

  function resetFields() {
    setModalTaxa(false);
    newUserFeeForm.resetFields();
    handleFetchTaxasUsuario();
    setTaxasUsuarioEditadas([]);
  }

  const columns = [
    {
      title: 'Forma',
      dataIndex: 'forma_pagamento',
      key: 'forma_pagamento',
    },
    {
      title: 'Parcelas',
      dataIndex: 'qtde_parcelas',
      key: 'qtde_parcelas',
    },
    {
      title: 'Coeficiênte',
      dataIndex: 'coeficiente_final',
      key: 'coeficiente_final',
    },
    {
      title: 'Taxa Conveniência',
      dataIndex: 'taxa_conveniencia',
      key: 'taxa_conveniencia',
      render: (item: string) => porcentagemMask(item),
    },
    {
      title: 'MDR',
      dataIndex: 'mdr',
      key: 'mdr',
      render: (item: string) => porcentagemMask(item),
    },
    {
      title: 'Antecipação',
      dataIndex: 'antecipacao',
      key: 'antecipacao',
      render: (item: string) => porcentagemMask(item),
    },
    {
      dataIndex: 'id_usuario_condicao_pagamento',
      key: 'id_usuario_condicao_pagamento',
      render: (item: string) =>
        item && userPermissions.TAXA_REMOVER ? (
          <Button
            danger
            type="primary"
            size="small"
            onClick={() => handleDeleteTaxaUsuario(item)}
          >
            <MdRemoveCircle />
          </Button>
        ) : null,
    },
  ];

  return (
    <>
      <CardCollapse
        title="Configuração de Taxas do Estabelecimento"
        loading={loading}
      >
        {userPermissions.TAXA_ADICIONAR ? (
          <>
            <Row justify="space-between" gutter={6}>
              <Col>
                <Space>
                  <Select
                    defaultValue={filterAdquirencia?.nome}
                    placeholder="Selecione a Adquirencia"
                    style={{
                      width: `200px`,
                    }}
                    onChange={value =>
                      handleFilterCondicaoPagamento({ id_adquirencia: value })
                    }
                    options={adquirencia.data?.map((item: any) => ({
                      label: item.nome,
                      value: item.id_adquirencia,
                    }))}
                  />
                  <Row justify="end">
                    <Button
                      type="primary"
                      onClick={() => handleApplyBankCharges()}
                    >
                      Aplicar
                    </Button>
                  </Row>
                </Space>
              </Col>
              <Col>
                <Row justify="end">
                  <Button type="primary" onClick={() => setModalTaxa(true)}>
                    Criar Taxa Específica
                  </Button>
                </Row>
              </Col>
            </Row>

            <br />
          </>
        ) : null}

        {taxasUsuario.length ? (
          <Table
            columns={columns}
            dataSource={taxasUsuario}
            pagination={false}
            size="small"
          />
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Nenhuma taxa expecífica"
          />
        )}
      </CardCollapse>

      <Modal
        title="Criar taxas específica"
        centered
        visible={modalTaxa}
        onCancel={() => setModalTaxa(false)}
        footer={false}
        width={1000}
      >
        <Form onFinish={data => handleAddFee(data)}>
          <Row gutter={16}>
            <Col md={20} xs={24}>
              <Form.Item name="condicao_pagamento">
                <Select options={condicao} />
              </Form.Item>
            </Col>
            <Col md={4} xs={24}>
              <Button
                block
                type="primary"
                htmlType="submit"
                style={{ marginTop: 5 }}
              >
                Adicionar
              </Button>
            </Col>
          </Row>
        </Form>

        <br />

        <Form
          form={newUserFeeForm}
          onFinish={data => handleCreateUserFee(data)}
        >
          <Table
            columns={[
              {
                title: 'Forma',
                dataIndex: 'forma_pagamento',
                key: 'forma_pagamento',
                render: (item, data) => `${item} - ${data.qtde_parcelas}x`,
              },
              {
                title: 'Coeficiênte Padrão',
                dataIndex: 'coeficiente_final',
                key: 'coeficiente_final',
              },
              {
                title: 'Coeficiênte',
                dataIndex: 'coeficiente_final',
                key: 'coeficiente_final',
                render: (item, data, index) => (
                  <>
                    <Form.Item name={`${index}-id_condicao_pagamento`} hidden />
                    <Form.Item name={`${index}-id_forma_pagamento`} hidden />
                    <Form.Item
                      name={`${index}-coeficiente_final`}
                      rules={[{ required: true, message: 'Campo Obrigatório' }]}
                    >
                      <InputNumber
                        precision={6}
                        min={1}
                        max={1.999999}
                        size="small"
                      />
                    </Form.Item>
                  </>
                ),
              },
              {
                title: 'Taxa Conveniência Padrão',
                dataIndex: 'taxa_conveniencia',
                key: 'taxa_conveniencia',
                render: (item: string) => porcentagemMask(item),
              },
              {
                title: 'Taxa Conveniência',
                dataIndex: 'taxa_conveniencia',
                key: 'taxa_conveniencia',
                render: (item, _, index) => (
                  <Form.Item
                    name={`${index}-taxa_conveniencia`}
                    rules={[{ required: true, message: 'Campo Obrigatório' }]}
                  >
                    <InputNumber
                      formatter={value => `${value}%`}
                      parser={(value: any) => value.replace('%', '')}
                      size="small"
                      min={0}
                      max={20}
                    />
                  </Form.Item>
                ),
              },
              {
                width: '30px',
                dataIndex: 'id_condicao_pagamento',
                key: 'id_condicao_pagamento',
                render: (item: number) => (
                  <Button
                    danger
                    type="primary"
                    onClick={() => handleRemoveFeeFromList(item)}
                  >
                    <MdRemoveCircle />
                  </Button>
                ),
              },
            ]}
            dataSource={taxasUsuarioEditadas}
            pagination={false}
            size="small"
          />

          <br />

          <Row justify="end">
            <Button type="primary" htmlType="submit">
              Salvar
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default CardTaxa;
