import React from 'react';
import { Typography } from 'antd';

const { Paragraph } = Typography;

type PaymentLinkButtonProps = {
  url: string;
  label?: string;
  target?: React.HTMLAttributeAnchorTarget;
};

export default function PaymentLinkButton({
  url,
  target,
  label = 'Parcelamos Tudo | Link de Pagamento',
}: PaymentLinkButtonProps) {
  return (
    <div
      style={{
        display: 'flex',
        maxWidth: 'max-content',
        maxHeight: 34,
        alignItems: 'center',
        borderRadius: 6,
        backgroundColor: '#EEE',
        padding: '4px 8px',
        gap: 4,
      }}
    >
      <img
        src={`${process.env.REACT_APP_PARCELAMOS_TUDO_BASE_URL_LINK_PAGAMENTO}/pt.ico`}
        style={{ width: 20 }}
        alt="Parcelamos Tudo"
      />

      <Paragraph copyable={{ text: url }} style={{ color: 'black', margin: 0 }}>
        <a href={url} target={target}>
          {label}
        </a>
      </Paragraph>
    </div>
  );
}
