import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { IState } from '../../store';
import TipoAcesso from '../../types/TipoAcesso';

import MenuAdmin from './MenuAdmin';
import MenuParceiro from './MenuParceiro';
import { MenuStyle } from './styles';

function Menu() {
  const tipo = useSelector<IState, TipoAcesso | undefined>(state => state.auth.tipo);
  const menu_opened = useSelector<IState, boolean>(state => state.auth.menu_opened);

  const menu = tipo === 'parceiro' ? <MenuParceiro /> : <MenuAdmin />;

  return (
    <MenuStyle opened={menu_opened}>
      {menu}
    </MenuStyle>
  )
}

export default memo(Menu);
