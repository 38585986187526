import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  List,
  Modal,
  Row,
  Select,
} from 'antd';
import React, { useState } from 'react';
import { MdRemoveCircle } from 'react-icons/md';
import { cleanMoneyMask, dinheiroMask } from 'masks-br';
import { useHistory } from 'react-router-dom';

import CardCollapse from '../../../components/CardCollapse';
import PageHeader from '../../../components/PageHeader';
import { fetchApi } from '../../../services/api';
import { ItemInputBarcode, ItemInputCurrency } from '../../../components/Form';
import Option from '../../../types/Option';
import {
  ChargeOptions,
  CheckoutBilletResponse,
  CheckoutChargeOptionsResponse,
  CheckoutCreatedResponse,
} from '../../../types/Checkout';
import { useSelector } from 'react-redux';
import { IState } from '../../../store';
import { IUserState } from '../../../store/modules/user/reducer';
import { extractPaymentMethod, extractUUID } from '../../../utils/checkout';
import moment from 'moment';
import {
  IGetEstablishmentOptions,
  getEstablishments,
} from '../Providers/get-establishments.provider';
import DebounceSelect from '../../../components/DebounceSelect';
import {
  IGetEncouragingOptions,
  getEncouraging,
} from '../Providers/get-encouraging.provider';

enum PaymentMethods {
  credit_card = 'credit_card',
  debit_card = 'debit_card',
  pix = 'pix',
  bill = 'bill',
}

type PaymentOptions = {
  value: string;
  label: string;
};

const FIVE_MINUTES_IN_MILISECONDS = 5 * 60 * 1000

const CarrinhoCriar: React.FC = () => {
  const [form] = Form.useForm();
  const user = useSelector<IState, IUserState>(state => state.user);
  const [loading, setLoading] = useState<boolean>(false);
  const [barcode, setBarcode] = useState<string>('');
  const [amount, setAmount] = useState<string>('');
  const [billets, setBillets] = useState<CheckoutBilletResponse[]>([]);
  const [paymentOptions, setPaymentOptions] = useState<PaymentOptions[]>([]);
  const [paymentType, setPaymentType] = useState<string>('');
  const [idCharge, setIdCharge] = useState<string>('');
  const [idEstablishment, setIdEstablishment] = useState<string>('');
  const [idEncouraging, setIdEncouraging] = useState<string>();
  const [charges, setCharges] = useState<ChargeOptions[]>([]);
  const [establishmentValue, setEstablishmentValue] =
    useState<IGetEstablishmentOptions>({} as IGetEstablishmentOptions);
  const [encouragingValue, setEncouragingValue] =
    useState<IGetEncouragingOptions>({} as IGetEncouragingOptions);

  const history = useHistory();

  const origin: Option[] = [
    {
      label: 'POS',
      value: 'POS',
    },
    {
      label: 'ONLINE',
      value: 'ONLINE',
    },
  ];

  const acquirer: Option[] = [
    {
      label: 'Work 8',
      value: 'WORK8',
    },
    {
      label: 'Pagseguro',
      value: 'PAGSEGURO',
    },
    {
      label: 'Mercado Pago',
      value: 'MERCADO_PAGO',
    },
    {
      label: 'CeoPag',
      value: 'CEOPAG',
    },
  ];

  const cardFlag: Option[] = [
    {
      label: 'Visa',
      value: 'VISA',
    },
    {
      label: 'Mastercard',
      value: 'MASTERCARD',
    },
    {
      label: 'Amex',
      value: 'AMEX',
    },
    {
      label: 'DinersClub',
      value: 'DINERS',
    },
    {
      label: 'Elo',
      value: 'ELO',
    },
    {
      label: 'Hipercard',
      value: 'HIPERCARD',
    },
    {
      label: 'Desconhecido',
      value: 'UNKNOWN',
    },
  ];

  async function handleFetchBillet() {
    const billetAlreadyAdd = billets.find(item => item.digitable === barcode);

    if (billetAlreadyAdd) {
      return Modal.warning({
        title:
          'Você já adicionou este código de barras, insira um código diferente.',
      });
    }

    if (!idEstablishment?.length) {
      return Modal.warning({
        title: 'Insira o estabelecimento antes de consultar o boleto.',
      });
    }

    setLoading(true);

    const response: CheckoutBilletResponse | undefined = await fetchApi({
      url: '/backoffice/checkout/manually/bill',
      method: 'post',
      query_params: {
        code: barcode,
        amount: amount !== '' ? cleanMoneyMask(amount) : undefined,
        id_establishment: idEstablishment,
      },
      messages: {
        error: 'Erro ao buscar as informações do boleto, tente novamente!',
        loading: 'Buscando informações do boleto, aguarde...',
      },
    });

    setLoading(false);

    if (response?.id_bill) {
      form.setFieldsValue({
        barcode: '',
      });
      setBarcode('');
      setBillets([...billets, response]);
    }
  }

  function handleRemoveBillet(id_bill: string) {
    setLoading(true);

    const newArray = billets.filter(item => item.id_bill !== id_bill);

    setBillets(newArray);
    setLoading(false);
  }

  async function handleFetchPaymentOptions() {
    const options = [];
    const allCharges = [];

    setLoading(true);

    const response: CheckoutChargeOptionsResponse | undefined = await fetchApi({
      url: '/backoffice/checkout/manually/charge/options',
      method: 'post',
      query_params: {
        id_establishment: user.id_usuario,
      },
      data: {
        origin: form.getFieldValue('origin'),
        id_establishment: idEstablishment,
        debits: billets.map(item => ({
          type: 'BILLET',
          id_bill: item.id_bill,
        })),
      },
      messages: {
        error: 'Erro ao buscar as opções de pagamento, tente novamente!',
        loading: 'Buscando opções de pagamento, aguarde...',
      },
    });

    setLoading(false);

    if (response) {
      const { bill, credit_card, debit_card, pix } = response;
      if (credit_card) {
        const credit = credit_card.map(item => ({
          value: `${PaymentMethods.credit_card} - ${item.id_charge_option}`,
          label: `Crédito - ${item.installment_number} X ${dinheiroMask(
            item.installment_amount,
          )}`,
        }));
        options.push(...credit);
        allCharges.push(...credit_card);
      }
      if (debit_card) {
        const debit = debit_card.map(item => ({
          value: `${PaymentMethods.debit_card} - ${item.id_charge_option}`,
          label: `Débito - ${item.installment_number} X ${dinheiroMask(
            item.installment_amount,
          )}`,
        }));
        options.push(...debit);
        allCharges.push(...debit_card);
      }
      if (bill) {
        const bankslip = {
          value: `${PaymentMethods.bill} - ${bill.id_charge_option}`,
          label: `Boleto - ${bill.installment_number} X ${dinheiroMask(
            bill.installment_amount,
          )}}`,
        };
        options.push(bankslip);
        allCharges.push(bill);
      }
      if (pix) {
        const pixOption = {
          value: `${PaymentMethods.pix} - ${pix.id_charge_option}`,
          label: `Pix - ${pix.installment_number} X ${dinheiroMask(
            pix.installment_amount,
          )}`,
        };
        options.push(pixOption);
        allCharges.push(pix);
      }
      setPaymentOptions(options);
      setCharges(allCharges);
    }
  }

  const handleSelectChange = (value: string) => {
    const uuid = extractUUID(value);
    const transactionType = extractPaymentMethod(value);

    setIdCharge(uuid!);
    setPaymentType(transactionType!);

    const charge = charges.find(item => item.id_charge_option === uuid);

    if (charge) {
      form.setFieldsValue({
        fee: charge.fee_amount,
        totalAmount: charge.total_amount,
      });
    }
  };

  async function handleCreateCheckout() {
    setLoading(true);
    const createdAt = form.getFieldValue('created_at').toISOString();

    const data =
      paymentType === PaymentMethods.credit_card ||
      paymentType === PaymentMethods.debit_card
        ? {
            establishment_id: idEstablishment,
            user_id: idEncouraging,
            date: createdAt,
            id_charge_option: idCharge,
            card: {
              card_last_four_digits: form.getFieldValue(
                'card_last_four_digits',
              ),
              acquirer: form.getFieldValue('acquirer'),
              card_brand: form.getFieldValue('card_brand'),
            },
          }
        : {
            establishment_id: idEstablishment,
            user_id: idEncouraging,
            date: createdAt,
            id_charge_option: idCharge,
          };

    const response: CheckoutCreatedResponse | undefined = await fetchApi({
      url: '/backoffice/checkout/manually',
      method: 'post',
      query_params: {
        id_establishment: user.id_usuario,
      },
      data,
      timeout: FIVE_MINUTES_IN_MILISECONDS,
      messages: {
        error: 'Erro ao finalizar o checkout, tente novamente!',
        loading: 'Finalizando o checkout, aguarde...',
      },
    });

    setLoading(false);

    if (response?.id_checkout) {
      history.push(`/checkout/${response.id_checkout}`);
    }
  }

  function handleSelectEstablishment(value: IGetEstablishmentOptions) {
    setEstablishmentValue(value);
    setIdEstablishment(value.value);
  }

  function handleSelectEncouraging(value: IGetEncouragingOptions) {
    setEstablishmentValue(value);
    setIdEncouraging(value.value);
  }

  return (
    <>
      <PageHeader
        title="Criar Checkout Manualmente"
        breadcrumb={['Dashboard', 'Checkout', 'Cadastrar']}
      />

      <CardCollapse>
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            created_at: moment(),
            origin: 'POS',
          }}
        >
          <Row gutter={16}>
            <Col md={4}>
              <Form.Item
                label="Data"
                name="created_at"
                rules={[
                  {
                    required: true,
                    message: 'Selecione a data de criação',
                  },
                ]}
              >
                <DatePicker format="DD/MM/YYYY HH:mm" showTime />
              </Form.Item>
            </Col>
            <Col md={4}>
              <Form.Item
                label="Origem"
                name="origin"
                rules={[
                  {
                    required: true,
                    message: 'Selecione a origem',
                  },
                ]}
              >
                <Select options={origin} />
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item
                label="Estabelecimento"
                name="id_establishment"
                rules={[
                  {
                    required: true,
                    message: 'Campo Obrigatório',
                  },
                ]}
              >
                <DebounceSelect
                  showSearch
                  value={establishmentValue}
                  placeholder="Selecione o estabelecimento"
                  fetchOptions={getEstablishments}
                  onChange={newValue =>
                    handleSelectEstablishment(
                      newValue as IGetEstablishmentOptions,
                    )
                  }
                />
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item label="Incentivador" name="user_id">
                <DebounceSelect
                  showSearch
                  value={encouragingValue}
                  placeholder="Selecione o incentivador"
                  fetchOptions={getEncouraging}
                  onChange={newValue =>
                    handleSelectEncouraging(newValue as IGetEncouragingOptions)
                  }
                />
              </Form.Item>
            </Col>
          </Row>

          <br />

          <Divider orientation="left">Débitos</Divider>

          <Row gutter={16}>
            <Col md={18} span={12}>
              <Form.Item label="Código de Barras" name="barcode">
                <ItemInputBarcode
                  form={form}
                  formItemName="barcode"
                  setBarCode={setBarcode}
                  barcode={barcode}
                />
              </Form.Item>
            </Col>
            <Col md={6} span={12}>
              <Form.Item label="Valor" name="valor">
                <ItemInputCurrency onChange={e => setAmount(e)} />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Button
              style={{ marginTop: 25 }}
              onClick={handleFetchBillet}
              block
              disabled={barcode.length === 0}
            >
              Buscar
            </Button>
          </Row>

          {billets.length > 0 ? (
            <List
              style={{ marginTop: 15, marginLeft: 7 }}
              dataSource={billets}
              renderItem={item => (
                <List.Item
                  actions={[
                    <Col md={1} key={item.id_bill}>
                      <Button
                        onClick={() => handleRemoveBillet(item.id_bill)}
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <MdRemoveCircle />
                      </Button>
                    </Col>,
                  ]}
                >
                  <List.Item.Meta
                    title={item.assignor}
                    description={item.digitable}
                  />
                  <div>
                    <strong>{dinheiroMask(item.amount.original)}</strong>
                  </div>
                </List.Item>
              )}
            />
          ) : null}

          <Row justify="end" style={{ marginTop: 15 }}>
            <Button
              type="primary"
              onClick={handleFetchPaymentOptions}
              loading={loading}
              disabled={billets.length === 0}
              htmlType="submit"
            >
              Calcular valor final
            </Button>
          </Row>

          <br />

          {paymentOptions.length > 0 ? (
            <>
              <Divider orientation="left">Forma de Pagamento</Divider>

              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    label="Forma de Pagamento"
                    name="id_forma_pagamento"
                    rules={[
                      {
                        required: true,
                        message: 'Campo Obrigatório',
                      },
                    ]}
                  >
                    <Select
                      options={paymentOptions}
                      onChange={handleSelectChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={8} md={8}>
                  <Form.Item label="Taxa" name="fee">
                    <ItemInputCurrency disabled />
                  </Form.Item>
                </Col>
                <Col span={8} md={8}>
                  <Form.Item label="Valor Total" name="totalAmount">
                    <ItemInputCurrency disabled />
                  </Form.Item>
                </Col>
              </Row>
            </>
          ) : null}

          {paymentType === PaymentMethods.credit_card ||
          paymentType === PaymentMethods.debit_card ? (
            <>
              <Row gutter={16}>
                <Col span={8} md={8}>
                  <Form.Item
                    label="4 últimos dígitos do cartão"
                    name="card_last_four_digits"
                    rules={[
                      {
                        required:
                          paymentType === PaymentMethods.credit_card ||
                          paymentType === PaymentMethods.debit_card
                            ? true
                            : false,
                        message: 'Campo Obrigatório',
                        max: 4,
                      },
                    ]}
                  >
                    <Input
                      maxLength={4}
                      onKeyPress={event => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={8} md={8}>
                  <Form.Item
                    label="Adquirente"
                    name="acquirer"
                    rules={[
                      {
                        required:
                          paymentType === PaymentMethods.credit_card ||
                          paymentType === PaymentMethods.debit_card
                            ? true
                            : false,
                        message: 'Campo Obrigatório',
                      },
                    ]}
                  >
                    <Select options={acquirer} />
                  </Form.Item>
                </Col>
                <Col span={8} md={8}>
                  <Form.Item
                    label="Bandeira do Cartão"
                    name="card_brand"
                    rules={[
                      {
                        required:
                          paymentType === PaymentMethods.credit_card ||
                          paymentType === PaymentMethods.debit_card
                            ? true
                            : false,
                        message: 'Campo Obrigatório',
                      },
                    ]}
                  >
                    <Select options={cardFlag} />
                  </Form.Item>
                </Col>
              </Row>
            </>
          ) : (
            <></>
          )}

          <Row justify="end" style={{ marginTop: 20 }}>
            <Button
              type="primary"
              htmlType="submit"
              onClick={handleCreateCheckout}
              loading={loading}
              hidden={paymentOptions.length > 0 ? false : true}
            >
              Cadastrar
            </Button>
          </Row>
        </Form>
      </CardCollapse>
    </>
  );
};

export default CarrinhoCriar;
