import {
  Select,
  Table,
  Row,
  Col,
  Button,
  Form,
  Popconfirm,
  Input,
  Result,
  message,
} from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { format } from 'date-fns';
import React, { useMemo, useState, useEffect } from 'react';
import { MdRemoveCircle } from 'react-icons/md';
import { RiExchangeFill } from 'react-icons/ri';
import { Link } from 'react-router-dom';

import CardCollapse from '../../../../components/CardCollapse';
import { useApi } from '../../../../hooks/useApi';
import { useUtils } from '../../../../hooks/useUtils';
import { usePermission } from '../../../../hooks/usePermissions';
import Option from '../../../../types/Option';
import { options_forma_recebimento, options_meio_arrecadacao } from './options';
import { useQuery } from 'react-query';
import { fetchApi } from '../../../../services/api';
import { GetSaleOffList, ResponseApiError } from './types';
import UserName from '../../../../components/User/Name';

type IState = {
  id_usuario: string;
  is_estabelecimento: boolean;
  default_message: string;
};

export enum EnumTipoLiquidacao {
  BANCARIO = 1,
  ARQUIVO_RETORNO = 2,
}

const CardTipoLiquidacao: React.FC<IState> = ({
  id_usuario,
  is_estabelecimento,
  default_message,
}: IState) => {
  const [form] = Form.useForm();
  const { getTipoLiquidacao } = useUtils();
  const { userPermissions } = usePermission();
  const { loading: loadingCreate, fetchRequest: fetchCreate } = useApi();
  const { loading: loadingRemove, fetchRequest: fetchRemove } = useApi();
  const { loading: loadingChange, fetchRequest: fetchChangeArquivoRetorno } =
    useApi();

  const [tipos, setTipos] = useState<Option[]>([]);
  const [modal, setModal] = useState(false);
  const [modalChange, setModalChange] = useState(false);
  const [tipoSelecionado, setTipoSelecionado] = useState<number | undefined>(
    undefined,
  );
  const [tipoLiquidacaoSelectedModal, setTipoLiquidacaoSelectedModal] =
    useState<any>();

  async function getSalesOff() {
    return fetchApi<GetSaleOffList & ResponseApiError>({
      method: 'get',
      url: `/backoffice/user/${id_usuario}/settlement`,
    });
  }

  const {
    data,
    isLoading,
    refetch: refetchList,
  } = useQuery(['salesoff'], {
    queryFn: getSalesOff,
    enabled: id_usuario != 'criar',
    refetchOnWindowFocus: false,
  });

  const fields = useMemo(() => {
    if (tipoSelecionado === EnumTipoLiquidacao.ARQUIVO_RETORNO) {
      return (
        <>
          <Col md={12}>
            <Form.Item
              label="Nome do Banco"
              name="bank_name"
              rules={[{ required: true, message: default_message }]}
            >
              <Input maxLength={20} />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label="Nome da Empresa/Orgão"
              name="company_organization_name"
              rules={[{ required: true, message: default_message }]}
            >
              <Input maxLength={20} />
            </Form.Item>
          </Col>
          <Col md={8}>
            <Form.Item
              label="Número Banco Empresa/Orgão"
              name="company_organization_bank_number"
              rules={[{ required: true, message: default_message }]}
            >
              <Input maxLength={3} />
            </Form.Item>
          </Col>
          <Col md={8}>
            <Form.Item
              label="Número de Convênio"
              name="agreement_number"
              rules={[{ required: true, message: default_message }]}
            >
              <Input maxLength={20} />
            </Form.Item>
          </Col>
          <Col md={8}>
            <Form.Item
              label="Prefixo da Agência"
              name="agency_prefix"
              rules={[{ required: true, message: default_message }]}
            >
              <Input maxLength={4} />
            </Form.Item>
          </Col>
          <Col md={8}>
            <Form.Item
              label="Digito Prefixo da Agência"
              name="agency_prefix_digit"
              rules={[{ required: true, message: default_message }]}
            >
              <Input maxLength={1} />
            </Form.Item>
          </Col>
          <Col md={8}>
            <Form.Item
              label="Conta Corrente"
              name="account_number"
              rules={[{ required: true, message: default_message }]}
            >
              <Input maxLength={10} />
            </Form.Item>
          </Col>
          <Col md={8}>
            <Form.Item
              label="Dígito Conta Corrente"
              name="account_number_digit"
              rules={[{ required: true, message: default_message }]}
            >
              <Input maxLength={1} />
            </Form.Item>
          </Col>
          <Col md={8}>
            <Form.Item
              label="Forma de Recebimento"
              name="receiving_method"
              rules={[{ required: true, message: default_message }]}
            >
              <Select placeholder="Selecione a forma de recebimento">
                {options_forma_recebimento.map(option_forma_recebimento => (
                  <Select.Option
                    value={option_forma_recebimento.label}
                    key={option_forma_recebimento.label}
                  >
                    {option_forma_recebimento.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={8}>
            <Form.Item
              label="Meio de Arrecadação"
              name="collection_method"
              rules={[{ required: true, message: default_message }]}
            >
              <Select placeholder="Selecione meio de arrecadação">
                {options_meio_arrecadacao.map(option_meio_recebimento => (
                  <Select.Option
                    value={option_meio_recebimento.label}
                    key={option_meio_recebimento.label}
                  >
                    {option_meio_recebimento.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={8}>
            <Form.Item
              label="Prefixo da Agencia Recebedora"
              name="receiving_agency_prefix"
              rules={[{ required: true, message: default_message }]}
            >
              <Input maxLength={4} />
            </Form.Item>
          </Col>
        </>
      );
    }

    return null;
  }, [tipoSelecionado]);

  useEffect(() => {
    getTipoLiquidacao().then(data => {
      const final = data
        ?.filter(item => {
          const salesOffTypesPermitted = [
            EnumTipoLiquidacao.ARQUIVO_RETORNO,
            EnumTipoLiquidacao.BANCARIO,
          ];

          return salesOffTypesPermitted.includes(item.id_settlement_type);
        })
        .map(item => ({
          label: item.name,
          value: item.id_settlement_type,
        }));

      setTipos(final);
    });
  }, []);

  const saleOffData = useMemo(() => {
    return data?.data.map(item => {
      const createdAt = format(
        new Date(item.created_at ?? ''),
        'dd/MM/yyyy HH:mm:ss',
      );
      return {
        ...item,
        created_at: createdAt,
      };
    });
  }, [data]);

  function handleCreate(data: any) {
    data.receiving_method = options_forma_recebimento
      .find(
        option_forma_recebimento =>
          option_forma_recebimento.label === data.receiving_method,
      )
      ?.number.toString();

    data.collection_method = options_meio_arrecadacao
      .find(
        option_meio_arrecadacao =>
          option_meio_arrecadacao.label === data.collection_method,
      )
      ?.number.toString();

    data.order = 1;

    return fetchCreate({
      method: 'post',
      url: `/backoffice/user/${id_usuario}/settlement`,
      data,
      onSuccess: () => {
        form.resetFields();
        setModal(false);
        refetchList();
        setTipoSelecionado(undefined);
      },
    });
  }

  function handleUpdateSaleOff(data: any) {
    data.receiving_method = options_forma_recebimento
      .find(
        option_forma_recebimento =>
          option_forma_recebimento.label === data.receiving_method,
      )
      ?.number.toString();

    data.collection_method = options_meio_arrecadacao
      .find(
        option_meio_arrecadacao =>
          option_meio_arrecadacao.label === data.collection_method,
      )
      ?.number.toString();

    delete data.validation_type;
    delete data.validation_value;

    const body = saleOffData?.map(item => {
      // eslint-disable-next-line eqeqeq
      if (
        item.id_establishment_settlement_type ==
        tipoLiquidacaoSelectedModal.id_establishment_settlement_type
      ) {
        delete item.validation_type;
        delete item.validation_value;
        delete item.updated_at;

        // remove created_at from item, after delete item.created_at
        const { created_at, ...rest } = item;

        return { ...rest, ...data };
      }

      return item;
    });

    return fetchChangeArquivoRetorno({
      method: 'put',
      url: `/backoffice/user/${id_usuario}/settlement`,
      data: body,
      onSuccess: () => {
        form.resetFields();
        setModalChange(false);
        refetchList();
        setTipoSelecionado(undefined);
      },
    });
  }

  function handleRemove(id_establishment_settlement_type: string) {
    return fetchRemove({
      method: 'delete',
      url: `/backoffice/user/${id_usuario}/settlement/${id_establishment_settlement_type}`,
      onSuccess: () => refetchList(),
    });
  }

  function handleTipoLiquidacaoSelected(
    id_establishment_settlement_type: string,
  ) {
    const tipo_liquidacao_selected = saleOffData?.find(
      saleOffType =>
        saleOffType.id_establishment_settlement_type ===
        id_establishment_settlement_type,
    );

    if (!tipo_liquidacao_selected) {
      message.warning('Tipo de liquidação não encontrado');
      return null;
    }

    return tipo_liquidacao_selected;
  }

  const handleChangeSafoffType = (
    id_establishment_settlement_type: string,
    id_tipo_liquidacao: EnumTipoLiquidacao,
  ) => {
    switch (id_tipo_liquidacao) {
      case EnumTipoLiquidacao.ARQUIVO_RETORNO:
        setTipoLiquidacaoSelectedModal({
          id_establishment_settlement_type,
          data: handleTipoLiquidacaoSelected(id_establishment_settlement_type),
        });

        setModalChange(true);
        break;
      default:
        break;
    }
  };

  function handleChangeSaleOffTypeLoading(
    id_tipo_liquidacao: EnumTipoLiquidacao,
  ) {
    switch (id_tipo_liquidacao) {
      case EnumTipoLiquidacao.ARQUIVO_RETORNO:
        return loadingChange;
      default:
        return false;
    }
  }

  const columns = [
    {
      title: 'Tipo de Liquidação',
      dataIndex: 'id_settlement_type',
      key: 'id_settlement_type',
      render: (item: number) => {
        if (EnumTipoLiquidacao.BANCARIO === item) return 'Bancária';

        return 'Arquivo retorno';
      },
    },
    {
      title: 'Criado Por',
      dataIndex: 'created_by',
      key: 'created_by',
      render: (item: string) => <UserName id_user={item} />,
    },
    {
      title: 'Criado Em',
      dataIndex: 'created_at',
      key: 'created_at',
    },
    {
      title: 'Alterar',
      dataIndex: 'id_establishment_settlement_type',
      key: 'id_establishment_settlement_type',
      width: '100px',
      render: (item: string, data: any) => (
        <Button
          type="primary"
          loading={handleChangeSaleOffTypeLoading(data.id_settlement_type)}
          onClick={() => handleChangeSafoffType(item, data.id_settlement_type)}
          disabled={!userPermissions.TIPO_LIQUIDACAO_REMOVER}
        >
          <RiExchangeFill />
        </Button>
      ),
    },
    {
      title: 'Remover',
      dataIndex: 'id_establishment_settlement_type',
      key: 'id_establishment_settlement_type',
      width: '100px',
      render: (item: string) => (
        <Popconfirm
          placement="topRight"
          title="Tem certeza que deseja remover esse tipo de liquidação"
          onConfirm={() => handleRemove(item)}
          okText="Sim"
          cancelText="Não"
          disabled={!userPermissions.TIPO_LIQUIDACAO_REMOVER}
        >
          <Button
            type="primary"
            danger
            loading={loadingRemove}
            disabled={!userPermissions.TIPO_LIQUIDACAO_REMOVER}
          >
            <MdRemoveCircle />
          </Button>
        </Popconfirm>
      ),
    },
  ];

  if (!is_estabelecimento || !userPermissions.TIPO_LIQUIDACAO_LISTAGEM) {
    return null;
  }

  return (
    <>
      <CardCollapse loading={isLoading} title="Tipo de Liquidação de Débitos">
        {userPermissions.TIPO_LIQUIDACAO_ADICIONAR ? (
          <>
            <Row gutter={16}>
              <Col md={18} />
              <Col md={6}>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  loading={loadingCreate}
                  onClick={() => setModal(true)}
                >
                  Adicionar
                </Button>
              </Col>
            </Row>

            <br />
          </>
        ) : null}

        {saleOffData?.length ? (
          <Table
            columns={columns}
            dataSource={saleOffData}
            size="small"
            pagination={false}
          />
        ) : (
          <Result extra="Nenhum tipo de liquidação adicionada" />
        )}
      </CardCollapse>

      <Modal
        title="Adicionar Tipo de Liquidacão"
        centered
        visible={modal}
        footer={false}
        onCancel={() => setModal(false)}
        width="1000px"
      >
        <Form
          form={form}
          onFinish={data => handleCreate(data)}
          layout="vertical"
        >
          <Row gutter={16}>
            <Col md={24}>
              <Form.Item
                label="Tipo de Liquidação"
                name="id_settlement_type"
                rules={[{ required: true, message: default_message }]}
              >
                <Select
                  placeholder="Selecione um tipo de liquidação"
                  options={tipos}
                  value={tipoSelecionado}
                  onChange={val => setTipoSelecionado(val)}
                />
              </Form.Item>
            </Col>
            {fields}
          </Row>

          <br />

          <Row justify="end">
            <Button
              type="primary"
              htmlType="submit"
              loading={loadingCreate}
              className="buttonMarginTopLow"
            >
              Adicionar
            </Button>
          </Row>
        </Form>
      </Modal>
      <Modal
        title="Alterar Dados do Arquivo de Retorno"
        centered
        visible={modalChange}
        onOk={handleUpdateSaleOff}
        onCancel={() => setModalChange(false)}
        footer={false}
        width="1000px"
      >
        <br />

        <Form
          form={form}
          onFinish={data => handleUpdateSaleOff(data)}
          layout="vertical"
        >
          <Row gutter={16}>
            <Col md={12}>
              <Form.Item
                label="Nome do Banco"
                name="bank_name"
                rules={[{ required: true, message: default_message }]}
                initialValue={tipoLiquidacaoSelectedModal?.data?.bank_name}
              >
                <Input maxLength={20} />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                label="Nome da Empresa/Orgão"
                name="company_organization_name"
                rules={[{ required: true, message: default_message }]}
                initialValue={
                  tipoLiquidacaoSelectedModal?.data?.company_organization_name
                }
              >
                <Input maxLength={20} />
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item
                label="Número Banco Empresa/Orgão"
                name="company_organization_bank_number"
                rules={[{ required: true, message: default_message }]}
                initialValue={
                  tipoLiquidacaoSelectedModal?.data
                    ?.company_organization_bank_number
                }
              >
                <Input maxLength={3} />
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item
                label="Número de Convênio"
                name="agreement_number"
                rules={[{ required: true, message: default_message }]}
                initialValue={
                  tipoLiquidacaoSelectedModal?.data?.agreement_number
                }
              >
                <Input maxLength={20} />
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item
                label="Prefixo da Agência"
                name="agency_prefix"
                rules={[{ required: true, message: default_message }]}
                initialValue={tipoLiquidacaoSelectedModal?.data?.agency_prefix}
              >
                <Input maxLength={4} />
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item
                label="Digito Prefixo da Agência"
                name="agency_prefix_digit"
                rules={[{ required: true, message: default_message }]}
                initialValue={
                  tipoLiquidacaoSelectedModal?.data?.agency_prefix_digit
                }
              >
                <Input maxLength={1} />
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item
                label="Conta Corrente"
                name="account_number"
                rules={[{ required: true, message: default_message }]}
                initialValue={tipoLiquidacaoSelectedModal?.data?.account_number}
              >
                <Input maxLength={10} />
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item
                label="Dígito Conta Corrente"
                name="account_number_digit"
                rules={[{ required: true, message: default_message }]}
                initialValue={
                  tipoLiquidacaoSelectedModal?.data?.account_number_digit
                }
              >
                <Input maxLength={1} />
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item
                label="Forma de Recebimento"
                name="receiving_method"
                rules={[{ required: true, message: default_message }]}
                initialValue={
                  options_forma_recebimento.find(
                    option_forma_recebimento =>
                      option_forma_recebimento.number.toString() ===
                      tipoLiquidacaoSelectedModal?.data?.receiving_method,
                  )?.label
                }
              >
                <Select placeholder="Selecione a forma de recebimento">
                  {options_forma_recebimento.map(option_forma_recebimento => (
                    <Select.Option value={option_forma_recebimento.label}>
                      {option_forma_recebimento.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item
                label="Meio de Arrecadação"
                name="collection_method"
                rules={[{ required: true, message: default_message }]}
                initialValue={
                  options_meio_arrecadacao.find(
                    option_meio_arrecadacao =>
                      option_meio_arrecadacao.number.toString() ===
                      tipoLiquidacaoSelectedModal?.data?.collection_method,
                  )?.label
                }
              >
                <Select placeholder="Selecione meio de arrecadação">
                  {options_meio_arrecadacao.map(option_meio_recebimento => (
                    <Select.Option
                      value={option_meio_recebimento.label}
                      key={option_meio_recebimento.label}
                    >
                      {option_meio_recebimento.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item
                label="Prefixo da Agencia Recebedora"
                name="receiving_agency_prefix"
                rules={[{ required: true, message: default_message }]}
                initialValue={
                  tipoLiquidacaoSelectedModal?.data?.receiving_agency_prefix
                }
              >
                <Input maxLength={4} />
              </Form.Item>
            </Col>
          </Row>

          <br />

          <Row justify="end">
            <Col md={3}>
              <Button type="default" onClick={() => setModalChange(false)}>
                Cancelar
              </Button>
            </Col>
            <Col md={4}>
              <Button type="primary" htmlType="submit" loading={loadingChange}>
                Salvar Alterações
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default CardTipoLiquidacao;
