import { Divider, Typography } from 'antd';
import { Container, Line, Label, Value } from './styles';
import React, { useMemo } from 'react';

const { Paragraph } = Typography;
export type ICard = {
  data: InfoData;
};

export type InfoData = {
  category: {
    name?: string;
    fields: InfoDataFields[];
  }[];
};
export type InfoDataFields = {
  name: string;
  value: any;
  copyable?: boolean;
};

const Card: React.FC<ICard> = ({ data }: ICard) => {
  const processed = useMemo(() => {
    if (!data.category.length) return undefined;

    let final: React.JSX.Element[] = [];

    data.category.forEach(cat => {
      if (cat.name) {
        final.push(<Divider orientation="left">{cat.name}</Divider>);
      }

      final.push(
        ...cat.fields.map(field => {
          let value = field.value;

          if (field.copyable) {
            value = <Paragraph copyable>{field.value}</Paragraph>;
          }

          return (
            <Line>
              <Label>{field.name}</Label>
              <Value>{value}</Value>
            </Line>
          );
        }),
      );
    });

    return final;
  }, [data]);

  return processed;
};

export default { Container, Line, Label, Value, Card };
