import { Typography, Tag } from 'antd';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import { format, parseISO } from 'date-fns';
import { dinheiroMask } from 'masks-br';
import CardCollapse from '../../../../components/CardCollapse';
import { DataBoletoRaw, EnumSettlementType } from '../../types';
import { StatusTipo } from '../../../../store/modules/utils/types';

const { Paragraph } = Typography;

interface PropsBoletoInfoGeral {
  data: DataBoletoRaw;
  loading: boolean;
}

export function findStatusColor(name: string, tipo: StatusTipo) {
  if (!name) {
    return null;
  }

  let color = '';

  switch (tipo?.toLocaleLowerCase()) {
    case 'danger':
    case 'error':
      color = 'red';
      break;
    case 'info':
      color = 'blue';
      break;
    case 'success':
      color = 'green';
      break;

    default:
      color = 'default';
      break;
  }

  return <Tag color={color}>{name}</Tag>;
}

const BoletoInfoGeral: React.FC<PropsBoletoInfoGeral> = ({
  data,
  loading,
}: PropsBoletoInfoGeral) => {
  const criado_em = useMemo(() => {
    if (!data?.created_at) return '';
    return format(new Date(data.created_at), 'dd/MM/yyyy HH:mm:ss');
  }, [data]);
  const due_date = useMemo(() => {
    if (!data?.due_date) return '';
    return format(parseISO(data.due_date), 'dd/MM/yyyy');
  }, [data]);

  const bill_type: string = useMemo(() => {
    if (data?.type === 'CLEARANCE_FORM') return 'Boleto bancário';
    if (data?.type === 'CONCESSIONAIRES') return 'Concessionária';
    return '';
  }, [data]);
  const settlement_type = useMemo(() => {
    switch (data?.id_settlement_type) {
      case EnumSettlementType.BANK:
        return <Tag color="blue">Bancário</Tag>;
      case EnumSettlementType.PIX:
        return <Tag color="blue">Pix</Tag>;
      case EnumSettlementType.RETURN_FILE:
        return <Tag color="blue">Arquivo de Retorno</Tag>;
      default:
        return <Tag>Não parametrizado</Tag>;
    }
  }, [data]);
  const payment_time = useMemo(() => {
    if (data?.start_payment_hour && data?.end_payment_hour) {
      return `${data?.start_payment_hour}:00 - ${data?.end_payment_hour}:00`;
    }
    return null;
  }, [data]);

  return (
    <>
      <CardCollapse header={false} loading={loading}>
        <Header>
          <div className="row row-header">
            <div>{criado_em}</div>
            <div>
              {data?.id_bill && <Paragraph copyable>{data?.id_bill}</Paragraph>}
            </div>
          </div>
          <div className="row row-body">
            <div>
              <span className="value">{dinheiroMask(data?.amount_total)}</span>
              {findStatusColor(data?.status?.name, data?.status?.type)}
            </div>
          </div>
        </Header>
        <Body>
          <span className="body-item">
            <h3>Original</h3>
            <p>{dinheiroMask(data?.amount_original)}</p>
          </span>
          <span className="body-item">
            <h3>Taxa</h3>
            <p>{dinheiroMask(data?.amount_additional)}</p>
          </span>
          <span className="body-item">
            <h3>Desconto</h3>
            <p>{dinheiroMask(data?.amount_discount)}</p>
          </span>
          <span className="body-item">
            <h3>Tipo</h3>
            <p>{bill_type}</p>
          </span>
          <span className="body-item">
            <h3>Liquidação</h3>
            {settlement_type}
          </span>
          {due_date && (
            <span className="body-item">
              <h3>Vencimento</h3>
              {due_date}
            </span>
          )}
          {data?.settlement_transaction_id ? (
            <span className="body-item">
              <h3>Protocolo</h3>
              <Paragraph copyable>{data?.settlement_transaction_id}</Paragraph>
            </span>
          ) : null}
          {payment_time ? (
            <span className="body-item">
              <h3>Janela de Pagamento</h3>
              {payment_time}
            </span>
          ) : null}
          {data?.source && (
            <span className="body-item">
              <h3>Origem</h3>
              {data.source}
            </span>
          )}
        </Body>
      </CardCollapse>
    </>
  );
};

export default BoletoInfoGeral;

export const Header = styled.div`
  border-bottom: 1px solid #f1f1f1;

  .row {
    display: flex;
    justify-content: space-between;

    span.value {
      font-size: 40px;
      margin-right: 25px;
      font-weight: lighter;
    }

    div {
      .ant-typography {
        margin: 0;
      }
    }
  }

  .row-header {
    font-size: 13px;
    color: #717171;

    .ant-typography {
      color: #717171;
    }
  }

  .row-body {
    margin-top: 10px;

    div {
      display: flex;
      align-items: center;
    }
  }
`;

export const Body = styled.div`
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;

  #body-item-cart {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2px;
  }

  span.body-item {
    /* min-width: 200px; */
    max-width: 300px;
    padding: 0 25px;
    height: 50px;
    border-right: 1px solid #f1f1f1;

    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
      border-right: none;
    }

    h3 {
      margin-bottom: 0;
      font-size: 13px;
      color: #a7a4a4;
      text-transform: uppercase;
    }

    p {
      font-size: 15px;
      margin: 0;
    }
  }
`;
