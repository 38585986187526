import React from 'react';
import { Button, Col, Row } from 'antd';
import CardCollapse from '../../../components/CardCollapse';
import { MdDelete } from 'react-icons/md';
import { format, parseISO } from 'date-fns';
import { dinheiroMask } from 'masks-br';
import { GetPanelBillSearchResponse } from '../services/types/bill';
import { DebitVehicle } from '../services/types/search.debits.detran';
import { ptBR } from 'date-fns/locale';

type CardDebitVehicleProps = {
  debit: DebitVehicle;
  onDelete: (id_debit: string) => void;
};

export function CardDebitVehicle({ onDelete, debit }: CardDebitVehicleProps) {
  return (
    <CardCollapse>
      <Row justify="space-between">
        <Col>
          <Row>
            <b>{debit.name}</b>
          </Row>
          {debit.description && (
            <Row style={{ gap: 4 }}>
              <b>Descrição: </b>
              {debit.description}
            </Row>
          )}
          <Row style={{ gap: 4 }}>
            <b>Vencimento:</b>
            {` ${format(parseISO(debit.due_date), 'dd/MM/yyyy', {
              locale: ptBR,
            })}`}
          </Row>

          {debit.renavam && (
            <Row style={{ gap: 4 }}>
              <b>Renavam:</b>
              {debit.renavam}
            </Row>
          )}
          {debit.infraction_date && (
            <Row style={{ gap: 4 }}>
              <b>Data da infração:</b>
              {` ${format(parseISO(debit.infraction_date), 'dd/MM/yyyy', {
                locale: ptBR,
              })}`}
            </Row>
          )}
          {debit.year && (
            <Row style={{ gap: 4 }}>
              <b>Ano:</b>
              {debit.year}
            </Row>
          )}
          <Row style={{ gap: 4 }}>
            <b>Placa:</b>
            {debit.license_plate}
          </Row>
          <Row style={{ gap: 4 }}>
            <b>Valor:</b> {dinheiroMask(debit.amount)}
          </Row>
        </Col>
        <Col md={1}>
          <Button
            type="link"
            icon={<MdDelete color="red" />}
            onClick={() => onDelete(debit.id_debit)}
          ></Button>
        </Col>
      </Row>
    </CardCollapse>
  );
}

type CardBillProps = {
  bill: GetPanelBillSearchResponse;
  onDelete: (id_bill: string) => void;
};

export function CardBill({ onDelete, bill }: CardBillProps) {
  return (
    <CardCollapse>
      <Row justify="space-between">
        <Col>
          <Row>
            <b>{bill.recipient.name}</b>
          </Row>
          <Row style={{ gap: 4 }}>
            <b>Linha Digitável: </b>
            {bill.digitable}
          </Row>
          <Row style={{ gap: 4 }}>
            <b>Vencimento:</b>
            {bill?.due_date
              ? ` ${format(parseISO(bill.due_date), 'dd/MM/yyyy', {
                  locale: ptBR,
                })}`
              : 'Sem data de vencimento'}
          </Row>
          <Row style={{ gap: 4 }}>
            <b>Valor:</b> {dinheiroMask(bill.amount.total)}
          </Row>
        </Col>
        <Col md={1}>
          <Button
            type="link"
            icon={<MdDelete color="red" />}
            onClick={() => onDelete(bill.id_bill)}
          ></Button>
        </Col>
      </Row>
    </CardCollapse>
  );
}
