import { Timeline, Typography } from 'antd';
import React, { useMemo } from 'react';
import { ptBR } from 'date-fns/locale';
import { format } from 'date-fns';

import { getColor, findStatusCheckoutIcon, findStatusColor } from '../../index';
import CardCollapse from '../../../../components/CardCollapse';
import { CheckoutTimeline } from '../types';

type ICarrinhoInfoStatus = {
  timeline: CheckoutTimeline[];
  loading: boolean;
};

const { Text } = Typography;

const CheckoutInfoTimeline: React.FC<ICarrinhoInfoStatus> = ({
  timeline,
  loading,
}: ICarrinhoInfoStatus) => {
  const status_list = useMemo(() => {
    return (
      <Timeline reverse>
        {timeline?.map(item => {
          return (
            <Timeline.Item
              dot={findStatusCheckoutIcon(item.status?.type, item.id_status)}
              color={getColor(item.status?.type?.toLocaleLowerCase())}
            >
              {findStatusColor(
                item.status.name,
                item.status.type,
                item.status.description,
              )}
              <Text type={item.status.type?.toLocaleLowerCase() as any}>
                {item.name}
              </Text>
              <br />
              <Text type="secondary">
                {item.created_at
                  ? format(new Date(item.created_at), 'PPPpp', { locale: ptBR })
                  : null}
              </Text>
              {item.description ? (
                <p style={{ marginTop: 15, marginBottom: 0 }}>
                  {item.description}
                </p>
              ) : null}
            </Timeline.Item>
          );
        })}
      </Timeline>
    );
  }, [timeline]);

  return (
    <>
      <CardCollapse title="Timeline" loading={loading}>
        {status_list}
      </CardCollapse>
    </>
  );
};

export default CheckoutInfoTimeline;
