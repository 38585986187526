import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { IState } from '../store';
import { IAuthState } from '../store/modules/auth/reducer';

export interface IPermissionContext {
  hasPermission(permission: number): boolean;
  getPermissions(): Permissions;
  userPermissions: Permissions;
}

const PermissionContext = createContext<IPermissionContext>(
  {} as IPermissionContext,
);

type Permissions = {
  [Property in PermissionsKeys]: boolean;
};
type PermissionsKeys = keyof typeof PermissoesPerfil;

const PermissionProvider: React.FC = ({ children }) => {
  const [permissions, setPermissions] = useState<Permissions>(
    {} as Permissions,
  );

  const { permissoes, authenticated } = useSelector<IState, IAuthState>(
    state => state.auth,
  );

  useEffect(() => {
    if (!authenticated) return;

    const final_permission: Permissions = {} as Permissions;
    const permissoes_copy = [...(permissoes || [])];

    Object.entries(PermissoesPerfil).forEach(([key, val]) => {
      if (Number.isNaN(Number(key))) {
        final_permission[key as PermissionsKeys] = permissoes_copy.some(
          perm => perm === val,
        );
      }
    });

    setPermissions(final_permission);
  }, [permissoes]);

  const hasPermission = (permission: number) => {
    return permissoes?.some(perm => perm === permission);
  };

  const getPermissions = () => {
    return permissions;
  };

  return (
    <PermissionContext.Provider
      value={{ hasPermission, getPermissions, userPermissions: permissions }}
    >
      {children}
    </PermissionContext.Provider>
  );
};

function usePermission(): IPermissionContext {
  const context = useContext(PermissionContext);

  if (!context) {
    throw new Error(
      'usePermission deve ser utilizado dentro do PermissionProvider',
    );
  }

  return context;
}

export { PermissionProvider, usePermission, PermissoesPerfil };

/**
 * Enum das permissões dos perfil
 *
 * @important
 * Os valores são gerados automaticamente pelo Typescript, ou seja, começa no valor 11 e continua em ordem do ENUM.
 * APENAS ALTERAR A ORDEM SE TIVER CERTEZA DO QUE ESTÁ FAZENDO!
 *
 * @example
 * CARRINHO_LISTAGEM = 11,
 * CARRINHO_INFORMACAO = 12,
 * ...
 */
enum PermissoesPerfil {
  CARRINHO = 1,
  COMISSAO,
  VINCULO,
  PERMISSAO,
  USUARIO,
  POS,
  FORMA_CONDICAO,
  CONTRATO,
  PERFIL,
  DETRAN,
  CARRINHO_LISTAGEM,
  CARRINHO_INFORMACAO,
  CARRINHO_REMOVER,
  CARRINHO_PAGAR_BOLETO,
  CARRINHO_ATUALIZAR_CLIENTE,
  COMISSAO_LISTAGEM,
  COMISSAO_PAGAR_COMISSAO,
  COMISSAO_REMOVER_COMISSAO,
  COMISSAO_PAGAR_COMUALMENTE_COMISSAO,
  VINCULO_LISTAGEM,
  VINCULO_APROVAR_REPROVAR,
  VINCULO_REMOVER,
  PERMISSAO_LISTAGEM,
  PERMISSAO_APROVAR_REPROVAR,
  PERMISSAO_ENVIAR_CONTRATO,
  PERMISSAO_ALTERAR_STATUS,
  PERMISSAO_VINCULAR_USUARIO,
  PERMISSAO_DESVINCULAR_USUARIO,
  USUARIO_LISTAGEM,
  USUARIO_CADASTRAR,
  USUARIO_EDITAR,
  USUARIO_VISUALIZAR_VINCULOS,
  USUARIO_INFORMACOES,
  POS_LISTAGEM,
  POS_CADASTRAR,
  POS_EDITAR,
  POS_VINCULAR_POS,
  POS_DESVINCULAR_POS,
  FORMA_CONDICAO_LISTAGEM,
  FORMA_CONDICAO_CADASTRAR,
  FORMA_CONDICAO_EDITAR,
  CONTRATO_SIGNATARIO_PADRAO_LISTAGEM,
  CONTRATO_SIGNATARIO_PADRAO_CADASTRAR,
  CONTRATO_SIGNATARIO_PADRAO_REMOVER,
  PERFIL_LISTAGEM,
  PERFIL_EDITAR,
  PERFIL_CADASTRAR,
  PERFIL_VINCULAR_USUARIO,
  PERFIL_DESVINCULAR_USUARIO,
  DETRAN_BUSCA,
  CLIENTE_API,
  CLIENTE_API_BUSCAR,
  CLIENTE_API_INFORMACAO,
  CLIENTE_API_LOGS,
  TIPO_LIQUIDACAO,
  TIPO_LIQUIDACAO_LISTAGEM,
  TIPO_LIQUIDACAO_ADICIONAR,
  TIPO_LIQUIDACAO_REMOVER,
  WHITELABEL,
  WHITELABEL_PERMISSAO,
  TAXA,
  TAXA_VISUALIZAR,
  TAXA_ADICIONAR,
  TAXA_REMOVER,
  CARRINHO_CADASTRAR_MANUALMENTE,
  ADMINISTRACAO,
  ADMINISTRACAO_GERAL_FORMAS_CONDICAO_PAGAMENTO,
  ADMINISTRACAO_GERAL_RESPONSABILIDADES,
  ADMINISTRACAO_GERAL_PERFIS,
  ADMINISTRACAO_GERAL_SIGNATARIOS_PADROES,
  ADMINISTRACAO_GERAL_SIMULADOR,
  ADMINISTRACAO_LOGISTICA_POS,
  ADMINISTRACAO_TI_CLIENTE_API,
  ADMINISTRACAO_TI_PARCEIROS,
  ADMINISTRACAO_TI_UPLOAD_IMAGENS,
  ADMINISTRACAO_RELATORIOS_GERAL,
  ADMINISTRACAO_RELATORIOS_FATURAMENTO,
  ADMINISTRACAO_RELATORIOS_ARQUIVO_RETORNO,
  ADMINISTRACAO_RELATORIOS_DIVERGENCIA_ADQUIRENCIA,
  ADMINISTRACAO_TOTEM_USUARIOS,
  DASHBOARD,
  DASHBOARD_BOLETO,
  DASHBOARD_TAXA,
  DASHBOARD_CARRINHO,
  DASHBOARD_SALDO_EM_CONTA,
  DASHBOARD_QTDE_VENDAS,
  DASHBOARD_QTDE_BOLETOS,
  DASHBOARD_TICKET_MEDIO_VENDAS,
  DASHBOARD_TICKET_MEDIO_BOLETOS,
  DASHBOARD_COMISSAO,
  DASHBOARD_BOLETOS_ESTORNADOS,
  DASHBOARD_BOLETOS_ERRO,
  DASHBOARD_BOLETOS_CANCELADOS,
  DASHBOARD_BOLETOS_AGUARDANDO_APROVACAO,
  DASHBOARD_BOLETOS_EM_DISPUTA,
  DASHBOARD_BOLETOS_ESTORNADO,
  DASHBOARD_GRAFICO_FATURAMENTO,
  DASHBOARD_GRAFICO_VENDAS,
}

export enum EnumPerfilTipo {
  ADMIN = 'admin',
  PARCEIRO = 'parceiro',
  ADMIN_ESTABELECIMENTO = 'admin_estabelecimento',
}
