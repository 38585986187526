import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import { MdOpenInNew } from 'react-icons/md';
import { Link, useHistory } from 'react-router-dom';

import PageHeader from '../../../components/PageHeader';
import Table from '../../../components/Table';
import { fetchApi } from '../../../services/api';
import Pagina from '../../../types/Pagina';
import CardCollapse from '../../../components/CardCollapse';
import { ISelect } from '../../../types/Form';
import findTagColorByTipo from '../../../utils/findTagColorByTipo';
import Card from '../../../components/Card';
import { usePermission } from '../../../hooks/usePermissions';

const { Paragraph } = Typography;

const uso_option: ISelect[] = [
  {
    label: <Tag color="green">Sim</Tag>,
    value: true,
  },
  {
    label: <Tag color="red">Não</Tag>,
    value: false,
  },
];

const POSPesquisar: React.FC = () => {
  const [form] = Form.useForm();
  const { userPermissions } = usePermission();

  const [loading, setLoading] = useState(true);
  const [filtros, setFiltros] = useState<any>({});
  const [dashboard, setDashboard] = useState<any[]>([]);
  const [pos, setPos] = useState<Pagina>({} as Pagina);

  const dashboardItems = useMemo(() => {
    const size = parseInt(String(24 / dashboard.length), 10);

    return dashboard.map(item => (
      <Col md={size}>
        <Card
          title={item.label}
          value={item.total}
          onClick={() =>
            form.setFieldsValue({ id_status_ponto_venda: item.value })
          }
        />
      </Col>
    ));
  }, [dashboard]);

  useEffect(() => {
    fetchPos();
    fetchDashboard();
  }, [filtros]);

  async function fetchPos(pagina = 1, porPagina = 25) {
    setLoading(true);

    return fetchApi({
      url: '/ponto_venda',
      method: 'get',
      query_params: {
        ...filtros,
        pagina,
        porPagina,
      },
      messages: {
        error: 'Erro ao buscar POS`s',
      },
      onError: () => {
        setLoading(false);
      },
      onSuccess: data => {
        setLoading(false);
        setPos(data);
      },
    });
  }

  async function fetchDashboard() {
    return fetchApi({
      url: '/ponto_venda/dashboard',
      method: 'get',
      onSuccess: data => {
        setDashboard(
          data.map((item: any) => ({
            value: item.id_status_ponto_venda,
            label: (
              <Tag color={findTagColorByTipo(item.tipo)}>{item.status}</Tag>
            ),
            total: item.total,
          })),
        );
      },
    });
  }

  function handleResetForm() {
    form.resetFields();
    setFiltros({});
  }

  function handleSetFiltroValues(data: any) {
    setFiltros(data);
  }

  const columns = [
    {
      title: '#',
      key: 'id_ponto_venda',
      dataIndex: 'id_ponto_venda',
      render: (item: string) => (
        <Paragraph copyable={{ tooltips: false }}>{item}</Paragraph>
      ),
    },
    {
      title: 'Modelo',
      key: 'modelo',
      dataIndex: 'modelo',
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (item: string, data: any) =>
        item ? (
          data.armazenamento ? (
            <Tooltip placement="top" title={data.armazenamento}>
              <Tag color={findTagColorByTipo(data.tipo_status)}>{item}</Tag>
            </Tooltip>
          ) : (
            <Tag color={findTagColorByTipo(data.tipo_status)}>{item}</Tag>
          )
        ) : (
          '-'
        ),
    },
    {
      title: 'Em uso',
      key: 'id_estabelecimento_ponto_venda',
      dataIndex: 'id_estabelecimento_ponto_venda',
      render: (item: boolean, data: any) =>
        item ? (
          <Tooltip placement="top" title={data.estabelecimento}>
            <Link to={`/usuario/${data.id_estabelecimento}`}>
              <Tag color="green">Sim</Tag>
            </Link>
          </Tooltip>
        ) : (
          <Tag color="red">Não</Tag>
        ),
    },
    {
      title: 'Serial Number',
      key: 'serial_number',
      dataIndex: 'serial_number',
    },
    {
      title: 'Logic Number',
      key: 'logic_number',
      dataIndex: 'logic_number',
    },
    {
      title: 'Editar',
      render: (data: any) =>
        userPermissions.POS_EDITAR ? (
          <Link
            className="ant-btn ant-btn-primary"
            to={`/pos/${data.id_ponto_venda}/editar`}
          >
            <MdOpenInNew />
          </Link>
        ) : (
          <Button type="primary" disabled>
            <MdOpenInNew />
          </Button>
        ),
    },
  ];

  return (
    <>
      <PageHeader title="POS`s" breadcrumb={['POS']}>
        {userPermissions.POS_CADASTRAR ? (
          <Link className="ant-btn ant-btn-primary" to="/pos/criar">
            Cadastrar POS
          </Link>
        ) : null}
      </PageHeader>

      <Row gutter={16}>{dashboardItems}</Row>

      <CardCollapse title="Filtros">
        <Form
          layout="vertical"
          onFinish={data => handleSetFiltroValues(data)}
          form={form}
        >
          <Row gutter={16}>
            <Col md={8}>
              <Form.Item name="id_ponto_venda" label="Id">
                <Input />
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item name="serial_number" label="Serial Number">
                <Input />
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item name="logic_number" label="Logic Number">
                <Input />
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item name="id_status_ponto_venda" label="Status">
                <Select options={dashboard} />
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item name="em_uso" label="Em Uso">
                <Select options={uso_option} />
              </Form.Item>
            </Col>
          </Row>

          <Row justify="end">
            <Button onClick={handleResetForm} className="buttonMarginRight">
              Limpar
            </Button>
            <Button type="primary" htmlType="submit">
              Filtrar
            </Button>
          </Row>
        </Form>
      </CardCollapse>

      <Table
        loading={loading}
        columns={columns}
        dataSource={pos.data}
        pagination={{
          current: pos.pagina,
          pageSize: pos.porPagina,
          total: pos.total,
          onChange: fetchPos,
        }}
      />
    </>
  );
};

export default POSPesquisar;
