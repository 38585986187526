import React, { useEffect, useMemo } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Route from './Route';
import { IState } from '../store';

import AdminRoutes from './adminRoutes';
import ParceiroRoutes from './parceiroRoutes';

import Dashboard from '../pages/Dashboard';
import DefaultNotFound from '../pages/Default/NotFound';
import Login from '../pages/Login';
import RecuperacaoSenha from '../pages/Login/RecuperacaoSenha';
import PerfilSeguranca from '../pages/Profile/Seguranca';
import TipoAcesso, { EnumTipoAcesso } from '../types/TipoAcesso';
import SMS from '../pages/SMS';
import PaymentLinksSearch from "../pages/LinkDePagamento/Search";
import PaymentLinkInfo from '../pages/LinkDePagamento/Info';


const Routes: React.FC = () => {
  const tipo_acesso = useSelector<IState, TipoAcesso | undefined>(
    state => state.auth.tipo,
  );

  const mainRoute = useMemo(() => {
    if (
      tipo_acesso === EnumTipoAcesso.ADMIN ||
      tipo_acesso === EnumTipoAcesso.ADMIN_ESTABELECIMENTO
    ) {
      return <AdminRoutes />;
    }

    if (tipo_acesso === EnumTipoAcesso.PARCEIRO) {
      return <ParceiroRoutes />;
    }

    return null;
  }, [tipo_acesso]);

  useEffect(() => {
    const script_zendesk = document.createElement('script');
    script_zendesk.id = 'ze-snippet';
    script_zendesk.src =
      'https://static.zdassets.com/ekr/snippet.js?key=defc81ac-8ea3-4569-950f-52290943ae10';
    const script_instatus = document.createElement('script');
    script_instatus.src =
      'https://parcelamostudo.instatus.com/pt-br/eae324fd/widget/script.js';

    if (mainRoute) {
      document.body.appendChild(script_instatus);
    }
    if (tipo_acesso !== EnumTipoAcesso.ADMIN) {
      document.body.appendChild(script_zendesk);
    }

    return () => {
      // clean up the script when the component in unmounted
      if (mainRoute) {
        try {
          document.body.removeChild(script_instatus);
          // document.body.removeChild(script_zendesk);
        } catch (error) {
          console.log(error);
        }
      }
    };
  }, [mainRoute]);

  return (
    <Switch>
      <Route path="/" exact component={() => <Redirect to="/login" />} />
      <Route path="/login" exact component={Login} />
      <Route path="/login/admin" exact component={Login} />
      <Route path="/login/admin/estabelecimento" exact component={Login} />
      <Route
        path="/account-recovery/:token"
        exact
        component={RecuperacaoSenha}
      />

      <Route path="/sms/comprovante_pagamento" component={SMS} />

      <Route path="/dashboard" exact component={Dashboard} isPrivate />

      <Route
        path="/perfil/seguranca"
        exact
        component={PerfilSeguranca}
        isPrivate
      />

      <Route path="/link" exact component={PaymentLinksSearch} isPrivate />
      <Route
        path="/link/:id_payment_link([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-4[0-9a-fA-F]{3}-[89aAbB][0-9a-fA-F]{3}-[0-9a-fA-F]{12})"
        exact
        component={PaymentLinkInfo}
        isPrivate
      />

      {mainRoute}

      <Route path="/*" exact component={DefaultNotFound} isPrivate />
    </Switch>
  );
};

export default Routes;
