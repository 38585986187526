import { IPerfil } from "../store/modules/user/reducer"
import EnumPerfil from "../types/enum/Perfis"
import EnumResponsabilidade from "../types/enum/Responsabilidade"

type CanAccessCommissionsPageProps = {
  responsabilidade?: EnumResponsabilidade
  perfis: IPerfil[]
}

export function canAccessCommissionsPage({ responsabilidade, perfis }: CanAccessCommissionsPageProps) {
  if (!responsabilidade) return false

  const profilesPublic = [
    EnumPerfil.DETRAN,
    EnumPerfil.PREFEITURA,
    EnumPerfil.TJ
  ]

  const hasSomePublicProfile = perfis.some(profile => profilesPublic.includes(profile.id_perfil))
  
  if (hasSomePublicProfile) {
    return responsabilidade === EnumResponsabilidade.REPRESENTANTE
  }

  const partnerResponsibilityCanAccess = [
    EnumResponsabilidade.REPRESENTANTE,
    EnumResponsabilidade.RESPONSAVEL
  ]

  return partnerResponsibilityCanAccess.includes(responsabilidade)
}