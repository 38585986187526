import { ApiDefaultResponseError } from '../../../../types/api';

export interface GetPanelBillSearchResponse {
  id_bill: string;
  digitable: string;
  type: string;
  assignor: string;
  amount: AmountBill;
  recipient: RecipientBill;
  payer: PayerBill;
  due_date: string;
}

export interface AmountBill {
  original: number;
  discount: number;
  additional: number;
  total: number;
}

export interface RecipientBill {
  name: string;
  document: string;
}

export interface PayerBill {
  name: string;
  document: string;
}

export type BillInfoErrorResponse = ApiDefaultResponseError<EnumErrorCodeBill>;

export enum EnumErrorCodeBill {
  PAYMENT_ERROR_BILL_EXPIRED = 'payment.error.bill.search.expired',
  PAYMENT_ERROR_BILL_INVALID = 'payment.error.bill.search.invalid.barcode',
  PAYMENT_ERROR_INVALID_BILL_CODE_SIZE = 'code must be shorter than or equal to 48 characters',
}

export const messageErrors = {
  [EnumErrorCodeBill.PAYMENT_ERROR_BILL_EXPIRED]:
    'O prazo de pagamento desta fatura expirou.',
  [EnumErrorCodeBill.PAYMENT_ERROR_BILL_INVALID]:
    'O código de barras não é válido.',
  [EnumErrorCodeBill.PAYMENT_ERROR_INVALID_BILL_CODE_SIZE]:
    'O código deve ter no máximo 48 caracteres.',
};

export function getBillErrorMessage({ error, message }: BillInfoErrorResponse) {
  return (
    messageErrors[error] ||
    messageErrors[message as EnumErrorCodeBill] ||
    message ||
    'Houve um erro inesperado, tente novamente!'
  );
}
