import React, { useEffect, useRef, useState } from 'react';
import CardCollapse from '../../../components/CardCollapse';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import { EnumTerminalType, terminalStatus } from '../services/types';
import { ColumnsType } from 'antd/lib/table';
import Table from '../../../components/Table';
import { useTerminalModels } from "../hooks";

export type FormStoreSchema = {
  type: EnumTerminalType;
  serial_number: string;
  logic_number: string;
  id_model: string;
  id_status: string;
};

export enum EnumFormType {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

type TerminalFormProps = {
  onSubmit: (data: FormStoreSchema) => void;
  loading: boolean;
  initialValues?: FormStoreSchema;
  formType?: EnumFormType;
};

export type FiltersModel = {
  name?: string
  page: number
  page_size: number
}

const defaultFiltersPagination = { page: 1, page_size: 25 }

const TerminalForm: React.FC<TerminalFormProps> = ({
  onSubmit,
  loading,
  initialValues,
  formType = EnumFormType.CREATE,
}) => {
  const [selectedIdModel, setSelectedIdModel] = useState<string>(
    initialValues?.id_model || '',
  );
  const [filtersModel, setFiltersModel] = useState<FiltersModel>(defaultFiltersPagination)
  const [form] = Form.useForm<FormStoreSchema>();
  const [formFilterModels] = Form.useForm();
  const hasFilledForm = useRef(false)

  const { data: models, isLoading: isLoadingModels } = useTerminalModels(filtersModel)

  useEffect(() => {
    if (initialValues && !hasFilledForm.current) {
      setSelectedIdModel(initialValues.id_model)
      form.setFieldsValue(initialValues);
      hasFilledForm.current = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  const statusOptions = Object.entries(terminalStatus).map(option => ({
    value: option[0],
    label: option[1],
  }));
  const typeOptions = Object.values(EnumTerminalType).map(value => ({ value }));

  const columns_models: ColumnsType<any> = [
    {
      title: 'Nome',
      dataIndex: 'name',
    },
    {
      title: 'Fabricante',
      dataIndex: 'manufacturer',
    },
    {
      title: 'Adquirente',
      dataIndex: 'acquirer',
    },
  ];

  function handleFilterModels(data: any) {
    if (data?.name) {
      setFiltersModel((oldFilters) => ({ ...oldFilters, ...data }))
    }
  }
  function handleResetFiltersModels() {
    formFilterModels.resetFields()
    setFiltersModel(({ page, page_size }) => ({ page, page_size }))
  }

  const validorFields = (rule: any, value: string, callback: (error?: string) => void) => {
    const startsWithSpace = /^\s/.test(value);
    const endsWithSpace = /\s$/.test(value);

    if (startsWithSpace || endsWithSpace) {
      callback('Campo não pode conter espaços!');
    } else {
      callback();
    }
  };

  return (
    <CardCollapse>
      <Form layout="vertical" onFinish={onSubmit} form={form}>
        <Row gutter={16}>
          <Col md={6} xs={8}>
            <Form.Item name="type" label="Tipo" rules={[{ required: true, message: 'Campo obrigatório' }]}>
              <Select
                options={typeOptions}
                placeholder="Tipo de terminal"
                disabled={loading}
              />
            </Form.Item>
          </Col>
          <Col md={6} xs={12}>
            <Form.Item name="serial_number" label="Serial Number" rules={[{ required: true, message: 'Campo obrigatório' }, { validator: validorFields }]}>
              <Input placeholder="Ex: 1170758865" disabled={loading} />
            </Form.Item>
          </Col>
          <Col md={6} xs={12}>
            <Form.Item name="logic_number" label="Logic Number" rules={[{ required: true, message: 'Campo obrigatório' }, { validator: validorFields }]}>
              <Input placeholder="Ex: 98BGB6E3R" disabled={loading} />
            </Form.Item>
          </Col>
          {formType === EnumFormType.UPDATE && (
            <Col md={6} xs={12}>
              <Form.Item name="id_status" label="Status" rules={[
                { required: true, message: 'Selecione um status' }
              ]}>
                <Select placeholder="Status do terminal" options={statusOptions} disabled={loading} />
              </Form.Item>
            </Col>
          )}
        </Row>

        <Row>
          <Col md={24}>
            <Form.Item
              name="id_model"
              label="Modelo"
              rules={[{ required: true, message: 'Selecione um modelo!' }]}
            >
              <CardCollapse>
                <Form
                  layout="vertical"
                  onFinish={data => handleFilterModels(data)}
                  form={formFilterModels}
                >
                  <Row gutter={16}>
                    <Col md={6} xs={12}>
                      <Form.Item name="name" label="Pesquisa por nome" rules={[{ validator: validorFields }]}>
                        <Input placeholder="Ex: A910" />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row justify="end">
                    <Button onClick={handleResetFiltersModels} className="buttonMarginRight">
                      Limpar
                    </Button>
                    <Button type="primary" onClick={() => formFilterModels.submit()}>
                      Filtrar
                    </Button>
                  </Row>
                </Form>
              </CardCollapse>
              <Table
                columns={columns_models}
                dataSource={models?.data || []}
                rowKey={row => row.id_model as string}
                pagination={{
                  current: models?.page,
                  pageSize: models?.page_size,
                  total: models?.total,
                  onChange: (page, page_size) => setFiltersModel(oldFilters => ({ ...oldFilters, page, page_size })),
                }}
                emptyText="Não há modelos disponíveis!"
                loading={isLoadingModels}
                rowSelection={{
                  type: 'radio',
                  selectedRowKeys: [selectedIdModel],
                  onChange: selectedRowKeys => {
                    if (!loading) {
                      setSelectedIdModel(selectedRowKeys[0]?.toString());
                      form.setFieldsValue({
                        id_model: selectedRowKeys[0]?.toString(),
                      });
                    }
                  },
                }}
                size="small"
              />
            </Form.Item>
          </Col>
        </Row>

        <br />

        <Row justify="end">
          <Button type="primary" htmlType="submit" loading={loading}>
            {formType === EnumFormType.CREATE ? 'Cadastrar' : 'Atualizar'}
          </Button>
        </Row>
      </Form>
    </CardCollapse>
  );
};

export default TerminalForm;
