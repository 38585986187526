import { Table, Typography } from 'antd';
import { dinheiroMask } from 'masks-br';
import React, { useMemo } from 'react';

import CardCollapse from '../../../../components/CardCollapse';
import { ICarrinho } from '../index';

const { Text } = Typography;

type IInfoSumario = {
  carrinho: ICarrinho;
  loading: boolean;
};

const taxas_antecipacao_padrao = 0.7;

const InfoSumario: React.FC<IInfoSumario> = ({
  carrinho,
  loading,
}: IInfoSumario) => {
  const boletos_val = useMemo(() => {
    return {
      total:
        (carrinho?.boletos?.reduce(
          (prev, item) => prev + item.valor_total,
          0,
        ) || 0) * -1,
      children:
        carrinho?.boletos?.map((boleto, index) => ({
          title: `Boleto ${index + 1}`,
          value: boleto.valor * -1,
        })) || [],
    };
  }, [carrinho.boletos]);

  const taxa_operacao_val = useMemo(() => {
    const final = carrinho?.formas_pagamento?.reduce(
      (prev, item) => {
        const mdr = item.valor_total * (item.mdr / 100);

        const valor_parcela =
          (item.valor_total - mdr) / item.condicao_pagamento;
        const antecipacao = Array.from(
          Array(item.condicao_pagamento).keys(),
        ).reduce(
          (_, i) =>
            valor_parcela * ((taxas_antecipacao_padrao * (i + 1)) / 100),
          0,
        );

        return {
          mdr: prev.mdr + mdr,
          antecipacao: prev.antecipacao + antecipacao,
        };
      },
      {
        mdr: 0,
        antecipacao: 0,
      },
    );

    const total = (final?.mdr + final?.antecipacao) * -1;

    return {
      total,
      children: [
        {
          title: `MDR`,
          value: final?.mdr * -1 || 0,
        },
        {
          title: `Antecipação`,
          value: final?.antecipacao * -1 || 0,
        },
      ],
    };
  }, [carrinho.formas_pagamento]);

  const comissao_val = useMemo(() => {
    return {
      total:
        (carrinho?.transacoes?.reduce(
          (prev, item) => prev + item.valor_comissao,
          0,
        ) || 0) * -1,
      children:
        carrinho?.transacoes?.map((comissao, index) => ({
          title: `Comissão ${index + 1}`,
          value: comissao.valor_comissao * -1,
        })) || [],
    };
  }, [carrinho.transacoes]);

  const valueColor = (value: number) => {
    if (value >= 0) {
      return <Text type="success">+ {dinheiroMask(value)}</Text>;
    }

    return <Text type="danger">- {dinheiroMask(value * -1)}</Text>;
  };

  return (
    <>
      <CardCollapse title="Sumário" loading={loading}>
        <Table
          size="small"
          pagination={false}
          showHeader={false}
          columns={[
            {
              dataIndex: 'title',
              key: 'title',
              width: '50%',
            },
            {
              dataIndex: 'value',
              key: 'value',
              width: '50%',
              align: 'right',
              render: (val: number) => valueColor(val),
            },
          ]}
          dataSource={[
            {
              // @ts-ignore
              key: '1',
              title: 'Pagamento',
              value: carrinho?.carrinho?.valor_total || 0,
            },
            {
              // @ts-ignore
              key: '2',
              title: 'Boleto',
              value: boletos_val.total,
              children: boletos_val.children,
            },
            {
              // @ts-ignore
              key: '3',
              title: 'Comissão',
              value: comissao_val.total,
              children: comissao_val.children,
            },
            {
              // @ts-ignore
              key: '4',
              title: 'Taxa de Operação',
              value: taxa_operacao_val.total,
              children: taxa_operacao_val.children,
            },
          ]}
          summary={(items: readonly { title: string; value: number }[]) => {
            const total = items.reduce((prev, item) => prev + item.value, 0);

            return (
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>
                  <strong>Total</strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} align="right">
                  {valueColor(total)}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            );
          }}
        />
      </CardCollapse>
    </>
  );
};

export default InfoSumario;
