import api from '../../../services/api';
import { GetEstablishmentsResponse } from './dto/get-establishment.dto';

export interface IGetEstablishmentOptions {
  label: string;
  value: string;
  key?: string;
  disabled?: boolean;
}

export async function getEstablishments(
  name: string,
): Promise<IGetEstablishmentOptions[]> {
  try {
    const response = await api.get<GetEstablishmentsResponse>(
      `/backoffice/establishment`,
      {
        params: {
          page: 1,
          page_size: 20,
          name,
        },
      },
    );

    return response.data.data.map(item => ({
      label: item.nome,
      value: item.id_usuario,
      key: item.id_usuario,
    }));
  } catch (error: any) {
    throw new Error(error.response.data);
  }
}
