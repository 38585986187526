import React, { memo, useEffect, useMemo } from 'react';
import { MdKeyboardArrowRight } from 'react-icons/md';

import { Container, LeftContainer, RightContainer, Breadcrumb } from './styles';

interface PageHeaderRequest {
  title: string;
  breadcrumb?: Array<string | JSX.Element>;
  children?: any;
}

function PageHeader({ children, title, breadcrumb = [] }: PageHeaderRequest) {
  useEffect(() => {
    document.title = `${title} | Parcelamos Tudo`;
  }, []);

  const bread = useMemo(() => {
    const breadcrumb_ref = [...breadcrumb];

    if (!breadcrumb_ref || !breadcrumb_ref.length) {
      return '';
    }

    if (breadcrumb_ref[0] !== 'Dashboard') {
      breadcrumb_ref.unshift('Dashboard');
    }

    return (
      <Breadcrumb>
        <ul>
          {breadcrumb_ref.map((item, index) => (
            <li key={String(index)}>
              {item}{' '}
              {index < breadcrumb_ref.length - 1 && <MdKeyboardArrowRight />}
            </li>
          ))}
        </ul>
      </Breadcrumb>
    );
  }, [breadcrumb]);

  return (
    <Container>
      <LeftContainer>
        <h1>{title}</h1>

        {bread}
      </LeftContainer>

      <RightContainer>{children}</RightContainer>
    </Container>
  );
}

export default memo(PageHeader);
