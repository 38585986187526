import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  message,
} from 'antd';
import { MdRemoveCircle } from 'react-icons/md';

import PageHeader from '../../../../components/PageHeader';
import Table from '../../../../components/Table';
import { usePermission } from '../../../../hooks/usePermissions';
import UserName from '../../../../components/User/Name';
import { EditFilled } from '@ant-design/icons';
import {
  QueryRule,
  query_rules_actions,
  query_rules_properties,
  query_rules_validations,
  parseRuleActionToPortuguese,
  parseRulePropertyToPortuguese,
  parseRuleValidationToPortuguese,
  EnumRuleType,
  ruleTypeLabel,
  query_rules_types,
} from '../types';
import { format } from 'date-fns';
import {
  useCreateQueryRule,
  useDeleteQueryRule,
  useUpdateQueryRule,
} from '../hooks';
import TextArea from 'antd/lib/input/TextArea';
import { useQuery } from 'react-query';
import { getGeneralQueryRules } from '../services';
import CardCollapse from '../../../../components/CardCollapse';
import { setQueryParams, useQueryParams } from '../../../../utils/UrlQuery';
import { fieldErrorMessages, fieldRegex } from '../../../../utils/fields';
import { render } from '@testing-library/react';

const default_message = 'Esse campo é obrigatório';
export type FiltersQueryRules = {
  page: number;
  page_size: number;
  type?: EnumRuleType;
  value?: string;
};

type FormFieldsSchema = {
  id_search_rule: string;
  property: string;
  validation: string;
  type: EnumRuleType;
  value: string;
  action: string;
  description: string;
};

const GeneralQueryRules: React.FC = () => {
  const { userPermissions } = usePermission();
  const [form] = Form.useForm();
  const query_values = useQueryParams();
  const [modalUpdateQueryRule, setModalUpdateQueryRule] = useState({
    open: false,
    query_rule: {} as QueryRule,
  });

  const [modalCreateQueryRule, setModalCreateQueryRule] = useState({
    open: false,
    query_rule: {} as QueryRule,
  });

  const [filters, setFilters] = useState<FiltersQueryRules>({
    page: 1,
    page_size: 25,
    ...query_values,
  });

  useEffect(() => {
    setQueryParams(filters)
    form.setFieldsValue({ ...query_values });
  }, [])

  const { data: queryRules, isLoading: isLoadingQueryRules } = useQuery(
    ['get-general-query-rules', filters],
    () => getGeneralQueryRules(filters),
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: false,
      onSuccess({data}) {
        if (Number(filters.page) !== 1 && !data.length) {
          handleSetFilterValue({ page: 1 })
        }
      },
    },
  );
  const { mutate: createQueryRule } = useCreateQueryRule();
  const { mutate: updateQueryRule } = useUpdateQueryRule();
  const { mutate: deleteQueryRule } = useDeleteQueryRule();

  const [formCreate] = Form.useForm();
  const [formUpdate] = Form.useForm<FormFieldsSchema>();

  function handlerAddQueryRule(data: any) {
    createQueryRule(data, {
      onSuccess: () => {
        message.success('Regra de consulta adicionada com sucesso!');
        setModalCreateQueryRule({ open: false, query_rule: {} as QueryRule });
        formCreate.resetFields();
      },
      onError: () => {
        setModalCreateQueryRule({ open: false, query_rule: {} as QueryRule });
        message.error('Erro ao adicionar regra de consulta!');
      },
    });
  }

  function handleRemoveQueryRule(id_search_rule: string) {
    deleteQueryRule(id_search_rule, {
      onSuccess: () => {
        message.success('Regra removida com sucesso!');
      },
      onError: () => {
        message.error('Erro ao remover regra!');
      },
    });
  }

  function handlerUpdateQueryRule(data: any) {
    updateQueryRule(data, {
      onSuccess: () => {
        message.success('Regra atualizada com sucesso!');
        setModalUpdateQueryRule({ open: false, query_rule: {} as QueryRule });
      },
      onError: () => {
        setModalUpdateQueryRule({ open: false, query_rule: {} as QueryRule });
        message.error('Erro ao atualizar regra!');
      },
    });
  }

  function handleSetFilterValue(data: any) {
    const newFilters = {
      ...filters,
      ...data,
    };
    setQueryParams(newFilters);
    setFilters(newFilters);
  }

  function handleResetFilters() {
    const reseted = {
      page: filters.page,
      page_size: filters.page_size,
    };
    form.resetFields();
    setQueryParams(reseted);
    setFilters(reseted);
  }

  const columns = [
    {
      title: 'Descrição',
      dataIndex: 'description',
      key: 'description',
      width: 250,
      render: (value: string) => {
        if (value) return value;

        return 'Sem descrição';
      },
    },
    {
      title: 'Estabelecimento',
      dataIndex: 'establishment_id',
      key: 'establishment_id',
      render: (value: string) => {
        if (value) return <UserName id_user={value} />;

        return <UserName name="Sem estabelecimento" />;
      },
    },
    {
      title: 'Tipo',
      dataIndex: 'type',
      key: 'type',
      render: (type: EnumRuleType) => {
        if (!type) return ruleTypeLabel[EnumRuleType.BILL];

        return ruleTypeLabel[type];
      },
    },
    {
      title: 'Regra',
      dataIndex: 'property',
      key: 'property',
      render: (item: string) => parseRulePropertyToPortuguese(item),
    },
    {
      title: 'Validação',
      dataIndex: 'validation',
      key: 'validation',
      render: (item: string) => parseRuleValidationToPortuguese(item),
    },
    {
      title: 'Valor',
      dataIndex: 'value',
      key: 'value',
    },
    {
      title: 'Ação',
      dataIndex: 'action',
      key: 'action',
      render: (item: string) => parseRuleActionToPortuguese(item),
    },
    {
      title: 'Criado Por',
      dataIndex: 'created_by',
      key: 'created_by',
      render: (item: string) => <UserName id_user={item} />,
    },
    {
      title: 'Criado Em',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (item: string) => format(new Date(item), 'dd/MM/yyyy HH:mm:ss'),
    },
    {
      title: 'Editar',
      width: 100,
      render: (data: any) => (
        <Button
          type="primary"
          onClick={() => {
            formUpdate.setFieldsValue({
              id_search_rule: data.id_search_rule,
              property: data.property,
              validation: data.validation,
              value: data.value,
              type: data.type,
              action: data.action,
              description: data.description,
            });
            setModalUpdateQueryRule({
              open: true,
              query_rule: data,
            });
          }}
        >
          <EditFilled />
        </Button>
      ),
    },
    {
      title: 'Remover',
      width: 100,
      render: (data: any) => (
        <Popconfirm
          placement="topRight"
          title="Tem certeza que deseja remover essa regra?"
          onConfirm={() => handleRemoveQueryRule(data.id_search_rule)}
          okText="Sim"
          cancelText="Não"
        >
          <Button type="primary" danger>
            <MdRemoveCircle />
          </Button>
        </Popconfirm>
      ),
    },
  ];

  return (
    <>
      <PageHeader
        title="Regras gerais de consulta"
        breadcrumb={['Regras gerais de consulta']}
      >
        {userPermissions.USUARIO_CADASTRAR ? (
          // @ts-ignore
          <Button
            type="primary"
            onClick={() => {
              setModalCreateQueryRule({
                open: true,
                query_rule: {} as QueryRule,
              });
            }}
          >
            Cadastrar Nova Regra
          </Button>
        ) : null}
      </PageHeader>

      <CardCollapse title="Filtros">
        <Form
          layout="vertical"
          onFinish={data => handleSetFilterValue(data)}
          form={form}
        >
          <Row gutter={16}>
            <Col md={8} xs={12}>
              <Form.Item name="type" label="Tipo">
                <Select
                  options={query_rules_types}
                  placeholder="Boleto ou Pix"
                />
              </Form.Item>
            </Col>
            <Col md={8} xs={12}>
              <Form.Item label="Valor" name="value">
                <Input placeholder="Digite um valor" />
              </Form.Item>
            </Col>
          </Row>

          <br />

          <Row justify="end">
            <Button className="buttonMarginRight" onClick={handleResetFilters}>
              Limpar
            </Button>
            <Button type="primary" htmlType="submit">
              Filtrar
            </Button>
          </Row>
        </Form>
      </CardCollapse>

      <Table
        columns={columns}
        dataSource={queryRules?.data || []}
        rowKey="id_search_rule"
        loading={isLoadingQueryRules}
        pagination={{
          pageSize: queryRules?.page_size || 0,
          current: queryRules?.page || 0,
          total: queryRules?.total || 0,
          showSizeChanger: false,
          onChange(page, page_size) {
            handleSetFilterValue({ page, page_size });
          },
        }}
        emptyText="Nenhuma regra de consulta criada!"
      />

      <Modal
        title="Criar Regra"
        visible={modalCreateQueryRule.open}
        onCancel={() =>
          setModalCreateQueryRule({ open: false, query_rule: {} as QueryRule })
        }
        footer={false}
      >
        <Form
          layout="vertical"
          onFinish={data => handlerAddQueryRule(data)}
          form={formCreate}
        >
          <Row gutter={2}>
            <Col md={24} xs={24}>
              <Form.Item
                label="Tipo"
                name="type"
                rules={[{ required: true, message: default_message }]}
              >
                <Select
                  placeholder="Para que se aplica?"
                  options={query_rules_types}
                />
              </Form.Item>
            </Col>
            <Col md={24} xs={24}>
              <Form.Item
                label="Propriedade"
                name="property"
                rules={[{ required: true, message: default_message }]}
              >
                <Select
                  placeholder="Selecione uma regra"
                  options={query_rules_properties}
                />
              </Form.Item>
            </Col>
            <Col md={24} xs={24}>
              <Form.Item
                label="Validação"
                name="validation"
                rules={[{ required: true, message: default_message }]}
              >
                <Select
                  placeholder="Selecione um validação"
                  options={query_rules_validations}
                />
              </Form.Item>
              <Form.Item
                label="Valor"
                name="value"
                rules={[
                  { required: true, message: default_message },
                  {
                    message: fieldErrorMessages.clearSpaces,
                    pattern: fieldRegex.clearSpacesBegnningAndEnd,
                  },
                ]}
              >
                <Input placeholder="Digite um valor" />
              </Form.Item>
            </Col>
            <Col md={24} xs={24}>
              <Form.Item
                label="Ação"
                name="action"
                rules={[{ required: true, message: default_message }]}
              >
                <Select
                  placeholder="Selecione uma regra"
                  options={query_rules_actions}
                />
              </Form.Item>
            </Col>
            <Col md={24} xs={24}>
              <Form.Item
                label="Descrição"
                name="description"
                rules={[
                  { required: true, message: default_message },
                  {
                    message: fieldErrorMessages.clearSpaces,
                    pattern: fieldRegex.clearSpacesBegnningAndEnd,
                  },
                ]}
              >
                <TextArea placeholder="Ex: Descrição..." rows={3} />
              </Form.Item>
            </Col>
          </Row>
          <br />
          <Row justify="end">
            <Button type="primary" htmlType="submit">
              Criar
            </Button>
          </Row>
        </Form>
      </Modal>

      <Modal
        title="Editar Regra"
        visible={modalUpdateQueryRule.open}
        onCancel={() => {
          setModalUpdateQueryRule({ open: false, query_rule: {} as QueryRule });
          formUpdate.resetFields();
        }}
        footer={false}
      >
        <Form
          layout="vertical"
          onFinish={data => handlerUpdateQueryRule(data)}
          form={formUpdate}
          initialValues={{
            id_search_rule: modalUpdateQueryRule.query_rule.id_search_rule,
            property: modalUpdateQueryRule.query_rule.property,
            validation: modalUpdateQueryRule.query_rule.validation,
            value: modalUpdateQueryRule.query_rule.value,
            action: modalUpdateQueryRule.query_rule.action,
            description: modalUpdateQueryRule.query_rule.description,
          }}
        >
          <Row gutter={4}>
            <Col md={24} xs={24}>
              <Form.Item
                label="ID"
                name="id_search_rule"
                rules={[{ required: true, message: default_message }]}
              >
                <Input placeholder="Digite um valor" disabled />
              </Form.Item>

              <Form.Item
                label="Tipo"
                name="type"
                rules={[{ required: true, message: default_message }]}
              >
                <Select
                  placeholder="Para que se aplica?"
                  options={query_rules_types}
                />
              </Form.Item>

              <Form.Item
                name="property"
                label="Regra"
                rules={[{ required: true, message: default_message }]}
              >
                <Select
                  placeholder="Selecione uma regra"
                  options={query_rules_properties}
                />
              </Form.Item>
            </Col>
            <Col md={24} xs={24}>
              <Form.Item
                label="Validação"
                name="validation"
                rules={[{ required: true, message: default_message }]}
              >
                <Select
                  placeholder="Selecione um validação"
                  options={query_rules_validations}
                />
              </Form.Item>
              <Form.Item
                label="Valor"
                name="value"
                rules={[
                  { required: true, message: default_message },
                  {
                    message: fieldErrorMessages.clearSpaces,
                    pattern: fieldRegex.clearSpacesBegnningAndEnd,
                  },
                ]}
              >
                <Input placeholder="Digite um valor" />
              </Form.Item>
            </Col>
            <Col md={24} xs={24}>
              <Form.Item
                label="Ação"
                name="action"
                rules={[{ required: true, message: default_message }]}
              >
                <Select
                  placeholder="Selecione uma regra"
                  options={query_rules_actions}
                />
              </Form.Item>
            </Col>
            <Col md={24} xs={24}>
              <Form.Item
                label="Descrição"
                name="description"
                rules={[
                  { required: true, message: default_message },
                  {
                    message: fieldErrorMessages.clearSpaces,
                    pattern: fieldRegex.clearSpacesBegnningAndEnd,
                  },
                ]}
              >
                <TextArea placeholder="Ex: Descrição..." rows={3} />
              </Form.Item>
            </Col>
          </Row>
          <br />
          <Row justify="end">
            <Button type="primary" htmlType="submit">
              Atualizar
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default GeneralQueryRules;
