import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 25px;
  border-radius: 5px;

  background: ${props => props.theme.card_background};
  box-shadow: ${props => props.theme.card_shadow};
  transition: ${props => props.theme.card_transition};

  &:hover {
    box-shadow: ${props => props.theme.card_shadow_hover};
  }
`;

export const ContainerItem = styled.div`
  padding: 10px 15px;

  & ~ & {
    border-top: 1px solid ${props => props.theme.card_hr};
  }
`;
