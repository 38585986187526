import api from "../../../services/api";
import { GetAllPixResponse } from "./types";

export type GetAllPixParams = {
  page: number,
  page_size: number,
  id_pix?: string,
  status?: string,
  amount_total?: number,
}

export async function getAllPix(params?: GetAllPixParams) {
  const response = await api.get<GetAllPixResponse>('/backoffice/pix', { params })

  return response.data
}