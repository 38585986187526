import { Row, Spin } from 'antd';
import React, { useState, useEffect, useMemo } from 'react';

import PageHeader from '../../../components/PageHeader';
import { useApi } from '../../../hooks/useApi';
import PosEstabelecimentoCard from './Card';

/** Página que lista os pos do estabelecimento */
const POSEstabelecimento: React.FC = () => {
  const [pos, setPos] = useState([]);

  const { fetchRequest, loading } = useApi();

  const posList = useMemo(() => {
    if (loading) {
      return (
        <Row justify="center" style={{ marginTop: 150 }}>
          <Spin />
        </Row>
      );
    }

    return pos.map(item => (
      <PosEstabelecimentoCard {...item} handleRefrash={handleFetch} />
    ));
  }, [pos, loading]);

  useEffect(() => {
    handleFetch();
  }, []);

  function handleFetch() {
    return fetchRequest({
      method: 'get',
      url: '/ponto_venda',
      onSuccess: data => {
        setPos(data);
      },
    });
  }

  return (
    <>
      <PageHeader title="Seus POS's" />

      {posList}
    </>
  );
};

export default POSEstabelecimento;
