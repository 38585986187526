import React, { useState, useEffect, useMemo } from 'react';
import { Button, Col, Form, Input, Row, Tag, Typography } from 'antd';
import { MdInfo, MdOpenInNew } from 'react-icons/md';
import { Link, useHistory } from 'react-router-dom';
import { cpfMask, cnpjMask, cleanMask } from 'masks-br';

import PageHeader from '../../../components/PageHeader';
import Table from '../../../components/Table';
import { fetchApi } from '../../../services/api';
import Pagina from '../../../types/Pagina';
import CardCollapse from '../../../components/CardCollapse';
import Card from '../../../components/Card';
import { setQueryParams, useQueryParams } from '../../../utils/UrlQuery';
import { usePermission } from '../../../hooks/usePermissions';

const { Paragraph } = Typography;
const fontSizeValue = '24px';

const UsuarioPesquisar: React.FC = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const query = useQueryParams();
  const { userPermissions } = usePermission();

  const [usuario, setUsuario] = useState<Pagina>({} as Pagina);
  const [loading, setLoading] = useState(true);
  const [filtros, setFiltros] = useState<any | undefined>(undefined);
  const [dashboardInfo, setDashboardInfo] = useState<any[]>([]);

  const dashboard = useMemo(() => {
    const size = parseInt(String(24 / dashboardInfo.length), 10);

    return dashboardInfo.map(item => (
      <Col md={size}>
        <Card
          title={item.responsabilidade}
          value={item.total}
          fontSizeValue={fontSizeValue}
        />
      </Col>
    ));
  }, [dashboardInfo]);

  useEffect(() => {
    const final = {
      pagina: 1,
      porPagina: 25,
      ...query,
    };

    setFiltros(final);
    form.setFieldsValue(final);

    fetchDashboard();
  }, []);

  useEffect(() => {
    if (filtros) {
      fetchUsuarios();
    }
  }, [filtros]);

  async function fetchUsuarios() {
    setLoading(true);

    return fetchApi({
      url: '/usuario',
      method: 'get',
      query_params: {
        ...filtros,
        documento: cleanMask(filtros.documento),
      },
      onSuccess: (data: any) => {
        setLoading(false);

        const list = data.data.map((item: any) => ({
          ...item,
          documento: item.cpf ? cpfMask(item.cpf) : cnpjMask(item.cnpj),
          open_vinculo:
            item.permissoes.includes('Estabelecimento') ||
            item.permissoes.includes('Representante'),
        }));

        setUsuario({
          ...data,
          data: list,
        });
      },
      onError: () => {
        setLoading(false);
      },
    });
  }

  function fetchDashboard() {
    return fetchApi({
      url: `/usuario/dashboard`,
      method: 'get',
      onSuccess: (data: any) => setDashboardInfo(data),
    });
  }

  function handleResetForm() {
    form.resetFields();
    setFiltros({});
    setQueryParams({});
  }

  function handleSetFiltroValues(data: any) {
    const final = {
      ...filtros,
      ...data,
    };

    setQueryParams(final);
    setFiltros(final);
  }

  function handleSetDocument(data: any) {
    const cleaned = cleanMask(data);

    const documento =
      cleaned.length <= 11 ? cpfMask(cleaned) : cnpjMask(cleaned);

    form.setFieldsValue({ documento });
  }

  const columns = [
    {
      title: '#',
      dataIndex: 'id_usuario',
      key: 'id_usuario',
      render: (item: string) => (
        <Paragraph copyable={{ tooltips: false }}>{item}</Paragraph>
      ),
    },
    {
      title: 'Nome',
      dataIndex: 'nome',
      key: 'nome',
    },
    {
      title: 'Documento',
      dataIndex: 'documento',
      key: 'documento',
    },
    {
      title: 'Permissões',
      dataIndex: 'permissoes',
      key: 'permissoes',
      render: (item: string[]) =>
        item.map(item => <Tag color="blue">{item}</Tag>),
    },
    {
      title: 'Vínculos',
      width: 100,
      render: (data: any) => (
        <Button
          onClick={() =>
            history.push(`/usuario/${data.id_usuario}/vinculo`, {
              nome_usuario: data.nome,
            })
          }
          disabled={
            !data.open_vinculo || !userPermissions.USUARIO_VISUALIZAR_VINCULOS
          }
        >
          <MdOpenInNew />
        </Button>
      ),
    },
    {
      title: 'Informações',
      width: 100,
      render: (data: any) =>
        userPermissions.USUARIO_INFORMACOES ? (
          <Link
            className="ant-btn ant-btn-primary"
            to={`/usuario/${data.id_usuario}`}
          >
            <MdInfo />
          </Link>
        ) : (
          <Button disabled>
            <MdInfo />
          </Button>
        ),
    },
  ];

  return (
    <>
      <PageHeader title="Usuários" breadcrumb={['Usuário']}>
        {userPermissions.USUARIO_CADASTRAR ? (
          <Link className="ant-btn ant-btn-primary" to="/usuario/criar">
            Cadastrar Usuário
          </Link>
        ) : null}
      </PageHeader>

      <Row gutter={16}>{dashboard}</Row>

      <CardCollapse title="Filtros">
        <Form
          layout="vertical"
          onFinish={data => handleSetFiltroValues(data)}
          form={form}
        >
          <Row gutter={16}>
            <Col md={6}>
              <Form.Item name="id_usuario" label="Id">
                <Input />
              </Form.Item>
            </Col>
            <Col md={9}>
              <Form.Item name="email" label="Email">
                <Input />
              </Form.Item>
            </Col>
            <Col md={9}>
              <Form.Item name="nome" label="Nome">
                <Input />
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item name="documento" label="Documento">
                <Input onChange={val => handleSetDocument(val.target.value)} />
              </Form.Item>
            </Col>
          </Row>

          <br />

          <Row justify="end">
            <Button onClick={handleResetForm} className="buttonMarginRight">
              Limpar
            </Button>
            <Button type="primary" htmlType="submit">
              Filtrar
            </Button>
          </Row>
        </Form>
      </CardCollapse>

      <Table
        columns={columns}
        dataSource={usuario.data}
        loading={loading}
        pagination={{
          total: usuario.total,
          current: usuario.pagina,
          pageSize: usuario.porPagina,
          onChange: (pagina, porPagina) =>
            handleSetFiltroValues({ pagina, porPagina }),
        }}
      />
    </>
  );
};

export default UsuarioPesquisar;
