import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Form,
  Popconfirm,
  Result,
  Row,
  Select,
  Table as TableAntd,
} from 'antd';
import { Link } from 'react-router-dom';
import { MdRemoveCircle } from 'react-icons/md';

import CardCollapse from '../../../../components/CardCollapse';
import EnumPerfil from '../../../../types/enum/Perfis';
import Option from '../../../../types/Option';
import { useUtils } from '../../../../hooks/useUtils';
import { fetchApi } from '../../../../services/api';
import { usePermission } from '../../../../hooks/usePermissions';

type IState = {
  loading: boolean;
  default_message: string;
  id_usuario: string;
  usuario_perfis: any[];
  user_is_master: boolean;
  onAddPerfil: () => void;
  onRemovePerfil: () => void;
};

const CardPerfil: React.FC<IState> = ({
  loading,
  default_message,
  usuario_perfis,
  user_is_master,
  id_usuario,
  onAddPerfil,
  onRemovePerfil,
}: IState) => {
  const { getPerfil } = useUtils();
  const { userPermissions } = usePermission();

  const [perfil, setPerfil] = useState<Option[]>([]);

  useEffect(() => {
    fetchPerfil();
  }, []);

  async function fetchPerfil() {
    const perfis_response = await getPerfil();

    setPerfil(
      perfis_response?.map(item => ({
        value: item.id_perfil,
        label: `${item.nome} - ${item.descricao}`,
        disabled: item.id_perfil === EnumPerfil.MASTER && !user_is_master,
      })),
    );
  }

  function handleRemovePerfil(id_usuario_perfil: string) {
    return fetchApi({
      url: `/usuario/perfil/${id_usuario_perfil}`,
      method: 'delete',
      onSuccess: () => onAddPerfil(),
    });
  }

  function handleAddPerfil(data: { id_perfil: number }) {
    return fetchApi({
      url: `/usuario/${id_usuario}/perfil`,
      method: 'post',
      data: {
        id_perfil: data.id_perfil,
      },
      onSuccess: () => onRemovePerfil(),
    });
  }

  const columns_perfil = [
    {
      title: 'Perfil',
      dataIndex: 'perfil',
      key: 'perfil',
      render: (item: string, data: any) =>
        userPermissions.PERFIL_EDITAR ? (
          <Link to={`/perfil/${data.id_perfil}`}>{item}</Link>
        ) : (
          item
        ),
    },
    {
      title: 'Criado Por',
      dataIndex: 'criado_por_nome',
      key: 'criado_por_nome',
      render: (item: string, data: any) => (
        <Link to={`/usuario/${data.criado_por}`}>{item}</Link>
      ),
    },
    {
      title: 'Criado Em',
      dataIndex: 'criado_em',
      key: 'criado_em',
    },
    {
      title: 'Remover',
      width: 100,
      render: (data: any) => (
        <Popconfirm
          placement="topRight"
          title="Tem certeza que deseja remover esse perfil?"
          onConfirm={() => handleRemovePerfil(data.id_usuario_perfil)}
          disabled={!userPermissions.PERFIL_DESVINCULAR_USUARIO}
          okText="Sim"
          cancelText="Não"
        >
          <Button
            type="primary"
            danger
            disabled={!userPermissions.PERFIL_DESVINCULAR_USUARIO}
          >
            <MdRemoveCircle />
          </Button>
        </Popconfirm>
      ),
    },
  ];

  return (
    <CardCollapse loading={loading} title="Perfil">
      {userPermissions.PERFIL_VINCULAR_USUARIO ? (
        <>
          <Form layout="vertical" onFinish={handleAddPerfil}>
            <Row justify="end" gutter={16}>
              <Col md={18} xs={24}>
                <Form.Item
                  name="id_perfil"
                  rules={[{ required: true, message: default_message }]}
                >
                  <Select placeholder="Selecione um Perfil" options={perfil} />
                </Form.Item>
              </Col>
              <Col md={6} xs={24}>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="buttonMarginTopLow"
                  block
                >
                  Adicionar
                </Button>
              </Col>
            </Row>
          </Form>

          <br />
        </>
      ) : null}

      {usuario_perfis?.length ? (
        <TableAntd
          columns={columns_perfil}
          dataSource={usuario_perfis}
          pagination={false}
        />
      ) : (
        <Result extra="Usuário com nenhum perfil" />
      )}
    </CardCollapse>
  );
};

export default CardPerfil;
