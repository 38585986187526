import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Col, Form, Input, InputNumber, Row, Select, Switch, Table } from 'antd';

import PageHeader from '../../../components/PageHeader';
import CardCollapse from '../../../components/CardCollapse';
import { fetchApi } from '../../../services/api';

interface IResponsabilidade {
  comissoes: any[];
  responsabilidade: any;
}

const ResponsabilidadeEditar: React.FC = () => {
  const { crypt_id } = useParams<{ crypt_id: string }>();
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [comissoes, setComissoes] = useState<any[]>([]);
  const [responsabilidade, setResponsabilidade] = useState<any>({});

  useEffect(() => {
    fetchResponsabilidadeInfo();
  }, []);

  function fetchResponsabilidadeInfo() {
    return fetchApi<IResponsabilidade>({
      url: `/responsabilidade/${crypt_id}`,
      method: 'get',
      onSuccess: data => {
        setComissoes(data.comissoes);
        setResponsabilidade(data.responsabilidade);
        setLoading(false)
      }
    });
  }
  
  function handleUpdate() {
    const data = {
      responsabilidade,
      comissao_padrao: comissoes.map(item => ({
        ...item,
        forma_pagamento: undefined,
        qtde_parcelas: undefined,
        criado_em: undefined,
      })),
    };

    return fetchApi<IResponsabilidade>({
      url: `/responsabilidade/${crypt_id}`,
      method: 'put',
      data,
      onSuccess: () => {
        history.push('/responsabilidade')
      }
    });
  }

  function handleUpdateField(id: number, campo: string, value: any) {
    const index = comissoes.findIndex(responsabilidade => responsabilidade.id_comissao_padrao === id);

    const respon = [...comissoes];

    respon[index] = {
      ...comissoes[index],
      [campo]: value,
    };

    setComissoes(respon);
  }

  const columns = [
    {
      title: 'Forma',
      key: 'forma_pagamento',
      dataIndex: 'forma_pagamento',
    },
    {
      title: 'Parcela',
      key: 'qtde_parcelas',
      dataIndex: 'qtde_parcelas',
    },
    // {
    //   title: 'Comissão',
    //   key: 'pct_comissao',
    //   dataIndex: 'pct_comissao',
    //   width: 250,
    //   render: (item: number, data: any) => (
    //     <InputNumber
    //       value={item}
    //       min={0}
    //       max={100}
    //       precision={2}
    //       formatter={value => `${value}%`}
    //       onChange={value => handleUpdateField(data.id_comissao_padrao, 'pct_comissao', value)}
    //     />
    //   )
    // },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (item: boolean, data: any) => (
        <Switch
          defaultChecked={item}
          onChange={value => handleUpdateField(data.id_comissao_padrao, 'status', value)}
        />
      )
    },
    {
      title: 'Base de Cálculo',
      key: 'base_calculo',
      dataIndex: 'base_calculo',
      width: 250,
      render: (item: number, data: any) => (
        <Select
          value={item}
          onChange={value => handleUpdateField(data.id_comissao_padrao, 'base_calculo', value)}
        >
          <Select.Option value={1}>Valor Bruto</Select.Option>
          <Select.Option value={2}>Valor Bruto + Taxa</Select.Option>
        </Select>
      )
    },
    {
      title: 'Porcentagem Imposto',
      key: 'pct_imposto',
      dataIndex: 'pct_imposto',
      width: 250,
      render: (item: number, data: any) => (
        <InputNumber
          value={item}
          min={0}
          max={100}
          precision={2}
          formatter={value => `${value}%`}
          onChange={value => handleUpdateField(data.id_comissao_padrao, 'pct_imposto', value)}
        />
      )
    },
  ];

  return (
    <>
      <PageHeader title="Editar Responsabilidade" breadcrumb={['Responsabilidade', 'Editar']} />

      <CardCollapse header={false}>
        <Form layout="vertical">
          <Row gutter={16}>
            <Col md={8}>
              <Form.Item label="Nome">
                <Input value={responsabilidade.nome} disabled />
              </Form.Item>
            </Col>
            <Col md={16}>
              <Form.Item label="Descrição">
                <Input value={responsabilidade.descricao} disabled />
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <br />

        <Table
          dataSource={comissoes}
          columns={columns}
          pagination={false}
          loading={loading}
          size="small"
        />

        <br />

        <Row justify="end">
          <Button type="primary" onClick={() => handleUpdate()}>
            Editar
          </Button>
        </Row>
      </CardCollapse>
    </>
  );
}

export default ResponsabilidadeEditar;