import React, { useEffect, useMemo, useState } from 'react';
import { Button, Col, Form, message, Row, Select } from 'antd';
import { useSelector } from 'react-redux';
import { dinheiroMask } from 'masks-br';

import { FilePdfFilled } from '@ant-design/icons';
import download from 'downloadjs';
import CardCollapse from '../../../components/CardCollapse';
import { ItemInputCurrency } from '../../../components/Form';
import PageHeader from '../../../components/PageHeader';
import { Important } from '../../../components/Text';
import Option from '../../../types/Option';
import api, { fetchApi } from '../../../services/api';
import { IState } from '../../../store';
import { IUserState } from '../../../store/modules/user/reducer';
import { IAuthState } from '../../../store/modules/auth/reducer';
import EnumResponsabilidade from '../../../types/enum/Responsabilidade';

const select_forma_pagamento: Option[] = [
  {
    label: 'Crédito',
    value: 1,
  },
  {
    label: 'Débito',
    value: 2,
  },
];

interface IField {
  valor_boleto: number;
  forma_pagamento: number;
  condicao_pagamento: number;
}

type FinalValues = {
  valor_boleto: string;
  valor_pagamento: string;
  valor_parcela: string;
  valor_diferenca_parcela: string;
  valor_diferenca_total: string;
};

type AllFee = {
  by_usuario: {
    condicao_pagamento: any;
    id_usuario: string;
    nome: string;
  }[];
  padrao: any[];
};

type IButtonDownloadPdf = {
  enable: boolean;
  valor_boleto: number;
  qtde_parcelas: number;
  forma_pagamento: number;
};

const SimulacaoInfo: React.FC = () => {
  const auth = useSelector<IState, IAuthState>(state => state.auth);
  const user = useSelector<IState, IUserState>(state => state.user);

  const [fieldValue, setFieldsValue] = useState<IField>({} as IField);
  const [selectCondicaoPagamento, setSelectCondicaoPagamento] = useState<
    Option[]
  >([]);
  const [condicaoPagamento, setCondicaoPagamento] = useState<any[]>([]);
  const [allCondicaoPagamento, setAllCondicaoPagamento] = useState<AllFee>(
    {} as AllFee,
  );
  const [estabelecimentoOptions, setEstabelecimentoOptions] = useState<
    Option[]
  >([]);
  const [establishmentSelected, setEstablishmentSelected] = useState();
  const [buttonDownloadPdf, setButtonDownloadPdf] =
    useState<IButtonDownloadPdf>({
      enable: false,
      valor_boleto: 0,
      qtde_parcelas: 0,
      forma_pagamento: 0,
    });

  const find_responsabilidade = user.responsabilidades.find(
    item => item.id_responsabilidade === auth.responsabilidade,
  );

  const is_incentivador =
    find_responsabilidade?.id_responsabilidade ===
    EnumResponsabilidade.INCENTIVADOR;
  const id_estabelecimento = is_incentivador
    ? user.id_estabelecimento
    : user.id_usuario;

  useEffect(() => {
    if (auth.tipo === 'parceiro') {
      fetchCondicaoPagmentoParceiro();
    } else {
      fetchCondicaoPagmentoAdmin();
    }
  }, []);

  const final_values: FinalValues = useMemo(() => {
    if (
      !fieldValue.condicao_pagamento ||
      !fieldValue.valor_boleto ||
      !fieldValue.forma_pagamento
    ) {
      return {
        valor_boleto: dinheiroMask(0),
        valor_pagamento: dinheiroMask(0),
        valor_parcela: dinheiroMask(0),
        valor_diferenca_total: dinheiroMask(0),
        valor_diferenca_parcela: dinheiroMask(0),
      };
    }

    const coeficiente_final = condicaoPagamento.find(
      item =>
        item.id_forma_pagamento === fieldValue.forma_pagamento &&
        item.id_condicao_pagamento === fieldValue.condicao_pagamento,
    );

    if (!coeficiente_final) {
      return {
        valor_boleto: dinheiroMask(0),
        valor_pagamento: dinheiroMask(0),
        valor_parcela: dinheiroMask(0),
        valor_diferenca_total: dinheiroMask(0),
        valor_diferenca_parcela: dinheiroMask(0),
      };
    }

    const valor_pagamento =
      fieldValue.valor_boleto * coeficiente_final.coeficiente_final;

    const valor_parcela_juros =
      valor_pagamento / coeficiente_final.qtde_parcelas;
    const valor_parcela_limpo =
      fieldValue.valor_boleto / coeficiente_final.qtde_parcelas;

    const valor_diferenca_parcela = valor_parcela_juros - valor_parcela_limpo;
    const valor_diferenca_total = valor_pagamento - fieldValue.valor_boleto;

    const valor_parcela = `${coeficiente_final.qtde_parcelas} de ${dinheiroMask(
      valor_parcela_juros,
    )}`;

    setButtonDownloadPdf({
      enable: true,
      valor_boleto: fieldValue.valor_boleto,
      qtde_parcelas: coeficiente_final.qtde_parcelas,
      forma_pagamento: fieldValue.forma_pagamento,
    });

    return {
      valor_boleto: dinheiroMask(fieldValue.valor_boleto),
      valor_pagamento: dinheiroMask(valor_pagamento),
      valor_diferenca_total: dinheiroMask(valor_diferenca_total),
      valor_diferenca_parcela: dinheiroMask(valor_diferenca_parcela),
      valor_parcela,
    };
  }, [fieldValue, condicaoPagamento]);

  function fetchCondicaoPagmentoParceiro() {
    return fetchApi({
      url: `/condicao_pagamento/usuario/${id_estabelecimento}`,
      method: 'get',
      onSuccess: data => {
        setCondicaoPagamento(data);
      },
    });
  }

  function fetchCondicaoPagmentoAdmin() {
    return fetchApi({
      url: `/condicao_pagamento/usuario`,
      method: 'get',
      onSuccess: (data: AllFee) => {
        setCondicaoPagamento(data.padrao);
        setAllCondicaoPagamento(data);

        const estabelecimento_opt: Option[] = [
          {
            label: 'Padrão',
            value: 'padrao',
          },
          ...data.by_usuario.map(item => ({
            label: item.nome,
            value: item.id_usuario,
          })),
        ];

        setEstabelecimentoOptions(estabelecimento_opt);
      },
    });
  }

  function handleSelectUser(option: any) {
    const find_condicao = allCondicaoPagamento.by_usuario.find(
      item => item.id_usuario === option,
    );

    const condicao = find_condicao
      ? find_condicao.condicao_pagamento
      : allCondicaoPagamento.padrao;

    setEstablishmentSelected(option);
    setCondicaoPagamento(condicao);
  }

  function updateFieldValue(name: string, value: any) {
    if (name === 'forma_pagamento') {
      const condicao_pagamento = condicaoPagamento
        .filter(item => item.id_forma_pagamento === value)
        .map(item => ({
          label: item.qtde_parcelas,
          value: item.id_condicao_pagamento,
        }));

      setSelectCondicaoPagamento(condicao_pagamento);

      if (condicao_pagamento.length === 1) {
        updateFieldValue('condicao_pagamento', condicao_pagamento[0].value);
      } else {
        updateFieldValue('condicao_pagamento', null);
      }
    }

    setFieldsValue({ ...fieldValue, [name]: value });
  }

  async function handleDownloadPdf() {
    message.loading({
      content: 'Preparando arquivo para ser baixado, aguarde...',
      key: 'download',
      duration: 10000,
    });

    const idEstablishment =
      auth.tipo === 'parceiro' ? id_estabelecimento : establishmentSelected;

    try {
      const { data, headers, status } = await api.post(
        '/simulador/exportar/pdf',
        {
          valor_boleto: buttonDownloadPdf.valor_boleto,
          qtde_parcelas: buttonDownloadPdf.qtde_parcelas,
          forma_pagamento: buttonDownloadPdf.forma_pagamento,
          id_establishment:
            !!idEstablishment && idEstablishment !== 'padrao'
              ? idEstablishment
              : undefined,
        },
        {
          responseType: 'blob',
          onDownloadProgress: ({ total, loaded }) => {
            message.loading({
              content: `Preparando arquivo para ser baixado (${(
                (loaded * 100) /
                total
              ).toFixed(2)}%), aguarde...`,
              key: 'download',
              duration: 10000,
            });
          },
        },
      );

      if (status !== 200) throw Error('Erro ao baixar arquivo!');

      await download(data, 'simulador_exported.pdf', headers['content-type']);

      message.destroy();
      // message.success({ content: 'Arquivo pronto para ser baixado!', key: 'download' });
    } catch (err) {
      message.error({
        content: 'Erro ao baixar arquivo, tente novamente!',
        key: 'download',
      });
    }
  }

  return (
    <>
      <PageHeader title="Simulação de Valores" breadcrumb={['Simulação']}>
        <Button
          type="primary"
          icon={<FilePdfFilled />}
          disabled={!buttonDownloadPdf.enable}
          onClick={() => handleDownloadPdf()}
        >
          Download da simulação
        </Button>
      </PageHeader>

      <CardCollapse header={false}>
        <Form
          layout="vertical"
          initialValues={{
            estabelecimento: 'padrao',
          }}
        >
          <Row gutter={16}>
            {auth.tipo === 'admin' ? (
              <>
                <Col md={8} xs={24}>
                  <Form.Item label="Estabelecimento" name="estabelecimento">
                    <Select
                      onChange={val => handleSelectUser(val)}
                      options={estabelecimentoOptions}
                    />
                  </Form.Item>
                </Col>
                <Col md={16} xs={24} />
              </>
            ) : null}
            <Col md={8} xs={24}>
              <Form.Item label="Valor do Boleto">
                <ItemInputCurrency
                  value={fieldValue.valor_boleto}
                  onChange={(_, val) => updateFieldValue('valor_boleto', val)}
                />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item label="Forma de Pagamento">
                <Select
                  placeholder="Selecione Crédito ou Débito"
                  options={select_forma_pagamento}
                  value={fieldValue.forma_pagamento}
                  onChange={val => updateFieldValue('forma_pagamento', val)}
                />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item label="Vezes de Pagamento">
                <Select
                  placeholder="Selecione a quantidade de parcelas"
                  options={selectCondicaoPagamento}
                  value={fieldValue.condicao_pagamento}
                  onChange={val => updateFieldValue('condicao_pagamento', val)}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <br />

        <h1>Resultado</h1>

        <Form layout="vertical">
          <Row gutter={16} justify="center">
            <Col md={8} sm={12} xs={24}>
              <Form.Item label="Valor do Boleto">
                <Important size={28}>{final_values.valor_boleto}</Important>
              </Form.Item>
            </Col>
            <Col md={8} sm={12} xs={24}>
              <Form.Item label="Valor da Parcela">
                <Important size={28}>{final_values.valor_parcela}</Important>
              </Form.Item>
            </Col>
            <Col md={8} sm={12} xs={24}>
              <Form.Item label="Valor final do pagamento">
                <Important size={28}>{final_values.valor_pagamento}</Important>
              </Form.Item>
            </Col>
            <Col md={8} sm={12} xs={24}>
              <Form.Item label="Diferença Parcela">
                <Important size={28}>
                  {final_values.valor_diferenca_parcela}
                </Important>
              </Form.Item>
            </Col>
            <Col md={8} sm={24} xs={24}>
              <Form.Item label="Diferença Total">
                <Important size={28}>
                  {final_values.valor_diferenca_total}
                </Important>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </CardCollapse>
    </>
  );
};

export default SimulacaoInfo;
