import React, { useState, useEffect } from 'react';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { FaFileExcel, FaFileCsv, FaFilePdf } from 'react-icons/fa';
import { Empty, Skeleton, Tooltip } from 'antd';

import { Collapse } from './styles';

interface CardCollapseRequest {
  title?: string;
  start?: boolean;
  visible?: boolean;
  collapse?: boolean;
  padding?: boolean;
  header?: boolean;
  children?: React.Component | any;
  loading?: boolean;
  exportCSV?: () => void;
  exportPlanilhas?: () => void;
  exportPDF?: () => void;
}

function CardCollapse({
  title,
  start = true,
  collapse = true,
  visible = true,
  padding = true,
  header = !!title,
  children,
  loading,
  exportCSV,
  exportPlanilhas,
  exportPDF,
}: CardCollapseRequest): JSX.Element {
  const [toggle, setToggle] = useState(!collapse ? true : start);

  useEffect(() => {
    if (!collapse) start = true;
  }, [collapse, start]);

  function setToggleCard() {
    if (collapse) {
      setToggle(!toggle);
    }
  }

  return (
    <Collapse
      collapse={collapse}
      header={header}
      padding={padding}
      visible={visible}
    >
      <div className="head" onClick={setToggleCard}>
        <div className="title">{title}</div>
        <div className="icons">
          {exportCSV ? (
            <Tooltip placement="topRight" title="Baixe para Texto (.csv)">
              <FaFileCsv className="csv" onClick={exportCSV} />
            </Tooltip>
          ) : null}
          {exportPlanilhas ? (
            <Tooltip placement="topRight" title="Baixe para Planilha (.xlsx)">
              <FaFileExcel className="planilha" onClick={exportPlanilhas} />
            </Tooltip>
          ) : null}
          {exportPDF ? (
            <Tooltip placement="topRight" title="Baixe para PDF (.pdf)">
              <FaFilePdf className="planilha" onClick={exportPDF} />
            </Tooltip>
          ) : null}
          {collapse ? (
            toggle ? (
              <MdKeyboardArrowUp className="arrow" />
            ) : (
              <MdKeyboardArrowDown className="arrow" />
            )
          ) : (
            ''
          )}
        </div>
      </div>
      {toggle && (
        <div className="body">
          {loading ? (
            <Skeleton active paragraph={{ rows: 4 }} />
          ) : (
            children || <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </div>
      )}
    </Collapse>
  );
}

export default CardCollapse;
