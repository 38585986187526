import { fetchApi } from '../../../../services/api';

export async function getBonds(
  query_params: any,
  onError?: (error: any) => void,
  onSuccess?: (data: any) => void,
) {
  return await fetchApi({
    url: '/vinculo',
    method: 'get',
    query_params,
    onError,
    onSuccess,
  });
}
