import React, { useState, useEffect } from 'react';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Tag,
} from 'antd';
import { format, parseISO } from 'date-fns';
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';
import locale from 'antd/es/date-picker/locale/pt_BR';
import moment from 'moment';
import CardCollapse from '../../../components/CardCollapse';
import PageHeader from '../../../components/PageHeader';
import 'moment/locale/pt-br';
import { fetchApi } from '../../../services/api';
import { setQueryParams, useQueryParams } from '../../../utils/UrlQuery';
import { useSelector } from 'react-redux';
import { IState } from '../../../store';
import EnumResponsabilidade from '../../../types/enum/Responsabilidade';

const default_message = 'Esse campo é obrigatório';

type Option = {
  label: string;
  value: string;
};
enum StatusVinculoUnidadeIncentivador {
  VENCIDO = 'VENCIDO',
  VALIDO = 'VALIDO',
  REMOVIDO = 'REMOVIDO',
}

export type IVinculoUnidadeIncentivador = {
  usuario_de_nome: string;
  usuario_para_nome: string;
  responsabilidade: string;
  aprovacao_por?: string;
  aprovado?: string;
  justificativa?: string;
  aprovacao_em?: Date;
  id_vinculo_status?: string;
  documento_key?: string;
  status_nome?: string;
  status_tipo?: string;
  id_status_vinculo?: string;
  id_usuario_vinculo: string;
  id_usuario_de: string;
  id_usuario_para: string;
  id_responsabilidade: number;
  id_aprovacao?: string;
  comissao?: number;
  validade: string;
  criado_por: string;
  criado_em: Date;
  removido_por?: string;
  removido_em?: Date;
};

interface IListIVinculoUnidadeIncentivador {
  total: number;
  pagina: number;
  porPagina: number;
  data: IVinculoUnidadeIncentivador[];
}

interface IModalEditVinculo {
  visible: boolean;
  data?: IVinculoUnidadeIncentivador;
}

const Vinculos: React.FC = () => {
  const [formFilters] = Form.useForm();
  const [formEditVinculo] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [unidades, setUnidades] = useState([] as Option[]);
  const [incentivadores, setIncentivadores] = useState([] as Option[]);
  const [modalEditVinculo, setModalEditVinculo] = useState<IModalEditVinculo>({
    visible: false,
    data: {} as IVinculoUnidadeIncentivador,
  });
  const [filtros, setFiltros] = useState<any | undefined>(undefined);
  const [vinculoUnidadeIncentivador] = Form.useForm();
  const query = useQueryParams();
  const [vinculos, setVinculos] = useState(
    {} as IListIVinculoUnidadeIncentivador,
  );
  const id_usuario = useSelector((state: IState) => state.user.id_usuario);

  useEffect(() => {
    fetchUnidades();
    fetchUsuarioIncentivadorTj();
  }, []);

  useEffect(() => {
    FilterListIncentivadorUnidade();
  }, [vinculos]);

  async function FilterListIncentivadorUnidade() {
    const nome_unidades = unidades.map(unidade => unidade.label);
    const nome_incentivadores = incentivadores.map(
      incentivador => incentivador.label,
    );
    if (vinculos && vinculos.data?.length) {
      vinculos.data.map(
        vinculo =>
          nome_incentivadores.includes(vinculo.usuario_de_nome) &&
          setIncentivadores(
            incentivadores.filter(
              incentivador => incentivador.label !== vinculo.usuario_de_nome,
            ),
          ),
      );

      vinculos.data.map(
        vinculo =>
          nome_unidades.includes(vinculo.usuario_para_nome) &&
          setUnidades(
            unidades.filter(
              unidade => unidade.label !== vinculo.usuario_para_nome,
            ),
          ),
      );
    }
  }

  function handleCreateVinculo(data: any) {
    setLoading(true);

    data.user = data.id_usuario;
    data.id_responsibility = EnumResponsabilidade.INCENTIVADOR;
    data.comission = 0;
    data.validity = data.validade;

    return fetchApi({
      url: `/panel/users/tj/bind/${data.id_unidade}`,
      method: 'post',
      data,
      onSuccess: async () => {
        await fetchVinculosUnidadesIncentivadores();
        message.success('Vinculo criado com sucesso.');
        vinculoUnidadeIncentivador.resetFields();
        setLoading(false);
      },
      onError: error => {
        setLoading(false);
        message.error(error.message);
      },
    });
  }

  async function fetchUnidades() {
    setLoading(true);

    return fetchApi({
      url: '/panel/users/tj/establishment',
      method: 'get',
      query_params: {
        page: 1,
        page_size: 999,
      },
      onSuccess: (data: any) => {
        setLoading(false);
        setUnidades(
          data.data.map((unidade: any) => {
            return {
              label: unidade.nome,
              value: unidade.id_usuario,
            };
          }),
        );
      },
      onError: () => {
        setLoading(false);
      },
    });
  }
  async function fetchUsuarioIncentivadorTj() {
    setLoading(true);

    return fetchApi({
      url: '/panel/users/tj',
      method: 'get',
      query_params: {
        page: 1,
        page_size: 999,
      },
      onSuccess: (data: any) => {
        setLoading(false);
        setIncentivadores(
          data.data.map((incentivador: any) => {
            return {
              label: incentivador.nome,
              value: incentivador.id_usuario,
            };
          }),
        );
      },
      onError: () => {
        setLoading(false);
      },
    });
  }

  const handleSetFiltroValues = (data: any) => {
    setFiltros({
      ...filtros,
      ...data,
    });
  };

  useEffect(() => {
    const final = {
      page: 1,
      page_size: 25,
      ...query,
    };

    setFiltros(final);
    formFilters.setFieldsValue(final);
  }, []);

  useEffect(() => {
    if (filtros) {
      fetchVinculosUnidadesIncentivadores();
    }
  }, [filtros]);

  async function fetchVinculosUnidadesIncentivadores() {
    setLoading(true);

    return fetchApi({
      url: '/panel/users/tj/bind',
      method: 'get',
      query_params: {
        ...filtros,
        id_responsible: id_usuario,
      },
      onSuccess: (data: any) => {
        setLoading(false);
        setVinculos(data);
      },
      onError: () => {
        setLoading(false);
      },
    });
  }

  function handleResetForm() {
    setQueryParams({});
    formFilters.resetFields();
    setFiltros({
      page: 1,
      page_size: 25,
    });
    fetchVinculosUnidadesIncentivadores();
  }

  async function handleEditarVinculo(id_usuario_vinculo: string) {
    const vinculo_unidade_incentivador = vinculos.data.find(
      vinculo => vinculo.id_usuario_vinculo === id_usuario_vinculo,
    );

    if (!vinculo_unidade_incentivador) {
      message.error('Erro ao editar vinculo.');
    }

    setModalEditVinculo({
      visible: true,
      data: vinculo_unidade_incentivador,
    });
  }

  function handleUpdateVinculoUnidadeIncentivador(data: any) {
    setLoading(true);
    return fetchApi({
      url: `/panel/users/tj/bind`,
      method: 'put',
      data: {
        validity: data.validate,
        id_bind: data.id_usuario_vinculo,
      },
      onSuccess: (_data: any) => {
        setLoading(false);
        message.success('Vinculo editado.');
        fetchUnidades();
        fetchUsuarioIncentivadorTj();
        fetchVinculosUnidadesIncentivadores();
        setModalEditVinculo({
          visible: false,
        });
      },
      onError: () => {
        setLoading(false);
        setModalEditVinculo({
          visible: false,
        });
        message.error('Erro ao editar vínculo.');
      },
    });
  }

  async function handleRemoverVinculo(id_usuario_vinculo: string) {
    setLoading(true);
    return fetchApi({
      url: `/panel/users/tj/bind/${id_usuario_vinculo}`,
      method: 'delete',
      onSuccess: (data: any) => {
        setLoading(false);
        message.success('Vinculo removido.');
        fetchUnidades();
        fetchUsuarioIncentivadorTj();
        fetchVinculosUnidadesIncentivadores();
      },
      onError: () => {
        setLoading(false);
        message.error('Erro ao remover vínculo.');
      },
    });
  }

  function setTypeStatusVinculo(status: string, removido: boolean) {
    if (removido) {
      return 'error';
    }

    switch (status) {
      case StatusVinculoUnidadeIncentivador.VALIDO:
        return 'success';
      case StatusVinculoUnidadeIncentivador.VENCIDO:
        return 'warning';
      default:
        return 'error';
    }
  }

  const columns = [
    {
      title: 'Unidade',
      dataIndex: 'usuario_para_nome',
      key: 'usuario_para_nome',
    },
    {
      title: 'Usuario',
      dataIndex: 'usuario_de_nome',
      key: 'usuario_de_nome',
    },
    {
      title: 'Validade',
      dataIndex: 'validade',
      key: 'validade',
      render: (item: string) =>
        item ? format(parseISO(item), 'dd/MM/yyyy HH:mm:ss') : null,
    },
    {
      title: 'Criado em',
      dataIndex: 'criado_em',
      key: 'criado_em',
      render: (item: string) => format(parseISO(item), 'dd/MM/yyyy HH:mm:ss'),
    },
    {
      title: 'Editar',
      dataIndex: 'id_usuario_vinculo',
      key: 'id_usuario_vinculo',
      render: (item: string) => (
        <Button type="primary" onClick={() => handleEditarVinculo(item)}>
          <AiFillEdit />
        </Button>
      ),
    },
    {
      title: 'Remover',
      dataIndex: 'id_usuario_vinculo',
      key: 'id_usuario_vinculo',
      render: (item: string) => (
        <Button
          type="primary"
          danger
          onClick={() => handleRemoverVinculo(item)}
        >
          <AiFillDelete />
        </Button>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status_validade',
      key: 'status_validade',
      render: (item: string, data: any) => (
        <Tag color={setTypeStatusVinculo(item, !!data.removido_em)}>
          {!!data.removido_em ? 'REMOVIDO' : item}
        </Tag>
      ),
    },
  ];

  const filterOption = (
    input: string,
    option?: { label: string; value: string },
  ) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <>
      <PageHeader
        title="Víncular Unidade Usuário"
        breadcrumb={['Vínculos', 'Criação']}
      />

      <CardCollapse loading={loading} title="Vincular Unidade Usuário">
        <Form
          layout="vertical"
          form={vinculoUnidadeIncentivador}
          onFinish={handleCreateVinculo}
        >
          <Row gutter={16}>
            <Col md={8} xs={24}>
              <Form.Item
                name="id_unidade"
                label="Unidade"
                rules={[{ required: true, message: default_message }]}
              >
                <Select
                  options={unidades}
                  showSearch
                  filterOption={filterOption}
                  placeholder="Selecione uma unidade"
                />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item
                name="id_usuario"
                label="Usuário"
                rules={[{ required: true, message: default_message }]}
              >
                <Select
                  options={incentivadores}
                  showSearch
                  filterOption={filterOption}
                  placeholder="Selecione um usuário"
                />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item
                name="validade"
                label="Validade"
                rules={[{ required: true, message: default_message }]}
              >
                <DatePicker
                  placeholder=""
                  format="DD/MM/YYYY"
                  locale={locale}
                />
              </Form.Item>
            </Col>
          </Row>
          <br />
          <Row justify="end">
            <Button type="primary" htmlType="submit">
              Vincular
            </Button>
          </Row>
        </Form>
      </CardCollapse>
      <CardCollapse title="Filtros">
        <Form
          layout="vertical"
          onFinish={data => handleSetFiltroValues(data)}
          form={formFilters}
        >
          <Row gutter={16}>
            <Col md={8}>
              <Form.Item name="establishment_name" label="Unidade">
                <Input />
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item name="user_name" label="Usuario">
                <Input />
              </Form.Item>
            </Col>
            {/* <Col md={8}>
              <Form.Item name="status" label="Status">
                <Select>
                  <Select.Option
                    value={StatusVinculoUnidadeIncentivador.VALIDO}
                  >
                    <Tag color="success">
                      {StatusVinculoUnidadeIncentivador.VALIDO}
                    </Tag>
                  </Select.Option>
                  <Select.Option
                    value={StatusVinculoUnidadeIncentivador.VENCIDO}
                  >
                    <Tag color="warning">
                      {StatusVinculoUnidadeIncentivador.VENCIDO}
                    </Tag>
                  </Select.Option>
                  <Select.Option
                    value={StatusVinculoUnidadeIncentivador.REMOVIDO}
                  >
                    <Tag color="error">
                      {StatusVinculoUnidadeIncentivador.REMOVIDO}
                    </Tag>
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col> */}
          </Row>

          <br />

          <Row justify="end">
            <Space>
              <Button onClick={() => handleResetForm()}>Limpar</Button>
              <Button type="primary" htmlType="submit">
                Filtrar
              </Button>
            </Space>
          </Row>
        </Form>
      </CardCollapse>
      <Table
        columns={columns}
        dataSource={vinculos.data}
        loading={loading}
        pagination={{
          total: vinculos.total,
          current: vinculos.pagina,
          pageSize: vinculos.porPagina,
          onChange: (page, page_size) =>
            handleSetFiltroValues({ page, page_size }),
        }}
      />
      <Modal
        title="Editar Vínculo"
        visible={modalEditVinculo.visible}
        onCancel={() =>
          setModalEditVinculo({
            visible: false,
          })
        }
        onOk={() => formEditVinculo.submit()}
        okText="Salvar"
        cancelText="Cancelar"
      >
        <Form
          layout="vertical"
          onFinish={data => handleUpdateVinculoUnidadeIncentivador(data)}
          form={formEditVinculo}
        >
          <Row gutter={16}>
            <Col md={12}>
              <Form.Item
                name="id_usuario_de"
                label="Usuário"
                initialValue={modalEditVinculo.data?.usuario_de_nome}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                name="id_usuario_para"
                label="Unidade"
                initialValue={modalEditVinculo.data?.usuario_para_nome}
              >
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col md={24}>
              <Form.Item name="validate" label="Validade">
                <DatePicker
                  placeholder=""
                  format="DD/MM/YYYY"
                  locale={locale}
                  defaultValue={moment(
                    parseISO(modalEditVinculo.data?.validade || ''),
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default Vinculos;
