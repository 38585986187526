import styled, { css } from 'styled-components';

import cores from '../../styles/colors';
import { header_size, menu_size } from '../../styles/global';
import devices from '../../styles/devices';

export const Container = styled.header`
  height: ${header_size};
  width: calc(100% - ${menu_size});
  background-color: ${cores.background_header};
  display: flex;
  float: right;
  padding: 0 20px;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  left: ${menu_size};
  z-index: 1;

  @media only screen and (max-width: ${devices.tablet}) {
    justify-content: space-between;
    width: 100%;
    left: 0;
  }
`;

export const Search = styled.div`
  background: transparent;
  border: 1px solid #bcbcbc;
  border-radius: 5px;
  height: 35px;
  padding-right: 10px;

  display: flex;
  align-items: center;

  input {
    border: none;
    background: transparent;
    padding-left: 10px;
  }

  svg {
    font-size: 22px;
    color: #bcbcbc;
    cursor: pointer;
  }
`;

export const Profile = styled.div`
  display: flex;
  align-items: center;

  svg {
    font-size: 20px;
  }

  .info {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 15px;

    span {
      font-size: 15px;
      font-weight: bold;
      text-align: right;

      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 250px;
    }
  }

  .profile-pic {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background: ${cores.background_dark};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: ${cores.primary};
    font-size: 20px;
    font-weight: bold;

    /* img {
      height: 44px;
      width: 44px;
      border-radius: 50%;
    } */
  }
`;

export const StyledButton = styled.button`
  display: none;
  border: none;
  background: none;

  svg {
    font-size: 25px;
  }

  @media only screen and (max-width: ${devices.tablet}) {
    display: block;
  }
`;

export const StyledMenu = styled.div`
  display: block;

  @media only screen and (max-width: ${devices.tablet}) {
    display: none;
  }
`;

export const BackgroundMenu = styled.div<{ opened: boolean }>`
  display: none;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;

  ${props =>
    props.opened &&
    css`
      display: block;
    `}
`;
