import styled from 'styled-components';

import cores from '../../../../styles/colors';

export const Container = styled.div`
  margin: 20px 0;
  background: ${cores.background_header};
  border-radius: 5px;
  box-shadow: 0px 0px 10px ${cores.shadow};
  transition: 0.3s;

  &:hover {
    box-shadow: 0px 0px 2px 2px #ccc;
  }
`;

export const Header = styled.div`
  display: flex;
  cursor: pointer;
`;
export const HeaderLeft = styled.div`
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 12px;
  margin-bottom: 12px;
  width: calc(100% - 35px);
`;
export const HeaderRigth = styled.div`
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid ${cores.shadow};
`;

export const Name = styled.div`
  font-weight: bold;

  small {
    margin-left: 10px;
    font-weight: normal;
  }
`;

export const Body = styled.div`
  border-top: 1px solid ${cores.shadow};
  padding: 20px 20px 20px 20px;
`;
