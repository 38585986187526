import { Reducer } from 'redux';

import EnumEstado from '../../../types/enum/Estado';
import { EnumAuthActions } from '../auth/actions';

export interface IPerfil {
  id_perfil: number;
  nome: string;
}

export interface IResponsabilidade {
  id_responsabilidade: number;
  nome: string;
}

export type ITipoLiquidacao = {
  id_tipo_liquidacao: number;
  nome: string;
};

export type IDocumentoConta = {
  documento?: string;
  verificado?: boolean;
  verificado_em?: Date;
};

export interface IUserState {
  id_usuario: string;
  /** Id do estabelecimento do vinculo */
  id_estabelecimento?: string;
  id_estabelecimento_whitelabel?: string;
  cod_usuario?: string;
  nome: string;
  email: string;
  perfis: IPerfil[];
  responsabilidades: IResponsabilidade[];
  id_estado?: EnumEstado;
  tipo_liquidacao: ITipoLiquidacao[];
  documento_conta?: IDocumentoConta;
}

const initial_state: IUserState = {
  id_usuario: '',
  id_estabelecimento: undefined,
  cod_usuario: undefined,
  nome: '',
  email: '',
  id_estado: undefined,
  documento_conta: undefined,
  perfis: [],
  responsabilidades: [],
  tipo_liquidacao: [],
};

const user: Reducer<IUserState> = (state = initial_state, action) => {
  switch (action.type) {
    case EnumAuthActions.SIGN_IN:
      const { payload } = action;

      return {
        nome: payload.usuario.nome,
        email: payload.usuario.email,
        id_usuario: payload.usuario.id_usuario,
        id_estabelecimento: payload.usuario.id_estabelecimento,
        id_estabelecimento_whitelabel:
          payload.usuario.id_estabelecimento_whitelabel,
        cod_usuario: payload.usuario.cod_usuario,
        id_estado: payload.usuario.id_estado,
        perfis: payload.perfis,
        responsabilidades: payload.responsabilidades,
        tipo_liquidacao: payload.tipo_liquidacao || [],
        documento_conta: payload.documento_conta,
      };

    case EnumAuthActions.SIGN_OUT:
      return initial_state;

    default:
      return state;
  }
};

export default user;
