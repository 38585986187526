enum EnumStatusVinculo {
  SOLICITACAO_VINCULO_PENDENTE = 1,
  ENVIO_CONTRATO_PENDENTE = 2,
  ASSINATURA_CONTRATO_PENDENTE = 3,
  CONTRATO_ASSINADO = 4,
  CONTRATO_EXPIRADO = 5,
  VINCULO_APROVADA = 6,
  VINCULO_REPROVADA = 7,
  VINCULO_REMOVIDA = 8,
}

export default EnumStatusVinculo;
