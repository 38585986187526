import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Col, Row } from 'antd';

import PageHeader from '../../../components/PageHeader';
import { fetchApi } from '../../../services/api';
import CheckoutInfoPayments from './Payments';
// import CarrinhoInfoTransacao from './Transacao';
import CheckoutInfoTimeline from './Timeline';
import CarrinhoInfoSumario from './Summary';
import CheckoutInfoClient from './Client';
import CheckoutCharges from './Charges';
import CheckoutInfoGeral from './Geral';

import { GetBackofficeCheckoutResponse } from './types';
import Commission from './Commission';

const CarrinhoInfo: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const [tick, setTick] = useState(Date.now());

  useEffect(() => {
    const interval = setInterval(() => {
      setTick(Date.now());
    }, 2500);
    return () => clearInterval(interval);
  }, []);

  const {
    isFetching: loading,
    data: checkout,
    refetch,
  } = useQuery({
    queryKey: ['checkout', id],
    queryFn: fetchCarrinhoInfo,
    cacheTime: 10000000,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
    refetchOnReconnect: false,
  });

  function fetchCarrinhoInfo() {
    return fetchApi<GetBackofficeCheckoutResponse>({
      url: `/backoffice/checkout/${id}`,
      method: 'get',
      onSuccess: data => {
        return data;
      },
    });
  }

  return (
    <>
      <PageHeader
        title="Informações do Carrinho"
        breadcrumb={['Carrinho', 'V2', 'Informação']}
      />

      <CheckoutInfoGeral
        data={checkout}
        loading={loading}
        tick={tick}
        fetchCheckoutInfo={refetch}
      />

      <Row gutter={16}>
        <Col md={12} xs={24}>
          <CheckoutInfoTimeline
            timeline={checkout?.checkout?.timeline || []}
            loading={loading}
          />
        </Col>
        <Col md={12} xs={24}>
          <CarrinhoInfoSumario checkout={checkout} loading={loading} />
          <CheckoutInfoClient loading={loading} checkout={checkout} />
        </Col>
      </Row>

      <CheckoutInfoPayments
        loading={loading}
        checkout={checkout}
        tick={tick}
        fetchCheckout={refetch}
      />

      <CheckoutCharges loading={loading} checkout={checkout} tick={tick} />

      <Commission
        loading={loading}
        commission={checkout?.commissions}
        fetchCheckout={refetch}
      />
    </>
  );
};

export default CarrinhoInfo;
