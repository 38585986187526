import api from "../../../services/api";
import { GetPartnerResponse, GetPartnersServiceProps, Partner, UpdatePartnerServiceProps } from "./dto/partner.dto";

export async function createPartnerService(data: Partner) {
  const response = await api.post('/backoffice/partner', data, {
    headers: {
      'api-version': 1
    }
  })

  return response.data
}

export async function updatePartnerService({data, id_partner}: UpdatePartnerServiceProps) {
  const response = await api.put(`/backoffice/partner/${id_partner}`, data, {
    headers: {
      'api-version': 1
    }
  })

  return response.data
}

export async function getPartnerService(id_partner: string) {
  const response = await api.get<GetPartnerResponse>(`/backoffice/partner/${id_partner}`,  {
    headers: {
      'api-version': 1
    }
  })

  return response.data
}

export async function getPartnersService({ page, page_size }: GetPartnersServiceProps) {
  const response = await api.get('/backoffice/partner',  {
    params: {
      page,
      page_size
    },
    headers: {
      'api-version': 1
    }
  })

  return response.data
}

export async function deletePartnerService(id_partner: string) {
  const response = await api.delete(`/backoffice/partner/${id_partner}`,  {
    headers: {
      'api-version': 1
    }
  })

  return response.data
}