import React, { useState } from 'react';
import { Button, Form, Input, message, Modal } from 'antd';
import { useDispatch } from 'react-redux';
import { cleanMask, cnpjMask, cpfMask } from 'masks-br';

import { fetchApi } from '../../services/api';
import {
  signIn,
  updateResponsabilidade,
} from '../../store/modules/auth/actions';
import authKey from '../../config/authKey';
import { chooseTypeLogin } from '../../utils/Login';

const Login: React.FC = () => {
  const [form] = Form.useForm();
  const [formModal] = Form.useForm();
  const tipo = chooseTypeLogin(window.location.pathname);

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [otaRequired, setOtaRequired] = useState(false);

  async function handleLogin(info: any) {
    setLoading(true);

    const data = await fetchApi({
      url: '/login',
      method: 'post',
      data: {
        ...authKey,
        senha: info.password,
        documento: cleanMask(info.username),
        tipo,
        otp: info.otp,
      },
      messages: {
        loading: 'Autenticando usuário, aguarde...',
        success: 'Usuário autenticado com sucesso!',
        error: 'Aconteceu um problema aqui, tente novamente!',
      },
      onError: error => {
        // @ts-ignore
        if (!otaRequired) setOtaRequired(error?.code === 'OTP_REQUIRED');
      },
    });

    setLoading(false);

    if (!data) return;

    if (!data.responsabilidades.length && data.logged_as === 'parceiro') {
      message.error(
        'Você ainda não possui nenhuma responsabilidade associada!',
      );

      return;
    }

    if (data.responsabilidades.length === 1 && data.logged_as === 'parceiro') {
      const responsabilidade = data.responsabilidades[0].id_responsabilidade;

      dispatch(updateResponsabilidade(responsabilidade));
    }

    dispatch(
      signIn({
        authenticated: data.authenticated,
        perfis: data.perfis,
        token: data.token,
        usuario: data.usuario,
        responsabilidades: data.responsabilidades,
        tipo: data.logged_as,
        permissoes: data.permissoes,
        tipo_liquidacao: data.tipo_liquidacao,
        documento_conta: data.documento_conta,
      }),
    );
  }

  function handleUpdateField(doc: string, modali = false) {
    let username = cleanMask(doc);

    if (username.length <= 11) {
      username = cpfMask(doc);
    } else {
      username = cnpjMask(doc);
    }

    if (modali) {
      formModal.setFieldsValue({ username });
      return;
    }

    form.setFieldsValue({ username });
  }

  function handleSubmitForgotPassword({ documento }: any) {
    return fetchApi({
      url: `/account-recovery`,
      method: 'post',
      messages: {
        error:
          'Não conseguimos concluir o envio do email, entre em contato conosco!',
        loading: 'Enviando email de recuperação de senha, aguarde!',
      },
      data: {
        document: cleanMask(documento),
      },
      onSuccess: () => {
        setModal(false);
        formModal.resetFields();
        Modal.success({
          title: 'Sucesso',
          content: 'Enviamos em seu email um link para alterar sua senha!',
          centered: true,
        });
      },
    });
  }

  return (
    <>
      <h1>Login</h1>

      <Form layout="vertical" onFinish={handleLogin} form={form}>
        <Form.Item
          label="CPF ou CNPJ"
          name="username"
          rules={[{ required: true, message: 'Favor inserir seu documento!' }]}
        >
          <Input onChange={val => handleUpdateField(val.target.value)} />
        </Form.Item>

        <Form.Item
          label="Senha"
          name="password"
          rules={[{ required: true, message: 'Favor inserir sua senha!' }]}
        >
          <Input.Password />
        </Form.Item>

        {otaRequired ? (
          <Form.Item
            label="Token"
            name="otp"
            rules={[
              { required: true, message: 'Insira o token de autenticação' },
            ]}
          >
            <Input.Password />
          </Form.Item>
        ) : null}

        <span onClick={() => setModal(true)} style={{ cursor: 'pointer' }}>
          Esqueceu sua senha?
        </span>
        <br />
        <br />

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Entrar
          </Button>
        </Form.Item>
      </Form>

      <Modal
        title="Esqueci minha senha"
        centered
        visible={modal}
        footer={false}
        onCancel={() => setModal(false)}
      >
        <Form
          form={formModal}
          layout="vertical"
          onFinish={data => handleSubmitForgotPassword(data)}
        >
          <Form.Item label="CPF ou CNPJ" name="documento">
            <Input
              onChange={val => handleUpdateField(val.target.value, true)}
            />
          </Form.Item>

          <br />

          <Button type="primary" htmlType="submit">
            Enviar Email
          </Button>
        </Form>
      </Modal>
    </>
  );
};

export default Login;
