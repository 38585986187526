import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Button, Col, DatePicker, Form, Row, Select } from 'antd';
import ApexChart from 'react-apexcharts';
import { dinheiroMask } from 'masks-br';
import { useDispatch, useSelector } from 'react-redux';
import { GoEyeClosed, GoEye } from 'react-icons/go';
import { MdOpenInNew } from 'react-icons/md';

import CsvDownload from 'react-json-to-csv';
import moment from 'moment';
import 'moment/locale/pt-br';
import locale from 'antd/es/date-picker/locale/pt_BR';
import CardCollapse from '../../../components/CardCollapse';
import PageHeader from '../../../components/PageHeader';
import Card from '../../../components/Card';
import { fetchApi } from '../../../services/api';
import { IState } from '../../../store';
import { IPerfil, IUserState } from '../../../store/modules/user/reducer';
import EnumResponsabilidade from '../../../types/enum/Responsabilidade';
import EnumPerfil from '../../../types/enum/Perfis';
import { updateShowCurrencyValues } from '../../../store/modules/auth/actions';
import cores from '../../../styles/colors';

import Option from '../../../types/Option';

import { time_options_partner, time_default_parceiro } from '..';
import { LinkButton } from './styled';
import { useEstablishmentsByUserAndResponsabilidade } from '../../Estabelecimento/hooks/useEstablishments';

const date_tooltip = 'dd/MM/yyyy';

export interface DashboardData {
  commission: number;
  commission_historical: CommissionHistorical[];
  payment_quantity: number;
  checkout_quantity: number;
}

interface CommissionHistorical {
  date: string;
  amount: number;
}

const select_type_filter: Option[] = [
  {
    label: 'Fixo',
    value: 1,
  },
  {
    label: 'Relativo',
    value: 2,
  },
];

const DashboardParceiro: React.FC = () => {
  const [form] = Form.useForm();
  const tipo_responsabilidade = useSelector<
    IState,
    EnumResponsabilidade | undefined
  >(state => state.auth.responsabilidade);
  const tipo_perfis = useSelector<IState, IPerfil[]>(
    state => state.user.perfis,
  );
  const visualizarValores = useSelector<IState, boolean>(
    state => state.auth.show_currency_values,
  );
  const usuario = useSelector<IState, IUserState>(state => state.user);
  const dispatch = useDispatch();

  const [dashboardData, setDashboardData] = useState<DashboardData>(
    {} as DashboardData,
  );

  const [filtros, setFiltros] = useState({ time: time_default_parceiro });
  const [rangeFiltros, setRangeFiltros] = useState({
    dates: [moment(), moment()],
  });
  const [selectFilter, setSelectFilter] = useState<any>({ time: 'today' });
  const [typeFixoOrRelativo, setTypeFixoOrRelativo] = useState(true);

  const id_estabelecimento = useSelector<IState, string>(
    state => state.user.id_estabelecimento as string,
  );

  const id_usuario = useSelector<IState, string>(
    state => state.user.id_usuario as string,
  );

  const id_estabelecimento_whitelabel = useSelector<IState, string>(
    state => state.user.id_estabelecimento_whitelabel as string,
  );

  const { data: establishments } = useEstablishmentsByUserAndResponsabilidade(
    1,
    25,
    id_usuario,
    EnumResponsabilidade.REPRESENTANTE,
    tipo_responsabilidade === EnumResponsabilidade.REPRESENTANTE,
  );

  const chart = useMemo(() => {
    return {
      categories:
        dashboardData?.commission_historical?.map(item => item.date) || [],
      data: [
        {
          name: 'Comissão',
          data: (dashboardData?.commission_historical || []).map(
            item => item.amount,
          ),
        },
      ],
    };
  }, [dashboardData]);

  const button_pagamento_online = useMemo(() => {
    const url = `${process.env.REACT_APP_URL_PORTAL}/payment_online/${
      id_estabelecimento ||
      id_estabelecimento_whitelabel ||
      usuario.id_usuario ||
      process.env.REACT_APP_PARCELAMOS_TUDO_ESTABLISHMENT
    }`;

    return (
      <a href={url} target="_blank" rel="noreferrer">
        <span>Pagamento Online</span>
        <MdOpenInNew />
      </a>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tipo_responsabilidade, usuario]);

  useEffect(() => {
    fetchDashboardData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectFilter]);

  const filtro_tipo = useMemo(() => {
    return (
      filtros.time === 'today' ||
      filtros.time === 'yesterday' ||
      filtros.time === 'today_last_week'
    );
  }, [filtros.time]);

  const fetchDashboardData = useCallback(() => {
    return fetchApi({
      url: '/panel/dashboard',
      method: 'get',
      query_params: { ...selectFilter, responsibility: tipo_responsabilidade },
      onSuccess: (data: DashboardData) => {
        if ('commission_historical' in data) {
          const commission_historical = data.commission_historical?.map(
            (item: any) => {
              const date = filtro_tipo
                ? new Date(item.date).getHours()
                : item.date.split('T')[0];

              return {
                ...item,
                date: date,
              };
            },
          );

          const update_data = {
            ...data,
            commission_historical,
          };

          setDashboardData(update_data);
        }
      },
    });
  }, [filtro_tipo, selectFilter, tipo_responsabilidade]);

  async function setTypeFilter(value: string) {
    if (parseInt(value, 10) === 1) setTypeFixoOrRelativo(true);
    if (parseInt(value, 10) === 2) setTypeFixoOrRelativo(false);
  }

  function handleUpdateFiltro(data: any) {
    if (data.time) {
      setFiltros({ ...filtros, time: data.time });
      setSelectFilter({ ...filtros, time: data.time });
    }
    if (data.dates) {
      setRangeFiltros({ dates: [data.dates[0], data.dates[1]] });
      setSelectFilter({
        dates: [data.dates[0].toISOString(), data.dates[1].toISOString()].join(
          ',',
        ),
      });
    }
    if (data.id_establishment) {
      setSelectFilter({
        ...selectFilter,
        id_establishment: data.id_establishment,
      });
    }
  }

  function handleUpdateShowValues() {
    dispatch(updateShowCurrencyValues(!visualizarValores));
  }

  return (
    <>
      <PageHeader title="Olá, seja bem vindo!" />

      <LinkButton>{button_pagamento_online}</LinkButton>

      <CardCollapse header={false}>
        <Form
          layout="vertical"
          onValuesChange={data => handleUpdateFiltro(data)}
          form={form}
          initialValues={{
            ...filtros,
            tipo: 'Fixo',
            dates: [rangeFiltros.dates[0], rangeFiltros.dates[1]],
          }}
        >
          <Row gutter={16} justify="space-between">
            <Col>
              <Button onClick={() => handleUpdateShowValues()}>
                {visualizarValores ? <GoEye /> : <GoEyeClosed />}
              </Button>
            </Col>
            <Col>
              <Row gutter={8}>
                {dashboardData?.commission_historical?.length &&
                  visualizarValores && (
                    <CsvDownload
                      data={dashboardData?.commission_historical}
                      filename="comissao-parcelamostudo.csv"
                      style={{ border: 'None', marginTop: '5px' }}
                    >
                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{ background: 'green', borderColor: 'green' }}
                      >
                        Exportar Dados
                      </Button>
                    </CsvDownload>
                  )}
                {tipo_responsabilidade ===
                  EnumResponsabilidade.REPRESENTANTE && (
                  <Col>
                    <Form.Item name="id_establishment">
                      <Select placeholder="Selecione um estabelecimento">
                        {establishments?.data?.map(estabelecimento => (
                          <Select.Option value={estabelecimento.id_usuario}>
                            {estabelecimento.nome}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                )}
                <Col>
                  <Form.Item name="tipo">
                    <Select
                      style={{
                        width: '100px',
                      }}
                      options={select_type_filter}
                      onChange={value => setTypeFilter(value)}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  {typeFixoOrRelativo ? (
                    <Form.Item name="time">
                      <Select
                        style={{ width: '200px' }}
                        value={selectFilter.time}
                      >
                        {time_options_partner}
                      </Select>
                    </Form.Item>
                  ) : (
                    <Form.Item name="dates">
                      <DatePicker.RangePicker
                        locale={locale}
                        value={selectFilter.dates}
                      />
                    </Form.Item>
                  )}
                </Col>
                <Col>
                  <Button
                    type="primary"
                    style={{
                      marginTop: 4,
                      background: 'gray',
                      borderColor: 'gray',
                    }}
                    onClick={() => form.resetFields()}
                  >
                    Limpar
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>

        <br />
        <h1>Histórico de Receitas</h1>

        {tipo_responsabilidade !== EnumResponsabilidade.INCENTIVADOR && (
          <ApexChart
            options={{
              chart: {
                height: 350,
                width: '100%',
                type: 'area',
                zoom: {
                  enabled: false,
                },
                toolbar: {
                  show: false,
                },
                animations: {
                  enabled: true,
                  easing: 'linear',
                },
              },
              dataLabels: {
                enabled: false,
              },
              colors: [cores.primary],
              stroke: {
                curve: 'smooth',
              },
              xaxis: {
                type: 'datetime',
                categories: chart.categories,
              },
              yaxis: {
                show: visualizarValores,
                labels: {
                  formatter: (val: number) => dinheiroMask(val),
                },
              },
              tooltip: {
                enabled: visualizarValores,
                x: { format: date_tooltip },
              },
            }}
            series={chart.data}
            type="area"
            height={350}
          />
        )}

        <Row gutter={16} justify="center">
          <Col md={12} xs={24}>
            <Card
              title="Qtde Boletos"
              value={dashboardData?.payment_quantity}
              showValue={visualizarValores}
            />
          </Col>
          <Col md={12} xs={24}>
            <Card
              title="Qtde Vendas"
              value={dashboardData?.checkout_quantity}
              showValue={visualizarValores}
            />
          </Col>
        </Row>
      </CardCollapse>
    </>
  );
};

export default DashboardParceiro;
