import { format, parseISO } from 'date-fns';
import { dinheiroMask } from 'masks-br';
import React, { useMemo } from 'react';
import Barcode from 'react-barcode';

import { Container, Header, Body } from './styles';

type InformacoesDebito = {
  descricao: string;
  data_vencimento: string;
  valor: number;
  codigo_barra: string;
  selected: boolean;
  className?: string;
  onClick?: () => any;
};

const CardDebito: React.FC<InformacoesDebito> = (data: InformacoesDebito) => {
  const data_vencimento = useMemo(() => {
    if (!data.data_vencimento) return '';

    return format(parseISO(data.data_vencimento), 'dd/MM/yyyy');
  }, [data.data_vencimento]);

  return (
    <Container
      selected={data.selected}
      className={data.selected ? 'SelectedCard' : 'NotSelectedCard'}
      onClick={() => data.onClick && data.onClick()}
    >
      <Header>
        <div>
          <h3>{data.descricao}</h3>
          <small>{data_vencimento}</small>
        </div>
        <div>
          <p>{dinheiroMask(data.valor)}</p>
        </div>
      </Header>
      <Body>
        <Barcode
          value={data.codigo_barra}
          height={80}
          fontSize={18}
          format="ITF"
        />
      </Body>
    </Container>
  );
};

export default CardDebito;
