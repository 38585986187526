import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Tree,
  Typography,
  Radio,
  message,
} from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import CardCollapse from '../../../components/CardCollapse';
import PageHeader from '../../../components/PageHeader';
import { fetchApi } from '../../../services/api';
import { EnumPerfilTipo } from '../../../hooks/usePermissions';

const { Text } = Typography;

const message_default = 'Esse campo é obrigatório';

type PermissoesPerfil = {
  id_permissao_perfil: number;
  permissao_perfil_pai?: number;
  tipo: Tipo;
  nome: string;
  descricao?: string;
};

type TreeData = {
  title: any;
  key: string;
  disabled?: boolean;
  children: TreeData[];
};

type Tipo = 'admin' | 'parceiro' | 'admin_estabelecimento';

const PerfilCadastro: React.FC = () => {
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();
  const [form] = Form.useForm();

  const [permissoes, setPermissoes] = useState<PermissoesPerfil[]>([]);
  const [permissaoSelected, setPermissaoSelected] = useState<any[]>([]);
  const [tipo, setTipo] = useState<Tipo>('admin');
  const [loading, setLoading] = useState(true);

  const treeData: TreeData[] = useMemo(() => {
    const tipoFilter =
      tipo === EnumPerfilTipo.ADMIN_ESTABELECIMENTO ? 'admin' : tipo;

    const parents_items: TreeData[] = permissoes
      .filter(item => !item.permissao_perfil_pai && tipoFilter === item.tipo)
      .map(item => ({
        title: item.nome,
        key: String(item.id_permissao_perfil),
        children: [],
      }));

    permissoes.forEach(item => {
      if (!item.permissao_perfil_pai) {
        return;
      }

      const find_parent = parents_items.find(
        parent => parent.key === String(item.permissao_perfil_pai),
      );

      if (find_parent) {
        find_parent.children.push({
          key: String(item.id_permissao_perfil),
          title: (
            <>
              {item.nome}
              <br />
              <Text type="secondary">{item.descricao}</Text>
            </>
          ),
          children: [],
        });
      }
    });

    return parents_items;
  }, [permissoes, tipo]);

  useEffect(() => {
    const to_fetch = [fetchPermissoesPerfil()];

    if (id) to_fetch.push(fetchPerfilInfo());

    Promise.all(to_fetch).then(() => setLoading(false));
  }, []);

  async function fetchPermissoesPerfil() {
    return fetchApi({
      url: '/perfil/permissoes',
      method: 'get',
      onSuccess: data => {
        setPermissoes(data);
      },
    });
  }

  async function fetchPerfilInfo() {
    return fetchApi({
      url: `/perfil/${id}`,
      method: 'get',
      onSuccess: data => {
        form.setFieldsValue(data.perfil);
        setPermissaoSelected(
          data.permissoes.map((item: number) => String(item)),
        );
        setTipo(data.perfil.tipo);
      },
    });
  }

  function selectItem(keys: any | any[]) {
    setPermissaoSelected(keys);
  }

  async function handleCreatePerfil(data: any) {
    if (tipo === 'admin' && !permissaoSelected.length) {
      return message.warn('É necessário selecionar pelo menos uma permissão');
    }

    return fetchApi({
      url: '/perfil',
      method: 'post',
      data: {
        ...data,
        permissoes: permissaoSelected,
        tipo,
      },
      messages: {
        loading: 'Salvando novo perfil, aguarde...',
        error: 'Erro ao criar novo perfil, tente novamente',
        success: 'Perfil salvo com sucesso!',
      },
      onSuccess: () => {
        history.push('/perfil');
      },
    });
  }

  async function handleUpdatePerfil(data: any) {
    if (tipo === 'admin' && !permissaoSelected.length) {
      return message.warn('É necessário selecionar pelo menos uma permissão');
    }

    return fetchApi({
      url: `/perfil/${id}`,
      method: 'put',
      data: {
        ...data,
        permissoes: permissaoSelected,
        tipo,
      },
      messages: {
        loading: 'Atualizando perfil, aguarde...',
        error: 'Erro ao atualizar perfil, tente novamente',
        success: 'Perfil atualizado com sucesso!',
      },
      onSuccess: () => {
        history.push('/perfil');
      },
    });
  }

  const validateName = (rule: any, value: string, callback: (error?: string) => void) => {
    const onlyLettersAndApostrophes = /^[a-zA-Z\u00C0-\u00FF']+(?:\s[a-zA-Z\u00C0-\u00FF']+)*$/;
    const startsWithSpace = /^\s/.test(value);
    const endsWithSpace = /\s$/.test(value);
    const consecutiveSpacesRegex = /\s{2,}/;

    if (value?.length < 4 && value?.length > 0) {
      callback('Obrigatório no minímo 4 caracteres!')
    } else if (startsWithSpace) {
      callback('O nome não pode iniciar com espaços!');
    } else if (endsWithSpace) {
      callback('O nome não pode terminar com espaços!');
    } else if (consecutiveSpacesRegex.test(value)) {
      callback('O campo não pode conter espaços consecutivos.');
    } else if (!onlyLettersAndApostrophes.test(value)) {
      callback('O nome não pode conter caracteres especiais!');
    } else {
        callback();
    }
};

const validateDescription = (rule: any, value: string, callback: (error?: string) => void) => {
  const startsWithSpace = /^\s/.test(value);
  const endsWithSpace = /\s$/.test(value);
  const consecutiveSpacesRegex = /\s{2,}/;

  if (value?.length < 4 && value?.length > 0) {
    callback('Obrigatório no minímo 4 caracteres!')
  } else if (startsWithSpace) {
    callback('A descrição não pode iniciar com espaços!');
  } else if (endsWithSpace) {
    callback('A descrição não pode terminar com espaços!');
  } else if (consecutiveSpacesRegex.test(value)) {
    callback('O campo não pode conter espaços consecutivos.');
  } else {
    callback();
  }
};

  return (
    <>
      <PageHeader
        title={!id ? 'Cadastro de Perfil' : 'Edição de Perfil'}
        breadcrumb={['Perfil', !id ? 'Cadastro' : 'Editar']}
      />

      <CardCollapse header={false} loading={loading}>
        <Form
          form={form}
          layout="vertical"
          onFinish={data =>
            !id ? handleCreatePerfil(data) : handleUpdatePerfil(data)
          }
          initialValues={{
            tipo: 'admin',
          }}
        >
          <Row gutter={16}>
            <Col md={12}>
              <Form.Item
                name="nome"
                label="Nome"
                rules={[{ required: true, message: message_default }, { validator: validateName }]}
              >
                <Input maxLength={255} />
              </Form.Item>
              <Form.Item
                name="descricao"
                label="Descrição"
                extra="Descreva detalhadamente a usabilidade desse perfil"
                rules={[{ required: true, message: message_default }, { validator: validateDescription }]}
              >
                <Input.TextArea rows={10} />
              </Form.Item>
            </Col>
            <Col md={12}>
              {id?.length ? (
                <Form.Item name="tipo" label="Tipo">
                  <Radio.Group
                    onChange={e => setTipo(e.target.value)}
                    value={tipo}
                    buttonStyle="solid"
                  >
                    <Radio.Button value="admin">Admin</Radio.Button>
                    <Radio.Button value="parceiro">Parceiro</Radio.Button>
                    <Radio.Button value="admin_estabelecimento">
                      Administrador de Estabelecimento
                    </Radio.Button>
                  </Radio.Group>
                </Form.Item>
              ) : null}
              <Form.Item name="permissoes" label="Permissões">
                <Tree
                  checkable
                  checkedKeys={permissaoSelected}
                  onCheck={data => selectItem(data)}
                  treeData={treeData}
                  selectable={false}
                  height={500}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row justify="end">
            <Button type="primary" htmlType="submit">
              {!id ? 'Criar' : 'Salvar'}
            </Button>
          </Row>
        </Form>
      </CardCollapse>
    </>
  );
};

export default PerfilCadastro;
