import React from 'react';
import PageHeader from '../../../components/PageHeader';
import CardCollapse from '../../../components/CardCollapse';
import { Col, List, Row, Typography } from 'antd';
import styled from 'styled-components';
import {
  terminalCheckStatus,
  terminalCheckType,
  terminalStatus,
} from '../services/types';
import UserName from '../../../components/User/Name';
import { useParams } from 'react-router';
import { useTerminalInfo } from '../hooks';
import { formatDate } from '../../../utils/date';

const { Paragraph } = Typography;

const TerminalInfo: React.FC = () => {
  const { id_terminal } = useParams<{ id_terminal: string }>();

  const { isLoading, data: terminal } = useTerminalInfo(id_terminal);

  if (!terminal) {
    return (
      <>
        <PageHeader
          title="Informações do terminal"
          breadcrumb={['Terminais', 'Informações']}
        />

        <CardCollapse header={false} loading={isLoading} />
        <Row gutter={16}>
          <Col md={12} xs={24}>
            <CardCollapse title="Timeline de Status" loading={isLoading} />
          </Col>
          <Col md={12} xs={24}>
            <CardCollapse title="Status do Terminal" loading={isLoading} />
            <CardCollapse
              title="Histórico de Estabelecimentos"
              loading={isLoading}
            />
          </Col>
        </Row>
      </>
    );
  }

  return (
    <>
      <PageHeader
        title="Informações do terminal"
        breadcrumb={['Terminais', 'Informações']}
      />

      <CardCollapse header={false} loading={isLoading}>
        <Header>
          <div className="row row-header">
            <div>
              {formatDate(new Date(terminal?.created_at || ''), 'datetimefull')}
            </div>
            <div>
              <Paragraph copyable>{terminal?.id_terminal}</Paragraph>
            </div>
          </div>
          <div className="row row-body">
            <div>
              <span className="value">{terminal?.type}</span>
              <span>
                {terminal?.id_status && terminalStatus[terminal.id_status]}
              </span>
            </div>
          </div>
        </Header>
        <Body>
          <span className="body-item">
            <h3>Fabricante</h3>
            <p>{terminal?.model.manufacturer}</p>
          </span>
          <span className="body-item">
            <h3>Modelo</h3>
            <p>
              <Paragraph copyable>{terminal?.model.name}</Paragraph>
            </p>
          </span>
          <span className="body-item">
            <h3>Adquirência</h3>
            <p>{terminal?.model.acquirer}</p>
          </span>

          <span className="body-item">
            <h3>Logic Number</h3>
            <p>
              <Paragraph copyable>{terminal?.logic_number}</Paragraph>
            </p>
          </span>
          <span className="body-item">
            <h3>Serial Number</h3>
            <p>
              <Paragraph copyable>{terminal?.serial_number}</Paragraph>
            </p>
          </span>
          <span className="body-item">
            <h3>Responsável</h3>
            <p>
              <UserName id_user={terminal.terminal_establishment[0]?.user_id} />
            </p>
          </span>
          <span className="body-item">
            <h3>Adicionado por</h3>
            <p>
              <UserName id_user={terminal.created_by} />
            </p>
          </span>
          <span className="body-item">
            <h3>Vinculado</h3>
            {terminal?.id_terminal_parent ? (
              <Paragraph copyable>{terminal.id_terminal_parent}</Paragraph>
            ) : (
              '-'
            )}
          </span>
        </Body>
      </CardCollapse>

      <Row gutter={16}>
        <Col md={12} xs={24}>
          <CardCollapse title="Timeline de Status" loading={isLoading}>
            <List
              itemLayout="horizontal"
              dataSource={terminal?.terminal_timeline}
              pagination={false}
              renderItem={item => (
                <List.Item>
                  <List.Item.Meta
                    title={terminalStatus[item.id_status]}
                    description={
                      <Row>
                        Atualizado por &nbsp;
                        <UserName id_user={item.created_by} />
                      </Row>
                    }
                  />

                  <span>
                    {formatDate(new Date(item.created_at), 'datetimefull')}
                  </span>
                </List.Item>
              )}
            />
          </CardCollapse>
        </Col>
        <Col md={12} xs={24}>
          <CardCollapse title="Status do Terminal" loading={isLoading}>
            <List
              itemLayout="horizontal"
              dataSource={terminal?.terminal_check}
              pagination={false}
              renderItem={item => (
                <List.Item>
                  <List.Item.Meta
                    title={terminalCheckStatus[item.status]}
                    description={terminalCheckType[item.type]}
                  />

                  <span>
                    {formatDate(new Date(item.updated_at), 'datetimefull')}
                  </span>
                </List.Item>
              )}
            />
          </CardCollapse>
          <CardCollapse
            title="Histórico de Estabelecimentos"
            loading={isLoading}
          >
            <List
              itemLayout="horizontal"
              dataSource={terminal?.terminal_establishment}
              pagination={false}
              renderItem={item => (
                <List.Item>
                  <List.Item.Meta
                    title={<UserName id_user={item.establishment_id} />}
                    description={
                      <Row>
                        Vinculado por &nbsp;
                        <UserName id_user={item.created_by} />
                      </Row>
                    }
                  />
                  <div>
                    {formatDate(new Date(item.created_at), 'datetimefull')}
                  </div>
                </List.Item>
              )}
            />
          </CardCollapse>
        </Col>
      </Row>
    </>
  );
};

export default TerminalInfo;

export const Header = styled.div`
  border-bottom: 1px solid #f1f1f1;

  .row {
    display: flex;
    justify-content: space-between;

    span.value {
      font-size: 40px;
      margin-right: 25px;
      font-weight: lighter;
    }

    div {
      .ant-typography {
        margin: 0;
      }
    }
  }

  .row-header {
    font-size: 13px;
    color: #717171;

    .ant-typography {
      color: #717171;
    }
  }

  .row-body {
    margin-top: 10px;

    div {
      display: flex;
      align-items: center;
    }
  }
`;

export const Body = styled.div`
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;

  #body-item-cart {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2px;
  }

  span.body-item {
    /* min-width: 200px; */
    max-width: 350px;
    padding: 0 25px;
    height: 50px;
    border-right: 1px solid #f1f1f1;

    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
      border-right: none;
    }

    h3 {
      margin-bottom: 0;
      font-size: 13px;
      color: #a7a4a4;
      text-transform: uppercase;
    }

    p {
      font-size: 15px;
      margin: 0;
    }
  }
`;
