import api from "../../../services/api";
import { GetTerminalEstablishmentLinksResponse } from "./types";

type GetTerminalEstablishmentLinksParams = {
  establishment_id: string;
  page: number;
  page_size: number;
}

export async function getTerminalsEstablishmentLink({page, page_size, establishment_id}: GetTerminalEstablishmentLinksParams) {
  const response = await api.get<GetTerminalEstablishmentLinksResponse>(`/backoffice/terminal/link/establishment/${establishment_id}/list`, {
    params: {
      page,
      page_size,
    }
  })

  return response.data
}

export async function deleteTerminalEstablishmentLink(id_terminal_establishment: string) {
  const response = await api.delete(`/backoffice/terminal/link/establishment/${id_terminal_establishment}`)

  return response.data
}

type PostTerminalEstablishmentLinkPayload = {
  id_terminal: string
  establishment_id: string
  user_id?: string
  month_rent_amount: number
  lost_amount: number
  tracking_code: string
}

export async function postTerminalEstablishmentLink(payload: PostTerminalEstablishmentLinkPayload) {
  const response = await api.post('/backoffice/terminal/link/establishment/', payload)

  return response.data
}