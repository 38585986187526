import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Row,
  Select,
  Table,
  Typography,
} from 'antd';
import { porcentagemMask } from 'masks-br';
import { MdClear } from 'react-icons/md';
import { Link, useLocation, useParams } from 'react-router-dom';
import { ColumnsType } from 'antd/lib/table';

import PageHeader from '../../../components/PageHeader';
import { fetchApi } from '../../../services/api';
import { ISelect } from '../../../types/Form';
import EnumResponsabilidade from '../../../types/enum/Responsabilidade';
import { useUtils } from '../../../hooks/useUtils';
import { findTagByTipo } from '../../../utils/findTagColorByTipo';
import { useApi } from '../../../hooks/useApi';

const default_message = 'Esse campo é obrigatório';

const { Paragraph, Text } = Typography;

interface IVinculo {
  ativo: any[];
  inativo: any[];
  pendente: any[];
}

const columns_historico: ColumnsType<any> = [
  {
    title: '#',
    dataIndex: 'id_usuario_vinculo',
    key: 'id_usuario_vinculo',
    render: (item: string) => (
      <Paragraph copyable={{ tooltips: false }}>{item}</Paragraph>
    ),
  },
  {
    title: 'Nome',
    dataIndex: 'usuario_de_nome',
    key: 'usuario_de_nome',
  },
  {
    title: 'Responsabilidade',
    dataIndex: 'responsabilidade',
    key: 'responsabilidade',
  },
  {
    title: 'Comissão',
    dataIndex: 'comissao',
    key: 'comissao',
    render: (item: number) => porcentagemMask(item),
  },
  {
    title: 'Status',
    dataIndex: 'status_nome',
    key: 'status_nome',
    align: 'center',
    render: (item: string, data: any) => findTagByTipo(item, data.status_tipo),
  },
];

const UsuarioVinculo: React.FC = (a: any) => {
  const [form] = Form.useForm();
  const { id } = useParams<{ id: string }>();
  const { getResponsabilidade } = useUtils();
  const { state } = useLocation<{ nome_usuario: string }>();

  const [vinculo, setVinculo] = useState<IVinculo>({} as IVinculo);
  const [modalVinculo, setModalVinculo] = useState(false);
  const [responsabilidades, setResponsabilidades] = useState<ISelect[]>([]);
  const [responsabilidadeSelected, setResponsabilidadeSelected] = useState<
    number | null
  >(null);

  const { fetchRequest, loading } = useApi();

  const pageTitle = state?.nome_usuario
    ? `Vínculos do Usuário - ${state?.nome_usuario}`
    : 'Vínculos do Usuário';

  const historico_vinculo = useMemo(() => {
    if (!vinculo.inativo || !vinculo.inativo.length) {
      return null;
    }

    return (
      <>
        <Divider orientation="left">Histórico de Vínculos</Divider>

        <Table
          columns={columns_historico}
          dataSource={vinculo.inativo}
          pagination={false}
          loading={loading}
          size="small"
        />
      </>
    );
  }, [vinculo.inativo, loading]);

  const responsabilidade_selecionada_check = useMemo(() => {
    return (
      responsabilidadeSelected !== EnumResponsabilidade.RESPONSAVEL &&
      responsabilidadeSelected !== EnumResponsabilidade.ESTABELECIMENTO
    );
  }, [responsabilidadeSelected]);

  useEffect(() => {
    fetchVinculos();
  }, []);

  function fetchVinculos() {
    return fetchRequest({
      url: `/usuario/${id}/vinculo/estabelecimento`,
      method: 'get',
      onSuccess: data => {
        setVinculo(data);
      },
    });
  }

  async function handleOpenModal() {
    if (responsabilidades.length) {
      setModalVinculo(true);
      return;
    }

    const responsabilidade_response = await getResponsabilidade();

    setResponsabilidades(
      responsabilidade_response.map(resp => ({
        label: resp.nome,
        value: resp.id_responsabilidade,
      })),
    );
    setModalVinculo(true);
  }

  function handleCloseModal() {
    setModalVinculo(false);
    form.resetFields();
  }

  function handleCreateVinculo(data: any) {
    return fetchApi({
      url: `/usuario/${id}/vinculo`,
      method: 'post',
      data,
      messages: {
        loading: 'Inserindo novo vínculo, aguarde...',
        error: 'Erro ao inserir novo vínculo, tente novamente!',
        success: 'Vínculo inserido com sucesso!',
      },
      onSuccess: () => {
        handleCloseModal();
        fetchVinculos();
      },
    });
  }

  function handleRemoveVinculo(id_usuario_vinculo: string) {
    return fetchApi({
      url: `/usuario/vinculo/${id_usuario_vinculo}`,
      method: 'delete',
      onSuccess: () => {
        fetchVinculos();
      },
    });
  }

  const columns_vigentes: ColumnsType<any> = [
    {
      title: '#',
      dataIndex: 'id_usuario_vinculo',
      key: 'id_usuario_vinculo',
      render: (item: string) =>
        item ? (
          <Paragraph copyable={{ tooltips: false }}>{item}</Paragraph>
        ) : null,
    },
    {
      title: 'Nome',
      dataIndex: 'usuario_de_nome',
      key: 'usuario_de_nome',
    },
    {
      title: 'Responsabilidade',
      dataIndex: 'responsabilidade',
      key: 'responsabilidade',
    },
    {
      title: 'Comissão',
      dataIndex: 'comissao',
      key: 'comissao',
      render: (item: number, data: any) =>
        data.id_responsabilidade === EnumResponsabilidade.RESPONSAVEL
          ? '-'
          : porcentagemMask(item),
    },
    {
      title: 'Comissão Final',
      dataIndex: 'comissao_final',
      key: 'comissao_final',
      render: (item: number, data: any) =>
        data.id_responsabilidade === EnumResponsabilidade.RESPONSAVEL
          ? '-'
          : porcentagemMask(item),
    },
    {
      title: 'Status',
      dataIndex: 'status_nome',
      key: 'status_nome',
      align: 'center',
      render: (item: string, data: any) =>
        item ? findTagByTipo(item, data.status_tipo) : null,
    },
    {
      title: 'Remover',
      render: (item: any) => (
        <Popconfirm
          placement="topRight"
          title="Tem certeza que deseja remover esse vínculo?"
          onConfirm={() => handleRemoveVinculo(item.id_usuario_vinculo)}
          okText="Sim"
          cancelText="Não"
          disabled={
            (item.id_responsabilidade === EnumResponsabilidade.INDICADOR &&
              !item.can_be_deleted) ||
            item.id_responsabilidade === EnumResponsabilidade.ESTABELECIMENTO
          }
        >
          <Button
            type="primary"
            danger
            disabled={
              (item.id_responsabilidade === EnumResponsabilidade.INDICADOR &&
                !item.can_be_deleted) ||
              item.id_responsabilidade === EnumResponsabilidade.ESTABELECIMENTO
            }
          >
            <MdClear />
          </Button>
        </Popconfirm>
      ),
    },
  ];

  const pendente_vinculo = useMemo(() => {
    if (!vinculo.pendente || !vinculo.pendente.length) {
      return null;
    }

    return (
      <>
        <Divider orientation="left">Vínculos Pendentes</Divider>

        <Table
          columns={columns_vigentes}
          dataSource={vinculo.pendente}
          pagination={false}
          loading={loading}
          size="small"
        />
      </>
    );
  }, [vinculo.pendente, loading]);

  return (
    <>
      <PageHeader title={pageTitle} breadcrumb={['Usuário', 'Vínculos']} />

      <Divider orientation="left">Vínculos Ativos</Divider>

      <Row justify="end">
        <Button type="primary" onClick={handleOpenModal}>
          Criar Vínculo
        </Button>
      </Row>

      <br />

      <Table
        columns={columns_vigentes}
        dataSource={vinculo.ativo}
        pagination={false}
        size="small"
        loading={loading}
        summary={pageData => {
          const total = pageData
            .filter(
              item =>
                item.id_responsabilidade !== EnumResponsabilidade.RESPONSAVEL,
            )
            .reduce((prev, item) => prev + item.comissao_final, 0);

          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell index={1}>Total</Table.Summary.Cell>
                <Table.Summary.Cell index={2} />
                <Table.Summary.Cell index={3} />
                <Table.Summary.Cell index={4} />
                <Table.Summary.Cell index={5}>
                  {porcentagemMask(total)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={6} />
                <Table.Summary.Cell index={7} />
              </Table.Summary.Row>
            </>
          );
        }}
      />

      <br />

      <Text code>
        Fórmula de Comissionamento Padrão = Valor Original do Boleto x{' '}
        <Link to="/forma-pagamento">Taxa Conveniência</Link> x Comissão
      </Text>

      {pendente_vinculo}

      {historico_vinculo}

      <Modal
        title="Criar novo vínculo"
        visible={modalVinculo}
        onCancel={() => handleCloseModal()}
        footer={false}
        centered
      >
        <Form
          layout="vertical"
          onFinish={val => handleCreateVinculo(val)}
          initialValues={{ comissao: 0 }}
          form={form}
        >
          <Row gutter={16}>
            <Col md={24}>
              <Form.Item
                name="usuario"
                label="Id ou CPF ou CNPJ do Usuário"
                rules={[{ required: true, message: default_message }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                name="id_responsabilidade"
                label="Responsabilidade"
                rules={[{ required: true, message: default_message }]}
              >
                <Select
                  options={responsabilidades}
                  onChange={val => setResponsabilidadeSelected(val as number)}
                />
              </Form.Item>
            </Col>
            {responsabilidade_selecionada_check ? (
              <Col md={12}>
                <Form.Item
                  name="comissao"
                  label="Comissão"
                  rules={[{ required: true, message: default_message }]}
                >
                  <InputNumber
                    min={0}
                    max={100}
                    formatter={value => `${value}%`}
                  />
                </Form.Item>
              </Col>
            ) : null}
          </Row>

          <br />

          <Row justify="end">
            <Button type="primary" htmlType="submit">
              Criar
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default UsuarioVinculo;
