import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Col,
  Form,
  Input,
  List,
  Row,
  Select,
  Table,
  Tag,
  Timeline,
  Typography,
  Space,
  Divider,
  Tooltip,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { dinheiroMask } from 'masks-br';
import { Link, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { format, parseISO } from 'date-fns';

import CardCollapse from '../../../components/CardCollapse';
import PageHeader from '../../../components/PageHeader';
import { fetchApi } from '../../../services/api';
import findTagColorByTipo from '../../../utils/findTagColorByTipo';
import Pagina from '../../../types/Pagina';
import { useUtils } from '../../../hooks/useUtils';

const default_message = 'Esse campo é obrigatório';
const { Text } = Typography;

const POSCadastro: React.FC = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const edit = !!useRouteMatch('/pos/:id/editar');
  const { id } = useParams<{ id: string }>();

  const { getPosArmazenamento, getPosModelo, getStatusPos } = useUtils();

  const [posModelos, setPosModelos] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedModel, setSelectedModel] = useState<any[]>([]);
  const [statusOptions, setStatusOptions] = useState<any[]>([]);
  const [armazenamentoOptions, setArmazenamentoOptions] = useState<any[]>([]);
  const [historicoStatus, setHistoricoStatus] = useState<any>({});
  const [historicoEstabelecimento, setHistoricoEstabelecimento] =
    useState<Pagina>({} as Pagina);
  const [statusHasArmazenamento, setStatusHasArmazenamento] = useState(true);

  const rastreio_encomenda = useMemo(() => {
    if (!historicoStatus.rastreio) {
      return null;
    }

    return (
      <Col sm={12}>
        <CardCollapse title="Rastreio da Encomenda" loading={loading}>
          <Timeline mode="left">
            {historicoStatus.rastreio.eventos.map((rastreio: any) => (
              <Timeline.Item>
                <Text type="secondary">
                  {rastreio.data} {rastreio.hora}
                </Text>
                <br />
                <strong>{rastreio.descricao}</strong>
                <br />
                {rastreio?.destino ? (
                  <span>
                    {rastreio?.destino?.local} - {rastreio?.destino?.cidade} (
                    {rastreio?.destino?.uf})
                  </span>
                ) : null}
              </Timeline.Item>
            ))}
          </Timeline>
        </CardCollapse>
      </Col>
    );
  }, [historicoStatus.rastreio, loading]);

  useEffect(() => {
    const to_fetch = [
      fetchPosModelos(),
      fetchStatusPontoVenda(),
      fetchPontoVendaArmazenamento(),
    ];

    if (edit) {
      to_fetch.push(
        ...[
          fetchPontoVenda(),
          fetchHistoricoStatus(),
          fetchHistoricoEstabelcimento(),
        ],
      );
    }

    Promise.all(to_fetch).then(() => setLoading(false));
  }, []);

  async function fetchPosModelos() {
    return getPosModelo().then(data => {
      if (data.length === 1) {
        form.setFieldsValue({ id_pos_modelo: data[0].id_pos_modelo });
        setSelectedModel([data[0].id_pos_modelo]);
      }
      setPosModelos(
        data.map((item: any) => ({ ...item, key: item.id_pos_modelo })),
      );
    });
  }

  function fetchPontoVenda() {
    return fetchApi({
      url: `/ponto_venda/${id}`,
      method: 'get',
      onSuccess: data => {
        form.setFieldsValue(data);
        setSelectedModel([data.id_pos_modelo]);
        setStatusHasArmazenamento(!!data.id_ponto_venda_armazenamento);
      },
    });
  }

  async function fetchStatusPontoVenda() {
    return getStatusPos().then(data => {
      setStatusOptions(
        data.map((item: any) => ({
          value: item.id_status_ponto_venda,
          label: <Tag color={findTagColorByTipo(item.tipo)}>{item.nome}</Tag>,
          ic_armazenamento: item.ic_armazenamento,
        })),
      );
    });
  }

  async function fetchPontoVendaArmazenamento() {
    return getPosArmazenamento().then(data => {
      setArmazenamentoOptions(
        data.map((item: any) => ({
          value: item.id_ponto_venda_armazenamento,
          label: item.nome,
        })),
      );
    });
  }

  function fetchHistoricoStatus(pagina = 1, porPagina = 25) {
    return fetchApi({
      url: `/ponto_venda/${id}/historico/status`,
      method: 'get',
      query_params: { pagina, porPagina },
      onSuccess: resp => {
        const data = resp.data.map((item: any) => ({
          ...item,
          criado_em: format(parseISO(item.criado_em), 'dd/MM/yyyy HH:mm:ss'),
          status: (
            <Tag color={findTagColorByTipo(item.status_tipo)}>
              {item.status}
            </Tag>
          ),
        }));

        setHistoricoStatus({
          ...resp,
          data,
        });
      },
    });
  }

  function fetchHistoricoEstabelcimento(pagina = 1, porPagina = 25) {
    return fetchApi({
      url: `/ponto_venda/${id}/historico/estabelecimento`,
      method: 'get',
      query_params: { pagina, porPagina },
      onSuccess: data => {
        setHistoricoEstabelecimento(data);
      },
    });
  }

  function handleStore(data: any) {
    return fetchApi({
      url: '/ponto_venda',
      method: 'post',
      data,
      messages: {
        loading: 'Inserindo nova POS, aguarde...',
        success: 'POS inserida com sucesso!',
        error: 'Erro ao inserir POS, tente novamente!',
      },
      onSuccess: () => {
        history.push('/pos');
      },
    });
  }

  function handleUpdate(data: any) {
    return fetchApi({
      url: `/ponto_venda/${id}`,
      method: 'put',
      data,
      messages: {
        loading: 'Atualizando POS, aguarde...',
        success: 'POS atualizada com sucesso!',
        error: 'Erro ao atualizar POS, tente novamente!',
      }
    });
  }

  function handleUpdatedStatus(id_status: any) {
    const find_status = statusOptions.find(item => item.value === id_status);

    if (
      find_status &&
      find_status.ic_armazenamento !== statusHasArmazenamento
    ) {
      setStatusHasArmazenamento(find_status.ic_armazenamento);
    }
  }

  const columns_modelo: ColumnsType<any> = [
    {
      title: 'Nome',
      key: 'nome',
      dataIndex: 'nome',
    },
    {
      title: 'Valor Padrão Aluguel',
      key: 'valor_padrao_aluguel',
      dataIndex: 'valor_padrao_aluguel',
      align: 'right',
      render: (item: number) => dinheiroMask(item),
    },
    {
      title: 'Valor Padrão Perda',
      key: 'valor_padrao_perda',
      dataIndex: 'valor_padrao_perda',
      align: 'right',
      render: (item: number) => dinheiroMask(item),
    },
    {
      title: 'Valor Padrão Seguro',
      key: 'valor_padrao_seguro',
      dataIndex: 'valor_padrao_seguro',
      align: 'right',
      render: (item: number) => dinheiroMask(item),
    },
  ];

  // const columns_status: ColumnsType<any> = [
  //   {
  //     title: 'Status',
  //     key: 'status',
  //     dataIndex: 'status',
  //     // render: (item: string, data: any) => (
  //     //   <Tag color={findTagColorByTipo(data.status_tipo)}>{item}</Tag>
  //     // ),
  //   },
  //   {
  //     title: 'Armazenamento',
  //     key: 'armazenamento',
  //     dataIndex: 'armazenamento',
  //     render: (item: string) => item || '-',
  //   },
  //   {
  //     title: 'Cod. Rastreio',
  //     key: 'cod_rastreio',
  //     dataIndex: 'cod_rastreio',
  //     render: (item: string) => item || '-',
  //   },
  //   // {
  //   //   title: 'Criado Por',
  //   //   key: 'usuario_nome',
  //   //   dataIndex: 'usuario_nome',
  //   //   render: (item: string, data: any) => (
  //   //     <Link to={`/usuario/${data.criado_por}`}>{item}</Link>
  //   //   ),
  //   // },
  //   {
  //     title: 'Criado Em',
  //     key: 'criado_em',
  //     dataIndex: 'criado_em',
  //     // render: (item: string) => format(parseISO(item), 'dd/MM/yyyy HH:mm:ss'),
  //   },
  // ];

  const columns_estabelecimento: ColumnsType<any> = [
    {
      title: 'Estabelecimento',
      key: 'estabelecimento',
      dataIndex: 'estabelecimento',
      render: (item: string, data: any) => (
        <Link to={`/usuario/${data.id_usuario}`}>{item}</Link>
      ),
    },
    {
      title: 'Criado Por',
      key: 'criado_por_nome',
      dataIndex: 'criado_por_nome',
      render: (item: string, data: any) => (
        <Link to={`/usuario/${data.criado_por}`}>{item}</Link>
      ),
    },
    {
      title: 'Removido Por',
      key: 'removido_por_nome',
      dataIndex: 'removido_por_nome',
      render: (item: string, data: any) =>
        item ? <Link to={`/usuario/${data.removido_por}`}>{item}</Link> : '-',
    },
  ];

  return (
    <>
      <PageHeader
        title={edit ? 'Edição de POS' : 'Cadastro de POS'}
        breadcrumb={['POS', edit ? 'Edição' : 'Cadastro']}
      />

      <CardCollapse header={false} loading={loading}>
        <Form
          form={form}
          layout="vertical"
          initialValues={{ id_status_ponto_venda: 1 }}
          onFinish={values =>
            edit ? handleUpdate(values) : handleStore(values)
          }
        >
          <Row gutter={16}>
            <Col md={6}>
              <Form.Item
                name="serial_number"
                label="Serial Number"
                rules={[{ required: true, message: default_message }]}
              >
                <Input maxLength={45} />
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item
                name="logic_number"
                label="Logic Number"
                rules={[{ required: true, message: default_message }]}
              >
                <Input maxLength={45} />
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item
                name="id_status_ponto_venda"
                label="Status"
                rules={[{ required: true, message: default_message }]}
              >
                <Select
                  options={statusOptions}
                  onSelect={(val: any) => handleUpdatedStatus(val)}
                />
              </Form.Item>
            </Col>
            {statusHasArmazenamento ? (
              <Col md={6}>
                <Form.Item
                  name="id_ponto_venda_armazenamento"
                  label="Local de Armazenamento"
                  rules={[{ required: true, message: default_message }]}
                >
                  <Select options={armazenamentoOptions} />
                </Form.Item>
              </Col>
            ) : null}
            <Col md={24}>
              <Form.Item
                name="id_pos_modelo"
                label="Modelo"
                rules={[{ required: true, message: default_message }]}
              >
                <Table
                  columns={columns_modelo}
                  dataSource={posModelos}
                  pagination={false}
                  rowSelection={{
                    type: 'radio',
                    selectedRowKeys: selectedModel,
                    onChange: selectedRowKeys => {
                      form.setFieldsValue({
                        id_pos_modelo: selectedRowKeys[0],
                      });
                      setSelectedModel(selectedRowKeys);
                    },
                  }}
                  size="small"
                />
              </Form.Item>
            </Col>
          </Row>

          <br />

          <Row justify="end">
            <Button type="primary" htmlType="submit">
              {edit ? 'Salvar' : 'Cadastrar'}
            </Button>
          </Row>
        </Form>
      </CardCollapse>

      {edit ? (
        <Row gutter={16}>
          <Col md={12} xs={24}>
            <CardCollapse title="Histórico de Status" loading={loading}>
              <List
                itemLayout="horizontal"
                dataSource={historicoStatus.data}
                pagination={{
                  current: historicoStatus.pagina,
                  pageSize: historicoStatus.porPagina,
                  total: historicoStatus.total,
                  onChange: fetchHistoricoStatus,
                  size: 'small',
                }}
                renderItem={(item: any) => (
                  <List.Item>
                    <List.Item.Meta
                      title={item.status}
                      description={item.criado_em}
                    />

                    <div>
                      <Space split={<Divider type="vertical" />}>
                        <Tooltip placement="top" title="Local de Armazenamento">
                          {item.armazenamento}
                        </Tooltip>
                        <Tooltip placement="top" title="Código de Rastreio">
                          {item.cod_rastreio}
                        </Tooltip>
                      </Space>
                    </div>
                  </List.Item>
                )}
              />
              {/* <Table
                columns={columns_status}
                dataSource={historicoStatus.data}
                pagination={{
                  current: historicoStatus.pagina,
                  pageSize: historicoStatus.porPagina,
                  total: historicoStatus.total,
                  onChange: fetchHistoricoStatus,
                }}
                size="small"
              /> */}
            </CardCollapse>
          </Col>
          {rastreio_encomenda}
          <Col md={12} xs={24}>
            <CardCollapse
              title="Historico de Estabelecimentos"
              loading={loading}
            >
              <Table
                columns={columns_estabelecimento}
                dataSource={historicoEstabelecimento.data}
                pagination={{
                  current: historicoEstabelecimento.pagina,
                  pageSize: historicoEstabelecimento.porPagina,
                  total: historicoEstabelecimento.total,
                  onChange: fetchHistoricoEstabelcimento,
                }}
                size="small"
              />
            </CardCollapse>
          </Col>
        </Row>
      ) : null}
    </>
  );
};

export default POSCadastro;
