import React, { useEffect, useState } from 'react';
import {
  Button,
  Form,
  Row,
  Col,
  Input,
  Select,
  Tag,
} from 'antd';
import { dinheiroMask } from 'masks-br';
import {
  MdOpenInNew,
} from 'react-icons/md';
import { ColumnsType } from 'antd/lib/table';
import 'moment/locale/pt-br';

import PageHeader from '../../../components/PageHeader';
import Table from '../../../components/Table';
import CardCollapse from '../../../components/CardCollapse';
import { ItemInputCurrency } from '../../../components/Form';
import {
  setQueryParams, useQueryParams,
} from '../../../utils/UrlQuery';
import { useQuery } from "react-query";
import { getCharges } from "../services";
import { EnumChargeStatus, EnumChargeType, GetChargesParams, chargeStatusOptions, chargeTypeLabels } from "../services/types";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import { uuid_pattern } from "../../../utils/pattern";
import { getOriginIcon } from "..";

const ChargeSearch: React.FC = () => {
  const default_filters = { page: 1, page_size: 25 };
  const query_values = useQueryParams();
  const [filters, setFilters] = useState<GetChargesParams>({
    ...default_filters,
    ...query_values,
  })

  const [form] = Form.useForm();

  const { isFetching: isLoading, data: payments } = useQuery({
    queryKey: ['backoffice-charge', filters],
    queryFn: () => getCharges(filters),
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
    refetchOnReconnect: false,
  });

  function handleSetFiltersValues(nextFilters: GetChargesParams) {
    let final: GetChargesParams = {
      ...filters,
      ...nextFilters,
      id_charge: nextFilters.id_charge ? nextFilters.id_charge : undefined
    };

    setQueryParams(final);
    setFilters(final);
  }

  function handleResetForm() {
    setQueryParams(default_filters);
    form.resetFields();
    setFilters(default_filters)
  }

  useEffect(() => {
    form.setFieldsValue({ ...query_values });
  }, []);

  const columns: ColumnsType = [
    {
      title: 'Origem',
      key: 'source',
      dataIndex: 'source',
      align: 'center',
      render: getOriginIcon,
    },
    {
      title: 'Valor total',
      key: 'amount_total',
      dataIndex: 'amount_total',
      align: 'center',
      render: (item: number) => dinheiroMask(item),
    },
    {
      title: 'Valor parcela',
      key: 'installment_amount',
      dataIndex: 'installment_amount',
      align: 'center',
      width: 100,
      render: (item: number) => dinheiroMask(item),
    },
    {
      title: 'Parcelas',
      key: 'installment_number',
      dataIndex: 'installment_number',
      align: 'center',
    },
    {
      title: 'Tipo',
      key: 'type',
      dataIndex: 'type',
      align: 'center',
      render: (item: EnumChargeType) => <Tag>{chargeTypeLabels[item]}</Tag>,
    },
    {
      title: 'Canal',
      key: 'channel',
      dataIndex: 'channel',
      align: 'center',
    },
    {
      title: 'Nsu',
      key: 'nsu_code',
      dataIndex: 'nsu_code',
      align: 'center',
    },
    {
      title: 'Adquirência',
      key: 'acquirer',
      dataIndex: 'acquirer',
      align: 'center',
    },
    {
      title: 'Status',
      key: 'id_status',
      dataIndex: 'id_status',
      align: 'center',
      render: (item: EnumChargeStatus): any => {
        const status = chargeStatusOptions.find(status => status.value === item)

        return status?.label
      },
    },
    {
      title: 'Criado em',
      align: 'center',
      key: 'created_at',
      dataIndex: 'created_at',
      render: (value) => {
        const createdAt = format(
          new Date(value),
          'dd/MM/yyyy HH:mm:ss',
        )

        return createdAt
      },
      width: 200,
    },
    {
      title: 'Abrir carrinho',
      width: 80,
      align: 'center',
      render: (data: any) =>
          <Link
            to={`/checkout/${data.checkout_id}`}
            className="ant-btn ant-btn-primary"
          >
            <MdOpenInNew />
          </Link>
    },
    {
      title: 'Abrir cobrança',
      width: 80,
      align: 'center',
      render: (data: any) =>
          <Link
            to={`/charge/${data.id_charge}`}
            className="ant-btn ant-btn-primary"
          >
            <MdOpenInNew />
          </Link>
    }
  ];

  return (
    <>
      <PageHeader title="Cobranças" breadcrumb={['Cobranças']} />

      <CardCollapse title="Filtros">
        <Form
          layout="vertical"
          form={form}
          onFinish={data => handleSetFiltersValues(data)}
        >
          <Row gutter={16}>
            <Col md={5} xs={12}>
              <Form.Item name="id_charge" label="Id" rules={[
                {
                  pattern: uuid_pattern,
                  message: 'Deve ser um ID válido!'
                 }
              ]}>
                <Input placeholder="ID da cobrança" />
              </Form.Item>
            </Col>
            <Col md={4} xs={12}>
              <Form.Item name="type" label="Tipo de pagamento">
                <Select 
                allowClear
                placeholder="Boleto, Pix..."
                options={Object.keys(chargeTypeLabels).map((value: any) => ({ label: chargeTypeLabels[value as EnumChargeType], value: value }))}
              />
              </Form.Item>
            </Col>
            <Col md={6} xs={12}>
              <Form.Item name="status" label="Status">
                <Select
                  options={chargeStatusOptions}
                  allowClear
                  placeholder="Status do pagamento"
                />
              </Form.Item>
            </Col>
            <Col md={6} xs={12}>
              <Form.Item name="amount_total" label="Valor">
                <ItemInputCurrency
                  onChange={(_, val) =>
                    form.setFieldsValue({ amount_total: val || undefined })
                  }
                />
              </Form.Item>
            </Col>
          </Row>

          <br />

          <Row justify="end">
            <Button onClick={handleResetForm} className="buttonMarginRight">
              Limpar
            </Button>
            <Button type="primary" htmlType="submit">
              Filtrar
            </Button>
          </Row>
        </Form>
      </CardCollapse>

      <Table
        columns={columns}
        dataSource={payments?.data ?? []}
        size="small"
        loading={isLoading}
        pagination={{
          total: payments?.total,
          current: payments?.page,
          pageSize: payments?.page_size,
          onChange: (page, page_size) => handleSetFiltersValues({ page, page_size }),
        }}
      />
    
    </>
  );
};

export default ChargeSearch;
