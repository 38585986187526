import api from '../../../services/api';
import { GetSimulationInstallmentsResponse } from "./types/installments";

type GetInstallmentsSimulationByEstablishment = {
  amount: number;
  id_establishment?: string;
};

export async function getInstallmentsSimulationByEstablishment(parameters: GetInstallmentsSimulationByEstablishment) {
  const response = await api.get<GetSimulationInstallmentsResponse>(
    '/panel/simulation/installments/',
    {
      params: {
        amount: parameters.amount
      },
      headers: {
        'id-establishment': parameters.id_establishment,
      },
    },
  );

  return response.data;
}
