import { useQuery } from "react-query";
import { getTerminalInfo, getTerminalModels } from "../services";
import { message } from "antd";
import { FiltersModel } from "../components/Form";

export function useTerminalInfo(id_terminal: string) {
  return useQuery({
    queryKey: ['terminal-info', id_terminal],
    queryFn: () => getTerminalInfo(id_terminal),
    enabled: !!id_terminal,
    retry: false,
    staleTime: 3 * 60 * 1000,
    onError(err: any) {
      if (err.statusCode === 404) {
        return message.error('Terminal não encontrado!')
      }

      message.error(err?.message)
    },
  })
}

export function useTerminalModels(filters: FiltersModel) {
  return useQuery({
    queryKey: ['terminal-models-list', filters],
    queryFn: () => getTerminalModels({ ...filters }),
    retry: false,
    staleTime: 1 * 60 * 1000,
    onError(err: any) {
      message.error(err?.message)
    },
  })
}