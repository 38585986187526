enum EnumStatusCarrinho {
  PAGAMENTO_INICIADO = 1,
  PAGAMENTO_FINALIZADO,
  INICIANDO_ANALISE_RISCO,
  ANALISE_RISCO_APROVADO,
  ANALISE_RISCO_REPROVADO,
  ANALISE_RISCO_ERRO,
  INICIANDO_PRE_APROVACAO_CARTAO,
  PRE_APROVACAO_CARTAO_AUTORIZADA,
  PRE_APROVACAO_CARTAO_REPROVADA,
  PRE_APROVACAO_CARTAO_ERRO,
  INICIANDO_CONFIRMACAO_PRE_APROVACAO,
  CONFIRMACAO_PRE_APROVACAO_AUTORIZADA,
  CONFIRMACAO_PRE_APROVACAO_REPROVADA,
  CONFIRMACAO_PRE_APROVACAO_ERRO,
  INICIANDO_CANCELAMENTO_PRE_APROVACAO,
  CANCELAMENTO_PRE_APROVACAO_AUTORIZADA,
  CANCELAMENTO_PRE_APROVACAO_REPROVADA,
  CANCELAMENTO_PRE_APROVACAO_ERRO,
  INICIANDO_LIQUIDACAO_BOLETOS,
  LIQUIDACAO_BOLETOS_CONCLUIDO,
  LIQUIDACAO_BOLETOS_ERRO,
  CARRINHO_REMOVIDO,
  PREPARANDO_WEBHOOK,
  WEBHOOK_CONFIRMADO,
  WEBHOOK_ERRO,
  LIQUIDACAO_MANUAL_SOLICITADA,
  NOTIFICACAO_ENVIADA,
  NOTIFICACAO_ENVIADA_MANUALMENTE,
  EM_DISPUTA,
  CHARGEBACK,
  ESTORNADO,
}

export default EnumStatusCarrinho;
