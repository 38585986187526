import api from "../../../services/api";
import { GetDebitVehicleResponse, GetDetransResponse } from "./types/search.debits.detran";

type GetDebitParameters = {
  state: string
  query?: any
}

export async function getDebitDetailInfoPanel(parameters: GetDebitParameters) {
  const response = await api.get<GetDebitVehicleResponse>(`/panel/detran/${parameters.state}/debits`, { params: parameters.query })

  return response.data
}

type GetDetransQueryParams = {
  integrated?: boolean
}

export async function getDetrans(params?: GetDetransQueryParams) {
  const response = await api.get<GetDetransResponse>('/panel/detran/', { params: {
    page: 1,
    page_size: 30,
    type: 'DETRAN',
    ...params
  } })

  return response.data
}