import React, { useEffect, useMemo, useState } from 'react';
import { endOfDay, format } from 'date-fns';
import locale from 'antd/es/date-picker/locale/pt_BR';
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Tooltip,
  Typography,
  message,
} from 'antd';

import { createPaymentLink } from '../services/links';
import PageHeader from '../../../components/PageHeader';
import { useEstablishmentsByUserAndResponsabilidade } from '../../Estabelecimento/hooks/useEstablishments';
import { validatorDigitableLineFormItemRule } from '../../../utils/billet';
import { useMutation, useQuery } from 'react-query';
import { FormSearchDebitCreatePaymentLinkSchema } from './types/form.search';
import {
  DebitDetranInfoErrorResponse,
  DebitVehicle,
  FieldDetran,
  getDetranErrorMessage,
} from '../services/types/search.debits.detran';
import { getBillDetailInfoPanel } from '../services/bill';
import {
  BillInfoErrorResponse,
  GetPanelBillSearchResponse,
  getBillErrorMessage,
} from '../services/types/bill';
import { getDebitDetailInfoPanel, getDetrans } from '../services/debit';
import { CardBill, CardDebitVehicle } from './CardDebit';
import { useSelector } from 'react-redux';
import { IState } from '../../../store';
import { IUserState } from '../../../store/modules/user/reducer';
import {
  EnumCreatePaymentLinkRequestItemType,
  TCreatePaymentLink,
  TCreatePaymentLinkItem,
} from '../services/types/links';
import { useHistory } from 'react-router';
import { getInstallmentsSimulationByEstablishment } from '../services/installments';
import { dinheiroMask } from 'masks-br';
import moment from 'moment';
import { RangePickerProps } from 'antd/lib/date-picker';
import { FieldTypeDetran, fieldValidate } from '../../../utils/fields';
import EnumResponsabilidade from '../../../types/enum/Responsabilidade';

const { Title, Paragraph } = Typography;

type FieldType = {
  amount: string;
  description: string;
  installment_number: number;
  primaryColor?: string;
  secondaryColor?: string;
  paymentSupportedTypes?: number[];
  expiration_date?: string;
  supportMultipleTransactions?: boolean;
  receivers?: string;
  payment_supported_types: string[];
  establishment_id: string;
  email?: string;
  charge_methods: ['CREDIT_CARD', 'DEBIT_CARD', 'PIX', 'BILL'];
};

const CreatePaymentLink: React.FC = () => {
  const user = useSelector<IState, IUserState>(state => state.user);
  const responsabilidade = useSelector<
    IState,
    EnumResponsabilidade | undefined
  >(state => state.auth.responsabilidade);
  const [form] = Form.useForm();
  const [searchDebitForm] =
    Form.useForm<FormSearchDebitCreatePaymentLinkSchema>();
  const [loading, setLoading] = useState<boolean>(false);
  const [expirationDate, setExpirationDate] = useState<any>();
  const [paymentLink, setPaymentLink] = useState<string>('');
  const [idEstablishment, setIdEstablishment] = useState<string>('');
  const [inputType, setInputType] = useState<'billet' | 'debit'>('billet');
  const [billsForPayment, setBillForPayment] = useState<
    GetPanelBillSearchResponse[]
  >([]);
  const [debtsForPayment, setDebtsForPayment] = useState<DebitVehicle[]>([]);
  const [fieldsDetranSelected, setFieldDetranSelected] = useState<
    FieldDetran[]
  >([]);
  const [chargeMethods, setChargeMethods] = useState(['CREDIT_CARD']);
  const history = useHistory();

  useEffect(() => {
    if (!chargeMethods.includes('CREDIT_CARD')) {
      form.setFields([{ name: 'installment_number', value: null }]);
    }
  }, [chargeMethods, form]);

  function addBillForPayment(dataBillet: GetPanelBillSearchResponse) {
    setBillForPayment(oldBillets => [...oldBillets, dataBillet]);
  }
  function addDebitForPayment(debits: DebitVehicle[]) {
    setDebtsForPayment(oldDebits => [...oldDebits, ...debits]);
  }

  const { data: detrans } = useQuery(
    ['get-detrans'],
    () => getDetrans({ integrated: true }),
    {
      cacheTime: 1000 * 60 * 2, // two minutes
    },
  );

  const { isLoading: isLoadingBill, mutate: getBillData } = useMutation({
    mutationKey: ['search-bill'],
    mutationFn: getBillDetailInfoPanel,
    onError(err: BillInfoErrorResponse) {
      const messageError = getBillErrorMessage(err);
      message.error(messageError);
    },
  });

  const { isLoading: isLoadingDebit, mutate: getDebitData } = useMutation({
    mutationKey: ['search-debit'],
    mutationFn: getDebitDetailInfoPanel,
    onError(err: DebitDetranInfoErrorResponse) {
      const messageError = getDetranErrorMessage(err);
      message.error(messageError);
    },
  });

  const {
    isLoading: isLoadingInstallments,
    mutate: getInstallments,
    data: installments,
    reset: resetInstallments,
  } = useMutation({
    mutationKey: ['get-installments'],
    mutationFn: getInstallmentsSimulationByEstablishment,
    onError(err) {
      const error = err as any;
      const messageError =
        error?.response?.data?.message ||
        error?.message ||
        'Houve um erro inesperado, tente novamente!';
      message.error(messageError);
    },
  });

  const isLoadingSearch = isLoadingBill || isLoadingDebit;

  const { data: establishments } = useEstablishmentsByUserAndResponsabilidade(
    1,
    25,
    user.id_usuario,
    responsabilidade ?? EnumResponsabilidade.REPRESENTANTE,
    !!responsabilidade,
  );

  async function handleFetchPaymentLinkCreation(data: FieldType) {
    try {
      setLoading(true);

      const items: TCreatePaymentLinkItem[] = [];

      if (billsForPayment.length) {
        billsForPayment.forEach(bill => {
          items.push({
            type: EnumCreatePaymentLinkRequestItemType.BILLET,
            bill_id: bill.id_bill,
          });
        });
      }

      if (debtsForPayment.length) {
        debtsForPayment.forEach(debit => {
          items.push({
            type: EnumCreatePaymentLinkRequestItemType.CAR_DEBIT,
            debit_id: debit.id_debit,
          });
        });
      }
      const fetchData: TCreatePaymentLink = {
        installment_number: data.installment_number,
        charge_methods: data.charge_methods,
        user_id: user.id_usuario,
        description: '',
        items,
        establishment_id: idEstablishment,
        notification: {
          email: data.email,
        },
      };

      if (expirationDate) {
        fetchData.expiration_date = format(
          endOfDay(new Date(expirationDate)),
          "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
        );
      }

      const response = await createPaymentLink(fetchData, idEstablishment);

      form.resetFields();
      setPaymentLink(response.url);
      message.success('Link de pagamento criado com sucesso!');
      history.push(`/link/${response.id_payment_link}`);
    } catch (err) {
      const error = err as any;
      const messageError =
        error?.response?.data?.message ||
        error?.message ||
        'Houve um erro inesperado, tente novamente!';
      message.error(messageError);
    } finally {
      setLoading(false);
    }
  }

  function handleDeleteBill(id_bill: string) {
    const filteredDebits = billsForPayment.filter(
      bill => bill.id_bill !== id_bill,
    );
    setBillForPayment(filteredDebits);
  }

  function handleDeleteDebitVehicle(id_debit: string) {
    const filteredDebits = debtsForPayment.filter(
      debit => debit.id_debit !== id_debit,
    );
    setDebtsForPayment(filteredDebits);
  }

  function handleSearchDebit(values: FormSearchDebitCreatePaymentLinkSchema) {
    if (values.type_debit_payment === 'billet') {
      const hasBilletBarcode = !!billsForPayment.find(
        bill => bill.digitable === values.barcode,
      );

      if (hasBilletBarcode) {
        message.warning('Você já adicionou este boleto!');
        return;
      }

      getBillData(
        { barcode: values.barcode, id_establishment: user.id_usuario },
        {
          onSuccess: data => {
            addBillForPayment(data);
            searchDebitForm.resetFields(['barcode']);
          },
        },
      );
      return;
    }

    getDebitData(
      { state: values.state, query: { ...values } },
      {
        onSuccess: data => {
          addDebitForPayment(data.debits);
          searchDebitForm.resetFields(['state']);
          setFieldDetranSelected([]);
        },
      },
    );
  }

  const enableSelectInstallments =
    chargeMethods.includes('CREDIT_CARD') && idEstablishment;

  useEffect(() => {
    if (enableSelectInstallments) {
      let amount = 0;
      billsForPayment.forEach(bill => {
        amount += bill.amount.total;
      });
      debtsForPayment.forEach(debit => {
        amount += debit.amount;
      });

      if (amount) {
        const payload = {
          amount,
          id_establishment: idEstablishment,
        };

        getInstallments(payload);
      } else {
        resetInstallments();
      }
    } else {
      resetInstallments();
    }
  }, [
    idEstablishment,
    chargeMethods,
    billsForPayment,
    debtsForPayment,
    getInstallments,
    resetInstallments,
    enableSelectInstallments,
  ]);

  const disabledDate: RangePickerProps['disabledDate'] = current => {
    return current && current < moment().subtract(1, 'day');
  };

  const installmentsOptions = useMemo(() => {
    const standardInstallment = { label: 'Todas as parcelas', value: null };

    if (!installments) {
      return [];
    }

    const options = installments?.map(installment => {
      const label = `Crédito - ${
        installment.installment_number
      }X ${dinheiroMask(installment.installment_amount)} (${
        installment.fee_percent
      }%)`;
      const value = installment.installment_number;

      return { label, value };
    });

    return [standardInstallment, ...options];
  }, [installments]);

  return (
    <>
      <PageHeader
        title="Informações do Link de Pagamento"
        breadcrumb={['Link de Pagamento', 'Novo Link']}
      />

      <Card>
        <Title level={5}>Dados da cobrança</Title>

        <Divider />

        <Form
          name="search_debit_form"
          form={searchDebitForm}
          layout="vertical"
          onFinish={handleSearchDebit}
        >
          <Col span={6}>
            <Form.Item name="type_debit_payment" initialValue="billet">
              <Radio.Group
                value={inputType}
                onChange={({ target: { value } }) => setInputType(value)}
              >
                <Radio value="billet">Boleto</Radio>
                <Radio value="debit">Débitos Veiculares</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <br />
          <br />

          <Row>
            <Col md={8}>
              {inputType === 'billet' ? (
                <Form.Item
                  label="Código de Barras"
                  name="barcode"
                  rules={[
                    {
                      validator: (_, value) => {
                        if (!value) return Promise.reject('Campo obrigatório!');

                        return validatorDigitableLineFormItemRule(value);
                      },
                    },
                  ]}
                >
                  <Input placeholder="Digite o código de barras aqui..." />
                </Form.Item>
              ) : (
                <>
                  <Form.Item
                    label="Selecione o Detran"
                    name="state"
                    rules={[
                      {
                        required: true,
                        message: 'Campo obrigatório!',
                      },
                    ]}
                  >
                    <Select
                      options={detrans?.data.map(detran => ({
                        label: detran.name,
                        value: detran.state,
                      }))}
                      onChange={value => {
                        const detran = detrans?.data.find(
                          detran => detran.state === value,
                        );
                        setFieldDetranSelected(detran?.fields ?? []);
                      }}
                      placeholder="Selecione um Detran"
                    />
                  </Form.Item>
                  {fieldsDetranSelected.map(field => (
                    <Form.Item
                      key={field.input_key}
                      label={field.label}
                      name={field.input_key.toLocaleLowerCase()}
                      rules={[
                        {
                          required: field.required,
                          validator:
                            fieldValidate[field.input_key as FieldTypeDetran],
                        },
                      ]}
                    >
                      <Input placeholder={field.label} />
                    </Form.Item>
                  ))}
                </>
              )}
            </Col>
            <Col
              md={2}
              push={2}
              style={{
                marginTop: 30,
              }}
            >
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="middle"
                  loading={isLoadingSearch}
                >
                  Pesquisar
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <br />

        <Row>
          <Col md={12}>
            {billsForPayment.map(bill => (
              <CardBill bill={bill} onDelete={handleDeleteBill} />
            ))}
          </Col>
          <Col push={2} md={10}>
            {debtsForPayment.map(debit => (
              <CardDebitVehicle
                debit={debit}
                onDelete={handleDeleteDebitVehicle}
              />
            ))}
          </Col>
        </Row>

        <Divider />

        <Form
          name="new_payment_link"
          form={form}
          layout="vertical"
          onFinish={handleFetchPaymentLinkCreation}
          initialValues={{
            installment_number: null,
          }}
        >
          <Row>
            <Col span={4}>
              <Form.Item<FieldType>
                label="Data de Expiração"
                name="expiration_date"
                tooltip="O Link de Pagamento não pode ter data de expiração após a do boleto ou débito mais próxima!"
              >
                <DatePicker
                  onChange={date => setExpirationDate(date)}
                  locale={locale}
                  format="DD/MM/YYYY"
                  disabledDate={disabledDate}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row style={{ marginTop: 20 }}>
            <Col span={14}>
              <Form.Item<FieldType>
                label="Formas de pagamento"
                name="charge_methods"
                initialValue={['CREDIT_CARD']}
                required
                rules={[
                  {
                    validator(rule, value: string[], callback) {
                      !value.length
                        ? callback(
                            'Selecione pelo menos uma forma de pagamento!',
                          )
                        : callback();
                    },
                  },
                ]}
              >
                <Checkbox.Group
                  value={chargeMethods}
                  onChange={value => setChargeMethods(value as any)}
                  options={[
                    { label: 'Cartão', value: 'CREDIT_CARD' },
                    { label: 'Boleto', value: 'BILL', disabled: true },
                    { label: 'Pix', value: 'PIX' },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row style={{ marginTop: 20 }}>
            <Col span={14}>
              <Form.Item<FieldType>
                label="Estabelecimento"
                name="establishment_id"
                rules={[{ required: true, message: 'Seleção Obrigatória!' }]}
              >
                <Select
                  placeholder="Selecione um dos seus estabelecimentos"
                  style={{ width: '100%' }}
                  onChange={setIdEstablishment}
                  options={establishments?.data.map(establishment => ({
                    value: establishment.id_usuario,
                    label: establishment.nome,
                  }))}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row
            style={{
              marginTop: 20,
              display: enableSelectInstallments ? 'flex' : 'none',
            }}
          >
            <Col span={6}>
              <Form.Item<FieldType>
                label="Fixe as Parcelas"
                name="installment_number"
              >
                <Select
                  loading={isLoadingInstallments}
                  options={installmentsOptions}
                  notFoundContent="Não há parcelas disponíveis"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row style={{ marginTop: 20 }}>
            <Col span={14}>
              <Form.Item<FieldType>
                label="Email"
                name="email"
                rules={[
                  {
                    type: 'email',
                    message: 'E-mail inválido!',
                  },
                ]}
                tooltip={
                  <Tooltip title="emailTooltip">
                    Email para qual será enviado a cobrança do link de
                    pagamento.
                  </Tooltip>
                }
              >
                <Input placeholder="Ex: email@parcelamostudo.com" />
              </Form.Item>
            </Col>
          </Row>

          {paymentLink.length ? (
            <>
              <Divider />

              <Title level={5}>Link Gerado</Title>

              <Row>
                <Col span={24}>
                  <Paragraph copyable>{paymentLink}</Paragraph>
                </Col>
              </Row>

              <Divider />
            </>
          ) : null}

          <Row style={{ marginTop: 30 }} justify="end">
            <Col>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: 150 }}
                size="large"
                loading={loading}
                disabled={!billsForPayment.length && !debtsForPayment.length}
              >
                Criar
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
    </>
  );
};

export default CreatePaymentLink;
