import { useMutation } from 'react-query';
import {
  createGeneralQueryRule,
  deleteGeneralQueryRule,
  updateGeneralQueryRule
} from '../services';
import { queryClientApp } from '../../../../App';

export function useUpdateQueryRule() {
  return useMutation({
    mutationFn: updateGeneralQueryRule,
    onSuccess: () => {
      queryClientApp.invalidateQueries('get-general-query-rules');
    },
  });
}

export function useDeleteQueryRule() {
  return useMutation({
    mutationFn: deleteGeneralQueryRule,
    onSuccess: () => {
      queryClientApp.invalidateQueries('get-general-query-rules');
    },
  });
}

export function useCreateQueryRule() {
  return useMutation({
    mutationFn: createGeneralQueryRule,
    onSuccess: () => {
      queryClientApp.invalidateQueries('get-general-query-rules');
    },
  });
}
