import styled from 'styled-components';

export const LinkButton = styled.div`
  a {
    background: var(--primary);
    color: #5a5a5a;

    margin: 10px 0;
    border-radius: 5px;
    padding: 5px 10px;
    transition: 0.3s;

    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover {
      box-shadow: 0px 0px 6px var(--primary);
    }
  }
`;
