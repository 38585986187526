import React, { useEffect, useMemo, useState } from 'react';
import { Form, Row, Col, Input, Checkbox, Button, Select } from 'antd';
import { MdAdd, MdRemoveCircle } from 'react-icons/md';
import { useHistory, useParams } from 'react-router-dom';

import CardCollapse from '../../../components/CardCollapse';
import PageHeader from '../../../components/PageHeader';
import { useUtils } from '../../../hooks/useUtils';
import Option from '../../../types/Option';
import { useApi } from '../../../hooks/useApi';

const default_message = 'Esse campo é obrigatório';
const uuid_pattern = new RegExp(
  '^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$',
  'i',
);

const field_name_options: Option[] = [
  {
    label: 'Documento',
    value: 'document',
  },
  {
    label: 'Placa do Veículo',
    value: 'license_plate',
  },
  {
    label: 'RENAVAM',
    value: 'renavam',
  },
];

const DetranCadastro: React.FC = () => {
  const { getEstado } = useUtils();
  const { fetchRequest: fetchCreateDetran, loading: loadingCreateDetran } =
    useApi();
  const { fetchRequest: fetchUpdateDetran, loading: loadingUpdateDetran } =
    useApi();
  const { fetchRequest: fetchDetranInfo, loading: loadingDetranInfo } =
    useApi();
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();
  const [form] = Form.useForm();

  const [estado, setEstado] = useState<Option[]>([]);

  const is_edit = useMemo(() => {
    return id && id !== 'new';
  }, [id]);

  useEffect(() => {
    getEstado().then(data => {
      setEstado(
        data.map(item => ({
          label: item.nome,
          value: item.id_estado,
        })),
      );
    });

    if (is_edit) {
      handlFetchDetranInfo();
    }
  }, []);

  function handleCreateDetran(data: any) {
    const fields = data.fields.map((field: any) => {
      const opt = field_name_options.find(item => item.value === field.name);

      return {
        ...field,
        label: opt?.label,
      };
    });

    fetchCreateDetran({
      url: '/detran',
      method: 'post',
      data: {
        ...data,
        fields,
      },
      messages: {
        loading: 'Cadastrando Detran, aguarde...',
        error: 'Erro ao inserir Detran, tente novamente!',
        success: 'Detran cadastrado com sucesso!',
      },
      onSuccess: () => {
        history.push('/detran');
      },
    });
  }

  function handleUpdateDetran(data: any) {
    fetchUpdateDetran({
      url: `/detran/${id}`,
      method: 'put',
      data,
      messages: {
        loading: 'Atualizando Detran, aguarde...',
        error: 'Erro ao atualizar Detran, tente novamente!',
        success: 'Detran atualizado com sucesso!',
      },
      onSuccess: () => {
        history.push('/detran');
      },
    });
  }

  function handlFetchDetranInfo() {
    return fetchDetranInfo({
      method: 'get',
      url: `/detran/${id}`,
      onSuccess: data => {
        const detran_info = {
          ...data,
          state: data.state.id_estado,
        };

        form.setFieldsValue(detran_info);
      },
    });
  }

  return (
    <>
      <PageHeader
        title="Detran"
        breadcrumb={['Detran', is_edit ? 'Editar' : 'Cadastro']}
      />

      <CardCollapse header={false} loading={loadingDetranInfo}>
        <Form
          layout="vertical"
          onFinish={is_edit ? handleUpdateDetran : handleCreateDetran}
          form={form}
        >
          <Row gutter={16}>
            <Col md={5}>
              <Form.Item
                name="state"
                label="Estado"
                rules={[{ required: true, message: default_message }]}
              >
                <Select options={estado} />
              </Form.Item>
            </Col>
            <Col md={7}>
              <Form.Item
                name="id_usuario"
                label="Id do Usuário Responsável"
                rules={[
                  { required: true, message: default_message },
                  {
                    pattern: uuid_pattern,
                    message: 'Deve ser um id válido',
                  },
                ]}
                extra="As vendas serão em nome desse usuário"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                name="image_src"
                label="URL da Imagem"
                rules={[
                  { required: true, message: default_message },
                  { type: 'url', message: 'Deve ser uma URL válida' },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            {is_edit ? (
              <Col md={5}>
                <Form.Item
                  name="production"
                  label="Ambiente"
                  valuePropName="checked"
                >
                  <Checkbox>Produção</Checkbox>
                </Form.Item>
              </Col>
            ) : null}
          </Row>

          <br />
          <br />

          <h3>Campos</h3>
          <Form.List name="fields">
            {(fields, { add, remove }) => (
              <>
                <Row gutter={16} align="middle">
                  {fields.map(({ key, name, ...restField }) => (
                    <>
                      {/* <Col md={8}>
                        <Form.Item
                          {...restField}
                          label="Nome"
                          name={[name, 'label']}
                          fieldKey={[fieldKey, 'label']}
                          rules={[{ required: true, message: default_message }]}
                        >
                          <Input placeholder="Documento" />
                        </Form.Item>
                      </Col> */}
                      <Col md={8}>
                        <Form.Item
                          {...restField}
                          label="Valor"
                          name={[name, 'name']}
                          fieldKey={[name, 'name']}
                          rules={[{ required: true, message: default_message }]}
                        >
                          <Select options={field_name_options} />
                        </Form.Item>
                      </Col>
                      <Col md={15}>
                        <Form.Item
                          {...restField}
                          label="Obrigatório"
                          name={[name, 'required']}
                          valuePropName="checked"
                          fieldKey={[name, 'required']}
                        >
                          <Checkbox />
                        </Form.Item>
                      </Col>
                      <Col md={1}>
                        {fields.length !== 1 ? (
                          <MdRemoveCircle onClick={() => remove(name)} />
                        ) : null}
                      </Col>
                    </>
                  ))}
                </Row>
                <br />
                <Row justify="space-between">
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    icon={<MdAdd />}
                    disabled={loadingCreateDetran || loadingUpdateDetran}
                  >
                    Adicionar Campo
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loadingCreateDetran || loadingUpdateDetran}
                  >
                    {is_edit ? 'Editar' : 'Cadastrar'}
                  </Button>
                </Row>
              </>
            )}
          </Form.List>
        </Form>
      </CardCollapse>
    </>
  );
};

export default DetranCadastro;
