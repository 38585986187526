import React, { useEffect, useMemo, useState } from 'react';
import {
  Col,
  Input,
  Row,
  Tag,
  Tooltip,
  Form,
  Select,
  Space,
  Button,
  Popconfirm,
  InputNumber,
  Modal,
  message,
} from 'antd';
import { format, formatDistanceToNow, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import CardCollapse from '../../../components/CardCollapse';
import PageHeader from '../../../components/PageHeader';
import Table from '../../../components/Table';
import { fetchApi } from '../../../services/api';
import Pagina, { Pagination } from '../../../types/Pagina';
import findTagColorByTipo from '../../../utils/findTagColorByTipo';
import { useUtils } from '../../../hooks/useUtils';
import Option from '../../../types/Option';
import Card from '../../../components/Card';
import EnumStatusVinculo from '../../../types/enum/StatusVinculo';
import { updateQtdeVinculosPendentes } from '../../../store/modules/utils/actions';
import FluxoUsuario from '../../../components/FluxoUsuario';
import EnumResponsabilidade from '../../../types/enum/Responsabilidade';
import { usePermission } from '../../../hooks/usePermissions';
import { setQueryParams, useQueryParams } from '../../../utils/UrlQuery';
import { useBonds } from './hooks/useBonds';
import type { Filters } from './hooks/useBonds';
import { Bonds } from './services/types';

const { TextArea } = Input;

const VinculoPesquisar: React.FC = () => {
  const [FormFiltro] = Form.useForm();
  const [FormAprovar] = Form.useForm();
  const [FormReprovar] = Form.useForm();
  const [formChangeStatus] = Form.useForm();
  const { getResponsabilidade, getStatusVinculo } = useUtils();
  const dispatch = useDispatch();
  const { getPermissions } = usePermission();
  const query_params = useQueryParams();

  const permissao = getPermissions();

  const [dashboardValues, setDashboardValues] = useState<any[]>([]);
  const [vinculos, setVinculos] = useState<Pagination<Bonds>>({} as Pagina);
  const [vinculoSelected, setVinculoSelected] = useState<any[]>([]);
  const [modalAprovar, setModalAprovar] = useState(false);
  const [modalReprovar, setModalReprovar] = useState(false);
  const [responsabilidade, setResponsabilidade] = useState<Option[]>([]);
  const [statusVinculo, setStatusVinculo] = useState<Option[]>([]);
  const [idStatusVinculo, setIdStatusVinculo] = useState<number | null>(null);
  const [filtros, setFiltros] = useState<Filters>({} as Filters);

  useEffect(() => {
    Promise.all([
      fetchResponsabilidade(),
      fetchStatusVinculo(),
      fetchDashboard(),
    ]);

    FormFiltro.setFieldsValue({});
  }, []);

  useEffect(() => {
    setVinculoSelected([]);
    setIdStatusVinculo(null);
  }, [filtros]);

  const { isLoading, refetch: refetchVinculos } = useBonds<Bonds>(filtros, {
    onSuccess(data) {
      const list = data.data.map((item: any) => {
        const parsed = parseISO(item.criado_em);

        return {
          ...item,
          key: item.id_usuario_vinculo,
          criado_em: format(parsed, 'dd/MM/yyyy HH:mm:ss'),
          criado_em_ref: formatDistanceToNow(parsed, {
            addSuffix: true,
            locale: ptBR,
          }),
        };
      });
      setQueryParams(filtros as Record<string, any>);
      setVinculos({
        ...data,
        data: list,
      });
    },
  });

  function handlePagination(pagina = 1, porPagina = 25) {
    setFiltros(filter => ({ ...filter, pagina, porPagina }));
    setQueryParams(filtros as Record<string, any>);
  }

  useEffect(() => {
    if (isLoading) {
      return;
    }

    const qtdeVinculosPendentes = dashboardValues.reduce((prev, post) => {
      if (
        post.id_status_vinculo === EnumStatusVinculo.ENVIO_CONTRATO_PENDENTE ||
        post.id_status_vinculo ===
          EnumStatusVinculo.SOLICITACAO_VINCULO_PENDENTE
      ) {
        return prev + post.total;
      }
      return prev;
    }, 0);

    dispatch(updateQtdeVinculosPendentes(qtdeVinculosPendentes));
  }, [dashboardValues, isLoading]);

  const dashboard = useMemo(() => {
    const size = parseInt(String(24 / dashboardValues.length), 10);

    return dashboardValues.map(item => (
      <Col md={size}>
        <Card
          title={
            item.status_nome ? (
              <Tag color={findTagColorByTipo(item.status_tipo)}>
                {item.status_nome}
              </Tag>
            ) : (
              <Tag>Outro</Tag>
            )
          }
          value={item.total}
          onClick={() =>
            FormFiltro.setFieldsValue({
              id_status_vinculo: [item.id_status_vinculo],
            })
          }
        />
      </Col>
    ));
  }, [dashboardValues]);

  const vinculos_selecionados = useMemo(() => {
    const total = vinculoSelected.length;

    if (total === 0) {
      return null;
    }

    return `${total} vínculo(s) selecionado(s)`;
  }, [vinculoSelected]);

  const button_verification = useMemo(() => {
    if (
      idStatusVinculo === EnumStatusVinculo.SOLICITACAO_VINCULO_PENDENTE &&
      permissao.VINCULO_APROVAR_REPROVAR
    ) {
      return (
        <Space size={10}>
          <Popconfirm
            placement="topLeft"
            title="Tem certeza que deseja aprovar esses vínculo de usuário?"
            onConfirm={() => handleButtonAprovarReprovar(true)}
            okText="Sim"
            cancelText="Não"
          >
            <Button type="primary">Aprovar</Button>
          </Popconfirm>
          <Popconfirm
            placement="topLeft"
            title="Tem certeza que deseja reprovar esses vínculo de usuário?"
            onConfirm={() => setModalReprovar(true)}
            okText="Sim"
            cancelText="Não"
          >
            <Button type="primary" danger>
              Reprovar
            </Button>
          </Popconfirm>
        </Space>
      );
    }

    if (
      idStatusVinculo === EnumStatusVinculo.VINCULO_APROVADA &&
      permissao.VINCULO_REMOVER
    ) {
      return (
        <Space size={10}>
          <Popconfirm
            placement="topLeft"
            title="Tem certeza que deseja remover esse vínculo?"
            onConfirm={() =>
              handleSaveStatus({
                id_status_vinculo: EnumStatusVinculo.VINCULO_REMOVIDA,
              })
            }
            okText="Sim"
            cancelText="Não"
          >
            <Button type="primary" danger>
              Remover
            </Button>
          </Popconfirm>
        </Space>
      );
    }

    return null;
  }, [idStatusVinculo, permissao]);

  async function fetchResponsabilidade() {
    const res = await getResponsabilidade();

    setResponsabilidade(
      res.map(item => ({
        label: item.nome,
        value: item.id_responsabilidade,
      })),
    );
  }

  async function fetchStatusVinculo() {
    const res = await getStatusVinculo();

    setStatusVinculo(
      res.map(item => ({
        label: <Tag color={findTagColorByTipo(item.tipo)}>{item.nome}</Tag>,
        value: item.id_status_vinculo,
      })),
    );
  }

  async function fetchDashboard() {
    return fetchApi({
      url: `/vinculo/dashboard`,
      method: 'get',
      onSuccess: data => {
        setDashboardValues(data);
      },
    });
  }

  function handleUpdateFiltro(data: any, clean = false) {
    if (clean) {
      setFiltros({} as Filters);
      FormFiltro.resetFields();

      return;
    }

    setFiltros(data);
    setQueryParams(data);
  }

  function handleSaveAprovacao({ porcentagem }: any) {
    if (vinculoSelected.length >= 2) {
      message.error('Selecione apenas 1 vínculo!');
      return null;
    }

    if (!vinculoSelected.length) {
      message.error('Selecione ao menos 1 vínculo!');
      return null;
    }

    const [id_usuario_vinculo] = vinculoSelected;

    const usuario_vinculo = vinculos.data.find(
      item => item.id_usuario_vinculo === id_usuario_vinculo,
    );

    return fetchApi({
      url: `/vinculo/${id_usuario_vinculo}`,
      method: 'post',
      messages: {
        loading: 'Salvando aprovação, aguarde...',
        success: 'Aprovação realizada com sucesso!',
        error: 'Erro ao salvar aprovação, tente novamente',
      },
      data: {
        porcentagem,
        aprovado: true,
        id_responsabilidade: usuario_vinculo!.id_responsabilidade,
      },
      onSuccess: () => {
        refetchVinculos();
        fetchDashboard();
        setModalAprovar(false);
        FormAprovar.resetFields();
        setVinculoSelected([]);
        setIdStatusVinculo(null);
      },
    });
  }

  function handleSaveReprovacao({ justificativa }: any) {
    if (vinculoSelected.length >= 2) {
      message.error('Selecione apenas 1 vínculo!');
      return null;
    }

    if (!vinculoSelected.length) {
      message.error('Selecione ao menos 1 vínculo!');
      return null;
    }

    const [id_usuario_vinculo] = vinculoSelected;

    const usuario_vinculo = vinculos.data.find(
      item => item.id_usuario_vinculo === id_usuario_vinculo,
    );

    return fetchApi({
      url: `/vinculo/${id_usuario_vinculo}`,
      method: 'post',
      messages: {
        loading: 'Salvando reprovação, aguarde...',
        success: 'Reprovação realizada com sucesso!',
        error: 'Erro ao salvar reprovação, tente novamente',
      },
      data: {
        justificativa,
        aprovado: false,
        id_responsabilidade: usuario_vinculo!.id_responsabilidade,
      },
      onSuccess: () => {
        refetchVinculos();
        fetchDashboard();
        setModalReprovar(false);
        FormReprovar.resetFields();
        setVinculoSelected([]);
        setIdStatusVinculo(null);
      },
    });
  }

  async function handleSaveStatus({ id_status_vinculo }: any) {
    return fetchApi({
      url: `/vinculo/status`,
      method: 'put',
      messages: {
        loading: 'Alterando status, aguarde...',
        success: 'Status alterado com sucesso!',
        error: 'Erro ao alterar status, tente novamente',
      },
      data: {
        id_status_vinculo,
        id_usuario_vinculo: vinculoSelected,
      },
      onSuccess: () => {
        refetchVinculos();
        fetchDashboard();

        setVinculoSelected([]);
        setIdStatusVinculo(null);
      },
    });
  }

  function handleButtonAprovarReprovar(aprovar: boolean) {
    const [id_usuario_vinculo] = vinculoSelected;

    const usuario_vinculo = vinculos.data.find(
      item => item.id_usuario_vinculo === id_usuario_vinculo,
    );

    if (
      (usuario_vinculo!.id_responsabilidade ===
        EnumResponsabilidade.ESTABELECIMENTO ||
        usuario_vinculo!.id_responsabilidade ===
          EnumResponsabilidade.RESPONSAVEL) &&
      aprovar
    ) {
      return handleSaveAprovacao({ porcentagem: 0 });
    }

    if (aprovar) {
      return setModalAprovar(true);
    }

    return null;
  }

  const columns_vinculo = [
    {
      title: 'Usuário',
      dataIndex: 'usuario_de_nome',
      key: 'usuario_de_nome',
      render: (item: string, data: any) => (
        <Link to={`/usuario/${data.id_usuario_de}`}>{item}</Link>
      ),
    },
    {
      title: 'Responsabilidade',
      dataIndex: 'responsabilidade',
      key: 'responsabilidade',
      width: 200,
    },
    {
      title: 'Estabelecimento/Representante',
      dataIndex: 'usuario_para_nome',
      key: 'usuario_para_nome',
      render: (item: string, data: any) => (
        <Link to={`/usuario/${data.id_usuario_para}`}>{item}</Link>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status_nome',
      key: 'status_nome',
      render: (item: string, data: any) =>
        item ? (
          <Tag color={findTagColorByTipo(data.status_tipo)}>{item}</Tag>
        ) : (
          '-'
        ),
    },
    {
      title: 'Criado Em',
      dataIndex: 'criado_em_ref',
      key: 'criado_em_ref',
      width: 200,
      render: (item: string, data: any) => (
        <Tooltip placement="top" title={data.criado_em}>
          {item}
        </Tooltip>
      ),
    },
  ];

  useEffect(() => {
    const filterParams = {
      ...query_params,
      ...(query_params?.id_responsabilidade && {
        id_responsabilidade: query_params.id_responsabilidade
          ?.split(',')
          .map(id => +id),
      }),
      ...(query_params?.id_status_vinculo && {
        id_status_vinculo: query_params.id_status_vinculo
          ?.split(',')
          .map(id => +id),
      }),
    };

    setFiltros(filterParams as Filters);
    FormFiltro.setFieldsValue(filterParams);
  }, []);

  return (
    <>
      <PageHeader title="Vínculos" breadcrumb={['Vínculos']}>
        <FluxoUsuario />
      </PageHeader>
      <Row gutter={16}>{dashboard}</Row>

      <CardCollapse title="Filtros">
        <Form
          form={FormFiltro}
          layout="vertical"
          onFinish={data => handleUpdateFiltro(data)}
        >
          <Row gutter={16}>
            <Col md={6}>
              <Form.Item label="Nome do Usuário" name="nome_usuario">
                <Input />
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item label="Responsabilidade" name="id_responsabilidade">
                <Select options={responsabilidade} mode="multiple" />
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item
                label="Nome do Estabelecimento/Representante"
                name="nome_estabelecimento"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item label="Status do Vínculo" name="id_status_vinculo">
                <Select options={statusVinculo} mode="multiple" />
              </Form.Item>
            </Col>
          </Row>

          <br />

          <Row justify="end">
            <Space>
              <Button onClick={() => handleUpdateFiltro({}, true)}>
                Limpar Filtros
              </Button>
              <Button type="primary" htmlType="submit">
                Filtrar
              </Button>
            </Space>
          </Row>
        </Form>
      </CardCollapse>
      <b>{vinculos_selecionados}</b>
      <Table
        columns={columns_vinculo}
        dataSource={vinculos.data}
        pagination={{
          total: vinculos.total,
          current: vinculos.pagina,
          pageSize: vinculos.porPagina,
          onChange: handlePagination,
        }}
        rowSelection={{
          hideSelectAll: !vinculoSelected.length,
          type: 'checkbox',
          onChange: (selectedRowKeys, selectedRows) => {
            if (selectedRowKeys.length === 1) {
              setVinculoSelected(selectedRowKeys);
              setIdStatusVinculo(selectedRows[0].id_status_vinculo);
              formChangeStatus.setFieldsValue({
                id_status_vinculo: selectedRows[0].id_status_vinculo,
              });
            } else if (selectedRowKeys.length === 0) {
              setIdStatusVinculo(null);
              formChangeStatus.setFieldsValue({ id_status_vinculo: null });
              setVinculoSelected([]);
            } else if (
              idStatusVinculo === EnumStatusVinculo.SOLICITACAO_VINCULO_PENDENTE
            ) {
              setVinculoSelected([selectedRowKeys[selectedRowKeys.length - 1]]);
            } else {
              setVinculoSelected(selectedRowKeys);
            }
          },
          selectedRowKeys: vinculoSelected,
          getCheckboxProps: record => ({
            disabled:
              idStatusVinculo === null
                ? false
                : record.id_status_vinculo !== idStatusVinculo,
          }),
        }}
        loading={isLoading}
      />
      {button_verification}
      {/* {idStatusVinculo === EnumStatusVinculo.SOLICITACAO_VINCULO_PENDENTE ? (
        <Space size={10}>
          <Popconfirm
            placement="topLeft"
            title="Tem certeza que deseja aprovar esses vínculo de usuário?"
            onConfirm={() => handleButtonAprovarReprovar(true)}
            okText="Sim"
            cancelText="Não"
          >
            <Button type="primary">Aprovar</Button>
          </Popconfirm>
          <Popconfirm
            placement="topLeft"
            title="Tem certeza que deseja reprovar esses vínculo de usuário?"
            onConfirm={() => setModalReprovar(true)}
            okText="Sim"
            cancelText="Não"
          >
            <Button type="primary" danger>
              Reprovar
            </Button>
          </Popconfirm>
        </Space>
      ) : null}

      {idStatusVinculo !== EnumStatusVinculo.ENVIO_CONTRATO_PENDENTE &&
      idStatusVinculo !== EnumStatusVinculo.SOLICITACAO_VINCULO_PENDENTE &&
      idStatusVinculo !== null ? (
        <Form
          layout="vertical"
          form={formChangeStatus}
          onFinish={data => handleSaveStatus(data)}
        >
          <Row gutter={16}>
            <Col md={8}>
              <Form.Item name="id_status_vinculo">
                <Select options={statusVinculo} />
              </Form.Item>
            </Col>
            <Col md={4}>
              <Button
                block
                type="primary"
                htmlType="submit"
                style={{ marginTop: 5 }}
              >
                Alterar Status
              </Button>
            </Col>
          </Row>
        </Form>
      ) : null} */}
      <Modal
        title="Aprovar Usuário"
        visible={modalAprovar}
        onCancel={() => setModalAprovar(false)}
        footer={false}
        centered
      >
        <Form
          layout="vertical"
          form={FormAprovar}
          onFinish={data => handleSaveAprovacao(data)}
        >
          <Row gutter={16}>
            <Col md={24}>
              <Form.Item label="Porcentagem" name="porcentagem">
                <InputNumber
                  min={0}
                  max={100}
                  formatter={value => `${value}%`}
                />
              </Form.Item>
            </Col>
          </Row>

          <br />

          <Row justify="end">
            <Button type="primary" htmlType="submit">
              Salvar
            </Button>
          </Row>
        </Form>
      </Modal>
      <Modal
        title="Reprovar Usuário"
        visible={modalReprovar}
        onCancel={() => setModalReprovar(false)}
        footer={false}
        centered
      >
        <Form
          layout="vertical"
          form={FormReprovar}
          onFinish={data => handleSaveReprovacao(data)}
        >
          <Row gutter={16}>
            <Col md={24}>
              <Form.Item label="Justificativa" name="justificativa">
                <TextArea maxLength={255} showCount rows={4} />
              </Form.Item>
            </Col>
          </Row>

          <br />

          <Row justify="end">
            <Button type="primary" htmlType="submit">
              Salvar
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default VinculoPesquisar;
