import Option from "../../../types/Option";
import { PagePagination } from "../../../types/Pagina";
import { findStatusColor } from "../../Checkout";

export type GetChargesParams = {
  page: number;
  page_size: number;
  id_charge?: string;
  type?: EnumChargeType
}

export type GetChargesResponse = PagePagination<Charge>
export type GetChargeByIdResponse = Charge

export enum EnumChargeType {
  CREDIT_CARD = "CREDIT_CARD",
  DEBIT_CARD = "DEBIT_CARD",
  PIX = "PIX",
  BILL = "BILL",
  TRANSFER = "TRANSFER"
}

export const chargeTypeLabels = {
  BILL: 'Boleto',
  CREDIT_CARD: 'Cartão de crédito',
  DEBIT_CARD: 'Cartão de débito',
  PIX: 'Pix',
  TRANSFER: 'Transferência'
}

export enum EnumChargeStatus {
  RECEIVED = "RECEIVED",
  WAITING_CHARGE = "WAITING_CHARGE",
  CHARGE_DONE = "CHARGE_DONE",
  CHARGE_ERROR = "CHARGE_ERROR",
  CHARGE_REVERSED = "CHARGE_REVERSED",
  CHARGE_CANCELED = "CHARGE_CANCELED",
  CHARGE_DENIED = "CHARGE_DENIED",
  CHARGE_CONFIRMED = "CHARGE_CONFIRMED",
  EXPIRED = "EXPIRED",
  RISK_ANALYSIS_REPROVED = "RISK_ANALYSIS_REPROVED",
  UNKNOWN = "UNKNOWN" 
}

export const chargeStatusOptions: Option[] = [
  {
    label: findStatusColor('Recebido', 'info'),
    value: EnumChargeStatus.RECEIVED
  },
  {
    label: findStatusColor('Aguardando cobrança', 'info'),
    value: EnumChargeStatus.WAITING_CHARGE
  },
  {
    label: findStatusColor('Cobrança feita', 'success'),
    value: EnumChargeStatus.CHARGE_DONE
  },
  {
    label: findStatusColor('Erro na cobrança', 'error'),
    value: EnumChargeStatus.CHARGE_ERROR
  },
  {
    label: findStatusColor('Cobrança estornada', 'warning'),
    value: EnumChargeStatus.CHARGE_REVERSED
  },
  {
    label: findStatusColor('Cobrança cancelada', 'warning'),
    value: EnumChargeStatus.CHARGE_CANCELED
  },
  {
    label: findStatusColor('Cobrança negada', 'error'),
    value: EnumChargeStatus.CHARGE_DENIED
  },
  {
    label: findStatusColor('Cobrança confirmada', 'success'),
    value: EnumChargeStatus.CHARGE_CONFIRMED
  },
  {
    label: findStatusColor('Expirado', 'warning'),
    value: EnumChargeStatus.EXPIRED
  },
  {
    label: findStatusColor('Ánalise de risco aprovado', 'success'),
    value: EnumChargeStatus.RISK_ANALYSIS_REPROVED
  },
  {
    label: findStatusColor('Cobrança desconhecida', 'warning'),
    value: EnumChargeStatus.UNKNOWN
  }
]

export interface Charge {
  id_charge: string
  id_status: EnumChargeStatus
  id_card: string
  checkout_id: string
  type: EnumChargeType
  source: string
  channel: string
  provider_code: string
  provider_name: string
  nsu_code: string
  acquirer: string
  psp: string
  bill_code: string
  pix_qr_code: string
  card_last_four_digits: string
  card_brand: string
  amount_total: number
  installment_number: number
  installment_amount: number
  due_date: string
  created_at: string
  updated_at: string
}