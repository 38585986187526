import styled, { css } from 'styled-components';

import cores from '../../../../styles/colors';

export const Container = styled.div<{ selected: boolean }>`
  margin: 20px 0;
  padding: 20px;
  background: ${cores.background_header};
  border-radius: 5px;
  box-shadow: 0px 0px 10px ${cores.shadow};
  border: 3px solid white;
  cursor: pointer;
  transition: 0.2s;

  ${props =>
    props.selected &&
    css`
      border: 3px solid #5ed46a;
      box-shadow: 0px 0px 20px #5ed46a;
    `}

  .toPrint > &.NotSelectedCard {
    display: none;
  }

  .toPrint > &.SelectedCard {
    border: none;
    box-shadow: none;
    border-radius: 0px;
    border-bottom: 1px solid #ccc;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  h3 {
    font-weight: bold;
    margin-bottom: 0;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const Body = styled.div`
  svg {
    width: 100%;
  }
`;
