import { message } from 'antd';
import download from 'downloadjs';

import api from '../services/api';

type IQueryParams = {
  id_user?: string;
  id_export_file?: string;
};

async function downloadFiles(
  url: string,
  fileName: string,
  type_end_line?: string,
  querys_params?: IQueryParams,
  requestHeaders?: Object,
): Promise<void> {
  message.loading({
    content: 'Preparando arquivo para ser baixado, aguarde...',
    key: 'download',
    duration: 10000,
  });
  try {
    const { data, headers, status } = await api.get(url, {
      headers: requestHeaders,
      responseType: 'blob',
      onDownloadProgress: ({ total, loaded }) => {
        message.loading({
          content: `Preparando arquivo para ser baixado (${(
            (loaded * 100) /
            total
          ).toFixed(2)}%), aguarde...`,
          key: 'download',
          duration: 10000,
        });
      },
      params: { ...querys_params, type_end_line },
    });

    if (status !== 200) throw Error('Erro ao baixar arquivo!');

    await download(data, fileName, headers['content-type']);

    message.destroy();
    // message.success({ content: 'Arquivo pronto para ser baixado!', key: 'download' });
  } catch (err) {
    message.error({
      content: 'Erro ao baixar arquivo, tente novamente!',
      key: 'download',
    });
  }
}

export default downloadFiles;
