import { Col, DatePicker, Form, Row, Select, Table } from 'antd';
import { dinheiroMask } from 'masks-br';
import moment from 'moment';
import React, { useState, useEffect } from 'react';

import Card from '../../../components/Card';
import PageHeader from '../../../components/PageHeader';
import { useApi } from '../../../hooks/useApi';
import Pagina from '../../../types/Pagina';

interface IDivergenciaAdquirencia extends Pagina {
  total_sum: {
    adquirencia: number;
    carrinho: number;
    diferenca: number;
  };
  data: any[];
}

interface IAdquirencia {
  id_adquirencia: number;
  nome: string;
}

const fontSizeValue = '24px';

const DivergenciaAdquirencia: React.FC = () => {
  const ID_PAGSEGURO = 1;
  const [adquirencias, setAdquirencias] = useState([] as IAdquirencia[]);
  const [idAdquirencia, setIdAdquirencia] = useState<number>(ID_PAGSEGURO);
  const [filtroData, setFiltroData] = useState<moment.Moment>(moment());
  const [dataSource, setDataSource] = useState<IDivergenciaAdquirencia>(
    {} as IDivergenciaAdquirencia,
  );

  const { loading, fetchRequest } = useApi();

  useEffect(() => {
    handleFetch();
    handleFetchAdquirencia();
  }, [filtroData, idAdquirencia]);

  function handleFetch(pagina = 1, porPagina = 25) {
    return fetchRequest({
      url: `/relatorio/divergencia_adquirencia/${idAdquirencia}`,
      method: 'get',
      query_params: {
        pagina,
        porPagina,
        data: filtroData.format('YYYY-MM-DD'),
      },
      onSuccess: data => {
        setDataSource(data);
      },
    });
  }

  function handleFetchAdquirencia(pagina = 1, porPagina = 25) {
    return fetchRequest({
      url: '/adquirencia',
      method: 'get',
      query_params: {
        pagina,
        porPagina,
      },
      onSuccess: data => {
        setAdquirencias(
          data.data.map((item: IAdquirencia) => ({
            id_adquirencia: item.id_adquirencia,
            nome: item.nome,
          })),
        );
      },
    });
  }

  return (
    <>
      <PageHeader
        title="Relatório de Divergências de Adquirência"
        breadcrumb={['Relatório', 'Divergências de Adquirência']}
      >
        <Form
          layout="vertical"
          initialValues={{
            adquirencia: idAdquirencia,
          }}
        >
          <Row gutter={5}>
            <Col>
              <Form.Item name="tipo">
                <DatePicker
                  format="DD/MM/YYYY"
                  onChange={val => setFiltroData(val!)}
                  value={filtroData}
                  allowClear={false}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="adquirencia">
                <Select
                  placeholder="Adquirencia"
                  onChange={val => setIdAdquirencia(val)}
                >
                  {adquirencias.map(item => (
                    <Select.Option
                      key={item.id_adquirencia}
                      value={item.id_adquirencia}
                    >
                      {item.nome}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </PageHeader>

      <Row gutter={16}>
        <Col md={8} xs={24}>
          <Card
            title="Adquirência"
            money
            loading={loading}
            value={dataSource?.total_sum?.adquirencia}
            fontSizeValue={fontSizeValue}
          />
        </Col>
        <Col md={8} xs={24}>
          <Card
            title="Carrinhos"
            money
            loading={loading}
            value={dataSource?.total_sum?.carrinho}
            fontSizeValue={fontSizeValue}
          />
        </Col>
        <Col md={8} xs={24}>
          <Card
            title="Diferença"
            money
            loading={loading}
            value={dataSource?.total_sum?.diferenca}
            type={dataSource?.total_sum?.diferenca === 0 ? 'success' : 'danger'}
            fontSizeValue={fontSizeValue}
          />
        </Col>
      </Row>

      <Table
        loading={loading}
        dataSource={dataSource?.data}
        pagination={{
          total: dataSource.total,
          current: dataSource.pagina,
          pageSize: dataSource.porPagina,
          onChange: handleFetch,
          // pageSizeOptions: ['25', '50', '100'],
          showSizeChanger: true,
        }}
        size="small"
        columns={[
          {
            title: 'Bruto',
            dataIndex: 'quantidade_bruta',
            key: 'quantidade_bruta',
            align: 'center',
            render: (val: string) => dinheiroMask(val),
          },
          {
            title: 'Desconto',
            dataIndex: 'quantidade_desconto',
            key: 'quantidade_desconto',
            align: 'center',
            render: (val: string) => dinheiroMask(val),
          },
          {
            title: 'Taxa Parcela',
            dataIndex: 'valor_taxa_parcela',
            key: 'valor_taxa_parcela',
            align: 'center',
            render: (val: string) => dinheiroMask(val),
          },
          {
            title: 'Taxa Intermediação',
            dataIndex: 'valor_taxa_intermediacao',
            key: 'valor_taxa_intermediacao',
            align: 'center',
            render: (val: string) => dinheiroMask(val),
          },
          {
            title: 'Final',
            dataIndex: 'valor_liquido',
            key: 'valor_liquido',
            align: 'center',
            render: (val: string) => dinheiroMask(val),
          },
          {
            width: 160,
            dataIndex: 'criado_em',
            key: 'criado_em',
            render: (val: string) => moment(val).format('DD/MM/YYYY HH:mm:ss'),
          },
        ]}
      />
    </>
  );
};

export default DivergenciaAdquirencia;
