import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Col, Row } from 'antd';
import PageHeader from '../../../components/PageHeader';
import { fetchApi } from '../../../services/api';
import BoletoInfoGeral from './Geral';
import { DataBoletoRaw } from '../types';
import BoletoLiquidacao from './Liquidacao';
import BoletoInfoData from './Data';
import BoletoIndoPagamentos from './Pagamentos';

const BoletoInfo: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const [loading, setLoading] = useState(true);
  const [dataBoletoRaw, setDataBoletoRaw] = useState<DataBoletoRaw>(
    {} as DataBoletoRaw,
  );

  useEffect(() => {
    fetchBoletoInfo();
  }, []);

  function fetchBoletoInfo() {
    return fetchApi({
      url: `/backoffice/bill/${id}`,
      method: 'get',
      headers: {
        'api-version': '1',
      },
      query_params: {
        id_boleto: id,
      },
      onSuccess: data => {
        setLoading(false);
        setDataBoletoRaw(data);
      },
    });
  }

  return (
    <>
      <PageHeader
        title="Informações do Boleto"
        breadcrumb={['Boleto', 'Informação']}
      />
      <BoletoInfoGeral data={dataBoletoRaw} loading={loading} />
      <Row gutter={16}>
        <Col md={12} xs={24}>
          <BoletoInfoData loading={loading} data={dataBoletoRaw} />
        </Col>
        <Col md={12} xs={24}>
          <BoletoIndoPagamentos loading={loading} data={dataBoletoRaw} />
          <BoletoLiquidacao loading={loading} data={dataBoletoRaw} />
        </Col>
      </Row>
    </>
  );
};

export default BoletoInfo;
