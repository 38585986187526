import React, { useEffect, useMemo, useState } from 'react';
import { format, formatDistanceToNow, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import {
  Col,
  Row,
  Tag,
  Form,
  Input,
  Select,
  Button,
  Space,
  Tooltip,
  Popconfirm,
  InputNumber,
  Modal,
  message,
  Popover,
} from 'antd';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { cleanMask, cpfMask, cnpjMask } from 'masks-br';
import Paragraph from 'antd/lib/typography/Paragraph';

import PageHeader from '../../../components/PageHeader';
import { fetchApi } from '../../../services/api';
import Pagina from '../../../types/Pagina';
import CardCollapse from '../../../components/CardCollapse';
import findTagColorByTipo from '../../../utils/findTagColorByTipo';
import { useUtils } from '../../../hooks/useUtils';
import Option from '../../../types/Option';
import Table from '../../../components/Table';
import EnumStatusPermissao from '../../../types/enum/StatusPermissao';
import Card from '../../../components/Card';
import { updateQtdePermissoesPendentes } from '../../../store/modules/utils/actions';
import EnumResponsabilidade from '../../../types/enum/Responsabilidade';
import FluxoUsuario from '../../../components/FluxoUsuario';
import { IState } from '../../../store';
import { IPerfil } from '../../../store/modules/user/reducer';
import EnumPerfil from '../../../types/enum/Perfis';
import { useQueryParams, setQueryParams } from '../../../utils/UrlQuery';
import { usePermission } from '../../../hooks/usePermissions';

const { TextArea } = Input;

// TODO: Adicionar de forma visual o fluxo https://reactflow.dev/
const PermissaoPesquisar: React.FC = () => {
  const is_master = useSelector<IState, IPerfil[]>(
    state => state.user.perfis,
  ).some(perfil => perfil.id_perfil === EnumPerfil.MASTER);

  const [formFiltros] = Form.useForm();
  const [FormAprovar] = Form.useForm();
  const [FormReprovar] = Form.useForm();
  const [formChangeStatus] = Form.useForm();
  const { getResponsabilidade, getStatusPermissao } = useUtils();
  const dispatch = useDispatch();
  const query = useQueryParams();
  const { userPermissions } = usePermission();

  const [dashboardInfo, setDashboardInfo] = useState<any[]>([]);
  const [responsabilidade, setResponsabilidade] = useState<Option[]>([]);
  const [statusPermissao, setStatusPermissao] = useState<Option[]>([]);
  const [permissao, setPermissao] = useState<Pagina>({} as Pagina);
  const [loading, setLoading] = useState(true);
  const [
    idStatusPermissaoSelecionada,
    setIdStatusPermissaoSelecionada,
  ] = useState<number | null>(null);
  const [rowsSelecteds, setRowsSelecteds] = useState<any[]>([]);
  const [modalAprovar, setModalAprovar] = useState(false);
  const [modalReprovar, setModalReprovar] = useState(false);
  const [filtros, setFiltros] = useState<any>(undefined);

  const usuarios_selecionados = useMemo(() => {
    const total = rowsSelecteds.length;

    if (total === 0) {
      return null;
    }

    return `${total} cliente(s) selecionado(s)`;
  }, [rowsSelecteds]);

  const dashboard = useMemo(() => {
    const size = parseInt(String(24 / dashboardInfo.length), 10);

    return dashboardInfo.map(item => (
      <Col md={size}>
        <Card
          title={
            item.status_nome ? (
              <Tag color={findTagColorByTipo(item.status_tipo)}>
                {item.status_nome}
              </Tag>
            ) : (
              <Tag>Outro</Tag>
            )
          }
          value={item.total}
          onClick={() =>
            formFiltros.setFieldsValue({
              id_status_permissao: [item.id_status_permissao],
            })
          }
        />
      </Col>
    ));
  }, [dashboardInfo]);

  useMemo(() => {
    if (loading) {
      return null;
    }

    const qtdePermissoesPendentes = dashboardInfo.reduce((prev, post) => {
      if (
        post.id_status_permissao ===
          EnumStatusPermissao.ENVIO_CONTRATO_PENDENTE ||
        post.id_status_permissao ===
          EnumStatusPermissao.SOLICITACAO_PERMISSAO_PENDENTE
      ) {
        return prev + post.total;
      }
      return prev;
    }, 0);

    return dispatch(updateQtdePermissoesPendentes(qtdePermissoesPendentes));
  }, [dashboardInfo, loading]);

  const table_bottom_buttons = useMemo(() => {
    if (
      idStatusPermissaoSelecionada ===
        EnumStatusPermissao.SOLICITACAO_PERMISSAO_PENDENTE &&
      userPermissions.PERMISSAO_APROVAR_REPROVAR
    ) {
      return (
        <Space size={10}>
          <Popconfirm
            placement="topLeft"
            title="Tem certeza que deseja aprovar esses usuários?"
            onConfirm={() => handleOpenAprovacao(true)}
            okText="Sim"
            cancelText="Não"
            disabled={!is_master}
          >
            <Button type="primary" disabled={!is_master}>
              Aprovar
            </Button>
          </Popconfirm>
          <Popconfirm
            placement="topLeft"
            title="Tem certeza que deseja reprovar esses usuários?"
            onConfirm={() => handleOpenAprovacao(false)}
            okText="Sim"
            cancelText="Não"
            disabled={!is_master}
          >
            <Button type="primary" danger disabled={!is_master}>
              Reprovar
            </Button>
          </Popconfirm>
        </Space>
      );
    }

    if (
      idStatusPermissaoSelecionada ===
        EnumStatusPermissao.ENVIO_CONTRATO_PENDENTE &&
      userPermissions.PERMISSAO_ENVIAR_CONTRATO
    ) {
      return (
        <Space size={10}>
          <Button
            type="primary"
            onClick={() => handleEnviarContrato()}
            disabled={!is_master}
          >
            Enviar Contrato
          </Button>
        </Space>
      );
    }

    if (
      idStatusPermissaoSelecionada ===
        EnumStatusPermissao.ASSINATURA_CONTRATO_PENDENTE &&
      userPermissions.PERMISSAO_ALTERAR_STATUS
    ) {
      return (
        <>
          <Space size={10}>
            <Popconfirm
              placement="topLeft"
              title="Tem certeza que deseja tomar essa ação?"
              onConfirm={() =>
                handleSaveStatus({
                  id_status_permissao: EnumStatusPermissao.CONTRATO_ASSINADO,
                })
              }
              okText="Sim"
              cancelText="Não"
              disabled={!is_master}
            >
              <Button type="primary" disabled={!is_master}>
                Contrato Assinado
              </Button>
            </Popconfirm>
            <Popconfirm
              placement="topLeft"
              title="Tem certeza que deseja tomar essa ação?"
              onConfirm={() =>
                handleSaveStatus({
                  id_status_permissao: EnumStatusPermissao.CONTRATO_CANCELADO,
                })
              }
              okText="Sim"
              cancelText="Não"
              disabled={!is_master}
            >
              <Button type="primary" danger disabled={!is_master}>
                Contrato Cancelado
              </Button>
            </Popconfirm>
          </Space>
        </>
      );
    }

    if (
      idStatusPermissaoSelecionada ===
        EnumStatusPermissao.CRIACAO_CONTA_BANCARIA_PENDENTE &&
      userPermissions.PERMISSAO_ALTERAR_STATUS
    ) {
      return (
        <>
          <Space size={10}>
            <Popconfirm
              placement="topLeft"
              title="Tem certeza que deseja tomar essa ação?"
              onConfirm={() =>
                handleSaveStatus({
                  id_status_permissao:
                    EnumStatusPermissao.CONTA_BANCARIA_APROVADA,
                })
              }
              okText="Sim"
              cancelText="Não"
              disabled={!is_master}
            >
              <Button type="primary" disabled={!is_master}>
                Conta Bancária Aprovada
              </Button>
            </Popconfirm>
            <Popconfirm
              placement="topLeft"
              title="Tem certeza que deseja tomar essa ação?"
              onConfirm={() =>
                handleSaveStatus({
                  id_status_permissao:
                    EnumStatusPermissao.CONTA_BANCARIA_REPROVADA,
                })
              }
              okText="Sim"
              cancelText="Não"
              disabled={!is_master}
            >
              <Button type="primary" danger disabled={!is_master}>
                Conta Bancária Reprovada
              </Button>
            </Popconfirm>
          </Space>
        </>
      );
    }

    return null;
  }, [
    idStatusPermissaoSelecionada,
    statusPermissao,
    formChangeStatus,
    is_master,
  ]);

  useEffect(() => {
    const url_filtros = {
      pagina: 1,
      porPagina: 25,
      ...query,
      id_status_permissao: query.id_status_permissao
        ? query?.id_status_permissao?.split(',').map(item => Number(item))
        : [],
      id_responsabilidade: query.id_responsabilidade
        ? query?.id_responsabilidade?.split(',').map(item => Number(item))
        : [],
    };

    setFiltros(url_filtros);
    formFiltros.setFieldsValue(url_filtros);

    Promise.all([
      fetchResponsabilidade(),
      fetchStatusPermissao(),
      fetchDashboard(),
    ]);
  }, []);

  useEffect(() => {
    setInitialValues();
    if (filtros) {
      fetchPermissaoPendentes();
    }
  }, [filtros]);

  function fetchPermissaoPendentes() {
    setLoading(true);

    const query_params = {
      ...filtros,
      id_status_permissao: filtros?.id_status_permissao?.length
        ? filtros?.id_status_permissao
        : undefined,
      id_responsabilidade: filtros?.id_responsabilidade?.length
        ? filtros?.id_responsabilidade
        : undefined,
      documento: filtros.documento ? cleanMask(filtros.documento) : undefined,
    };

    return fetchApi({
      url: `/permissao`,
      method: 'get',
      query_params,
      onError: () => setLoading(false),
      onSuccess: data => {
        const list = data.data.map((item: any) => {
          const parsed = parseISO(item.criado_em);

          return {
            ...item,
            key: item.id_usuario_permissao,
            criado_em: format(parsed, 'dd/MM/yyyy HH:mm:ss'),
            criado_em_ref: formatDistanceToNow(parsed, {
              addSuffix: true,
              locale: ptBR,
            }),
            status_nome_id_aprovacao: {
              status_nome: item.status_nome,
              id_aprovacao: item?.id_aprovacao,
            },
          };
        });

        setLoading(false);
        setPermissao({
          ...data,
          data: list,
        });
      },
    });
  }

  async function fetchResponsabilidade() {
    const respon = await getResponsabilidade();

    setResponsabilidade(
      respon.map(item => ({
        label: item.nome,
        value: item.id_responsabilidade,
      })),
    );
  }

  async function fetchStatusPermissao() {
    const respon = await getStatusPermissao();

    setStatusPermissao(
      respon.map(item => ({
        label: <Tag color={findTagColorByTipo(item.tipo)}>{item.nome}</Tag>,
        value: item.id_status_permissao,
      })),
    );
  }

  async function fetchDashboard() {
    return fetchApi({
      url: `/permissao/dashboard`,
      method: 'get',
      onSuccess: data => {
        setDashboardInfo(data);
      },
    });
  }

  async function handleSaveStatus({ id_status_permissao }: any) {
    return fetchApi({
      url: `/permissao/status`,
      method: 'put',
      messages: {
        loading: 'Alterando status, aguarde...',
        success: 'Status alterado com sucesso!',
        error: 'Erro ao alterar status, tente novamente',
      },
      data: {
        id_status_permissao,
        id_usuario_permissao: rowsSelecteds,
      },
      onSuccess: () => {
        fetchPermissaoPendentes();
        fetchDashboard();

        setInitialValues();
      },
    });
  }

  function handleOpenAprovacao(aprovado: boolean) {
    if (!aprovado) {
      setModalReprovar(true);
      return null;
    }

    if (!rowsSelecteds.length) {
      message.warn('Selecione ao menos 1 usuário');
      return null;
    }

    const usuario = permissao.data.find(item => item.key === rowsSelecteds[0]);

    if (!usuario) {
      return null;
    }

    if (usuario.id_responsabilidade === EnumResponsabilidade.ESTABELECIMENTO) {
      setModalAprovar(true);
      return null;
    }

    return handleSaveAprovacao(true, {});
  }

  async function handleSaveAprovacao(
    aprovado: boolean,
    { justificativa, porcentagem }: any,
  ) {
    return fetchApi({
      url: `/permissao/aprovacao`,
      method: 'post',
      messages: {
        loading: 'Salvando aprovação, aguarde...',
        success: 'Aprovação realizada com sucesso!',
        error: 'Erro ao salvar aprovação, tente novamente',
      },
      data: {
        justificativa,
        id_usuario_permissao: rowsSelecteds,
        aprovado,
        comissao: porcentagem,
      },
      onSuccess: () => {
        fetchPermissaoPendentes();
        fetchDashboard();

        setInitialValues();
      },
    });
  }

  function handleUpdateFiltro(data: any, clean = false) {
    if (clean) {
      data = undefined;

      formFiltros.resetFields();
    }

    setQueryParams(data);
    setFiltros(data || {});
  }

  function updatePagina(pagina: any, porPagina: any) {
    const final = { ...filtros, pagina, porPagina };

    setQueryParams(final);
    setFiltros(final);
  }

  async function handleEnviarContrato() {
    const [id_usuario_permissao] = rowsSelecteds;

    return fetchApi({
      url: `/permissao/${id_usuario_permissao}/contrato`,
      method: 'post',
      messages: {
        loading: 'Enviando contrato, aguarde...',
        success: 'Contrato enviado com sucesso!',
        error: 'Erro ao enviar contrato, tente novamente',
      },
      onSuccess: () => {
        fetchPermissaoPendentes();
        fetchDashboard();

        setInitialValues();
      },
    });
  }

  function setInitialValues() {
    setRowsSelecteds([]);
    setIdStatusPermissaoSelecionada(null);
    setModalAprovar(false);
    setModalReprovar(false);
    FormAprovar.resetFields();
    FormReprovar.resetFields();
  }

  function updateDocument(val: any) {
    const cleaned = cleanMask(val);

    const documento =
      cleaned.length <= 11 ? cpfMask(cleaned) : cnpjMask(cleaned);

    formFiltros.setFieldsValue({ documento });
  }

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'usuario_nome',
      key: 'usuario_nome',
      render: (item: string, data: any) => (
        <Link to={`/usuario/${data.id_usuario}`}>{item}</Link>
      ),
    },
    {
      title: 'Responsabilidade',
      dataIndex: 'responsabilidade',
      key: 'responsabilidade',
    },
    {
      title: 'Status',
      dataIndex: 'status_nome_id_aprovacao',
      key: 'status_nome_id_aprovacao',
      render: (item: any, data: any) => {
        return item.status_nome === 'Permissão Aprovada' ? (
          <Popover
            content={
              item.id_aprovacao ? (
                <Paragraph copyable>{item.id_aprovacao}</Paragraph>
              ) : (
                'Não possui id de aprovacao.'
              )
            }
            title="Id Aprovação"
            trigger="click"
          >
            <Button
              style={{ border: 'none', background: 'none', boxShadow: 'none' }}
            >
              <Tag color={findTagColorByTipo(data.status_tipo)}>
                {item.status_nome}
              </Tag>
            </Button>
          </Popover>
        ) : (
          <Tag color={findTagColorByTipo(data.status_tipo)}>
            {item.status_nome}
          </Tag>
        );
      },
    },
    {
      title: 'Estado',
      dataIndex: 'estado',
      key: 'estado',
    },
    {
      title: 'Cidade',
      dataIndex: 'cidade',
      key: 'cidade',
    },
    {
      title: 'Criado Em',
      dataIndex: 'criado_em_ref',
      key: 'criado_em_ref',
      render: (item: string, data: any) => (
        <Tooltip title={data.criado_em}>{item}</Tooltip>
      ),
    },
  ];

  return (
    <>
      <PageHeader title="Permissões" breadcrumb={['Permissões']}>
        <FluxoUsuario />
      </PageHeader>

      <Row gutter={16}>{dashboard}</Row>

      <CardCollapse title="Filtros">
        <Form
          form={formFiltros}
          layout="vertical"
          onFinish={data => handleUpdateFiltro(data)}
        >
          <Row gutter={16}>
            <Col md={8}>
              <Form.Item label="Nome" name="nome">
                <Input />
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item label="Responsabilidade" name="id_responsabilidade">
                <Select options={responsabilidade} mode="multiple" />
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item label="Status" name="id_status_permissao">
                <Select options={statusPermissao} mode="multiple" />
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item label="Estado" name="estado">
                <Input />
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item label="Cidade" name="cidade">
                <Input />
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item label="Documento" name="documento">
                <Input onChange={val => updateDocument(val.target.value)} />
              </Form.Item>
            </Col>
          </Row>

          <br />

          <Row justify="end">
            <Space size={10}>
              <Button onClick={() => handleUpdateFiltro({}, true)}>
                Limpar Filtros
              </Button>
              <Button type="primary" htmlType="submit">
                Filtrar
              </Button>
            </Space>
          </Row>
        </Form>
      </CardCollapse>

      <b>{usuarios_selecionados}</b>

      <Table
        columns={columns}
        loading={loading}
        dataSource={permissao.data}
        pagination={{
          current: permissao.pagina,
          pageSize: permissao.porPagina,
          total: permissao.total,
          onChange: updatePagina,
        }}
        rowSelection={{
          hideSelectAll: !rowsSelecteds.length,
          type: 'checkbox',
          selectedRowKeys: rowsSelecteds,
          onChange: (selectedRowKeys: React.Key[], selectedRows) => {
            if (selectedRowKeys.length === 1) {
              setRowsSelecteds(selectedRowKeys);
              setIdStatusPermissaoSelecionada(
                selectedRows[0].id_status_permissao,
              );
              formChangeStatus.setFieldsValue({
                id_status_permissao: selectedRows[0].id_status_permissao,
              });
            } else if (selectedRowKeys.length === 0) {
              setRowsSelecteds([]);
              setIdStatusPermissaoSelecionada(null);
              formChangeStatus.setFieldsValue({ id_status_permissao: null });
            } else if (
              selectedRows.find(
                item =>
                  item.id_status_permissao ===
                  EnumStatusPermissao.ENVIO_CONTRATO_PENDENTE,
              )
            ) {
              setRowsSelecteds([selectedRowKeys[selectedRowKeys.length - 1]]);
            } else if (
              selectedRows.find(
                item =>
                  item.id_responsabilidade ===
                    EnumResponsabilidade.ESTABELECIMENTO &&
                  item.id_status_permissao ===
                    EnumStatusPermissao.SOLICITACAO_PERMISSAO_PENDENTE,
              )
            ) {
              setRowsSelecteds([selectedRowKeys[selectedRowKeys.length - 1]]);
            } else {
              setRowsSelecteds(selectedRowKeys);
            }
          },
          getCheckboxProps: record => ({
            disabled:
              idStatusPermissaoSelecionada === null
                ? false
                : record.id_status_permissao !== idStatusPermissaoSelecionada,
          }),
        }}
      />

      {table_bottom_buttons}

      <Modal
        title="Aprovar Usuário"
        visible={modalAprovar}
        onCancel={() => setModalAprovar(false)}
        footer={false}
        centered
      >
        <Form
          layout="vertical"
          form={FormAprovar}
          onFinish={data => handleSaveAprovacao(true, data)}
        >
          <Row gutter={16}>
            <Col md={24}>
              <Form.Item label="Porcentagem" name="porcentagem">
                <InputNumber
                  min={0}
                  max={100}
                  formatter={value => `${value}%`}
                />
              </Form.Item>
            </Col>
          </Row>

          <br />

          <Row justify="end">
            <Button type="primary" htmlType="submit">
              Salvar
            </Button>
          </Row>
        </Form>
      </Modal>

      <Modal
        title="Reprovar Usuário"
        visible={modalReprovar}
        onCancel={() => setModalReprovar(false)}
        footer={false}
        centered
      >
        <Form
          layout="vertical"
          form={FormReprovar}
          onFinish={data => handleSaveAprovacao(false, data)}
        >
          <Row gutter={16}>
            <Col md={24}>
              <Form.Item label="Justificativa" name="justificativa">
                <TextArea maxLength={255} showCount rows={4} />
              </Form.Item>
            </Col>
          </Row>

          <br />

          <Row justify="end">
            <Button type="primary" htmlType="submit">
              Salvar
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default PermissaoPesquisar;
