import React, { useEffect, useMemo, useState } from 'react';
import { Button, Form, Row, Col, Select, DatePicker, Tag } from 'antd';
import { format } from 'date-fns';
import { dinheiroMask } from 'masks-br';
import { MdOpenInNew } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { ColumnsType } from 'antd/lib/table';
import 'moment/locale/pt-br';

import PageHeader from '../../../components/PageHeader';
import Table from '../../../components/Table';
import CardCollapse from '../../../components/CardCollapse';

import * as usePaymentLinks from '../hooks/usePaymentLinks';
import {
  EnumStatusPaymentLink,
  GetPaymentLinksQueryParams,
  OptionsStatusPaymentLink,
} from '../services/types/links';
import { useSelector } from 'react-redux';
import { IState } from '../../../store';
import TipoAcesso, { EnumTipoAcesso } from '../../../types/TipoAcesso';
import UserName from '../../../components/User/Name';
import ItemInputCurrency from '../../../components/Form/ItemInputCurrency';
import { ItemInputBarcode } from '../../../components/Form';
import { setQueryParams, useQueryParams } from '../../../utils/UrlQuery';
import moment, { Moment } from 'moment';
import { useEstablishmentsByUserAndResponsabilidade } from '../../Estabelecimento/hooks/useEstablishments';
import EnumResponsabilidade from '../../../types/enum/Responsabilidade';

const { RangePicker } = DatePicker;

interface ParamsFilter {
  id_status?: EnumStatusPaymentLink;
  date?: Moment[];
  amount?: string;
  code?: string;
  pageSize?: number;
  page?: number;
}

const PaymentLinksSearch: React.FC = () => {
  const [form] = Form.useForm();
  const tipo_acesso = useSelector<IState, TipoAcesso | undefined>(
    state => state.auth.tipo,
  );
  const userId = useSelector<IState, string>(
    state => state.user.id_usuario as string,
  );
  const resposibilityType = useSelector<
    IState,
    EnumResponsabilidade | undefined
  >(state => state.auth.responsabilidade);

  const profileIsRepresentative =
    resposibilityType === EnumResponsabilidade.REPRESENTANTE;

  const { data: establishments } = useEstablishmentsByUserAndResponsabilidade(
    1,
    25,
    userId,
    EnumResponsabilidade.REPRESENTANTE,
    profileIsRepresentative,
  );
  const query_values = useQueryParams();

  const isAdmin = useMemo(() => {
    return tipo_acesso === EnumTipoAcesso.ADMIN;
  }, [tipo_acesso]);

  const [filtros, setFiltros] = useState<GetPaymentLinksQueryParams>({
    page: 1,
    page_size: 10,
  });

  const { data: paymentLinks, isLoading } = usePaymentLinks.usePaymentLinks(
    filtros,
    isAdmin,
  );

  function handleResetForm() {
    form.resetFields();
    setFiltros({
      page: 1,
      page_size: 10,
    });
  }

  function handleSetFiltroValues(data: ParamsFilter) {
    const [data_inicio, data_fim] = [...(data?.date || [])];
    const { pageSize: page_size, date, ...restData } = data;

    const treatedFilter: GetPaymentLinksQueryParams = {
      ...filtros,
      ...restData,
      ...(page_size && { page_size }),
      start_date: data_inicio ? data_inicio.format('YYYY-MM-DD') : undefined,
      end_date: data_fim ? data_fim.format('YYYY-MM-DD') : undefined,
    };

    setFiltros(treatedFilter);
    setQueryParams(treatedFilter);
  }

  function handleUpdatePagination(page: number, pageSize?: number) {
    const treatedFilter = {
      ...filtros,
      page,
      ...(pageSize && { page_size: pageSize }),
    };
    setFiltros(treatedFilter);
    setQueryParams(treatedFilter);
  }

  const columns: ColumnsType<any> = [
    {
      title: 'Valor',
      key: 'total_amount',
      dataIndex: 'total_amount',
      align: 'center',
      render: original_amount => dinheiroMask(original_amount),
    },
    {
      title: 'Status',
      key: 'id_status',
      dataIndex: 'id_status',
      align: 'center',
      render: (id_status: EnumStatusPaymentLink) => {
        switch (id_status) {
          case EnumStatusPaymentLink.CREATED:
            return <Tag color="blue">Criado</Tag>;

          case EnumStatusPaymentLink.CANCELED:
            return <Tag color="red">Cancelado</Tag>;

          case EnumStatusPaymentLink.PAYED:
            return <Tag color="green">Pago</Tag>;

          case EnumStatusPaymentLink.EXPIRED:
            return <Tag color="gold">Expirado</Tag>;

          default:
            return <Tag color="gray">Indefinido</Tag>;
        }
      },
    },
    isAdmin
      ? {
          title: 'Estabelecimento',
          key: 'establishment_id',
          dataIndex: 'establishment_id',
          align: 'center',
          render: (establishment_id: string) => (
            <UserName id_user={establishment_id} />
          ),
        }
      : {
          title: 'Data de Expiração',
          key: 'expiration_date',
          dataIndex: 'expiration_date',
          align: 'center',
          render: (expiration_date?: string) =>
            expiration_date
              ? format(new Date(expiration_date), 'dd/MM/yyyy HH:mm')
              : 'Não configurado',
        },
    {
      title: 'Criado Em',
      key: 'created_at',
      dataIndex: 'created_at',
      align: 'center',
      render: (created_at: string) =>
        format(new Date(created_at), 'dd/MM/yyyy HH:mm'),
    },
    {
      title: 'Abrir',
      width: 80,
      align: 'center',
      render: (data: any) => (
        <Link
          to={`/link/${data.id_payment_link}`}
          className="ant-btn ant-btn-primary"
        >
          <MdOpenInNew />
        </Link>
      ),
    },
  ];

  useEffect(() => {
    let date: [moment.Moment, moment.Moment] | undefined;

    if (query_values.start_date && query_values.end_date) {
      date = [moment(query_values.start_date), moment(query_values.end_date)];
    }

    setFiltros({
      ...filtros,
      ...query_values,
    });

    form.setFieldsValue({ ...query_values, date });
  }, []);

  return (
    <>
      <PageHeader title="Links de Pagamentos" breadcrumb={['Links']} />

      {!isAdmin ? (
        <Row justify="end">
          <Link to={`/link/create`} className="ant-btn ant-btn-primary">
            Criar novo link
          </Link>
        </Row>
      ) : null}

      <CardCollapse title="Filtros">
        <Form
          layout="vertical"
          onFinish={data => handleSetFiltroValues(data)}
          form={form}
        >
          <Row gutter={16}>
            {profileIsRepresentative && (
              <Col md={8} xs={12}>
                <Form.Item name="establishment_id" label="Estabelecimento">
                  <Select placeholder="Selecione um estabelecimento">
                    {establishments?.data?.map(estabelecimento => (
                      <Select.Option value={estabelecimento.id_usuario}>
                        {estabelecimento.nome}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            <Col md={8} xs={12}>
              <Form.Item name="id_status" label="Status">
                <Select
                  options={OptionsStatusPaymentLink}
                  placeholder="Status do link de pagamento"
                  allowClear
                />
              </Form.Item>
            </Col>

            <Col md={5} xs={10}>
              <Form.Item name="amount" label="Valor">
                <ItemInputCurrency
                  onChange={(_, val) => form.setFieldsValue({ amount: val })}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col md={8} xs={12}>
              <Form.Item name="code" label="Código de Barras">
                <ItemInputBarcode form={form} formItemName="code" />
              </Form.Item>
            </Col>
            <Col md={8} xs={12}>
              <Form.Item name="date" label="Data">
                <RangePicker
                  format="DD/MM/YYYY"
                  placeholder={['Data Inicio', 'Data Fim']}
                  allowClear
                />
              </Form.Item>
            </Col>
          </Row>

          <br />

          <Row justify="end">
            <Button onClick={handleResetForm} className="buttonMarginRight">
              Limpar
            </Button>
            <Button type="primary" htmlType="submit">
              Filtrar
            </Button>
          </Row>
        </Form>
      </CardCollapse>

      <Table
        columns={columns}
        dataSource={paymentLinks?.data}
        loading={isLoading}
        size="small"
        pagination={{
          total: paymentLinks?.total,
          current: paymentLinks?.page,
          pageSize: paymentLinks?.page_size,
          onChange: handleUpdatePagination,
        }}
      />
    </>
  );
};

export default PaymentLinksSearch;
