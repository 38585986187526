import React from 'react';
import { Result, Layout, Button } from 'antd';
import { FallbackProps } from 'react-error-boundary';
import { HomeOutlined } from '@ant-design/icons';

const ErrorPage: React.FC<FallbackProps> = () => {

  function handleGoHome() {
    window.location.pathname = '/dashboard'
  }

  return (
    <>
      <Layout
        style={{
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Result
          title="Ops, um erro inesperado aconteceu!"
          subTitle="Por favor, recarregue a página novamente e verifique se não está fazendo algo errado!"
          status="500"
        />
        <Button
          type="link"
          style={{ marginLeft: 'auto', marginRight: 'auto' }}
          icon={<HomeOutlined />}
          onClick={handleGoHome}
        >
          Voltar para página inicial
        </Button>
      </Layout>
    </>
  );
};

export default ErrorPage;
