import React from 'react';
import { Tag } from 'antd';
import { EnumSettlementType } from '../pages/Boleto/types';

export const regexBillet = /^[0-9]{44,48}$/;

export const errorInvalidDigitableLineMessage = 'Linha digitável inválida';

export const removeAccentsAndSpecialCharacteres = (str: string) => {
  return str.replace(/[^a-zA-Z0-9]/g, '');
};

export const checkDigitableLineBankslipRegex = (digitable_line: string) => {
  const cleanDigitableLine = removeAccentsAndSpecialCharacteres(digitable_line);
  return (
    regexBillet.test(cleanDigitableLine) || errorInvalidDigitableLineMessage
  );
};

export const validatorDigitableLineFormItemRule = (digitable_line: string) => {
  const cleanDigitableLine = removeAccentsAndSpecialCharacteres(digitable_line);

  const isValidDigitableLine = regexBillet.test(cleanDigitableLine);

  if (isValidDigitableLine) return Promise.resolve();

  return Promise.reject(errorInvalidDigitableLineMessage);
};

export function getSettlementType(id_settlement_type: EnumSettlementType) {
  switch (id_settlement_type) {
    case EnumSettlementType.BANK:
      return <Tag color="blue">Bancário</Tag>;
    case EnumSettlementType.PIX:
      return <Tag color="blue">Pix</Tag>;
    case EnumSettlementType.RETURN_FILE:
      return <Tag color="blue">Arquivo de Retorno</Tag>;
    default:
      return <Tag>Não parametrizado</Tag>;
  }
}
