import React, { useEffect, useState } from 'react';
import { Table, Image, Tag, Modal, Form, Button, Select, message } from 'antd';
import PageHeader from '../../../components/PageHeader';
import { useApi } from '../../../hooks/useApi';
import Paragraph from 'antd/lib/typography/Paragraph';
import { BsArrowUpRightSquareFill } from 'react-icons/bs';
import TextArea from 'antd/lib/input/TextArea';
import { fetchApi } from '../../../services/api';

const DetranPesquisar: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [detran, setDetran] = useState<any[]>([]);
  const [detranToEdit, setDetranToEdit] = useState<any>(null);

  const { fetchRequest, loading } = useApi();

  useEffect(() => {
    fetchRequest({
      method: 'get',
      url: '/detran',
      onSuccess: data => {
        console.log(data.data);
        setDetran(data.data);
      },
    });
  }, []);

  const columns = [
    {
      dataIndex: 'image_src',
      key: 'image_src',
      render: (item: string) => <Image width={50} src={item} preview={false} />,
    },
    {
      title: 'ID',
      dataIndex: 'id_detran',
      key: 'id_detran',
      render: (item: string) => (
        <Paragraph copyable={{ text: item }}> {item} </Paragraph>
      ),
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      title: 'UF',
      dataIndex: 'uf',
      key: 'uf',
      sorter: (a: any, b: any) => a.uf.localeCompare(b.uf),
    },
    {
      title: 'Ambiente',
      dataIndex: 'ambient',
      key: 'ambient',
      render: (item: string) => handleAmbientTag(item),
    },
    {
      title: 'Descrição',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Link',
      dataIndex: 'link',
      key: 'link',
      render: (item: string) => (
        <a href={item} target="_blank">
          <BsArrowUpRightSquareFill size={28} />
        </a>
      ),
    },

    {
      title: 'Edit',
      dataIndex: 'id_detran',
      key: 'id_detran',
      render: (item: string) => {
        return (
          <Button type="primary" onClick={() => showModal(item)}>
            Editar
          </Button>
        );
      },
    },
  ];

  function handleAmbientTag(ambient: string) {
    switch (ambient) {
      case 'PRODUCAO':
        return <Tag color="green">PRODUCAO</Tag>;
      case 'HOMOLOGACAO':
        return <Tag color="blue">HOMOLOGACAO</Tag>;
      case 'TESTE':
        return <Tag color="orange">TESTE</Tag>;
      case 'DESENVOLVIMENTO':
        return <Tag color="purple">DESENVOLVIMENTO</Tag>;
      case 'POC':
        return <Tag color="red">POC</Tag>;
      default:
        return <Tag color="purple">DESENVOLVIMENTO</Tag>;
    }
  }

  const showModal = (id_detran: string) => {
    setDetranToEdit(detran.find(item => item.id_detran === id_detran));
    setOpen(true);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  const onFinish = async (data: any) => {
    fetchApi({
      method: 'put',
      url: `/detran/${detranToEdit?.id_detran}`,
      data: {
        ambient: data?.ambient,
        description: data?.description,
      },
      onSuccess: async () => {
        await fetchRequest({
          method: 'get',
          url: '/detran',
          onSuccess: data => {
            setDetran(data.data);
          },
        });
      },
      onError: () => {
        message.error('Erro ao atualizar o Detran');
      },
    });
    handleCancel();
  };

  return (
    <>
      <Modal
        title={`Detran ${detranToEdit?.name}` || 'Detran'}
        visible={open}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          onFinish={onFinish}
          initialValues={detranToEdit}
        >
          <Form.Item label="Ambiente" name="ambient">
            <Select placeholder="Selecione o ambiente">
              <Select.Option value="PRODUCAO">
                <Tag color="green">PRODUCAO</Tag>
              </Select.Option>
              <Select.Option value="HOMOLOGACAO">
                <Tag color="blue">HOMOLOGACAO</Tag>
              </Select.Option>
              <Select.Option value="TESTE">
                <Tag color="orange">TESTE</Tag>
              </Select.Option>
              <Select.Option value="DESENVOLVIMENTO">
                <Tag color="purple">DESENVOLVIMENTO</Tag>
              </Select.Option>
              <Select.Option value="POC">
                <Tag color="red">POC</Tag>
              </Select.Option>
            </Select>
          </Form.Item>

          <Form.Item label="Descrição" name="description">
            <TextArea rows={4} placeholder="Insira uma descrição" />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Salvar
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <PageHeader title="Detran" breadcrumb={['Detran', 'Pesquisar']} />

      <Table
        columns={columns}
        dataSource={detran}
        pagination={false}
        loading={loading}
        size="small"
      />
    </>
  );
};

export default DetranPesquisar;
