import React, { useEffect, useState } from 'react';
import { Col, Row, DatePicker, Table, Divider } from 'antd';
import { dinheiroMask } from 'masks-br';
import moment from 'moment';
import { Link } from 'react-router-dom';

import CardCollapse from '../../../components/CardCollapse';
import PageHeader from '../../../components/PageHeader';
import { useApi } from '../../../hooks/useApi';
import EnumStatusPermissao from '../../../types/enum/StatusPermissao';
import EnumResponsabilidade from '../../../types/enum/Responsabilidade';

import { Filtro } from '../index';

const { RangePicker } = DatePicker;

type IRelatorioGeral = {
  representante_qtde_by_estado: any[];
  estabelecimento_without_revenue: any[];
  estabelecimento: any[];
  representante: any[];
  estabelecimento_qtde_by_estado: any[];
  faturamento_by_estado: any[];
  representante_without_revenue: any[];
};

const pagination = {
  defaultPageSize: 10,
  pageSizeOptions: ['10', '25', '50', '100'],
  showSizeChanger: true,
};

const regiao_filter = {
  filters: [
    {
      text: 'Norte',
      value: 'Norte',
    },
    {
      text: 'Nordeste',
      value: 'Nordeste',
    },
    {
      text: 'Centro Oeste',
      value: 'Centro Oeste',
    },
    {
      text: 'Sudeste',
      value: 'Sudeste',
    },
    {
      text: 'Sul',
      value: 'Sul',
    },
  ],
  filterMultiple: false,
  onFilter: (value: any, record: any) => record.regiao.indexOf(value) === 0,
};

const por_estado_summary = (pageData: any) => {
  const qtde = pageData.reduce(
    (prev: number, curr: any) => prev + curr.qtde,
    0,
  );

  return (
    <>
      <Table.Summary.Row>
        <Table.Summary.Cell index={1}>
          <b>Total</b>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={1} />
        <Table.Summary.Cell index={1} align="center">
          {qtde}
        </Table.Summary.Cell>
      </Table.Summary.Row>
    </>
  );
};
const receita_summary = (pageData: any) => {
  const total = pageData.reduce(
    (prev: any, curr: any) => ({
      avg: prev.avg + curr.avg,
      qtde: prev.qtde + curr.qtde,
      total: prev.total + curr.total,
    }),
    {
      avg: 0,
      qtde: 0,
      total: 0,
    },
  );

  return (
    <>
      <Table.Summary.Row>
        <Table.Summary.Cell index={1}>
          <b>Total</b>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={1} align="center">
          {dinheiroMask(total.total)}
        </Table.Summary.Cell>
        <Table.Summary.Cell index={1} align="center">
          {total.qtde}
        </Table.Summary.Cell>
        <Table.Summary.Cell index={1} align="center">
          {dinheiroMask(total.avg)}
        </Table.Summary.Cell>
      </Table.Summary.Row>
    </>
  );
};

const RelatorioGeral: React.FC = () => {
  const [data, setData] = useState<IRelatorioGeral>({} as IRelatorioGeral);
  const [filtros, setFiltros] = useState<Filtro>(() => {
    const data = moment();

    return {
      data_inicio_ref: data,
      data_final_ref: data,
      data_inicio: data.startOf('month').format('YYYY-MM-DD'),
      data_final: data.endOf('month').format('YYYY-MM-DD'),
    };
  });

  const { fetchRequest, loading } = useApi();

  useEffect(() => {
    handleFetchData();
  }, [filtros]);

  function handleFetchData() {
    return fetchRequest({
      method: 'get',
      url: '/relatorio/geral',
      query_params: {
        data_inicio: filtros.data_inicio,
        data_final: filtros.data_final,
      },
      onSuccess: resp => setData(resp),
    });
  }

  function handleUpdate(data: any) {
    const data_inicio = data[0].startOf('month').format('YYYY-MM-DD');
    const data_final = data[1].endOf('month').format('YYYY-MM-DD');

    setFiltros({
      data_inicio_ref: data[0],
      data_final_ref: data[1],
      data_inicio,
      data_final,
    });
  }

  const columns_receita = [
    {
      title: 'Usuario',
      key: 'usuario',
      dataIndex: 'usuario',
      width: '40%',
      render: (item: string, data: any) => (
        <Link to={`/usuario/${data.id_usuario}`}>{item}</Link>
      ),
    },
    {
      title: 'Total',
      key: 'total',
      dataIndex: 'total',
      width: '20%',
      align: 'center' as const,
      defaultSortOrder: 'descend' as const,
      render: (item: string) => dinheiroMask(item),
      sorter: (a: any, b: any) => a.total - b.total,
    },
    {
      title: 'Qtde Vendas',
      key: 'qtde',
      dataIndex: 'qtde',
      width: '20%',
      align: 'center' as const,
      sorter: (a: any, b: any) => a.qtde - b.qtde,
    },
    {
      title: 'Ticket Médio',
      key: 'avg',
      dataIndex: 'avg',
      width: '20%',
      align: 'center' as const,
      render: (item: string) => dinheiroMask(item),
      sorter: (a: any, b: any) => a.avg - b.avg,
    },
  ];

  const columns_by_estado = [
    {
      title: 'Estado',
      key: 'estado',
      dataIndex: 'estado',
      render: (item: string) => (
        <Link
          to={`/permissoes?id_responsabilidade=${EnumResponsabilidade.ESTABELECIMENTO}&estado=${item}&id_status_permissao=${EnumStatusPermissao.PERMISSAO_APROVADA}`}
        >
          {item}
        </Link>
      ),
    },
    {
      title: 'Região',
      key: 'regiao',
      dataIndex: 'regiao',
      align: 'center' as const,
      ...regiao_filter,
    },
    {
      title: 'Qtde',
      key: 'qtde',
      dataIndex: 'qtde',
      align: 'center' as const,
      sorter: (a: any, b: any) => a.qtde - b.qtde,
    },
  ];

  const columns_sem_movimentacoes = [
    {
      title: 'Usuário',
      key: 'usuario',
      dataIndex: 'usuario',
      render: (item: string, data: any) => (
        <Link to={`/usuario/${data.id_usuario}`}>{item}</Link>
      ),
    },
  ];

  return (
    <>
      <PageHeader title="Relatório Geral" breadcrumb={['Relatório', 'Geral']}>
        <RangePicker
          picker="month"
          format="MM/yyyy"
          defaultValue={[filtros.data_inicio_ref, filtros.data_final_ref]}
          allowClear={false}
          placeholder={['Mês Inicial', 'Mês Final']}
          onChange={val1 => handleUpdate(val1)}
        />
      </PageHeader>

      <Divider orientation="left">Geral</Divider>

      <Row gutter={16}>
        <Col xs={24}>
          <CardCollapse title="Por Estado" collapse={false} loading={loading}>
            <Table
              dataSource={data.faturamento_by_estado}
              columns={[
                {
                  title: 'Estado',
                  key: 'estado',
                  dataIndex: 'estado',
                  width: '20%',
                },
                {
                  title: 'Região',
                  key: 'regiao',
                  dataIndex: 'regiao',
                  width: '20%',
                  align: 'center',
                  ...regiao_filter,
                },
                {
                  title: 'Total',
                  key: 'total',
                  dataIndex: 'total',
                  width: '20%',
                  align: 'center',
                  defaultSortOrder: 'descend',
                  render: (item: string) => dinheiroMask(item),
                  sorter: (a, b) => a.total - b.total,
                },
                {
                  title: 'Qtde de Vendas',
                  key: 'qtde',
                  dataIndex: 'qtde',
                  width: '20%',
                  align: 'center',
                  sorter: (a, b) => a.qtde - b.qtde,
                },
                {
                  title: 'Ticket Médio',
                  key: 'avg',
                  dataIndex: 'avg',
                  width: '20%',
                  align: 'center',
                  render: (item: string) => dinheiroMask(item),
                  sorter: (a, b) => a.avg - b.avg,
                },
              ]}
              size="small"
              summary={pageData => {
                const total = pageData.reduce(
                  (prev, curr) => ({
                    avg: prev.avg + curr.avg,
                    qtde: prev.qtde + curr.qtde,
                    total: prev.total + curr.total,
                  }),
                  {
                    avg: 0,
                    qtde: 0,
                    total: 0,
                  },
                );

                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={1}>
                        <b>Total</b>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={1} />
                      <Table.Summary.Cell index={1} align="center">
                        {dinheiroMask(total.total)}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={1} align="center">
                        {total.qtde}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={1} align="center">
                        {dinheiroMask(total.avg)}
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </>
                );
              }}
              pagination={false}
            />
          </CardCollapse>
        </Col>
      </Row>

      <Divider orientation="left">Representante</Divider>

      <Row gutter={16}>
        <Col xs={24}>
          <CardCollapse title="Receita" collapse={false} loading={loading}>
            <Table
              dataSource={data.representante}
              columns={columns_receita}
              size="small"
              pagination={pagination}
              summary={receita_summary}
            />
          </CardCollapse>
        </Col>
        <Col md={12} xs={24}>
          <CardCollapse title="Por Estado" collapse={false} loading={loading}>
            <Table
              dataSource={data.representante_qtde_by_estado}
              columns={columns_by_estado}
              size="small"
              pagination={false}
              summary={por_estado_summary}
            />
          </CardCollapse>
        </Col>
        <Col md={12} xs={24}>
          <CardCollapse title="Sem Movimentações" loading={loading}>
            <Table
              dataSource={data.representante_without_revenue}
              columns={columns_sem_movimentacoes}
              size="small"
              showHeader={false}
              pagination={pagination}
            />
          </CardCollapse>
        </Col>
      </Row>

      <Divider orientation="left">Estabelecimento</Divider>

      <Row gutter={16}>
        <Col xs={24}>
          <CardCollapse title="Receita" collapse={false} loading={loading}>
            <Table
              dataSource={data.estabelecimento}
              columns={columns_receita}
              size="small"
              pagination={pagination}
              summary={receita_summary}
            />
          </CardCollapse>
        </Col>
        <Col md={12} xs={24}>
          <CardCollapse title="Por Estado" collapse={false} loading={loading}>
            <Table
              dataSource={data.estabelecimento_qtde_by_estado}
              columns={columns_by_estado}
              size="small"
              pagination={false}
              summary={por_estado_summary}
            />
          </CardCollapse>
        </Col>
        <Col md={12} xs={24}>
          <CardCollapse title="Sem Movimentações" loading={loading}>
            <Table
              dataSource={data.estabelecimento_without_revenue}
              columns={columns_sem_movimentacoes}
              size="small"
              showHeader={false}
              pagination={pagination}
            />
          </CardCollapse>
        </Col>
      </Row>
    </>
  );
};

export default RelatorioGeral;
