import React, { useEffect, useMemo, useState } from 'react';
import {
  Alert,
  Button,
  Col,
  Form,
  Image,
  Input,
  Row,
  Space,
  Typography,
} from 'antd';
import { toDataURL } from 'qrcode';
import { authenticator } from '@otplib/preset-default';
import { useSelector } from 'react-redux';

import CardCollapse from '../../../components/CardCollapse';
import PageHeader from '../../../components/PageHeader';
import { fetchApi } from '../../../services/api';
import { IState } from '../../../store';
import { IUserState } from '../../../store/modules/user/reducer';

const required_message = 'Esse campo é obrigatório';

const { Paragraph } = Typography;

const PerfilSeguranca: React.FC = () => {
  const usuario = useSelector<IState, IUserState>(state => state.user);

  const [formUpdatePassword] = Form.useForm();
  const [formGenerateOtp] = Form.useForm();
  const [formConfirmOtp] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [loadingOtp, setLoadingOtp] = useState(true);
  const [loadingGenerateOtp, setLoadingGenerateOtp] = useState(false);
  const [loadingConfirmOtp, setLoadingConfirmOtp] = useState(false);

  const [isTotpGenerated, setIsTotpGenerated] = useState(false);
  const [otpSecret, setOtpSecret] = useState<IOtpSecret>();

  useEffect(() => {
    handleValidateOtp();
  }, []);

  function handleUpdatePassword(data: any) {
    setLoading(true);

    return fetchApi({
      url: `/alterar_senha`,
      method: 'post',
      data,
      messages: {
        loading: 'Alterando sua senha, aguarde...',
        success: 'Senha alterada com sucesso!',
        error: 'Erro ao alterar sua senha, tente novamente!',
      },
      onError: () => setLoading(false),
      onSuccess: () => {
        setLoading(false);
        formUpdatePassword.resetFields();
      },
    });
  }

  function handleValidateOtp() {
    setLoadingOtp(true);
    return fetchApi({
      url: `/usuario/otp`,
      method: 'get',
      onError: () => setLoadingOtp(false),
      onSuccess: data => {
        setLoadingOtp(false);
        setIsTotpGenerated(data.is_generated);
      },
    });
  }
  function handleGenerateOtp(data: any) {
    setLoadingGenerateOtp(true);

    return fetchApi({
      url: `/usuario/otp`,
      method: 'post',
      data,
      onError: () => setLoadingGenerateOtp(false),
      onSuccess: data => {
        setLoadingGenerateOtp(false);

        const service = 'Parcelamos Tudo Backoffice';

        const otpauth = authenticator.keyuri(
          usuario.email,
          service,
          data.secret,
        );

        toDataURL(otpauth, (err, image) => {
          if (err) {
            return;
          }
          setOtpSecret({ image, secret: data.secret });
        });
      },
    });
  }
  function handleConfirmOtp(data: any) {
    setLoadingConfirmOtp(true);

    return fetchApi({
      url: `/usuario/otp/confirm`,
      method: 'post',
      data: {
        token: data.token,
        secret: otpSecret?.secret,
      },
      onError: () => setLoadingConfirmOtp(false),
      onSuccess: data => {
        setLoadingConfirmOtp(false);
        setIsTotpGenerated(true);
        setOtpSecret(undefined);
      },
    });
  }

  const otpDescription = useMemo(() => {
    let text =
      'Use um aplicativo de autenticação (Google Authenticator, 1Password) para obter tokens de autenticação de dois fatores quando solicitado.';

    if (otpSecret) {
      text += ` Escaneie o QRCode utilizando seu aplicativo e insira o token gerado por ele.`;
    }

    return text;
  }, [otpSecret]);

  return (
    <>
      <PageHeader title="Segurança" breadcrumb={['Perfil', 'Segurança']} />

      <CardCollapse title="Alterar senha">
        <Form
          layout="vertical"
          onFinish={data => handleUpdatePassword(data)}
          form={formUpdatePassword}
        >
          <Row gutter={16}>
            <Col md={12}>
              <Form.Item
                name="senha_antiga"
                label="Senha Antiga"
                rules={[{ required: true, message: required_message }]}
              >
                <Input.Password />
              </Form.Item>
            </Col>
            <Col md={12} />
            <Col md={12}>
              <Form.Item
                name="senha_nova"
                label="Nova Senha"
                rules={[
                  { required: true, message: required_message },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue('senha_antiga') !== value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        'Nova senha deve ser diferente da senha antiga',
                      );
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                name="senha_confirmacao"
                label="Confirmação de Senha"
                dependencies={['senha_nova']}
                rules={[
                  { required: true, message: required_message },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue('senha_nova') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        'Confirmação de senha não coincide com a nova senha',
                      );
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
            </Col>
          </Row>

          <br />

          <Row justify="end">
            <Button type="primary" htmlType="submit" loading={loading}>
              Salvar
            </Button>
          </Row>
        </Form>
      </CardCollapse>

      <CardCollapse
        title="Autenticação de múltiplos fatores"
        loading={loadingOtp}
      >
        {isTotpGenerated ? (
          <Alert
            message="Aplicativo autenticador configurado"
            description={otpDescription}
            type="success"
            showIcon
          />
        ) : (
          <Alert
            message="Aplicativo autenticador não configurado"
            description={otpDescription}
            type="warning"
            showIcon
            action={
              otpSecret ? (
                <Form
                  layout="vertical"
                  onFinish={data => handleConfirmOtp(data)}
                  form={formConfirmOtp}
                >
                  <Space direction="vertical">
                    <Space>
                      <Image
                        src={otpSecret.image}
                        alt={otpSecret.secret}
                        width={200}
                        height={200}
                      />
                      <Paragraph copyable={{ text: otpSecret.secret }}>
                        Secret
                      </Paragraph>
                    </Space>
                    <Space>
                      <Form.Item
                        name="token"
                        rules={[{ required: true, message: required_message }]}
                      >
                        <Input.Password
                          size="small"
                          placeholder="Token"
                          visibilityToggle
                        />
                      </Form.Item>
                      <Button
                        size="small"
                        htmlType="submit"
                        loading={loadingConfirmOtp}
                      >
                        Confirmar
                      </Button>
                    </Space>
                  </Space>
                </Form>
              ) : (
                <Form
                  layout="vertical"
                  onFinish={data => handleGenerateOtp(data)}
                  form={formGenerateOtp}
                >
                  <Space>
                    <Form.Item
                      name="senha"
                      rules={[{ required: true, message: required_message }]}
                    >
                      <Input.Password
                        size="small"
                        placeholder="Senha de acesso"
                      />
                    </Form.Item>
                    <Button
                      size="small"
                      htmlType="submit"
                      loading={loadingGenerateOtp}
                    >
                      Gerar token
                    </Button>
                  </Space>
                </Form>
              )
            }
          />
        )}
      </CardCollapse>
    </>
  );
};

type IOtpSecret = {
  image: string;
  secret: string;
};

export default PerfilSeguranca;
