import React, { useEffect, useState, useMemo } from 'react';
import { Col, Row } from 'antd';
import { useParams } from 'react-router-dom';

import PageHeader from '../../../components/PageHeader';
import { fetchApi } from '../../../services/api';
import CarrinhoInfoBoleto from './Boleto';
import CarrinhoInfoTransacao from './Transacao';
import CarrinhoInfoFormaPagamento from './FormaPagamento';
import CarrinhoInfoCliente from './Cliente';
import CarrinhoInfoGeral from './Geral';
import CarrinhoInfoStatus from './Status';
import CarrinhoInfoSumario from './Sumario';

import { findTransacaoStatus, findFormaPagamento } from '..';
import { CarrinhoStatus, CarrinhoBoleto, CarrinhoAnaliseRisco } from './types';

export type ICarrinho = {
  carrinho: any;
  cliente?: any;
  boletos: CarrinhoBoleto[];
  transacoes: any[];
  formas_pagamento: any[];
  status: CarrinhoStatus[];
  analise_risco: CarrinhoAnaliseRisco[];
  busca?: any;
};

const CarrinhoInfo: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const [carrinho, setCarrinho] = useState<ICarrinho>({} as ICarrinho);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchCarrinhoInfo();
  }, []);

  function fetchCarrinhoInfo() {
    return fetchApi({
      url: `/carrinho/${id}`,
      method: 'get',
      onSuccess: data => {
        setLoading(false);

        const transacoes = data.transacoes.map((item: any) => ({
          ...item,
          ...findTransacaoStatus(item),
        }));

        const formas_pagamento = data.formas_pagamento.map((item: any) => ({
          ...item,
          status: findFormaPagamento(item, data.carrinho.id_status_carrinho),
        }));

        setCarrinho({
          ...data,
          transacoes,
          formas_pagamento,
        });
      },
    });
  }

  const carrinhoEmDisputa = useMemo(() => {
    return carrinho?.carrinho?.id_status_carrinho === 29;
  }, [carrinho]);

  return (
    <>
      <PageHeader
        title="Informações do Carrinho"
        breadcrumb={['Carrinho', 'Informação']}
      />

      <CarrinhoInfoGeral
        carrinho={carrinho.carrinho}
        analise_risco={carrinho.analise_risco}
        loading={loading}
        emDisputa={carrinhoEmDisputa}
        fetchCarrinho={fetchCarrinhoInfo}
      />

      <Row gutter={16}>
        <Col md={12} xs={24}>
          <CarrinhoInfoStatus
            status={carrinho.status || []}
            loading={loading}
          />
        </Col>
        <Col md={12} xs={24}>
          <CarrinhoInfoSumario carrinho={carrinho} loading={loading} />
          <CarrinhoInfoCliente
            id={id}
            loading={loading}
            cliente={carrinho.cliente}
            fetchCarrinho={fetchCarrinhoInfo}
            setLoading={setLoading}
            busca={carrinho?.busca}
          />
        </Col>
      </Row>

      <CarrinhoInfoBoleto
        cartId={id}
        loading={loading}
        setLoading={setLoading}
        busca={carrinho?.busca}
        estabelecimento={carrinho?.carrinho?.estabelecimento}
      />

      <CarrinhoInfoFormaPagamento
        loading={loading}
        formas_pagamento={carrinho.formas_pagamento}
      />

      <CarrinhoInfoTransacao
        fetchCarrinho={fetchCarrinhoInfo}
        loading={loading}
        transacoes={carrinho.transacoes}
      />
    </>
  );
};

export default CarrinhoInfo;
