import {
  Button,
  Modal,
  Timeline,
  List,
  Col,
  Form,
  Row,
  Typography,
  Radio,
  Checkbox,
  Table,
  message,
} from 'antd';
import React, { useMemo, useState } from 'react';
import { MdList, MdDelete } from 'react-icons/md';
import { useMutation } from 'react-query';
import CardCollapse from '../../../../components/CardCollapse';
import { CreateApiClientKeyParams, CreateApiClientKeyScopeParams, EnumScopeType, GetApiClientKeyScopesResponse, GetBackofficeApiClientDetailKeysResponse, GetBackofficeApiClientDetailResponse, GetBackofficeApiClientKeyDetailResponse, scopeTypeLabels } from "../types";
import { deleteApiClientKey, deleteApiClientKeyScope } from "../../services";
import { useCreateApiClientKey, useCreateApiClientKeyScope, useKeyDetails, useKeySecret } from "../../hooks";
import { queryClientApp } from "../../../../App";

const { Paragraph } = Typography;

type IInfoChaveClientApi = {
  api_client?: GetBackofficeApiClientDetailResponse;
  loading: boolean;
};

type selectedApiClientKey = {
  idApiClientkey: string | null;
  modalSecret: boolean;
  modalScope: boolean;
  modalScopeCreation: boolean;
}

const InfoChaveClientApi: React.FC<IInfoChaveClientApi> = ({
  api_client,
  loading,
}: IInfoChaveClientApi) => {
  const [selectedApiClientKey, setSelectedApiClientKey] = useState<selectedApiClientKey>({ idApiClientkey: null, modalSecret: false, modalScope: false, modalScopeCreation: false });
  const [formScopeCreation] = Form.useForm();
  const [formKeyCreation] = Form.useForm();
  const [selectedScope, setSelectedScope] = useState<string>('');
  const [idApiClientKeyCreation, setIdApiClientKeyCreation] = useState<
    string | undefined
  >(undefined);
  const { mutate: createApiClienteKeyScope } = useCreateApiClientKeyScope({
    onSuccess: () => {
      closeModalAndClear();
    }
  });
  const { mutate: createApiClienteKey } = useCreateApiClientKey({
    onSuccess: () => {
      closeModalAndClearCheckbox()
    }
  })
  
  const { data: key } = useKeyDetails(selectedApiClientKey.idApiClientkey || '');
  const { data: keySecret } = useKeySecret(selectedApiClientKey.idApiClientkey || '');
  
  const availableScopes = Object.keys(scopeTypeLabels).filter((keyLabel) => {
    const scopesList = key?.scopes?.map((scope: GetApiClientKeyScopesResponse) => scope.scope) || [];
    return !(scopesList ?? []).includes(keyLabel)
  });

  const closeModal = () => {
    setSelectedApiClientKey({idApiClientkey: null, modalScope: false, modalSecret: false, modalScopeCreation: false })
  }; 

  const closeCreateKeyScopeModal = () => {
    setSelectedApiClientKey({idApiClientkey: key?.id_api_client_key!, modalScope: true, modalSecret: false, modalScopeCreation: false })
  }; 

  const handleCreateApiClientKey = (data: CreateApiClientKeyParams) => {
    createApiClienteKey(data);
  };

  const handleShowApiClientKeySecret = (id_api_client_key: string) => {
    setSelectedApiClientKey({idApiClientkey: id_api_client_key, modalSecret: true, modalScope: false, modalScopeCreation: false });
  };

  const handleListApiClientKeyScopes = (id_api_client_key: string) => {
    setSelectedApiClientKey({idApiClientkey: id_api_client_key, modalSecret: false, modalScope: true, modalScopeCreation: false });
  };

  const handleCreateApiClientKeyScope = (data: CreateApiClientKeyScopeParams) => {
    setSelectedApiClientKey({idApiClientkey: data.param_id_api_client_key, modalSecret: false, modalScope: true, modalScopeCreation: true });
    createApiClienteKeyScope(data);
  };

  const closeModalAndClear = () => {
    closeCreateKeyScopeModal()
    formScopeCreation.resetFields(); 
  };

  const closeModalAndClearCheckbox = () => {
    setIdApiClientKeyCreation(undefined);
    formKeyCreation.resetFields(); 
  };

  const keys = useMemo(() => {
    return (
      api_client?.keys?.map(key => {
        return {
          ...key,
          id_api_client: key.id_api_client,
        };
      }) || []
    );
  }, [api_client?.keys]);

  const isModalSecretKeyVisible = selectedApiClientKey.modalSecret&&!!keySecret?.secret_key
  const isModalScopesVisible = selectedApiClientKey.modalScope&&!!key?.id_api_client_key
  const isModalScopeCreationVisible = selectedApiClientKey.modalScopeCreation

  const { mutate: deleteApiClienteKey } = useMutation({
    mutationFn: (id_api_client_key: string) => deleteApiClientKey(id_api_client_key),
    onSuccess: () => {
      queryClientApp.invalidateQueries(['client']);
      message.success('Chave de Cliente API removida com sucesso!');
    },
    onError: (error: any) => {
      message.error(error?.message || 'Erro ao remover Chave de Cliente API, tente novamente!');
    },
  });
  
  function handleDeleteApiClientKey(id_api_client_key: string) {
    Modal.confirm({
      title: 'Tem certeza que deseja remover a chave?',
      content: 'Essa ação não poderá ser desfeita!',
      centered: true,
      onOk() {
        deleteApiClienteKey(id_api_client_key);
      },
    });
  }

  const { mutate: deleteApiClienteKeyScope } = useMutation({
    mutationFn: (id_api_client_key_scope: string) => deleteApiClientKeyScope(id_api_client_key_scope),
    onSuccess: () => {
      queryClientApp.invalidateQueries(['key-details']);
      message.success('Escopo de Chave de Cliente API removida com sucesso!');
    },
    onError: (error: any) => {
      message.error(error?.message || 'Erro ao remover escopo de Chave de Cliente API, tente novamente!');
    },
  });

  function handleDeleteApiClientKeyScope(id_api_client_key_scope: string) {
    Modal.confirm({
      title: 'Tem certeza que deseja remover o escopo dessa chave?',
      content: 'Essa ação não poderá ser desfeita!',
      centered: true,
      onOk() {
        deleteApiClienteKeyScope(id_api_client_key_scope);
      },
    });
  }

  function findKeyInfoButtons(key: GetBackofficeApiClientDetailKeysResponse) {
    return [
      <Button 
      key="1" 
      onClick={() => handleListApiClientKeyScopes(key.id_api_client_key)}
      style={{ marginRight: 15 }}
      >
        <MdList />
      </Button>,
      <Button
        key="2"
        type="primary"
        onClick={() => handleDeleteApiClientKey(key.id_api_client_key)}
        danger
      >
        <MdDelete />
      </Button>
    ];
  }

  const columns = [
    {
      title: <span style={{ fontWeight: 'bold' }}>Cliente API</span>,
      dataIndex: 'id_api_client',
      key: 'id_api_client',
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>Secret Key</span>,
      key: 'secret_key',
      render: (record: GetBackofficeApiClientKeyDetailResponse) => (
        <Button 
          type="link" 
          onClick={() => handleShowApiClientKeySecret(record.id_api_client_key)}
        >
          Visualizar Secret Key
        </Button>
      ),
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>Ações</span>,
      key: 'actions',
      render: (record: GetBackofficeApiClientDetailKeysResponse) => (
        findKeyInfoButtons(record)
    ),
    }
  ];

  return (
    <>
    <CardCollapse title="Chaves" loading={loading}>

      <Button
          onClick={() => setIdApiClientKeyCreation(api_client?.id_api_client)}
          type="primary"
        >
          Criar Chave
      </Button>

      <br/>
      <br/>

      <Table
        columns={columns}
        dataSource={keys}
        loading={loading}
        pagination={false}
        size="small"
      />

      <Modal
        visible={!!idApiClientKeyCreation}
        title={<span style={{ fontWeight: 'bold' }}>Criação de Chave</span>}
        footer={false}
        onCancel={() => setIdApiClientKeyCreation(undefined)}
        centered
      >
        <Form
          form={formKeyCreation}
          layout="vertical"
          onFinish={data => {handleCreateApiClientKey({scopes: data.scopes, param_id_api_client: api_client?.id_api_client!});
          }}
        >
          <Row gutter={16}>
            <Col md={24}>
              <Form.Item label={<span style={{ fontWeight: 'bold' }}>Escopos: </span>} name="scopes">
              <Checkbox.Group>
                <Row>
                  {Object.keys(scopeTypeLabels).map((key) => (
                    <Col span={24} key={key}>
                      <Checkbox value={key}>
                        {scopeTypeLabels[key as keyof typeof EnumScopeType]}
                      </Checkbox>
                    </Col>
                  ))}
                </Row>
              </Checkbox.Group>
              </Form.Item>
            </Col>
            <Col md={24}>
              <br />
              <Button htmlType="submit" type="primary" block>
                Salvar
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </CardCollapse>

    <Modal
      visible={isModalSecretKeyVisible}
      title={<span style={{ fontWeight: 'bold' }}>Segredo da Chave</span>}
      footer={false}
      onCancel={closeModal}
      centered
    >
      <div>
        <Paragraph copyable={{ text: keySecret?.secret_key}}>
          {keySecret?.secret_key}
        </Paragraph>
      </div>
    </Modal>

    <Modal
      visible={isModalScopesVisible}
      title={<span style={{ fontWeight: 'bold' }}>Escopos da Chave</span>}
      footer={false}
      onCancel={closeModal}
      centered
    >
      <Button
      onClick={() => setSelectedApiClientKey({idApiClientkey: key?.id_api_client_key!, modalSecret: false, modalScope: true, modalScopeCreation: true })}
      type="primary"
     >
       Adicionar Escopo
    </Button>
      <Timeline mode="left">{key?.scopes?.map((scope: GetApiClientKeyScopesResponse) => (
          <List.Item key={scope.id_api_client_key_scope}>
            <strong>{scope.scope}</strong>
            <Button
              type="default"
              onClick={() => handleDeleteApiClientKeyScope(scope?.id_api_client_key_scope)}
            >
              <MdDelete />
            </Button>
          </List.Item>
        ))}</Timeline>
    </Modal>

    <Modal
      visible={isModalScopeCreationVisible}
      title={<span style={{ fontWeight: 'bold' }}>Criação de Escopo para Chave</span>}
      footer={false}
      onCancel={closeCreateKeyScopeModal}
      centered
    >
      <Form
        form={formScopeCreation}
        layout="vertical"
        onFinish={data => {
          handleCreateApiClientKeyScope({ scope: selectedScope, param_id_api_client_key: key?.id_api_client_key!});
        }}
      >
        <Row gutter={16}>
          <Col md={24}>
            <Form.Item label={<span style={{ fontWeight: 'bold' }}>Escopos:</span>} name="scopes">
              <Radio.Group
                onChange={(e) => setSelectedScope(e.target.value)}
                value={selectedScope}
              >
                {availableScopes.length > 0 ? (
                  availableScopes.map((key) => (
                    <Radio key={key} value={key}>
                      {scopeTypeLabels[key as keyof typeof EnumScopeType]}
                    </Radio>
                  ))
                ) : (
                  <Radio disabled>Nenhum Escopo disponível</Radio>
                )}
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col md={24}>
            <br />
            <Button htmlType="submit" type="primary" block>
              Salvar
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
    </>
  );
};

export default InfoChaveClientApi;