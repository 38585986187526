import { Empty } from 'antd';
import { format } from 'date-fns';
import { celularMask, cpfMask } from 'masks-br';
import React from 'react';
import styled from 'styled-components';

import CardCollapse from '../../../../components/CardCollapse';
import PropertyList from '../../../../components/PropertyList';
import { GetBackofficeCheckoutResponse } from '../types';

type ICarrinhoInfoCliente = {
  loading: boolean;
  checkout?: GetBackofficeCheckoutResponse;
};

const CheckoutInfoClient: React.FC<ICarrinhoInfoCliente> = ({
  loading,
  checkout,
}: ICarrinhoInfoCliente) => {
  return (
    <>
      <CardCollapse title="Cliente" loading={loading}>
        {checkout?.client?.id_cliente ? (
          <PropertyList.Container>
            <PropertyList.Line>
              <PropertyList.Label>Nome</PropertyList.Label>
              <PropertyList.Value>{checkout?.client?.nome}</PropertyList.Value>
            </PropertyList.Line>
            <PropertyList.Line>
              <PropertyList.Label>Documento</PropertyList.Label>
              <PropertyList.Value>
                {cpfMask(checkout?.client?.cpf)}
              </PropertyList.Value>
            </PropertyList.Line>
            <PropertyList.Line>
              <PropertyList.Label>Email</PropertyList.Label>
              <PropertyList.Value>{checkout?.client?.email}</PropertyList.Value>
            </PropertyList.Line>
            <PropertyList.Line>
              <PropertyList.Label>Telefone</PropertyList.Label>
              <PropertyList.Value>
                {celularMask(checkout?.client?.telefone)}
              </PropertyList.Value>
            </PropertyList.Line>
            {checkout?.client?.data_nascimento && (
              <PropertyList.Line>
                <PropertyList.Label>Nascimento</PropertyList.Label>
                <PropertyList.Value>
                  {format(new Date(checkout?.client?.data_nascimento), 'PPP')}
                </PropertyList.Value>
              </PropertyList.Line>
            )}
          </PropertyList.Container>
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            // description="Cliente não vinculado"
          />
        )}
      </CardCollapse>
    </>
  );
};

export default CheckoutInfoClient;

export const Item = styled.div`
  span {
    color: #a7a7a7;
  }
`;
