import { Typography, Tag, Tooltip, message } from 'antd';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import { format } from 'date-fns';
import { dinheiroMask } from 'masks-br';
import CardCollapse from '../../../../components/CardCollapse';
import { StatusTipo } from '../../../../store/modules/utils/types';
import { EnumPaymentType, PaymentDetailItem } from '../../services/types';
import { Link } from 'react-router-dom';
import { MdOpenInNew } from 'react-icons/md';
import { useQuery } from "react-query";
import { getCheckoutById } from "../../services";

const { Paragraph } = Typography;

interface PropsPaymentInfoGeneral {
  data: PaymentDetailItem;
  loading: boolean;
}

export function findStatusColor(name: string, tipo: StatusTipo) {
  if (!name) {
    return null;
  }

  let color = '';

  switch (tipo?.toLocaleLowerCase()) {
    case 'danger':
    case 'error':
      color = 'red';
      break;
    case 'info':
      color = 'blue';
      break;
    case 'success':
      color = 'green';
      break;

    default:
      color = 'default';
      break;
  }

  return <Tag color={color}>{name}</Tag>;
}

const PaymentInfoGeneral: React.FC<PropsPaymentInfoGeneral> = ({
  data,
  loading,
}: PropsPaymentInfoGeneral) => {
  const created_at = useMemo(() => {
    if (!data?.created_at) return '';
    return format(new Date(data.created_at), 'dd/MM/yyyy HH:mm:ss');
  }, [data]);

  const bill_type: string = useMemo(() => {
    if (data.type === EnumPaymentType.PIX) return 'Pix';
    if (data.type === EnumPaymentType.TRANSFER) return 'Transferência';
    if (data.type === EnumPaymentType.PARTNER) return 'Débito Veicular';

    if (data.bill.type === 'CLEARANCE_FORM') return 'Boleto bancário';
    if (data.bill.type === 'CONCESSIONAIRES') return 'Concessionária';
    return '';
  }, [data]);

  const { isLoading, data: checkout } = useChekoutDetail(data.checkout_id);
  
  const partner = checkout?.partner;

  const partnerExist = useMemo(() => {
    return partner?.name;
  }, [partner]);

  function useChekoutDetail(id_checkout: string) {
    return useQuery({
      queryKey: ['checkout_partner_name', id_checkout],
      queryFn: () => getCheckoutById(id_checkout),
      staleTime: 2 * 60 * 1000,
      retry: false,
      enabled: !!id_checkout,
      onError: (err: any) => {
        message.error(err?.message || 'Erro ao buscar parceiro');
      },
    });
  }

  return (
    <>
      <CardCollapse header={false} loading={loading}>
        <Header>
          <div className="row row-header">
            <div>{created_at}</div>
            <div>
              {data?.id_payment && (
                <Paragraph copyable>{data?.id_payment}</Paragraph>
              )}
            </div>
          </div>
          <div className="row row-body">
            <div className="status">
              <span className="value">{dinheiroMask(data.total_amount)}</span>
              {findStatusColor(data.status.name, data.status.type)}
            </div>
            <div>
              <Tooltip title="">
                <Link
                  to={`/checkout/${data.checkout_id}`}
                  className="ant-btn ant-btn-circle"
                >
                  <MdOpenInNew />
                </Link>
              </Tooltip>
            </div>
          </div>
        </Header>
        <Body>
          <span className="body-item">
            <h3>Original</h3>
            <p>{dinheiroMask(data.amount)}</p>
          </span>
          <span className="body-item">
            <h3>Taxa</h3>
            <p>{dinheiroMask(data.fee_amount)}</p>
          </span>
          <span className="body-item">
            <h3>Tipo</h3>
            <p>{bill_type}</p>
          </span>
          {!!partnerExist &&(
          <span className="body-item">
            <h3>Parceiro</h3>
            <p>{partnerExist}</p>
          </span>)}
        </Body>
      </CardCollapse>
    </>
  );
};

export default PaymentInfoGeneral;

export const Header = styled.div`
  border-bottom: 1px solid #f1f1f1;

  .row {
    display: flex;
    justify-content: space-between;

    span.value {
      font-size: 40px;
      margin-right: 25px;
      font-weight: lighter;
    }

    div {
      .ant-typography {
        margin: 0;
      }
    }
  }

  .row-header {
    font-size: 13px;
    color: #717171;

    .ant-typography {
      color: #717171;
    }
  }

  .row-body {
    margin-top: 10px;
    display: flex;
    align-items: center;

    .status {
      display: flex;
      align-items: center;
    }
  }
`;

export const Body = styled.div`
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;

  #body-item-cart {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2px;
  }

  span.body-item {
    /* min-width: 200px; */
    max-width: 300px;
    padding: 0 25px;
    height: 50px;
    border-right: 1px solid #f1f1f1;

    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
      border-right: none;
    }

    h3 {
      margin-bottom: 0;
      font-size: 13px;
      color: #a7a4a4;
      text-transform: uppercase;
    }

    p {
      font-size: 15px;
      margin: 0;
    }
  }
`;
