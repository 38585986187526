export const salesStatus = [
  {
    id_status: 'CHARGE_NOT_SUCCEEDED',
    name: 'Cobrança não sucedida',
    type: 'ERROR',
    description: 'Houve algum problema durante a cobrança',
  },
  {
    id_status: 'RISK_ANALYSIS_REPROVED',
    name: 'Análise de risco reprovada',
    type: 'ERROR',
    description: 'Cobrança não passou na análise de risco',
  },
  {
    id_status: 'REMOVED',
    name: 'Removida',
    type: 'ERROR',
    description: 'Compra removida',
  },
  {
    id_status: 'REVERSED',
    name: 'Revertida',
    type: 'ERROR',
    description: 'Compra foi extornada',
  },
  {
    id_status: 'PAYMENT_ERROR',
    name: 'Pagamentos com erro',
    type: 'ERROR',
    description: 'Um ou mais pagamentos apresentaram erro',
  },
  {
    id_status: '3DS_REPROVED',
    name: '3DS reprovada',
    type: 'ERROR',
    description: 'Validação 3DS reprovada',
  },
  {
    id_status: '3DS_ERROR',
    name: '3DS com erro',
    type: 'ERROR',
    description: 'Erro no processo de validação 3DS',
  },
  {
    id_status: 'CHARGEBACK',
    name: 'Charge-back',
    type: 'ERROR',
    description: 'Compra foi contestada',
  },
  {
    id_status: 'CHARGE_WAITING',
    name: 'Aguardando cobrança',
    type: 'INFO',
    description: 'Cobrança aguardando pagamento do cliente',
  },
  {
    id_status: 'INITIATED',
    name: 'Iniciado',
    type: 'INFO',
    description: 'Processo inicializado',
  },
  {
    id_status: '3DS_VALIDATION_REQUESTED',
    name: 'Validação 3DS requisitada',
    type: 'INFO',
    description: 'Validação 3DS necessária para seguir a transação',
  },
  {
    id_status: 'RISK_ANALYSIS_STARTED',
    name: 'Análise de risco iniciada',
    type: 'INFO',
    description: 'Processo de análise de risco iniciado',
  },
  {
    id_status: 'PAYMENT_STARTED',
    name: 'Pagamentos iniciados',
    type: 'INFO',
    description: 'Processo de liquidação dos débitos iniciado',
  },
  {
    id_status: 'CREATED',
    name: 'Criado',
    type: 'SUCCESS',
    description: 'Processo inicializado',
  },
  {
    id_status: 'COMPLETED',
    name: 'Finalizado',
    type: 'SUCCESS',
    description: 'Processo de cobrança e pagamento finalizado',
  },
  {
    id_status: 'CHARGE_SUCCEEDED',
    name: 'Cobrança sucedida',
    type: 'SUCCESS',
    description: 'Cobrança realizada com sucesso',
  },
  {
    id_status: 'RISK_ANALYSIS_APPROVED',
    name: 'Análise de risco aprovada',
    type: 'SUCCESS',
    description: 'Análise de risco foi aprovada',
  },
  {
    id_status: 'PAYMENT_DONE',
    name: 'Pagamentos finalizados',
    type: 'SUCCESS',
    description: 'Pagamentos realizados com sucesso',
  },
  {
    id_status: '3DS_APPROVED',
    name: '3DS aprovada',
    type: 'SUCCESS',
    description: 'Validação de 3DS aprovada',
  },
  {
    id_status: 'IN_DISPUTE',
    name: 'Em disputa',
    type: 'WARNING',
    description: 'Cliente pediu disputa do pagamento',
  },
];
