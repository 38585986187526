import styled from 'styled-components';
import { Row, Col, Divider } from 'antd';

export const HeaderRow = styled(Row)`
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
  color: #717171;
`;

export const BodyContainer = styled(Col)`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: auto;
`;

export const BodyAmountRow = styled(Row)`
  align-items: center;
  justify-content: flex-start;
  margin: 0px;
`;

export const BodyAmountText = styled.p`
  font-size: 40px;
  font-weight: lighter;
  margin: 0px 25px 0px 0px;
`;

export const BodyInfoRow = styled(Row)`
  margin-top: 10px;
`;

export const BodyInfoCol = styled(Col)`
  max-width: 320px;
  align-items: start;
  justify-content: flex-start;
`;

export const InfoLabel = styled.h3`
  font-size: 13px;
  color: rgb(167, 164, 164);
  text-transform: uppercase;
`;

export const InfoDivider = styled(Divider)`
  height: auto;
  margin: 0px 10px;
`;

export const InfoText = styled.p`
  font-size: 15px;
  text-align: center;
  margin: 0px;
`;

export const LinkCol = styled(Col)`
  flex: 1;
  width: auto;
  align-items: start;
  justify-content: flex-start;
`;

export const LinkRow = styled(Row)`
  flex: 1;
  width: auto;
  align-items: center;
  justify-content: flex-end;
`;
