const devices = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '426px',
  tablet: '769px',
  laptop: '1025px',
  laptopL: '1440px',
  desktop: '2560px',
};

export default devices;
