enum EnumTipoSignatario {
  /** Validador */
  VALIDATOR = 'validator',
  /** Parte */
  PARTY = 'party',
  /** Testemunha */
  WITNESS = 'witness',
}

export default EnumTipoSignatario;
