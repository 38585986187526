import { ApiDefaultResponseError } from '../../../../types/api';

export interface GetDebitVehicleResponse {
  license_plate: string;
  renavam: string;
  document: string;
  debits: DebitVehicle[];
}

export interface DebitVehicle {
  id_debit: string;
  id_partner: string;
  establishment_id: string;
  payment_id: string;
  type: string;
  name: string;
  description: string;
  identifier: string;
  digitable: string;
  year: number;
  amount: number;
  amount_fee: number;
  amount_fine: number;
  amount_original: number;
  license_plate: string;
  renavam: string;
  due_date: string;
  infraction_date: string;
  payed_at: string;
  expired_at: string;
}

export interface GetDetransResponse {
  data: Detran[];
}

export interface Detran {
  id_partner: string;
  type: string;
  name: string;
  state: string;
  image_src: string;
  production: boolean;
  display: boolean;
  fields: FieldDetran[];
}

export interface FieldDetran {
  required: boolean;
  label: string;
  input_key: string;
}

export type DebitDetranInfoErrorResponse =
  ApiDefaultResponseError<EnumErrorCodeVehicles>;

export enum EnumErrorCodeVehicles {
  VEHICLE_BLOCKED = 'VEHICLE_BLOCKED',
  VEHICLE_WITHOUT_DEBITS = 'VEHICLE_WITHOUT_DEBITS',
  RENAVAM_INVALID_SIZE = 'renavam must be longer than or equal to 9 characters',
  PLATE_LICENSE_INVALID_SIZE = 'license_plate must be longer than or equal to 7 characters',
  VEHICLE_NOT_FOUND = 'VEHICLE_NOT_FOUND',
}

export const messageErrors = {
  [EnumErrorCodeVehicles.VEHICLE_BLOCKED]: 'Veículo bloqueado.',
  [EnumErrorCodeVehicles.VEHICLE_NOT_FOUND]: 'Veículo não encontrado',
  [EnumErrorCodeVehicles.VEHICLE_WITHOUT_DEBITS]:
    'Nenhum débito encontrado para este veículo.',
  [EnumErrorCodeVehicles.RENAVAM_INVALID_SIZE]:
    'O Renavam deve ter 9 ou mais caracteres.',
  [EnumErrorCodeVehicles.PLATE_LICENSE_INVALID_SIZE]:
    'A placa deve ter 7 ou mais caracteres.',
};

export function getDetranErrorMessage({
  error,
  message,
}: DebitDetranInfoErrorResponse) {
  return (
    messageErrors[error] ||
    messageErrors[message as EnumErrorCodeVehicles] ||
    message ||
    'Houve um erro inesperado, tente novamente!'
  );
}
