export enum EnumAccountType {
  SAVINGS_ACCOUNT = 'savings_account',
  CHECKING_ACCOUNT = 'checking_account',
}
export enum EnumTransferStatus {
  RECEIVED = 'RECEIVED',
  PENDING = 'PENDING',
  PAYMENT_DONE = 'PAYMENT_DONE',
}

export interface Transfer {
  id_transfer: string;
  id_status: EnumTransferStatus;
  bank_name: string;
  bank_code: string;
  agency_number: string;
  agency_number_digit: string;
  account_number: string;
  account_number_digit: string;
  account_type: EnumAccountType;
  amount_total: number;
  description?: string;
  recipient_name: string;
  recipient_document: string;
  recipient_email: string;
  recipient_phone: string;
  payer_name?: string;
  payer_document?: string;
  payer_email?: string;
  payer_phone?: string;
  reference_id: string;
  authentication_code?: string;
  created_at: string;
  updated_at: string;
  payments?: Payment[];
}

export interface Payment {
  id_payment: string;
  checkout_id: string;
  establishment_id: string;
  id_bill: any;
  debit_id: any;
  id_transfer: string;
  id_status: string;
  type: string;
  amount: number;
  fee_amount: number;
  total_amount: number;
  created_at: string;
  updated_at: string;
}

export interface ListTransferResponse {
  data: Transfer[];
  page: number;
  page_size: number;
  total: number;
  total_pages: number;
}
