import styled, { css } from 'styled-components';

import cores from '../../styles/colors';

interface CollapseI {
  collapse: boolean;
  header: boolean;
  padding: boolean;
  visible?: boolean;
}

export const Collapse = styled.div<CollapseI>`
  margin: 20px 0;
  background: ${cores.background_header};
  border-radius: 5px;
  box-shadow: 0px 0px 10px ${cores.shadow};

  .head {
    display: flex;
    height: 50px;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    cursor: ${props => (props.collapse ? 'pointer' : 'default')};
    border-bottom: 1px solid ${cores.background_light};
    display: ${props => (props.header ? '' : 'none')};
    .title {
      font-size: 16px;
      font-weight: bold;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .icons {
      display: flex;
      align-items: center;

      .csv,
      .planilha {
        font-size: 19px;
        margin-left: 10px;
        cursor: pointer;
      }

      .arrow {
        font-size: 23px;
        margin-left: 15px;
      }
    }
  }

  .body {
    padding: ${props => (props.padding ? '20px' : '0')};
    transition: 0.3s;

    ${props =>
      !props.visible &&
      css`
        filter: blur(7px);
      `}
  }
`;
