import {
  Button,
  Col,
  DatePicker,
  Form,
  Modal,
  Row,
  Select,
  Space,
  Tooltip,
} from 'antd';
import { dinheiroMask } from 'masks-br';
import Table from '../../../components/Table';

import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import locale from 'antd/es/date-picker/locale/pt_BR';

import colors from '../../../styles/colors';
import PageHeader from '../../../components/PageHeader';
import Pagina from '../../../types/Pagina';
import { useApi } from '../../../hooks/useApi';
import { IState } from '../../../store';
import EnumResponsabilidade from '../../../types/enum/Responsabilidade';
import CardCollapse from '../../../components/CardCollapse';
import { useEstablishmentsByUserAndResponsabilidade } from '../../Estabelecimento/hooks/useEstablishments';
import { ColumnsType } from 'antd/lib/table';
import {
  MdFileDownload,
  MdOutlineSmartphone,
  MdPhonelink,
  MdPointOfSale,
} from 'react-icons/md';
import { salesStatus } from './sales_status';
import { findStatusColor } from '../../Checkout';
import UserName from '../../../components/User/Name';
import { ItemInputCurrency } from '../../../components/Form';
import downloadFiles from '../../../utils/downloadFiles';

export interface ExtractSaleItem {
  id_checkout: string;
  id_status: string;
  establishment_id: string;
  user_id: any;
  origin: string;
  amount: number;
  fee_amount: number;
  total_amount: number;
  status: {
    name: string;
    type: string;
    description: string;
  };
  created_at: string;
}

const { RangePicker } = DatePicker;
const { Option } = Select;

enum EnumAuthTypeRequestExtractSales {
  ESTABLISHMENT = 'ESTABLISHMENT',
  REPRESENTATIVE = 'REPRESENTATIVE',
  INCENTIVE = 'INCENTIVE',
  INDICATOR = 'INDICATOR',
  RESPONSIBLE = 'RESPONSIBLE',
}

const VendasExtrato: React.FC = () => {
  const [form] = Form.useForm();
  const [pagination, setPagination] = useState<{
    page: number;
    page_size: number;
  }>({ page: 1, page_size: 25 });
  const responsabilidade = useSelector<
    IState,
    EnumResponsabilidade | undefined
  >(state => state.auth.responsabilidade);
  const [vendas, setVendas] = useState<Pagina<ExtractSaleItem[]>>(
    {} as Pagina<ExtractSaleItem[]>,
  );
  const id_usuario = useSelector<IState, string>(
    state => state.user.id_usuario,
  );

  const { data: establishments } = useEstablishmentsByUserAndResponsabilidade(
    1,
    30,
    id_usuario,
    responsabilidade ?? EnumResponsabilidade.REPRESENTANTE,
    !!responsabilidade,
  );

  const StatusOptions = salesStatus.map((item: any) => ({
    label: findStatusColor(item.name, item.type, item.description),
    value: item.id_status,
  }));

  const getOriginIcon = (origin: string) => {
    const color = '#443d3e';
    const size = 25;

    if (origin.toLocaleLowerCase() === 'pos') {
      return (
        <Tooltip placement="right" title="POS">
          <MdOutlineSmartphone size={size} color={color} />
        </Tooltip>
      );
    }

    if (origin.toLocaleLowerCase() === 'online') {
      return (
        <Tooltip placement="right" title="Online">
          <MdPhonelink size={size} color={color} />
        </Tooltip>
      );
    }

    if (origin.toLocaleLowerCase() === 'totem') {
      return (
        <Tooltip placement="right" title="Totem">
          <MdPointOfSale size={size} color={color} />
        </Tooltip>
      );
    }

    return origin.toLocaleUpperCase();
  };

  const columns: ColumnsType<any> = useMemo(() => {
    return [
      {
        title: '',
        key: 'origin',
        dataIndex: 'origin',
        align: 'center',
        render: getOriginIcon,
      },
      {
        title: '',
        key: 'establishment_id',
        dataIndex: 'establishment_id',
        align: 'center',
        render: establishment_id => <UserName id_user={establishment_id} />,
      },
      {
        title: 'Valor',
        key: 'amount',
        dataIndex: 'amount',
        align: 'center',
        render: dinheiroMask,
      },
      {
        title: 'Taxa',
        key: 'fee_amount',
        dataIndex: 'fee_amount',
        align: 'center',
        render: dinheiroMask,
      },
      {
        title: 'Valor Total',
        key: 'total_amount',
        dataIndex: 'total_amount',
        align: 'center',
        render: dinheiroMask,
      },
      {
        title: 'Status',
        key: 'status',
        dataIndex: 'status',
        align: 'center',
        render: (item: any): any =>
          findStatusColor(item.name, item.type as any, item.description),
      },
      {
        title: 'Criado em',
        key: 'created_at',
        dataIndex: 'created_at',
        align: 'center',
        render: (created_at: string) =>
          format(new Date(created_at), 'dd/MM/yyyy HH:mm:ss'),
      },
    ];
  }, []);

  const { fetchRequest, loading } = useApi();

  useEffect(() => {
    handleFetchVendas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const authType = useMemo(() => {
    switch (responsabilidade) {
      case EnumResponsabilidade.REPRESENTANTE:
        return EnumAuthTypeRequestExtractSales.REPRESENTATIVE;
      case EnumResponsabilidade.INCENTIVADOR:
        return EnumAuthTypeRequestExtractSales.INCENTIVE;
      case EnumResponsabilidade.RESPONSAVEL:
        return EnumAuthTypeRequestExtractSales.RESPONSIBLE;
      case EnumResponsabilidade.INDICADOR:
        return EnumAuthTypeRequestExtractSales.INDICATOR;
      case EnumResponsabilidade.ESTABELECIMENTO:
        return EnumAuthTypeRequestExtractSales.ESTABLISHMENT;

      default:
        return EnumAuthTypeRequestExtractSales.ESTABLISHMENT;
    }
  }, [responsabilidade]);

  function handleFetchVendas(page = 1, page_size = 25, filtro: any = {}) {
    setPagination({ page, page_size });
    const data = filtro?.data ?? [];

    const [date_start, date_end] = data;
    const id_establishment =
      filtro.id_establishment || form.getFieldValue('id_establishment');
    const total_amount =
      filtro.total_amount || form.getFieldValue('total_amount');
    const id_status = filtro.id_status || form.getFieldValue('id_status');
    const origin = filtro.origin || form.getFieldValue('origin');

    if (
      [
        EnumResponsabilidade.REPRESENTANTE,
        EnumResponsabilidade.RESPONSAVEL,
      ].includes(responsabilidade as EnumResponsabilidade) &&
      !id_establishment
    )
      return;

    return fetchRequest({
      method: 'get',
      url: '/panel/statement/sales',
      query_params: {
        page,
        page_size,
        auth_type: authType,
        id_establishment,
        total_amount: total_amount > 0 ? total_amount : undefined,
        id_status,
        origin,
        date_start: date_start ? date_start.toISOString() : undefined,
        date_end: date_end ? date_end.toISOString() : undefined,
      },
      onSuccess: response => {
        setVendas(response);
      },
    });
  }

  async function downloadFile(type = 'csv') {
    const { data, ...filtersForExport } = form.getFieldsValue();
    const { page, page_size } = pagination;

    const date = data ?? [];
    const [date_start, date_end] = date;

    Modal.info({
      title: 'A exportação utilizará os filtros da pesquisa!',
      centered: true,
      onOk() {
        return downloadFiles(
          `/panel/statement/sales/export`,
          `exportacao_vendas.${type}`,
          undefined,
          {
            ...filtersForExport,
            date_start: date_start ? date_start.toISOString() : undefined,
            date_end: date_end ? date_end.toISOString() : undefined,
            page,
            page_size,
            auth_type: authType,
            file_type: type,
          } as any,
          { 'api-version': '1' },
        );
      },
    });
  }

  return (
    <>
      <PageHeader title="Extrato de Venda" breadcrumb={['Vendas', 'Extrato']} />

      <CardCollapse title="Filtro">
        <Form
          form={form}
          layout="vertical"
          onFinish={data => handleFetchVendas(1, 25, data)}
        >
          <Row gutter={16}>
            {[
              EnumResponsabilidade.REPRESENTANTE,
              EnumResponsabilidade.RESPONSAVEL,
            ].includes(responsabilidade as EnumResponsabilidade) ? (
              <Col md="12" xs="24">
                <Form.Item label="Estabelecimento" name="id_establishment">
                  <Select placeholder="Selecione um estabelecimento">
                    {establishments?.data.map(option => (
                      <Option value={option.id_usuario} key={option.id_usuario}>
                        {option.nome}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            ) : null}
            <Col md={4} xs={12}>
              <Form.Item name="origin" label="Origem">
                <Select>
                  <Select.Option value="ONLINE">ONLINE</Select.Option>
                  <Select.Option value="POS">POS</Select.Option>
                  <Select.Option value="TOTEM">TOTEM</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col md={6} xs={10}>
              <Form.Item name="id_status" label="Status">
                <Select options={StatusOptions} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} justify="start">
            <Col md="12" xs="24">
              <Form.Item label="Data" name="data">
                <RangePicker
                  placeholder={['Data Inicio', 'Data Fim']}
                  locale={locale}
                  format={'DD/MM/YYYY'}
                />
              </Form.Item>
            </Col>
            <Col md={4} xs={10}>
              <Form.Item name="total_amount" label="Valor">
                <ItemInputCurrency
                  onChange={(_, val) =>
                    form.setFieldsValue({ total_amount: val })
                  }
                />
              </Form.Item>
            </Col>
          </Row>

          <Row justify="end">
            <Space>
              <Button onClick={() => form.resetFields()}>Limpar Filtros</Button>
              <Button type="primary" htmlType="submit">
                Filtrar
              </Button>
              {/* {vendasForExport?.data?.length ? (
                <CsvDownload
                  data={vendasForExport?.data}
                  filename="vendas-parcelamostudo.csv"
                  style={{ border: 'None' }}
                >
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ background: 'green', borderColor: 'green' }}
                  >
                    Exportar Dados
                  </Button>
                </CsvDownload>
              ) : null} */}
            </Space>
          </Row>
        </Form>
      </CardCollapse>

      <Table
        columns={columns}
        dataSource={vendas?.data ?? []}
        loading={loading}
        size="small"
        pagination={{
          total: vendas?.total,
          current: vendas?.page,
          pageSize: vendas?.page_size,
          onChange: handleFetchVendas,
        }}
      >
        {' '}
        <Tooltip placement="topRight" title="Exportar para CSV">
          <Button
            onClick={() => downloadFile()}
            type="primary"
            shape="round"
            icon={<MdFileDownload />}
            disabled={!vendas?.total}
          />
        </Tooltip>
      </Table>
    </>
  );
};

export default VendasExtrato;

export const Container = styled.div`
  background: white;
  border-radius: 5px;
  box-shadow: 0px 0px 10px ${colors.shadow};
  padding: 15px 25px 25px;

  .ant-list-item-meta-title {
    font-size: 17px;
  }

  .content {
    text-align: right;

    span {
      font-size: 20px;
      display: block;
    }
    small {
      font-size: 15px;
    }
  }
`;
