import { Button, message, Modal, Popconfirm, Table, Tag, Tooltip } from 'antd';
import { addHours, format } from 'date-fns';
import { dinheiroMask } from 'masks-br';
import React, { useEffect, useState } from 'react';
import { MdCancel, MdMonetizationOn } from 'react-icons/md';

import PageHeader from '../../../components/PageHeader';
import { useApi } from '../../../hooks/useApi';
import Pagina from '../../../types/Pagina';
import ArquivoExportacaoPesquisarRelatorio from '../../ArquivoExportacao/Pesquisar/Relatorio';
import { fetchApi } from '../../../services/api';
import { usePermission } from '../../../hooks/usePermissions';
import UserName from '../../../components/User/Name';
import { useSelector } from 'react-redux';
import { IState } from '../../../store';

const RelatorioArquivoRetorno: React.FC = () => {
  const [data, setData] = useState<Pagina>({} as Pagina);

  const { fetchRequest, loading } = useApi();
  const { fetchRequest: fetchConfirm, loading: loadingConfirm } = useApi();

  const { getPermissions } = usePermission();

  const permissoes = getPermissions();

  const id_user = useSelector<IState, string>(state => state.user.id_usuario);

  useEffect(() => {
    handleRequestData();
  }, []);

  function handleRequestData(page = 1, page_size = 25) {
    return fetchRequest({
      method: 'get',
      url: '/backoffice/establishment/file',
      query_params: { page, page_size },
      onSuccess: (data: any) => {
        setData(data);
      },
    });
  }

  function handleConfimPayment(
    id_export_file: string,
    id_establishment: string,
  ) {
    Modal.confirm({
      title: 'Deseja confirmar essa transferência?',
      centered: true,
      onOk() {
        fetchConfirm({
          method: 'post',
          url: `/backoffice/establishment/${id_establishment}/file/confirm`,
          data: {
            id_export_file: id_export_file,
            id_user,
          },
          onSuccess: () => handleRequestData(),
          onError: () => {
            message.error('Erro ao confirmar transferência!');
          },
        });
      },
    });
  }

  async function handleRemoveArquivoRetorno(
    id_establishment: string,
    id_arquivo_exportacao: string,
  ) {
    return fetchApi({
      url: `/backoffice/establishment/${id_establishment}/file/${id_arquivo_exportacao}`,
      method: 'delete',
      onSuccess: () => {
        message.success('Arquivo deletado com sucesso!');
        handleRequestData();
      },
      onError: () => {
        message.error('Erro ao deletar arquivo exportacao!');
      },
    });
  }

  return (
    <>
      <PageHeader
        title="Relatório de Arquivo de Retorno"
        breadcrumb={['Relatório', 'Arquivo de Retorno']}
      />

      <Table
        dataSource={data.data}
        loading={loading}
        pagination={{
          current: data.pagina,
          pageSize: data.porPagina,
          total: data.total,
          onChange: (p, pp) => handleRequestData(p, pp),
        }}
        columns={[
          {
            title: 'Data Referente',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (item: string) =>
              format(addHours(new Date(item), 3), 'dd/MM/yyyy'),
          },
          {
            title: 'Data para Pagamento',
            dataIndex: 'payment_date',
            key: 'payment_date',
            render: (item: string) =>
              format(addHours(new Date(item), 3), 'dd/MM/yyyy'),
          },
          {
            title: 'Estabelecimento',
            dataIndex: 'establishment_id',
            key: 'establishment_id',
            render: (item: string) => <UserName id_user={item} />,
          },
          {
            title: 'Dados',
            render: (data: any) => (
              <>
                <b>Banco:</b>{' '}
                {
                  data.establishment_settlement_type
                    ?.company_organization_bank_number
                }
                <br />
                <b>Conta:</b>{' '}
                {data.establishment_settlement_type?.account_number}-
                {data.establishment_settlement_type?.account_number_digit}
                <br />
                <b>Agencia:</b>{' '}
                {data.establishment_settlement_type?.agency_prefix}-
                {data.establishment_settlement_type?.agency_prefix_digit}
              </>
            ),
          },
          {
            title: 'Valor',
            dataIndex: 'debits_total',
            key: 'debits_total',
            render: (item: number) => dinheiroMask(item),
          },
          {
            title: 'Pago',
            dataIndex: 'settlement_by',
            key: 'settlement_by',
            render: (item: string) =>
              item ? <Tag color="green">Sim</Tag> : <Tag color="red">Não</Tag>,
          },
          {
            title: 'Relatório',
            dataIndex: 'id_export_file',
            key: 'id_export_file',
            render: (item: string) => (
              <ArquivoExportacaoPesquisarRelatorio
                id_arquivo_exportacao={item}
                type_login="backoffice"
              />
            ),
          },
          {
            title: 'Pagar',
            width: '80px',
            render: (data: any) => (
              <Button
                onClick={() =>
                  handleConfimPayment(
                    data.id_export_file,
                    data.establishment_id,
                  )
                }
                loading={loadingConfirm}
                disabled={data.settlement_by}
              >
                <MdMonetizationOn />
              </Button>
            ),
          },
          {
            title: 'Remover',
            dataIndex: 'id_export_file',
            key: 'id_export_file',
            width: 90,
            render: (id_export_file: string, data: any) =>
              permissoes.COMISSAO_REMOVER_COMISSAO ? (
                <Popconfirm
                  placement="topRight"
                  title="Tem certeza que deseja remover esse arquivo de retorno?"
                  onConfirm={() =>
                    handleRemoveArquivoRetorno(
                      data.establishment_id,
                      id_export_file,
                    )
                  }
                  okText="Sim"
                  cancelText="Não"
                >
                  <Button danger>
                    <MdCancel />
                  </Button>
                </Popconfirm>
              ) : (
                <Tooltip
                  placement="topRight"
                  title="Você não tem permissão para remover comissão!"
                >
                  <Button danger disabled>
                    <MdCancel />
                  </Button>
                </Tooltip>
              ),
          },
        ]}
      />
    </>
  );
};

export default RelatorioArquivoRetorno;
