enum EnumStatusBoleto {
  LIQUIDADO = 1,
  ESTORNADO,
  CANCELADO,
  REMOVIDO,
  AGUARDANDO_CONFIRMACAO,
  ERRO,
  PRONTO_EXPORTACAO,
  EXPORTADO,
  AGUARDANDO_LIQUIDACAO,
}

export default EnumStatusBoleto;
