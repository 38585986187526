import { useQuery } from 'react-query';
import {
  getEstablishments,
  getEstablishmentsByUserAndResponsabilidade,
} from '../services';
import EnumResponsabilidade from '../../../types/enum/Responsabilidade';

export function useEstablishments(
  page: number,
  page_size: number,
  enabled?: boolean,
) {
  return useQuery(
    ['establishments', page, page_size],
    () => getEstablishments(page, page_size),
    {
      enabled,
    },
  );
}
export function useEstablishmentsByUserAndResponsabilidade(
  pagina: number,
  porPagina: number,
  id_usuario: string,
  id_responsabilidade: EnumResponsabilidade,
  enabled?: boolean,
) {
  return useQuery(
    [
      'establishments-by-user-responsabilidade',
      pagina,
      porPagina,
      id_usuario,
      id_responsabilidade,
    ],
    () =>
      getEstablishmentsByUserAndResponsabilidade(
        pagina,
        porPagina,
        id_usuario,
        id_responsabilidade,
      ),
    {
      enabled,
      staleTime: 1000 * 60 * 2
    },
  );
}
