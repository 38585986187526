import styled from 'styled-components';

export const Header = styled.div`
  border-bottom: 1px solid #f1f1f1;

  .row {
    display: flex;
    justify-content: space-between;

    span.name {
      font-size: 40px;
      margin-right: 25px;
      font-weight: lighter;
    }

    div {
      .ant-typography {
        margin: 0;
      }
    }
  }

  .row-header {
    font-size: 13px;
    color: #717171;

    .ant-typography {
      color: #717171;
    }
  }

  .row-body {
    margin-top: 10px;
    display: flex;
    align-items: center;

    .information {
      display: flex;
      align-items: center;
    }
  }
`;

export const Body = styled.div`
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;

  span.body-item {
    max-width: 300px;
    padding: 0 25px;
    border-right: 1px solid #f1f1f1;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
      border-right: none;
    }

    h3 {
      margin-bottom: 0;
      font-size: 13px;
      color: #a7a4a4;
      text-transform: uppercase;
    }

    p {
      font-size: 15px;
      margin: 0;
    }
  }
`;
