import { Button, Form, Input } from 'antd';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { fetchApi } from '../../../services/api';

const required_message = 'Esse campo é obrigatório';

const RecuperacaoSenha: React.FC = () => {
  const { token } = useParams<{ token: string }>();
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  function handleFinish(data: any) {
    setLoading(true);

    return fetchApi({
      url: `/account-recovery/${token}`,
      method: 'post',
      data,
      onError: () => setLoading(false),
      onSuccess: () => history.push('/login'),
    })
  }

  return (
    <>
      <h2>Recuperação de Senha</h2>

      <br />

      <Form
        layout="vertical"
        onFinish={data => handleFinish(data)}
      >
        <Form.Item
          label="Senha"
          name="senha"
          rules={[
            { required: true, message: required_message },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="Confirmação de Senha"
          name="confirmacao_senha"
          rules={[
            { required: true, message: required_message },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue('senha') === value) {
                  return Promise.resolve();
                }
                return Promise.reject('Confirmação de senha deve ser igual à senha');
              },
            })
          ]}
        >
          <Input.Password />
        </Form.Item>

        <br />

        <Button type="primary" htmlType="submit" loading={loading}>
          Salvar
        </Button>
      </Form>
    </>
  );
}

export default RecuperacaoSenha;