import React from 'react';

import CardCollapse from '../../../../components/CardCollapse';
import { DataBoletoRaw } from '../../types';
import PropertyList from '../../../../components/PropertyList';
import { formatDate } from '../../../../utils/date';

type IProps = {
  data: DataBoletoRaw;
  loading: boolean;
};

const BoletoLiquidacao: React.FC<IProps> = ({ loading, data }: IProps) => {
  const title = 'Liquidação';

  if (!data.settled_at) {
    return null;
  }

  return (
    <CardCollapse title={title} loading={loading}>
      <PropertyList.Container>
        <PropertyList.Line>
          <PropertyList.Label>Instituição</PropertyList.Label>
          <PropertyList.Value>{data.settlement_institution}</PropertyList.Value>
        </PropertyList.Line>
        <PropertyList.Line>
          <PropertyList.Label>Código de Autenticação</PropertyList.Label>
          <PropertyList.Value>{data.authentication_code}</PropertyList.Value>
        </PropertyList.Line>
        <PropertyList.Line>
          <PropertyList.Label>Liquidado em</PropertyList.Label>
          <PropertyList.Value>
            {formatDate(new Date(data.settled_at), 'datetimefull')}
          </PropertyList.Value>
        </PropertyList.Line>
      </PropertyList.Container>
    </CardCollapse>
  );
};

export default BoletoLiquidacao;
