import api from '../../../services/api';
import { GetEncouragingResponse } from './dto/get-encouraging.dto';

export interface IGetEncouragingOptions {
  label: string;
  value: string;
  key?: string;
  disabled?: boolean;
}

export async function getEncouraging(
  name: string,
): Promise<IGetEncouragingOptions[]> {
  try {
    const response = await api.get<GetEncouragingResponse>(
      `/backoffice/user/encouragers`,
      {
        params: {
          page: 1,
          page_size: 20,
          name,
        },
      },
    );

    return response.data.data.map(item => ({
      label: item.nome,
      value: item.id_usuario,
      key: item.id_usuario,
    }));
  } catch (error: any) {
    throw new Error(error.response.data);
  }
}
