import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Table, Form, Input, Select, Popconfirm } from 'antd';
import { MdDelete } from 'react-icons/md';
import { format, parseISO } from 'date-fns';
import { Link } from 'react-router-dom';

import CardCollapse from '../../../components/CardCollapse';
import PageHeader from '../../../components/PageHeader';
import { fetchApi } from '../../../services/api';
import EnumTipoSignatario from '../../../types/enum/TipoSignatario';
import Option from '../../../types/Option';
import { usePermission } from '../../../hooks/usePermissions';

const tipos_signatarios_option: Option[] = [
  {
    label: 'Parte',
    value: EnumTipoSignatario.PARTY,
  },
  {
    label: 'Testemunha',
    value: EnumTipoSignatario.WITNESS,
  },
  {
    label: 'Validador',
    value: EnumTipoSignatario.VALIDATOR,
  },
];

const ContratoSignatariosPadroes: React.FC = () => {
  const { userPermissions } = usePermission();

  const [signatarios, setSignatarios] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchSignatario();
  }, []);

  function fetchSignatario() {
    setLoading(true);

    return fetchApi({
      url: '/contrato/signatario',
      method: 'get',
      onSuccess: data => {
        setSignatarios(
          data.map((item: any) => ({
            ...item,
            key: item.id_signatario_padrao,
            criado_em: format(parseISO(item.criado_em), 'dd/MM/yyyy HH:mm:ss'),
          })),
        );
        setLoading(false);
      },
    });
  }

  function handleAdicionarSignatario(data: any) {
    return fetchApi({
      url: '/contrato/signatario',
      method: 'post',
      data,
      messages: {
        loading: 'Adicionando signatário padrão, aguarde',
        error: 'Erro ao adicionar signatário padrão, tente novamente!',
        success: 'Signatário padrão adicionado com sucesso!',
      },
      onSuccess: () => fetchSignatario(),
    });
  }

  function handleRemoverSignatario(id: string) {
    return fetchApi({
      url: `/contrato/signatario/${id}`,
      method: 'delete',
      messages: {
        loading: 'Removendo signatário padrão, aguarde',
        error: 'Erro ao remover signatário padrão, tente novamente!',
        success: 'Signatário padrão removido com sucesso!',
      },
      onSuccess: () => fetchSignatario(),
    });
  }

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'nome',
      key: 'nome',
      render: (item: string, data: any) => (
        <Link to={`/usuario/${data.id_usuario}`}>{item}</Link>
      ),
    },
    {
      title: 'Tipo',
      dataIndex: 'tipo_signatario',
      key: 'tipo_signatario',
    },
    {
      title: 'Chave Integradora',
      dataIndex: 'key_integradora',
      key: 'key_integradora',
    },
    {
      title: 'Criado Em',
      dataIndex: 'criado_em',
      key: 'criado_em',
    },
    {
      title: 'Remover',
      dataIndex: 'id_signatario_padrao',
      key: 'id_signatario_padrao',
      render: (item: string) => (
        <Popconfirm
          placement="topRight"
          title="Tem certeza que deseja remover esse signatário"
          onConfirm={() => handleRemoverSignatario(item)}
          okText="Sim"
          cancelText="Não"
          disabled={!userPermissions.CONTRATO_SIGNATARIO_PADRAO_REMOVER}
        >
          <Button
            type="primary"
            danger
            disabled={!userPermissions.CONTRATO_SIGNATARIO_PADRAO_REMOVER}
          >
            <MdDelete />
          </Button>
        </Popconfirm>
      ),
    },
  ];

  return (
    <>
      <PageHeader
        title="Signatários Padrões de Contratos"
        breadcrumb={['Contratos', 'Signatários Padrões']}
      />

      {userPermissions.CONTRATO_SIGNATARIO_PADRAO_CADASTRAR ? (
        <CardCollapse title="Adicionar Signatário Padrão" start={false}>
          <Form
            layout="vertical"
            onFinish={data => handleAdicionarSignatario(data)}
          >
            <Row gutter={16}>
              <Col md={6}>
                <Form.Item
                  label="Id Usuário"
                  name="id_usuario"
                  rules={[
                    {
                      required: true,
                      message: 'Esse campo é obrigatório',
                    },
                  ]}
                >
                  <Input maxLength={36} />
                </Form.Item>
              </Col>
              <Col md={6}>
                <Form.Item
                  label="Tipo de Signatário"
                  name="tipo_signatario"
                  rules={[
                    {
                      required: true,
                      message: 'Esse campo é obrigatório',
                    },
                  ]}
                >
                  <Select options={tipos_signatarios_option} />
                </Form.Item>
              </Col>
            </Row>

            <br />

            <Row justify="end">
              <Button type="primary" htmlType="submit">
                Adicionar
              </Button>
            </Row>
          </Form>
        </CardCollapse>
      ) : null}

      <Table
        dataSource={signatarios}
        columns={columns}
        pagination={false}
        loading={loading}
      />
    </>
  );
};

export default ContratoSignatariosPadroes;
