import {
  Form,
  Row,
  Col,
  Input,
  Button,
  Divider,
  Typography,
  Select,
} from 'antd';
import { celularMask, cpfMask, cleanMask, cepMask } from 'masks-br';
import React, { useEffect } from 'react';
import styled from 'styled-components';

import CardCollapse from '../../../../components/CardCollapse';
import { usePermission } from '../../../../hooks/usePermissions';
import { fetchApi } from '../../../../services/api';
import EnumEstado from '../../../../types/enum/Estado';

const { Paragraph } = Typography;

type ICarrinhoInfoCliente = {
  id: string;
  loading: boolean;
  cliente?: any;
  busca?: any;
  setLoading: (val: boolean) => void;
  fetchCarrinho: () => Promise<void>;
};

const CarrinhoInfoCliente: React.FC<ICarrinhoInfoCliente> = ({
  id,
  loading,
  cliente,
  busca,
  setLoading,
  fetchCarrinho,
}: ICarrinhoInfoCliente) => {
  const { userPermissions } = usePermission();
  const [formCliente] = Form.useForm();

  useEffect(() => {
    if (cliente) {
      formCliente.setFieldsValue({
        nome: cliente.nome,
        email: cliente.email,
        cpf: cliente.cpf ? cpfMask(cliente.cpf) : '',
        telefone: cliente.telefone ? celularMask(cliente.telefone) : '',
        cep: cliente.cep ? cepMask(cliente.cep) : '',
        cidade: cliente.cidade,
        estado: cliente.estado,
        endereco: cliente.endereco,
        bairro: cliente.bairro,
        numero: cliente.numero,
        complemento: cliente.complemento,
      });
    }
  }, [cliente]);

  function handleUpdateCliente(data: any) {
    return fetchApi({
      url: `/carrinho/${id}/cliente`,
      method: 'post',
      data: {
        ...data,
        cpf: cleanMask(data.cpf),
        telefone: cleanMask(data.telefone),
        cep: cleanMask(data.cep),
      },
      messages: {
        loading: 'Atualizando cliente, aguarde...',
        error: 'Erro ao atualizar cliente, tente novamente!',
        success: 'Cliente atulizado com sucesso!',
      },
      onSuccess: async () => {
        setLoading(true);

        await fetchCarrinho();

        setLoading(false);
      },
    });
  }

  return (
    <>
      <CardCollapse title="Cliente" loading={loading}>
        <Form
          layout="vertical"
          form={formCliente}
          onFinish={data => handleUpdateCliente(data)}
        >
          <Row gutter={16}>
            <Col lg={12} md={24} sm={24}>
              <Form.Item name="nome" label="Nome">
                <Input />
              </Form.Item>
            </Col>
            <Col lg={12} md={24} sm={24}>
              <Form.Item name="email" label="Email">
                <Input />
              </Form.Item>
            </Col>
            <Col lg={12} md={24} sm={24}>
              <Form.Item name="cpf" label="CPF">
                <Input
                  onChange={e =>
                    formCliente.setFieldsValue({ cpf: cpfMask(e.target.value) })
                  }
                />
              </Form.Item>
            </Col>
            <Col lg={12} md={24} sm={24}>
              <Form.Item name="telefone" label="Telefone">
                <Input
                  onChange={e =>
                    formCliente.setFieldsValue({
                      telefone: celularMask(e.target.value),
                    })
                  }
                />
              </Form.Item>
            </Col>
            <Col lg={12} md={24} sm={24}>
              <Form.Item name="cep" label="CEP">
                <Input
                  onChange={e =>
                    formCliente.setFieldsValue({
                      cep: cepMask(e.target.value),
                    })
                  }
                />
              </Form.Item>
            </Col>
            <Col lg={12} md={24} sm={24}>
              <Form.Item name="cidade" label="Cidade">
                <Input />
              </Form.Item>
            </Col>
            <Col lg={12} md={24} sm={24}>
              <Form.Item name="estado" label="Estado">
                <Select id="estado" placeholder="Selecione um estado">
                  {Object.keys(EnumEstado)
                    .filter((x: any) => Number.isNaN(Number(x)))
                    .map((estado, index) => (
                      <option value={index}>{estado}</option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={12} md={24} sm={24}>
              <Form.Item name="endereco" label="Endereço">
                <Input />
              </Form.Item>
            </Col>
            <Col lg={12} md={24} sm={24}>
              <Form.Item name="bairro" label="Bairro">
                <Input />
              </Form.Item>
            </Col>
            <Col lg={12} md={24} sm={24}>
              <Form.Item name="numero" label="Número">
                <Input type="number" />
              </Form.Item>
            </Col>
            <Col lg={12} md={24} sm={24}>
              <Form.Item name="complemento" label="Complemento">
                <Input />
              </Form.Item>
            </Col>
          </Row>

          {busca ? (
            <>
              <Divider orientation="left">Busca</Divider>

              <Row gutter={16}>
                <Col md={24}>
                  <Item>
                    <span>Parceiro</span>
                    <p>{busca.parceiro}</p>
                  </Item>
                </Col>
                <Col md={12} xs={24}>
                  <Item>
                    <span>Placa do Veículo</span>
                    {busca.license_plate ? (
                      <Paragraph copyable>{busca.license_plate}</Paragraph>
                    ) : null}
                  </Item>
                </Col>
                <Col md={12} xs={24}>
                  <Item>
                    <span>Documento</span>
                    {busca.document ? (
                      <Paragraph copyable>{busca.document}</Paragraph>
                    ) : null}
                  </Item>
                </Col>
                <Col md={12} xs={24}>
                  <Item>
                    <span>Renavam</span>
                    {busca.renavam ? (
                      <Paragraph copyable>{busca.renavam}</Paragraph>
                    ) : null}
                  </Item>
                </Col>
              </Row>
            </>
          ) : null}

          <br />

          <Row justify="end">
            <Button
              type="primary"
              htmlType="submit"
              disabled={!userPermissions.CARRINHO_ATUALIZAR_CLIENTE}
            >
              Salvar
            </Button>
          </Row>
        </Form>
      </CardCollapse>
    </>
  );
};

export default CarrinhoInfoCliente;

export const Item = styled.div`
  span {
    color: #a7a7a7;
  }
`;
