import React, { useEffect, useMemo, useState, useRef } from 'react';
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  List,
  Tooltip,
  Descriptions,
  Typography,
  message,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { cleanMask, cnpjMask, cpfMask, dinheiroMask } from 'masks-br';
import { format, parseISO } from 'date-fns';
import { MdPrint } from 'react-icons/md';
import { isEmpty } from 'lodash';

import CardCollapse from '../../../components/CardCollapse';
import PageHeader from '../../../components/PageHeader';
import { IState } from '../../../store';
import EnumEstado from '../../../types/enum/Estado';
import { fetchApi } from '../../../services/api';

import CardDebito from './CardDebito';

export type Informacoes = {
  renavam?: string;
  marca?: string;
  cor?: string;
  ano_fabricacao?: number;
  ano_modelo?: number;
  chassi?: string;
  valor_venal?: string;
  debitos: {
    codigo_barra: string;
    data_vencimento: string;
    valor: number;
    descricao: string;
    valor_original?: number;
    valor_multa?: number;
    valor_juros?: number;
    valor_desconto?: number;
    selected: boolean;
  }[];
  totais?: {
    qtde: number;
    valores: number;
  };
};

export type DetranHistorico = {
  created_at: string;
  id_estado: number;
  id_usuario: string;
  license_plate: string;
  renavam?: string;
  document?: string;
  __v: number;
  _id: string;
};

const default_message = 'Esse campo é obrigatório';
const { Text } = Typography;

const DetranPresencial: React.FC = () => {
  const [form] = useForm();
  const id_estado = useSelector<IState, EnumEstado | undefined>(
    state => state.user.id_estado,
  );
  const history = useHistory();
  const componentRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `padding: 15px;width: 794px;`,
    documentTitle: 'Débitos de Licenciamento do Veículo',
    onBeforeGetContent: () => {
      componentRef.current?.classList.add('toPrint');
    },
    onAfterPrint: () => {
      componentRef.current?.classList.remove('toPrint');
    },
  });

  const [loadingInformacoes, setLoadingInformacoes] = useState(false);
  const [loadingHistorico, setLoadingHistorico] = useState(true);
  const [detranHistorico, setDetranHistorico] = useState<any[]>([]);
  const [informacoesDebito, setInformacoesDebito] = useState<Informacoes>(
    {} as Informacoes,
  );

  const somatorio = useMemo(() => {
    let count = 0;
    let amount = 0;

    informacoesDebito?.debitos?.forEach(debito => {
      if (debito.selected) {
        count += 1;
        amount += debito.valor;
      }
    });

    return { count: parseInt(String(count), 10), amount: dinheiroMask(amount) };
  }, [informacoesDebito.debitos]);

  const id_detran = useMemo(() => {
    if (id_estado === EnumEstado.PE) {
      return '4d165a40-1068-4291-90c7-dbd1d2f4047e';
    }
    if (id_estado === EnumEstado.RS) {
      return '845e0387-9810-4739-b895-7523631a259b';
    }
    if (id_estado === EnumEstado.MS) {
      return '12d49562-f065-496a-b69a-4e23c0ae5944';
    }
    if (id_estado === EnumEstado.GO) {
      return '42f7d5fc-4d93-43e2-a8cd-2a29ebbbf762';
    }

    return '';
  }, [id_estado]);

  const detran_nome = useMemo(() => {
    if (id_estado === EnumEstado.PE) {
      return 'Pernambuco';
    }

    if (id_estado === EnumEstado.MS) {
      return 'Mato Grosso do Sul';
    }

    if (id_estado === EnumEstado.RS) {
      return 'Rio Grande do Sul';
    }

    return history.push('/dashboard');
  }, [id_estado]);

  const input = useMemo(() => {
    if (id_estado === EnumEstado.PE) {
      return (
        <>
          {/* <Col sm={4} xs={24}>
            <Form.Item
              label="Busca"
              name="tipo_busca"
              rules={[{ required: true, message }]}
            >
              <Select>
                <Select.Option value="UNICA">IPVA Parcela Única</Select.Option>
                <Select.Option value="PARCELADO">IPVA Parcelado</Select.Option>
              </Select>
            </Form.Item>
          </Col> */}
          <Col md={6} xs={24}>
            <Form.Item
              label="Documento do Proprietário"
              name="document"
              rules={[{ required: true, message: default_message }]}
            >
              <Input
                placeholder="CPF ou CNPJ"
                onChange={val => handleUpdateDocumentInput(val.target.value)}
              />
            </Form.Item>
          </Col>
        </>
      );
    }

    if (id_estado === EnumEstado.MS) {
      return (
        <Col md={6} xs={24}>
          <Form.Item
            label="RENAVAM"
            name="renavam"
            rules={[{ required: true, message: default_message }]}
          >
            <Input />
          </Form.Item>
        </Col>
      );
    }

    if (id_estado === EnumEstado.RS) {
      return (
        <Col md={6} xs={24}>
          <Form.Item
            label="RENAVAM"
            name="renavam"
            rules={[{ required: true, message: default_message }]}
          >
            <Input />
          </Form.Item>
        </Col>
      );
    }

    return null;
  }, [id_estado]);

  const has_informacao = useMemo(() => {
    return !isEmpty(informacoesDebito);
  }, [informacoesDebito]);

  useEffect(() => {
    // fetchDetranHistorico();
  }, []);

  function fetchInformacoesDebito(data: any) {
    setLoadingInformacoes(true);

    return fetchApi({
      url: `/parceiro/1/${id_detran}`,
      data: {
        ...data,
        detran: id_detran,
        document: data.document ? cleanMask(data.document) : undefined,
      },
      method: 'post',
      messages: {
        error: 'Erro ao débitos do veículo, tente novamente!',
        // loading: 'Buscando débitos do veículo, aguarde...',
      },
      onError: () => setLoadingInformacoes(false),
      onSuccess: values => {
        const debitos = values.debitos.map((item: any) => ({
          ...item,
          seleted: false,
        }));
        setInformacoesDebito({ ...values, debitos });
        setLoadingInformacoes(false);
        // fetchDetranHistorico();
      },
    });
  }

  function fetchDetranHistorico() {
    return fetchApi({
      url: `/detran/historico`,
      method: 'get',
      onError: () => setLoadingHistorico(false),
      onSuccess: values => {
        setDetranHistorico(
          values.map((item: any) => {
            let title = `${item.license_plate} - `;

            if (item.document) {
              title += CpfCnpjMask(item.document);
            }

            if (item.renavam) {
              title += item.renavam;
            }

            return {
              ...item,
              title,
              description: format(
                parseISO(item.created_at),
                'dd/MM/yyyy HH:mm:ss',
              ),
            };
          }),
        );
        setLoadingHistorico(false);
      },
    });
  }

  function handleCleanButton() {
    setInformacoesDebito({} as Informacoes);
    form.resetFields();
  }

  function handleInsertFormValues(item: DetranHistorico) {
    form.setFieldsValue({
      license_plate: item.license_plate,
      document: item.document ? CpfCnpjMask(item.document) : undefined,
      renavam: item.renavam,
    });
  }

  function handleUpdateDocumentInput(val: any) {
    const masked = CpfCnpjMask(val);

    form.setFieldsValue({ document: masked });
  }

  function CpfCnpjMask(val: string) {
    const cleaned_val = cleanMask(val);

    return cleaned_val.length <= 11
      ? cpfMask(cleaned_val)
      : cnpjMask(cleaned_val);
  }

  function handleSelectBoleto(cod_barra: string, select: boolean) {
    const info = { ...informacoesDebito };

    const boleto = info.debitos.find(item => item.codigo_barra === cod_barra);

    if (!boleto) return null;

    const is_parcelado = boleto.descricao.search(/Parcela [0-9]/g) >= 0;
    const is_parcela_unica = boleto.descricao.search(/Parcela ÚNICA/g) >= 0;

    const [ano_debito] = boleto.descricao.match(/[0-9]{4}/g) || [];

    let search = '';

    if (is_parcelado) {
      search = `${ano_debito}.*Parcela ÚNICA`;
    } else if (is_parcela_unica) {
      search = `${ano_debito}.*Parcela [0-9]`;
    }

    const search_regexp = new RegExp(search, 'u');

    const outro_tipo_selecionado = search
      ? info.debitos.some(item => {
          return item.descricao.search(search_regexp) >= 0 && item.selected;
        })
      : false;

    if (outro_tipo_selecionado) {
      message.info(
        'Não é possível selecionar um boleto de Parcela Única e Parcelado ao mesmo tempo',
      );

      return null;
    }

    info.debitos = informacoesDebito.debitos.map(debito => {
      if (debito.codigo_barra === cod_barra) {
        debito.selected = select;
      }

      return debito;
    });

    return setInformacoesDebito(info);
  }

  return (
    <>
      <PageHeader title={`Detran - ${detran_nome}`} />

      <CardCollapse header={false}>
        <Form
          form={form}
          layout="vertical"
          onFinish={data => fetchInformacoesDebito(data)}
        >
          <Row gutter={16}>
            {input}
            <Col md={4} xs={24}>
              <Form.Item
                label="Placa do Veículo"
                name="license_plate"
                rules={[{ required: true, message: default_message }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Button
                onClick={() => handleCleanButton()}
                style={{ marginTop: 35 }}
                disabled={loadingInformacoes}
              >
                Limpar
              </Button>
              <Button
                htmlType="submit"
                type="primary"
                style={{ marginTop: 35, marginLeft: 10 }}
                loading={loadingInformacoes}
              >
                Buscar
              </Button>
            </Col>
          </Row>
        </Form>
      </CardCollapse>

      {/* <Row gutter={16}> */}
      {/* <Col xl={14} sm={24} xs={24}> */}
      <CardCollapse title="Busca" loading={loadingInformacoes}>
        {has_informacao ? (
          <>
            <Button
              onClick={() => handlePrint && handlePrint()}
              style={{ position: 'absolute', right: 25 }}
            >
              <MdPrint />
            </Button>
            <div ref={componentRef}>
              <Descriptions
                title="Informações do Veículo"
                layout="vertical"
                size="small"
                bordered
              >
                <Descriptions.Item label="Renavam">
                  {informacoesDebito.renavam}
                </Descriptions.Item>
                <Descriptions.Item label="Marca">
                  {informacoesDebito.marca}
                </Descriptions.Item>
                <Descriptions.Item label="Cor">
                  {informacoesDebito.cor}
                </Descriptions.Item>
                <Descriptions.Item label="Ano de Fabricação">
                  {informacoesDebito.ano_fabricacao}
                </Descriptions.Item>
                <Descriptions.Item label="Ano de Modelo">
                  {informacoesDebito.ano_modelo}
                </Descriptions.Item>
                <Descriptions.Item label="Chassi">
                  {informacoesDebito.chassi}
                </Descriptions.Item>
              </Descriptions>

              <br />

              <Descriptions
                title="Valores Totais"
                layout="vertical"
                size="small"
                bordered
              >
                <Descriptions.Item label="Valor">
                  {dinheiroMask(informacoesDebito?.totais?.valores || 0)}
                </Descriptions.Item>
                <Descriptions.Item label="Qtde de Débidos">
                  {informacoesDebito?.totais?.qtde || 0}
                </Descriptions.Item>
              </Descriptions>

              <br />

              <Descriptions
                title="Boletos Selecionados"
                layout="vertical"
                size="small"
                bordered
              >
                <Descriptions.Item label="Quantidade">
                  {somatorio.count}
                </Descriptions.Item>
                <Descriptions.Item label="Total">
                  {somatorio.amount}
                </Descriptions.Item>
              </Descriptions>

              <br />

              <h4>
                <b>Débitos</b>
              </h4>

              <Text type="secondary">Selecione os débitos para impressão</Text>

              {informacoesDebito.debitos &&
                informacoesDebito.debitos.map(item => (
                  <CardDebito
                    selected={item.selected}
                    codigo_barra={item.codigo_barra}
                    data_vencimento={item.data_vencimento}
                    descricao={item.descricao}
                    valor={item.valor}
                    onClick={() =>
                      handleSelectBoleto(item.codigo_barra, !item.selected)
                    }
                  />
                ))}
            </div>
          </>
        ) : null}
      </CardCollapse>
      {/* </Col> */}

      {/* <Col xl={10} sm={24} xs={24}>
          <CardCollapse title="Histórico de Buscas">
            <List
              bordered
              loading={loadingHistorico}
              dataSource={detranHistorico}
              renderItem={(item: any) => (
                <Tooltip placement="top" title="Clique para selecionar">
                  <List.Item
                    onClick={() => handleInsertFormValues(item)}
                    style={{ cursor: 'pointer' }}
                  >
                    <List.Item.Meta
                      title={item.title}
                      description={item.description}
                    />
                  </List.Item>
                </Tooltip>
              )}
            />
          </CardCollapse>
        </Col> */}
      {/* </Row> */}
    </>
  );
};

export default DetranPresencial;
