import React from 'react'
import { Tag, Tooltip } from "antd";
import { StatusTipo } from "../store/modules/utils/types";
import { InfoCircleOutlined } from "@ant-design/icons";

export function findTagColor(
  name: string,
  tipo: StatusTipo,
  tooltip?: string,
) {
  let color = '';

  switch (tipo?.toLowerCase()) {
    case 'danger':
    case 'error':
      color = 'red';
      break;
    case 'info':
      color = 'blue';
      break;
    case 'success':
      color = 'green';
      break;

    case 'warning':
      color = 'gold';
      break;

    default:
      color = 'default';
      break;
  }

  const tag = <Tag icon={tooltip ? <InfoCircleOutlined /> : undefined} color={color}>{name}</Tag>;

  if (tooltip) {
    return (
      <Tooltip placement="top" title={tooltip}>
        {tag}
      </Tooltip>
    );
  }

  return tag;
}