import React, { useMemo } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';

import { IState } from '../../../store';
import PageHeader from '../../../components/PageHeader';
import { usePaymentLinkInfo } from '../hooks/usePaymentLinks';
import TipoAcesso, { EnumTipoAcesso } from '../../../types/TipoAcesso';
import PaymentLinkInfoCard from '../../../components/PaymentLinkInfoCard';

const PaymentLinkInfo: React.FC = () => {
  const { id_payment_link } = useParams<{ id_payment_link: string }>();

  const tipo_acesso = useSelector<IState, TipoAcesso | undefined>(
    state => state.auth.tipo,
  );

  const isAdmin = useMemo(() => {
    return tipo_acesso === EnumTipoAcesso.ADMIN;
  }, [tipo_acesso]);

  const { data: paymentLinkInfo, isLoading } = usePaymentLinkInfo(
    id_payment_link,
    isAdmin,
  );

  return (
    <>
      <PageHeader
        title="Informações do Link de Pagamento"
        breadcrumb={['Link de Pagamento', 'Informações']}
      />

      {!isLoading && <PaymentLinkInfoCard data={paymentLinkInfo!} isAdmin={isAdmin} />}
    </>
  );
};

export default PaymentLinkInfo;
