import styled from 'styled-components';
import { lighten } from 'polished';

import cores from '../../../styles/colors';

export const Wrapper = styled.div`
  height: 100vh;
  background: linear-gradient(
    153.22deg,
    ${cores.primary} 0%,
    ${cores.third} 100%
  );
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    margin-bottom: 45px;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 380px;
  text-align: center;
  background-color: ${cores.background_light};
  padding: 25px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.6);

  h1 {
    color: ${cores.font_dark};
    font-size: 32px;
    padding: 0 0 15px;

    span {
      font-weight: 100;
    }
  }

  h3 {
    text-align: left;
    color: #f5f5f5;
  }

  /* form,
  div.form {
    display: flex;
    flex-direction: column;
    margin-top: 30px;

    .ant-calendar-picker-clear {
      background: rgba(0, 0, 0, 0.1);
    }

    span {
      color: #fb6f91;
      align-self: flex-start;
      margin: 0 0 10px;
      font-weight: bold;
    }
  } */

  input,
  input.ant-input,
  .ant-input-password,
  select {
    background: rgba(0, 0, 0, 0.1) !important;
    border: 0;
    border-radius: 4px;
    height: 44px;
    padding: 0 15px;
    /* color: #fff; */
    margin: 0 0 10px;

    &::placeholder {
      color: rgba(255, 255, 255, 0.7);
    }

    &.ant-input[disabled] {
      color: ${lighten(0.5, '#fff')} !important;
    }

    // &:hover {
    //   background: rgba(0, 0, 0, 0.1) !important;
    // }
  }

  span.ant-input-password {
    background: rgba(0, 0, 0, 0.1) !important;
    border: 0;
    border-radius: 4px;
    height: 44px;
    background: 0;
    padding: 0;

    .ant-input-suffix {
      /* color: ${cores.primary}; */
      margin: auto 10px;

      span {
        /* color: ${cores.primary}; */
        margin: 0;
      }
    }

    input {
      background: 0 !important;
    }
  }

  .recaptcha-container {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
  }

  button {
    margin: 5px 0 0;
    height: 44px;
    background: ${cores.font_dark};
    font-weight: bold;
    color: ${cores.primary};
    border: 0;
    border-radius: 4px;
    font-size: 16px;
    transition: 0.2;
    cursor: pointer;

    &:hover:enabled {
      background: ${lighten(0.07, cores.primary)};
    }

    &:disabled {
      opacity: 0.8;
      cursor: default;
    }
  }

  a {
    color: #fff;
    margin-top: 15px;
    font-size: 16px;
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }
  }

  a.forgot-password-link {
    margin: 0 0 15px;
    text-align: right;
    font-size: 13px;
  }

  .ant-result-title {
    font-size: 21px;
    line-height: 1.8;
    text-align: center;
    color: white;
  }

  .recaptcha div div {
    width: auto !important;
    margin-bottom: 12px;
  }
`;
