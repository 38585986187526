export const options_forma_recebimento = [
  {
    label: 'Dinheiro',
    number: 1,
  },
  {
    label: 'Cheque',
    number: 2,
  },
  {
    label: 'Não Identificado/Outras Formas',
    number: 3,
  },
  {
    label: 'Cartão De Crédito',
    number: 4,
  },
  {
    label: 'Cartão/Multibanco',
    number: 5,
  },
  {
    label: 'Débito Em Conta',
    number: 6,
  },
];

export const options_meio_arrecadacao = [
  {
    label: 'Gichê de Caixa com fatura/guia de arrecadação',
    number: 1,
  },
  {
    label: 'Arrecadação Eletrônica com fatura/guia de arrecadação',
    number: 2,
  },
  {
    label: 'Internet/mobile com fatura/guia de arrecadação',
    number: 3,
  },
  {
    label: 'Outros meios com fatura/guia de arrecadação',
    number: 4,
  },
  {
    label: 'Correspondentes bancários com fatura/guia de arrecadação',
    number: 5,
  },
  {
    label: 'Telefone com fatura/guia de arredação',
    number: 6,
  },
  {
    label: 'Casas lotéricas com fatura/guia de arrecadação',
    number: 7,
  },
  {
    label: 'Cartão/Multibanco com fatura/guia de arrecadação',
    number: 8,
  },
  {
    label: 'PIX com fatura/guia de arrecadação',
    number: 9,
  },
  {
    label: 'Guichê de Caixa sem fatura/guia de arredação',
    number: 'a',
  },
  {
    label: 'Arrecadação Eletrônica sem fatura/guia de arrecadação',
    number: 'b',
  },
  {
    label: 'Internet/Mobile sem fatura/guia de arrecadação',
    number: 'c',
  },
  {
    label: 'Correspondentes bancários sem fatura/guia de arrecadação',
    number: 'd',
  },
  {
    label: 'Telefone sem fatura/guia de arrecadação',
    number: 'e',
  },
  {
    label: 'Outros meios sem fatura/guia de arredação',
    number: 'f',
  },
  {
    label: 'Casas lotéricas sem fatura/guia de arrecadação',
    number: 'g',
  },
  {
    label: 'Cartão/Multibanco sem fatura/guia de arrecadação',
    number: 'h',
  },
  {
    label: 'PIX sem fatura/guia de arrecadação',
    number: 'i',
  },
];
