import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

  body {
    background: #F5F5F5;
    color: #333232;
    -webkit-font-smoothing: antialiased;
  }

  body, input, button {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
  }

  button {
    cursor: pointer;
  }

  ul {
    list-style: none;
  }

  .buttonMarginTop {
    margin-top: 34px !important;
  }
  .buttonMarginTopLow {
    margin-top: 4px !important;
  }

  .buttonMarginRight {
    margin-right: 15px !important;
  }

  /* Ant */
  .ant-form-item {
    margin-bottom: 0 !important;
  }
  button.ant-btn {
    margin-top: 0;
  }
  .ant-select, .ant-input-number, .ant-picker {
    width: 100%
  }
  .ant-input[disabled], .ant-select-disabled .ant-select-selection-selected-value, .ant-input-number-disabled {
    color: rgba(0, 0, 0, 0.5) !important;
  }

  /* .ant-dropdown {
    width: 200px;

    li.ant-dropdown-menu-item {
      text-align: right !important;
    }
  } */
  .ant-form-item {
    margin-top: 5px;
  }

  .ant-transfer-list {
    width: 48%;
  }

  .ant-table-content {
    tfoot.ant-table-summary {
      td.ant-table-cell {
        background-color: #fafafa;
      }
    }
  }

  .ant-badge {
    font-size: inherit;
  }

  .ant-tree {
    background: transparent;
  }

  .ant-col.ant-form-item-label {
    position: static;

    label {
      position: static !important;
    }
  }
`;

export const header_size = '75px';
export const menu_size = '162px';
