import Axios from 'axios';

/**
 * Função que busca as informações de um endereço por um cep
 * @param cep CEP da busca
 */
export default async function SearchCEP(cep: string | number): Promise<ISearchCEP> {
  const response = await Axios.get<ISearchCEP>(`https://viacep.com.br/ws/${cep}/json/`);

  return response.data;
}

export interface ISearchCEP {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  ibge: string;
  gia: string;
  ddd: string;
  siafi: string;
}
