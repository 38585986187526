import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Table,
  Tag,
  Tooltip,
  Typography,
  message,
} from 'antd';
import { format, parseISO } from 'date-fns';
import { isNumber } from 'lodash';
import { cleanMoneyMask, dinheiroMask, numeroMask } from 'masks-br';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { MdFileDownload, MdOpenInNew } from 'react-icons/md';
import CardCollapse from '../../../components/CardCollapse';
import { ItemInputCurrency } from '../../../components/Form';
import PageHeader from '../../../components/PageHeader';
import { fetchApi } from '../../../services/api';
import EnumTipoAcordoTj from '../../../types/enum/TipoAcordoTj';
import Pagina from '../../../types/Pagina';
import downloadFiles from '../../../utils/downloadFiles';
import UrlQuery, {
  setQueryParams,
  useQueryParams,
} from '../../../utils/UrlQuery';

const { Paragraph } = Typography;
const { RangePicker } = DatePicker;

const RelatoriosAcordos: React.FC = () => {
  const [form] = Form.useForm();
  const query_values = useQueryParams();

  const [carrinho, setCarrinho] = useState<Pagina>({} as Pagina);
  const [filtros, setFiltros] = useState<any | undefined>(undefined);

  const [loading, setLoading] = useState(false);

  const columns = [
    {
      title: 'ID do acordo',
      dataIndex: 'id_checkout',
      key: 'id_checkout',
      render: (item: string) => (
        <Paragraph copyable={{ tooltips: false }} ellipsis>
          {item}
        </Paragraph>
      ),
    },
    {
      title: 'Identificador',
      dataIndex: 'identifier',
      key: 'identifier',
      render: (item: string) => (
        <Paragraph copyable={{ tooltips: false }}>{item}</Paragraph>
      ),
    },
    {
      title: 'Número de Guia',
      render: (_item: string, data: any) => (
        <Paragraph copyable={{ tooltips: false }}>
          {data.guide_number || data.id_transfer}
        </Paragraph>
      ),
    },
    {
      title: 'Tipo de Acordo',
      dataIndex: 'report_type',
      key: 'report_type',
      render: (item: string) => (
        <Tag
          color={EnumTipoAcordoTj.DJO === item ? 'blue' : 'green'}
          key={item}
        >
          {item}
        </Tag>
      ),
    },
    {
      title: 'Matricula',
      dataIndex: 'registration',
      key: 'registration',
    },
    {
      title: 'Beneficiario',
      dataIndex: 'recipient_name',
      key: 'recipient_name',
    },
    {
      title: 'Pagador',
      dataIndex: 'payer_name',
      key: 'payer_name',
    },
    {
      title: 'Valor',
      dataIndex: 'amount',
      key: 'amount',
      render: (item: string) => dinheiroMask(item),
    },
    {
      title: 'Número do Processo',
      dataIndex: 'reference_id',
      key: 'reference_id',
      render: (item = '') => (
        <Paragraph copyable={{ text: item }} ellipsis>
          {item}
        </Paragraph>
      ),
    },
    {
      title: 'Criado em',
      dataIndex: 'created_at',
      key: 'created_at',
    },
    {
      title: 'Data de Vencimento',
      dataIndex: 'due_date',
      key: 'due_date',
      render: (item: string) =>
        item ? format(parseISO(item), 'dd/MM/yyyy') : null,
    },
    {
      title: 'Comprovante',
      dataIndex: 'id_checkout',
      key: 'id_checkout',
      render: (id_checkout: string) => (
        <Button
          type="primary"
          href={`${process.env.REACT_APP_URL_PORTAL}/checkout/${id_checkout}`}
          target="_blank"
        >
          <MdOpenInNew />
        </Button>
      ),
    },
  ];

  useEffect(() => {
    let data;

    if (query_values.data_inicio && query_values.data_fim) {
      data = [moment(query_values.data_inicio), moment(query_values.data_fim)];
    }

    setFiltros({
      page: 1,
      page_size: 25,
      ...query_values,
      data,
    });
    form.setFieldsValue({ ...query_values, data });
  }, []);

  useEffect(() => {
    if (filtros) {
      fetchCarrinhos();
    }
  }, [filtros]);

  async function fetchCarrinhos() {
    setLoading(true);
    return fetchApi({
      url: '/panel/report',
      method: 'get',
      query_params: { ...filtros },
      onSuccess: data => {
        const list = data.data.map((item: any) => {
          return {
            ...item,
            created_at: format(
              parseISO(item.created_at),
              'dd/MM/yyyy HH:mm:ss',
            ),
          };
        });
        setCarrinho({
          ...data,
          data: list,
        });
        setLoading(false);
      },
      onError: () => {
        message.error('Erro ao buscar os dados');
        setLoading(false);
      },
    });
  }

  function handleResetForm() {
    setQueryParams({});
    form.resetFields();
    setFiltros({
      page: 1,
      page_size: 25,
    });
    fetchCarrinhos();
  }

  function handleSetFiltroValues(data: any) {
    const [data_inicio, data_fim] = [...(data?.data || [])];
    let amount;

    if (data.amount) {
      amount = cleanMoneyMask(data.amount);
    }

    delete data.data;
    delete data.amount;

    const final = {
      ...filtros,
      ...data,
      data_initial: data_inicio ? data_inicio.format('YYYY-MM-DD') : undefined,
      data_final: data_fim ? data_fim.format('YYYY-MM-DD') : undefined,
      ...(isNumber(amount) && { amount }),
    };

    setQueryParams(final);
    setFiltros(final);
  }

  function handleUpdatePagination(page: number, page_size?: number) {
    handleSetFiltroValues({ page, page_size });
  }

  const result_text = useMemo(() => {
    if (carrinho.total) {
      const number_result = numeroMask(
        Number((carrinho && carrinho?.total) || carrinho.total),
        {
          minimumFractionDigits: 0,
        },
      );

      return <b>Total de Relatórios: {number_result}</b>;
    }

    return null;
  }, [carrinho]);

  async function downloadFile(type = 'csv') {
    Modal.info({
      title: 'A exportação utilizará os filtros da pesquisa!',
      centered: true,
      onOk() {
        const today = new Date().toLocaleString('pt-BR');
        const formattedDate = today.replaceAll('/', '-').replaceAll(':', '-');

        return downloadFiles(
          `/panel/report/export?${UrlQuery({ ...filtros })}`,
          `relatorios_acordos ${formattedDate}.${type}`,
        );
      },
    });
  }

  return (
    <>
      <PageHeader
        title="Relatório Acordos e Custas"
        breadcrumb={['Relatório Acordos e Custas', 'Listagem']}
      />

      <CardCollapse title="Filtros">
        <Form
          layout="vertical"
          onFinish={data => handleSetFiltroValues(data)}
          form={form}
        >
          <Row gutter={16}>
            <Col md={8} xs={12}>
              <Form.Item name="id_checkout" label="ID do acordo">
                <Input />
              </Form.Item>
            </Col>
            <Col md={8} xs={12}>
              <Form.Item
                name="identifier"
                label="Identificador"
                rules={[
                  { pattern: /^\d{12}$/, message: 'Identificador inválido' },
                ]}
              >
                <Input maxLength={12} />
              </Form.Item>
            </Col>
            <Col md={8} xs={12}>
              <Form.Item name="guide_number" label="Número de Guia">
                <Input />
              </Form.Item>
            </Col>
            <Col md={8} xs={12}>
              <Form.Item name="amount" label="Valor">
                <ItemInputCurrency
                  onChange={(_, val) =>
                    form.setFieldsValue({ valor_total: val })
                  }
                />
              </Form.Item>
            </Col>
            <Col md={8} xs={12}>
              <Form.Item name="data" label="Data">
                <RangePicker
                  format="DD/MM/YYYY"
                  placeholder={['Data Inicio', 'Data Fim']}
                  allowClear
                />
              </Form.Item>
            </Col>
          </Row>

          <br />

          <Row justify="end">
            <Button onClick={handleResetForm} className="buttonMarginRight">
              Limpar
            </Button>
            <Button type="primary" htmlType="submit">
              Filtrar
            </Button>
          </Row>
        </Form>
      </CardCollapse>

      <Row justify="space-between" align="middle">
        {result_text}
        <Tooltip placement="topRight" title="Exportar para CSV">
          <Button
            onClick={() => downloadFile()}
            type="primary"
            shape="round"
            icon={<MdFileDownload />}
          />
        </Tooltip>
      </Row>

      <br />

      <CardCollapse loading={loading} title="Listagem de acordos">
        <Table
          columns={columns}
          dataSource={carrinho.data}
          loading={loading}
          scroll={{ x: 1600, y: 400 }}
          size="small"
          pagination={{
            total: carrinho.total,
            current: carrinho.page,
            pageSize: carrinho.page_size,
            onChange: handleUpdatePagination,
          }}
        />
      </CardCollapse>
    </>
  );
};

export default RelatoriosAcordos;
