import styled from 'styled-components';

import cores from '../../../styles/colors';
import { header_size, menu_size } from '../../../styles/global';
import devices from '../../../styles/devices';

export const Wrapper = styled.div`
  background-color: ${cores.background_dark};
`;

export const Name = styled.div`
  height: ${header_size};
  width: ${menu_size};
  background-color: #312f30;
  color: ${cores.font_light};
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 30px;
  position: fixed;
  flex-direction: column;
  cursor: pointer;

  h1 {
    color: ${cores.font_light};
    font-size: 23px;
    font-weight: bold;
    margin: 0;

    span {
      font-size: 23px;
      font-weight: 100;
      margin-top: -5px;
    }
  }

  img {
    height: 73px;
  }

  @media only screen and (max-width: ${devices.tablet}) {
    z-index: 3;
    left: -100%;
    transition: 0.5s;

    &.opened {
      left: 0;
    }
  }
`;

export const App = styled.section`
  width: 100%;
  background-color: ${cores.background_light};
  float: right;

  position: fixed;
  margin-top: ${header_size};
  overflow: auto;
  overflow-x: hidden;
  height: calc(100vh - ${header_size});

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .container {
    padding: 25px;
  }

  @media only screen and (max-width: ${devices.tablet}) {
    width: 100%;
    left: 0;
  }
`;

export const AppContainer = styled.div`
  padding: 0 50px 20px;

  @media only screen and (max-width: ${devices.tablet}) {
    padding: 0 20px 20px;
  }
`;

export const Footer = styled.footer`
  width: 100%;
  background-color: ${cores.primary};
  clear: both;
  background-color: ${cores.background_dark};
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 12px 0;
  color: #8e8e8e;

  ul,
  p {
    margin: 0;
  }

  a {
    color: white;
    transition: 0.2s;

    &:hover {
      color: ${cores.primary};
    }
  }

  .contato {
    text-align: center;
  }

  @media only screen and (max-width: ${devices.laptop}) {
    flex-direction: column;

    .copyright {
      margin: 25px 0;
    }
  }

  @media only screen and (max-width: ${devices.tablet}) {
    max-height: 80px;

    .copyright {
      text-align: center;
    }
  }
`;

export const MenuBackground = styled.div`
  @media only screen and (max-width: ${devices.tablet}) {
    &.opened {
      position: fixed;
      background: rgba(0, 0, 0, 0.5);
      height: 100vh;
      width: 100%;
      z-index: 2;
    }
  }
`;
