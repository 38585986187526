import React, { useState } from 'react';
import { Button, Drawer, Timeline } from 'antd';
import { Link } from 'react-router-dom';

function FluxoUsuario() {
  const [drawer, setDrawer] = useState(false); // drawer

  return (
    <>
      <Button onClick={() => setDrawer(true)}>Abrir fluxo de usuário</Button>

      <Drawer
        title="Fluxo de Usuário"
        visible={drawer}
        onClose={() => setDrawer(false)}
        closable
        width="40%"
      >
        <Timeline mode="right">
          <Timeline.Item label="Realizar a aprovação da Permissão do usuário">
            Na tela de <Link to="/permissoes">Permissões</Link>
          </Timeline.Item>
          <Timeline.Item label="Realizar a aprovação dos Vínculos do usuário">
            Na tela de <Link to="/vinculos">Vínculos</Link>
          </Timeline.Item>
          <Timeline.Item label="Aguardar o usuário criar sua conta no nosso Banco Digital">
            Na tela de <Link to="/permissoes">Permissões</Link>
          </Timeline.Item>
          <Timeline.Item label="Enviar contrato para o usuário">
            Na tela de <Link to="/permissoes">Permissões</Link>
          </Timeline.Item>
          <Timeline.Item label="Usuário aceito" color="green" />
        </Timeline>
      </Drawer>
    </>
  );
}

export default FluxoUsuario;
