import {
  Button,
  Col,
  Form,
  Input,
  message,
  Row,
  Select,
  Space,
  Table,
  Upload,
  Typography,
  Tag,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { AiOutlineDownload } from 'react-icons/ai';
import { BiUpload } from 'react-icons/bi';
import CardCollapse from '../../components/CardCollapse';
import PageHeader from '../../components/PageHeader';
import { fetchApi } from '../../services/api';
import downloadFiles from '../../utils/downloadFiles';
import { setQueryParams, useQueryParams } from '../../utils/UrlQuery';
import { BsTrash } from 'react-icons/bs';

const { Paragraph } = Typography;

type IObject = {
  name: string;
  url: string;
};

interface IBucket {
  total: number;
  pagina: number;
  porPagina: number;
  data: IObject[];
}

const BucketPesquisar: React.FC = () => {
  const [formFilters] = Form.useForm();
  const [formUpload] = Form.useForm();
  const [filtros, setFiltros] = useState<any | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const [objectsBucket, setObjectsBucket] = useState({} as IBucket);
  const query = useQueryParams();
  const formData = new FormData();

  useEffect(() => {
    const final = {
      pagina: 1,
      porPagina: 25,
      ...query,
    };

    setFiltros(final);
    formFilters.setFieldsValue(final);
  }, []);

  const handleSetFiltroValues = (data: any) => {
    setFiltros(data);
  };

  useEffect(() => {
    if (filtros) {
      fetchObject();
    }
  }, [filtros]);

  async function fetchObject() {
    setLoading(true);

    return fetchApi({
      url: '/bucket/images',
      method: 'get',
      query_params: {
        ...filtros,
      },
      onSuccess: (data: any) => {
        setLoading(false);
        setObjectsBucket(data);
      },
      onError: () => {
        setLoading(false);
      },
    });
  }

  async function deleteObject(id_images_whitelabel: string) {
    setLoading(true);

    return fetchApi({
      url: `/bucket/images/${id_images_whitelabel}`,
      method: 'delete',
      onSuccess: async () => {
        setLoading(false);
        message.success('Imagem removida com sucesso.');
        await fetchObject();
      },
      onError: () => {
        setLoading(false);
        message.error('Erro ao remover a imagem.');
      },
    });
  }

  function chooseColorTypeDebts(bucket: string): string {
    let color;

    switch (bucket) {
      case 'detran':
        color = 'blue';
        break;

      case 'partner':
        color = 'orange';
        break;

      default:
        color = 'red';
        break;
    }

    return color;
  }

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'nome',
      key: 'nome',
    },
    {
      title: 'URL',
      dataIndex: 'url',
      key: 'url',
      render: (item: string) => (
        <Paragraph copyable={{ tooltips: false }}>{item}</Paragraph>
      ),
    },
    {
      title: 'Bucket',
      dataIndex: 'bucket',
      key: 'bucket',
      render: (bucket: string) => (
        <Tag color={chooseColorTypeDebts(bucket)} key={bucket}>
          {(bucket && bucket === 'partner'
            ? 'parceiro'
            : bucket
          )?.toUpperCase()}
        </Tag>
      ),
    },

    {
      title: 'Download',
      dataIndex: 'id_images_whitelabel',
      key: 'id_images_whitelabel',
      render: (item: any) => (
        <Button
          type="primary"
          onClick={() =>
            downloadFiles(`/bucket/images/${item}`, `image-${item}`)
          }
        >
          <AiOutlineDownload />
        </Button>
      ),
    },

    {
      title: 'Remover',
      dataIndex: 'id_images_whitelabel',
      key: 'id_images_whitelabel',
      render: (item: any) => (
        <Button type="primary" danger onClick={() => deleteObject(item)}>
          <BsTrash />
        </Button>
      ),
    },
  ];

  async function handleSendUpload(data: any) {
    setLoading(true);

    formData.append('file_bucket', data.file_bucket);
    formData.append('bucket_object', data.bucket_object.file.originFileObj);

    return fetchApi({
      url: '/bucket/images',
      method: 'post',
      data: formData,
      onSuccess: async () => {
        setLoading(false);
        await fetchObject();
        message.success('Upload realizado com sucesso.');
        formUpload.resetFields();
      },
      onError: () => {
        setLoading(false);
        message.error('Erro no upload da imagem');
      },
    });
  }

  function handleResetForm() {
    setQueryParams({});
    formFilters.resetFields();
    setFiltros({});
  }

  return (
    <>
      <PageHeader title="Upload de Objetos" />
      <CardCollapse title="Informações do objeto">
        <Form
          layout="vertical"
          onFinish={data => handleSendUpload(data)}
          form={formUpload}
        >
          <Row gutter={16}>
            <Col md={6}>
              <Form.Item
                name="bucket_object"
                extra="O padrão do nome das imagens é [instancia]-[estado].[png ou jpeg]. Ex: detran-sp.png ou energia-rj.jpeg"
              >
                <Upload listType="picture">
                  <Button icon={<BiUpload style={{ marginRight: 8 }} />}>
                    Upload
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item name="file_bucket">
                <Select placeholder="Selecione a instancia">
                  <Select.Option value="detran">Detran</Select.Option>
                  <Select.Option value="partner">Parceiro</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <br />

          <Row justify="end">
            <Button type="primary" htmlType="submit">
              Enviar
            </Button>
          </Row>
        </Form>
      </CardCollapse>

      <CardCollapse title="Filtros">
        <Form
          layout="vertical"
          onFinish={data => handleSetFiltroValues(data)}
          form={formFilters}
        >
          <Row gutter={16}>
            <Col md={6}>
              <Form.Item name="nome" label="Nome">
                <Input />
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item name="url" label="URL">
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <br />

          <Row justify="end">
            <Space>
              <Button onClick={() => handleResetForm()}>Limpar</Button>
              <Button type="primary" htmlType="submit">
                Filtrar
              </Button>
            </Space>
          </Row>
        </Form>
      </CardCollapse>

      <Table
        columns={columns}
        dataSource={objectsBucket.data}
        loading={loading}
        pagination={{
          total: objectsBucket.total,
          current: objectsBucket.pagina,
          pageSize: objectsBucket.porPagina,
          onChange: (pagina, porPagina) =>
            handleSetFiltroValues({ pagina, porPagina }),
        }}
      />
    </>
  );
};

export default BucketPesquisar;
