import api from "../../../services/api";
import { GetBackofficeCheckoutResponse } from "../../Checkout/Info/types";
import { GetPaymentByIdResponse, GetPaymentParams, GetPaymentsResponse } from "./types";

export async function getPayments(params: GetPaymentParams) {
  const response = await api.get<GetPaymentsResponse>('/backoffice/payment', { params })

  return response.data
}

export async function getPaymentbyId(id_payment: string) {
  const response = await api.get<GetPaymentByIdResponse>(`/backoffice/payment/${id_payment}`)

  return response.data.data
}

export async function getCheckoutById(id_checkout: string) {
  const response = await api.get<GetBackofficeCheckoutResponse>(`/backoffice/checkout/${id_checkout}`)

  return response.data
}