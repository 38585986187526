import React, { useMemo } from 'react';

import Route from './Route';
import { PermissoesPerfil } from '../hooks/usePermissions';

import DetranPresencial from '../pages/Detran/Presencial';
import EstabelecimentoPesquisar from '../pages/Estabelecimento/Pesquisar';
import CommissionsSearch from '../pages/Commissions/Search';
import AlterarResponsabilidade from '../pages/Login/AlterarResponsabilidade';
import SimulacaoInfo from '../pages/Simulacao/Info';
import POSEstabelecimento from '../pages/POS/Estabelecimento';
import ArquivoExportacaoPesquisar from '../pages/ArquivoExportacao/Pesquisar';
import VendasExtrato from '../pages/Vendas/Extrato';
import PerfilDadosBancarios from '../pages/Profile/DadosBancarios';
import CreatePaymentLink from '../pages/LinkDePagamento/Create';
import CadastroUsuario from '../pages/TJ/Cadastrar/Usuario';
import CadastroUnidade from '../pages/TJ/Cadastrar/Unidade';
import { RelatoriosAcordos, Vinculos } from '../pages/TJ';
import EnumResponsabilidade from '../types/enum/Responsabilidade';
import { useSelector } from 'react-redux';
import { IState } from '../store';
import { IPerfil } from '../store/modules/user/reducer';
import { canAccessCommissionsPage } from '../utils/permissions';

const ParceiroRoute: React.FC = () => {
  const responsabilidade = useSelector<
    IState,
    EnumResponsabilidade | undefined
  >(state => state.auth.responsabilidade);
  const perfis = useSelector<IState, IPerfil[]>(state => state.user.perfis);

  const renderRouteComissions = useMemo(() => {
    const isAccessAllowed = canAccessCommissionsPage({
      responsabilidade,
      perfis,
    });

    if (isAccessAllowed) {
      return (
        <Route
          path="/commissions"
          exact
          component={CommissionsSearch}
          isPrivate
        />
      );
    }

    return null;
  }, [perfis, responsabilidade]);

  return (
    <>
      <Route path="/simulador" exact component={SimulacaoInfo} isPrivate />

      <Route
        path="/alterar_responsabilidade"
        exact
        component={AlterarResponsabilidade}
        isPrivate
      />

      <Route
        path="/estabelecimentos"
        exact
        component={EstabelecimentoPesquisar}
        isPrivate
      />

      {renderRouteComissions}

      <Route
        path="/detran"
        exact
        component={DetranPresencial}
        isPrivate
        permissionLevelRoute={PermissoesPerfil.DETRAN_BUSCA}
      />

      <Route path="/pos" exact component={POSEstabelecimento} isPrivate />

      <Route
        path="/arquivo_exportacao"
        exact
        component={ArquivoExportacaoPesquisar}
        isPrivate
      />

      <Route
        path="/perfil/dados-bancarios"
        exact
        component={PerfilDadosBancarios}
        isPrivate
      />

      <Route
        path="/cadastro_usuario"
        exact
        component={CadastroUsuario}
        isPrivate
      />
      <Route
        path="/cadastro_unidade"
        exact
        component={CadastroUnidade}
        isPrivate
      />
      <Route path="/vinculos" exact component={Vinculos} isPrivate />
      <Route
        path="/relatorios_acordos"
        exact
        component={RelatoriosAcordos}
        isPrivate
      />

      <Route path="/extrato" exact component={VendasExtrato} isPrivate />

      <Route
        path="/link/create"
        exact
        component={CreatePaymentLink}
        isPrivate
      />
    </>
  );
};

export default ParceiroRoute;
