import React, { useState } from 'react';
import { FormInstance, Input } from 'antd';
import { cleanMask } from 'masks-br';

interface ItemInputBarcodeResquest {
  form: FormInstance;
  formItemName: string;
  setBarCode?: (value: string) => void;
  barcode?: string;
}

const ItemInputBarcode: React.FC<ItemInputBarcodeResquest> = ({
  form,
  formItemName,
  setBarCode: setCode,
  barcode: code,
}: ItemInputBarcodeResquest) => {
  const [barcode, setBarcode] = useState<string>('');

  return (
    <Input
      value={code ?? barcode}
      placeholder="ex: 836300000012481700730007219797539000001370993212"
      onChange={e => {
        setCode
          ? setCode(cleanMask(e.target.value))
          : setBarcode(cleanMask(e.target.value));
        form.setFieldsValue({ [formItemName]: cleanMask(e.target.value) });
      }}
      onKeyPress={event => {
        if (!/[0-9]/.test(event.key)) {
          event.preventDefault();
        }
      }}
    />
  );
};

export default ItemInputBarcode;
