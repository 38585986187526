import React, { Fragment } from 'react';
import { Tooltip } from 'antd';

interface ITooltipConditional {
  condition: boolean;
  title: string;
  children?: React.ReactNode;
  placement?:
    | 'top'
    | 'left'
    | 'right'
    | 'bottom'
    | 'topLeft'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomRight'
    | 'leftTop'
    | 'leftBottom'
    | 'rightTop'
    | 'rightBottom';
}

const TooltipConditional: React.FC<ITooltipConditional> = ({
  condition,
  title,
  placement = 'bottom',
  children,
}: ITooltipConditional) => {
  return condition ? (
    <Tooltip title={title} placement={placement}>
      {children}
    </Tooltip>
  ) : (
    <Fragment>{children}</Fragment>
  );
};

export default TooltipConditional;
