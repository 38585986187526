import React, { useState } from 'react';
import { Button, Col, Form, Input, Row, Select, message } from 'antd';

import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import CardCollapse from '../../../components/CardCollapse';
import PageHeader from '../../../components/PageHeader';
import { fetchApi } from '../../../services/api';
import Option from '../../../types/Option';
import { IState } from '../../../store/index';

const required_message = 'Esse campo é obrigatório';

const select_forma_pagamento: Option[] = [
  {
    label: 'Conta-Corrente',
    value: 'CHECKING',
  },
  {
    label: 'Poupança',
    value: 'SAVINGS',
  },
];
const initialValuesDataBank = {
  cod_banco: '332',
  agencia: '0001',
  tipo: 'CHECKING',
  senha: '00000000000000',
};

const PerfilDadosBancarios: React.FC = () => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [changeDataBanck, setChangeDataBanck] = useState(false);
  const [disablePassword, setDisablePassword] = useState(true);
  const id_usuario = useSelector<IState, string>(
    state => state.user.id_usuario,
  );

  useEffect(() => {
    handleGetDataBank();
  }, [changeDataBanck]);

  async function handleGetDataBank() {
    setLoading(true);
    try {
      const response = await fetchApi({
        url: `/conta_pagamento/${id_usuario}`,
        method: 'get',
      });
      form.setFieldsValue({
        ...response,
        senha: response.senha ? initialValuesDataBank.senha : '',
      });
      setDisablePassword(!!response.senha);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      message.warn('Boleto com vencimento inválido.');
    }
  }

  const handleCreateDataBank = (data: any) => {
    setLoading(true);

    data.id_usuario = id_usuario;

    return fetchApi({
      url: `/conta_pagamento`,
      method: 'post',
      data,
      messages: {
        loading: 'Gravando dados bancários, aguarde...',
      },
      onError: () => {
        setLoading(false);
        message.error('Erro ao gravar dados bancários, tente novamente!');
        setChangeDataBanck(!changeDataBanck);
      },
      onSuccess: () => {
        setLoading(false);
        form.resetFields();
        setChangeDataBanck(!changeDataBanck);
        message.success('Dados bancários gravados com sucesso!');
      },
    });
  };

  async function handleChangePassword() {
    setDisablePassword(!disablePassword);
    form.resetFields(['senha']);
  }

  return (
    <>
      <PageHeader
        title="Dados Bancários"
        breadcrumb={['Perfil', 'Dados Bancários']}
      />

      <CardCollapse header={false}>
        {!loading && (
          <Form
            layout="vertical"
            onFinish={data => handleCreateDataBank(data)}
            form={form}
            initialValues={initialValuesDataBank}
          >
            <Row gutter={16}>
              <Col md={2}>
                <Form.Item
                  name="cod_banco"
                  label="Código do Banco"
                  rules={[{ required: true, message: required_message }]}
                >
                  <Input maxLength={3} disabled />
                </Form.Item>
              </Col>
              <Col md={2}>
                <Form.Item
                  name="agencia"
                  label="Agência"
                  rules={[{ required: true, message: required_message }]}
                >
                  <Input maxLength={4} disabled />
                </Form.Item>
              </Col>
              <Col md={8}>
                <Form.Item
                  name="conta"
                  label="Número da Conta"
                  rules={[{ required: true, message: required_message }]}
                >
                  <Input maxLength={9} />
                </Form.Item>
              </Col>
              <Col md={8}>
                <Form.Item
                  label="Tipo de Conta"
                  name="tipo"
                  rules={[{ required: true, message: required_message }]}
                >
                  <Select options={select_forma_pagamento} />
                </Form.Item>
              </Col>
              <Col md={4}>
                <Form.Item name="senha" label="Senha">
                  <Input.Password maxLength={20} disabled={disablePassword} />
                </Form.Item>
                {disablePassword && (
                  <a onClick={() => handleChangePassword()}>Alterar senha</a>
                )}
              </Col>
            </Row>
            <Row justify="end" style={{ marginTop: 12 }}>
              <Button type="primary" htmlType="submit" loading={loading}>
                Salvar
              </Button>
            </Row>
          </Form>
        )}
      </CardCollapse>
    </>
  );
};

export default PerfilDadosBancarios;
