import React from 'react';
import PageHeader from '../../../components/PageHeader';
import CardCollapse from '../../../components/CardCollapse';
import { Button, Col, Form, Input, Row, Space, message } from 'antd';
import { useMutation } from 'react-query';
import { getBillDetailInfo } from '../services';
import BoletoInfoGeral from '../Info/Geral';
import BoletoInfoData from '../Info/Data';
import { DataBoletoRaw } from '../types';
import { errorInvalidDigitableLineMessage, removeAccentsAndSpecialCharacteres, validatorDigitableLineFormItemRule } from "../../../utils/billet";

type FormSchema = { barcode: string };

const BoletoPesquisarDetalhado: React.FC = () => {
  const [formSearch] = Form.useForm<FormSchema>();

  const {
    isLoading,
    mutate: getBillData,
    data: billData,
    reset,
  } = useMutation({
    mutationKey: ['search-bill'],
    mutationFn: getBillDetailInfo,
    onError(err) {
      const error = err as any;
      const messageError =
        error?.response?.data?.message ||
        error?.message ||
        'Houve um erro inesperado, tente novamente!';
      message.error(messageError);
    },
  });

  function handleResetForm() {
    formSearch.resetFields();
    reset();
  }

  function handleSubmit(data: FormSchema) {
    const cleanDigitableLine = removeAccentsAndSpecialCharacteres(data.barcode)
    getBillData(cleanDigitableLine);
  }

  return (
    <>
      <PageHeader
        title="Pesquisar Boleto"
        breadcrumb={['Boletos', 'Detalhes do boleto']}
      />

      <CardCollapse header={false}>
        <Form layout="vertical" form={formSearch} onFinish={handleSubmit}>
          <Row gutter={16} justify="space-between" align="bottom">
            <Col md={16}>
              <Form.Item
                label="Código de Barras"
                name="barcode"
                rules={[
                  { 
                    validator: (_, value) => validatorDigitableLineFormItemRule(value),
                    message: errorInvalidDigitableLineMessage,
                  }
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col>
              <Space>
                <Button onClick={() => handleResetForm()}>Limpar</Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={isLoading}
                  loading={isLoading}
                >
                  Pesquisar
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </CardCollapse>

      {billData && (
        <>
          <BoletoInfoGeral
            data={billData || ({} as DataBoletoRaw)}
            loading={isLoading}
          />
          <Row gutter={16}>
            <Col md={12} xs={24}>
              <BoletoInfoData
                loading={isLoading}
                data={billData || ({} as DataBoletoRaw)}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default BoletoPesquisarDetalhado;
