import { Pagination, Select } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';

import PageHeader from '../../../components/PageHeader';
import { fetchApi } from '../../../services/api';
import Pagina from '../../../types/Pagina';
import { time_options, time_default_parceiro } from '../../Dashboard';
import EstabelecimentoCard from './EstabelecimentoCard';

const EstabelecimentoPesquisar: React.FC = () => {
  const [estabelecimento, setEstabelecimento] = useState<Pagina>({} as Pagina);
  const [timeFilter, setTimeFilter] = useState(time_default_parceiro);
  const [loading, setLoading] = useState(true);

  const EstabelecimentosList = useMemo(() => {
    return estabelecimento.data?.map(item => (
      <EstabelecimentoCard
        id_usuario={item.id_usuario}
        nome={item.nome}
        cpf={item.cpf}
        cnpj={item.cnpj}
        criado_em={item.criado_em}
        status_permissao={item.status_nome}
        tipo_status={item.status_tipo}
        total_comissao={item.total_comissao}
        faturamento={item.faturamento}
      />
    ));
  }, [estabelecimento]);

  useEffect(() => {
    fetchEstabelecimentos();
  }, [timeFilter]);

  function fetchEstabelecimentos(pagina = 1, porPagina = 20) {
    setLoading(true);

    return fetchApi({
      url: '/usuario/estabelecimento',
      method: 'get',
      query_params: { pagina, porPagina, time: timeFilter },
      onSuccess: data => {
        setEstabelecimento(data);
        setLoading(false);
      },
    });
  }

  return (
    <>
      <PageHeader title="Meus Estabelecimentos">
        <Select
          value={timeFilter}
          onChange={val => setTimeFilter(val)}
          loading={loading}
          style={{ width: '180px' }}
        >
          {time_options}
        </Select>
      </PageHeader>

      {EstabelecimentosList}

      <Pagination
        onChange={fetchEstabelecimentos}
        current={estabelecimento?.pagina || 0}
        pageSize={estabelecimento?.porPagina || 0}
        total={estabelecimento?.total || 0}
      />
    </>
  );
};

export default EstabelecimentoPesquisar;
