import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Typography,
  message,
} from 'antd';
import { Link } from 'react-router-dom';
import { MdRemoveCircle } from 'react-icons/md';

import CardCollapse from '../../../../components/CardCollapse';
import { ItemInputCurrency } from '../../../../components/Form';
import { usePermission } from '../../../../hooks/usePermissions';
import { useMutation, useQuery } from 'react-query';
import {
  deleteTerminalEstablishmentLink,
  getTerminalsEstablishmentLink,
  postTerminalEstablishmentLink,
} from '../../services';
import { format } from 'date-fns';
import Table from '../../../../components/Table';
import { queryClientApp } from '../../../../App';
import UserName from '../../../../components/User/Name';
import {
  TerminalErrorResponse,
  getTerminalErrorMessages,
} from '../../../terminals/services/types';
import { uuid_pattern } from '../../../../utils/pattern';

type IState = {
  userId: string;
};

const { Paragraph } = Typography;
const default_filters = { page: 1, page_size: 25 };
const default_message_field_required = 'Campo obrigatório!';

const CardTerminal: React.FC<IState> = ({ userId }: IState) => {
  const [form] = Form.useForm();
  const { userPermissions } = usePermission();
  const [filters, setFilters] = useState(default_filters);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const { data: terminalLinks, isLoading } = useQuery({
    queryKey: ['terminals-link-establishment', userId, filters],
    queryFn: () =>
      getTerminalsEstablishmentLink({ ...filters, establishment_id: userId }),
    retry: false,
    staleTime: 3 * 60 * 1000,
  });
  const { mutate: unlinkTerminalEstablishment } = useMutation({
    mutationFn: deleteTerminalEstablishmentLink,
    onMutate() {
      message.open({
        type: 'loading',
        duration: 0,
        key: 'terminal-establishment-link',
        content: 'Removendo vínculo de terminal...',
      });
    },
    onSuccess() {
      queryClientApp.invalidateQueries([
        'terminals-link-establishment',
        userId,
      ]);
      message.open({
        type: 'success',
        content: 'Terminal desvinculado com sucesso!',
        key: 'terminal-establishment-link',
      });
    },
    onError(error: any) {
      const errorResponse = error as TerminalErrorResponse;

      const errorText = getTerminalErrorMessages(errorResponse);

      message.open({
        type: 'error',
        content: errorText,
        key: 'terminal-establishment-link',
        duration: 3,
      });
    },
  });
  const {
    mutate: linkTerminalEstablishment,
    isLoading: isLoadingLinkTerminal,
  } = useMutation({
    mutationFn: postTerminalEstablishmentLink,
    onMutate() {
      message.open({
        type: 'loading',
        duration: 0,
        key: 'terminal-establishment-link',
        content: 'Vínculando terminal...',
      });
    },
    onSuccess(data) {
      queryClientApp.invalidateQueries([
        'terminals-link-establishment',
        userId,
      ]);
      queryClientApp.invalidateQueries(['terminals-info', data?.id_terminal]);
      form.resetFields();
      setModalIsOpen(false);
      message.open({
        type: 'success',
        content: 'Terminal vinculado com sucesso!',
        key: 'terminal-establishment-link',
      });
    },
    onError(error: any) {
      const errorResponse = error as TerminalErrorResponse;

      const errorText = getTerminalErrorMessages(errorResponse);

      message.open({
        type: 'error',
        content: errorText,
        key: 'terminal-establishment-link',
        duration: 3,
      });
    },
  });

  function handleAddTerminal(data: any) {
    linkTerminalEstablishment({
      establishment_id: userId,
      ...data,
    });
  }

  function handleUnlinkTerminal(id_terminal_establishment: string) {
    unlinkTerminalEstablishment(id_terminal_establishment);
  }

  const columns = [
    {
      title: 'Id Terminal',
      dataIndex: 'id_terminal',
      key: 'id_terminal',
      render: (id_terminal: string) => (
        <Paragraph copyable={{ tooltips: false, text: id_terminal }}>
          <Link to={`/terminal/${id_terminal}`} className="ant-link">
            {id_terminal}
          </Link>
        </Paragraph>
      ),
    },
    {
      title: 'Criado Em',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (value: string) => {
        const createdAt = format(new Date(value), 'dd/MM/yyyy HH:mm:ss');

        return createdAt;
      },
    },
    {
      title: 'Criado Por',
      dataIndex: 'created_by',
      key: 'created_by',
      render: (userId: string) => <UserName id_user={userId} />,
    },
    {
      title: 'Remover',
      width: 100,
      render: (data: any) => (
        <Popconfirm
          placement="topRight"
          title="Tem certeza que deseja remover essa associação?"
          onConfirm={() => handleUnlinkTerminal(data.id_terminal_establishment)}
          disabled={!userPermissions.POS_DESVINCULAR_POS}
        >
          <Button
            type="primary"
            danger
            disabled={!userPermissions.POS_DESVINCULAR_POS}
          >
            <MdRemoveCircle />
          </Button>
        </Popconfirm>
      ),
    },
  ];

  return (
    <>
      <CardCollapse loading={isLoading} title="Terminais Associados">
        {userPermissions.POS_VINCULAR_POS ? (
          <>
            <Row justify="end">
              <Button
                type="primary"
                htmlType="submit"
                onClick={() => setModalIsOpen(true)}
              >
                Adicionar
              </Button>
            </Row>

            <br />
          </>
        ) : null}

        <Table
          columns={columns}
          dataSource={terminalLinks?.data ?? []}
          size="small"
          loading={isLoading}
          emptyText="Não há terminais vinculados!"
          pagination={{
            total: terminalLinks?.total,
            current: terminalLinks?.page,
            pageSize: terminalLinks?.page_size,
            onChange: (page, page_size) => setFilters({ page, page_size }),
          }}
        />
      </CardCollapse>

      <Modal
        title="Associar Terminal"
        centered
        footer={false}
        visible={modalIsOpen}
        onCancel={() => (!isLoadingLinkTerminal ? setModalIsOpen(false) : null)}
      >
        <Form
          layout="vertical"
          form={form}
          initialValues={{
            lost_amount: 1500,
            month_rent_amount: 50,
          }}
          onFinish={handleAddTerminal}
        >
          <Row gutter={16}>
            <Col md={24}>
              <Form.Item
                name="id_terminal"
                label="Id Terminal"
                rules={[
                  { required: true, message: default_message_field_required },
                  { pattern: uuid_pattern, message: 'Id inválido!' },
                ]}
              >
                <Input maxLength={36} disabled={isLoadingLinkTerminal} />
              </Form.Item>
            </Col>
            <Col md={24}>
              <Form.Item
                label="Id usuário"
                name="user_id"
                extra="Id do usuário responsável!"
                rules={[{ pattern: uuid_pattern, message: 'Id inválido!' }]}
              >
                <Input disabled={isLoadingLinkTerminal} />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                name="month_rent_amount"
                label="Valor Mensal do aluguel"
                rules={[
                  { required: true, message: default_message_field_required },
                ]}
              >
                <ItemInputCurrency
                  onChange={(_, val) =>
                    form.setFieldsValue({ month_rent_amount: val })
                  }
                  disabled={isLoadingLinkTerminal}
                />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                name="lost_amount"
                label="Valor de Perda"
                rules={[
                  { required: true, message: default_message_field_required },
                ]}
              >
                <ItemInputCurrency
                  onChange={(_, val) =>
                    form.setFieldsValue({ lost_amount: val })
                  }
                  disabled={isLoadingLinkTerminal}
                />
              </Form.Item>
            </Col>
            <Col md={24}>
              <Form.Item
                label="Código de Rastreio do Correio"
                name="tracking_code"
                extra="Aqui é o único lugar para colocar o Código de Rastreio do objeto!"
              >
                <Input disabled={isLoadingLinkTerminal} />
              </Form.Item>
            </Col>
          </Row>

          <br />

          <Row justify="end">
            <Button
              type="primary"
              htmlType="submit"
              className="buttonMarginTop"
              loading={isLoadingLinkTerminal}
            >
              Vincular
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default CardTerminal;
