import React from 'react';

import Route from './Route';
import { PermissoesPerfil } from '../hooks/usePermissions';

import CarrinhoInfo from '../pages/Carrinho/Info';
import CarrinhoPesquisar from '../pages/Carrinho/Pesquisar';
import CheckoutSearch from '../pages/Checkout/Pesquisar';
import PaymentSearch from '../pages/Payments/Search';
import ChargeSearch from '../pages/Charges/Search';
import ChargeInfo from '../pages/Charges/Info';
import PaymentInfo from '../pages/Payments/Info';
import PixSearch from '../pages/Pix/Pesquisar';
import CarrinhoTotemInfo from '../pages/Checkout/Info';
import ComissaoPesquisar from '../pages/Comissao/Pesquisar';
import ContratoSignatariosPadroes from '../pages/Contrato/SignatariosPadroes';
import EstabelecimentoPesquisar from '../pages/Estabelecimento/Pesquisar';
import FormaCondicaoPagamentoPesquisar from '../pages/FormaCondicaoPagamento/Pesquisar';
import PermissaoPesquisar from '../pages/Permissao/Pesquisar';
import POSCadastro from '../pages/POS/Cadastro';
import POSPesquisar from '../pages/POS/Pesquisar';
import ResponsabilidadeEditar from '../pages/Responsabilidade/Editar';
import ResponsabilidadePesquisar from '../pages/Responsabilidade/Pesquisar';
import SimulacaoInfo from '../pages/Simulacao/Info';
import UsuarioCadastro from '../pages/Usuario/Cadastro';
import UsuarioInfo from '../pages/Usuario/Info';
import UsuarioPesquisar from '../pages/Usuario/Pesquisar';
import UsuarioVinculo from '../pages/Usuario/Vinculo';
import VinculoPesquisar from '../pages/Vinculo/Pesquisar';
import PerfilPesquisar from '../pages/Perfil/Pesquisar';
import PerfilCadastro from '../pages/Perfil/Cadastro';
import ClienteApiPesquisar from '../pages/ClienteApi/Pesquisar';
import ClienteApiInfo from '../pages/ClienteApi/Info';
import ClienteApiChaveLog from '../pages/ClienteApi/ChaveLog';
import BoletoPesquisar from '../pages/Boleto/Pesquisar';
import DetranPesquisar from '../pages/Detran/Pesquisar';
import DetranCadastro from '../pages/Detran/Cadastro';
import RelatorioGeral from '../pages/Relatorio/Geral';
import RelatorioArquivoRetorno from '../pages/Relatorio/ArquivoRetorno';
import UsuarioEditar from '../pages/Usuario/Editar';
import RelatorioFaturamento from '../pages/Relatorio/Faturamento';
import DivergenciaAdquirencia from '../pages/Relatorio/DivergenciaAdquirencia';
import ParceiroPesquisar from '../pages/Parceiro/Pesquisar';
import ParceiroCadastro from '../pages/Parceiro/Cadastro';
import CarrinhoCriar from '../pages/Carrinho/Criar';
import BoletoInfo from '../pages/Boleto/Info';
import ClientePesquisar from '../pages/Cliente/ClientePesquisar';
import BucketPesquisar from '../pages/Bucket';
import Clientes from '../pages/Clientes/Pesquisar';
import PesquisarSMS from '../pages/SMS/Pesquisar';
import BoletoPesquisarDetalhado from '../pages/Boleto/PesquisarDetalhado';
import GeneralQueryRules from '../pages/General/QueryRules/Search';
import Transferencias from '../pages/Transferencias';
import TerminalSearch from "../pages/terminals/search";
import TerminalInfo from "../pages/terminals/info";
import TerminalRegister from "../pages/terminals/register";
import TerminalEdit from "../pages/terminals/edit";

const AdminRoutes: React.FC = () => {
  return (
    <>
      <Route
        path="/usuario"
        exact
        component={UsuarioPesquisar}
        isPrivate
        permissionLevelRoute={PermissoesPerfil.USUARIO_LISTAGEM}
      />
      <Route
        path="/usuario/:id"
        exact
        component={UsuarioInfo}
        isPrivate
        permissionLevelRoute={PermissoesPerfil.USUARIO_INFORMACOES}
      />
      <Route
        path="/usuario/:id/editar"
        exact
        component={UsuarioEditar}
        isPrivate
        permissionLevelRoute={PermissoesPerfil.USUARIO_INFORMACOES}
      />
      <Route
        path="/usuario/criar"
        exact
        component={UsuarioCadastro}
        isPrivate
        permissionLevelRoute={PermissoesPerfil.USUARIO_CADASTRAR}
      />
      <Route
        path="/usuario/:id/vinculo"
        exact
        component={UsuarioVinculo}
        isPrivate
        permissionLevelRoute={PermissoesPerfil.USUARIO_VISUALIZAR_VINCULOS}
      />

      <Route
        path="/regras/consultas"
        exact
        component={GeneralQueryRules}
        isPrivate
        permissionLevelRoute={PermissoesPerfil.USUARIO_LISTAGEM}
      />

      <Route
        path="/pos"
        exact
        component={POSPesquisar}
        isPrivate
        permissionLevelRoute={PermissoesPerfil.POS_LISTAGEM}
      />
      <Route
        path="/pos/criar"
        exact
        component={POSCadastro}
        isPrivate
        permissionLevelRoute={PermissoesPerfil.POS_CADASTRAR}
      />
      <Route
        path="/pos/:id/editar"
        exact
        component={POSCadastro}
        isPrivate
        permissionLevelRoute={PermissoesPerfil.POS_EDITAR}
      />

      <Route
        path="/forma-pagamento"
        exact
        component={FormaCondicaoPagamentoPesquisar}
        isPrivate
        permissionLevelRoute={PermissoesPerfil.FORMA_CONDICAO_LISTAGEM}
      />

      <Route
        path="/responsabilidade"
        exact
        component={ResponsabilidadePesquisar}
        isPrivate
      />
      <Route
        path="/responsabilidade/:crypt_id"
        exact
        component={ResponsabilidadeEditar}
        isPrivate
      />

      <Route
        path="/perfil"
        exact
        component={PerfilPesquisar}
        isPrivate
        permissionLevelRoute={PermissoesPerfil.PERFIL_LISTAGEM}
      />
      <Route
        path="/perfil/:id"
        exact
        component={PerfilCadastro}
        isPrivate
        permissionLevelRoute={PermissoesPerfil.PERFIL_EDITAR}
      />
      <Route
        path="/perfil/new"
        exact
        component={PerfilCadastro}
        isPrivate
        permissionLevelRoute={PermissoesPerfil.PERFIL_CADASTRAR}
      />

      <Route path="/boleto" exact component={BoletoPesquisar} isPrivate />
      <Route path="/boleto/:id" exact component={BoletoInfo} isPrivate />
      <Route
        path="/boleto/pesquisar"
        exact
        component={BoletoPesquisarDetalhado}
        isPrivate
      />

      <Route
        path="/carrinho"
        exact
        component={CarrinhoPesquisar}
        isPrivate
        permissionLevelRoute={PermissoesPerfil.CARRINHO_LISTAGEM}
      />
      <Route
        path="/checkout"
        exact
        component={CheckoutSearch}
        isPrivate
        permissionLevelRoute={PermissoesPerfil.CARRINHO_LISTAGEM}
      />
      <Route
        path="/payment"
        exact
        component={PaymentSearch}
        isPrivate
      />
      <Route
        path="/charge"
        exact
        component={ChargeSearch}
        isPrivate
      />
      <Route
        path="/charge/:id"
        exact
        component={ChargeInfo}
        isPrivate
      />
      <Route
        path="/payment/:id"
        exact
        component={PaymentInfo}
        isPrivate
      />
      <Route
        path="/pix"
        exact
        component={PixSearch}
        isPrivate
      />
      <Route
        path="/checkout/:id"
        exact
        component={CarrinhoTotemInfo}
        isPrivate
        permissionLevelRoute={PermissoesPerfil.CARRINHO_INFORMACAO}
      />
      <Route
        path="/carrinho/:id"
        exact
        component={CarrinhoInfo}
        isPrivate
        permissionLevelRoute={PermissoesPerfil.CARRINHO_INFORMACAO}
      />
      <Route
        path="/carrinho-novo"
        exact
        component={CarrinhoCriar}
        isPrivate
        permissionLevelRoute={PermissoesPerfil.CARRINHO_CADASTRAR_MANUALMENTE}
      />
      <Route
        path="/cliente"
        exact
        component={ClientePesquisar}
        isPrivate
        permissionLevelRoute={PermissoesPerfil.CARRINHO_LISTAGEM}
      />

      <Route
        path="/comissao/pendente"
        exact
        component={ComissaoPesquisar}
        isPrivate
        permissionLevelRoute={PermissoesPerfil.COMISSAO_LISTAGEM}
      />

      <Route
        path="/permissoes"
        exact
        component={PermissaoPesquisar}
        isPrivate
        permissionLevelRoute={PermissoesPerfil.PERMISSAO_LISTAGEM}
      />

      <Route
        path="/vinculos"
        exact
        component={VinculoPesquisar}
        isPrivate
        permissionLevelRoute={PermissoesPerfil.VINCULO_LISTAGEM}
      />

      <Route
        path="/cliente-api"
        exact
        component={ClienteApiPesquisar}
        isPrivate
        permissionLevelRoute={PermissoesPerfil.CLIENTE_API_BUSCAR}
      />
      <Route
        path="/cliente-api/:id"
        exact
        component={ClienteApiInfo}
        isPrivate
        permissionLevelRoute={PermissoesPerfil.CLIENTE_API_INFORMACAO}
      />
      <Route
        path="/cliente-api/chave/:id/log"
        exact
        component={ClienteApiChaveLog}
        isPrivate
        permissionLevelRoute={PermissoesPerfil.CLIENTE_API_LOGS}
      />

      <Route
        path="/contrato/signatarios"
        exact
        component={ContratoSignatariosPadroes}
        isPrivate
        permissionLevelRoute={
          PermissoesPerfil.CONTRATO_SIGNATARIO_PADRAO_LISTAGEM
        }
      />

      <Route path="/simulador" exact component={SimulacaoInfo} isPrivate />

      <Route
        path="/estabelecimentos"
        exact
        component={EstabelecimentoPesquisar}
        isPrivate
      />

      <Route path="/detran" exact component={DetranPesquisar} isPrivate />
      <Route path="/detran/new" exact component={DetranCadastro} isPrivate />
      <Route path="/detran/:id" exact component={DetranCadastro} isPrivate />

      <Route path="/parceiro" exact component={ParceiroPesquisar} isPrivate />
      <Route
        path="/parceiro/new"
        exact
        component={ParceiroCadastro}
        isPrivate
      />
      <Route
        path="/parceiro/:id"
        exact
        component={ParceiroCadastro}
        isPrivate
      />

      <Route
        path="/relatorio/geral"
        exact
        component={RelatorioGeral}
        isPrivate
      />
      <Route
        path="/relatorio/faturamento"
        exact
        component={RelatorioFaturamento}
        isPrivate
      />
      <Route
        path="/relatorio/arquivo_retorno"
        exact
        component={RelatorioArquivoRetorno}
        isPrivate
      />
      <Route
        path="/relatorio/divergencia_adquirencia"
        exact
        component={DivergenciaAdquirencia}
        isPrivate
      />

      <Route
        path="/bucket/images"
        exact
        component={BucketPesquisar}
        isPrivate
      />

      <Route
        path="/transferencias"
        exact
        component={Transferencias}
        isPrivate
      />

      <Route path="/clientes" exact component={Clientes} isPrivate />
      <Route path="/sms" exact component={PesquisarSMS} isPrivate />

      <Route 
        exact
        isPrivate
        path="/terminal"
        component={TerminalSearch}
      />
      <Route 
        exact
        isPrivate
        path="/terminal/new"
        component={TerminalRegister}
      />
      <Route 
        exact
        isPrivate
        path="/terminal/:id_terminal([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-4[0-9a-fA-F]{3}-[89aAbB][0-9a-fA-F]{3}-[0-9a-fA-F]{12})"
        component={TerminalInfo}
      />
      <Route 
        exact
        isPrivate
        path="/terminal/:id_terminal([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-4[0-9a-fA-F]{3}-[89aAbB][0-9a-fA-F]{3}-[0-9a-fA-F]{12})/edit"
        component={TerminalEdit}
      />
    </>
  );
};

export default AdminRoutes;
