export interface ApiResponseError {
  message: Array<string> | string
  error?: string
  statusCode: number
}

export enum EnumMessageWithoutCode {
  //Create API Client
  NAME_MUST_BE_STRING = 'name must be a string',
  DESCRIPTION_MUST_BE_STRING = 'description must be a string',
  API_CLIENT_ALREADY_CREATED_FOR_THIS_ESTABLISHMENT = 'Api client already created for this establishment',

  //Create API Client 
  EACH_VALUE_IN_SCOPES_MUST_BE_A_STRING = 'each value in scopes must be a string',

  //Create API Client Scope
  BAD_REQUEST = 'Bad Request'
}

export const errorMessages = {
  [EnumMessageWithoutCode.NAME_MUST_BE_STRING]: 'Preencha o nome para continuar.',
  [EnumMessageWithoutCode.DESCRIPTION_MUST_BE_STRING]: 'Preencha a descrição para continuar.',
  [EnumMessageWithoutCode.EACH_VALUE_IN_SCOPES_MUST_BE_A_STRING]: 'Selecione ao menos um escopo para continuar.',
  [EnumMessageWithoutCode.API_CLIENT_ALREADY_CREATED_FOR_THIS_ESTABLISHMENT]: 'Esse estabelecimento já está em uso.',
  [EnumMessageWithoutCode.BAD_REQUEST]: 'Selecione um escopo para continuar.'
}

export function GetAuthServiceApiErrorMessages(error: ApiResponseError, defaultMessage: string, statusCode?: number) {
  let message: string

  if (Array.isArray(error.message)) {
    if (error.message.length == 1 || error.message[0] == EnumMessageWithoutCode.EACH_VALUE_IN_SCOPES_MUST_BE_A_STRING) {
      message = error.message[0]
    } else {
      return 'Preencha o nome e a descrição para continuar.'
    }
  } else {
    message = error.message
    if (statusCode && message === EnumMessageWithoutCode.BAD_REQUEST) {
      return defaultMessage
    }
  }

  return errorMessages[message as EnumMessageWithoutCode] || defaultMessage
}