import api from "../../../services/api";
import { GetChargeByIdResponse, GetChargesParams, GetChargesResponse } from "./types";

export async function getCharges(params: GetChargesParams) {
  const response = await api.get<GetChargesResponse>('/backoffice/charge', { params })

  return response.data
}


export async function getChargeById(id_charge: string) {
  const response = await api.get<GetChargeByIdResponse>(`/backoffice/charge/${id_charge}`)

  return response.data
}