import React from 'react';
import { Tag } from 'antd';

export default function findTagColorByTipo(tipo: string) {
  switch (tipo) {
    case 'info':
      return 'blue';
    case 'success':
      return 'green';
    case 'danger':
      return 'red';
    case 'warning':
      return 'gold';
  
    default:
      return '';
  }
}

export function findTagByTipo(status: string, tipo: string) {
  return (
    <Tag color={findTagColorByTipo(tipo)}>
      {status}
    </Tag>
  )
}