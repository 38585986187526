import { Timeline, Typography } from 'antd';
import { format } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';

import {
  AiFillCheckCircle,
  AiFillInfoCircle,
  AiFillCloseCircle,
  AiFillCreditCard,
  AiOutlineCreditCard,
} from 'react-icons/ai';
import { FaShieldAlt } from 'react-icons/fa';
import { RiFilePaperLine, RiMoneyDollarCircleFill } from 'react-icons/ri';
import { MdFileUpload, MdOutlineCallReceived, MdPayment } from 'react-icons/md';
import CardCollapse from '../../../../components/CardCollapse';
import { BoletoStatus, EnumPaymentStatus, PaymentTimeline } from '../../types';
import { fetchApi } from '../../../../services/api';
import { paymentTimelineToBoletoStatus } from '../../mapper';

export function getColor(info: string) {
  switch (info) {
    case 'danger':
    case 'error':
      return 'red';
    case 'info':
      return 'blue';
    case 'success':
      return 'green';

    default:
      return 'gray';
  }
}

function findStatusBoletoIcon(tipo: string, id_status: string) {
  if (id_status === EnumPaymentStatus.RECEIVED) {
    return <MdOutlineCallReceived />;
  }

  if (id_status === EnumPaymentStatus.STARTED) {
    return <MdPayment />;
  }

  if (id_status === EnumPaymentStatus.PAYMENT_DONE) {
    return <RiMoneyDollarCircleFill />;
  }

  switch (tipo) {
    case 'danger':
    case 'error':
      return <AiFillCloseCircle />;
    case 'info':
      return <AiFillInfoCircle />;
    case 'success':
      return <AiFillCheckCircle />;

    default:
      return null;
  }
}

type IBoletoInfoStatus = {
  loading: boolean;
  id_payment: string;
};

const { Text } = Typography;

const BoletoInfoStatus: React.FC<IBoletoInfoStatus> = ({
  loading,
  id_payment,
}: IBoletoInfoStatus) => {
  const [status, setStatus] = useState<BoletoStatus[]>([] as BoletoStatus[]);

  useEffect(() => {
    fetchPaymentTimeline();
  }, [id_payment]);

  async function fetchPaymentTimeline() {
    return await fetchApi({
      url: `/backoffice/checkout/payment/${id_payment}/timeline`,
      method: 'get',
      headers: {
        'api-version': '1',
      },
      onSuccess: data => {
        setStatus(
          data.timeline.map((item: PaymentTimeline) =>
            paymentTimelineToBoletoStatus(item),
          ),
        );
      },
    });
  }

  const status_list = useMemo(() => {
    return (
      <Timeline>
        {status?.map((item: any) => (
          <Timeline.Item
            dot={findStatusBoletoIcon(item.tipo, item.id_status)}
            color={getColor(item.tipo)}
            key={item.id_status}
          >
            <Text type={item.tipo}>{item.nome}</Text>
            <br />
            <Text type="secondary">
              {item.criado_em
                ? format(new Date(item.criado_em), 'dd/MM/yyyy HH:mm:ss')
                : null}
            </Text>
            {item.mensagem ? (
              <p style={{ marginTop: 15, marginBottom: 0 }}>{item.mensagem}</p>
            ) : null}
          </Timeline.Item>
        ))}
      </Timeline>
    );
  }, [status]);

  return (
    <>
      <CardCollapse title="Timeline" loading={loading}>
        {status_list}
      </CardCollapse>
    </>
  );
};

export default BoletoInfoStatus;
