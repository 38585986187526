import React from 'react';
import { useHistory } from 'react-router-dom';

import Header from '../../../components/Header';
import Logo from '../../../assets/image/logo.svg';

import { Wrapper, Name, App, Footer, AppContainer } from './styles';

const ano = new Date().getFullYear();

const DefaultLayout: React.FC = ({ children }: any) => {
  const history = useHistory();

  return (
    <Wrapper>
      <Name onClick={() => history.push('/dashboard')}>
        <img src={Logo} alt="Logo Parcelamos Tudo" width={250} />
      </Name>
      <Header />
      <App>
        <AppContainer>{children}</AppContainer>

        <iframe
          src="https://parcelamostudo.instatus.com/embed-status/eae324fd/light-sm"
          title="Sistema status"
          // width="230"
          height="61"
          style={{ border: 'none', alignSelf: 'center', marginTop: 'auto' }}
        />

        <Footer>
          <div className="copyright">
            Copyright @ {ano} Parcelamos Tudo - Todos os direitos reservados
          </div>
        </Footer>
      </App>
    </Wrapper>
  );
};

export default DefaultLayout;
