import { useParams } from 'react-router-dom';
import PageHeader from '../../../components/PageHeader';
import { useQuery } from "react-query";
import ClientApiInfoGeral from "./Geral";
import InfoChaveClientApi from "./Chaves";
import { getApiClientDetail } from "../services";
import { message } from "antd";
import React from "react";

const ClienteApiInfo: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { isLoading, data: client } = useClientDetail(id);

  function useClientDetail(id_api_client: string) {
    return useQuery({
      queryKey: ['client', id_api_client],
      queryFn: () => getApiClientDetail(id_api_client),
      staleTime: 2 * 60 * 1000,
      retry: false,
      enabled: !!id_api_client,
      onError: (err: any) => {
        message.error(err?.message || 'Erro ao buscar Cliente API');
      },
    });
  }

  return (
    <>
      <PageHeader
        title="Informações do Cliente de API"
        breadcrumb={['Clientes API', 'Informações']}
      />

      <ClientApiInfoGeral
        data={client}
        loading={isLoading}
      />

      <InfoChaveClientApi
        loading={isLoading}
        api_client={client}
      />
    </>
  );
};

export default ClienteApiInfo;