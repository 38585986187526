import { useQuery } from 'react-query';
import { getBonds } from '../services';
import type { UseQueryOptions } from 'react-query';
import { Pagination } from '../../../../types/Pagina';

type QueryOptions =
  | Omit<
      UseQueryOptions<unknown, unknown, unknown, any[]>,
      'queryKey' | 'queryFn'
    >
  | undefined;

interface Functions<R> {
  onError?: (error: any) => void;
  onSuccess?: (data: R) => void;
}

export interface Filters {
  nome_usuario?: string;
  id_responsabilidade?: number[];
  nome_estabelecimento?: string;
  id_status_vinculo?: number[];
  pagina: number;
  porPagina: number;
}

export function useBonds<R>(
  filters: Filters,
  { onError, onSuccess }: Functions<Pagination<R>>,
  options?: QueryOptions,
) {
  return useQuery(
    ['bonds', filters],
    () => getBonds(filters, onError, onSuccess),
    options,
  );
}
