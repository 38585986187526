import { useMutation, useQuery } from "react-query";
import { createPartnerService, deletePartnerService, getPartnerService, getPartnersService, updatePartnerService } from "../provider/partner.provider";
import { queryClientApp } from "../../../App";
import { GetPartnersServiceProps } from "../provider/dto/partner.dto";

type UseGetPartnerProps = {
  id_partner: string;
  enabled: boolean
}

export function useCreatePartner() {
  return useMutation(['create-partner'], createPartnerService)
}
export function useUpdatePartner() {
  return useMutation(['update-partner'], updatePartnerService, {
    onSuccess: () => {
      queryClientApp.invalidateQueries('get-partner')
    }
  })
}

export function useDeletePartner() {
  return useMutation(['delete-partner'], deletePartnerService, {
    onSuccess: () => {
      queryClientApp.invalidateQueries('get-partner')
    }
  })
}

export function useGetPartner({ id_partner, enabled }: UseGetPartnerProps) {
  return useQuery(['get-partner', id_partner], () => getPartnerService(id_partner), { enabled })
}

export function useGetPartners(params: GetPartnersServiceProps) {
  return useQuery(['get-partner', params], () => getPartnersService(params))
}