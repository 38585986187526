import React, { useState, useEffect } from 'react';
import {
  Button,
  Form,
  Row,
  Col,
  Input,
  Select,
  Typography,
  DatePicker,
  message,
} from 'antd';
import { format } from 'date-fns';
import { dinheiroMask } from 'masks-br';
import { ColumnsType } from 'antd/lib/table';
import 'moment/locale/pt-br';
import { useQuery } from 'react-query';

import PageHeader from '../../../components/PageHeader';
import Table from '../../../components/Table';
import CardCollapse from '../../../components/CardCollapse';
import { ItemInputCurrency } from '../../../components/Form';
import {
  setQueryParams,
  useQueryParams,
} from '../../../utils/UrlQuery';
import { usePermission } from '../../../hooks/usePermissions';

import { GetAllPixParams, getAllPix } from "../services";
import { pixStatusOptions } from "../services/types";
const { Paragraph } = Typography;

const { RangePicker } = DatePicker;

const uuid_pattern = new RegExp(
  '^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$',
  'i',
);

const PixSearch: React.FC = () => {
  const default_filters = { page: 1, page_size: 25 };

  const [form] = Form.useForm();
  const query_values = useQueryParams();
  const { getPermissions } = usePermission();

  const permissoes = getPermissions();

  const [filters, setFilters] = useState<GetAllPixParams | undefined>({ page: 1, page_size: 25 });

  const { isLoading, data: pixList } = useQuery({
    queryKey: ['pix-list', filters],
    queryFn: () => getAllPix(filters),
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
    refetchOnReconnect: false,
    onError(err: any) {
      message.error(err.message)
    },
  })

  useEffect(() => {
    setFilters({
      ...default_filters,
      ...query_values,
    });
    form.setFieldsValue({ ...query_values });
  }, []);

  function handleResetForm() {
    setQueryParams(default_filters);
    form.resetFields();
    setFilters(default_filters);
  }

  function handleSetFilterValues(data: any) {
    let [start_date, end_date] = [...(data?.data || [])];

    if (!start_date && !end_date) {
      const dataField = form.getFieldValue('data') as any;
      [start_date, end_date] = dataField || [];
    }

    delete data.data;

    let final = {
      ...filters,
      ...data,
      start_date: start_date ? start_date.format('YYYY-MM-DD') : undefined,
      end_date: end_date ? end_date.format('YYYY-MM-DD') : undefined,
    };

    if (!data.page) {
      final = { ...final, ...default_filters };
    }

    setQueryParams(final);
    setFilters(final);
  }

  function handleUpdatePagination(page: number, page_size?: number) {
    handleSetFilterValues({ page, page_size });
  }

  const columns: ColumnsType<any> = [
    {
      key: 'id_pix',
      dataIndex: 'id_pix',
      title: '#',
      render(value) {
        return <Paragraph copyable={{ tooltips: false }}>{value}</Paragraph>
      },
    },
    {
      title: 'Valor',
      key: 'amount_total',
      dataIndex: 'amount_total',
      align: 'center',
      render: (item: number) => dinheiroMask(item),
    },

    {
      title: 'Criado em',
      align: 'center',
      key: 'created_at',
      dataIndex: 'created_at',
      render: (value) => format(
        new Date(value),
        'dd/MM/yyyy HH:mm:ss',
      ),
    },
    {
      title: 'Status',
      key: 'id_status',
      dataIndex: 'id_status',
      align: 'center',
      render: (item: string): any => {
        const status = pixStatusOptions.find(status => status.value === item)

        return status?.label
      },
    },
  ];

  return (
    <>
      <PageHeader title="Pix" breadcrumb={['Pix']}>

      </PageHeader>

      <CardCollapse title="Filtros">
        <Form
          layout="vertical"
          onFinish={data => handleSetFilterValues(data)}
          form={form}
        >
          <Row gutter={16}>
            <Col md={5} xs={12}>
              <Form.Item name="id_pix" label="Id" rules={[
                {
                  pattern: uuid_pattern,
                  message: 'Deve ser um ID válido!'
                 },
              ]}>
                <Input />
              </Form.Item>
            </Col>
            <Col md={6} xs={12}>
              <Form.Item name="status" label="Status">
                <Select
                  options={pixStatusOptions}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col md={6} xs={12}>
              <Form.Item name="amount_total" label="Valor">
                <ItemInputCurrency
                  onChange={(_, val) =>
                    form.setFieldsValue({ amount_total: val || undefined })
                  }
                />
              </Form.Item>
            </Col>
            <Col md={6} xs={12}>
              <Form.Item name="data" label="Data de criação">
                <RangePicker
                  format="DD/MM/YYYY"
                  placeholder={['Data Inicio', 'Data Fim']}
                  allowClear
                />
              </Form.Item>
            </Col>
          </Row>

          <br />

          <Row justify="end">
            <Button onClick={handleResetForm} className="buttonMarginRight">
              Limpar
            </Button>
            <Button type="primary" htmlType="submit">
              Filtrar
            </Button>
          </Row>
        </Form>
      </CardCollapse>

      <Table
        columns={columns}
        dataSource={pixList?.data ?? []}
        loading={isLoading}
        rowKey="id_pix"
        size="small"
        pagination={{
          total: pixList?.total,
          current: pixList?.page,
          pageSize: pixList?.page_size,
          onChange: handleUpdatePagination,
        }}
      >

      </Table>
    </>
  );
};

export default PixSearch;
