import api from '../../../services/api';
import {
  CreatePaymentLinkResponse,
  GetPaymentLinksQueryParams,
  GetPaymentLinksResponse,
  PaymentLinkInfoData,
  PaymentLinkInfoResponse,
  TCreatePaymentLink,
} from './types/links';

export async function getLinks(
  filters: GetPaymentLinksQueryParams,
  isAdmin: boolean,
): Promise<GetPaymentLinksResponse> {
  const getPaymentLinkUrl = isAdmin
    ? '/backoffice/payment-link'
    : '/panel/payment-link';

  const response = await api.get(getPaymentLinkUrl, { params: filters });

  return response.data;
}

export async function getPaymentLinkInfo(
  id_payment_link: string,
  isAdmin: boolean,
): Promise<PaymentLinkInfoData> {
  const getPaymentLinkUrl = isAdmin
    ? `/backoffice/payment-link/${id_payment_link}`
    : `/panel/payment-link/${id_payment_link}`;

  const response = await api.get<PaymentLinkInfoResponse>(getPaymentLinkUrl);

  return response.data;
}

export async function createPaymentLink(
  data: TCreatePaymentLink,
  id_establishment: string,
): Promise<CreatePaymentLinkResponse> {

  const response = await api.post<CreatePaymentLinkResponse>(
    `/panel/payment-link`,
    data,
    {
      headers: {
        'id-establishment': id_establishment,
      },
    },
  );

  return response.data;
}
