import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Radio,
  Row,
  Select,
  Typography,
} from 'antd';
import { celularMask, cepMask, cleanMask, cnpjMask, cpfMask } from 'masks-br';
import gerador from 'generate-password';
import { useHistory } from 'react-router-dom';
import Axios from 'axios';

import CardCollapse from '../../../components/CardCollapse';
import PageHeader from '../../../components/PageHeader';
import { fetchApi } from '../../../services/api';
import SearchCEP from '../../../services/SearchCEP';
import { useUtils } from '../../../hooks/useUtils';
import Option from '../../../types/Option';

const { Text, Paragraph } = Typography;

const default_message = 'Esse campo é obrigatório';

const UsuarioCadastro: React.FC = () => {
  const history = useHistory();
  const { getEstadoCivil, getEstado, getResponsabilidade } = useUtils();

  const [tipoPessoa, setTipoPessoa] = useState<'fisica' | 'juridica'>('fisica');
  const [responsabilidadesOptions, setResponsabilidadesOptions] = useState<
    Option[]
  >([]);
  const [estadoOptions, setEstadoOptions] = useState<Option[]>([]);
  const [cidadeOptions, setCidadeOptions] = useState<Option[]>([]);
  const [ramoAtividadeOptions, setRamoAtividadeOptions] = useState<Option[]>(
    [],
  );
  const [newRamoAtividade, setNewRamoAtividade] = useState('');
  const [estadoCivil] = useState<Option[]>(() =>
    getEstadoCivil().map(item => ({ value: item, label: item })),
  );
  const [loading, setLoading] = useState({
    estado: true,
    responsabilidade: true,
    ramo_atividade: true,
    cidade: false,
    all: true,
  });
  const [landing, setLanding] = useState({
    url: <Text type="warning">É necessário cadastrar o código de usuário</Text>,
    cod_usuario: '',
    email: '',
  });

  const [form] = Form.useForm();

  const senha_default = useMemo(() => {
    return gerador.generate({ uppercase: false, numbers: false, length: 8 });
  }, []);

  useEffect(() => {
    const fetchAll = [
      fetchResponsabilidades(),
      fetchEstado(),
      fetchRamoAtividade(),
    ];

    Promise.all(fetchAll).then(() => {
      setLoading({
        ...loading,
        estado: false,
        responsabilidade: false,
        ramo_atividade: false,
        all: false,
      });
    });
  }, []);

  async function fetchResponsabilidades() {
    const responsabilidade_response = await getResponsabilidade();

    setResponsabilidadesOptions(
      responsabilidade_response.map((item: any) => ({
        value: item.id_responsabilidade,
        label: item.nome,
      })),
    );
  }

  async function fetchEstado() {
    const estado_response = await getEstado();

    setEstadoOptions(
      estado_response.map(item => ({
        value: item.id_estado,
        label: `${item.nome} - ${item.sigla}`,
        sigla: item.sigla,
      })),
    );
  }

  async function fetchRamoAtividade() {
    return fetchApi({
      url: '/ramo_atividade',
      method: 'get',
      onSuccess: data => {
        setRamoAtividadeOptions(
          data.map((item: any) => ({
            value: item.id_ramo_atividade,
            label: item.nome,
          })),
        );
      },
    });
  }

  async function handleStoreRamoAtividade() {
    if (!newRamoAtividade.length) {
      message.warn('Insira algum valor');
      return;
    }

    setLoading({ ...loading, ramo_atividade: true });

    await fetchApi({
      url: '/ramo_atividade',
      method: 'post',
      data: {
        nome: newRamoAtividade,
      },
      messages: {
        loading: 'Inserindo, aguarde...',
        success: 'Ramo de Atividade inserido com sucesso!',
        error: 'Erro ao inserir, tente novamente!',
      },
      onSuccess: data => {
        setNewRamoAtividade('');
        setRamoAtividadeOptions([
          ...ramoAtividadeOptions,
          {
            value: data.id_ramo_atividade,
            label: data.nome,
          },
        ]);
        form.setFieldsValue({ id_ramo_atividade: data.id_ramo_atividade });
      },
    });

    setLoading({ ...loading, ramo_atividade: false });
  }

  async function handleFetchCidade(id_estado: any, ibge?: string) {
    setLoading({ ...loading, cidade: true });

    const url = `/estado/${id_estado}/cidades`;

    const data = await fetchApi({
      url,
      method: 'get',
    });

    setLoading({ ...loading, cidade: false });

    if (!data) return;

    setCidadeOptions(
      data.map((item: any) => ({
        value: item.id_cidade,
        label: item.nome,
      })),
    );

    const find_cidade = ibge
      ? data.find((item: any) => item.ibge === ibge)?.id_cidade
      : null;

    form.setFieldsValue({ id_cidade: find_cidade });
  }

  async function handleStoreData(data: any) {
    const final_obj = {
      permissoes: data.permissoes,
      tipo: tipoPessoa,
      // dado_bancario: {
      //   valido: true,
      //   banco: data.banco,
      //   conta: data.conta,
      //   agencia: data.agencia,
      //   digito_conta: data.digito_conta,
      //   digito_agencia: data.digito_agencia,
      // },
      usuario_is_detran: data.usuario_is_detran,
      usuario: {
        indicado_por:
          data.indicado_por && data.indicado_por.length
            ? data.indicado_por
            : undefined,
        id_ramo_atividade: data.id_ramo_atividade,
        id_cidade: data.id_cidade,
        id_estado: data.id_estado,
        nome: data.nome,
        razao_social: data.razao_social,
        cpf: data.cpf ? cleanMask(data.cpf) : undefined,
        cnpj: data.cnpj ? cleanMask(data.cnpj) : undefined,
        email: data.email,
        senha: data.senha,
        telefone: data.telefone ? cleanMask(data.telefone) : undefined,
        cod_usuario: data.cod_usuario,
        endereco: data.endereco,
        numero: data.numero,
        complemento: data.complemento,
        bairro: data.bairro,
        cep: data.cep ? cleanMask(data.cep) : undefined,
        latitude: data.latitude,
        longitude: data.longitude,
        rg: data.rg,
        data_nascimento: data.data_nascimento,
        orgao_emissor: data.orgao_emissor,
        estado_emissor: data.estado_emissor,
        estado_civil: data.estado_civil,
      },
    };

    return fetchApi({
      url: '/usuario',
      method: 'post',
      data: final_obj,
      messages: {
        loading: 'Inserindo novo usuário, aguarde...',
        error: 'Erro, tente novamente',
        success: 'Usuário inserido com sucesso',
      },
      onSuccess: () => {
        history.push('/usuario');
      },
    });
  }

  async function handlFetchCEP(cep: string) {
    form.setFieldsValue({ cep: cepMask(cep) });

    if (cep.length !== 9) return;

    message.loading({ content: 'Buscando...', duration: 10000, key: 'cep' });

    const fetched_info = await SearchCEP(cleanMask(cep));

    message.destroy();

    const find_estado = estadoOptions.find(
      (val: any) => val.sigla === fetched_info.uf,
    )?.value;

    handleFetchCidade(find_estado, fetched_info.ibge);

    form.setFieldsValue({
      endereco: fetched_info.logradouro,
      bairro: fetched_info.bairro,
      id_estado: find_estado,
    });
  }

  function handleUpdateLanding(data: any) {
    if (!['cod_usuario', 'email'].includes(Object.keys(data)[0])) {
      return null;
    }

    const landing_info = { ...landing };

    if (data?.cod_usuario && data?.cod_usuario !== landing_info.cod_usuario) {
      landing_info.cod_usuario = data?.cod_usuario;
    } else if (data?.email && data?.email !== landing_info.email) {
      landing_info.email = data?.email;
    } else {
      return null;
    }

    if (!landing_info.email) {
      landing_info.url = (
        <Text type="warning">É necessário inserir o email</Text>
      );
    }

    if (!landing_info.cod_usuario) {
      landing_info.url = (
        <Text type="warning">É necessário inserir o código de usuário</Text>
      );
    }

    if (landing_info.cod_usuario && landing_info.email) {
      const url = `https://parcelamostudo.com.br/landing.php?nome_representante=${landing_info.cod_usuario}&email_representante=${landing_info.email}`;

      landing_info.url = (
        <Paragraph copyable={{ tooltips: false, text: url }}>
          <a href={url} target="_blank" rel="noopener noreferrer">
            {url}
          </a>
        </Paragraph>
      );
    }

    return setLanding(landing_info);
  }

  function handleFetchCNPJ(cnpj: any) {
    const key = String(Math.random());

    message.loading({
      content: 'Buscando informações de empresa, aguarde...',
      key,
      duration: 100000,
    });

    return Axios.get(`https://www.receitaws.com.br/v1/cnpj/${cnpj}`)
      .then(({ data }) => {
        if (data.cep) {
          handlFetchCEP(cleanMask(data.cep));
        }

        form.setFieldsValue({
          nome: data.fantasia || data.nome,
          razao_social: data.nome,
          cep: cepMask(cleanMask(data.cep)),
          numero: data.numero,
          telefone: celularMask(cleanMask(data.telefone)),
          complemento: data.complemento,
          email: data.email,
        });

        message.destroy(key);
      })
      .catch(() => {
        message.info({
          content: 'Informação de empresa não encontrada',
          key,
        });
      });
  }

  function handleChangeCNPJ(cnpj: any) {
    form.setFieldsValue({ cnpj: cnpjMask(cnpj) });

    const cleaned_cnpj = cleanMask(cnpj);

    if (cleaned_cnpj.length === 14) {
      handleFetchCNPJ(cleaned_cnpj);
    }
  }

  return (
    <>
      <PageHeader
        title="Cadastro de Usuário"
        breadcrumb={['Usuario', 'Cadastro']}
      />

      <Form
        form={form}
        layout="vertical"
        initialValues={{ banco: '100', senha: senha_default }}
        onFinish={values => handleStoreData(values)}
        onValuesChange={val => handleUpdateLanding(val)}
      >
        <CardCollapse header={false} loading={loading.all}>
          <Row gutter={16}>
            <Col md={24}>
              <Form.Item label="Pessoa">
                <Radio.Group
                  value={tipoPessoa}
                  buttonStyle="solid"
                  onChange={value => setTipoPessoa(value.target.value)}
                >
                  <Radio.Button value="fisica">Fisica</Radio.Button>
                  <Radio.Button value="juridica">Juridica</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          <Divider orientation="left">Informações</Divider>

          <Row gutter={16}>
            {tipoPessoa === 'juridica' ? (
              <>
                <Col md={8}>
                  <Form.Item
                    name="cnpj"
                    label="CNPJ"
                    rules={[{ required: true, message: default_message }]}
                  >
                    <Input onChange={e => handleChangeCNPJ(e.target.value)} />
                  </Form.Item>
                </Col>
                <Col md={16} />
              </>
            ) : null}
            <Col md={12}>
              <Form.Item
                name="nome"
                label="Nome"
                rules={[{ required: true, message: default_message }]}
              >
                <Input maxLength={150} />
              </Form.Item>
            </Col>
            {tipoPessoa === 'fisica' ? (
              <Col md={12} />
            ) : (
              <Col md={12}>
                <Form.Item name="razao_social" label="Razão Social">
                  <Input maxLength={255} />
                </Form.Item>
              </Col>
            )}
            {tipoPessoa === 'fisica' ? (
              <>
                <Col md={8}>
                  <Form.Item
                    name="cpf"
                    label="CPF"
                    rules={[{ required: true, message: default_message }]}
                  >
                    <Input
                      onChange={e =>
                        form.setFieldsValue({ cpf: cpfMask(e.target.value) })
                      }
                    />
                  </Form.Item>
                </Col>
                <Col md={8}>
                  <Form.Item name="rg" label="RG">
                    <Input maxLength={14} />
                  </Form.Item>
                </Col>
                <Col md={8}>
                  <Form.Item name="orgao_emissor" label="Orgão Emissor">
                    <Input maxLength={150} />
                  </Form.Item>
                </Col>
                <Col md={8}>
                  <Form.Item name="estado_emissor" label="Estado Emissor">
                    <Input maxLength={150} />
                  </Form.Item>
                </Col>
                <Col md={8}>
                  <Form.Item name="data_nascimento" label="Data Nascimento">
                    <DatePicker placeholder="" format="DD/MM/YYYY" />
                  </Form.Item>
                </Col>
              </>
            ) : null}
            <Col md={8}>
              <Form.Item name="telefone" label="Telefone">
                <Input
                  onChange={e =>
                    form.setFieldsValue({
                      telefone: celularMask(e.target.value),
                    })
                  }
                />
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  { required: true, message: default_message },
                  { type: 'email', message: 'Insira um email válido' },
                ]}
              >
                <Input maxLength={150} />
              </Form.Item>
            </Col>
            {tipoPessoa === 'fisica' ? (
              <Col md={8}>
                <Form.Item name="cod_usuario" label="Código do Usuário">
                  <Input maxLength={50} />
                </Form.Item>
              </Col>
            ) : null}
            {/* <Col md={8}>
              <Form.Item name="indicado_por" label="Indicado Por">
                <Input maxLength={36} placeholder="Insira o id do usuário" />
              </Form.Item>
            </Col> */}
            {/* <Col md={8}>
              <Form.Item name="senha" label="Senha">
                <Input maxLength={36} />
              </Form.Item>
            </Col> */}
            {tipoPessoa === 'juridica' ? (
              <Col md={8}>
                <Form.Item
                  name="id_ramo_atividade"
                  label="Ramo de Atividade"
                  rules={[{ required: true, message: default_message }]}
                >
                  <Select
                    options={ramoAtividadeOptions}
                    loading={loading.ramo_atividade}
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: '4px 0' }} />
                        <div
                          style={{
                            display: 'flex',
                            flexWrap: 'nowrap',
                            padding: 8,
                          }}
                        >
                          <Input
                            style={{ flex: 'auto' }}
                            value={newRamoAtividade}
                            onChange={val =>
                              setNewRamoAtividade(val.target.value)
                            }
                            onPressEnter={() => handleStoreRamoAtividade()}
                            placeholder="Insira um novo Ramo de Atividade"
                          />
                        </div>
                      </div>
                    )}
                  />
                </Form.Item>
              </Col>
            ) : (
              <Col md={8}>
                <Form.Item
                  name="estado_civil"
                  label="Estado Civil"
                  rules={[{ required: true, message: default_message }]}
                >
                  <Select options={estadoCivil} />
                </Form.Item>
              </Col>
            )}
          </Row>

          <Divider orientation="left">Landing Page</Divider>

          <Row gutter={16}>
            <Col md={24}>{landing.url}</Col>
          </Row>

          <Divider orientation="left">Endereço</Divider>

          <Row gutter={16}>
            <Col md={6}>
              <Form.Item
                name="cep"
                label="CEP"
                rules={[{ required: true, message: default_message }]}
              >
                <Input onChange={e => handlFetchCEP(e.target.value)} />
              </Form.Item>
            </Col>
            <Col md={18}>
              <Form.Item
                name="endereco"
                label="Endereço"
                rules={[{ required: true, message: default_message }]}
              >
                <Input maxLength={150} />
              </Form.Item>
            </Col>
            <Col md={18}>
              <Form.Item name="complemento" label="Complemento">
                <Input maxLength={255} />
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item
                name="numero"
                label="Número"
                rules={[{ required: true, message: default_message }]}
              >
                <Input maxLength={10} />
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item
                name="id_estado"
                label="Estado"
                rules={[{ required: true, message: default_message }]}
              >
                <Select
                  options={estadoOptions}
                  onChange={a => handleFetchCidade(a)}
                  loading={loading.estado}
                  showSearch
                  filterOption={(input, option) =>
                    String(option?.label)
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) === 0
                  }
                />
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item
                name="id_cidade"
                label="Cidade"
                rules={[{ required: true, message: default_message }]}
              >
                <Select
                  options={cidadeOptions}
                  disabled={!cidadeOptions.length}
                  loading={loading.cidade}
                  showSearch
                  filterOption={(input, option) =>
                    String(option?.label)
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) === 0
                  }
                />
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item
                name="bairro"
                label="Bairro"
                rules={[{ required: true, message: default_message }]}
              >
                <Input maxLength={150} />
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item name="latitude" label="Latitude">
                <Input maxLength={45} />
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item name="longitude" label="Longitude">
                <Input maxLength={45} />
              </Form.Item>
            </Col>
          </Row>

          <Divider orientation="left">Permissões</Divider>

          <Form.Item name="permissoes">
            <Checkbox.Group options={responsabilidadesOptions} />
          </Form.Item>

          <Divider orientation="left">Outros</Divider>

          <Form.Item name="usuario_is_detran" valuePropName="checked">
            <Checkbox>Usuário é ou faz parte de um Detran</Checkbox>
          </Form.Item>

          {/* <Divider orientation="left">Dado Bancário</Divider>

          <Row gutter={16}>
            <Col md={6}>
              <Form.Item
                name="banco"
                label="Banco"
                rules={[{ required: true, message: default_message }]}
              >
                <Select disabled={edit}>
                  <Select.Option value="100">Parcelamos Tudo</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col md={5}>
              <Form.Item
                name="agencia"
                label="Agência"
                rules={[{ required: true, message: default_message }]}
              >
                <Input maxLength={15} disabled={edit} />
              </Form.Item>
            </Col>
            <Col md={4}>
              <Form.Item name="digito_agencia" label="Digito Agência">
                <Input maxLength={15} disabled={edit} />
              </Form.Item>
            </Col>
            <Col md={5}>
              <Form.Item
                name="conta"
                label="Conta"
                rules={[{ required: true, message: default_message }]}
              >
                <Input maxLength={15} disabled={edit} />
              </Form.Item>
            </Col>
            <Col md={4}>
              <Form.Item
                name="digito_conta"
                label="Digito Conta"
                rules={[{ required: true, message: default_message }]}
              >
                <Input maxLength={15} disabled={edit} />
              </Form.Item>
            </Col>
          </Row> */}
        </CardCollapse>

        <Row justify="end">
          <Button type="primary" htmlType="submit">
            Cadastrar
          </Button>
        </Row>
      </Form>
    </>
  );
};

export default UsuarioCadastro;
