import React, { useEffect, useState } from 'react';
import {
  Button,
  Form,
  Row,
  Col,
  Input,
  Select,
  Typography,
} from 'antd';
import { dinheiroMask } from 'masks-br';
import {
  MdOpenInNew,
} from 'react-icons/md';
import { ColumnsType } from 'antd/lib/table';
import 'moment/locale/pt-br';

import PageHeader from '../../../components/PageHeader';
import Table from '../../../components/Table';
import CardCollapse from '../../../components/CardCollapse';
import { ItemInputCurrency } from '../../../components/Form';
import {
  setQueryParams, useQueryParams,
} from '../../../utils/UrlQuery';
import { useQuery } from "react-query";
import { getPayments } from "../services";
import { EnumPaymentType, GetPaymentParams, paymentStatusOptions, paymentTypeLabel } from "../services/types";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import { uuid_pattern } from "../../../utils/pattern";

const { Paragraph } = Typography;

const PaymentSearch: React.FC = () => {
  const default_filters = { page: 1, page_size: 25 };
  const [filters, setFilters] = useState<GetPaymentParams>(default_filters)
  const query_values = useQueryParams();

  const [form] = Form.useForm();
  useEffect(() => {
    setFilters({
      ...default_filters,
      ...query_values,
    });
    form.setFieldsValue({ ...query_values });
  }, []);

  const { isFetching: isLoading, data: payments } = useQuery({
    queryKey: ['backoffice-payments', filters],
    queryFn: () => getPayments(filters),
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
    refetchOnReconnect: false,
  });

  function handleSetFiltersValues(nextFilters: GetPaymentParams) {
    let final: GetPaymentParams = {
      ...filters,
      ...nextFilters,
    };
    
    setQueryParams(final);
    setFilters(final);
  }

  function handleResetForm() {
    setQueryParams(default_filters);
    form.resetFields();
    setFilters(default_filters)
  }


  const columns: ColumnsType = [
    {
      title: 'ID',
      key: 'id_payment',
      dataIndex: 'id_payment',
      align: 'center',
      render: (item: string) => (
        <Paragraph copyable={{ tooltips: false }}>{item}</Paragraph>
      ),
    },
    {
      title: 'Valor',
      key: 'total_amount',
      dataIndex: 'total_amount',
      align: 'center',
      render: (item: number) => dinheiroMask(item),
    },
    {
      title: 'Tipo',
      key: 'type',
      dataIndex: 'type',
      align: 'center',
      render: (item: EnumPaymentType) => paymentTypeLabel[item],
    },
    {
      title: 'Status',
      key: 'id_status',
      dataIndex: 'id_status',
      align: 'center',
      render: (item: string): any => {
        const status = paymentStatusOptions.find(status => status.value === item)

        return status?.label
      },
    },
    {
      title: 'Criado em',
      align: 'center',
      key: 'created_at',
      dataIndex: 'created_at',
      render: (value) => {
        const createdAt = format(
          new Date(value),
          'dd/MM/yyyy HH:mm:ss',
        )

        return createdAt
      },
      width: 200,
    },
    {
      title: 'Abrir carrinho',
      width: 80,
      align: 'center',
      render: (data: any) =>
          <Link
            to={`/checkout/${data.checkout_id}`}
            className="ant-btn ant-btn-primary"
          >
            <MdOpenInNew />
          </Link>
    },
    {
      title: 'Abrir pagamento',
      width: 80,
      align: 'center',
      render: (data: any) =>
          <Link
            to={`/payment/${data.id_payment}`}
            className="ant-btn ant-btn-primary"
          >
            <MdOpenInNew />
          </Link>
    }
  ];

  return (
    <>
      <PageHeader title="Pagamentos" breadcrumb={['Pagamentos']} />

      <CardCollapse title="Filtros">
        <Form
          layout="vertical"
          form={form}
          onFinish={data => handleSetFiltersValues(data)}
        >
          <Row gutter={16}>
            <Col md={5} xs={12}>
              <Form.Item name="id_payment" label="Id" rules={[
                {
                  pattern: uuid_pattern,
                  message: 'Deve ser um ID válido!'
                 },
              ]}>
                <Input placeholder="ID do pagamento" />
              </Form.Item>
            </Col>
            <Col md={4} xs={12}>
              <Form.Item name="type" label="Tipo de pagamento">
                <Select allowClear placeholder="Boleto, Pix...">
                  <Select.Option value={EnumPaymentType.BILL}>Boleto</Select.Option>
                  <Select.Option value={EnumPaymentType.PARTNER}>Débito Veicular</Select.Option>
                  <Select.Option value={EnumPaymentType.PIX}>Pix</Select.Option>
                  <Select.Option value={EnumPaymentType.TRANSFER}>Transferência</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col md={6} xs={12}>
              <Form.Item name="status" label="Status">
                <Select
                  options={paymentStatusOptions}
                  allowClear
                  placeholder="Status do pagamento"
                />
              </Form.Item>
            </Col>
            <Col md={6} xs={12}>
              <Form.Item name="amount_total" label="Valor">
                <ItemInputCurrency
                  onChange={(_, val) =>
                    form.setFieldsValue({ amount_total: val || undefined })
                  }
                />
              </Form.Item>
            </Col>
          </Row>

          <br />

          <Row justify="end">
            <Button onClick={handleResetForm} className="buttonMarginRight">
              Limpar
            </Button>
            <Button type="primary" htmlType="submit">
              Filtrar
            </Button>
          </Row>
        </Form>
      </CardCollapse>

      <Table
        columns={columns}
        dataSource={payments?.data ?? []}
        size="small"
        loading={isLoading}
        pagination={{
          total: payments?.total,
          current: payments?.page,
          pageSize: payments?.page_size,
          onChange: (page, page_size) => handleSetFiltersValues({ page, page_size }),
        }}
      />
    
    </>
  );
};

export default PaymentSearch;
