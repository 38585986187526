import React, { createContext, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchApi } from '../services/api';
import { IState } from '../store';
import { IUtilsState } from '../store/modules/utils/reducer';
import {
  updateEstado,
  updateResponsabilidade,
  updateStatusAprovacao,
  updateStatusVinculo,
} from '../store/modules/utils/actions';
import {
  Estado,
  StatusPermissao,
  StatusVinculo,
  Responsabilidade,
  Perfil,
  PosModelo,
  StatusPos,
  PosArmazenamento,
  TipoLiquidacao,
  StatusCarrinho,
  StatusBoleto,
  PartnerFull,
} from '../store/modules/utils/types';
import EnumEstadoCivil from '../types/enum/EstadoCivil';

interface UtilsContext {
  getStatusCarrinho(refresh?: boolean): Promise<StatusCarrinho[]>;
  getStatusBoleto(refresh?: boolean): Promise<StatusBoleto[]>;
  getStatusPermissao(refresh?: boolean): Promise<StatusPermissao[]>;
  getStatusVinculo(refresh?: boolean): Promise<StatusVinculo[]>;
  getEstado(refresh?: boolean): Promise<Estado[]>;
  getResponsabilidade(refresh?: boolean): Promise<Responsabilidade[]>;
  getEstadoCivil(): string[];
  getPerfil(refresh?: boolean): Promise<Perfil[]>;
  getPosModelo(refresh?: boolean): Promise<PosModelo[]>;
  getStatusPos(refresh?: boolean): Promise<StatusPos[]>;
  getPosArmazenamento(refresh?: boolean): Promise<PosArmazenamento[]>;
  getTipoLiquidacao(refresh?: boolean): Promise<TipoLiquidacao[]>;
  getParceiros(refresh?: boolean): Promise<PartnerFull[]>;
}

const UtilsContext = createContext<UtilsContext>({} as UtilsContext);

const UtilsProvider: React.FC = ({ children }) => {
  const [perfil, setPerfil] = useState<Perfil[]>([]);
  const [parceiros, setParceiros] = useState<PartnerFull[]>([]);
  const [posModelo, setPosModelo] = useState<PosModelo[]>([]);
  const [statusPos, setStatusPos] = useState<StatusPos[]>([]);
  const [statusCarrinho, setStatusCarrinho] = useState<StatusCarrinho[]>([]);
  const [statusBoleto, setStatusBoleto] = useState<StatusBoleto[]>([]);
  const [tipoLiquidacao, setTipoLiquidacao] = useState<TipoLiquidacao[]>([]);
  const [posEstabelecimento, setPosEstabelecimento] = useState<
    PosArmazenamento[]
  >([]);

  const data = useSelector<IState, IUtilsState>(state => state.utils);
  const dispatch = useDispatch();

  const getStatusPermissao = async (refresh = false) => {
    if (!data?.status_permissao?.length || refresh) {
      const response = await fetchApi({
        url: '/status_permissao',
        method: 'get',
      });

      dispatch(updateStatusAprovacao(response));

      return response;
    }

    return data.status_permissao;
  };

  const getEstado = async (refresh = false) => {
    if (!data?.estado?.length || refresh) {
      const response = await fetchApi({
        url: '/estado',
        method: 'get',
      });

      dispatch(updateEstado(response));

      return response;
    }

    return data.estado;
  };

  const getResponsabilidade = async (refresh = false) => {
    if (!data?.responsabilidade?.length || refresh) {
      const response = await fetchApi({
        url: '/responsabilidade',
        method: 'get',
      });

      dispatch(updateResponsabilidade(response));

      return response;
    }

    return data.responsabilidade;
  };

  const getStatusVinculo = async (refresh = false) => {
    if (!data?.status_vinculo?.length || refresh) {
      const response = await fetchApi({
        url: '/status_vinculo',
        method: 'get',
      });

      dispatch(updateStatusVinculo(response));

      return response;
    }

    return data.status_vinculo;
  };

  const getStatusCarrinho = async (refresh = false) => {
    if (!statusCarrinho?.length || refresh) {
      const response = await fetchApi({
        url: '/status_carrinho',
        method: 'get',
      });

      setStatusCarrinho(response);

      return response;
    }

    return statusCarrinho;
  };

  const getStatusBoleto = async (refresh = false) => {
    if (!statusBoleto?.length || refresh) {
      const response = await fetchApi({
        url: '/status_boleto',
        method: 'get',
      });

      setStatusBoleto(response);

      return response;
    }

    return statusBoleto;
  };

  const getPerfil = async (refresh = false): Promise<Perfil[]> => {
    if (!perfil.length || refresh) {
      const response = await fetchApi({
        url: '/perfil',
        method: 'get',
      });

      setPerfil(response);

      return response;
    }

    return perfil;
  };

  const getParceiros = async (refresh = false): Promise<PartnerFull[]> => {
    if (!parceiros.length || refresh) {
      const response = await fetchApi({
        url: '/parceiro',
        method: 'get',
      });

      setParceiros(response);

      return response;
    }

    return parceiros;
  };

  const getPosModelo = async (refresh = false): Promise<PosModelo[]> => {
    if (!posModelo.length || refresh) {
      const response = await fetchApi({
        url: '/pos_modelo',
        method: 'get',
      });

      setPosModelo(response);

      return response;
    }

    return posModelo;
  };

  const getStatusPos = async (refresh = false): Promise<StatusPos[]> => {
    if (!statusPos.length || refresh) {
      const response = await fetchApi({
        url: '/ponto_venda/status',
        method: 'get',
      });

      setStatusPos(response);

      return response;
    }

    return statusPos;
  };

  const getPosArmazenamento = async (
    refresh = false,
  ): Promise<PosArmazenamento[]> => {
    if (!posEstabelecimento.length || refresh) {
      const response = await fetchApi({
        url: '/ponto_venda/armazenamento',
        method: 'get',
      });

      setPosEstabelecimento(response);

      return response;
    }

    return posEstabelecimento;
  };

  const getTipoLiquidacao = async (
    refresh = false,
  ): Promise<TipoLiquidacao[]> => {
    if (!tipoLiquidacao.length || refresh) {
      const { data: response } = await fetchApi({
        url: '/backoffice/payment/settlement-type?page=1&page_size=50',
        method: 'get',
      });

      setTipoLiquidacao(response.data);

      return response;
    }

    return tipoLiquidacao;
  };

  const getEstadoCivil = () => {
    return [
      EnumEstadoCivil.CASADO,
      EnumEstadoCivil.DIVORCIADO,
      EnumEstadoCivil.SEPARADO,
      EnumEstadoCivil.SOLTEIRO,
      EnumEstadoCivil.VIUVO,
    ] as string[];
  };

  return (
    <UtilsContext.Provider
      value={{
        getStatusBoleto,
        getStatusCarrinho,
        getStatusPermissao,
        getStatusVinculo,
        getEstado,
        getResponsabilidade,
        getEstadoCivil,
        getPerfil,
        getParceiros,
        getPosModelo,
        getStatusPos,
        getPosArmazenamento,
        getTipoLiquidacao,
      }}
    >
      {children}
    </UtilsContext.Provider>
  );
};

function useUtils(): UtilsContext {
  const context = useContext(UtilsContext);

  if (!context) {
    throw new Error('useUtils deve ser utilizado dentro do UtilsProvider');
  }

  return context;
}

export { UtilsContext, UtilsProvider, useUtils };
