import api from '../../../services/api';
import {
  GetEstablishmentsByUserByResponsabilidadeResponse,
  GetEstablishmentsResponse,
} from './types';

export async function getEstablishments(
  page: number,
  page_size: number,
): Promise<GetEstablishmentsResponse> {
  const response = await api.get<GetEstablishmentsResponse>(
    '/usuario/estabelecimento',
    {
      params: { page, page_size },
    },
  );

  return response.data;
}

export async function getEstablishmentsByUserAndResponsabilidade(
  pagina: number,
  porPagina: number,
  id_usuario: string,
  id_responsabilidade: number,
): Promise<GetEstablishmentsByUserByResponsabilidadeResponse> {
  const response =
    await api.get<GetEstablishmentsByUserByResponsabilidadeResponse>(
      '/usuario/estabelecimento/responsabilidade/list',
      {
        params: { pagina, porPagina, id_usuario, id_responsabilidade },
      },
    );

  return response.data;
}
