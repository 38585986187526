import {
  Table,
  Button,
  Modal,
  Form,
  Row,
  Col,
  Input,
  DatePicker,
  Empty,
} from 'antd';
import React, { useMemo, useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { Link } from 'react-router-dom';
import { dinheiroMask, porcentagemMask } from 'masks-br';
import { MdAttachMoney } from 'react-icons/md';

import CardCollapse from '../../../../components/CardCollapse';
import { usePermission } from '../../../../hooks/usePermissions';
import { fetchApi } from '../../../../services/api';

type ICarrinhoInfoTransacao = {
  transacoes?: any[];
  loading: boolean;
  fetchCarrinho: () => Promise<void>;
};

const CarrinhoInfoTransacao: React.FC<ICarrinhoInfoTransacao> = ({
  loading,
  transacoes,
  fetchCarrinho,
}: ICarrinhoInfoTransacao) => {
  const { userPermissions } = usePermission();
  const [formPagamentoManual] = Form.useForm();

  const [idTransacaoPagamentoManual, setIdTransacaoPagamentoManual] = useState<
    string | undefined
  >(undefined);

  const summary = useMemo(() => {
    const total = transacoes?.reduce(
      (prev, data) => ({
        valor_comissao: prev.valor_comissao + (data?.valor_comissao || 0),
        pct_comissao: prev.pct_comissao + (data?.pct_comissao || 0),
      }),
      {
        valor_comissao: 0,
        pct_comissao: 0,
      },
    );

    return {
      valor_comissao: dinheiroMask(total?.valor_comissao || 0),
      pct_comissao: porcentagemMask(total?.pct_comissao || 0),
    };
  }, [transacoes]);

  function handlePagamentoManual(data: {
    token_transacao: string;
    transacionado_em: Date;
  }) {
    return fetchApi({
      url: `/comissao/${idTransacaoPagamentoManual}`,
      method: 'post',
      data,
      messages: {
        error: 'Erro ao salvar pagamanto manual, tente novamente!',
        loading: 'Salvando pagamento manual, aguarde...',
        success: 'Pagamento manual salvo com sucesso!',
      },
      onSuccess: () => {
        fetchCarrinho();
        formPagamentoManual.resetFields();
        setIdTransacaoPagamentoManual(undefined);
      },
    });
  }

  const columns_transacao: ColumnsType<any> = [
    {
      title: 'Usuário',
      dataIndex: 'nome_usuario',
      key: 'nome_usuario',
      render: (item: string, data: any) => (
        <Link to={`/usuario/${data.id_usuario}`}>{item}</Link>
      ),
    },
    {
      title: 'Responsabilidade',
      dataIndex: 'responsabilidade',
      key: 'responsabilidade',
      align: 'center',
    },
    {
      title: 'Forma de Pagamento',
      dataIndex: 'forma_pagamento',
      key: 'forma_pagamento',
      align: 'center',
      render: (item: string, data: any) => (
        <Link to="/forma-pagamento">
          {item} - {data.condicao_pagamento}
        </Link>
      ),
    },
    {
      title: 'Comissão',
      dataIndex: 'valor_comissao',
      key: 'valor_comissao',
      align: 'center',
      render: (item: number) => dinheiroMask(item),
    },
    {
      title: 'Porcetagem',
      dataIndex: 'pct_comissao',
      key: 'pct_comissao',
      align: 'center',
      render: (item: number) => porcentagemMask(item),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
    },
    {
      title: 'Status Usuário Ação',
      dataIndex: 'nome_usuario_status',
      key: 'nome_usuario_status',
      render: (item: string, data: any) => (
        <Link to={`/usuario/${data.id_usuario_status}`}>{item}</Link>
      ),
    },
    {
      title: 'Pagamento Manual',
      width: 100,
      render: (data: any) => (
        <Button
          onClick={() => setIdTransacaoPagamentoManual(data.id_transacao)}
          disabled={
            data.token_transacao ||
            data.removido_por ||
            !userPermissions.COMISSAO_PAGAR_COMUALMENTE_COMISSAO
          }
        >
          <MdAttachMoney />
        </Button>
      ),
    },
  ];

  return (
    <>
      <CardCollapse title="Comissões" loading={loading}>
        {transacoes?.length ? (
          <Table
            columns={columns_transacao}
            dataSource={transacoes || []}
            pagination={false}
            size="small"
            summary={() => {
              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={1}>Total</Table.Summary.Cell>
                    <Table.Summary.Cell index={2} />
                    <Table.Summary.Cell index={3} />
                    <Table.Summary.Cell index={4} align="center">
                      {summary.valor_comissao}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={5} align="center">
                      {summary.pct_comissao}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={6} />
                    <Table.Summary.Cell index={7} />
                    <Table.Summary.Cell index={8} />
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Carrinho sem comissão"
          />
        )}
      </CardCollapse>

      <Modal
        visible={!!idTransacaoPagamentoManual}
        title="Pagamento Manual"
        footer={false}
        onCancel={() => setIdTransacaoPagamentoManual(undefined)}
        centered
      >
        <p>
          Utilize essa funcionalidade apenas quando o pagamento da comissão for
          realizado fora do sistema! Caso contrário, impedirá o pagamento da
          comissão pelo sistema
        </p>
        <Form
          form={formPagamentoManual}
          layout="vertical"
          onFinish={data => handlePagamentoManual(data)}
        >
          <Row gutter={16}>
            <Col md={12}>
              <Form.Item label="Código de Liquidação" name="token_transacao">
                <Input maxLength={45} />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item label="Data do Pagamento" name="transacionado_em">
                <DatePicker
                  showTime
                  format="DD/MM/YYYY HH:mm:ss"
                  placeholder=""
                />
              </Form.Item>
            </Col>
            <Col md={12} />
            <Col md={12}>
              <br />
              <Button htmlType="submit" type="primary" block>
                Salvar
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default CarrinhoInfoTransacao;
