import { PagePagination } from '../../../types/Pagina';
import { ApiDefaultResponseError } from "../../../types/api";
import { findTagColor } from "../../../utils/tag";

export type GetTerminalsResponse = PagePagination<Terminal>
export type GetTerminalModelsResponse = PagePagination<Model>
export type GetTerminalInfoResponse = TerminalInfo;
export type TerminalErrorResponse = ApiDefaultResponseError<EnumTerminalErrorCode>

export enum EnumTerminalErrorCode {
  SERIAL_NUMBER_DUPLICATE = 'terminal.error.serial.number.duplicate',
  LOGIC_NUMBER_DUPLICATE = 'terminal.error.logic.number.duplicate',
  MODEL_NOT_FOUND = 'terminal.error.model.not.found',
  TERMINAL_ALREADY_LINK_ESTABLISHMENT = 'terminal.error.terminal.already.link.establishment',
  TERMINAL_ALREADY_LINK_ANOTHER_ESTABLISHMENT = 'terminal.error.terminal.already.link.another.establishment',
  TERMINAL_LINK_ESTABLISHMENT_ERROR = 'terminal.error.link.establishment.error',
}

const errorMessages = {
  [EnumTerminalErrorCode.LOGIC_NUMBER_DUPLICATE]: 'Já existe terminal cadastrado com esse logic number!',
  [EnumTerminalErrorCode.SERIAL_NUMBER_DUPLICATE]: 'Já existe terminal cadastrado com esse serial number!',
  [EnumTerminalErrorCode.MODEL_NOT_FOUND]: 'Modelo de terminal não encontrado!',
  [EnumTerminalErrorCode.TERMINAL_ALREADY_LINK_ESTABLISHMENT]: 'Esse terminal já está vinculado a esse estabelecimento!',
  [EnumTerminalErrorCode.TERMINAL_ALREADY_LINK_ANOTHER_ESTABLISHMENT]: 'Terminal já está vinculado a outro estabelecimento!',
  [EnumTerminalErrorCode.TERMINAL_LINK_ESTABLISHMENT_ERROR]: 'Houve uma falha nos terminais!',
}

export function getTerminalErrorMessages({ error, message }: TerminalErrorResponse) {
  return errorMessages[error] || message || 'Houve um erro inesperado, tente novamente!'
}


export type Terminal = {
  id_terminal: string
  id_model: string
  id_status: EnumTerminalStatus
  id_terminal_parent: any
  type: EnumTerminalType
  serial_number: string
  logic_number: string
  location_latitude: string
  location_longitude: string
  created_at: string
  created_by: string
  updated_at: string
  updated_by: string
}

export enum EnumTerminalStatus {
  STOCKED = 'STOCKED',
  IN_TRANSIT = 'IN_TRANSIT',
  IN_OPERATION = 'IN_OPERATION',
  REMOVED = 'REMOVED' 
}
export enum EnumTerminalType {
  SMARTPOS = 'SMARTPOS',
  PINPAD = 'PINPAD',
  TOTEM = 'TOTEM'
}

export const terminalStatus = {
  [EnumTerminalStatus.STOCKED]: findTagColor('Em estoque', 'info'),
  [EnumTerminalStatus.IN_TRANSIT]: findTagColor('Em trânsito', 'info'),
  [EnumTerminalStatus.IN_OPERATION]: findTagColor('Em operação', 'success'),
  [EnumTerminalStatus.REMOVED]: findTagColor('Removido', 'danger')
}


export interface TerminalInfo {
  id_terminal: string
  id_terminal_parent: string | null
  type: EnumTerminalType
  id_status: EnumTerminalStatus
  serial_number: string
  logic_number: string
  created_at: string
  created_by: string
  user_id: string;
  model: Model
  terminal_parent: TerminalParent | null
  terminal_establishment: TerminalEstablishment[]
  terminal_check: TerminalCheck[]
  terminal_timeline: TerminalTimeline[]
}

export interface Model {
  acquirer: string
  manufacturer: string
  name: string
  id_model: string
}

export interface TerminalParent {
  id_terminal: string
  id_status: string
  id_terminal_parent: string
  created_by: string
}
export interface TerminalStatus {
  id_status: string
  title: string
  description: string
}


interface TerminalEstablishment {
  id_terminal_establishment: string
  id_terminal: string
  establishment_id: string
  user_id: string
  lost_amount: number
  tracking_code: string
  termo_de_recebimento_url: string
  created_at: string
  created_by: string
  deleted_at: string
  deleted_by: string
}

enum EnumTerminalCheckType {
  PINPAD = 'PINPAD',
  PRINTER = 'PRINTER',
  READER = 'READER'
}

enum EnumTerminalCheckStatus {
  OPERATIONAL = 'OPERATIONAL',
  OUT_OF_SERVICE = 'OUT_OF_SERVICE' 
}

export const terminalCheckStatus = {
  [EnumTerminalCheckStatus.OPERATIONAL]: findTagColor('Em operação', 'success'),
  [EnumTerminalCheckStatus.OUT_OF_SERVICE]: findTagColor('Fora de serviço', 'danger'),
}
export const terminalCheckType = {
  [EnumTerminalCheckType.PINPAD]: 'Pin Pad',
  [EnumTerminalCheckType.PRINTER]: 'Impressora',
  [EnumTerminalCheckType.READER]: 'Leitor',
}

export interface TerminalCheck {
  id_terminal_check: string
  id_terminal: string
  type: EnumTerminalCheckType
  status: EnumTerminalCheckStatus
  updated_at: string
}

export interface TerminalTimeline {
  id_terminal_timeline: string
  id_terminal: string
  id_status: EnumTerminalStatus
  description: string
  created_at: string
  created_by: string
  terminal: string
}