import { useQuery } from 'react-query';
import { getLinks, getPaymentLinkInfo } from '../services/links';
import { GetPaymentLinksQueryParams } from '../services/types/links';

export function usePaymentLinks(
  filters: GetPaymentLinksQueryParams,
  isAdmin: boolean,
) {
  return useQuery(['payment-links', filters, isAdmin], () =>
    getLinks(filters, isAdmin),
  {
    staleTime: 1000 * 60
  }
  );
}

export function usePaymentLinkInfo(id_payment_link: string, isAdmin: boolean) {
  return useQuery(['payment-link-info', id_payment_link], () =>
    getPaymentLinkInfo(id_payment_link, isAdmin),
  );
}
