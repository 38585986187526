import { cepMask, cnpjMask, cpfMask } from 'masks-br';
import React, { useMemo } from 'react';
import { Empty } from 'antd';

import CardCollapse from '../../../../components/CardCollapse';
import PropertyList, {
  InfoData,
  InfoDataFields,
} from '../../../../components/PropertyList';
import { EnumPaymentType, PaymentDetailItem } from '../../services/types';
import { getSettlementType } from '../../../../utils/billet';
import { formatDate } from '../../../../utils/date';

type IProps = {
  data: PaymentDetailItem;
  loading: boolean;
};

const PaymentInfo: React.FC<IProps> = ({ data, loading }: IProps) => {
  function formatDocument(doc?: string) {
    if (doc?.length === 11) return cpfMask(doc);
    return cnpjMask(doc || '');
  }

  const info = useMemo(() => {
    const datas: InfoData = { category: [] };

    if (data.type === EnumPaymentType.PIX && data.pix) {
      let fields: InfoDataFields[] = [];

      if (data.pix.id_pix) {
        fields.push({
          name: 'ID',
          value: data.pix.id_pix,
          copyable: true,
        });
      }
      if (data.pix.id_status) {
        fields.push({
          name: 'Status',
          value: data.pix.id_status,
        });
      }
      if (data.pix.key) {
        fields.push({
          name: 'Chave',
          value: data.pix.key,
        });
      }
      if (data.pix.source) {
        fields.push({
          name: 'Source',
          value: data.pix.source,
        });
      }
      if (data.pix.type) {
        fields.push({
          name: 'Tipo',
          value: data.pix.type,
        });
      }
      if (data.pix.end_to_end_id) {
        fields.push({
          name: 'End to end ID',
          value: data.pix.end_to_end_id,
        });
      }
      if (data.pix.emv) {
        fields.push({
          name: 'EMV',
          value: data.pix.emv,
        });
      }

      datas.category.push({ fields });

      fields = [];

      if (data.pix.merchant_name) {
        fields.push({
          name: 'Nome',
          value: data.pix.merchant_name,
        });
      }
      if (data.pix.merchant_category_code) {
        fields.push({
          name: 'Categoria',
          value: data.pix.merchant_category_code,
        });
      }
      if (data.pix.merchant_city) {
        fields.push({
          name: 'Cidade',
          value: data.pix.merchant_city,
        });
      }
      if (data.pix.merchant_country) {
        fields.push({
          name: 'País',
          value: data.pix.merchant_country,
        });
      }
      if (data.pix.merchant_zipcode) {
        fields.push({
          name: 'CEP',
          value: cepMask(data.pix.merchant_zipcode),
        });
      }

      datas.category.push({ name: 'Comércio', fields });
    }
    if (data.type === EnumPaymentType.PARTNER && data.debit) {
      let fields: InfoDataFields[] = [];

      if (data.debit.id_debit) {
        fields.push({
          name: 'ID',
          value: data.debit.id_debit,
          copyable: true,
        });
      }
      if (data.debit.license_plate) {
        fields.push({
          name: 'Placa',
          value: data.debit.license_plate,
        });
      }
      if (data.debit.renavam) {
        fields.push({
          name: 'Renavam',
          value: data.debit.renavam,
        });
      }
      if (data.debit.year) {
        fields.push({
          name: 'Ano',
          value: data.debit.year,
        });
      }
      if (data.debit.type) {
        fields.push({
          name: 'Tipo',
          value: data.debit.type,
        });
      }
      if (data.debit.name) {
        fields.push({
          name: 'Nome',
          value: data.debit.name,
        });
      }
      if (data.debit.description) {
        fields.push({
          name: 'Descrição',
          value: data.debit.description,
        });
      }
      if (data.debit.infraction_date) {
        fields.push({
          name: 'Data da infração',
          value: formatDate(new Date(data.debit.infraction_date), 'date'),
        });
      }

      datas.category.push({ fields });
    }
    if (data.type === EnumPaymentType.BILL && data.bill) {
      let fields: InfoDataFields[] = [];

      if (data.bill.id_bill) {
        fields.push({
          name: 'ID',
          value: data.bill.id_bill,
          copyable: true,
        });
      }
      if (data.bill.id_status) {
        fields.push({
          name: 'Status',
          value: data.bill.id_status,
        });
      }
      if (data.bill.start_payment_hour && data.bill.end_payment_hour) {
        fields.push({
          name: 'Janela de pagamento',
          value: `${data.bill.start_payment_hour}:00 - ${data.bill.end_payment_hour}:00`,
        });
      }
      if (data.bill.id_settlement_type) {
        fields.push({
          name: 'Forma da pagamento',
          value: getSettlementType(data.bill.id_settlement_type),
        });
      }
      if (data.bill.code) {
        fields.push({
          name: 'Código',
          value: data.bill.code,
          copyable: true,
        });
      }
      if (data.bill.source) {
        fields.push({
          name: 'Origem',
          value: data.bill.source,
        });
      }
      if (data.bill.correlation_id) {
        fields.push({
          name: 'Protocolo de Consulta',
          value: data.bill.correlation_id,
          copyable: true,
        });
      }
      if (data.bill.settlement_transaction_id) {
        fields.push({
          name: 'Protocolo de Pagamento',
          value: data.bill.settlement_transaction_id,
          copyable: true,
        });
      }

      datas.category.push({ fields });

      fields = [];

      if (data.bill.payer_name) {
        fields.push({
          name: 'Nome',
          value: data.bill.payer_name,
        });
      }
      if (data.bill.payer_document) {
        fields.push({
          name: 'Document',
          value: formatDocument(data.bill.payer_document),
        });
      }

      datas.category.push({ name: 'Pagador', fields });

      fields = [];

      if (data.bill.recipient_name) {
        fields.push({
          name: 'Nome',
          value: data.bill.recipient_name,
        });
      }
      if (data.bill.recipient_document) {
        fields.push({
          name: 'Document',
          value: formatDocument(data.bill.recipient_document),
        });
      }
      if (data.bill.recipient_assignor) {
        fields.push({
          name: 'Nome',
          value: data.bill.recipient_assignor,
        });
      }

      datas.category.push({ name: 'Recebedor', fields });
    }
    if (data.type === EnumPaymentType.TRANSFER && data.transfer) {
      let fields: InfoDataFields[] = [];

      if (data.transfer.id_transfer) {
        fields.push({
          name: 'ID',
          value: data.transfer.id_transfer,
          copyable: true,
        });
      }
      if (data.transfer.description) {
        fields.push({
          name: 'Descrição',
          value: data.transfer.description,
        });
      }

      datas.category.push({ fields });

      fields = [];
      if (data.transfer.bank_name) {
        fields.push({
          name: 'Nome do banco',
          value: data.transfer.bank_name,
        });
      }
      if (data.transfer.bank_code) {
        fields.push({
          name: 'Código do Banco',
          value: data.transfer.bank_code,
        });
      }
      if (data.transfer.agency_number) {
        fields.push({
          name: 'Agência',
          value: data.transfer.agency_number,
        });
      }
      if (data.transfer.agency_number_digit) {
        fields.push({
          name: 'Agência - Dígito',
          value: data.transfer.agency_number_digit,
        });
      }
      if (data.transfer.account_number) {
        fields.push({
          name: 'Número da conta',
          value: data.transfer.account_number,
        });
      }
      if (data.transfer.account_number_digit) {
        fields.push({
          name: 'Dígito do número da conta',
          value: data.transfer.account_number_digit,
        });
      }
      if (data.transfer.account_type) {
        fields.push({
          name: 'Tipo da conta',
          value: data.transfer.account_type,
        });
      }

      datas.category.push({ name: 'Detalhes', fields });

      fields = [];

      if (data.transfer.payer_name) {
        fields.push({
          name: 'Nome',
          value: data.transfer.payer_name,
        });
      }
      if (data.transfer.payer_document) {
        fields.push({
          name: 'Documento',
          value: cpfMask(data.transfer.payer_document),
        });
      }
      if (data.transfer.payer_email) {
        fields.push({
          name: 'Email',
          value: data.transfer.payer_email,
        });
      }
      if (data.transfer.payer_phone) {
        fields.push({
          name: 'Celular',
          value: data.transfer.payer_phone,
        });
      }

      datas.category.push({ name: 'Pagador', fields });

      fields = [];

      if (data.transfer.recipient_name) {
        fields.push({
          name: 'Nome',
          value: data.transfer.recipient_name,
        });
      }
      if (data.transfer.recipient_document) {
        fields.push({
          name: 'Documento',
          value: cpfMask(data.transfer.recipient_document),
        });
      }
      if (data.transfer.recipient_email) {
        fields.push({
          name: 'Email',
          value: data.transfer.recipient_email,
        });
      }
      if (data.transfer.recipient_phone) {
        fields.push({
          name: 'Celular',
          value: data.transfer.recipient_phone,
        });
      }

      datas.category.push({ name: 'Recebedor', fields });
    }

    if (!datas.category.length) return undefined;

    return datas;
  }, [data]);

  return (
    <CardCollapse title="Dados" loading={loading}>
      {!info ? (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="Pagamento sem informações"
        />
      ) : (
        <PropertyList.Card data={info} />
      )}
    </CardCollapse>
  );
};

export default PaymentInfo;
