import React from 'react';
import { Tooltip } from "antd";
import { MdPhonelink, MdPointOfSale } from "react-icons/md";

export const getOriginIcon = (origin: string) => {
  const color = '#443d3e';
  const size = 25;

  if (['pos', 'totem', 'terminal'].includes(origin.toLocaleLowerCase())) {
    return (
      <Tooltip placement="right" title={origin}>
        <MdPointOfSale size={size} color={color} />
      </Tooltip>
    );
  }

  if (origin.toLocaleLowerCase() === 'online') {
    return (
      <Tooltip placement="right" title="Online">
        <MdPhonelink size={size} color={color} />
      </Tooltip>
    );
  }

  return origin.toLocaleUpperCase();
};