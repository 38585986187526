export function chooseTypeLogin(pathname: string) {
    switch (pathname) {
        case '/login':
            return 'parceiro';
        case '/login/admin':
            return 'admin';
        case '/login/admin/estabelecimento':
            return 'admin_estabelecimento';
        default:
            return 'parceiro';
    }
}