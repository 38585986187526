import { Col, Row, Select, Form, DatePicker, Divider, Button } from 'antd';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import ApexChart from 'react-apexcharts';
import { dinheiroMask } from 'masks-br';
import { ApexOptions } from 'apexcharts';
import { useQuery } from 'react-query';
import styled from 'styled-components';

import moment from 'moment';
import 'moment/locale/pt-br';
import locale from 'antd/es/date-picker/locale/pt_BR';
import { isBefore, parseISO, subHours } from 'date-fns';
import Card from '../Components/Card';
import PageHeader from '../../../components/PageHeader';
import { fetchApi } from '../../../services/api';
import CardCollapse from '../../../components/CardCollapse';
import cores from '../../../styles/colors';

import Option from '../../../types/Option';

import { time_default_admin, time_options } from '..';
import { usePermission } from '../../../hooks/usePermissions';
import { EnumBillStatus } from '../../Boleto/types';
import { EnumTransferStatus } from '../../Transferencias/types';

const date_tooltip = 'dd/MM/yyyy';
// const chart_colors = ['#b3b3b380', cores.primary];
const fontSizeValue = '26px';

type ChatData = {
  categories: any[];
  data: {
    name: string;
    data: number[];
  }[];
};

type BoletoData = {
  criado_em_alias: Date;
  taxa: number;
  valor: number;
  valor_total: number;
  count: number;
};

type BoletoStatus = {
  estornado: number;
  erro: number;
  cancelado: number;
  aguardando_aprovacao: number;
};

type CarrinhoStatus = {
  em_disputa: number;
  estornado: number;
};

export interface DashboardData {
  info_boleto?: {
    qtde_boletos: number;
  };
  info_carrinho?: {
    taxa: number;
    valor: number;
    valor_total: number;
    qtde_carrinhos: number;
    comissao: number;
  };
  contas?: {
    saldo_conta: number;
    saldo_conta_daycoval: number;
  };
  boletos?: BoletoData[];
  boletos_last_week?: BoletoData[];
  boleto_status?: BoletoStatus;
  carrinho_status?: CarrinhoStatus;
}

interface GETBalanceResponse {
  balances: {
    celcoin: number;
  };
  transfer: {
    pending: number;
  };
  checkout: {
    amount: number;
    quantity: number;
    average_ticket: number;
    fee_amount: number;
    historical: {
      date: string;
      quantity: number;
      total_amount: number;
    }[];
  };
  bills: {
    amount: number;
    quantity: number;
    average_ticket: number;
    status: {
      FETCH_ERROR: number;
      FETCHED: number;
      WAITING_PAYMENT: number;
      WAITING_CONFIRMATION: number;
      PAYMENT_ERROR: number;
      PAYED: number;
      CANCELED: number;
      REVERSED: number;
      WAITING_EXPORT: number;
      EXPORTED: number;
    };
  };
  terminal: {
    status: {
      OUT_OF_SERVICE: number;
      OPERATIONAL: number;
    }
  }
}

const select_type_filter: Option[] = [
  {
    label: 'Fixo',
    value: 1,
  },
  {
    label: 'Relativo',
    value: 2,
  },
];

const DashboardAdmin: React.FC = () => {
  const [filtros, setFiltros] = useState({ time: time_default_admin });
  const [rangeFiltros, setRangeFiltros] = useState({
    dates: [moment(), moment()],
  });
  const [selectFilter, setSelectFilter] = useState<any>({ time: 'today' });
  const [visible, setVisible] = useState<boolean>(true);
  const [typeFixoOrRelativo, setTypeFixoOrRelativo] = useState(true);
  const { userPermissions } = usePermission();

  const dispatch = useDispatch();

  function getBalance() {
    return fetchApi<GETBalanceResponse>({
      method: 'get',
      url: '/backoffice/dashboard',
      query_params: selectFilter,
    });
  }

  const {
    isLoading,
    data: balanceData,
    refetch: refetchBackoffice,
    isFetched,
  } = useQuery({
    queryKey: ['dashboard-balance'],
    queryFn: getBalance,
    refetchOnWindowFocus: true,
    refetchInterval: 1000 * 60 * 1, // Refetch after 1 minute
    refetchIntervalInBackground: false,
    refetchOnReconnect: true,
  });

  useEffect(() => {
    if (isFetched) {
      refetchBackoffice();
    }
  }, [selectFilter]);

  const chart_valores: ChatData = useMemo(() => {
    if (!balanceData?.checkout?.historical?.length) {
      return {
        categories: [],
        data: [],
      };
    }

    return {
      categories: balanceData.checkout.historical.map(item => item.date),
      data: [
        {
          name: 'Valor',
          data:
            balanceData?.checkout.historical
              .filter(validateChartDate)
              .map(item => item.total_amount) || [],
        },
      ],
    };
  }, [balanceData]);

  const chart_qtde: ChatData = useMemo(() => {
    if (!balanceData?.checkout?.historical?.length) {
      return {
        categories: [],
        data: [],
      };
    }

    return {
      categories: balanceData.checkout.historical.map(item => item.date),
      data: [
        {
          name: 'Vendas',
          data:
            balanceData?.checkout.historical
              .filter(validateChartDate)
              .map(item => item.quantity) || [],
        },
      ],
    };
  }, [balanceData]);

  function validateChartDate(
    data: GETBalanceResponse['checkout']['historical'][0],
  ) {
    const date = new Date();

    return (
      isBefore(
        parseISO(data.date),
        parseISO(subHours(date, date.getTimezoneOffset() / 60).toISOString()),
      ) || data.quantity
    );
  }

  // const filtro_tipo = useMemo(() => {
  //   const tipo =
  //     (filtros.time === 'today' ||
  //       filtros.time === 'yesterday' ||
  //       filtros.time === 'today_last_week') &&
  //     typeFixoOrRelativo;

  //   return tipo;
  // }, [filtros.time, typeFixoOrRelativo]);

  const chart_valores_max_value: {
    max: number;
    tick: number;
  } = useMemo(() => {
    const max_value =
      balanceData?.checkout?.historical?.reduce((prev, item) => {
        if (prev >= item.total_amount) {
          return prev;
        }

        return item.total_amount;
      }, 0) || 0;

    if (max_value <= 4000) {
      return {
        max: 4000,
        tick: 4,
      };
    }

    const max = (parseInt((max_value / 1000).toFixed(), 10) + 1) * 1000;
    const tick = max % 2 ? 5 : 6;

    return {
      max,
      tick,
    };
  }, [balanceData]);

  const chart_qtde_max_value: number = useMemo(() => {
    const max_value =
      balanceData?.checkout.historical?.reduce((prev, item) => {
        if (prev >= item.quantity) {
          return prev;
        }

        return item.quantity;
      }, 0) || 0;

    if (max_value < 5) {
      return 5;
    }

    const max = max_value + 1;

    return max;
  }, [balanceData]);

  useEffect(() => {
    if (isFetched) {
      getBalance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectFilter]);

  async function setTypeFilter(value: string) {
    if (parseInt(value, 10) === 1) setTypeFixoOrRelativo(true);
    if (parseInt(value, 10) === 2) setTypeFixoOrRelativo(false);
  }

  function handleUpdateFiltro(data: any) {
    if (data.time) {
      setFiltros({ ...filtros, time: data.time });
      setSelectFilter({ ...filtros, time: data.time });
    }
    if (data.dates) {
      setRangeFiltros({ dates: [data.dates[0], data.dates[1]] });
      setSelectFilter({
        dates: [data.dates[0].toISOString(), data.dates[1].toISOString()].join(
          ',',
        ),
      });
    }
  }

  const chart_option: ApexOptions = {
    chart: {
      height: 250,
      width: '100%',
      type: 'area',
      group: 'dashboard',
      zoom: {
        enabled: true,
      },
      toolbar: {
        show: true,
        tools: {
          download: false,
          selection: false,
          zoom: true,
          zoomin: false,
          zoomout: false,
          pan: false,
        },
      },
      animations: {
        enabled: true,
        easing: 'linear',
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors:
      filtros.time !== 'today' || !typeFixoOrRelativo
        ? [cores.primary]
        : ['#ffc706'],
    stroke: {
      curve: 'smooth',
    },
    tooltip: {
      x:
        filtros.time !== 'today' || !typeFixoOrRelativo
          ? {
              format: date_tooltip,
            }
          : { format: 'HH:mm' },
    },
    xaxis: {
      tooltip: {
        enabled: false,
      },
    },
  };
  console.debug(rangeFiltros);
  return (
    <>
      <PageHeader title="Dashboard">
        <Form
          layout="vertical"
          onValuesChange={data => handleUpdateFiltro(data)}
          initialValues={{
            ...filtros,
            tipo: 'Fixo',
            dates: [rangeFiltros.dates[0], rangeFiltros.dates[1]],
          }}
        >
          <Row gutter={5}>
            <Col>
              <Form.Item>
                <Button
                  shape="circle"
                  icon={visible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                  onClick={() => setVisible(!visible)}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="tipo">
                <Select
                  style={{ width: '100px', marginLeft: 4 }}
                  options={select_type_filter}
                  onChange={value => setTypeFilter(value)}
                />
              </Form.Item>
            </Col>
            <Col>
              {typeFixoOrRelativo ? (
                <Form.Item name="time">
                  <Select style={{ width: '200px' }} value={selectFilter.time}>
                    {time_options}
                  </Select>
                </Form.Item>
              ) : (
                <Form.Item name="dates">
                  <DatePicker.RangePicker
                    locale={locale}
                    value={selectFilter.dates}
                  />
                </Form.Item>
              )}
            </Col>
          </Row>
        </Form>
      </PageHeader>

      <Row gutter={16}>
        <Col md={24} xs={24}>
          <Row gutter={16}>
            <Col md={5} xs={24}>
              {userPermissions.DASHBOARD_BOLETO && (
                <Card
                  title="Boleto"
                  fontSizeValue={fontSizeValue}
                  money
                  value={balanceData?.bills.amount}
                  loading={isLoading}
                  blur={!visible}
                />
              )}
            </Col>
            <Col md={5} xs={24}>
              {userPermissions.DASHBOARD_TAXA && (
                <Card
                  title="Taxa"
                  fontSizeValue={fontSizeValue}
                  money
                  value={balanceData?.checkout.fee_amount}
                  loading={isLoading}
                  blur={!visible}
                />
              )}
            </Col>
            <Col md={6} xs={24}>
              {userPermissions.DASHBOARD_CARRINHO && (
                <Card
                  title="Carrinho"
                  fontSizeValue={fontSizeValue}
                  money
                  value={balanceData?.checkout.amount}
                  loading={isLoading}
                  blur={!visible}
                />
              )}
            </Col>
            <Col md={8} xs={24}>
              {userPermissions.DASHBOARD_SALDO_EM_CONTA && (
                <Card
                  title="Saldo em conta [Celcoin]"
                  fontSizeValue={fontSizeValue}
                  money
                  delay={1.5}
                  value={balanceData?.balances.celcoin}
                  loading={isLoading}
                  blur={!visible}
                />
              )}
            </Col>
            <Col md={6} xs={24}>
              {userPermissions.DASHBOARD_QTDE_VENDAS && (
                <Card
                  title="Qtde Vendas"
                  fontSizeValue={fontSizeValue}
                  value={balanceData?.checkout.quantity}
                  loading={isLoading}
                  blur={!visible}
                />
              )}
            </Col>
            <Col md={6} xs={24}>
              {userPermissions.DASHBOARD_QTDE_BOLETOS && (
                <Card
                  title="Qtde Boletos"
                  fontSizeValue={fontSizeValue}
                  value={balanceData?.bills.quantity}
                  loading={isLoading}
                  blur={!visible}
                />
              )}
            </Col>
            <Col md={6} xs={24}>
              {userPermissions.DASHBOARD_TICKET_MEDIO_VENDAS && (
                <Card
                  title="Ticket Médio Vendas"
                  fontSizeValue={fontSizeValue}
                  money
                  value={balanceData?.checkout.average_ticket}
                  loading={isLoading}
                  blur={!visible}
                />
              )}
            </Col>
            <Col md={6} xs={24}>
              {userPermissions.DASHBOARD_TICKET_MEDIO_BOLETOS && (
                <Card
                  title="Ticket Médio Boletos"
                  fontSizeValue={fontSizeValue}
                  money
                  value={balanceData?.bills.average_ticket}
                  loading={isLoading}
                  blur={!visible}
                />
              )}
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter={6}>
        <Col span={18}>
          <Divider orientation="left">Pagamentos</Divider>
        </Col>
        <Col span={6}>
          <Divider orientation="center">Terminais</Divider>
        </Col>
      </Row>
      <Row gutter={6}>
        <Col span={18}>
          <Row gutter={8}>
            <Col span={6}>
              {userPermissions.DASHBOARD_BOLETOS_ERRO && (
                <Card
                  title="Erro no Pagamento"
                  to={`/boleto?status=${EnumBillStatus.PAYMENT_ERROR}`}
                  fontSizeValue={fontSizeValue}
                  status={
                    balanceData?.bills.status.PAYMENT_ERROR
                      ? 'danger'
                      : undefined
                  }
                  value={balanceData?.bills.status.PAYMENT_ERROR || 0}
                  loading={isLoading}
                  blur={!visible}
                />
              )}
            </Col>
            <Col span={6}>
              {userPermissions.DASHBOARD_BOLETOS_ESTORNADOS && (
                <Card
                  title="Aguardando Pagamento"
                  to={`/boleto?status=${EnumBillStatus.WAITING_PAYMENT}`}
                  status={
                    balanceData?.bills.status.WAITING_PAYMENT
                      ? 'danger'
                      : undefined
                  }
                  fontSizeValue={fontSizeValue}
                  value={balanceData?.bills.status.WAITING_PAYMENT || 0}
                  loading={isLoading}
                  blur={!visible}
                />
              )}
            </Col>
            <Col span={6}>
              {userPermissions.DASHBOARD_BOLETOS_ESTORNADOS && (
                <Card
                  title="Estornados"
                  to={`/boleto?status=${EnumBillStatus.REVERSED}`}
                  fontSizeValue={fontSizeValue}
                  status={
                    balanceData?.bills.status.REVERSED ? 'danger' : undefined
                  }
                  value={balanceData?.bills.status.REVERSED || 0}
                  loading={isLoading}
                  blur={!visible}
                />
              )}
            </Col>
            <Col span={6}>
              {userPermissions.DASHBOARD_BOLETOS_AGUARDANDO_APROVACAO && (
                <Card
                  title="Transferências pendentes"
                  to={`/transferencias?id_status=${EnumTransferStatus.PENDING}`}
                  fontSizeValue={fontSizeValue}
                  status={balanceData?.transfer.pending ? 'danger' : undefined}
                  value={balanceData?.transfer.pending || 0}
                  loading={isLoading}
                  blur={!visible}
                />
              )}
            </Col>
          </Row>
        </Col>
        <Col span={6}>
          <Row gutter={8}>
            <Col span={24}>
              <Card
                title="Fora de Serviço"
                fontSizeValue={fontSizeValue}
                status={
                  balanceData?.terminal?.status?.OUT_OF_SERVICE
                    ? 'danger'
                    : undefined
                }
                value={balanceData?.terminal?.status.OUT_OF_SERVICE || 0}
                loading={isLoading}
                blur={!visible}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter={12}>
        <Col md={16} xs={24}>
          {userPermissions.DASHBOARD_GRAFICO_FATURAMENTO && (
            <CardCollapse
              title="Faturamento"
              collapse={false}
              visible={visible}
            >
              <ApexChart
                options={{
                  ...chart_option,
                  legend: {
                    show: false,
                  },
                  xaxis: {
                    type: 'datetime',
                    categories: chart_valores.categories,
                  },
                  yaxis: {
                    tickAmount: chart_valores_max_value.tick,
                    min: 0,
                    max: chart_valores_max_value.max,
                    labels: {
                      formatter: (val: number) => dinheiroMask(val),
                    },
                  },
                }}
                series={chart_valores.data}
                type="area"
                height={250}
              />
            </CardCollapse>
          )}
        </Col>
        <Col md={8} xs={24}>
          {userPermissions.DASHBOARD_GRAFICO_VENDAS && (
            <CardCollapse title="Vendas" collapse={false} visible={visible}>
              <ApexChart
                options={{
                  ...chart_option,
                  legend: {
                    show: false,
                  },
                  xaxis: {
                    type: 'datetime',
                    categories: chart_qtde.categories,
                    labels: {
                      datetimeFormatter: {
                        year: 'yyyy',
                        month: "MMM 'yy",
                        day: 'dd MMM',
                        hour: 'HH:mm',
                      },
                    },
                  },
                  yaxis: {
                    min: 0,
                    max: chart_qtde_max_value,
                  },
                }}
                series={chart_qtde.data}
                type="area"
                height={250}
              />
            </CardCollapse>
          )}
        </Col>
      </Row>
    </>
  );
};

export default DashboardAdmin;

const Subtitle = styled.h3`
  margin-top: 30px;
`;
