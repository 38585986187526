import React from 'react';

import { CardI } from '../../../../components/Card';

import { Container } from './styles';

const Card: React.FC<CardI> = (data: CardI) => {
  return <Container {...data} />;
};

export default Card;
