import { LoadingOutlined } from '@ant-design/icons';
import { Spin, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import React, { useMemo } from 'react';

import { fetchApi } from '../../../services/api';
import { useSelector } from 'react-redux';
import { IState } from '../../../store';
import TipoAcesso, { EnumTipoAcesso } from '../../../types/TipoAcesso';

const { Text } = Typography;

type IProps = {
  id_user?: string;
  name?: string;
};

const UserName: React.FC<IProps> = ({ id_user, name }: IProps) => {
  const tipo_acesso = useSelector<IState, TipoAcesso | undefined>(
    state => state.auth.tipo,
  );

  const isAdmin = useMemo(() => {
    return tipo_acesso === EnumTipoAcesso.ADMIN;
  }, [tipo_acesso]);

  const { isLoading, data: userTag } = useQuery<UserTag>({
    queryKey: ['user-tag', id_user],
    queryFn: fetchData,
    cacheTime: 10000000,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
    refetchOnReconnect: false,
  });

  async function fetchData() {
    if (name) {
      return { name };
    }

    if (!id_user) {
      return;
    }

    return fetchApi({
      url: `/usuario/${id_user}/tag`,
      method: 'get',
    });
  }

  if (!id_user && name) {
    return (
      <div>
        <Text type="secondary">{name}</Text>
      </div>
    );
  }

  if (!id_user) {
    return (
      <div>
        <Text type="secondary">Usuário não informado</Text>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div>
        <Spin indicator={<LoadingOutlined style={{ fontSize: 18 }} spin />} />
      </div>
    );
  }

  if (!userTag) {
    return (
      <div>
        <Text type="danger">Usuário não encontrado</Text>
      </div>
    );
  }

  return (
    <div>
      {isAdmin ? (
        <Link className="ant-link" to={`/usuario/${id_user}`}>
          {userTag.name}
        </Link>
      ) : (
        <Text type="secondary">{userTag.name}</Text>
      )}
    </div>
  );
};

export default UserName;

type UserTag = {
  name: string;
};
