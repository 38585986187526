import axios, { Method } from 'axios';
import { message } from 'antd';

import UrlQuery from '../utils/UrlQuery';
import { IState, store } from '../store';
import { signOut } from '../store/modules/auth/actions';
import history from './History';

export const baseURL =
  process.env.REACT_APP_API_URL || 'https://sandbox.api.parcelamostudo.tech';

const api = axios.create({ baseURL, headers: { 'api-version': 1 } });

store.subscribe(() => {
  const { auth } = store.getState() as IState;

  api.defaults.headers.authorization = `Bearer ${auth.token}`;
});

api.interceptors.response.use(
  data => {
    return data;
  },
  async err => {
    return Promise.reject({
      ...err.response.data,
      statusCode: err.response.status,
    });
  },
);

api.interceptors.response.use(data => data, err => {
  const statusCodesAuthExpired = [401, 403]

  if (statusCodesAuthExpired.includes(err.statusCode)) {
    store.dispatch(signOut());
    history.push('/login')
    message.error(err.message ?? 'Sua sessão expirou, faça login novamente!')
    return
  }

  return Promise.reject(err)
})

export interface FetchApiI<T> {
  url: string;
  method: Method;
  query_params?: any;
  headers?: any;
  data?: any;
  timeout?: number;
  messages?: {
    loading?: string;
    success?: string;
    error?: string;
  };
  onSuccess?: (data: T) => any;
  onError?: (error: Error) => any;
}

export async function fetchApi<T = any>(
  data: FetchApiI<T>,
): Promise<T | undefined> {
  const { onSuccess, onError } = data;

  api.defaults.headers = { ...api.defaults.headers, ...data.headers };

  const info = { ...data };
  info.messages = {
    ...{
      loading: '',
      success: '',
      error: '',
    },
    ...data.messages,
  };

  const key = Math.random().toString(36).substring(7);

  if (info.messages?.loading) {
    message.loading({
      content: info.messages?.loading,
      key,
      duration: 100000,
    });
  }

  let { url } = info;

  if (info.query_params) {
    url += '?';
    url += UrlQuery(info.query_params);
  }

  try {
    const response = await api({
      method: info.method,
      url,
      data: info.data,
      headers: {
        'api-version': 1,
        ...info.headers,
      },
      timeout: info.timeout
    });

    if (info.messages?.success) {
      message.success({
        content: info.messages?.success,
        key,
      });
    } else {
      message.destroy();
    }

    if (onSuccess) {
      const success_response = onSuccess(response.data);

      if (success_response) return success_response;
    }

    return response.data;
  } catch (error) {
    const err = error as any;
    if (err.statusCode !== 401 && (info.messages?.error || err.showMessage)) {
      message.error({
        content: err.showMessage ? err.message : info.messages?.error,
        key,
      });
    }

    if (onError) onError(err);

    if (err.statusCode === 401) {
      const wid = { ...window };
      const url = `/login?redirect=${wid.location.pathname}`;

      store.dispatch(signOut());

      history.push(url);
    }

    return undefined;
  }
}

export default api;
