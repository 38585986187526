import React, { memo, useMemo } from 'react';
import { Menu, Dropdown } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { MdMenu } from 'react-icons/md';

import { Container, Profile, StyledButton, BackgroundMenu } from './styles';
import { IState } from '../../store';
import {
  IPerfil,
  IResponsabilidade,
  IUserState,
} from '../../store/modules/user/reducer';
import { signOut, toggleMenu } from '../../store/modules/auth/actions';
import MenuInline from '../Menu';
import EnumResponsabilidade from '../../types/enum/Responsabilidade';
import EnumPerfil from '../../types/enum/Perfis';
import TipoAcesso from '../../types/TipoAcesso';

function Header() {
  const dispatch = useDispatch();

  const responsabilidades = useSelector<IState, IResponsabilidade[]>(
    state => state.user.responsabilidades,
  );
  const responsabilidade_selecionada = useSelector<
    IState,
    EnumResponsabilidade | undefined
  >(state => state.auth.responsabilidade);
  const tipo_acesso = useSelector<IState, TipoAcesso | undefined>(
    state => state.auth.tipo,
  );
  const tipo_perfis = useSelector<IState, IPerfil[]>(
    state => state.user.perfis,
  );
  const menu_opened = useSelector<IState, boolean>(
    state => state.auth.menu_opened,
  );
  const usuario = useSelector<IState, IUserState>(state => state.user);

  const responsabilidade = useMemo(() => {
    const find = responsabilidades.find(
      item => item.id_responsabilidade === responsabilidade_selecionada,
    );

    if (!find) return '';

    return find.nome;
  }, [responsabilidade_selecionada]);

  const abreviacao = useMemo(() => {
    const nome = usuario.nome.trim();

    let abr = '';

    abr += nome[0].toLocaleUpperCase();

    const nomes = nome.split(' ');

    if (nomes.length > 1) {
      abr += nomes[nomes.length - 1][0].toLocaleUpperCase();
    } else {
      abr += nome[1].toLocaleUpperCase();
    }

    return abr;
  }, [usuario.nome]);

  function handleLogout() {
    dispatch(signOut());
  }

  const menu = (
    <Menu>
      {/* <Menu.Item>
        <Link to="/perfil">Meu Perfil</Link>
      </Menu.Item> */}
      <Menu.Item>
        <Link to="/perfil/seguranca">Segurança</Link>
      </Menu.Item>
      {tipo_acesso === 'parceiro' ? (
        <>
          <Menu.Item>
            <Link to="/alterar_responsabilidade">Alterar Responsabilidade</Link>
          </Menu.Item>
          {!tipo_perfis.some(
            tipo_perfil => tipo_perfil.id_perfil === EnumPerfil.PREFEITURA,
          ) ? (
            <>
              <Menu.Divider />
              <Menu.Item>
                <Link to="/perfil/dados-bancarios">Dados bancários</Link>
              </Menu.Item>
            </>
          ) : null}
          <Menu.Divider />
        </>
      ) : null}
      <Menu.Item onClick={handleLogout}>Sair</Menu.Item>
    </Menu>
  );

  return (
    <>
      <Container>
        <StyledButton onClick={() => dispatch(toggleMenu(true))}>
          <MdMenu />
        </StyledButton>

        <MenuInline />

        <Profile>
          <div className="info">
            <span>{usuario.nome}</span>
            <small>{responsabilidade}</small>
          </div>
          <Dropdown overlay={menu} placement="bottomRight">
            <div className="profile-pic">{abreviacao}</div>
          </Dropdown>
        </Profile>

        <BackgroundMenu
          opened={menu_opened}
          onClick={() => dispatch(toggleMenu(false))}
        />
      </Container>
    </>
  );
}

export default memo(Header);
