import {
  Alert,
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Space,
  Table,
} from 'antd';
import { porcentagemMask } from 'masks-br';
import React, { useEffect, useState } from 'react';
import { AiFillDelete } from 'react-icons/ai';

import PageHeader from '../../../components/PageHeader';
import { usePermission } from '../../../hooks/usePermissions';
import { fetchApi } from '../../../services/api';

const default_message = 'Esse campo é obrigatório';

interface IAdquirencia {
  data: {
    id_adquirencia: number;
    nome: string;
    descricao: string;
  }[];
  total: number;
  pagina: number;
  porPagina: number;
}

const FormaCondicaoPagamentoPesquisar: React.FC = () => {
  const [form] = Form.useForm();
  const [form_adquirencia] = Form.useForm();
  const { userPermissions } = usePermission();

  const [formaPagamento, setFormaPagamento] = useState<any[]>([]);
  const [condicaoPagamento, setCondicaoPagamento] = useState<any[]>([]);
  const [adquirencia, setAdquirencia] = useState({} as IAdquirencia);
  const [filterAdquirencia, setFilterAdquirencia] = useState<{
    id_adquirencia: number;
    nome: string;
  }>({
    id_adquirencia: 1,
    nome: 'PagSeguro',
  });
  const [modalCondicao, setModalCondicao] = useState(false);
  const [modalAdquirencia, setModalAdquirencia] = useState(false);
  const [loading, setLoading] = useState({
    forma: true,
    condicao: true,
  });

  useEffect(() => {
    Promise.all([
      fetchAdquirencia(),
      fetchFormaPagamento(),
      fetchCondicaoPagamento(),
    ]).then(() =>
      setLoading({
        forma: false,
        condicao: false,
      }),
    );
  }, [filterAdquirencia]);

  function fetchFormaPagamento() {
    return fetchApi({
      url: '/forma_pagamento',
      method: 'get',
      onSuccess: data => {
        setFormaPagamento(data);
      },
    });
  }

  function fetchCondicaoPagamento() {
    return fetchApi({
      url: '/condicao_pagamento',
      method: 'get',
      query_params: filterAdquirencia,
      onSuccess: data => {
        setCondicaoPagamento(data);
      },
    });
  }

  async function handleFilterCondicaoPagamento(data: any) {
    setFilterAdquirencia(data);
  }

  async function handleDeleteAdquirencia(id_adquirencia: string) {
    return fetchApi({
      url: `/adquirencia/${id_adquirencia}`,
      method: 'delete',
      onSuccess: async () => {
        message.success('Adquirencia excluída com sucesso');
        await fetchAdquirencia();
      },
      onError: () => {
        message.error('Erro ao excluir adquirencia');
      },
    });
  }

  function fetchAdquirencia() {
    return fetchApi({
      url: '/adquirencia',
      method: 'get',
      onSuccess: data => {
        setAdquirencia(data);
      },
    });
  }

  function handleCreateCondicaoPagamento(data: any) {
    if (!userPermissions.FORMA_CONDICAO_CADASTRAR) return null;

    return fetchApi({
      url: '/condicao_pagamento',
      method: 'post',
      data,
      onSuccess: async () => {
        setModalCondicao(false);
        form.resetFields();

        setLoading({ ...loading, condicao: true });

        await fetchCondicaoPagamento();

        setLoading({ ...loading, condicao: false });
      },
    });
  }

  function handleCreateAdquirencia(data: any) {
    if (!userPermissions.FORMA_CONDICAO_CADASTRAR) return null;

    return fetchApi({
      url: '/adquirencia',
      method: 'post',
      data,
      onSuccess: async () => {
        setModalAdquirencia(false);
        form.resetFields();

        setLoading({ ...loading, condicao: true });

        await fetchAdquirencia();

        setLoading({ ...loading, condicao: false });

        message.success('Adquirencia cadastrada com sucesso');
      },
      onError: () => {
        message.error('Erro ao cadastrar adquirencia');
      },
    });
  }

  const columns_forma = [
    {
      title: 'Nome',
      dataIndex: 'nome',
      key: 'nome',
    },
  ];

  const columns_condicao = [
    {
      title: 'Forma',
      dataIndex: 'forma_pagamento',
      key: 'forma_pagamento',
    },
    {
      title: 'Parcelas',
      dataIndex: 'qtde_parcelas',
      key: 'qtde_parcelas',
    },
    {
      title: 'Coeficiênte',
      dataIndex: 'coeficiente_final',
      key: 'coeficiente_final',
    },
    {
      title: 'Comissão Padrão',
      dataIndex: 'comissao_padrao',
      key: 'comissao_padrao',
      render: (item: number) => porcentagemMask(item),
    },
    {
      title: 'Taxa Conveniência',
      dataIndex: 'taxa_conveniencia',
      key: 'taxa_conveniencia',
      render: (item: number) => porcentagemMask(item),
    },
    {
      title: 'MDR',
      dataIndex: 'mdr',
      key: 'mdr',
      render: (item: number) => porcentagemMask(item),
    },
    {
      title: 'Antecipação',
      dataIndex: 'antecipacao',
      key: 'antecipacao',
      render: (item: number) => porcentagemMask(item),
    },
    {
      title: 'Prazo Padrão',
      dataIndex: 'prazo_padrao',
      key: 'prazo_padrao',
    },
  ];

  const columnsAdquirements = [
    {
      title: 'Nome',
      dataIndex: 'nome',
      key: 'nome',
    },
    {
      title: 'Descrição',
      dataIndex: 'descricao',
      key: 'descricao',
    },
    {
      title: 'Remover',
      dataIndex: 'id_adquirencia',
      key: 'id_adquirencia',
      render: (id_adquirencia: string) => (
        <Button
          type="primary"
          danger
          onClick={() => handleDeleteAdquirencia(id_adquirencia)}
        >
          <AiFillDelete />
        </Button>
      ),
    },
  ];

  return (
    <>
      <PageHeader
        title="Formas e Condições de Pagamento"
        breadcrumb={['Pagamento', 'Formas e Condições']}
      />

      <Divider orientation="left">Formas de Pagamento</Divider>

      <Table
        columns={columns_forma}
        dataSource={formaPagamento}
        pagination={false}
        loading={loading.forma}
        size="small"
      />

      <Divider orientation="left">Condições de Pagamento</Divider>

      {userPermissions.FORMA_CONDICAO_CADASTRAR ? (
        <Row justify="space-between" gutter={6}>
          <Col>
            <Select
              defaultValue={filterAdquirencia?.nome}
              style={{
                width: `200px`,
              }}
              onChange={value =>
                handleFilterCondicaoPagamento({ id_adquirencia: value })
              }
              options={adquirencia.data?.map((item: any) => ({
                label: item.nome,
                value: item.id_adquirencia,
              }))}
            />
          </Col>
          <Col>
            <Space>
              <Button type="primary" onClick={() => setModalAdquirencia(true)}>
                Criar nova adquirência
              </Button>
              <Button type="primary" onClick={() => setModalCondicao(true)}>
                Criar nova condição de pagamento
              </Button>
            </Space>
          </Col>
        </Row>
      ) : null}

      <br />

      <Table
        columns={columns_condicao}
        dataSource={condicaoPagamento}
        pagination={false}
        loading={loading.condicao}
        size="small"
      />

      <Modal
        title="Cadastro de Adquirência"
        centered
        footer={false}
        visible={modalAdquirencia}
        onCancel={() => setModalAdquirencia(false)}
      >
        <Form
          layout="vertical"
          onFinish={values => handleCreateAdquirencia(values)}
          form={form_adquirencia}
        >
          <Alert
            message="Empresas com papel de liquidar as transações financeiras por meio de cartão de crédito e débito."
            type="info"
          />
          <br />

          <Table
            columns={columnsAdquirements}
            dataSource={adquirencia.data}
            pagination={false}
            size="small"
          />
          <br />

          <Row gutter={16}>
            <Col md={14}>
              <Form.Item
                name="nome"
                label="Nome"
                rules={[{ required: true, message: default_message }]}
              >
                <Input placeholder="Adicione o nome da adquirência" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col md={14}>
              <Form.Item
                name="descricao"
                label="Descrição"
                rules={[{ required: true, message: default_message }]}
              >
                <Input placeholder="Descreva seu serviço" />
              </Form.Item>
            </Col>
          </Row>

          <br />

          <Row justify="end">
            <Button type="primary" htmlType="submit">
              Criar
            </Button>
          </Row>
        </Form>
      </Modal>

      <Modal
        title="Cadastro de Condição Pagamento"
        centered
        footer={false}
        visible={modalCondicao}
        onCancel={() => setModalCondicao(false)}
      >
        <Form
          layout="vertical"
          onFinish={values => handleCreateCondicaoPagamento(values)}
          initialValues={{ comissao_padrao: 4.5 }}
          form={form}
        >
          <Row gutter={16}>
            <Col md={12}>
              <Form.Item
                name="id_forma_pagamento"
                label="Forma de Pagamento"
                rules={[{ required: true, message: default_message }]}
              >
                <Select>
                  {formaPagamento.map(forma => (
                    <Select.Option value={forma.id_forma_pagamento}>
                      {forma.nome}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                name="qtde_parcelas"
                label="Parcelas"
                rules={[{ required: true, message: default_message }]}
              >
                <InputNumber />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                name="coeficiente_final"
                label="Coeficiênte Final"
                rules={[{ required: true, message: default_message }]}
              >
                <InputNumber precision={6} />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                name="comissao_padrao"
                label="Comissão Padrão"
                rules={[{ required: true, message: default_message }]}
              >
                <InputNumber
                  precision={6}
                  // formatter={value => `${value}%`}
                  addonAfter="%"
                />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                name="taxa_conveniencia"
                label="Taxa Conveniência"
                rules={[{ required: true, message: default_message }]}
              >
                <InputNumber precision={6} addonAfter="%" />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                name="mdr"
                label="MDR"
                extra="Taxa de desconto do logista"
                rules={[{ required: true, message: default_message }]}
              >
                <InputNumber precision={6} addonAfter="%" />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                name="antecipacao"
                label="Antecipação"
                rules={[{ required: true, message: default_message }]}
              >
                <InputNumber precision={6} addonAfter="%" />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                name="prazo_padrao"
                label="Prazo Padrão"
                rules={[{ required: true, message: default_message }]}
              >
                <InputNumber />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                name="id_adquirencia"
                label="Adquirência"
                rules={[{ required: true, message: default_message }]}
              >
                <Select
                  placeholder="Selecione a adquirência"
                  options={adquirencia.data?.map((item: any) => ({
                    label: item.nome,
                    value: item.id_adquirencia,
                  }))}
                />
              </Form.Item>
            </Col>
          </Row>

          <br />

          <Row justify="end">
            <Button type="primary" htmlType="submit">
              Criar
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default FormaCondicaoPagamentoPesquisar;
