import React, { useMemo, useState } from 'react';
import { formatDistance, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Col, Row, Spin, List, Tag, Divider } from 'antd';
import { cnpjMask, cpfMask, dinheiroMask } from 'masks-br';
import { MdKeyboardArrowDown, MdKeyboardArrowLeft } from 'react-icons/md';

import {
  Container,
  Header,
  HeaderLeft,
  HeaderRigth,
  Name,
  Body,
} from './styles';
import findTagColorByTipo, {
  findTagByTipo,
} from '../../../../utils/findTagColorByTipo';
import { Important } from '../../../../components/Text';
import api from '../../../../services/api';

type IEstabelecimentoCard = {
  id_usuario: string;

  nome: string;
  cpf: string;
  cnpj: string;
  criado_em: string;
  status_permissao: string;
  tipo_status: string;

  total_comissao: number;
  faturamento: number;
  // total_qtde_carrinho: number;
};

type IInfo = {
  pos: any[];
};

const EstabelecimentoCard: React.FC<IEstabelecimentoCard> = (
  data: IEstabelecimentoCard,
) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState<IInfo | undefined>(undefined);

  const criado_em = useMemo(() => {
    return formatDistance(parseISO(data.criado_em), new Date(), {
      locale: ptBR,
      addSuffix: true,
    });
  }, [data.criado_em]);

  const tag_status = useMemo(() => {
    return findTagByTipo(data.status_permissao, data.tipo_status);
  }, [data.status_permissao, data.tipo_status]);

  async function handleToggleInfo() {
    if (open) {
      setOpen(false);
      return open;
    }

    if (info) {
      setOpen(true);
      return open;
    }

    setLoading(true);

    await fetchInfo();

    setOpen(true);

    setLoading(false);
    return open;
  }

  async function fetchInfo() {
    const { data: response } = await api.get(
      `/usuario/estabelecimento/${data.id_usuario}`,
    );

    setInfo(response);
  }

  return (
    <Container>
      <Header onClick={() => handleToggleInfo()}>
        <HeaderLeft>
          <div>
            <Name>
              {data.nome}
              <small>
                {data.cpf ? cpfMask(data.cpf) : cnpjMask(data.cnpj)}
              </small>
            </Name>
            <small>{criado_em}</small>
          </div>
          <div>{tag_status}</div>
        </HeaderLeft>
        <HeaderRigth>
          {loading ? <Spin size="small" /> : null}
          {open && !loading ? <MdKeyboardArrowDown /> : null}
          {!open && !loading ? <MdKeyboardArrowLeft /> : null}
        </HeaderRigth>
      </Header>
      {open && !loading ? (
        <Body>
          <Row>
            <Col md={12} xs={12}>
              <small>Faturamento</small>
              <Important>{dinheiroMask(data?.faturamento || 0)}</Important>
            </Col>
            <Col md={12} xs={12}>
              <small>Total de Comissão</small>
              <Important>{dinheiroMask(data?.total_comissao || 0)}</Important>
            </Col>
            {/* <Col md={12} xs={12}>
              <small>Total de Vendas</small>
              <Important>{data?.total_qtde_carrinho || 0}</Important>
            </Col> */}
          </Row>
          <Divider orientation="left">POS&apos;s Associados</Divider>
          <List
            itemLayout="horizontal"
            dataSource={info?.pos || []}
            renderItem={item => {
              const color = findTagColorByTipo(item.status_tipo);
              return (
                <List.Item
                  key={item.logic_number}
                  extra={<Tag color={color}>{item.status}</Tag>}
                >
                  <List.Item.Meta
                    title={item.nome}
                    description={`${item.logic_number} - ${item.serial_number}`}
                  />
                </List.Item>
              );
            }}
          />
        </Body>
      ) : null}
    </Container>
  );
};

export default EstabelecimentoCard;
