import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Tag,
} from 'antd';
import { cpfMask, dinheiroMask, telefoneMask } from 'masks-br';
import React, { useEffect, useState } from 'react';
import { MdBookmark, MdOpenInNew } from 'react-icons/md';

import { EnumTransferStatus, ListTransferResponse, Payment } from './types';
import { useApi } from '../../hooks/useApi';
import { format } from 'date-fns';
import PageHeader from '../../components/PageHeader';
import UserName from '../../components/User/Name';
import Paragraph from 'antd/lib/typography/Paragraph';
import CardCollapse from '../../components/CardCollapse';
import { setQueryParams, useQueryParams } from '../../utils/UrlQuery';
import { default_message } from '../Checkout/Criar';
import { instituicao_liquidacao } from '../Boleto/Pesquisar';
import moment from 'moment';
import { Link } from 'react-router-dom';

interface IFilters {
  id_transfer?: string;
  id_status?: string | string[];
  page?: number;
  page_size?: number;
}

export const OptionsStatusTransfer = [
  {
    label: 'Pendente',
    value: EnumTransferStatus.PENDING,
  },
  {
    label: 'Recebido',
    value: EnumTransferStatus.RECEIVED,
  },
  {
    label: 'Pago',
    value: EnumTransferStatus.PAYMENT_DONE,
  },
];

type modalPaymentManual = {
  modal_open: boolean;
  id_payment?: string;
};

const Transferencias: React.FC = () => {
  const [formFilters] = Form.useForm();
  const query = useQueryParams();
  const [data, setData] = useState<ListTransferResponse>(
    {} as ListTransferResponse,
  );
  const [formLiquidacao] = Form.useForm();
  const [loadingModal, setLoadingModal] = useState<boolean>(false);
  const [modalCodLiquidacao, setModalCodLiquidacao] =
    useState<modalPaymentManual>({} as modalPaymentManual);
  const [filtros, setFiltros] = useState<IFilters>({
    id_status: [EnumTransferStatus.PENDING, EnumTransferStatus.PAYMENT_DONE],
    page: 1,
    page_size: 25,
  });

  const { fetchRequest, loading } = useApi();

  useEffect(() => {
    setFiltros({ ...filtros, ...query });
  }, []);

  useEffect(() => {
    if (filtros) {
      fetchTransfer();
    }
  }, [filtros]);

  function handleRequestData(page = 1, page_size = 25) {
    const final = {
      ...filtros,
      page,
      page_size,
    };
    setQueryParams(final);
    setFiltros(final);
  }

  async function fetchTransfer() {
    return fetchRequest({
      method: 'get',
      url: '/backoffice/transfer',
      query_params: {
        ...filtros,
      },
      onSuccess: (data: any) => {
        setData(data);
      },
    });
  }

  function handleLiquidacaoManual(data: any) {
    setLoadingModal(true);

    const { id_payment } = modalCodLiquidacao;
    data.settled_at = data.settled_at.toISOString();

    return fetchRequest({
      method: 'put',
      url: `/backoffice/payment/${id_payment}/manually`,
      data,
      onSuccess: () => {
        setLoadingModal(false);
        setModalCodLiquidacao({
          modal_open: false,
        });
        message.success('Código de liquidação adicionado com sucesso!');
        formFilters.resetFields();
        handleRequestData();
      },
      onError: () => {
        setLoadingModal(false);
        setModalCodLiquidacao({
          modal_open: false,
        });
        message.error('Erro ao adicionar código de liquidação!');
      },
    });
  }

  const handleSetFiltroValues = (data: any) => {
    const final = {
      ...filtros,
      id_transfer: data.id_transfer,
      id_status: data.id_status,
    };

    console.log(final);

    setFiltros(final);
    setQueryParams(final);
  };

  function handlerStatus(status: string) {
    switch (status) {
      case 'PENDING':
        return <Tag color="blue">PENDENTE</Tag>;
      case 'PAYMENT_DONE':
        return <Tag color="green">PAGO</Tag>;
      case 'RECEIVED':
        return <Tag>RECEBIDO</Tag>;
      default:
        return status;
    }
  }

  function handleResetForm() {
    setQueryParams({});
    formFilters.resetFields();
    setFiltros({
      id_status: [EnumTransferStatus.PENDING, EnumTransferStatus.PAYMENT_DONE],
      page: 1,
      page_size: 25,
    });
  }

  return (
    <>
      <PageHeader title="Transferências" breadcrumb={['Transferências']} />

      <CardCollapse title="Filtros">
        <Form
          layout="vertical"
          onFinish={data => handleSetFiltroValues(data)}
          form={formFilters}
        >
          <Row gutter={16}>
            <Col md={6}>
              <Form.Item name="id_transfer" label="ID">
                <Input placeholder="Informe um ID de transferência" />
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item name="id_status" label="Status">
                <Select
                  options={OptionsStatusTransfer}
                  placeholder="Status da transferência"
                />
              </Form.Item>
            </Col>
          </Row>

          <br />

          <Row justify="end">
            <Space>
              <Button onClick={() => handleResetForm()}>Limpar</Button>
              <Button type="primary" htmlType="submit">
                Filtrar
              </Button>
            </Space>
          </Row>
        </Form>
      </CardCollapse>

      <Table
        dataSource={data.data}
        loading={loading}
        pagination={{
          current: data.page,
          pageSize: data.page_size,
          total: data.total,
          onChange: (p, pp) => handleRequestData(p, pp),
        }}
        columns={[
          {
            title: 'ID',
            dataIndex: 'id_transfer',
            key: 'id_transfer',
            render: (item: string) => (
              <Paragraph copyable={{ tooltips: false }}>{item}</Paragraph>
            ),
          },
          {
            title: 'Descrição',
            dataIndex: 'description',
            key: 'description',
          },
          {
            title: 'Estabelecimento',
            dataIndex: 'payments',
            key: 'payments',
            render: (item: Payment[]) => (
              <UserName id_user={item[0]?.establishment_id} />
            ),
          },
          {
            title: 'Dados',
            render: (data: any) => (
              <>
                <b>Banco:</b> {data.bank_code}-{data.bank_name}
                <br />
                <b>Conta:</b> {data.account_number}-{data.account_number_digit}
                <br />
                <b>Agencia:</b> {data.agency_number}-{data.agency_number_digit}
              </>
            ),
          },
          {
            title: 'Beneficiário',
            render: (data: any) => (
              <>
                <b>Nome:</b> {data.recipient_name}
                <br />
                <b>Documento:</b> {cpfMask(data.recipient_document)}
                <br />
                <b>Telefone:</b> {telefoneMask(data.recipient_phone)}
                <br />
                <b>Email:</b> {data.recipient_email}
              </>
            ),
          },
          {
            title: 'Pagador',
            render: (data: any) => (
              <>
                <b>Nome:</b> {data.payer_name}
                <br />
                <b>Documento:</b> {cpfMask(data.payer_document)}
                <br />
                <b>Telefone:</b> {telefoneMask(data.payer_phone)}
                <br />
                <b>Email:</b> {data.payer_email}
              </>
            ),
          },
          {
            title: 'Valor',
            dataIndex: 'amount_total',
            key: 'amount_total',
            render: (item: number) => dinheiroMask(item),
          },
          {
            title: 'Status',
            dataIndex: 'id_status',
            key: 'id_status',
            render: (item: string) => handlerStatus(item),
          },
          {
            title: 'Criado Em',
            key: 'created_at',
            dataIndex: 'created_at',
            align: 'center',
            render: (created_at: string) =>
              created_at
                ? format(new Date(created_at), 'dd/MM/yyyy HH:mm')
                : null,
          },
          {
            title: 'Abrir Carrinho',
            dataIndex: 'payments',
            key: 'payments',
            width: 60,
            render: (item: Payment[]) => (
              <Link
                className="ant-btn"
                to={`/checkout/${item[0]?.checkout_id}`}
                style={{
                  pointerEvents: item[0]?.checkout_id ? 'auto' : 'none',
                  opacity: item[0]?.checkout_id ? 1 : 0.5,
                }}
              >
                <MdOpenInNew />
              </Link>
            ),
          },
          {
            title: 'Pagar',
            width: '80px',
            dataIndex: 'payments',
            key: 'payments',
            render: (item: Payment[], data: any) => (
              <Button
                onClick={() =>
                  setModalCodLiquidacao({
                    modal_open: true,
                    id_payment: item[0]?.id_payment,
                  })
                }
                disabled={!item[0]?.id_payment || data.id_status !== 'PENDING'}
              >
                <MdBookmark />
              </Button>
            ),
          },
        ]}
      />

      <Modal
        centered
        visible={modalCodLiquidacao.modal_open}
        title="Adicionar Código de Liquidação"
        onCancel={() =>
          setModalCodLiquidacao({
            modal_open: false,
          })
        }
        okText="Adicionar"
        onOk={() => formLiquidacao.submit()}
        okButtonProps={{ loading: loadingModal }}
      >
        <Form
          layout="vertical"
          form={formLiquidacao}
          onFinish={handleLiquidacaoManual}
          initialValues={{
            settled_at: moment(),
            settlement_institution: instituicao_liquidacao[0].value,
          }}
        >
          <Form.Item
            label="Código de Liquidação"
            name="authentication_code"
            rules={[
              {
                required: true,
                message: default_message,
              },
            ]}
          >
            <Input
              maxLength={45}
              placeholder="Informe o código de liquidação"
            />
          </Form.Item>
          <Form.Item label="Data da Liquidação" name="settled_at">
            <DatePicker placeholder="Selecione a data" format={'DD/MM/YYYY'} />
          </Form.Item>
          <Form.Item label="Instituição" name="settlement_institution">
            <Select options={instituicao_liquidacao} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Transferencias;
