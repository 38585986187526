
import Option from '../../../types/Option';
import { CarrinhoCriar } from './CarrinhoCriar';

// import { Container } from './styles';
export const default_message = 'Esse campo é obrigatório';

export enum EnumBillSettlementInstitution {
  CELCOIN = 'CELCOIN',
  CAIXA = 'CAIXA',
  BANCO_DO_BRASIL = 'BANCO_DO_BRASIL',
  BANESE = 'BANESE',
}

export const instituicao_liquidacao: Option[] = Object.keys(
  EnumBillSettlementInstitution,
).map(key => ({
  label: key.replace(/_/g, ' '),
  value:
    EnumBillSettlementInstitution[
      key as keyof typeof EnumBillSettlementInstitution
    ],
}));

export const tipo_liquidacao: Option[] = [
  {
    label: 'Bancária',
    value: '1',
  },
  {
    label: 'Arquivo Retorno',
    value: '2',
  },
  {
    label: 'Webhook',
    value: '3',
  },
];

export default CarrinhoCriar;
