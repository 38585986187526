import api from '../../../services/api';
import { GetPanelBillSearchResponse } from './types/bill';

type GetBillParameters = {
  barcode: string;
  id_establishment?: string;
};

export async function getBillDetailInfoPanel(parameters: GetBillParameters) {
  const response = await api.get<GetPanelBillSearchResponse>(
    `/panel/bill/search/${parameters.barcode}`,
    {
      headers: {
        'id-establishment': parameters.id_establishment,
      },
    },
  );

  return response.data;
}
