import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Card = styled.div<{ selected?: boolean }>`
  padding: 25px;
  box-shadow: 0 0 20px #ccc;
  margin: 0px 10px;
  border-radius: 6px;
  transition: 0.5s;
  cursor: pointer;
  background-color: white;

  &:hover {
    box-shadow: 0 0 5px var(--primary);
  }

  ${props =>
    props.selected &&
    css`
      box-shadow: 0 0 10px 2px var(--primary);
    `}
`;
