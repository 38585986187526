import CardBrandMastercard from './Mastercard';
import CardBrandDiners from './Diners';
import CardBrandAmex from './Amex';
import CardBrandVisa from './Visa';
import CardBrandElo from './Elo';

export default {
  Mastercard: CardBrandMastercard,
  Visa: CardBrandVisa,
  Amex: CardBrandAmex,
  Diners: CardBrandDiners,
  Elo: CardBrandElo,
};
