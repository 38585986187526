import React from 'react';
import { useHistory } from 'react-router-dom';
import { Wrapper, Name, App, Footer, AppContainer } from '../default/styles';
import Logo from '../../../assets/image/logo.svg';

const ano = new Date().getFullYear();

const InformationLayout: React.FC = ({ children }) => {
  const history = useHistory();
  return (
    <Wrapper>
      <Name onClick={() => history.push('/login')}>
        <img src={Logo} alt="Logo Parcelamos Tudo" width={250} />
      </Name>
      <App>
        <AppContainer>{children}</AppContainer>

        <Footer>
          <div className="copyright">
            Copyright @ {ano} Parcelamos Tudo - Todos os direitos reservados
          </div>
        </Footer>
      </App>
    </Wrapper>
  );
};

export default InformationLayout;
