import React from 'react';
import { Divider, Space, Card, Typography, List } from 'antd';
import Text from 'antd/lib/typography/Text';
import { format } from 'date-fns';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import { MdDateRange } from 'react-icons/md';
import { FaRegMoneyBillAlt } from 'react-icons/fa';
import Paragraph from 'antd/lib/typography/Paragraph';

type BoletoSms = {
  cod_boleto: string;
  valor_total: string;
  liquidado_em: string;
};

const SMS: React.FC = () => {
  const { search } = useLocation();

  const [boletos, setBoletos] = useState<BoletoSms[] | undefined>([]);

  const cod_boletos = new URLSearchParams(search)
    .get('cod_boletos')
    ?.split(',');
  const valores_totais = new URLSearchParams(search).get('valores_totais');
  const liquidados_em = new URLSearchParams(search)
    .get('liquidados_em')
    ?.split(',');

  useEffect(() => {
    setBoletos(
      cod_boletos?.map((cod_boleto, idx) => {
        return {
          cod_boleto,
          valor_total: valores_totais || '',
          liquidado_em: liquidados_em ? liquidados_em[idx] : '',
        };
      }),
    );
  }, []);

  const cliente_nome = new URLSearchParams(search).get('cliente_nome');
  const cliente_email = new URLSearchParams(search).get('cliente_email');

  return (
    <>
      <Typography.Title level={2}> Comprovante de Pagamento </Typography.Title>

      <Divider />

      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Typography.Title level={5}>Olá, {cliente_nome} </Typography.Title>

        <Text>
          Informamos a liquidação dos seguintes boletos em nossa plataforma,
          {cliente_email ? (
            <Text>
              cliente <Text strong>{cliente_email}</Text>
            </Text>
          ) : null}{' '}
          agrademos pela preferência!
        </Text>
        <Card title="Boletos">
          <List
            itemLayout="horizontal"
            dataSource={boletos}
            renderItem={(item: BoletoSms) => (
              <List.Item>
                <List.Item.Meta
                  title={
                    <Text strong>
                      <Paragraph copyable={{ text: item.cod_boleto }}>
                        {item.cod_boleto}
                      </Paragraph>
                    </Text>
                  }
                  description={
                    <Space>
                      <FaRegMoneyBillAlt />
                      {item.valor_total}
                      <Divider type="vertical" />
                      <MdDateRange />
                      {format(
                        new Date(item.liquidado_em),
                        'dd/MM/yyyy HH:mm:dd',
                      )}
                    </Space>
                  }
                />
              </List.Item>
            )}
          />
        </Card>
      </Space>
    </>
  );
};

export default SMS;
