import {
  Button,
  Col,
  Row,
  Table,
  Form,
  Input,
  Select,
  Space,
  Typography,
  Tag,
} from 'antd';
import { cpfMask, dinheiroMask, cleanMask } from 'masks-br';
import React, { useEffect, useState } from 'react';
import { MdOpenInNew } from 'react-icons/md';
import { Link } from 'react-router-dom';

import CardCollapse from '../../../components/CardCollapse';
import PageHeader from '../../../components/PageHeader';
import { useApi } from '../../../hooks/useApi';
import Option from '../../../types/Option';
import { IPagina } from '../../../types/Pagina';
import { ItemInputCurrency } from '../../../components/Form';
import { setQueryParams, useQueryParams } from '../../../utils/UrlQuery';
import { findStatusColor } from '../../Carrinho';
import { EnumBillStatus, EnumSettlementType } from '../types';
import { StatusTipo } from '../../../store/modules/utils/types';
import { format } from 'date-fns';

type modalPaymentManual = {
  modal_open: boolean;
  id_payment?: string;
};

export enum EnumBillSettlementInstitution {
  CELCOIN = 'CELCOIN',
  CAIXA = 'CAIXA',
  BANCO_DO_BRASIL = 'BANCO_DO_BRASIL',
  BANESE = 'BANESE',
}

export const instituicao_liquidacao: Option[] = Object.keys(
  EnumBillSettlementInstitution,
).map(key => ({
  label: key.replace(/_/g, ' '),
  value:
    EnumBillSettlementInstitution[
      key as keyof typeof EnumBillSettlementInstitution
    ],
}));

export const tipo_liquidacao: Option[] = [
  {
    label: 'Bancária',
    value: '1',
  },
  {
    label: 'Arquivo Retorno',
    value: '2',
  },
  {
    label: 'Webhook',
    value: '3',
  },
];

const BoletoPesquisar: React.FC = () => {
  const [boletos, setBoletos] = useState<IPagina>({} as IPagina);
  const [filtros, setFiltros] = useState<any>(undefined);
    useState<modalPaymentManual>({} as modalPaymentManual);
  const [statusBoleto, setStatusBoleto] = useState<Option[]>([]);

  const { loading, fetchRequest } = useApi();
  const query_values = useQueryParams();
  const [formFilter] = Form.useForm();

  const { Paragraph } = Typography;

  useEffect(() => {
    setFiltros({ ...query_values });
    formFilter.setFieldsValue(query_values);
    formFilter.setFieldsValue({
      cpf_cliente: cpfMask(query_values.cpf_cliente),
    });
    fetchStatus();
  }, []);

  function handleNameSettlementType(
    id_settlement_type: EnumSettlementType,
  ): React.ReactNode {
    switch (id_settlement_type) {
      case EnumSettlementType.BANK:
        return <Tag color="blue">BANCÁRIA</Tag>;
      case EnumSettlementType.PIX:
        return <Tag color="green">PIX</Tag>;
      case EnumSettlementType.RETURN_FILE:
        return <Tag color="blue">ARQUIVO DE RETORNO</Tag>;
      default:
        return <Tag>SEM LIQUIDACAO</Tag>;
    }
  }

  useEffect(() => {
    if (filtros) {
      fetchBoletos();
    }
  }, [filtros]);

  function fetchBoletos() {
    return fetchRequest({
      method: 'get',
      url: '/backoffice/bill',
      headers: {
        'api-version': '1',
      },
      query_params: {
        page: 1,
        page_size: 25,
        ...filtros,
      },
      messages: {
        error: 'Erro ao pesquisar boleto!',
      },
      onSuccess: (response: IPagina<any>) => {
        const data = response.data.map((item: any) => ({
          ...item,
          amount: dinheiroMask(item.amount_total),
          created_at: format(new Date(item.created_at), 'dd/MM/yyyy HH:mm:ss'),
          status: findStatusColor(item.id_status, item.type),
          id_checkout: item.payments[0]?.checkout_id,
          id_payment: item.payments[0]?.id_payment,
          status_name: item.status?.name,
          status_type: item.status?.type,
        }));

        setBoletos({
          ...response,
          data,
        });
      },
    });
  }

  function handleResetForm() {
    setQueryParams({});
    formFilter.resetFields();
    setFiltros({});
  }

  function handleSetFiltroValues(data: any) {
    const [data_inicio, data_fim] = [...(data?.data || [])];
    data.cpf_cliente = data.cpf_cliente
      ? cleanMask(data.cpf_cliente)
      : undefined;

    const final = {
      ...filtros,
      ...data,
      data_inicio: data_inicio ? data_inicio.format('YYYY-MM-DD') : undefined,
      data_fim: data_fim ? data_fim.format('YYYY-MM-DD') : undefined,
    };

    setQueryParams(final);
    setFiltros(final);
  }

  function fetchStatus() {
    const response = [
      {
        nome: 'Pago',
        id_status_boleto: EnumBillStatus.PAYED,
        tipo: 'success',
      },
      {
        nome: 'Exportado',
        id_status_boleto: EnumBillStatus.EXPORTED,
        tipo: 'success',
      },
      {
        nome: 'Aguardando Confirmação',
        id_status_boleto: EnumBillStatus.WAITING_CONFIRMATION,
        tipo: 'info',
      },
      {
        nome: 'Pesquisado',
        id_status_boleto: EnumBillStatus.FETCHED,
        tipo: 'info',
      },
      {
        nome: 'Aguardando Pagamento',
        id_status_boleto: EnumBillStatus.WAITING_PAYMENT,
        tipo: 'info',
      },
      {
        nome: 'Aguardando Exportação',
        id_status_boleto: EnumBillStatus.WAITING_EXPORT,
        tipo: 'info',
      },
      {
        nome: 'Erro no Pagamento',
        id_status_boleto: EnumBillStatus.PAYMENT_ERROR,
        tipo: 'error',
      },
      {
        nome: 'Cancelado',
        id_status_boleto: EnumBillStatus.CANCELED,
        tipo: 'error',
      },
      {
        nome: 'Estornado',
        id_status_boleto: EnumBillStatus.REVERSED,
        tipo: 'error',
      },
      {
        nome: 'Erro na consulta',
        id_status_boleto: EnumBillStatus.FETCH_ERROR,
        tipo: 'error',
      },
    ];
    setStatusBoleto(
      response.map(item => ({
        label: findStatusColor(item.nome, item.tipo as StatusTipo),
        value: item.id_status_boleto,
      })),
    );
  }

  const columns = [
    {
      title: '#',
      key: 'id_bill',
      dataIndex: 'id_bill',
      // width: 330,
      render: (item: string) => (
        <Paragraph copyable={{ tooltips: false }}>{item}</Paragraph>
      ),
    },
    {
      title: 'Código de Barras',
      key: 'digitable',
      dataIndex: 'digitable',
      render: (item: string) => (
        <Paragraph copyable={{ tooltips: false }}>{item}</Paragraph>
      ),
    },
    {
      title: 'Valor',
      key: 'amount',
      dataIndex: 'amount',
    },
    {
      title: 'Tipo Liquidação',
      key: 'id_settlement_type',
      dataIndex: 'id_settlement_type',
      render(item: EnumSettlementType) {
        return handleNameSettlementType(item);
      },
    },
    {
      title: 'Status',
      key: 'status_name',
      dataIndex: 'status_name',
      render(item: string, data: any) {
        return findStatusColor(item, data?.status_type as StatusTipo);
      },
    },
    {
      title: 'Criado Em',
      key: 'created_at',
      dataIndex: 'created_at',
    },
    {
      title: 'Abrir Carrinho',
      key: 'id_checkout',
      dataIndex: 'id_checkout',
      width: 60,
      render: (item: string) => (
        <Link
          className="ant-btn"
          to={`/checkout/${item}`}
          style={{
            pointerEvents: item ? 'auto' : 'none',
            opacity: item ? 1 : 0.5,
          }}
        >
          <MdOpenInNew />
        </Link>
      ),
    },
    {
      title: 'Abrir Boleto',
      key: 'id_bill',
      dataIndex: 'id_bill',
      width: 60,
      render: (item: string) => (
        <Link
          className="ant-btn"
          to={`/boleto/${item}`}
          style={{ border: 'None' }}
        >
          <Button type="primary">
            <MdOpenInNew />
          </Button>
        </Link>
      ),
    },
  ];

  return (
    <>
      <PageHeader title="Boletos" breadcrumb={['Boletos']}>
        <Link
          className="ant-btn"
          to="/boleto/pesquisar"
          style={{ border: 'None', background: 'none' }}
        >
          <Button type="primary">Pesquisar</Button>
        </Link>
      </PageHeader>

      <CardCollapse header={false}>
        <Form
          layout="vertical"
          form={formFilter}
          onFinish={handleSetFiltroValues}
        >
          <Row gutter={16}>
            <Col md={6}>
              <Form.Item label="Id Boleto" name="id_bill">
                <Input />
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item label="Código de Barras" name="digitable">
                <Input />
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item label="Status" name="status">
                <Select options={statusBoleto} />
              </Form.Item>
            </Col>
            <Col md={6} xs={12}>
              <Form.Item name="amount_total" label="Valor">
                <ItemInputCurrency
                  onChange={(_, val) =>
                    formFilter.setFieldsValue({ amount_total: val })
                  }
                />
              </Form.Item>
            </Col>
          </Row>

          <br />

          <Row justify="end">
            <Space>
              <Button onClick={() => handleResetForm()}>Limpar</Button>
              <Button type="primary" htmlType="submit">
                Filtrar
              </Button>
            </Space>
          </Row>
        </Form>
      </CardCollapse>

      <br />

      <Table
        dataSource={boletos.data}
        columns={columns}
        loading={loading}
        size="small"
        pagination={{
          current: boletos.page,
          pageSize: boletos.page_size,
          total: boletos.total,
          onChange: (page, page_size) =>
            handleSetFiltroValues({ page, page_size }),
        }}
      />
    </>
  );
};

export default BoletoPesquisar;
