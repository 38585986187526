import {
  Button,
  Dropdown,
  Input,
  Menu,
  Modal,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import { format, formatDistanceToNow } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { dinheiroMask } from 'masks-br';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { BsArrowCounterclockwise } from 'react-icons/bs';
import { GiPunch } from 'react-icons/gi';
import { MdDelete } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { findStatusColor } from '../..';
import CardCollapse from '../../../../components/CardCollapse';
import LinkCopyable from '../../../../components/LinkCopyable';
import UserName from '../../../../components/User/Name';
import { fetchApi } from '../../../../services/api';
import { IState } from '../../../../store';
import { IUserState } from '../../../../store/modules/user/reducer';
import { GetBackofficeCheckoutResponse, RiskAnalysisResult } from '../types';

const { TextArea } = Input;
const { Paragraph } = Typography;

enum EnumIdStatus {
  'IN_DISPUTE' = 'IN_DISPUTE',
  'REVERSED' = 'REVERSED',
  'REMOVED' = 'REMOVED',
}

type ICarrinhoInfoGeral = {
  data?: GetBackofficeCheckoutResponse;
  loading: boolean;
  tick: number;
  fetchCheckoutInfo: () => void;
};

const CheckoutInfoGeral: React.FC<ICarrinhoInfoGeral> = ({
  loading,
  data = {} as GetBackofficeCheckoutResponse,
  tick,
  fetchCheckoutInfo,
}: ICarrinhoInfoGeral) => {
  const { checkout } = data;

  const [description, setDescription] = useState<string>('');
  const user = useSelector<IState, IUserState>(state => state.user);

  const criado_em = useMemo(() => {
    if (!checkout?.created_at) {
      return '';
    }

    return (
      <Tooltip
        title={format(new Date(checkout?.created_at), 'dd/MM/yyyy HH:mm:ss')}
        placement="topLeft"
      >
        {format(new Date(checkout?.created_at), 'PPPpp')}
      </Tooltip>
    );
  }, [checkout]);
  const last_update = useMemo(() => {
    if (!checkout?.updated_at) {
      return '';
    }

    return formatDistanceToNow(new Date(checkout?.updated_at), {
      locale: ptBR,
      addSuffix: true,
      includeSeconds: true,
    });
  }, [checkout, tick]);

  const analise_risco_tag = useMemo(() => {
    if (!checkout?.risk_analysis?.length) {
      return <Tag color="default">Não executado</Tag>;
    }

    return checkout?.risk_analysis?.map(ar => {
      if (ar.result === RiskAnalysisResult.APPROVED) {
        return (
          <Tag
            key={ar.id_checkout}
            icon={<CheckCircleOutlined />}
            color="success"
          >
            {ar.provider} | Aprovada
          </Tag>
        );
      } else if (ar.result === RiskAnalysisResult.REPROVED) {
        return (
          <Tag
            key={ar.id_checkout}
            icon={<CloseCircleOutlined />}
            color="error"
          >
            {ar.provider} | Reprovada
          </Tag>
        );
      } else if (ar.result === RiskAnalysisResult.PENDING) {
        return (
          <Tag
            key={ar.id_checkout}
            icon={<SyncOutlined spin />}
            color="processing"
          >
            {ar.provider} | Pendente
          </Tag>
        );
      }

      return (
        <Tag color="default">
          {ar.provider} | {ar.result}
        </Tag>
      );
    });
  }, [checkout?.risk_analysis]);

  function handleChangeStatus(id_status: EnumIdStatus, name_status: string) {
    Modal.confirm({
      title:
        id_status === EnumIdStatus.REMOVED
          ? 'Tem certeza que deseja remover ?'
          : `Tem certeza que deseja mudar o status para "${name_status}" ?`,
      content: (
        <TextArea
          rows={4}
          placeholder="Insira o motivo da alteração"
          onChange={e => setDescription(e.target.value)}
        />
      ),
      centered: true,
      onOk() {
        return fetchApi({
          url: `/backoffice/checkout/${checkout?.id_checkout}/status`,
          method: 'patch',
          data: {
            id_status,
            name: name_status,
            description,
            updated_by: user.id_usuario,
          },
          messages: {
            loading: 'Aguarde...',
            error: 'Erro, tente novamente!',
            success: 'Alterado com sucesso!',
          },
          onSuccess: () => {
            fetchCheckoutInfo();
          },
        });
      },
    });
  }

  const menu = (
    <Menu>
      <Menu.ItemGroup>
        <Menu.Item>
          <Button
            onClick={() =>
              handleChangeStatus(EnumIdStatus.IN_DISPUTE, 'em disputa')
            }
            type="text"
            icon={<GiPunch style={{ marginRight: 10 }} />}
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            Disputa
          </Button>
        </Menu.Item>
        <Menu.Item>
          <Button
            onClick={() =>
              handleChangeStatus(EnumIdStatus.REVERSED, 'estornada')
            }
            type="text"
            icon={<BsArrowCounterclockwise style={{ marginRight: 10 }} />}
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            Estornado
          </Button>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item>
          <Button
            disabled={checkout?.id_status === 'REMOVED'}
            onClick={() => handleChangeStatus(EnumIdStatus.REMOVED, 'removido')}
            type="text"
            icon={<MdDelete style={{ marginRight: 10 }} />}
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            Remover
          </Button>
        </Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  );

  useEffect(() => {}, [checkout, checkout?.id_status]);

  return (
    <>
      <CardCollapse header={false} loading={loading}>
        <Header>
          <div className="row row-header">
            <div>{criado_em}</div>
            <div>
              <Paragraph copyable>{checkout?.id_checkout}</Paragraph>
            </div>
          </div>
          <div className="row row-body">
            <div className="status">
              <span className="value">
                {dinheiroMask(checkout?.total_amount || 0)}
              </span>
              {findStatusColor(
                checkout?.status?.name,
                checkout?.status?.type,
                checkout?.status?.description,
              )}
            </div>
            <div>
              <Dropdown overlay={menu} placement="bottomRight">
                <Button shape="circle">
                  <BiDotsVerticalRounded />
                </Button>
              </Dropdown>
            </div>
          </div>
        </Header>

        <Body>
          <span className="body-item">
            <h3>Última atualização</h3>
            <p>{last_update}</p>
          </span>
          <span className="body-item">
            <h3>Estabelecimento</h3>
            <p>
              <UserName
                id_user={checkout?.establishment_id}
                name={checkout?.establishment?.name}
              />
            </p>
          </span>
          {checkout?.user_id && (
            <span className="body-item">
              <h3>Usuário</h3>
              <p>
                <UserName id_user={checkout?.user_id} />
              </p>
            </span>
          )}
          <span className="body-item">
            <h3>Taxa</h3>
            <p>{dinheiroMask(checkout?.fee_amount)}</p>
          </span>
          {checkout?.order_code ? (
            <span className="body-item">
              <h3>Pedido</h3>
              <p>{checkout?.order_code}</p>
            </span>
          ) : null}
          <span className="body-item">
            <h3>Origem</h3>
            <p>{checkout?.origin?.toLocaleUpperCase()}</p>
          </span>
          <span className="body-item">
            <h3>Análise de Risco</h3>
            <p>{analise_risco_tag}</p>
          </span>
          {checkout?.created_by ? (
            <span className="body-item">
              <h3>Criado Por</h3>
              <p>
                <UserName id_user={checkout?.created_by} />
              </p>
            </span>
          ) : null}
          {checkout?.payment_link_id && (
            <span className="body-item" style={{ maxWidth: '370px' }}>
              <h3>Link de pagamento</h3>
              <LinkCopyable
                to={`/link/${checkout.payment_link_id}`}
                copyableText={checkout.payment_link_id}
              >
                ID
              </LinkCopyable>
            </span>
          )}
          {checkout?.terminal_id ? (
            <span className="body-item">
              <h3>Terminal</h3>
              <p>
                <LinkCopyable
                  to={`/terminal/${checkout?.terminal_id}`}
                  copyableText={checkout?.terminal_id}
                >
                  ID
                </LinkCopyable>
              </p>
            </span>
          ) : null}
        </Body>
      </CardCollapse>
    </>
  );
};

export default CheckoutInfoGeral;

export const Header = styled.div`
  border-bottom: 1px solid #f1f1f1;

  .row {
    display: flex;
    justify-content: space-between;

    span.value {
      font-size: 40px;
      margin-right: 25px;
      font-weight: lighter;
    }

    div {
      .ant-typography {
        margin: 0;
      }
    }
  }

  .row-header {
    font-size: 13px;
    color: #717171;

    .ant-typography {
      color: #717171;
    }
  }

  .row-body {
    margin-top: 10px;
    display: flex;
    align-items: center;

    .status {
      display: flex;
      align-items: center;
    }
  }
`;

export const Body = styled.div`
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;

  span.body-item {
    /* min-width: 200px; */
    max-width: 300px;
    padding: 0 25px;
    border-right: 1px solid #f1f1f1;

    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
      border-right: none;
    }

    h3 {
      margin-bottom: 0;
      font-size: 13px;
      color: #a7a4a4;
      text-transform: uppercase;
    }

    p {
      font-size: 15px;
      margin: 0;
    }
  }
`;
