import colors from './colors';

const theme = {
  ...colors,

  card_background: '#fff',
  card_shadow: '0px 0px 10px rgba(0, 0, 0, .1)',
  card_shadow_hover: '0px 0px 2px 2px #ccc',
  card_transition: '0.3s',
  card_hr: '#e6e6e6',

  shadow: 'rgba(0, 0, 0, .1)',
  input: '#BCBCBC',
  table_border: '#eaeaea',
};

export default theme;
