export function extractPaymentMethod(str: string) {
  const match = str.match(/^([^\s-]+)/);
  return match ? match[1] : null;
}

export function extractUUID(str: string) {
  const match = str.match(/^[\w-]+\s*-\s*([\da-fA-F-]+)$/);
  return match ? match[1] : null;
}

export function getChargeTypeName(type: string) {
  switch (type) {
    case 'CREDIT_CARD':
      return 'Cartão de crédito';
    case 'DEBIT_CARD':
      return 'Cartão de débito';
    case 'BILL':
      return 'Boleto';
    case 'PIX':
      return 'Pix';
    default:
      return type;
  }
}
