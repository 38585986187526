import { createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer, PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { encryptTransform } from 'redux-persist-transform-encrypt';

import rootReducer from './modules/rootReducer';
import { IAuthState } from './modules/auth/reducer';
import { IUserState } from './modules/user/reducer';
import { IUtilsState } from './modules/utils/reducer';

export interface IState {
  auth: IAuthState,
  user: IUserState,
  utils: IUtilsState,
}

const encryptor = encryptTransform({
  secretKey: String(process.env.REACT_APP_CLIENT_SECRET),
  onError: error => {
    console.error('Persist reducer error -', error);
  },
});

const persistConsig: PersistConfig<any> = {
  key: 'PARCELAMOS_TUDO',
  storage,
  transforms: [encryptor],
}

const persistedReducer = persistReducer(persistConsig, rootReducer);

export const store = createStore(persistedReducer, composeWithDevTools());
export const persistor = persistStore(store);
