import EnumEstado from '../../../types/enum/Estado';
import EnumResponsabilidade from '../../../types/enum/Responsabilidade';
import TipoAcesso from '../../../types/TipoAcesso';
import { IDocumentoConta, ITipoLiquidacao } from '../user/reducer';

export enum EnumAuthActions {
  SIGN_IN = 'SIGN_IN',
  SIGN_OUT = 'SIGN_OUT',
  UPDATE_RESPONSABILIDADE = 'UPDATE_RESPONSABILIDADE',
  UPDATE_SHOW_CURRENCY_VALUE = 'UPDATE_SHOW_CURRENCY_VALUE',
  TOGGLE_MENU = 'TOGGLE_MENU',
}

interface SignInRequest {
  usuario: {
    nome: string;
    email: string;
    id_estado: EnumEstado | number;
    id_estabelecimento?: string;
    id_estabelecimento_whitelabel?: string;
  };
  perfis: {
    nome: string;
    id_perfil: number;
  }[];
  responsabilidades: {
    nome: string;
    id_perfil: number;
  }[];
  authenticated: boolean;
  documento_conta: IDocumentoConta;
  token: string;
  tipo: TipoAcesso;
  permissoes: number[];
  tipo_liquidacao: ITipoLiquidacao[];
}

export function signIn(data: SignInRequest) {
  return {
    type: EnumAuthActions.SIGN_IN,
    payload: data,
  };
}

export function signOut() {
  return {
    type: EnumAuthActions.SIGN_OUT,
    payload: {},
  };
}

export function updateResponsabilidade(responsabilidade: EnumResponsabilidade) {
  return {
    type: EnumAuthActions.UPDATE_RESPONSABILIDADE,
    payload: { responsabilidade },
  };
}

export function updateShowCurrencyValues(value: boolean) {
  return {
    type: EnumAuthActions.UPDATE_SHOW_CURRENCY_VALUE,
    payload: { show_currency_values: value },
  };
}

export function toggleMenu(value?: boolean) {
  return {
    type: EnumAuthActions.TOGGLE_MENU,
    payload: { toggle_menu: value },
  };
}
