import { StatusPermissao, Estado, Responsabilidade, StatusVinculo } from "./types";

export enum EnumUtilsActions {
  SET_PERMISSOES_PENDENTES = 'SET_PERMISSOES_PENDENTES',
  SET_VINCULOS_PENDENTES = 'SET_VINCULOS_PENDENTES',
  SET_STATUS_PERMISSOES = 'SET_STATUS_PERMISSOES',
  SET_STATUS_VINCULOS = 'SET_STATUS_VINCULOS',
  SET_ESTADOS = 'SET_ESTADOS',
  SET_RESPONSABILIDADE = 'SET_RESPONSABILIDADE',
}

export function updateQtdePermissoesPendentes(qtdePermissoesPendentes: number) {
  return {
    type: EnumUtilsActions.SET_PERMISSOES_PENDENTES,
    payload: { qtdePermissoesPendentes },
  }
}

export function updateQtdeVinculosPendentes(qtdeVinculosPendentes: number) {
  return {
    type: EnumUtilsActions.SET_VINCULOS_PENDENTES,
    payload: { qtdeVinculosPendentes },
  }
}

export function updateStatusAprovacao(data: StatusPermissao[]) {
  return {
    type: EnumUtilsActions.SET_STATUS_PERMISSOES,
    payload: { status_permissao: data },
  }
}

export function updateStatusVinculo(data: StatusVinculo[]) {
  return {
    type: EnumUtilsActions.SET_STATUS_VINCULOS,
    payload: { status_vinculo: data },
  }
}

export function updateEstado(data: Estado[]) {
  return {
    type: EnumUtilsActions.SET_ESTADOS,
    payload: { estado: data },
  }
}

export function updateResponsabilidade(data: Responsabilidade[]) {
  return {
    type: EnumUtilsActions.SET_RESPONSABILIDADE,
    payload: { responsabilidade: data },
  }
}
