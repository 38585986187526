export function randomString(length: number): string {
  const chars =
    '0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz'.split('');

  if (!length) {
    length = Math.floor(Math.random() * chars.length);
  }

  let str = '';
  for (let i = 0; i < length; i++) {
    str += chars[Math.floor(Math.random() * chars.length)];
  }

  return str;
}

export function randomStringNumber(length: number): string {
  const chars = '0123456789'.split('');

  if (!length) {
    length = Math.floor(Math.random() * chars.length);
  }

  let str = '';
  for (let i = 0; i < length; i++) {
    str += chars[Math.floor(Math.random() * chars.length)];
  }

  return str;
}

export function randomEmail(): string {
  return `${randomString(8)}@${randomString(5)}.com`;
}

export function randomCnpj(): string {
  // Generate random numbers for the CNPJ
  const randomDigits = Array.from({ length: 12 }, () =>
    Math.floor(Math.random() * 10),
  );

  // Calculate the first verification digit
  const firstDigit = calculateVerificationDigit(randomDigits, 5);

  // Calculate the second verification digit
  const secondDigit = calculateVerificationDigit(
    [...randomDigits, firstDigit],
    6,
  );

  // Format the CNPJ
  const formattedCNPJ = randomDigits.join('') + firstDigit + secondDigit;

  return formatCNPJ(formattedCNPJ);
}

function calculateVerificationDigit(digits: number[], factor: number): number {
  const sum = digits.reduce(
    (acc, digit, index) => acc + digit * (factor - index),
    0,
  );
  const remainder = sum % 11;

  return remainder < 2 ? 0 : 11 - remainder;
}

function formatCNPJ(cnpj: string): string {
  return `${cnpj.slice(0, 2)}.${cnpj.slice(2, 5)}.${cnpj.slice(
    5,
    8,
  )}/${cnpj.slice(8, 12)}-${cnpj.slice(12)}`;
}
