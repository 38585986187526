import React from 'react';
import { Tag, Tooltip } from 'antd';
import { cnpjMask, cpfMask } from 'masks-br';

type IProps = {
  documento_conta_pt_verificado: boolean;
  documento_conta_pt: string;
};

function findTagByDocumentAccount({
  documento_conta_pt,
  documento_conta_pt_verificado,
}: IProps) {
  const verificado = documento_conta_pt_verificado;

  const document =
    documento_conta_pt?.length === 11
      ? cpfMask(documento_conta_pt)
      : cnpjMask(documento_conta_pt);

  if (!document) {
    return null;
  }

  if (verificado === true) {
    return (
      <Tooltip placement="top" title="Documento verificado e aprovado">
        <Tag color="green">{document}</Tag>
      </Tooltip>
    );
  }

  if (verificado === false) {
    return (
      <Tooltip placement="top" title="Documento verificado e reprovado">
        <Tag color="red">{document}</Tag>
      </Tooltip>
    );
  }

  return (
    <Tooltip placement="top" title="Documento não verificado">
      <Tag>{document}</Tag>
    </Tooltip>
  );
}

export default findTagByDocumentAccount;
