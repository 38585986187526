import {
  Button,
  Dropdown,
  Typography,
  Menu,
  Modal,
  message,
} from 'antd';
import React, { useState } from 'react';
import UserName from '../../../../components/User/Name';
import CardCollapse from '../../../../components/CardCollapse';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import TextArea from 'antd/lib/input/TextArea';
import { useHistory } from 'react-router';
import { formatDate } from '../../../../utils/date';
import { useMutation } from 'react-query';
import { deleteApiClient } from '../../services';
import { ApiClient } from "../types";
import { MdDelete } from "react-icons/md";
import * as S from './styles';
import { queryClientApp } from "../../../../App";

const { Paragraph } = Typography;

type IClientApiInfoGeral = {
  data?: ApiClient;
  loading: boolean;
};

const ClientApiInfoGeral: React.FC<IClientApiInfoGeral> = ({
  loading,
  data = {} as ApiClient,
}) => {
  const [description, setDescription] = useState<string>('');
  const history = useHistory();

  const { mutate: deleteApiCliente } = useMutation({
    mutationFn: (id: string) => deleteApiClient(id),
    onSuccess: () => {
      queryClientApp.invalidateQueries(['clients']);
      message.success('Cliente API removido com sucesso!');
      handleGetBackToListApiClient();
    },
    onError: (error: any) => {
      message.error(error?.message || 'Erro ao remover Cliente API, tente novamente!');
    },
  });

  const handleGetBackToListApiClient = () => {
    history.push('/cliente-api');
  };

  const handleRemoveApiClient = (id_api_client: string) => {
    Modal.confirm({
      title: 'Tem certeza que deseja remover?',
      content: (
        <TextArea
          rows={4}
          placeholder="Insira o motivo da alteração"
          onChange={(e) => setDescription(e.target.value)}
        />
      ),
      centered: true,
      onOk() {
        deleteApiCliente(id_api_client);
      },
    });
  };

  const menu = (
    <Menu>
      <Menu.Item>
        <Button
          onClick={() => handleRemoveApiClient(data.id_api_client)}
          type="text"
          icon={<MdDelete style={{ marginRight: 10 }} />}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          Remover
        </Button>
      </Menu.Item>
    </Menu>
  );

  const formattedDate = data?.created_at ? formatDate(new Date(data.created_at), 'datetimefull') : '';

  return (
    <CardCollapse header={false} loading={loading}>
      <S.Header>
        <div className="row row-header">
          <div>{formattedDate}</div>
          <div>
            <Paragraph copyable>{data?.id_api_client}</Paragraph>
          </div>
        </div>
        <div className="row row-body">
          <div className="information">
            <span className="name">{data.name}</span>
          </div>
          <div>
            <Dropdown overlay={menu} placement="bottomRight">
              <Button shape="circle">
                <BiDotsVerticalRounded />
              </Button>
            </Dropdown>
          </div>
        </div>
      </S.Header>
      <S.Body>
        <span className="body-item">
          <h3>Estabelecimento</h3>
          <p>
            <UserName id_user={data?.establishment_id} />
          </p>
        </span>
        {data?.created_by && (
          <span className="body-item">
            <h3>Criado Por</h3>
            <p>
              <UserName id_user={data?.created_by} />
            </p>
          </span>
        )}
      </S.Body>
    </CardCollapse>
  );
};

export default ClientApiInfoGeral;
