import { Button, Modal, Tag, Tooltip, Typography, Dropdown, Menu } from 'antd';
import React, { useMemo } from 'react';
import { MdDelete } from 'react-icons/md';
import { GiPunch } from 'react-icons/gi';
import {
  AiTwotoneLike,
  AiTwotoneDislike,
  AiFillCheckCircle,
} from 'react-icons/ai';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { BsArrowCounterclockwise } from 'react-icons/bs';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { dinheiroMask } from 'masks-br';
import { FaShieldAlt } from 'react-icons/fa';

import CardCollapse from '../../../../components/CardCollapse';
import { fetchApi } from '../../../../services/api';
import { usePermission } from '../../../../hooks/usePermissions';

import { findStatusColor } from '../..';
import EnumStatusCarrinho from '../../../../types/enum/StatusCarrinho';

const { Paragraph } = Typography;

type ICarrinhoInfoGeral = {
  carrinho: any;
  analise_risco: any[];
  loading: boolean;
  emDisputa: boolean;
  fetchCarrinho: () => void;
};

const CarrinhoInfoGeral: React.FC<ICarrinhoInfoGeral> = ({
  loading,
  emDisputa,
  carrinho,
  analise_risco,
  fetchCarrinho,
}: ICarrinhoInfoGeral) => {
  const { userPermissions } = usePermission();

  const criado_em = useMemo(() => {
    if (!carrinho?.criado_em) {
      return '';
    }

    return format(new Date(carrinho.criado_em), 'dd/MM/yyyy HH:mm:ss');
  }, [carrinho]);

  const analise_risco_tag = useMemo(() => {
    if (!analise_risco?.length) {
      return '-';
    }

    return analise_risco?.map(ar => {
      const color = ar.aprovado === null ? '' : ar.aprovado ? 'green' : 'red';
      const info = ar.integrador
        ? `${ar.integrador} - ${ar.score}%`
        : `Não executado`;

      return (
        <Tooltip placement="top" title={ar.mensagem}>
          <Tag icon={<FaShieldAlt />} color={color}>
            {' '}
            {info}
          </Tag>
        </Tooltip>
      );
    });
  }, [analise_risco]);

  function handleRemoveCart() {
    Modal.confirm({
      title: 'Tem certeza que deseja remover esse carrinho?',
      content: 'Iremos remover as comissões não pagas desse carrinho',
      centered: true,
      onOk() {
        return fetchApi({
          url: `/carrinho/${carrinho?.id_carrinho}`,
          method: 'delete',
          messages: {
            loading: 'Removendo carrinho, aguarde...',
            error: 'Erro ao remover o carrinho, tente novamente!',
            success: 'Carrinho removido com sucesso!',
          },
          onSuccess: () => fetchCarrinho(),
        });
      },
    });
  }

  function handleStatusCart(
    id_status: EnumStatusCarrinho,
    name_status: string,
  ) {
    Modal.confirm({
      title: `Tem certeza que deseja mudar o status do carrinho para "${name_status}" ?`,
      centered: true,
      onOk() {
        return fetchApi({
          url: `/carrinho/${carrinho?.id_carrinho}/${id_status}`,
          method: 'put',
          onSuccess: async () => {
            await fetchCarrinho();
          },
        });
      },
    });
  }

  const menu = (
    <Menu>
      {emDisputa ? (
        <Menu.ItemGroup>
          <Menu.Item>
            <Button
              onClick={() =>
                handleStatusCart(
                  EnumStatusCarrinho.PAGAMENTO_FINALIZADO,
                  'Pagamento Finalizado',
                )
              }
              type="text"
              icon={<AiTwotoneLike />}
            >
              Disputa Ganha
            </Button>
          </Menu.Item>
          <Menu.Item>
            <Button
              onClick={() =>
                handleStatusCart(EnumStatusCarrinho.CHARGEBACK, 'Chargeback')
              }
              type="text"
              icon={<AiTwotoneDislike />}
            >
              Disputa Perdida
            </Button>
          </Menu.Item>
        </Menu.ItemGroup>
      ) : (
        <Menu.Item>
          <Button
            disabled={!userPermissions.CARRINHO_INFORMACAO}
            onClick={() =>
              handleStatusCart(EnumStatusCarrinho.EM_DISPUTA, 'Em disputa')
            }
            type="text"
            icon={<GiPunch />}
          >
            Carrinho em disputa
          </Button>
        </Menu.Item>
      )}
      {carrinho?.id_status_carrinho !== EnumStatusCarrinho.ESTORNADO ? (
        <Menu.Item>
          <Button
            onClick={() =>
              handleStatusCart(EnumStatusCarrinho.ESTORNADO, 'Estornado')
            }
            type="text"
            icon={<BsArrowCounterclockwise />}
          >
            Carrinho Estornado
          </Button>
        </Menu.Item>
      ) : (
        <Menu.Item>
          <Button
            onClick={() =>
              handleStatusCart(
                EnumStatusCarrinho.PAGAMENTO_FINALIZADO,
                'Pagamento finalizado',
              )
            }
            type="text"
            icon={<BsArrowCounterclockwise />}
          >
            Pagamento Finalizado
          </Button>
        </Menu.Item>
      )}
      <Menu.Divider />
      <Menu.Item>
        <Button
          disabled={
            carrinho?.removido_em || !userPermissions.CARRINHO_INFORMACAO
          }
          onClick={() => handleRemoveCart()}
          type="text"
          icon={<MdDelete />}
        >
          Remover
        </Button>
      </Menu.Item>
      <Menu.Item>
        <Button
          disabled={
            carrinho?.id_status_carrinho ===
              EnumStatusCarrinho.PAGAMENTO_FINALIZADO ||
            carrinho?.removido_em ||
            !userPermissions.CARRINHO_INFORMACAO
          }
          onClick={() =>
            handleStatusCart(
              EnumStatusCarrinho.PAGAMENTO_FINALIZADO,
              'Pagamento Finalizado',
            )
          }
          type="text"
          icon={<AiFillCheckCircle />}
        >
          Pagamento Finalizado
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <CardCollapse header={false} loading={loading}>
        <Header>
          <div className="row row-header">
            <div>{criado_em}</div>
            <div>
              <Paragraph copyable>{carrinho?.id_carrinho}</Paragraph>
            </div>
          </div>
          <div className="row row-body">
            <div className="status">
              <span className="value">
                {dinheiroMask(carrinho?.valor_total)}
              </span>
              {carrinho
                ? findStatusColor(carrinho.status, carrinho.status_tipo)
                : null}
            </div>
            <div>
              <Dropdown overlay={menu} placement="bottomRight">
                <Button shape="circle">
                  <BiDotsVerticalRounded />
                </Button>
              </Dropdown>
            </div>
          </div>
        </Header>

        <Body>
          {carrinho?.estabelecimento ? (
            <span className="body-item">
              <h3>Estabelecimento</h3>
              <p>
                <Link to={`/usuario/${carrinho?.id_estabelecimento}`}>
                  {carrinho?.estabelecimento}
                </Link>
              </p>
            </span>
          ) : null}
          {carrinho?.usuario ? (
            <span className="body-item">
              <h3>Usuário</h3>
              <p>
                <Link to={`/usuario/${carrinho?.id_usuario}`}>
                  {carrinho?.usuario}
                </Link>
              </p>
            </span>
          ) : null}
          {carrinho?.num_pedido ? (
            <span className="body-item">
              <h3>Pedido</h3>
              <p>{carrinho?.num_pedido}</p>
            </span>
          ) : null}
          <span className="body-item">
            <h3>Taxa</h3>
            <p>{dinheiroMask(carrinho?.taxa)}</p>
          </span>
          <span className="body-item">
            <h3>Origem</h3>
            <p>{carrinho?.origem?.toLocaleUpperCase()}</p>
          </span>
          <span className="body-item">
            <h3>Análise de Risco</h3>
            <p>{analise_risco_tag}</p>
          </span>
          {carrinho?.criado_por_nome ? (
            <span className="body-item">
              <h3>Criado Por</h3>
              <p>
                <Link to={`/usuario/${carrinho.criado_por}`}>
                  {carrinho.criado_por_nome}
                </Link>
              </p>
            </span>
          ) : null}
        </Body>
      </CardCollapse>
    </>
  );
};

export default CarrinhoInfoGeral;

export const Header = styled.div`
  border-bottom: 1px solid #f1f1f1;

  .row {
    display: flex;
    justify-content: space-between;

    span.value {
      font-size: 40px;
      margin-right: 25px;
      font-weight: lighter;
    }

    div {
      .ant-typography {
        margin: 0;
      }
    }
  }

  .row-header {
    font-size: 13px;
    color: #717171;

    .ant-typography {
      color: #717171;
    }
  }

  .row-body {
    margin-top: 10px;
    display: flex;
    align-items: center;

    .status {
      display: flex;
      align-items: center;
    }
  }
`;

export const Body = styled.div`
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;

  span.body-item {
    /* min-width: 200px; */
    max-width: 300px;
    padding: 0 25px;
    border-right: 1px solid #f1f1f1;

    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
      border-right: none;
    }

    h3 {
      margin-bottom: 0;
      font-size: 13px;
      color: #a7a4a4;
      text-transform: uppercase;
    }

    p {
      font-size: 15px;
      margin: 0;
    }
  }
`;
