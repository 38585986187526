import { Button, Col, Form, Input, message, Row, Space, Table } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { format, parseISO } from 'date-fns';
import CardCollapse from '../../../../components/CardCollapse';
import PageHeader from '../../../../components/PageHeader';
import 'moment/locale/pt-br';
import { fetchApi } from '../../../../services/api';
import { setQueryParams, useQueryParams } from '../../../../utils/UrlQuery';
import { celularMask, cleanMask } from 'masks-br';
import EnumResponsabilidade from '../../../../types/enum/Responsabilidade';
import gerador from 'generate-password';
import { randomCnpj, randomEmail } from '../../../../utils/Random';

const default_message = 'Esse campo é obrigatório';

export type IUnidade = {
  nome: string;
  criado_em: string;
};

interface IListUnidade {
  total: number;
  pagina: number;
  porPagina: number;
  data: IUnidade[];
}

const CadastroUnidade: React.FC = () => {
  const [formFilters] = Form.useForm();
  const [unidadeForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [unidades, setUnidades] = useState({} as IListUnidade);
  const [filtros, setFiltros] = useState<any | undefined>(undefined);
  const query = useQueryParams();

  async function handleCreateUnidade(data: any) {
    setLoading(true);

    data.permissions = [EnumResponsabilidade.ESTABELECIMENTO];

    data.type = 'juridica';

    data.user = {
      name: data.nome,
      email: randomEmail(),
      document: cleanMask(randomCnpj()),
      phone: data.telefone ? cleanMask(data.telefone) : undefined,
      password:
        data.senha ||
        gerador.generate({ uppercase: false, numbers: false, length: 8 }),
    };

    delete data.nome;
    delete data.telefone;

    return fetchApi({
      url: `/panel/users/tj`,
      method: 'post',
      data,
      onSuccess: async () => {
        await fetchUnidades();
        message.success('Unidade cadastrada com sucesso.');
        unidadeForm.resetFields();
        setLoading(false);
      },
      onError: error => {
        setLoading(false);
        message.error(error.message);
      },
    });
  }

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'nome',
      key: 'nome',
    },
    {
      title: 'Criado em',
      dataIndex: 'criado_em',
      key: 'criado_em',
      render: (item: string) => format(parseISO(item), 'dd/MM/yyyy HH:mm:ss'),
    },
  ];

  useEffect(() => {
    const final = {
      page: 1,
      page_size: 25,
      ...query,
    };

    setFiltros(final);
    formFilters.setFieldsValue(final);
  }, []);

  const handleSetFiltroValues = (data: any) => {
    setFiltros({
      ...data,
      page: 1,
      page_size: 25,
    });
  };

  useEffect(() => {
    if (filtros) {
      fetchUnidades();
    }
  }, [filtros]);

  async function fetchUnidades() {
    setLoading(true);

    return fetchApi({
      url: '/panel/users/tj/establishment',
      method: 'get',
      query_params: {
        ...filtros,
      },
      onSuccess: (data: any) => {
        setLoading(false);
        setUnidades(data);
      },
      onError: () => {
        setLoading(false);
      },
    });
  }

  function handleResetForm() {
    setQueryParams({});
    formFilters.resetFields();
    setFiltros({
      page: 1,
      page_size: 25,
    });
    fetchUnidades();
  }

  const senha_default = useMemo(() => {
    return gerador.generate({ uppercase: false, numbers: false, length: 8 });
  }, []);

  return (
    <>
      <PageHeader
        title="Cadastro de Unidade"
        breadcrumb={['Cadastrar Unidade', 'Criação']}
      />
      <CardCollapse loading={loading} title="Cadastrar Unidade">
        <Form
          layout="vertical"
          form={unidadeForm}
          onFinish={handleCreateUnidade}
          initialValues={{ senha: senha_default }}
        >
          <Row gutter={16}>
            <Col md={12} xs={24}>
              <Form.Item
                name="nome"
                label="Nome"
                rules={[{ required: true, message: default_message }]}
              >
                <Input maxLength={150} />
              </Form.Item>
            </Col>
            <Col md={6} xs={24}>
              <Form.Item
                name="telefone"
                label="Telefone"
                rules={[{ required: true, message: default_message }]}
              >
                <Input
                  onChange={e =>
                    unidadeForm.setFieldsValue({
                      telefone: celularMask(e.target.value),
                    })
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="end">
            <Button type="primary" htmlType="submit">
              Salvar
            </Button>
          </Row>
        </Form>
      </CardCollapse>
      <CardCollapse title="Filtros">
        <Form
          layout="vertical"
          onFinish={data => handleSetFiltroValues(data)}
          form={formFilters}
        >
          <Row gutter={16}>
            <Col md={6}>
              <Form.Item name="nome" label="Nome">
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <br />

          <Row justify="end">
            <Space>
              <Button onClick={() => handleResetForm()}>Limpar</Button>
              <Button type="primary" htmlType="submit">
                Filtrar
              </Button>
            </Space>
          </Row>
        </Form>
      </CardCollapse>
      <Table
        columns={columns}
        dataSource={unidades.data}
        loading={loading}
        pagination={{
          total: unidades.total,
          current: unidades.pagina,
          pageSize: unidades.porPagina,
          onChange: (page, page_size) =>
            handleSetFiltroValues({ page, page_size }),
        }}
      />
    </>
  );
};

export default CadastroUnidade;
