import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Row,
  Select,
  Typography,
} from 'antd';
import { celularMask, cepMask, cleanMask, cnpjMask, cpfMask } from 'masks-br';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import moment from 'moment';
import 'moment/locale/pt-br';
import locale from 'antd/es/date-picker/locale/pt_BR';

import CardCollapse from '../../../components/CardCollapse';
import PageHeader from '../../../components/PageHeader';
import { usePermission } from '../../../hooks/usePermissions';
import Option from '../../../types/Option';
import { useUtils } from '../../../hooks/useUtils';
import EnumResponsabilidade from '../../../types/enum/Responsabilidade';
import { fetchApi } from '../../../services/api';

const default_message = 'Esse campo é obrigatório';

const { Text, Paragraph } = Typography;

interface IUsuario {
  usuario?: any;
  dado_bancario?: any;
  permissoes?: any[];
  pos?: any[];
  perfis?: any[];
  tipo_liquidacao?: any[];
  anotacoes?: any[];
}

const required_message = 'Esse campo é obrigatório';

const select_forma_pagamento: Option[] = [
  {
    label: 'Conta-Corrente',
    value: 'CHECKING',
  },
  {
    label: 'Poupança',
    value: 'SAVINGS',
  },
];
const initialValuesDataBank = {
  cod_banco: '332',
  agencia: '0001',
  tipo: 'CHECKING',
};

const UsuarioEditar: React.FC = () => {
  const [usuarioForm] = Form.useForm();
  const [usuarioContaPagamentoform] = Form.useForm();
  const { userPermissions } = usePermission();
  const { id } = useParams<{ id: string }>();
  const { getEstadoCivil, getEstado } = useUtils();

  const [usuario, setUsuario] = useState<IUsuario>({} as IUsuario);
  const [loading, setLoading] = useState(true);
  const [estado, setEstado] = useState<Option[]>([]);
  const [cidade, setCidade] = useState<Option[]>([]);
  const [estadoCivil] = useState<Option[]>(() =>
    getEstadoCivil().map(item => ({ value: item, label: item })),
  );

  useEffect(() => {
    Promise.all([fetchUserData(), fetchEstado(), fetchDataBank()]).then(() => {
      setLoading(false);
    });
  }, []);

  const landing_page = useMemo(() => {
    if (!usuario.usuario?.cod_usuario) {
      return (
        <Text type="warning">É necessário cadastrar o código de usuário</Text>
      );
    }

    if (!usuario.usuario?.email) {
      return <Text type="warning">É necessário cadastrar o email</Text>;
    }

    const url = `https://parcelamostudo.com.br/landing.php?nome_representante=${usuario.usuario?.cod_usuario}&email_representante=${usuario.usuario?.email}`;

    return (
      <Paragraph copyable={{ tooltips: false, text: url }}>
        <a href={url} target="_blank" rel="noopener noreferrer">
          {url}
        </a>
      </Paragraph>
    );
  }, [usuario]);

  const is_estabelecimento = useMemo(() => {
    if (
      usuario.permissoes?.find(
        e => e.id_responsabilidade === EnumResponsabilidade.ESTABELECIMENTO,
      )
    ) {
      return true;
    }

    return false;
  }, [usuario]);

  function fetchUserData() {
    return fetchApi<IUsuario>({
      url: `/usuario/${id}`,
      method: 'get',
      onSuccess: data => {
        const mask = 'dd/MM/yyyy HH:mm:ss';

        const permissoes = data?.permissoes?.map(item => ({
          ...item,
          criado_em: format(parseISO(item.criado_em), mask),
          removido_em: item.removido_em
            ? format(parseISO(item.removido_em), mask)
            : '-',
        }));
        const pos = data?.pos?.map(item => ({
          ...item,
          criado_em: format(parseISO(item.criado_em), mask),
        }));
        const perfis = data?.perfis?.map(item => ({
          ...item,
          criado_em: format(parseISO(item.criado_em), mask),
        }));

        setUsuario({
          ...data,
          permissoes,
          pos,
          perfis,
        });

        usuarioForm.setFieldsValue({
          ...data.usuario,
          cpf: cpfMask(data.usuario.cpf),
          cnpj: cnpjMask(data.usuario.cnpj),
          documento_conta_pt:
            data.usuario?.documento_conta_pt?.length <= 11
              ? cpfMask(data.usuario?.documento_conta_pt)
              : cnpjMask(data.usuario?.documento_conta_pt),
          cep: cepMask(data.usuario.cep),
          telefone: celularMask(data.usuario.telefone),
          data_nascimento: data.usuario.data_nascimento
            ? moment(data.usuario.data_nascimento, 'YYYY-MM-DD')
            : undefined,
        });

        fetchCidade(data.usuario.id_estado);
      },
    });
  }

  function fetchCidade(id_estado: string) {
    return fetchApi({
      url: `/cidade/estado/${id_estado}`,
      method: 'get',
      onSuccess: data => {
        const list: Option[] = data?.map((item: any) => ({
          value: item.id_cidade,
          label: item.nome,
        }));

        setCidade(list);
      },
    });
  }

  function handleUpdateUsuario(data: any) {
    if (!userPermissions.USUARIO_EDITAR) return null;

    const keys = Object.keys(data);

    data.cpf = data.cpf ? cleanMask(data.cpf) : undefined;
    data.cnpj = data.cnpj ? cleanMask(data.cnpj) : undefined;
    data.cep = data.cep ? cleanMask(data.cep) : undefined;
    data.telefone = data.telefone ? cleanMask(data.telefone) : undefined;
    data.documento_conta_pt = data.documento_conta_pt
      ? cleanMask(data.documento_conta_pt)
      : undefined;
    data.data_nascimento = data.data_nascimento
      ? `${data.data_nascimento.format('YYYY-MM-DD')}T03:00:00.000Z`
      : undefined;

    const updated_keys = keys?.filter(key => {
      if (data[key] !== usuario.usuario[key]) {
        return key;
      }
      return undefined;
    });

    if (!updated_keys.length) {
      return null;
    }

    const updated_values = updated_keys.reduce(
      (prev, key) => ({ ...prev, [key]: data[key] }),
      {},
    );

    return fetchApi({
      url: `/usuario/${id}`,
      method: 'put',
      data: updated_values,
      messages: {
        loading: 'Atualizando usuário, aguarde...',
        error: 'Erro ao atualizar usuário, tente novamente!',
        success: 'Usuário atualizados com sucesso!',
      },
      onSuccess: async () => {
        setLoading(true);

        await fetchUserData();

        setLoading(false);
      },
    });
  }

  function handleFetchCidade(id_estado: string) {
    usuarioForm.setFieldsValue({ id_cidade: undefined });

    return fetchCidade(id_estado);
  }

  async function fetchEstado() {
    const estados_response = await getEstado();

    setEstado(
      estados_response?.map(item => ({
        value: item.id_estado,
        label: item.nome,
      })),
    );
  }

  async function fetchDataBank() {
    await fetchApi({
      url: `/conta_pagamento/${id}`,
      method: 'get',
      onSuccess: (data: any) => {
        data.documento_conta_pt =
          data.documento_conta_pt <= 11
            ? cpfMask(data.documento_conta_pt)
            : cnpjMask(data.documento_conta_pt);
        usuarioContaPagamentoform.setFieldsValue(data);
        message.success('Dados bancários gravados com sucesso!');
      },
      onError: () => {
        message.warn('Boleto com vencimento inválido.');
      },
    });
  }

  const handleCreateDataBank = (data: any) => {
    setLoading(true);

    data.id_usuario = id;

    data.documento_conta_pt = cleanMask(data.documento_conta_pt);

    return fetchApi({
      url: `/conta_pagamento`,
      method: 'post',
      data,
      messages: {
        loading: 'Gravando dados bancários, aguarde...',
        success: 'Dados bancários gravados com sucesso!',
        error: 'Erro ao gravar dados bacários, tente novamente!',
      },
      onError: () => setLoading(false),
      onSuccess: async () => {
        await fetchDataBank();
        setLoading(false);
      },
    });
  };

  return (
    <>
      <PageHeader
        title="Edição de Usuário"
        breadcrumb={['Usuário', 'Informações', 'Edição']}
      />

      <CardCollapse loading={loading} title="Info">
        <Form
          layout="vertical"
          form={usuarioForm}
          onFinish={handleUpdateUsuario}
        >
          <Row gutter={16}>
            <Col md={12} xs={24}>
              <Form.Item
                name="nome"
                label="Nome"
                rules={[{ required: true, message: default_message }]}
              >
                <Input maxLength={150} />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item name="razao_social" label="Razão Social">
                <Input maxLength={255} />
              </Form.Item>
            </Col>
            {usuario?.usuario?.cpf ? (
              <Col md={8} xs={24}>
                <Form.Item name="cpf" label="CPF">
                  <Input
                    onChange={e =>
                      usuarioForm.setFieldsValue({
                        cpf: cpfMask(e.target.value),
                      })
                    }
                  />
                </Form.Item>
              </Col>
            ) : (
              <Col md={8} xs={24}>
                <Form.Item name="cnpj" label="CNPJ">
                  <Input
                    onChange={e =>
                      usuarioForm.setFieldsValue({
                        cnpj: cnpjMask(e.target.value),
                      })
                    }
                  />
                </Form.Item>
              </Col>
            )}
            <Col md={8} xs={24}>
              <Form.Item name="rg" label="RG">
                <Input maxLength={14} />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item name="orgao_emissor" label="Orgão Emissor">
                <Input maxLength={150} />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item name="estado_emissor" label="Estado Emissor">
                <Input maxLength={150} />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item name="data_nascimento" label="Data Nascimento">
                <DatePicker
                  placeholder=""
                  format="DD/MM/YYYY"
                  locale={locale}
                />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item name="cod_usuario" label="Código do Usuário">
                <Input maxLength={50} />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item
                name="email"
                label="Email"
                rules={[{ type: 'email', message: 'Insira um email válido' }]}
              >
                <Input maxLength={150} />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item name="telefone" label="Telefone">
                <Input
                  onChange={e =>
                    usuarioForm.setFieldsValue({
                      telefone: celularMask(e.target.value),
                    })
                  }
                />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item name="estado_civil" label="Estado Civil">
                <Select options={estadoCivil} />
              </Form.Item>
            </Col>
          </Row>

          <Divider orientation="left">Landing Page</Divider>

          <Row gutter={16}>
            <Col md={24} xs={24}>
              {landing_page}
            </Col>
          </Row>

          <Divider orientation="left">Endereço</Divider>

          <Row gutter={16}>
            <Col md={6} xs={24}>
              <Form.Item name="cep" label="CEP">
                <Input
                  onChange={e =>
                    usuarioForm.setFieldsValue({
                      cep: cepMask(e.target.value),
                    })
                  }
                />
              </Form.Item>
            </Col>
            <Col md={18} xs={24}>
              <Form.Item name="endereco" label="Endereço">
                <Input />
              </Form.Item>
            </Col>
            <Col md={18} xs={24}>
              <Form.Item name="complemento" label="Complemento">
                <Input />
              </Form.Item>
            </Col>
            <Col md={6} xs={24}>
              <Form.Item name="numero" label="Número">
                <Input />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item name="id_estado" label="Estado">
                <Select
                  options={estado}
                  onChange={(a: any) => handleFetchCidade(a)}
                />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item name="id_cidade" label="Cidade">
                <Select options={cidade} />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item name="bairro" label="Bairro">
                <Input />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item name="latitude" label="Latitude">
                <Input />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item name="longitude" label="Longitude">
                <Input />
              </Form.Item>
            </Col>
          </Row>

          {is_estabelecimento ? (
            <>
              <Divider orientation="left">Configurações</Divider>

              <Form.Item name="enviar_sms" valuePropName="checked">
                <Checkbox disabled>Enviar SMS para Usuários</Checkbox>
              </Form.Item>
              <Form.Item name="enviar_whatsappp" valuePropName="checked">
                <Checkbox disabled>Enviar Whatsapp para Usuários</Checkbox>
              </Form.Item>
            </>
          ) : null}

          <br />

          {userPermissions.USUARIO_EDITAR ? (
            <Row justify="end">
              <Button type="primary" htmlType="submit">
                Salvar
              </Button>
            </Row>
          ) : null}
        </Form>
      </CardCollapse>

      <CardCollapse>
        <Divider orientation="left">Dados Bancários</Divider>
        <Form
          layout="vertical"
          onFinish={data => handleCreateDataBank(data)}
          form={usuarioContaPagamentoform}
          initialValues={initialValuesDataBank}
        >
          <Row gutter={16}>
            <Col md={4}>
              <Form.Item
                name="cod_banco"
                label="Código do Banco"
                rules={[{ required: true, message: required_message }]}
              >
                <Input maxLength={3} disabled />
              </Form.Item>
            </Col>
            <Col md={4}>
              <Form.Item
                name="agencia"
                label="Agência"
                rules={[{ required: true, message: required_message }]}
              >
                <Input maxLength={4} disabled />
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item
                name="conta"
                label="Número da Conta"
                rules={[{ required: true, message: required_message }]}
              >
                <Input maxLength={9} />
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item
                name="documento_conta_pt"
                label="Documento da Conta"
                rules={[{ required: true, message: required_message }]}
              >
                <Input
                  onChange={e =>
                    usuarioContaPagamentoform.setFieldsValue({
                      documento_conta_pt:
                        e.target.value.length <= 14
                          ? cpfMask(e.target.value)
                          : cnpjMask(e.target.value),
                    })
                  }
                />
              </Form.Item>
            </Col>
            <Col md={4}>
              <Form.Item
                label="Tipo de Conta"
                name="tipo"
                rules={[{ required: true, message: required_message }]}
              >
                <Select options={select_forma_pagamento} />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="end" style={{ marginTop: 12 }}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Salvar
            </Button>
          </Row>
        </Form>
      </CardCollapse>
    </>
  );
};

export default UsuarioEditar;
