import { format } from 'date-fns';

export function formatDate(
  date: Date,
  form: 'date' | 'datetime' | 'datetimefull',
): string {
  let forma = 'dd/MM/yyyy HH:mm:ss';

  if (form === 'date') forma = 'dd/MM/yyyy';
  if (form === 'datetime') forma = 'dd/MM/yyyy HH:mm';

  return format(date, forma);
}
