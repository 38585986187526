import { StatusTipo } from '../../store/modules/utils/types';

export type BoletoStatus = {
  id_boleto?: string;
  id_boleto_status?: string;
  id_status_boleto?: number;
  nome: string;
  tipo: StatusTipo;
  mensagem?: string;
  criado_em: string;
  id_status?: string;
};

export interface Boleto {
  id_boleto: string;
  id_carrinho?: string;
  id_payment?: string;

  identificador_terceiro?: string;
  cod_boleto: string;
  codigo_barras?: string;
  linha_digitavel?: string;
  valor: number;
  taxa: number;
  desconto: number;
  valor_total: number;
  data_vencimento?: string;
  liquidado_em?: string;
  liquidacao_id?: string | null;
  removido_em?: string;
  removido_por?: string;

  criado_em: string;
  tipo_liquidacao?: string;
  id_status_boleto: number;
  status: string;
  status_tipo: StatusTipo;

  id_settlement_type: EnumSettlementType;

  id_status?: EnumBillStatus;
}

export interface Carrinho {
  id_carrinho: string;
  num_pedido?: number;
  valor_total: number;
  criado_em: Date;
}

export interface Cliente {
  email: string;
}
export interface DataBoleto {
  boleto: Boleto;
  status: BoletoStatus[];
  tipos_liquidacao: string[];
  carrinhos: Carrinho[];
  cliente: Cliente;
  tipo_boleto_liquicacao: BoletoTipoLiquidacao[][];
}
export interface DataBoletoRaw {
  id_bill: string;
  id_status: string;
  code: string;
  digitable: string;
  type: 'CLEARANCE_FORM' | 'CONCESSIONAIRES';
  correlation_id: string;
  recipient_assignor: string;
  recipient_document: string;
  recipient_name: string;
  payer_document: string;
  payer_name: string;
  due_date: string;
  start_payment_hour?: number;
  end_payment_hour?: number;
  amount_original: number;
  amount_total: number;
  amount_discount: number;
  amount_additional: number;
  authentication_code?: string;
  id_settlement_type?: EnumSettlementType;
  settlement_institution?: string;
  settlement_transaction_id?: string;
  settled_at?: string;
  created_at: string;
  updated_at: string;
  source?: string;
  payments: {
    id_payment: string;
    checkout_id: string;
    establishment_id: string;
    id_bill: string;
    debit_id: null;
    id_status: string;
    type: 'BILL' | 'PIX' | 'PARTNER';
    amount: number;
    fee_amount: number;
    total_amount: number;
    created_at: string;
    updated_at: string;
    status: DataBoletoRaw['status'];
  }[];
  status: {
    id_status: string;
    name: string;
    type: StatusTipo;
    description: string;
  };
}

export type BoletoTipoLiquidacao = {
  atualizado_em: string;
  id_boleto: string;
  id_boleto_liquidacao: string;
  id_status_boleto: number;
  id_tipo_liquidacao: number;
  info?: string;
  liquidacao_id: string;
  status_nome: string;
  status_tipo: StatusTipo;
  tipo_liquidacao_nome: string;
};

export type Bill = {
  id_bill: string;
  id_status: string;
  code: string;
  digitable: string;
  type: string;
  correlation_id: string;
  recipient_assignor: string;
  recipient_document: string;
  recipient_name: string;
  payer_document: string;
  payer_name: string;
  due_date: null | string;
  start_payment_hour: number;
  end_payment_hour: number;
  amount_original: number;
  amount_total: number;
  amount_discount: number;
  amount_additional: number;
  authentication_code: string;
  id_settlement_type: number;
  settlement_institution: string;
  settlement_transaction_id: string;
  settled_at: string;
  created_at: string;
  updated_at: string;
  payments: Payment[];
  status: PaymentStatus;
};

type Payment = {
  id_payment: string;
  checkout_id: string;
  establishment_id: string;
  id_bill: string;

  debit_id?: string;

  id_status: EnumBillStatus;

  type: EnumBillType;
  amount: number;
  fee_amount: number;
  total_amount: number;
  created_at: string;
  updated_at: string;
};

export enum EnumBillType {
  CLEARANCE_FORM = 'CLEARANCE_FORM',
  CONCESSIONAIRES = 'CONCESSIONAIRES',
}

export enum EnumBillStatus {
  FETCH_ERROR = 'FETCH_ERROR',
  FETCHED = 'FETCHED',
  WAITING_PAYMENT = 'WAITING_PAYMENT',
  WAITING_CONFIRMATION = 'WAITING_CONFIRMATION',
  PAYMENT_ERROR = 'PAYMENT_ERROR',
  PAYED = 'PAYED',
  CANCELED = 'CANCELED',
  REVERSED = 'REVERSED',
  WAITING_EXPORT = 'WAITING_EXPORT',
  EXPORTED = 'EXPORTED',
}

export type PaymentTimeline = {
  id_timeline: string;
  id_payment: string;
  id_status: string;
  description: string;
  created_by: string;
  created_at: string;
  status: PaymentStatus;
};

export type PaymentStatus = {
  id_status: EnumPaymentStatus;
  name: string;
  type: EnumStatusType;
  description: string;
};

export enum EnumPaymentStatus {
  // Iniciais
  RECEIVED = 'RECEIVED',
  STARTED = 'STARTED',
  PENDING = 'PENDING',
  // Pagamento
  PAYMENT_DONE = 'PAYMENT_DONE',
  PAYMENT_ERROR = 'PAYMENT_ERROR',
  PAYMENT_REVERSED = 'PAYMENT_REVERSED',
  PAYMENT_CANCELED = 'PAYMENT_CANCELED',
  PAYMENT_CONFIRMED = 'PAYMENT_CONFIRMED',
  PAID_MANUALLY = 'PAID_MANUALLY',
  WAITING_PAYMENT = 'WAITING_PAYMENT',
  // Aprovação
  WAITING_APPROVAL = 'WAITING_APPROVAL',
  APPROVED = 'APPROVED',
  REPROVED = 'REPROVED',
}

export enum EnumStatusType {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  INFO = 'INFO',
}

export enum EnumSettlementType {
  BANK = 1,
  RETURN_FILE = 2,
  WEBHOOK = 3,
  BANK_ONLINE = 4,
  BANK_ESTABLISHMENT = 5,
  PIX = 6,
}
