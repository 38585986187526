import React, { useEffect, useState } from 'react';
import { Table, Form, Row, Col, Input, Button } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { cpfMask, celularMask, cleanMask, dinheiroMask } from 'masks-br';
import { Link } from 'react-router-dom';
import { MdOpenInNew } from 'react-icons/md';
import { fetchApi } from '../../../services/api';
import CardCollapse from '../../../components/CardCollapse';
import PageHeader from '../../../components/PageHeader';
import { setQueryParams } from '../../../utils/UrlQuery';

interface clienteData {
  nome: string;
  email: string;
  cpf: string;
  telefone: string;
  boletos: number;
  valor_total: string;
}

interface responseListClientes {
  total: number;
  pagina?: number;
  porPagina?: number;
  clientes: clienteData[];
}

interface typeFiltros {
  nome?: string;
  email?: string;
  cpf?: string;
  telefone?: string;
  pagina?: number;
  porPagina?: number;
}

const ClientePesquisar: React.FC = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState<responseListClientes>();
  const [filtros, setFiltros] = useState<typeFiltros>();

  useEffect(() => {
    setFiltros({
      pagina: 1,
      porPagina: 25,
    });
  }, []);

  useEffect(() => {
    if (filtros) {
      fetchClient();
    }
  }, [filtros]);

  async function fetchClient() {
    return fetchApi({
      url: '/cliente',
      method: 'get',
      query_params: filtros,
      onSuccess: data => {
        setResponse(data);
        setLoading(false);
      },
    });
  }

  function handleSetFiltroValues(data: typeFiltros) {
    if (data.cpf) {
      data.cpf = cleanMask(data.cpf);
    }
    if (data.telefone) {
      data.telefone = cleanMask(data.telefone);
    }

    const final = {
      ...filtros,
      ...data,
    };

    setQueryParams(final);
    setFiltros(final);
  }

  function handleUpdatePagination(pagina: number, porPagina?: number) {
    handleSetFiltroValues({ pagina, porPagina });
  }

  function handleResetForm() {
    setQueryParams({});
    form.resetFields();
    setFiltros({});
  }

  const columns: ColumnsType<any> = [
    {
      title: 'Nome',
      key: 'nome',
      dataIndex: 'nome',
      align: 'center',
    },
    {
      title: 'Email',
      key: 'email',
      dataIndex: 'email',
      align: 'center',
    },
    {
      title: 'CPF',
      key: 'cpf',
      dataIndex: 'cpf',
      align: 'center',
      render: (item: string) => cpfMask(item),
    },
    {
      title: 'Telefone',
      key: 'telefone',
      dataIndex: 'telefone',
      align: 'center',
      render: (item: string) => celularMask(item),
    },
    {
      title: 'Boletos',
      key: 'num_boletos',
      dataIndex: 'num_boletos',
      align: 'center',
    },
    {
      title: 'Valor total dos boletos',
      key: 'valor_total_boletos',
      dataIndex: 'valor_total_boletos',
      align: 'center',
      render: (item: string) => dinheiroMask(item),
    },
    {
      title: 'Abrir Boletos',
      key: 'cpf',
      dataIndex: 'cpf',
      width: 100,
      render: (cpf: string) => (
        <Link to={{ pathname: '/boleto', search: `?cpf_cliente=${cpf}` }}>
          <Button type="primary">
            <MdOpenInNew />
          </Button>
        </Link>
      ),
    },
  ];

  return (
    <>
      <PageHeader title="Clientes" breadcrumb={['Clientes', 'Pesquisar']} />
      <CardCollapse title="Filtros">
        <Form
          layout="vertical"
          form={form}
          onFinish={data => handleSetFiltroValues(data)}
        >
          <Row gutter={16}>
            <Col md={6} xs={12}>
              <Form.Item name="nome" label="Nome">
                <Input />
              </Form.Item>
            </Col>
            <Col md={6} xs={12}>
              <Form.Item name="email" label="Email">
                <Input />
              </Form.Item>
            </Col>
            <Col md={6} xs={12}>
              <Form.Item name="cpf" label="CPF">
                <Input
                  onChange={e =>
                    form.setFieldsValue({ cpf: cpfMask(e.target.value) })
                  }
                />
              </Form.Item>
            </Col>
            <Col md={6} xs={12}>
              <Form.Item name="telefone" label="Telefone">
                <Input
                  onChange={e =>
                    form.setFieldsValue({
                      telefone: celularMask(e.target.value),
                    })
                  }
                />
              </Form.Item>
            </Col>
          </Row>

          <br />

          <Row justify="end">
            <Button onClick={handleResetForm} className="buttonMarginRight">
              Limpar
            </Button>
            <Button type="primary" htmlType="submit">
              Filtrar
            </Button>
          </Row>
        </Form>
      </CardCollapse>
      <strong>Número de Clientes: {response?.clientes.length}</strong>
      <Table
        columns={columns}
        dataSource={response?.clientes}
        loading={loading}
        size="small"
        pagination={{
          total: response?.total,
          current: response?.pagina,
          pageSize: response?.porPagina,
          onChange: handleUpdatePagination,
        }}
      />
      ;
    </>
  );
};

export default ClientePesquisar;
