import { Button, Col, Form, Input, Modal, Row, Switch, Typography } from 'antd';
import { Rule } from 'rc-field-form/lib/interface';
import React, { useEffect, useState } from 'react';

import CardCollapse from '../../../../components/CardCollapse';
import { useApi } from '../../../../hooks/useApi';
import WhitelabelVisualizacao from './Visualizacao';
import Paragraph from 'antd/lib/typography/Paragraph';

const { Text } = Typography;

const required_rule: Rule = {
  required: true,
  message: 'Esse campo é obrigatório!',
};
const url_rule: Rule = {
  type: 'url',
  message: 'Deve ser uma URL válida',
};

type ICardWhitelabel = {
  id_usuario: string;
  whitelabel?: any;
  perfis?: any[];
};

const CardWhitelabel: React.FC<ICardWhitelabel> = ({
  id_usuario: id,
  whitelabel,
  perfis,
}: ICardWhitelabel) => {
  const [form] = Form.useForm();
  const { fetchRequest, loading } = useApi();
  const [newWhitelabel, setNewWhitelabel] = useState<any>();
  const [formEditLabelDescription, setFormEditLabelDescription] = useState<{
    visible: boolean;
    service_name: string;
  }>({
    visible: false,
    service_name: '',
  });
  const [enableEditPayBillet, setEnableEditPayBillet] = useState<boolean>(
    whitelabel?.pay_billet_installments,
  );
  const [enableEditPayTaxes, setEnableEditPayTaxes] = useState<boolean>(
    whitelabel?.pay_taxes,
  );
  const [enableEditPayVehicleDebts, setEnableEditPayVehicleDebts] =
    useState<boolean>(whitelabel?.pay_vehicle_debts);
  const [enableEditRechargeCellPhone, setEnableEditRechargeCellPhone] =
    useState<boolean>(whitelabel?.recharge_cell_phone);
  const [enableTransferBank, setEnableTransferBank] = useState<boolean>(
    whitelabel?.transfer_bank,
  );
  const { TextArea } = Input;

  const boxStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: '1px solid #d9d9d9',
    borderRadius: 4,
    padding: '4px 11px',
  };

  useEffect(() => {
    form.setFieldsValue(whitelabel);
    setNewWhitelabel(whitelabel);

    setEnableEditPayBillet(whitelabel?.pay_billet_installments);
    setEnableEditPayTaxes(whitelabel?.pay_taxes);
    setEnableEditPayVehicleDebts(whitelabel?.pay_vehicle_debts);
    setEnableEditRechargeCellPhone(whitelabel?.recharge_cell_phone);
    setEnableTransferBank(whitelabel?.transfer_bank);
  }, [whitelabel]);

  function handleSubmitUpdate(data: any) {
    return fetchRequest({
      method: 'post',
      url: `/usuario/${id}/whitelabel`,
      data,
      messages: {
        loading: 'Alterando configurações de whitelabel',
        error:
          'Erro ao atualizar configurações do whitelabel, tente novamente!',
        success: 'Configurações atualizadas com sucesso',
      },
      onSuccess: () => {
        setNewWhitelabel({
          ...whitelabel,
          ...data,
        });
      },
    });
  }

  if (!perfis?.find(item => item.id_perfil === 5)) {
    return null;
  }

  const handleUpdateLabelDescription = () => {
    form.submit();
    setFormEditLabelDescription({
      visible: false,
      service_name: '',
    });
  };

  return (
    <CardCollapse title="Configurações Whitelabel">
      <Form
        layout="vertical"
        form={form}
        onFinish={data => handleSubmitUpdate(data)}
        initialValues={{
          message_font_size: 8,
          label_pay_billet_installments:
            whitelabel?.label_pay_billet_installments,
        }}
      >
        <Row gutter={16}>
          <Col md={24}>
            <Form.Item label="Nome" name="name" rules={[required_rule]}>
              <Input maxLength={255} />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label="Cor Primaria"
              name="primary_color"
              rules={[required_rule]}
            >
              <input type="color" className="ant-input" />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label="Cor Secondária"
              name="secondary_color"
              rules={[required_rule]}
            >
              <input type="color" className="ant-input" />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label="URL Logo Principal"
              name="main_logo_url"
              rules={[required_rule, url_rule]}
            >
              <Input maxLength={255} />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label="URL Favicon"
              name="favicon_url"
              rules={[required_rule, url_rule]}
            >
              <Input maxLength={255} />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label="URL do Cliente"
              name="client_url"
              rules={[required_rule, url_rule]}
            >
              <Input maxLength={255} />
            </Form.Item>
          </Col>
          <Col md={8}>
            <Form.Item label="Parâmetros da Fonte" name="message_font_color">
              <input type="color" className="ant-input" />
            </Form.Item>
          </Col>
          <Col md={4}>
            <Form.Item label="Tamanho" name="message_font_size">
              <input type="number" className="ant-input" min={8} max={28} />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item label="Conteúdo da mensagem" name="message_content">
              <TextArea maxLength={300} rows={4} />
            </Form.Item>
            <div style={{ ...boxStyle, marginTop: 10 }}>
              <Text strong>Habilitar edição de boletos</Text>
              <Form.Item name="action_button_billet" valuePropName="checked">
                <Switch />
              </Form.Item>
            </div>
            <div style={{ ...boxStyle, marginTop: 10 }}>
              <Text strong>Habilitar pagamento via pix</Text>
              <Form.Item name="pix_enable" valuePropName="checked">
                <Switch />
              </Form.Item>
            </div>
            <div style={{ ...boxStyle, marginTop: 10 }}>
              <Text strong>Habilitar pagamento via cartão de crédito</Text>
              <Form.Item name="credit_card_enable" valuePropName="checked">
                <Switch />
              </Form.Item>
            </div>
            <div style={{ ...boxStyle, marginTop: 10 }}>
              <Text strong>Habilitar pagamento via cartão de débito</Text>
              <Form.Item name="debit_card_enable" valuePropName="checked">
                <Switch />
              </Form.Item>
            </div>
          </Col>
          <Col md={12}>
            <div
              style={{
                padding: '8px 0px',
              }}
            >
              <Text>Serviços</Text>
            </div>

            <div style={boxStyle}>
              <Paragraph
                editable={
                  enableEditPayBillet
                    ? {
                        onStart: () => {
                          setFormEditLabelDescription({
                            visible: true,
                            service_name: 'pay_billet_installments',
                          });
                        },
                      }
                    : undefined
                }
              >
                <Text strong>Pagar boletos</Text>
              </Paragraph>
              <Form.Item name="pay_billet_installments" valuePropName="checked">
                <Switch onChange={value => setEnableEditPayBillet(value)} />
              </Form.Item>
            </div>
            <div style={boxStyle}>
              <Paragraph
                editable={
                  enableEditPayTaxes
                    ? {
                        onStart: () => {
                          setFormEditLabelDescription({
                            visible: true,
                            service_name: 'pay_taxes',
                          });
                        },
                      }
                    : undefined
                }
              >
                <Text strong>Pagar Impostos</Text>
              </Paragraph>
              <Form.Item name="pay_taxes" valuePropName="checked">
                <Switch onChange={value => setEnableEditPayTaxes(value)} />
              </Form.Item>
            </div>
            <div style={boxStyle}>
              <Paragraph
                editable={
                  enableEditPayVehicleDebts
                    ? {
                        onStart: () => {
                          setFormEditLabelDescription({
                            visible: true,
                            service_name: 'pay_vehicle_debts',
                          });
                        },
                      }
                    : undefined
                }
              >
                <Text strong>Débitos de Veículos</Text>
              </Paragraph>
              <Form.Item name="pay_vehicle_debts" valuePropName="checked">
                <Switch
                  onChange={value => setEnableEditPayVehicleDebts(value)}
                />
              </Form.Item>
            </div>
            <div style={boxStyle}>
              <Paragraph
                editable={
                  enableEditRechargeCellPhone
                    ? {
                        onStart: () => {
                          setFormEditLabelDescription({
                            visible: true,
                            service_name: 'recharge_cell_phone',
                          });
                        },
                      }
                    : undefined
                }
              >
                <Text strong>Regarregar Celular</Text>
              </Paragraph>
              <Form.Item name="recharge_cell_phone" valuePropName="checked">
                <Switch
                  onChange={value => setEnableEditRechargeCellPhone(value)}
                />
              </Form.Item>
            </div>
            <div style={boxStyle}>
              <Paragraph
                editable={
                  enableTransferBank
                    ? {
                        onStart: () => {
                          setFormEditLabelDescription({
                            visible: true,
                            service_name: 'transfer_bank',
                          });
                        },
                      }
                    : undefined
                }
              >
                <Text strong>Transferência Bancária</Text>
              </Paragraph>
              <Form.Item name="transfer_bank" valuePropName="checked">
                <Switch onChange={value => setEnableTransferBank(value)} />
              </Form.Item>
            </div>
          </Col>
        </Row>

        <br />

        <Row justify="end">
          <Button type="primary" htmlType="submit" loading={loading}>
            Salvar
          </Button>
        </Row>
        <Modal
          title="Editar label e descrição do serviço"
          visible={formEditLabelDescription.visible}
          onCancel={() =>
            setFormEditLabelDescription({
              visible: false,
              service_name: '',
            })
          }
          onOk={() => handleUpdateLabelDescription()}
        >
          <Form.Item
            name={`label_${formEditLabelDescription.service_name}`}
            label="Label"
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={`description_${formEditLabelDescription.service_name}`}
            label="Descrição"
          >
            <TextArea />
          </Form.Item>
        </Modal>
      </Form>

      <WhitelabelVisualizacao id_usuario={id} />
    </CardCollapse>
  );
};

export default CardWhitelabel;
