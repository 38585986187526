import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { usePermission } from '../../../hooks/usePermissions';

import { IState } from '../../../store';
import { IPerfil, ITipoLiquidacao } from '../../../store/modules/user/reducer';
import EnumPerfil from '../../../types/enum/Perfis';
import EnumResponsabilidade from '../../../types/enum/Responsabilidade';
import EnumTipoLiquidacao from '../../../types/enum/TipoLiquidacao';
import { MenuItem } from '../styles';
import { Dropdown, Menu } from 'antd';
import { canAccessCommissionsPage } from "../../../utils/permissions";

export default function MenuParceiro(): JSX.Element {
  const responsabilidade = useSelector<
    IState,
    EnumResponsabilidade | undefined
  >(state => state.auth.responsabilidade);
  const perfis = useSelector<IState, IPerfil[]>(state => state.user.perfis);
  const tipo_liquidacao = useSelector<IState, ITipoLiquidacao[]>(
    state => state.user.tipo_liquidacao,
  );
  const id_estabelecimento = useSelector<IState, string>(
    state => state.user.id_estabelecimento as string,
  );

  const id_estabelecimento_whitelabel =
    useSelector<IState, string>(
      state => state.user.id_estabelecimento_whitelabel as string,
    ) || process.env.REACT_APP_PARCELAMOS_TUDO_ESTABLISHMENT;
  const { userPermissions } = usePermission();

  const canAccessComissionsPage = useMemo(() => canAccessCommissionsPage({ responsabilidade, perfis }), [perfis, responsabilidade])

  const menuItems = useMemo(() => {
    const menuItens = [];

    if (
      perfis.find(perfil => perfil.id_perfil === EnumPerfil.DETRAN) &&
      userPermissions.DETRAN_BUSCA
    ) {
      menuItens.push(
        <MenuItem>
          <Link to="/detran">Detran</Link>
        </MenuItem>,
      );
    }

    if (
      responsabilidade === EnumResponsabilidade.REPRESENTANTE ||
      (responsabilidade === EnumResponsabilidade.RESPONSAVEL &&
        !perfis.some(perfil => perfil.id_perfil === EnumPerfil.TJ))
    ) {
      menuItens.push(
        <MenuItem>
          <Link to="/estabelecimentos">Meus Estabelecimentos</Link>
        </MenuItem>,
      );
    }

    if (responsabilidade === EnumResponsabilidade.ESTABELECIMENTO) {
      menuItens.push(
        <MenuItem>
          <Link to="/pos">Meus POS`s</Link>
        </MenuItem>,
      );
    }

    if (
      responsabilidade === EnumResponsabilidade.ESTABELECIMENTO &&
      tipo_liquidacao?.some(
        item => item.id_tipo_liquidacao === EnumTipoLiquidacao.ARQUIVO_RETORNO,
      )
    ) {
      menuItens.push(
        <MenuItem>
          <Link to="/arquivo_exportacao">Arquivos de Retorno</Link>
        </MenuItem>,
      );
    }

    const responsabilidadesParaAcessarVendas = [
      EnumResponsabilidade.ESTABELECIMENTO,
      EnumResponsabilidade.REPRESENTANTE,
      EnumResponsabilidade.INCENTIVADOR,
      EnumResponsabilidade.RESPONSAVEL,
    ];
    if (
      responsabilidadesParaAcessarVendas.includes(
        responsabilidade as EnumResponsabilidade,
      )
    ) {
      menuItens.push(
        <MenuItem>
          <Link to="/extrato">Vendas</Link>
        </MenuItem>,
      );
    }

    if (
      perfis.some(perfil => perfil.id_perfil === EnumPerfil.TJ) &&
      responsabilidade === EnumResponsabilidade.RESPONSAVEL
    ) {
      const menu_cadastro = () => (
        <Menu>
          <Menu.Item key="1">
            <Link to="/cadastro_usuario">Usuário</Link>
          </Menu.Item>
          <Menu.Item key="1">
            <Link to="/cadastro_unidade">Unidade</Link>
          </Menu.Item>
        </Menu>
      );

      menuItens.push(
        <MenuItem>
          <Dropdown overlay={menu_cadastro}>
            <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
              Cadastrar
            </a>
          </Dropdown>
        </MenuItem>,
        <MenuItem>
          <Link to="/vinculos">Vínculos</Link>
        </MenuItem>,
        <MenuItem>
          <Link to="/relatorios_acordos">Relatórios de Acordos e Custas</Link>
        </MenuItem>,
      );
    }

    if (
      perfis.some(perfil => perfil.id_perfil === EnumPerfil.TJ) &&
      responsabilidade === EnumResponsabilidade.INCENTIVADOR
    ) {
      menuItens.push(
        <MenuItem>
          <Link to="/relatorios_acordos">Relatórios de Acordos e Custas</Link>
        </MenuItem>,
        <MenuItem>
          <a
            href={`${process.env.REACT_APP_URL_PORTAL}/simulador/${
              id_estabelecimento || id_estabelecimento_whitelabel
            }`}
            target="_blank"
            rel="noreferrer"
          >
            Simulador
          </a>
        </MenuItem>,
      );
    }
    if (canAccessComissionsPage) {
      menuItens.push(
        <MenuItem>
          <Link to="/commissions">Comissões</Link>
        </MenuItem>,
      );
    }

    return menuItens.map(item => item);
  }, [responsabilidade]);

  if (!responsabilidade) {
    return <p>Selecione uma responsabilidade</p>;
  }

  const profileCanAccessSimulator = [
    EnumResponsabilidade.ESTABELECIMENTO,
    EnumResponsabilidade.INCENTIVADOR,
  ];

  const profileCanAccessPaymentLink = [
    EnumResponsabilidade.INCENTIVADOR,
    EnumResponsabilidade.REPRESENTANTE,
    EnumResponsabilidade.RESPONSAVEL
  ]

  return (
    <>
      <MenuItem>
        <Link to="/dashboard">Dashboard</Link>
      </MenuItem>

      {/* {!(
        perfis.some(perfil => perfil.id_perfil === EnumPerfil.TJ) &&
        responsabilidade === EnumResponsabilidade.INCENTIVADOR
      ) && (
        <MenuItem>
          <a
            href={`${process.env.REACT_APP_URL_PORTAL}/simulador/${
              id_estabelecimento || id_estabelecimento_whitelabel
            }`}
            target="_blank"
            rel="noreferrer"
          >
            Simulador
          </a>
        </MenuItem>
      )} */}
      {profileCanAccessSimulator.includes(responsabilidade) ? (
        <MenuItem>
          <Link to="/simulador">Simulador</Link>
        </MenuItem>
      ) : null}

      {menuItems}

      {profileCanAccessPaymentLink.includes(responsabilidade) ? (
        <MenuItem>
          <Link to="/link">Links de Pagamentos</Link>
        </MenuItem>
      ) : null}
    </>
  );
}
