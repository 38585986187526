import {
  Button,
  Col,
  Form,
  message,
  Popconfirm,
  Result,
  Row,
  Select,
  Table,
} from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { MdRemoveCircle } from 'react-icons/md';
import { Link } from 'react-router-dom';
import Paragraph from 'antd/lib/typography/Paragraph';
import { format, parseISO } from 'date-fns';
import { useForm } from 'antd/lib/form/Form';
import CardCollapse from '../../../../components/CardCollapse';
import { useUtils } from '../../../../hooks/useUtils';
import { fetchApi } from '../../../../services/api';
import Option from '../../../../types/Option';

type IState = {
  loading: boolean;
  default_message: string;
  user_is_totem: boolean;
};

interface FormAddEstabelecimentoParceiro {
  id_parceiro: string;
}

interface ILinkEstabelecimentoParceiro {
  id_estabelecimento_parceiro: string;
  id_estabelecimento: string;
  id_partner: string;
  criado_em: string;
  criado_port: string;
  nome_parceiro: string;
}

interface Pagina {
  data: ILinkEstabelecimentoParceiro[];
  pagina: number;
  porPagina: number;
  total: number;
}

const CardParceiros: React.FC<IState> = ({
  loading,
  default_message,
}: IState) => {
  const [form] = useForm();
  const id_estabelecimento = useParams<{ id: string }>().id;
  const { getParceiros } = useUtils();

  const [parceiros, setParceiros] = useState<Option[]>([]);
  const [
    linkEstabelecimentoParceiro,
    setLinkEstabelecimentoParceiro,
  ] = useState({} as Pagina);

  useEffect(() => {
    fetchParceiros();
    fetchEstabelecimentoParceiro();
  }, []);

  async function fetchParceiros() {
    const parceiros_response = await getParceiros();

    setParceiros(
      parceiros_response?.map(item => ({
        value: item.id_partner,
        label: item.name,
      })),
    );
  }
  function fetchEstabelecimentoParceiro() {
    return fetchApi({
      url: '/estabelecimento_parceiro/',
      method: 'get',
      query_params: { id_estabelecimento },
      onSuccess: list_estabelecimento_parceiro => {
        setLinkEstabelecimentoParceiro(list_estabelecimento_parceiro);
      },
      onError: () => {
        message.error(
          'Erro ao buscar dados de relacionamento de estabelecimento e parceiros',
        );
      },
    });
  }

  function handleRemoveEstabelecimentoParceiro(id_estabelecimento: string) {
    return fetchApi({
      url: `/estabelecimento_parceiro/${id_estabelecimento}`,
      method: 'delete',
      onSuccess: () => {
        fetchEstabelecimentoParceiro();
        message.success('Parceiro removido com sucesso');
      },
      onError: () => {
        message.error('Parceiro não removido');
      },
    });
  }

  function handleAddEstabelecimentoParceiro(
    data: FormAddEstabelecimentoParceiro,
  ) {
    return fetchApi({
      url: '/estabelecimento_parceiro',
      method: 'post',
      data: {
        id_estabelecimento,
        id_partner: data.id_parceiro,
      },
      onSuccess: () => {
        fetchEstabelecimentoParceiro();
        form.resetFields();
        message.success('Parceiro adicionado com sucesso');
      },
      onError: () => {
        message.error('Parceiro não adicionado');
      },
    });
  }

  const parceirosFiltered = useMemo(() => {
    return parceiros?.filter(
      item =>
        !linkEstabelecimentoParceiro.data?.find(
          data => data.id_partner === item.value,
        ),
    );
  }, [linkEstabelecimentoParceiro, parceiros]);

  const columns_parceiro = [
    {
      title: 'Id do vínculo',
      dataIndex: 'id_estabelecimento_parceiro',
      key: 'id_estabelecimento_parceiro',
      render: (item: string) => <Paragraph copyable>{item}</Paragraph>,
    },
    {
      title: 'Nome do Parceiro',
      dataIndex: 'nome_parceiro',
      key: 'nome_parceiro',
      render: (item: string, data: any) => (
        <a
          href={`https://portal.parcelamostudo.com.br/payment_online/${data.id_partner}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {item}
        </a>
      ),
    },
    {
      title: 'Criado Por',
      dataIndex: 'criado_por',
      key: 'criado_por',
      render: (item: string, data: any) => (
        <Link to={`/usuario/${data.criado_por}`}>{item}</Link>
      ),
    },
    {
      title: 'Criado Em',
      dataIndex: 'criado_em',
      key: 'criado_em',
      render: (item: string) => format(parseISO(item), 'dd/MM/yyyy HH:mm:ss'),
    },
    {
      title: 'Remover',
      dataIndex: 'id_estabelecimento_parceiro',
      key: 'id_estabelecimento_parceiro',
      width: 100,
      render: (item: string) => (
        <Popconfirm
          placement="topRight"
          title="Tem certeza que deseja remover esse parceiro?"
          onConfirm={() => handleRemoveEstabelecimentoParceiro(item)}
          okText="Sim"
          cancelText="Não"
        >
          <Button type="primary" danger>
            <MdRemoveCircle />
          </Button>
        </Popconfirm>
      ),
    },
  ];

  return (
    <CardCollapse loading={loading} title="Lista de Parceiros Vinculados">
      <>
        <Form
          layout="vertical"
          onFinish={handleAddEstabelecimentoParceiro}
          form={form}
        >
          <Row justify="end" gutter={16}>
            <Col md={18} xs={24}>
              <Form.Item
                name="id_parceiro"
                rules={[{ required: true, message: default_message }]}
              >
                <Select
                  placeholder="Selecione um Parceiro"
                  options={parceirosFiltered}
                />
              </Form.Item>
            </Col>
            <Col md={6} xs={24}>
              <Button
                type="primary"
                htmlType="submit"
                className="buttonMarginTopLow"
                block
              >
                Adicionar
              </Button>
            </Col>
          </Row>
        </Form>

        <br />
      </>

      {linkEstabelecimentoParceiro.data?.length ? (
        <Table
          columns={columns_parceiro}
          dataSource={linkEstabelecimentoParceiro.data}
          pagination={{
            total: linkEstabelecimentoParceiro.total,
            current: linkEstabelecimentoParceiro.pagina,
            pageSize: linkEstabelecimentoParceiro.porPagina,
          }}
        />
      ) : (
        <Result extra="Estabelecimento sem nenhum parceiro" />
      )}
    </CardCollapse>
  );
};

export default CardParceiros;
