import React from 'react';
import {
  Route as ReactDomRoute,
  RouteProps as ReactDomRouteProps,
  Redirect,
  Link,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Result } from 'antd';

import AuthLayout from '../pages/_layouts/auth';
import DefaultLayout from '../pages/_layouts/default';
import InformationLayout from '../pages/_layouts/information';
import { IState } from '../store';
import { IAuthState } from '../store/modules/auth/reducer';
import { usePermission } from '../hooks/usePermissions';

interface RouteProps extends ReactDomRouteProps {
  isPrivate?: boolean;
  isInfomation?: boolean;
  permissionLevelRoute?: number;
  component: React.ComponentType;
}

const Route: React.FC<RouteProps> = ({
  isInfomation = false,
  isPrivate = false,
  permissionLevelRoute,
  component,
  ...rest
}) => {
  const { authenticated, tipo, responsabilidade } = useSelector<
    IState,
    IAuthState
  >(state => state.auth);
  const { hasPermission } = usePermission();

  let Component = component;

  if (!authenticated && isPrivate) {
    return <Redirect to="/login" />;
  }

  if (authenticated && !isPrivate) {
    const resto = { ...window.location };

    const fields = resto.search.substr(1).split('&') as string[];

    const has_redirect = fields.find(item => item.indexOf('redirect') !== -1);

    if (has_redirect) {
      const redirect_to = has_redirect.split('=')[1];

      return <Redirect to={redirect_to} />;
    }

    return <Redirect to="/dashboard" />;
  }

  if (
    authenticated &&
    isPrivate &&
    tipo === 'parceiro' &&
    !responsabilidade &&
    rest.path !== '/alterar_responsabilidade'
  ) {
    return <Redirect to="/alterar_responsabilidade" />;
  }

  if (permissionLevelRoute && !hasPermission(permissionLevelRoute)) {
    Component = () => (
      <Result
        status="403"
        title="Você não tem autorização para acessar essa página"
        extra={<Link to="/dashboard">Voltar para o dashboard</Link>}
      />
    );
  }

  const Layout = isPrivate
    ? DefaultLayout
    : isInfomation
    ? InformationLayout
    : AuthLayout;

  return (
    <ReactDomRoute
      {...rest}
      render={() => {
        return (
          <Layout>
            <Component />
          </Layout>
        );
      }}
    />
  );
};

export default Route;
