import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

const transparentizePct = 0.5;

interface StyledCardI {
  clickable: boolean;
  hover: boolean;
  blur: boolean;
  fontSize?: string;
  shadow_color?: string;
}

export const StyledCard = styled.div<StyledCardI>`
  /* min-height: 150px; */
  background: white;
  margin-bottom: 15px;
  border-radius: 5px;
  /* box-shadow: 0px 0px 14px rgba(0,0,0,.1); */
  box-shadow: 0px 0px 8px
    ${props =>
      props.shadow_color
        ? transparentize(transparentizePct, props.shadow_color)
        : 'rgba(0,0,0,.1)'};
  padding: 15px;
  transition: 0.3s;

  cursor: ${props => (props.clickable ? 'pointer' : '')};
  border: 1px solid
    ${props =>
      props.shadow_color
        ? transparentize(transparentizePct, props.shadow_color)
        : 'white'};

  ${props =>
    props.hover &&
    css`
      &:hover {
        box-shadow: 0px 0px 2px 2px
          ${props.shadow_color
            ? transparentize(transparentizePct, props.shadow_color)
            : '#ccc'};
      }
    `}

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .title {
    color: #929292;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    text-overflow: ellipsis;
    text-align: center;
  }

  .value {
    font-size: ${props => props.fontSize || '40px'};
    font-weight: bold;
    transition: 0.3s;

    ${props =>
      props.blur &&
      css`
        filter: blur(7px);
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */
      `}

    .prefix,
    .posfix {
      font-weight: 400;
      font-size: 20px;
      color: #929292;
    }
  }

  .description {
    font-size: 12px;
    color: #929292;
  }
`;
