export enum EnumPerfil {
  ADMIN = 1,
  PADRAO = 2,
  MASTER = 3,
  DETRAN = 4,
  WHITELABEL = 5,
  CADASTRO_DE_CARRINHO = 6,
  PREFEITURA = 7,
  TOTEM = 8,
  PORTAL = 9,
  ADMIN_ESTABELECIMENTO = 10,
  TJ = 11,
}

export default EnumPerfil;
