import React, { useMemo } from 'react';
import { Empty } from 'antd';

import CardCollapse from '../../../../components/CardCollapse';
import PropertyList, {
  InfoData,
  InfoDataFields,
} from '../../../../components/PropertyList';
import { Charge } from '../../services/types';

type IProps = {
  data: Charge;
  loading: boolean;
};

const PaymentInfo: React.FC<IProps> = ({ data, loading }: IProps) => {
  const info = useMemo(() => {
    const datas: InfoData = { category: [] };

      let fields: InfoDataFields[] = [];

      if (data.pix_qr_code) {
        fields.push({
          name: 'EMV',
          value: data.pix_qr_code,
          copyable: true,
        });
      }
      if (data.bill_code) {
        fields.push({
          name: 'Código de barras',
          value: data.bill_code,
          copyable: true,
        });
      }
      if (data.acquirer) {
        fields.push({
          name: 'Adquirência',
          value: data.acquirer
        })
      }
      if (data.provider_code) {
        fields.push({
          name: 'Código do provedor',
          value: data.provider_code
        })
      }
      if (data.provider_name) {
        fields.push({
          name: 'Provedor',
          value: data.provider_name
        })
      }
      if (data.nsu_code) {
        fields.push({
          name: 'NSU',
          value: data.nsu_code
        })
      }
      if (data.psp) {
        fields.push({
          name: 'PSP',
          value: data.psp
        })
      }
      if (data.channel) {
        fields.push({
          name: 'Canal',
          value: data.channel
        })
      }
      if (data.card_brand) {
        fields.push({
          name: 'Bandeira do cartão',
          value: data.card_brand
        })
      }
      if (data.card_last_four_digits) {
        fields.push({
          name: 'Últimos 4 digítos',
          value: data.card_last_four_digits
        })
      }

      datas.category.push({ fields });
      

    if (!datas.category.length) return undefined;

    return datas;
  }, [data]);

  return (
    <CardCollapse title="Dados" loading={loading}>
      {!info ? (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="Cobrança sem informações"
        />
      ) : (
        <PropertyList.Card data={info} />
      )}
    </CardCollapse>
  );
};

export default PaymentInfo;
