import React from 'react';
import PageHeader from "../../../components/PageHeader";
import TerminalForm, { EnumFormType, FormStoreSchema } from "../components/Form";
import { useMutation } from "react-query";
import { putTerminal } from "../services";
import { message } from "antd";
import { useHistory, useParams } from "react-router";
import { useTerminalInfo } from "../hooks";
import { queryClientApp } from "../../../App";
import { TerminalErrorResponse, getTerminalErrorMessages } from "../services/types";

const TerminalEdit: React.FC = () => {
  const { id_terminal } = useParams<{ id_terminal: string }>();
  const history = useHistory()

  const { data, isLoading: loadingInfo } = useTerminalInfo(id_terminal)

  const { isLoading: loadingUpdate, mutate: editTerminal } = useMutation({
    mutationFn: putTerminal,
    onMutate() {
      message.open({
        type: 'loading',
        duration: 0,
        key: 'terminal-message-update',
        content: 'Atualizando terminal...'
      })
    },
    onSuccess() {
      queryClientApp.invalidateQueries(['terminal-info', id_terminal])
      queryClientApp.invalidateQueries(['terminal-search'])
      message.open({
        type: 'success',
        content: 'Terminal atualizado com sucesso!',
        key: 'terminal-message-update',
        onClose() {
          history.replace(`/terminal/${id_terminal}`)
        },
      })   
    },
    onError(error: any) {
      const errorResponse = error as TerminalErrorResponse

      const errorText = getTerminalErrorMessages(errorResponse)

      message.open({
        type: 'error',
        content: errorText,
        key: 'terminal-message-update',
        duration: 3
      })    
    },
  })

  const isLoading = loadingInfo || loadingUpdate

  function handleSubmit(data: FormStoreSchema) {
    editTerminal({payload: data, id_terminal})
  }

  return (
    <>
      <PageHeader 
        title="Editar terminal"
        breadcrumb={['Terminais', 'Editar']}
      />

      <TerminalForm loading={isLoading} onSubmit={handleSubmit} formType={EnumFormType.UPDATE} initialValues={data ? {
        id_model: data?.model.id_model,
        logic_number: data.logic_number,
        serial_number: data.serial_number,
        type: data.type,
        id_status: data.id_status
      } : undefined} />
    </>
  )
}

export default TerminalEdit