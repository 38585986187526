import React, { useEffect, useState } from 'react';
import { MdCloudDownload } from 'react-icons/md';
import {
  Button,
  Table,
  Form,
  DatePicker,
  Row,
  Col,
  Tag,
  Menu,
  Dropdown,
  message,
} from 'antd';
import { dinheiroMask } from 'masks-br';
import { format, addDays } from 'date-fns';

import CardCollapse from '../../../components/CardCollapse';
import PageHeader from '../../../components/PageHeader';
import { useApi } from '../../../hooks/useApi';
import Pagina from '../../../types/Pagina';
import downloadFiles from '../../../utils/downloadFiles';
import ArquivoExportacaoPesquisarRelatorio from './Relatorio';
import { useSelector } from 'react-redux';
import { IState } from '../../../store';

const default_message = 'Esse campo é obrigatório';

const ArquivoExportacaoPesquisar: React.FC = () => {
  const userID = useSelector<IState, string>(state => state.user.id_usuario);
  const [arquivos, setArquivos] = useState<Pagina>({} as Pagina);

  const { fetchRequest, loading } = useApi();
  const { fetchRequest: fetchGenerate, loading: loadingGenerate } = useApi();

  useEffect(() => {
    handleFetchFiles();
  }, []);

  function handleFetchFiles(page = 1, page_size = 25) {
    return fetchRequest({
      method: 'get',
      url: `/panel/export-file`,
      query_params: { page, page_size },
      onSuccess: resp => {
        const data = resp.data.map((item: any) => ({
          ...item,
          reference_date: format(
            addDays(new Date(item.reference_date), 1),
            'dd/MM/yyyy',
          ),
        }));

        setArquivos({
          ...resp,
          data,
        });
      },
    });
  }

  function handleGenerateFile(data: any) {
    return fetchGenerate({
      method: 'post',
      url: `/panel/export-file/generate`,
      data: {
        reference_date: data.reference_date.format('YYYY-MM-DD'),
      },
      onSuccess: () => {
        handleFetchFiles();
      },
      onError: error => {
        console.error(error.message);
        message.error(
          'Não foi possível gerar o arquivo ou não há boletos pagos na data selecionada, tente novamente mais tarde!',
        );
      },
    });
  }

  async function handleDownloadFile(data: any, type_end_line: string) {
    await downloadFiles(
      `/panel/export-file/${data.id_export_file}`,
      `${data.reference_date} - ${data.file_name} - Parcelamos Tudo.${(data.type as string)?.toLocaleLowerCase()}`,
      type_end_line,
    );

    const values = arquivos.data.map(item => {
      if (item.id_export_file === data.id_export_file) {
        item.downloaded = true;
      }

      return item;
    });

    setArquivos({
      ...arquivos,
      data: values,
    });
  }

  return (
    <>
      <PageHeader title="Download de Arquivos de Retorno" />

      <CardCollapse header={false}>
        <Form layout="vertical" onFinish={data => handleGenerateFile(data)}>
          <Row gutter={16}>
            <Col md={16}>
              <Form.Item
                label="Data de solicitação"
                name="reference_date"
                rules={[{ required: true, message: default_message }]}
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  placeholder="Selecione a data"
                />
              </Form.Item>
            </Col>
            <Col md={8}>
              <Button
                type="primary"
                htmlType="submit"
                className="buttonMarginTop"
                block
                loading={loadingGenerate}
              >
                Solicitar geração de arquivo
              </Button>
            </Col>
          </Row>
        </Form>
      </CardCollapse>

      <Table
        dataSource={arquivos.data}
        loading={loading}
        pagination={{
          total: arquivos.total,
          current: arquivos.page,
          pageSize: arquivos.page_size,
          onChange: (page, pageSize) => handleFetchFiles(page, pageSize),
        }}
        columns={[
          {
            title: 'Data Referente',
            dataIndex: 'reference_date',
            key: 'reference_date',
          },
          {
            title: 'Código do Arquivo',
            dataIndex: 'file_code',
            key: 'file_code',
          },
          {
            title: 'Nome',
            dataIndex: 'file_name',
            key: 'file_name',
          },
          {
            title: 'Boletos',
            render: (item: any) =>
              `${dinheiroMask(item.debits_total)} - ${item.debits_quantity
              } uni.`,
          },
          {
            title: 'Baixado',
            dataIndex: 'export_file_download',
            key: 'export_file_download',
            render: (item: any[]) =>
              item.length ? (
                <Tag color="green">Sim</Tag>
              ) : (
                <Tag color="blue">Não</Tag>
              ),
          },
          {
            title: 'Relatório',
            dataIndex: 'id_export_file',
            key: 'id_export_file',
            width: 100,
            render: (item: any) => (
              <ArquivoExportacaoPesquisarRelatorio
                id_arquivo_exportacao={item}
                type_login="panel"
              />
            ),
          },
          {
            title: 'Baixar',
            width: 100,
            render: (data: any) => (
              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item>
                      <a onClick={() => handleDownloadFile(data, 'CRLF')}>
                        CRLF
                      </a>
                    </Menu.Item>
                    <Menu.Item>
                      <a onClick={() => handleDownloadFile(data, 'LF')}>LF</a>
                    </Menu.Item>
                    <Menu.Item>
                      <a onClick={() => handleDownloadFile(data, 'CR')}>CR</a>
                    </Menu.Item>
                  </Menu>
                }
              >
                <Button type="primary">
                  <MdCloudDownload />
                </Button>
              </Dropdown>
            ),
          },
        ]}
      />
    </>
  );
};

export default ArquivoExportacaoPesquisar;
