import { cpf, cnpj } from 'cpf-cnpj-validator';

export const fieldRegex = {
  clearSpacesBegnningAndEnd: /^[^\s]+(\s+[^\s]+)*$/,
};

export const fieldErrorMessages = {
  clearSpaces: 'Retire os espaços!',
};

export type FieldTypeDetran = 'RENAVAM' | 'LICENSE_PLATE' | 'DOCUMENT';

type ParamsValidators = (_: any, value: string) => Promise<void>;
type ValidateFieldRecord = Record<FieldTypeDetran, ParamsValidators>;

export function validateDocument(_: any, value: string): Promise<void> {
  if (!value) return Promise.reject('Campo Obrigatório!');

  const numberOnly = value.replace(/\D/g, '');
  if (numberOnly.length === 11 && !cpf.isValid(numberOnly)) {
    return Promise.reject('CPF Inválido!');
  }
  if (numberOnly.length === 14 && !cnpj.isValid(numberOnly)) {
    return Promise.reject('CNPJ Inválido!');
  }
  if (numberOnly.length !== 11 && numberOnly.length !== 14) {
    return Promise.reject('Documento Inválido!');
  }

  return Promise.resolve();
}

export function validateLicensePlate(_: any, value: string): Promise<void> {
  if (value && value.length === 7) {
    return Promise.resolve();
  } else {
    return Promise.reject('Placa Invalida!');
  }
}

export function validateRenavam(_: any, value: string): Promise<void> {
  if (!value) return Promise.reject('Campo Obrigatório!');

  const regexRenavam = /^\d{9,11}$/;
  if (!regexRenavam.test(value)) return Promise.reject('Renavam invalido!');

  return Promise.resolve();
}

export const fieldValidate: ValidateFieldRecord = {
  RENAVAM: validateRenavam,
  LICENSE_PLATE: validateLicensePlate,
  DOCUMENT: validateDocument,
};
