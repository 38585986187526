import { Typography, Row, Timeline, Button, Modal } from 'antd';
import React from 'react';
import { useMemo } from 'react';
import { fetchApi } from '../../../../services/api';

import { findTagByTipo } from '../../../../utils/findTagColorByTipo';

import { Container, ContainerItem } from './styles';

const { Text } = Typography;
const { confirm } = Modal;

type POSEstabelecimento = {
  status: string;
  status_tipo: string;
  logic_number: string;
  serial_number: string;
  id_ponto_venda: string;
  rastreio?: any;
  handleRefrash: () => void;
};

const PosEstabelecimentoCard: React.FC<POSEstabelecimento> = ({
  status,
  status_tipo,
  logic_number,
  serial_number,
  rastreio,
  id_ponto_venda,
  handleRefrash,
}: POSEstabelecimento) => {
  const rastreio_item = useMemo(() => {
    if (!rastreio) {
      return null;
    }

    const { eventos } = rastreio;

    return (
      <ContainerItem>
        <Row justify="space-between">
          <h3>Rastreio</h3>

          <Button type="link" onClick={handleConfirm}>
            Confirmar recebimento do POS
          </Button>
        </Row>

        <br />

        <Timeline mode="left">
          {eventos.map((evento: any) => (
            <Timeline.Item>
              <Text type="secondary">
                {evento.data} {evento.hora}
              </Text>
              <br />
              <strong>{evento.descricao}</strong>
              <br />
              {evento?.destino ? (
                <span>
                  {evento?.destino?.local} - {evento?.destino?.cidade} (
                  {evento?.destino?.uf})
                </span>
              ) : null}
            </Timeline.Item>
          ))}
        </Timeline>
      </ContainerItem>
    );
  }, [rastreio]);

  function handleConfirm() {
    confirm({
      title: 'Tem certeza que deseja confirmar o recebimento do POS?',
      centered: true,
      onOk() {
        fetchApi({
          method: 'post',
          url: `/ponto_venda/${id_ponto_venda}/confirm_delivery`,
          messages: {
            loading: 'Confirmando recebimento do POS, aguarde...',
            error: 'Erro ao confirmar recebimento do POS, tente novamente',
            success: 'Recebimento confirmado com sucesso!',
          },
          onSuccess: () => {
            handleRefrash();
          },
        });
      },
    });
  }

  return (
    <Container>
      <ContainerItem>
        <Row justify="space-between" align="middle">
          <div>
            <Text type="secondary">Logic Number:</Text> {logic_number}
            <br />
            <Text type="secondary">Serial Number:</Text> {serial_number}
          </div>
          {findTagByTipo(status, status_tipo)}
        </Row>
      </ContainerItem>

      {rastreio_item}
    </Container>
  );
};

export default PosEstabelecimentoCard;
