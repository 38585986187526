import { format, formatDistanceToNow } from 'date-fns';
import {
  Button,
  Divider,
  List,
  Modal,
  Timeline,
  Tooltip,
  Typography,
} from 'antd';
import { dinheiroMask } from 'masks-br';
import React, { useCallback, useMemo, useState } from 'react';
import { ptBR } from 'date-fns/locale';
import { MdInfo, MdList } from 'react-icons/md';

import PropertyList from '../../../../components/PropertyList';
import CardCollapse from '../../../../components/CardCollapse';
import CardBrand from '../../../../components/Icons/CardBrand';
import { Charge, GetBackofficeCheckoutResponse } from '../types';
import { findStatusColor, getColor } from '../..';
import UserName from '../../../../components/User/Name';
import { fetchApi } from '../../../../services/api';
import { getChargeTypeName } from '../../../../utils/checkout';

const { Text } = Typography;

type ICarrinhoInfoFormaPagamento = {
  loading: boolean;
  tick: number;
  checkout?: GetBackofficeCheckoutResponse;
};

type ITimeline = {
  open: boolean;
  data: any[];
  id_charge?: string;
};

const CheckoutCharges: React.FC<ICarrinhoInfoFormaPagamento> = ({
  loading,
  checkout,
  tick,
}: ICarrinhoInfoFormaPagamento) => {
  const [openInfoModal, setOpenInfoModal] = useState<string>();
  const [timeline, setTimeline] = useState<ITimeline>({
    data: [],
    open: false,
  });

  const selectedCharge = useMemo(() => {
    return checkout?.charges?.find(item => item.id_charge === openInfoModal)!;
  }, [openInfoModal, checkout]);

  function handleFetchChargeLogs(id_charge: string) {
    if (timeline.id_charge === id_charge) {
      setTimeline({
        ...timeline,
        open: true,
      });

      return;
    }

    return fetchApi({
      url: `/backoffice/checkout/charge/${id_charge}/timeline`,
      method: 'get',
      onSuccess: data => {
        setTimeline({
          open: true,
          id_charge,
          data: data.timeline,
        });
      },
    });
  }

  const getBrandIcon = (brand: string) => {
    switch (brand?.toLocaleUpperCase()) {
      case 'MASTERCARD':
        return (
          <>
            <CardBrand.Mastercard /> <span> Mastercard</span>
          </>
        );
      case 'VISA':
        return (
          <>
            <CardBrand.Visa /> <span> Visa</span>
          </>
        );
      case 'AMEX':
        return (
          <>
            <CardBrand.Amex /> <span> American Express</span>
          </>
        );
      case 'DINERS':
        return (
          <>
            <CardBrand.Diners /> <span> Diners</span>
          </>
        );
      case 'ELO':
        return (
          <>
            <CardBrand.Elo /> <span> Elo</span>
          </>
        );

      default:
        return brand;
    }
  };

  const getTitle = (item: Charge) => {
    if (item.type === 'PIX') {
      return (
        <>
          <span style={{ fontSize: '20px' }}>Pix</span>
          {' - '}
          <b style={{ fontSize: '20px' }}>{dinheiroMask(item.amount_total)}</b>
        </>
      );
    }

    return (
      <>
        <span style={{ fontSize: '20px' }}>{getChargeTypeName(item.type)}</span>
        {' - '}
        <b style={{ fontSize: '20px' }}>{dinheiroMask(item.amount_total)}</b>
        {'   '}
        {item.installment_number} x {dinheiroMask(item.installment_amount)}
      </>
    );
  };

  const getDistanceTime = useCallback(
    (updated_at: string) => {
      return (
        'Atualizado ' +
        formatDistanceToNow(new Date(updated_at), {
          locale: ptBR,
          addSuffix: true,
          includeSeconds: true,
        })
      );
    },
    [tick],
  );

  return (
    <>
      <CardCollapse title="Cobranças" loading={loading}>
        <List
          loading={loading}
          itemLayout="horizontal"
          dataSource={checkout?.charges || []}
          renderItem={item => (
            <List.Item
              key={item.id_charge}
              actions={[
                <Tooltip title="Abrir informações">
                  <Button onClick={() => setOpenInfoModal(item.id_charge)}>
                    <MdInfo />
                  </Button>
                </Tooltip>,
                <Tooltip title="Timeline da cobrança">
                  <Button onClick={() => handleFetchChargeLogs(item.id_charge)}>
                    <MdList />
                  </Button>
                </Tooltip>,
              ]}
            >
              <List.Item.Meta
                title={getTitle(item)}
                description={getDistanceTime(item.updated_at)}
              />
              <div>
                {findStatusColor(item?.status?.name, item?.status?.type)}
              </div>
            </List.Item>
          )}
        />
      </CardCollapse>

      <Modal
        title="Informações da Cobrança"
        centered
        visible={!!openInfoModal}
        footer={false}
        onCancel={() => setOpenInfoModal(undefined)}
      >
        <PropertyList.Container>
          <PropertyList.Line>
            <PropertyList.Label>Tipo</PropertyList.Label>
            <PropertyList.Value>{selectedCharge?.type}</PropertyList.Value>
          </PropertyList.Line>
          <PropertyList.Line>
            <PropertyList.Label>Origem</PropertyList.Label>
            <PropertyList.Value>{selectedCharge?.source}</PropertyList.Value>
          </PropertyList.Line>
          <PropertyList.Line>
            <PropertyList.Label>Canal</PropertyList.Label>
            <PropertyList.Value>{selectedCharge?.channel}</PropertyList.Value>
          </PropertyList.Line>
          <PropertyList.Line>
            <PropertyList.Label>Valor Total</PropertyList.Label>
            <PropertyList.Value>
              {dinheiroMask(selectedCharge?.amount_total)}
            </PropertyList.Value>
          </PropertyList.Line>
          <PropertyList.Line>
            <PropertyList.Label>Parcelas</PropertyList.Label>
            <PropertyList.Value>
              {selectedCharge?.installment_number}
            </PropertyList.Value>
          </PropertyList.Line>
          <PropertyList.Line>
            <PropertyList.Label>Valor por Parcela</PropertyList.Label>
            <PropertyList.Value>
              {dinheiroMask(selectedCharge?.installment_amount)}
            </PropertyList.Value>
          </PropertyList.Line>
          <PropertyList.Line>
            <PropertyList.Label>Adquirência</PropertyList.Label>
            <PropertyList.Value>
              {selectedCharge?.acquirer || <Text disabled>Não informado</Text>}
            </PropertyList.Value>
          </PropertyList.Line>
          <PropertyList.Line>
            <PropertyList.Label>NSU</PropertyList.Label>
            <PropertyList.Value>
              {selectedCharge?.nsu_code || <Text disabled>Não informado</Text>}
            </PropertyList.Value>
          </PropertyList.Line>
          <PropertyList.Line>
            <PropertyList.Label>Código do Provedor</PropertyList.Label>
            <PropertyList.Value>
              {selectedCharge?.provider_code}
            </PropertyList.Value>
          </PropertyList.Line>
        </PropertyList.Container>

        {selectedCharge?.card_brand && (
          <>
            <Divider orientation="left">Cartão</Divider>

            <PropertyList.Container>
              {/* <PropertyList.Line>
                <PropertyList.Label>Nome</PropertyList.Label>
                <PropertyList.Value>
                  {selectedCharge?.card?.holder_name}
                </PropertyList.Value>
              </PropertyList.Line> */}
              <PropertyList.Line>
                <PropertyList.Label>Número</PropertyList.Label>
                <PropertyList.Value>
                  •••• {selectedCharge?.card_last_four_digits}
                </PropertyList.Value>
              </PropertyList.Line>
              <PropertyList.Line>
                <PropertyList.Label>Bandeira</PropertyList.Label>
                <PropertyList.Value>
                  {getBrandIcon(selectedCharge.card_brand)}
                </PropertyList.Value>
              </PropertyList.Line>
            </PropertyList.Container>
          </>
        )}
      </Modal>

      <Modal
        visible={timeline.open}
        title="Timeline da cobrança"
        footer={false}
        onCancel={() => setTimeline({ ...timeline, open: false })}
        centered
      >
        <Timeline mode="left" reverse>
          {timeline?.data?.map(log => (
            <Timeline.Item
              key={log.id_timeline}
              label={format(new Date(log.created_at), 'dd/MM/yyyy HH:mm:ss')}
              color={getColor(log.status.type.toLowerCase())}
              position="start"
            >
              <strong>{log.status.name}</strong> <br />
              {log.description && (
                <Text type="secondary">{log.description}</Text>
              )}
              {log.created_by && <UserName id_user={log.created_by} />}
            </Timeline.Item>
          ))}
        </Timeline>
      </Modal>
    </>
  );
};

export default CheckoutCharges;
