import React from 'react';
import CurrencyInput from 'react-currency-input';
import { FormInstance } from 'antd/lib/form';

interface ItemInputCurrencyResquest {
  name?: string;
  prefix?: string;
  thousandSeparator?: string;
  decimalSeparator?: string;
  disabled?: boolean;
  form?: FormInstance;
  value?: any;
  allowNegative?: boolean;
  borderless?: boolean;
  onChange?: (val1: string, val2: number) => void;
}

const ItemInputCurrency: React.FC<ItemInputCurrencyResquest> = ({
  name,
  prefix = 'R$ ',
  thousandSeparator = '.',
  decimalSeparator = ',',
  disabled,
  form,
  value,
  allowNegative = false,
  borderless = false,
  ...rest
}: ItemInputCurrencyResquest) => {
  return (
    <CurrencyInput
      className={`ant-input ${disabled ? 'ant-input-disabled' : ''} ${borderless ? 'ant-input-borderless' : ''}`}
      value={value || name && form?.getFieldValue(name) || undefined}
      prefix={prefix}
      allowNegative={allowNegative}
      decimalSeparator={decimalSeparator}
      thousandSeparator={thousandSeparator}
      {...rest}
    />
  );
};

export default ItemInputCurrency;
