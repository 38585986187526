import styled from 'styled-components';

import cores from '../../styles/colors';

interface IImportant {
  type?: 'success' | 'warn';
  size?: number;
}

export const Important = styled.span<IImportant>`
  font-size: ${props => props.size ? `${props.size}px` : '25px'};
  font-weight: 500;
  display: flex;

  color: ${props => props.type === 'success' && cores.success || props.type === 'warn' && cores.warn};

  svg {
    font-size: 17px;
  }
`;
